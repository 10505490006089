<template>
  <Container>
    <!-- <h1>Dashboard</h1> -->
  </Container>
</template>

<script>
import Container from '../components/Container';
export default {
  name: 'Dashboard',
  components: {
    Container,
  },
};
</script>
