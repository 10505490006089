<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light @click="onClickBack()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            <div v-if="!isTitlePageGroup">
              {{
                (pageGameStore.selectedHomePageData &&
                  pageGameStore.selectedHomePageData.title) ||
                'Loading...'
              }}
              <v-btn
                icon
                light
                @click="isTitlePageGroup = true"
                :disabled="isLock"
              >
                <v-icon color="white">edit</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-layout>
                <v-flex>
                  <v-text-field
                    placeholder="title"
                    :value="pageGameStore.selectedHomePageData.title"
                    @change="e => onchangPageGroup('title', e)"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <v-btn icon light color="success" @click="saveTitle()">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="isTitlePageGroup = false">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <HomeGroupDetail></HomeGroupDetail>
          <v-layout row grid-list-md>
            <HomePageDataList></HomePageDataList>
            <HomePageDataDetail
              v-if="pageGameStore.selectedHomePageIndex != -1"
            ></HomePageDataDetail>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </Container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Container from '../../components/Container.vue';
import {
  HomePageDataList,
  HomePageDataDetail,
  HomeGroupDetail,
} from './components/';
import ruleValidate from '../../utils/ruleValidate';

export default {
  components: {
    Container,
    HomePageDataList,
    HomePageDataDetail,
    HomeGroupDetail,
  },
  data() {
    return {
      isTitlePageGroup: false,
      rules: ruleValidate,
    };
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
      isLock: state => state.homepage.adminIsLock,
    }),
  },
  methods: {
    ...mapActions('homepage', [
      'fetchHomePageData',
      'setStateByKey',
      'updatePageGroupData',
    ]),
    saveTitle() {
      const updateData = { ...this.pageGameStore.updatePageGroup };
      this.updatePageGroupData(updateData);
      this.isTitlePageGroup = false;
    },
    onClickBack() {
      const id = this.pageGameStore.updatePageTopic.id;
      id
        ? this.$router.push('/home_page/group?id=' + id)
        : this.$router.push('/home_page');
    },
    onchangPageGroup(key, value) {
      this.setStateByKey({
        statekey: 'updatePageGroup',
        key: key,
        value: value,
      });
    },
  },
  created() {
    if (this.$route.query.id) {
      const id = this.$route.query.id;
      this.fetchHomePageData(id);
      this.setStateByKey({ statekey: 'updatePageGroup', key: 'id', value: id });
    }
  },
};
</script>

<style></style>
