<template>
  <div>
    <v-text-field
      :rules="minMaxRules"
      type="number"
      label="Level Number"
      v-model.number="object.level"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'CreateLevel',
  components: {},
  computed: {},
  props: {
    object: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      minMaxRules: [
        v => !!v || 'Number is required but cannot be zero',
        v => (v && v >= 1) || 'Min number should be above 0',
        v => (v && v <= 100) || 'Max number should not be above 100',
      ],
    };
  },
  methods: {},
  created() {},
};
</script>

<style></style>
