import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
import Constants from '../../constants';
import httpClient from '../../utils/httpClient';

const defaultActivity = {
  title: 'New Activity',
  transition: 'LearningTime',
  game_data: [
    {
      game_type: 'WWA',
      game_id: '5d0a074567fc4d57a818ef52',
      sub_id_list: [''],
      video_data: [
        {
          video_uri: '',
          isLoop: false,
          nextIndex: -1,
        },
      ],
    },
  ],
  videoData: {
    video_uri: '',
    isLoop: false,
    nextIndex: -1,
  },
};

const state = {
  storyvideoList: [],
  selectedStoryVideo: {},
  updateLevel: {},
  updateActivity: {},
  isLoadStoryVideo: false,
  createActivity: {
    title: '',
    game_data: [{ game_type: '', game_id: '' }],
    transition: 'LearningTime',
  },
  gameData: { game_type: '', game_id: '', sub_id_list: [''] },
};
const getters = {};
const actions = {
  fetchStoryVideo({ commit, dispatch }, filter) {
    commit('storeToggleLoadStoryVideo');
    let uri = `/api/storyvideo?`;
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          uri += `&${key}=${filter[key]}`;
        }
      });
    }
    http
      .GET(uri, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeStoryVideo', response.data);
        commit('storeToggleLoadStoryVideo');
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Cannot fetch stry game. Please try again',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchSingleStoryVideo({ commit, dispatch }, id) {
    commit('storeToggleLoadStoryVideo');
    http
      .GET(`/api/storyvideo?id=${id}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeToggleLoadStoryVideo');
        commit('storeStoryVideo', response.data);
        commit('storeSelectedStoryVideobyId', id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Cannot fetch stry game please try again',
          },
          {
            root: true,
          },
        );
      });
  },
  selectStoryVideo({ commit }, id) {
    commit('storeSelectedStoryVideobyId', id);
  },
  updateLevelData({ commit }, { key, value }) {
    commit('storeUpdateLevel', {
      key,
      value,
    });
  },
  updateLevel({ dispatch, state }) {
    swal
      .fire({
        titleText: 'Confirm changes?',
        showCancelButton: true,
        type: 'question',
      })
      .then(result => {
        if (result.value) {
          http
            .PUT(
              '/api/storyvideo',
              {
                ...state.updateLevel,
              },
              {
                isAuthorization: true,
              },
            )
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Update Level Success',
                },
                {
                  root: true,
                },
              );
              router.push('/storyvideo');
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: 'Cannot update unit please try again',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  updateActivityData({ commit }, { key, value }) {
    commit('storeAcitivityData', {
      key,
      value,
    });
  },
  updateActivity({ commit, dispatch, state }) {
    http
      .PUT('/api/storyvideo/activity', state.updateActivity, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update Activity Success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleStoryVideo', state.selectedStoryVideo._id);
        commit('clearStoreUpdateActivity');
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Activity Failed Please Try again',
          },
          {
            root: true,
          },
        );
      });
  },
  deleteLevel({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Are you sure to delete this unit',
        type: 'question',
        showCancelButton: true,
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/storyvideo?id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Delete Level Success',
                },
                {
                  root: true,
                },
              );
              router.push('/storyvideos');
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: 'Delete Level Failed Please Try again',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  deleteActivity({ dispatch, state }) {
    swal
      .fire({
        titleText: 'Are you sure you want to delete this Activity ?',
        type: 'question',
        showCancelButton: true,
      })
      .then(async result => {
        if (result.value) {
          try {
            const { data: deleteActivityResult } = await http.DELETE(
              `/api/storyvideo/activity?id=${state.updateActivity.id}`,
              {
                isAuthorization: true,
              },
            );

            const rawCurrentAcitivityId = Object.values(
              state.selectedStoryVideo.activities,
            );

            const currentActivityId = rawCurrentAcitivityId.map(
              acitivity => acitivity._id,
            );
            let updateLevel = {
              ...state.selectedStoryVideo,
              id: state.selectedStoryVideo._id,
              activities: [
                ...currentActivityId.filter(
                  id => deleteActivityResult.id !== id,
                ),
              ],
            };

            delete updateLevel._id;
            const { data: resultUpdateLevel } = await http.PUT(
              '/api/storyvideo',
              updateLevel,
              {
                isAuthorization: true,
              },
            );

            dispatch(
              'appConfig/toggleAlert',
              {
                type: 'success',
                message: 'Delete Activity Success',
              },
              {
                root: true,
              },
            );
            dispatch('fetchSingleStoryVideo', state.selectedStoryVideo._id);
          } catch (error) {
            dispatch(
              'appConfig/toggleAlert',
              {
                type: 'error',
                message: 'Delete Activity Failed.Please try again',
              },
              {
                root: true,
              },
            );
          }
        }
      });
  },
  async createActivity({ dispatch, state }) {
    try {
      const { data: resultActivity } = await http.POST(
        '/api/storyvideo/activity',
        { ...defaultActivity },
        {
          isAuthorization: true,
        },
      );
      const rawCurrentAcitivityId = Object.values(
        state.selectedStoryVideo.activities,
      );
      let currentActivityId = rawCurrentAcitivityId.map(
        acitivity => acitivity._id,
      );
      let updateLevel = {
        ...state.selectedStoryVideo,
        id: state.selectedStoryVideo._id,
        activities: [...currentActivityId, resultActivity.id],
      };
      delete updateLevel._id;
      const { data: resultUpdateLevel } = await http.PUT(
        '/api/storyvideo',
        updateLevel,
        {
          isAuthorization: true,
        },
      );
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'success',
          message: 'Create Acitivity Complete',
        },
        {
          root: true,
        },
      );
      dispatch('fetchSingleStoryVideo', state.selectedStoryVideo._id);
    } catch (error) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'error',
          message: 'Create Acitivity Failed Please Try again',
        },
        {
          root: true,
        },
      );
    }
  },
  async createLevel({ dispatch, state }) {
    try {
      const { data: CreateActivityData } = await http.POST(
        '/api/storyvideo/activity',
        {
          ...defaultActivity,
        },
        {
          isAuthorization: true,
        },
      );

      const { data: CreateLevelData } = await http.POST(
        '/api/storyvideo',
        {
          img_uri: '',
          name: 'New Level',
          activities: [CreateActivityData.id],
        },
        {
          isAuthorization: true,
        },
      );

      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'success',
          message: 'Create Level Success',
        },
        {
          root: true,
        },
      );
      dispatch('fetchStoryVideo');
    } catch (error) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'error',
          message: 'Create Level Failed',
        },
        {
          root: true,
        },
      );
    }
  },
  orderLevel({ state, dispatch }, { currentIndex, newIndex }) {
    const unitList = state.storyvideoList;
    const movedItem = unitList.splice(currentIndex, 1)[0];
    unitList.splice(newIndex, 0, movedItem);

    Object.keys(unitList).forEach(index => {
      httpClient
        .PUT(
          '/api/storyvideo/units',
          {
            id: unitList[index]._id,
            index: parseInt(index),
          },
          {
            isAuthorization: true,
          },
        )
        .then(() => {})
        .catch(error => {
          throw new Error(error);
        })
        .finally(() => {
          if (index == unitList.length - 1) {
            dispatch('fetchStoryVideo');
          }
        });
    });
  },
  orderActivity({ state, dispatch }, { currentIndex, newIndex }) {
    const currentActivityOrder = state.selectedStoryVideo.activities.map(
      activity => activity._id,
    );
    const temp = currentActivityOrder[newIndex];
    currentActivityOrder[newIndex] = currentActivityOrder[currentIndex];
    currentActivityOrder[currentIndex] = temp;
    httpClient
      .PUT(
        '/api/storyvideo/level',
        {
          id: state.selectedStoryVideo._id,
          activities: currentActivityOrder,
        },
        {
          isAuthorization: true,
        },
      )
      .then(() => {
        dispatch('fetchSingleStoryVideo', state.selectedStoryVideo._id);
      })
      .catch(() => {
        dispatch('fetchSingleStoryVideo', state.selectedStoryVideo._id);
      });
  },
  clearUpdateActivityData({ commit }) {
    commit('clearStoreUpdateActivity');
  },
  clearUpdateLevelData({ commit }) {
    commit('clearStoreUpdateLevel');
  },
  fetchAllGameType({ commit }) {
    let gameType = {};
    let constants = [...Constants.GAME_TYPE];
    constants.shift();
    constants.forEach(async game => {
      const { data } = await http.GET(
        `/api/game/category?game_type=${game.value}`,
        {
          isAuthorization: true,
        },
      );
      gameType[game.value] = data.map(gameData => {
        return {
          ...gameData,
          text: gameData.category,
          value: gameData.category_id,
        };
      });
    });
    commit('storeGameType', gameType);
  },
};
const mutations = {
  storeStoryVideo(state, storyList = []) {
    if (storyList.length > 0) {
      state.storyvideoList = storyList.map(lp => ({
        ...lp,
        created_at: moment
          .unix(new Date(lp.created_at) * 0.001)
          .format('MMMM D YYYY h:mm a'),
        updated_at: moment
          .unix(new Date(lp.updated_at) * 0.001)
          .format('MMMM D YYYY h:mm a'),
      }));
    } else {
      delete state.storyvideoList;
    }
  },
  async storeSelectedStoryVideobyId(state, id) {
    try {
      const index = state.storyvideoList.findIndex(lp => lp._id === id);
      const storyvideo = state.storyvideoList[index];
      state.selectedStoryVideo = { ...storyvideo };
    } catch (error) {}
  },
  storeUpdateLevel(state, { key, value }) {
    state.updateLevel = {
      ...state.updateLevel,
      [key]: value,
    };
  },
  clearSelectStoryVideo(state) {
    state.selectedStoryVideo = {};
    state.storyvideoList = [];
  },
  storeAcitivityData(state, { key, value }) {
    state.updateActivity = {
      ...state.updateActivity,
      [key]: value,
    };
  },
  storeToggleLoadStoryVideo(state) {
    state.isLoadStoryVideo = !state.isLoadStoryVideo;
  },
  storeCreateActivity(state, { key, value }) {
    if (value) {
      state.createActivity[key] = value;
    } else {
      delete state.createActivity[key];
    }
  },
  storeLevelData(state, { key, value }) {
    if (value) {
      state.updateLevel[key] = value;
    } else {
      delete state.updateLevel[key];
    }
  },
  storeGameType(state, gameType) {
    state.gameType = gameType;
  },
  clearStoreUpdateLevel(state) {
    state.updateLevel = {};
  },
  clearStoreUpdateActivity(state) {
    var id = state.updateActivity.id;
    state.updateActivity = {};
    state.updateActivity.id = id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
