<template>
  <v-dialog
    v-model="isOpen"
    persistent
    transition="slide-y-transition"
    width="60%"
  >
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="red" fab icon v-on:click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-text>
        <v-list>
          <v-list-tile
            v-for="dictionary in dictionaryStore.dictionaryList"
            :key="dictionary._id"
            v-on:click="() => onSelect(dictionary._id)"
          >
            <v-list-tile-avatar>
              <v-icon>arrow_right</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ dictionary.word }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'Dictionary',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Untitled',
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      dictionaryStore: state => state.dictionary,
    }),
  },
  methods: {
    ...mapActions('dictionary', ['fetchDictionary']),
    onChangeFilter(e, key) {
      if (e) {
        this.filter = {
          ...this.filter,
          [key]: e,
        };
      } else {
        delete this.filter[key];
      }

      this.fetchDictionary({
        page: 1,
        limit: 0,
        filter: this.filter,
      });
    },
  },
  mounted() {
    this.fetchDictionary({
      page: 1,
      limit: 0,
    });
  },
};
</script>
