import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
import Constants from '../../constants';
import httpClient from '../../utils/httpClient';

const defaultActivity = {
  title: 'New Activity',
  transition: 'Random',
  game_data: [
    {
      game_type: 'WWA',
      game_id: '5d0a074567fc4d57a818ef52',
      sub_id_list: [''],
      video_data: [
        {
          video_uri: '',
          isLoop: false,
          nextIndex: -1,
        },
      ],
    },
  ],
  videoData: {
    video_uri: '',
    isLoop: false,
    nextIndex: -1,
  },
  config: {},
};

const state = {
  LEARNING_PATH_LIST: [],
  selectedLearningpath: {},
  updateUnit: {},
  updateActivity: {},
  isLoadLearnPath: false,
  createActivity: { title: '', game_data: [{ game_type: '', game_id: '' }] },
  updateLesson: {},
  gameData: { game_type: '', game_id: '', sub_id_list: [''] },
  minichatVideoURIs: [],
  gameType: {},
};
const getters = {};
const actions = {
  fetchMinichatVideoURIs({ commit, dispatch }, minichat_id) {
    http
      .GET(`/api/minichat/node/getVideoURIs?minichat_id=${minichat_id}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeMinichatVideoURIs', response.data.videoURIs);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Minichat VideoURIs might not available in this time. Please check again later.',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchLearningPathList({ commit }, filter) {
    http
      .GET(`/api/learningpath/list?${filter ? filter : ''}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_LEARNING_PATH_LIST', data);
      })
      .catch(error => {
        throw error;
      });
  },
  fetchLP({ commit, dispatch }, filter) {
    commit('storeToggleLoadLP');
    let uri = `/api/learningpath?isWeb=true&`;

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          uri += `&${key}=${filter[key]}`;
        }
      });
    }
    http
      .GET(uri, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeLearningPath', response.data);
        commit('storeToggleLoadLP');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Cannot fetch learning path please try again',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchSingleLP({ commit, dispatch }, id) {
    commit('storeToggleLoadLP');
    return http
      .GET(`/api/learningpath?id=${id}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeToggleLoadLP');
        commit('SET_LEARNING_PATH_LIST', response.data);
        commit('storeSelectedLearningPathbyId', id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Cannot fetch learning path please try again',
          },
          {
            root: true,
          },
        );
      });
  },
  selectLearningPath({ commit }, id) {
    commit('storeSelectedLearningPathbyId', id);
  },
  updateUnitData({ commit }, { key, value }) {
    commit('storeUpdateUnit', {
      key,
      value,
    });
  },
  updateUnit({ dispatch, state }) {
    swal
      .fire({
        titleText: 'Confirm changes?',
        showCancelButton: true,
        type: 'question',
      })
      .then(result => {
        if (result.value) {
          http
            .PUT(
              '/api/learningpath/units',
              {
                ...state.updateUnit,
              },
              {
                isAuthorization: true,
              },
            )
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Update Unit Success',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchSingleLP', state.selectedLearningpath._id);
              // router.push('/learningpaths');
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: 'Cannot update unit please try again',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  updateActivityData({ commit }, { key, value }) {
    commit('storeAcitivityData', {
      key,
      value,
    });
  },
  updateActivity({ commit, dispatch, state }) {
    delete state.updateActivity.created_at;
    delete state.updateActivity.updated_at;
    http
      .PUT('/api/learningpath/activity', state.updateActivity, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update Activity Success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleLP', state.selectedLearningpath._id);
        commit('clearStoreUpdateActivity');
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Activity Failed Please Try again',
          },
          {
            root: true,
          },
        );
      });
  },
  deleteActivity({ dispatch, state }, lessonId) {
    swal
      .fire({
        titleText: 'Are you sure you want to delete this Activity ?',
        type: 'question',
        showCancelButton: true,
      })
      .then(async result => {
        if (result.value) {
          http
            .DELETE(
              `/api/learningpath/activity?id=${state.updateActivity.id}`,
              {
                isAuthorization: true,
              },
            )
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Delete Activity Success',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchSingleLP', state.selectedLearningpath._id);
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: 'Delete Activity Failed Please Try again',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  createActivityData({ commit }, { key, value }) {
    commit('storeCreateActivity', {
      key,
      value,
    });
  },
  async createActivity({ dispatch, state }, lessonId) {
    try {
      const { data: resultActivity } = await http.POST(
        '/api/learningpath/activity',
        { ...defaultActivity },
        {
          isAuthorization: true,
        },
      );
      const { data: currentLesson } = await http.GET(
        `/api/learningpath/lesson?id=${lessonId}`,
        {
          isAuthorization: true,
        },
      );

      let updateLesson = {
        id: currentLesson[0]._id,
        activities: [...currentLesson[0].activities, resultActivity.id],
      };
      await http.PUT('/api/learningpath/lesson', updateLesson, {
        isAuthorization: true,
      });

      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'success',
          message: 'Create Acitivity Complete',
        },
        {
          root: true,
        },
      );
      dispatch('fetchSingleLP', state.selectedLearningpath._id);
    } catch (error) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'error',
          message: 'Create Acitivity Failed Please Try again',
        },
        {
          root: true,
        },
      );
    }
  },
  updateLessonData({ commit }, { key, value }) {
    if ((key === 'isGuestPlayable' || key === 'skip_lesson') && !value) {
      value = false;
    }
    commit('storeLessonData', {
      key,
      value,
    });
  },
  updateLesson({ commit, dispatch, state }) {
    http
      .PUT(
        '/api/learningpath/lesson',
        {
          ...state.updateLesson,
        },
        {
          isAuthorization: true,
        },
      )
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'update Lesson Complete',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleLP', state.selectedLearningpath._id);
        commit('clearStoreUpdateLesson');
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Lesson failed',
          },
          {
            root: true,
          },
        );
      });
  },
  async createLesson({ dispatch, state }) {
    try {
      const { data: currentUnitData } = await http.GET(
        `/api/learningpath/units?id=${state.selectedLearningpath._id}`,
        {
          isAuthorization: true,
        },
      );

      const { data: CreateActivityData } = await http.POST(
        '/api/learningpath/activity',
        {
          ...defaultActivity,
        },
        {
          isAuthorization: true,
        },
      );

      const { data: CreateLessonData } = await http.POST(
        '/api/learningpath/lesson',
        {
          img_uri: '',
          name: 'New Lesson',
          activities: [CreateActivityData.id],
        },
        {
          isAuthorization: true,
        },
      );

      const updateLessonData = [
        ...currentUnitData[0].lessons,
        CreateLessonData.id,
      ];

      const { data: UpdateUnitData } = await http.PUT(
        '/api/learningpath/units',
        {
          id: state.selectedLearningpath._id,
          lessons: updateLessonData,
        },
        {
          isAuthorization: true,
        },
      );
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'success',
          message: 'Create Lesson Success',
        },
        {
          root: true,
        },
      );
      dispatch('fetchSingleLP', state.selectedLearningpath._id);
    } catch (error) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'error',
          message: 'Create Lesson Failed',
        },
        {
          root: true,
        },
      );
    }
  },
  deleteLesson({ dispatch, state }, lessonId) {
    swal
      .fire({
        titleText: 'Are you sure to delete Lesson',
        type: 'question',
        showCancelButton: true,
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE('/api/learningpath/lesson?id=' + lessonId, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Delete Lesson Success',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchSingleLP', state.selectedLearningpath._id);
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: 'Delete Lesson Failed',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  async orderUnit({ state, dispatch }, { currentIndex, newIndex }) {
    const unitList = state.LEARNING_PATH_LIST;
    const movedItem = unitList.splice(currentIndex, 1)[0];
    unitList.splice(newIndex, 0, movedItem);

    Object.keys(unitList).forEach(index => {
      httpClient
        .PUT(
          '/api/learningpath/units',
          {
            id: unitList[index]._id,
            index: parseInt(index),
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {})
        .catch(error => {})
        .finally(() => {
          if (index == unitList.length - 1) {
            dispatch('fetchLearningPathList');
          }
        });
    });
  },
  orderActivity({ state, dispatch }, { currentIndex, newIndex, lessonId }) {
    const index = state.selectedLearningpath.lessons.findIndex(
      lesson => lesson._id === lessonId,
    );
    const currentActivityOrder = state.selectedLearningpath.lessons[
      index
    ].activities.map(activity => activity._id);
    const temp = currentActivityOrder[newIndex];
    currentActivityOrder[newIndex] = currentActivityOrder[currentIndex];
    currentActivityOrder[currentIndex] = temp;
    httpClient
      .PUT(
        '/api/learningpath/lesson',
        {
          id: state.selectedLearningpath.lessons[index]._id,
          activities: currentActivityOrder,
        },
        {
          isAuthorization: true,
        },
      )
      .then(({ data }) => {
        dispatch('fetchSingleLP', state.selectedLearningpath._id);
      })
      .catch(error => {
        dispatch('fetchSingleLP', state.selectedLearningpath._id);
      });
  },
  orderLesson({ state, dispatch }, { currentIndex, newIndex, learningPathId }) {
    const currentLessonOrder = state.selectedLearningpath.lessons.map(
      lesson => lesson._id,
    );
    const temp = currentLessonOrder[newIndex];
    currentLessonOrder[newIndex] = currentLessonOrder[currentIndex];
    currentLessonOrder[currentIndex] = temp;
    httpClient
      .PUT(
        '/api/learningpath/units',
        {
          id: learningPathId,
          lessons: currentLessonOrder,
        },
        {
          isAuthorization: true,
        },
      )
      .then(({ data }) => {
        dispatch('fetchSingleLP', state.selectedLearningpath._id);
      })
      .catch(error => {
        dispatch('fetchSingleLP', state.selectedLearningpath._id);
      });
  },
  async createUnit({ dispatch }) {
    try {
      const { data: createActivity } = await http.POST(
        '/api/learningpath/activity',
        defaultActivity,
        {
          isAuthorization: true,
        },
      );
      const { data: CreateLesson } = await http.POST(
        '/api/learningpath/lesson',
        {
          name: 'Untitle',
          img_uri: '',
          activities: [createActivity.id],
        },
        {
          isAuthorization: true,
        },
      );

      const unitsTemplete = {
        name: 'Untitle',
        img_uri: '',
        lessons: [CreateLesson.id],
      };
      const { data: CreateUnit } = await http.POST(
        '/api/learningpath/units',
        unitsTemplete,
        {
          isAuthorization: true,
        },
      );
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'success',
          message: 'Create Unit Success',
        },
        {
          root: true,
        },
      );
      dispatch('fetchLearningPathList');
    } catch (error) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'error',
          message: 'Create Unit failed',
        },
        {
          root: true,
        },
      );
    }
  },
  deleteUnit({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Are you sure to delete this unit',
        type: 'question',
        showCancelButton: true,
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/learningpath/units?id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Delete Unit Success',
                },
                {
                  root: true,
                },
              );
              router.push('/learningpaths');
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: 'Delete Unit Failed Please Try again',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  clearUpdateActivityData({ commit }) {
    commit('clearStoreUpdateActivity');
  },
  clearUpdateLessonData({ commit }) {
    commit('clearStoreUpdateLesson');
  },
  clearUpdateUnitData({ commit }) {
    commit('clearStoreUpdateUnit');
  },
  clearMinichatVideoURIs({ commit }) {
    commit('clearMinichatVideoURIs');
  },
  fetchAllGameType({ commit }) {
    commit('storeToggleLoadLP');
    const constants = { ...Constants.GAME_TYPE_ABBREV_ENUMS };
    const game_type = Object.values(constants)
      .map((value, index) => {
        return index === Object.keys(constants).length - 1
          ? `game_type[]=${value}`
          : `game_type[]=${value}&`;
      })
      .join('');
    return http
      .GET(`/api/game/web-category?${game_type}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('storeToggleLoadLP');
        commit('storeGameType', data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Fetch Game Type Failed, Please Try again',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  SET_LEARNING_PATH_LIST(state, payload) {
    state.LEARNING_PATH_LIST = payload;
  },
  storeLearningPath(state, lpList = []) {
    if (lpList.length > 0) {
      state.LEARNING_PATH_LIST = lpList.map(lp => ({
        ...lp,
        created_at: moment
          .unix(new Date(lp.created_at) * 0.001)
          .format('MMMM D YYYY h:mm a'),
        updated_at: moment
          .unix(new Date(lp.updated_at) * 0.001)
          .format('MMMM D YYYY h:mm a'),
      }));
    } else {
      delete state.LEARNING_PATH_LIST;
    }
  },
  async storeSelectedLearningPathbyId(state, id) {
    try {
      const index = state.LEARNING_PATH_LIST.findIndex(lp => lp._id === id);
      const learningpath = state.LEARNING_PATH_LIST[index];
      state.selectedLearningpath = { ...learningpath };
    } catch (error) {}
  },
  storeUpdateUnit(state, { key, value }) {
    state.updateUnit = {
      ...state.updateUnit,
      [key]: value,
    };
  },
  storeMinichatVideoURIs(state, value) {
    if (value) {
      state.minichatVideoURIs = value;
    } else {
      delete state.minichatVideoURIs;
    }
  },
  clearMinichatVideoURIs(state) {
    state.minichatVideoURIs = [];
  },
  clearSelectLearningpath(state) {
    state.selectedLearningpath = {};
    state.LEARNING_PATH_LIST = [];
  },
  storeAcitivityData(state, { key, value }) {
    state.updateActivity = {
      ...state.updateActivity,
      [key]: value,
    };
  },
  storeToggleLoadLP(state) {
    state.isLoadLearnPath = !state.isLoadLearnPath;
  },
  storeCreateActivity(state, { key, value }) {
    if (value) {
      state.createActivity[key] = value;
    } else {
      delete state.createActivity[key];
    }
  },
  storeLessonData(state, { key, value }) {
    state.updateLesson = {
      ...state.updateLesson,
      [key]: value,
    };
  },
  storeGameType(state, gameType) {
    state.gameType = gameType;
  },
  clearStoreUpdateUnit(state) {
    state.updateUnit = {};
  },
  clearStoreUpdateLesson(state) {
    state.updateLesson = {};
  },
  clearStoreUpdateActivity(state) {
    var id = state.updateActivity.id;
    state.updateActivity = {};
    state.updateActivity.id = id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
