<template>
  <Modal
    v-if="isOpen"
    :isOpen="isOpen"
    :close="close"
    :isLastStep="valid"
    :save="save"
    type="create"
    :title="title"
  >
    <div>
      <v-btn icon color="green" v-on:click="addData()">
        <v-icon>add</v-icon>
      </v-btn>
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="n in data.length">
            <v-stepper-step
              :key="`${n}-step`"
              :complete="currentStep > n"
              :step="n"
              editable
            >
              <!-- Sticker {{ n }} -->
            </v-stepper-step>

            <v-divider v-if="n !== data.length" :key="n"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-for="n in data.length"
            :key="`${n}-content`"
            :step="n"
          >
            <v-form>
              <v-card v-if="currentStep - 1 > -1">
                <v-toolbar dark>
                  <v-toolbar-title>Image {{ currentStep }}</v-toolbar-title>
                  <v-btn
                    color="error"
                    small
                    absolute
                    fab
                    right
                    @click="onClickRemoveStickerByIndex(currentStep - 1)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container v-if="data[currentStep - 1]">
                  <v-layout>
                    <v-flex>
                      <v-form ref="formUpdate" v-model="valid" lazy-validation>
                        <div v-if="title === 'Tts Image List Manager'">
                          <TtsImage
                            :data="data"
                            :index="currentStep - 1"
                          ></TtsImage>
                        </div>
                        <div v-if="title === 'Word List Manager'">
                          <WordImage
                            :data="data"
                            :index="currentStep - 1"
                          ></WordImage>
                        </div>
                      </v-form>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="currentStep - 1 > 0"
                  color="red"
                  dark
                  @click="onClickBackStep()"
                  >Previous</v-btn
                >
                <v-btn
                  v-if="currentStep - 1 < data.length - 1"
                  color="primary"
                  dark
                  @click="onClickNextStep()"
                  >Next</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </Modal>
</template>

<script>
import Modal from '../components/Modal.vue';
import TtsImage from '../views/ShortStory/components/TtsImage.vue';
import WordImage from '../views/ShortStory/components/WordImage.vue';

import swal from 'sweetalert2';

export default {
  name: 'ModalStickers',
  components: {
    Modal,
    TtsImage,
    WordImage,
  },
  data() {
    return {
      currentStep: -1,
      data: [],
      valid: true,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Manager',
    },
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
    defaultData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    limitData() {
      if (this.title === 'Tts Image List Manager') {
        return 10;
      } else return 5;
    },
    addData() {
      const defaultData = JSON.parse(JSON.stringify(this.defaultData));
      if (this.data.length < this.limitData()) {
        this.data.push(defaultData);
        this.currentStep = this.data.length;
      }
    },
    onClickRemoveStickerByIndex(index) {
      swal
        .fire({
          titleText: `Remove data at index ${index + 1}?`,
          text: 'This operation cannot be undone. Would you like to proceed?',
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.data.splice(index, 1);
            if (this.currentStep - 1 > 0) {
              this.currentStep -= 1;
            }
          }
        });
    },
    onClickNextStep() {
      if (this.currentStep - 1 < this.data.length - 1) {
        this.currentStep += 1;
      }
    },
    onClickBackStep() {
      if (this.currentStep - 1 >= 0) {
        this.currentStep -= 1;
      }
    },
    save() {
      if (this.data.length) {
        if (this.$refs.formUpdate[0].validate()) {
          this.currentStep = 1;
          this.$emit('save', [...this.data]);
        }
      } else {
        this.currentStep = -1;
        this.$emit('save', [...this.data]);
      }
    },
    close() {
      if (this.data.length) this.currentStep = 1;
      else this.currentStep = -1;
      this.$emit('close');
    },
  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.dataArray));
    if (this.data.length) this.currentStep = 1;
  },
};
</script>
