import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';
import user from './userInfo';
const auth = user.state;

const state = {
  adminIsLock: false,
  infoGame: '',
  homePageTopicList: [],
  homePageList: [],
  selectedHomePageData: {},
  selectedHomePageDetail: {},
  selectedHomePageIndex: -1,
  updatePageDetail: {},
  updatePageGroup: {},
  updatePageTopic: {},
  defaultHomeDataCreate: {
    group_id: '',
    game_id: '',
    format: 0,
    img_uri: '',
    title: '',
    type: 0,
    data: {
      _id: '',
      name: '',
    },
  },
  defaultCreateHomeDataList: {
    topic_id: '',
    title: '',
    cell_datas: [],
    selected_cell: [],
  },
  defaultCreateHomeDataTopic: {
    title: '',
    group_datas: [],
    index: -1,
  },
};
const getters = {};
const actions = {
  async selectedHomeGroupDataById({ commit, dispatch }, id) {
    http
      .GET(`/api/homepage/topic?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const group_datas = data.group_datas.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        const formatData = { ...data, group_datas: group_datas };
        dispatch('setStateByKey', {
          statekey: 'homePageList',
          key: null,
          value: formatData,
        });
        dispatch('setStateByKey', {
          statekey: 'updatePageTopic',
          key: 'id',
          value: formatData._id,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch home page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async fetchHomePageTopicListAll({ commit, dispatch }) {
    http
      .GET(`/api/homepage/topic?isWeb=true&page=1`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('setStateByKey', {
          statekey: 'homePageTopicList',
          key: null,
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch home page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  // async fetchHomePageListAll({ commit, dispatch }) {
  //   http
  //     .GET(`/api/homepage/homegroupdata?isWeb=true`, {
  //       isAuthorization: true,
  //     })
  //     .then(({ data }) => {
  //       const formatData = data.map(e => ({
  //         ...e,
  //         created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
  //         updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
  //       }));
  //       dispatch('setStateByKey', {
  //         statekey: 'HomePageList',
  //         key: null,
  //         value: formatData,
  //       });
  //     })
  //     .catch(err => {
  //       dispatch(
  //         'appConfig/toggleAlert',
  //         {
  //           message:
  //             'Cannot fetch home page game in this time. please try again.',
  //           type: 'error',
  //         },
  //         {
  //           root: true,
  //         },
  //       );
  //       throw new Error(err);
  //     });
  // },
  async fetchHomePageData({ commit, dispatch }, id) {
    http
      .GET(`/api/homepage/homegroupdata?id=${id}&isWeb=${true}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('setStateByKey', {
          statekey: 'selectedHomePageData',
          key: null,
          value: data,
        });
        dispatch('checkAdminIsLock');
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch home page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async createPageTopic({ commit, dispatch }, createData) {
    http
      .POST(`/api/homepage/topic`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchHomePageTopicListAll');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Home Page Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create home group page in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async createGroupPageData({ commit, dispatch }, createData) {
    http
      .POST(`/api/homepage/homegroupdata`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('selectedHomeGroupDataById', state.updatePageTopic.id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Home Page Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create home group page in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async createPageData({ commit, dispatch }, createData) {
    http
      .POST(`/api/homepage`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Home Page Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create home group page in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async deletePageTopicData({ commit, dispatch }, id) {
    http
      .DELETE(`/api/homepage/topic?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchHomePageTopicListAll');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete Home Group Page Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete home group page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async deletePageGroupData({ commit, dispatch }, id) {
    const topic_id = state.updatePageTopic.id;
    http
      .DELETE(`/api/homepage/homegroupdata?id=${id}&topic_id=${topic_id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('selectedHomeGroupDataById', state.updatePageTopic.id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete Home Group Page Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete home group page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async deletePageHomeData({ commit, dispatch }, id) {
    http
      .DELETE(`/api/homepage?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'selectedHomePageIndex');
        dispatch('fetchHomePageData', state.updatePageGroup.id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete Home Page Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete home group page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async updatePageTopicData({ commit, dispatch }, updateData) {
    updateData ? updateData : (updateData = state.updatePageTopic);
    http
      .PUT(`/api/homepage/topic`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (state.updatePageTopic.id) {
          dispatch('selectedHomeGroupDataById', state.updatePageTopic.id);
          dispatch('clearStateByKey', 'updatePageTopic');
        } else dispatch('fetchHomePageTopicListAll');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update Home Page Topic Successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot update home page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async updatePageData({ commit, dispatch }, updateData) {
    http
      .PUT(`/api/homepage`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchHomePageData', state.updatePageGroup.id);
        dispatch('clearStateByKey', 'updatePageDetail');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update Home Page Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot update home page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async updatePageGroupData({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updatePageGroup;
    http
      .PUT(`/api/homepage/homegroupdata`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('selectedHomeGroupDataById', state.updatePageTopic.id);
        dispatch('clearStateByKey', 'updatePageGroup');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update Home Group Data Successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot update home page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  checkAdminIsLock({ dispatch }) {
    const role_id = auth.userData.role_id;
    const isLock = role_id !== 100 ? state.selectedHomePageData.isLock : false;
    dispatch('setStateByKey', {
      statekey: 'adminIsLock',
      value: isLock,
    });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'string':
          state[statekey] = value;
          break;
        case 'number':
          state[statekey] = value;
          break;
        case 'boolean':
          state[statekey] = value;
          break;
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = [];
    } else {
      switch (typeof state[statekey]) {
        case 'string':
          state[statekey] = '';
          break;
        case 'number':
          state[statekey] = -1;
          break;
        case 'boolean':
          state[statekey] = false;
          break;
        case 'object':
          for (const [key, value] of Object.entries(state[statekey])) {
            if (key != 'id') {
              delete state[statekey][key];
            }
          }
          break;
      }
    }
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = value => {
  switch (typeof value) {
    case 'string':
      return '';
    case 'number':
      return -1;
    case 'boolean':
      return false;
    case 'object':
      return {};
    default:
      return '';
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
