var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"mr-2":"","xs3":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Set Detail")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.dragComboStore.selectedDragCombo.sets[
            _vm.dragComboStore.selectedSetIndex
          ]._id,"color":"green"},on:{"click":function($event){return _vm.onClickUpdateSet()}}},[_c('v-icon',[_vm._v("save")]),_vm._v("Save ")],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-xs":"","text-xs-center":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('v-text-field',{attrs:{"label":"_id","value":_vm.dragComboStore.selectedDragCombo.sets[
                    _vm.dragComboStore.selectedSetIndex
                  ]._id,"disabled":""}}),_c('v-text-field',{attrs:{"label":"Name","value":_vm.dragComboStore.selectedDragCombo.sets[
                    _vm.dragComboStore.selectedSetIndex
                  ].name,"rules":[_vm.rules.required]},on:{"change":function (e) { return _vm.onchangeSet('name', e); }}})],1),_c('v-flex',[(_vm.getNodeLength < 10)?_c('v-btn',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.createNodes()}}},[_c('v-icon',[_vm._v("add")]),_vm._v("Create Node ")],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":[{ text: 'Sets', value: 'set', sortable: false }],"items":_vm.dragComboStore.selectedDragCombo.sets[
                    _vm.dragComboStore.selectedSetIndex
                  ].nodes,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:props.index === _vm.dragComboStore.selectedNodeIndex
                        ? 'active'
                        : 'inactive',on:{"click":function($event){return _vm.seleteNodes(props.item._id, props.index)}}},[_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(props.item.name)+" ")]),_c('td',[_c('v-btn',{attrs:{"color":"error","small":"","fab":"","left":""},on:{"click":function($event){return _vm.deleteNodes(props.item._id)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)])]}}])})],1)],1)],1)],1)],1)],1),_c('ModalCreate',{attrs:{"isOpen":_vm.isCreate,"title":_vm.title},on:{"close":function($event){return _vm.closeCreate()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }