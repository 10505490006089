import http from '../../utils/httpClient';
import swal from 'sweetalert2';
import moment from 'moment';
import { LANGUAGES } from '../../constants/localization/languageList';
const state = {
  isValidKey: false,
  language: [],
  languageJSONtoCSV: [],
  uploadResult: {},
  updateLanguage: {},
  updateLanguagePrev: {},
  subtitleLocalizationData: {},
  isValidSubtitle: false,
  existingSubtitleLocalizationList: [],
  selectedSubtitleLocalization: [],
};
const getters = {};
const actions = {
  validateDuplicateSubtitle({ commit }, English) {
    http
      .GET(
        `/api/subtitle_video_localization/duplicateSubtitle?English=${English}`,
        {
          isAuthorization: true,
        },
      )
      .then(response => {
        if (response.data.status === 'OK') {
          commit('SET_DUPLICATE_SUBTITLE_RESULT', true);
        }
      })
      .catch(error => {
        if (error.response.status === 409) {
          commit('SET_DUPLICATE_SUBTITLE_RESULT', false);
          commit(
            'SET_EXISTING_SUBTITLE_LOCALIZATION_LIST',
            error.response.data.content,
          );
        }
      });
  },
  getSubtitleLocalizationDataByEnglish({ commit }, English) {
    http
      .GET(`/api/subtitle_video_localization/filter?English=${English}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_SELECTED_SUBTITLE_LOCALIZATION', data[0]);
      })
      .catch(error => {});
  },
  getSubtitleLocalizationDataBySubtitleIDAndEnglish(
    { commit },
    { subtitle_id, English },
  ) {
    http
      .GET(
        `/api/subtitle_video_localization/filter?subtitle_id=${subtitle_id}&English=${English}`,
        { isAuthorization: true },
      )
      .then(response => {
        commit('storeSubtitleLocalization', response.data[0]);
      })
      .catch(error => {});
  },
  checkDuplicateKey({ commit }, video_uri) {
    http
      .GET(
        `/api/subtitle_video_localization/duplicate?video_uri=${video_uri}`,
        {
          isAuthorization: true,
        },
      )
      .then(response => {
        commit('storeDuplicateID', response.data.valid);
      })
      .catch(error => {
        if (error.response.status === 409) {
          commit('storeDuplicateID', error.response.data.valid);
        }
      });
  },
  fetchLanguage({ commit, dispatch }, language) {
    http
      .GET(`/api/subtitle_video_localization/specific?language=${language}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const languageData = data.data.map(language => ({
          ...language,
          created_at: moment(language.created_at).format('YYYY MMMM D h:mm A'),
          updated_at: moment(language.updated_at).format('YYYY MMMM D h:mm A'),
        }));
        commit('storeLanguage', languageData);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch language at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  addLanguage({ dispatch }, { languageData, selectedLanguage }) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Adding Language',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/subtitle_video_localization', languageData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Language Successfully Created',
          },
          {
            root: true,
          },
        );
        dispatch('fetchLanguage', selectedLanguage);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  editLanguage({ dispatch }, { updateData, selectedLanguage }) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Updating Language',
      },
      {
        root: true,
      },
    );
    http
      .PATCH('/api/subtitle_video_localization', updateData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Language Successfully Updated',
          },
          {
            root: true,
          },
        );
        dispatch('fetchLanguage', selectedLanguage);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteLanguage({ dispatch }, { id, English, selectedLanguage }) {
    swal
      .fire({
        titleText: `Confirm Delete '${English}'`,
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'red',
        confirmButtonText: 'Remove',
        showCancelButton: true,
        type: 'warning',
      })
      .then(response => {
        if (response.value) {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'info',
              message: 'Deleting Language',
            },
            {
              root: true,
            },
          );
          http
            .DELETE(`/api/subtitle_video_localization?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Language Successfully Deleted',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchLanguage', selectedLanguage);
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: error.response.data,
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  statePreviousData({ commit }, { key, value }) {
    commit('storeUpdateLanguagePrevData', {
      key,
      value,
    });
  },
  stateEditLanguage({ commit }, { key, value }) {
    commit('storeUpdateLanguageData', {
      key,
      value,
    });
  },
  validateLanguage(value) {
    let key = Object.keys(value)[1];
    const languageList = LANGUAGES;
    if (languageList.includes(key)) {
      return key;
    }
  },
  clearUpdateState({ commit }) {
    commit('clearUpdateState');
  },
  clearUpdatePrevState({ commit }) {
    commit('clearUpdatePrevState');
  },
  resetExistingSubtitleLocalizationList({ commit }) {
    commit('RESET_EXISTING_SUBTITLE_LOCALIZATION_LIST');
  },
  resetSelectedSubtitleLocalization({ commit }) {
    commit('RESET_SELECTED_SUBTITLE_LOCALIZATION');
  },
};
const mutations = {
  SET_EXISTING_SUBTITLE_LOCALIZATION_LIST(state, value) {
    if (value) {
      state.existingSubtitleLocalizationList = value;
    } else {
      delete state.existingSubtitleLocalizationList;
    }
  },
  RESET_EXISTING_SUBTITLE_LOCALIZATION_LIST(state) {
    state.existingSubtitleLocalizationList = [];
  },
  SET_DUPLICATE_SUBTITLE_RESULT(state, payload) {
    state.isValidSubtitle = payload;
  },
  SET_SELECTED_SUBTITLE_LOCALIZATION(state, payload) {
    state.selectedSubtitleLocalization = payload;
  },
  RESET_SELECTED_SUBTITLE_LOCALIZATION(state) {
    state.selectedSubtitleLocalization = [];
  },
  storeSubtitleLocalization(state, payload) {
    state.subtitleLocalizationData = payload;
  },
  storeDuplicateID(state, video_uri) {
    state.isValidKey = video_uri;
  },
  storeLanguage(state, value) {
    if (value) {
      state.language = value;
    } else {
      delete state.language;
    }
  },
  storeUploadResult(state, value) {
    if (value) {
      state.uploadResult = value;
    } else {
      delete state.uploadResult;
    }
  },
  storeLanguageJSONtoCSV(state, value) {
    if (value) {
      state.languageJSONtoCSV = value;
    } else {
      delete state.languageJSONtoCSV;
    }
  },
  storeUpdateLanguagePrevData(state, { key, value }) {
    state.updateLanguagePrev = {
      ...state.updateLanguagePrev,
      [key]: value,
    };
  },
  storeUpdateLanguageData(state, { key, value }) {
    state.updateLanguage = {
      ...state.updateLanguage,
      [key]: value,
    };
  },
  clearUpdateState(state) {
    state.updateLanguage = {};
  },
  clearUpdatePrevState(state) {
    state.updateLanguagePrev = {};
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
