<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Content Navigation Manager</v-toolbar-title>
          <v-btn small fab icon @click="syncIndexWithLearningPath()">
            <v-icon>sync_alt</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="openModalCreate()"> Create </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="header"
          :items="contentNavigationStore"
          :search="search"
          :pagination.sync="pagination"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td class="text-xs-center">{{ props.item.unit_id }}</td>
            <td class="text-xs-center">{{ props.item.unit_name }}</td>
            <td class="text-xs-center">
              {{ props.item.unit_index }}
            </td>
            <td class="text-xs-center">
              <span>
                {{ props.item.content }}
              </span>
            </td>
            <!-- <td class="text-xs-center">{{ props.item.content }}</td> -->
            <td class="justify-center layout px-0">
              <v-icon small class="mr-2" @click="onClickEdit(props.item)"
                >edit</v-icon
              >
              <v-icon
                v-if="userLevel === 99"
                small
                @click="onClickDelete(props.item._id, props.item.unit_name)"
                >delete</v-icon
              >
            </td>
          </template>
          <template v-slot:no-data v-if="!contentNavigationStore">
            <v-alert :value="true" color="error" icon="warning">
              Sorry, nothing to display here :(
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <Modal
      v-if="isOpenCreateModal"
      :isOpen="isOpenCreateModal"
      :save="onSaveCreate"
      :close="closeModalCreate"
      :isLastStep="create.valid"
      :width="'60%'"
      title="Create"
    >
      <v-form ref="form" v-model="create.valid">
        <v-autocomplete
          :rules="requiredRule"
          label="Select Unit"
          v-model="create.model.unit_id"
          :items="availableUnitListStore"
          item-text="name"
          item-value="_id"
          chips
          hide-details
          hide-no-data
          hide-selected
        ></v-autocomplete>
        <div>
          <tiptap-vuetify
            v-model="create.model.content"
            :extensions="extensions"
          />
        </div>
      </v-form>
    </Modal>
    <Modal
      required
      v-if="isOpenEditModal"
      :isOpen="isOpenEditModal"
      :save="onSaveEdit"
      :close="closeModalEdit"
      :isLastStep="edit.valid"
      :width="'60%'"
      title="Edit"
    >
      <v-form ref="form" v-model="edit.valid">
        <v-text-field
          disabled
          readonly
          label="Unit Name"
          :value="edit.model.unit_name"
        ></v-text-field>
        <div>
          <tiptap-vuetify
            v-model="edit.model.content"
            :extensions="extensions"
          />
        </div>
      </v-form>
    </Modal>
  </Container>
</template>
<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  CodeBlock,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify';
import { mapState, mapActions } from 'vuex';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import swal from 'sweetalert2';
export default {
  name: 'ContentNavigation',
  components: { Container, Modal, TiptapVuetify },
  methods: {
    ...mapActions('content_navigation', [
      'fetchContentNavigation',
      'fetchAvailableUnitList',
      'createContentNavigation',
      'updateContentNavigation',
      'resetAvailableUnitList',
      'deleteContentNavigation',
      'syncLearningPathContent',
    ]),
    alertSyncData() {
      swal
        .fire({
          titleText: `Sync Content Navigation with Learning Path`,
          text: 'This operation will sync data e.g. name, index from learning path.',
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          cancelButtonText: 'Later',
          showCancelButton: true,
          type: 'warning',
        })
        .then(async response => {
          if (response.value) {
            const unit_id_list = await this.formatUnitIDList();
            await this.syncLearningPathContent({ unit_id_list: unit_id_list });
          }
        });
    },
    async syncIndexWithLearningPath() {
      this.alertSyncData();
    },
    async formatUnitIDList() {
      let unitList = [];
      this.contentNavigationStore.forEach(async element => {
        const unit_id = element.unit_id;
        await unitList.push(unit_id);
      });
      return unitList;
    },
    openModalEdit() {
      this.isOpenEditModal = true;
    },
    closeModalEdit() {
      this.isOpenEditModal = false;
    },
    replaceBoldTag(content) {
      const head = content.replaceAll('<strong>', '<b>');
      const tail = head.replaceAll('</strong>', '</b>');
      return tail;
    },
    replaceItalicTag(content) {
      const head = content.replaceAll('<em>', '<i>');
      const tail = head.replaceAll('</em>', '</i>');
      return tail;
    },
    replaceHardBreakTag(content) {
      const head = content.replaceAll('<br>', '\\n');
      return head;
    },
    replaceParagraphTag(content) {
      const head = content.replaceAll('<p>', '');
      const tail = head.replaceAll('</p>', '');
      return tail;
    },
    async validateAndReplaceTags(content) {
      const isContentHasParagraphTag =
        content.search('<p>') !== -1 ? true : false;
      const isContentHasItalicTag =
        content.search('<em>') !== -1 ? true : false;
      const isContentHasBoldTag =
        content.search('<strong>') !== -1 ? true : false;
      const isContentHasHardBreakTag =
        content.search('<br>') !== -1 ? true : false;
      if (isContentHasBoldTag) {
        content = await this.replaceBoldTag(content);
      }
      if (isContentHasItalicTag) {
        content = await this.replaceItalicTag(content);
      }
      if (isContentHasHardBreakTag) {
        content = await this.replaceHardBreakTag(content);
      }
      if (isContentHasParagraphTag) {
        content = await this.replaceParagraphTag(content);
      }
      return await content;
    },
    async onSaveEdit() {
      const model = this.edit.model;
      const formatContent = this.validateAndReplaceTags(
        this.edit.model.content,
      );
      this.edit.model.content = await formatContent;
      await this.updateContentNavigation(model);
      this.closeModalEdit();
    },
    openModalCreate() {
      this.isOpenCreateModal = true;
      this.create.model = {};
      if (this.availableUnitListStore.length === 0) {
        this.fetchAvailableUnitList();
      }
    },
    closeModalCreate() {
      this.isOpenCreateModal = false;
    },
    async onSaveCreate() {
      const selectedUnitItem = this.availableUnitListStore.filter(data =>
        this.create.model.unit_id.includes(data._id),
      );
      this.create.model.unit_name = selectedUnitItem[0].name;
      this.create.model.unit_index = selectedUnitItem[0].index;
      const model = this.create.model;
      const formatContent = this.validateAndReplaceTags(
        this.create.model.content,
      );
      this.create.model.content = await formatContent;
      await this.createContentNavigation(model);
      await this.resetAvailableUnitList();
      await this.closeModalCreate();
    },
    onClickEdit(item) {
      this.openModalEdit();
      this.edit.model.content = item.content;
      this.edit.model.unit_name = item.unit_name;
      this.edit.model._id = item._id;
    },
    onClickDelete(id, unit_name) {
      const model = {
        ID: id,
        unit_name: unit_name,
      };
      this.deleteContentNavigation(model);
    },
  },
  computed: {
    ...mapState({
      contentNavigationStore: state =>
        state.content_navigation.CONTENT_NAVIGATION,
      availableUnitListStore: state =>
        state.content_navigation.AVAILABLE_UNIT_LIST,
      userLevel: state => state.userInfo.userData.role_id,
    }),
  },
  data() {
    return {
      extensions: [
        new Bold(),
        new Italic(),
        new Underline(),
        new HardBreak(),
        new History(),
      ],
      requiredRule: [v => !!v || 'This field is required'],
      isOpenEditModal: false,
      create: {
        valid: false,
        selectedUnit: '',
        model: {
          unit_id: '',
          unit_name: '',
          unit_index: '',
          content: '',
        },
      },
      edit: { valid: false, model: { _id: '', content: '' } },
      search: '',
      isOpenCreateModal: false,
      pagination: {
        rowsPerPage: -1,
        sortBy: 'unit_index',
      },
      header: [
        {
          text: 'Unit ID',
          value: 'unit_id',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Unit Name',
          value: 'unit_name',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Index',
          value: 'unit_index',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Content',
          value: 'content',
          align: 'center',
          sortable: true,
        },
        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ],
    };
  },
  mounted() {
    this.fetchContentNavigation();
  },
};
</script>

<style scope></style>
