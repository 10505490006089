import axios from 'axios';
import swal from 'sweetalert2';
import router from '../../router';
import http from '../../utils/httpClient';

const state = {
  userData: {},
  isLogin: false,
};
const getters = {};
const actions = {
  loginUser({ commit, dispatch }, loginInfo) {
    let is_web = true;
    http
      .POST(
        `/api/users/login`,
        {
          ...loginInfo,
          is_web,
        },
        {
          isAuthorization: true,
        },
      )
      .then(result => {
        commit('storeLoginInfo', result.data);
        swal
          .fire(`Welcome ${result.data.username}`, '', 'success')
          .then(() => window.$cookies.set('token', result.data.token))
          .then(() => router.push('/'))
          .then(() =>
            dispatch('appConfig/toggleDrawer', '', {
              root: true,
            }),
          );
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          swal.fire(
            'Login Infomation',
            response.data.error || response.data.message,
            'warning',
          );
        } else if (response.status === 403) {
          swal.fire(
            'Access denied',
            "You're not eligible to access this feature",
            'warning',
          );
        } else {
          swal.fire('Connection', 'Cannot connect to the server', 'error');
        }
      });
  },
  logoutUser({ commit }) {
    commit('removeLoginInfo');
    swal
      .fire('Logout Success', '', 'success')
      .then(() => router.push('/login'));
  },
  revokeAccess({ commit }) {
    commit('removeLoginInfo');
    swal
      .fire({
        title: 'Access denied',
        type: 'error',
        text: 'Unauthorized, please re-authorize',
        timer: 10000,
      })
      .then(() => router.push('/login'));
  },
};
const mutations = {
  storeLoginInfo(state, userData) {
    state.userData = { ...userData };
    state.isLogin = true;
  },
  removeLoginInfo(state) {
    state.userData = {};
    state.isLogin = false;
    window.$cookies.remove('token');
    window.$cookies.remove('server');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
