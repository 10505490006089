<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark class="pb-4">
          <v-toolbar-title class="pt-3">Learning Path Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-flex shrink mr-1 pt-5>
            <v-btn color="green" @click="createUnit">
              <v-icon>add</v-icon>Create Learning Path
            </v-btn>
          </v-flex>
          <!-- <v-flex shrink pt-4 mr-2>
            <v-checkbox v-model="selected" label="Tags"></v-checkbox>
          </v-flex> -->
          <v-flex column pt-3>
            <v-checkbox
              v-model="isTags"
              label="Search by Tags"
              style="margin-bottom: -22px"
              @change="clearSearch()"
            ></v-checkbox>
            <v-text-field
              v-if="!isTags"
              solo
              placeholder="Search"
              append-icon="search"
              hide-details
              @keyup.enter.native="onSearch"
              v-model="search"
            >
            </v-text-field>
            <v-combobox
              v-else
              v-model="searchtag"
              hide-selected
              hint="Maximum of 10 tags"
              label="Search by tag"
              multiple
              small-chips
              clearable
              solo
              v-on:keyup.enter="onSearch"
            >
              <template v-slot:selection="data">
                <v-chip
                  :selected="data.selected"
                  close
                  @input="removeTag(data.item)"
                >
                  <strong>{{ data.item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <draggable @end="order">
              <v-list-tile
                v-for="lp in learningpathList"
                :key="lp._id"
                @click="() => onClickEdit(lp._id)"
              >
                <v-list-tile-avatar>
                  {{ lp.index }}
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ lp.name }}</v-list-tile-title>
                  <v-list-tile-sub-title v-if="lp.lessons.length > 1">
                    {{ lp.lessons.length }} lessons
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title v-else>
                    {{ lp.lessons.length }} lesson
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-icon v-if="!lp.isPublish" color="grey lighten-1">
                    star_border
                  </v-icon>
                  <v-icon v-else color="yellow lighten-2"> star </v-icon>
                </v-list-tile-action>
              </v-list-tile>
            </draggable>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable';
import Container from '../../components/Container';
export default {
  name: 'Learningpathlist',
  components: {
    Container,
    draggable,
  },
  data: function () {
    return {
      search: '',
      isTags: false,
      searchtag: [],
    };
  },
  methods: {
    ...mapActions('learningpath', [
      'fetchLearningPathList',
      'fetchLP',
      'createUnit',
      'deleteUnit',
      'orderUnit',
    ]),
    order(e) {
      this.orderUnit({
        currentIndex: e.oldIndex,
        newIndex: e.newIndex,
      });
    },
    onClickEdit(id) {
      this.$router.push(`/learningpath?id=${id}`);
    },
    onSearch() {
      if (this.search) {
        const query = `name=${this.search}`;
        this.fetchLearningPathList(query);
      } else if (this.searchtag.length) {
        const query = `tags=${this.searchtag.join(',')}`;
        this.fetchLearningPathList(query);
      } else {
        this.fetchLearningPathList();
      }
    },
    removeTag(item) {
      this.searchtag.splice(this.searchtag.indexOf(item), 1);
      this.searchtag = [...this.searchtag];
    },
    clearSearch() {
      this.search = '';
      this.searchtag = [];
    },
  },
  created() {
    this.fetchLearningPathList();
  },
  computed: {
    ...mapState({
      learningpathList: state => state.learningpath.LEARNING_PATH_LIST,
    }),
  },
  watch: {
    searchtag(val) {
      if (val.length > 10) {
        this.$nextTick(() => this.searchtag.pop());
      }
    },
  },
};
</script>

<style></style>
