<template>
  <!-- <v-content> -->
  <div>
    <v-checkbox
      :label="title"
      :input-value="isToggle"
      @change="
        e =>
          this.$emit('onchangeToggleSprite', {
            value: e,
            index: index,
          })
      "
    ></v-checkbox>
    <v-layout row justify-center v-if="isToggle">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :color="colorBtn"
            >{{ fieldname }} {{ dataSpriteSheet.column }}X{{
              dataSpriteSheet.row
            }}</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="title">
            <span class="headline">Edit Sprite Sheet Popup </span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Column"
                    :value="dataSpriteSheet.column"
                    type="number"
                    v-model.number="dataSpriteSheet.column"
                    @input="e => checkSum('column', e)"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Row"
                    :value="dataSpriteSheet.row"
                    type="number"
                    v-model.number="dataSpriteSheet.row"
                    @input="e => checkSum('row', e)"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Frame/Millisec"
                    :value="dataSpriteSheet.frame_ms"
                    type="number"
                    v-model.number="dataSpriteSheet.frame_ms"
                    @input="e => checkSum('frame_ms', e)"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Loop"
                    :value="dataSpriteSheet.loop_count"
                    type="number"
                    v-model.number="dataSpriteSheet.loop_count"
                    @input="e => checkSum('loop_count', e)"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="cancel">Cancel</v-btn>
            <v-btn color="blue darken-1" flat @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <!-- </v-content> -->
  </div>
</template>

<script>
export default {
  name: 'SpriteSheet',
  data() {
    return {
      dialog: false,
      dataSpriteSheet: {},
    };
  },
  props: {
    isToggle: {
      type: Boolean,
      default: false,
      // required: true,
    },
    title: {
      type: String,
      default: 'Use Sprite Sheet',
    },
    fieldname: {
      type: String,
      default: 'Edit Sheet Data',
    },
    object: {
      type: Object,
      default: () => {},
      // required: true,
    },
    index: {
      type: Number,
      // default: 0,
    },
    color: {
      type: String,
    },
  },
  computed: {
    colorBtn() {
      return this.color !== undefined ? 'black' : null;
    },
  },
  methods: {
    save() {
      this.$emit('saveSpriteSheetData', {
        value: this.dataSpriteSheet,
        index: this.index,
      });
      this.dialog = false;
    },
    cancel() {
      this.dataSpriteSheet = { ...this.object };
      this.dialog = false;
    },
    checkSum(key, value) {
      if (value === '') {
        this.dataSpriteSheet[key] = 0;
      }
    },
  },
  created() {
    this.dataSpriteSheet = { ...this.object };
  },
  watch: {
    object(val) {
      this.dataSpriteSheet = { ...val };
    },
  },
};
</script>

<style scoped>
.title {
  justify-content: center;
}
</style>
