<template>
  <div>
    <v-select
      :items="GameType"
      item-text="name"
      item-value="value"
      :value="shortStoryStore.selectedShortStory.type"
      v-model="shortStoryStore.selectedShortStory.type"
      label="Type"
      v-on:change="e => onchangeGameDetail('type', e)"
      :rules="[rules.required]"
    ></v-select>
    <div v-if="shortStoryStore.selectedShortStory.type === 1">
      <!-- Mic -->
      <v-text-field
        :value="shortStoryStore.selectedShortStory.word"
        label="Word"
        v-on:change="e => onchangeGameDetail('word', e)"
      ></v-text-field>
      <v-text-field
        type="number"
        :value="shortStoryStore.selectedShortStory.mic_duration"
        label="Microphone Time (ms)"
        v-on:change="e => onchangeGameDetail('mic_duration', parseInt(e))"
      ></v-text-field>
      <v-text-field
        :value="shortStoryStore.selectedShortStory.word_sound_uri"
        label="Word Sound URI (URL or Select file)"
        v-on:change="e => onchangeGameDetail('word_sound_uri', e)"
        :rules="[rules.validURI]"
      ></v-text-field>
      <v-btn class="btn-center" @click="onclickMusicSound('word')"
        ><v-icon>audiotrack</v-icon>Select Word Sound URI</v-btn
      >
      <v-select
        :items="eventIndexList()"
        item-text="name"
        item-value="index"
        :value="shortStoryStore.selectedShortStory.event_index_fail"
        v-model="shortStoryStore.selectedShortStory.event_index_fail"
        label="Event index fail"
        v-on:change="e => onchangeGameDetail('event_index_fail', e)"
      ></v-select>
      <v-select
        :items="eventIndexList()"
        item-text="name"
        item-value="index"
        :value="shortStoryStore.selectedShortStory.event_index_1star"
        v-model="shortStoryStore.selectedShortStory.event_index_1star"
        label="Event index 1 star"
        v-on:change="e => onchangeGameDetail('event_index_1star', e)"
      ></v-select>
      <v-select
        :items="eventIndexList()"
        item-text="name"
        item-value="index"
        :value="shortStoryStore.selectedShortStory.event_index_2star"
        v-model="shortStoryStore.selectedShortStory.event_index_2star"
        label="Event index 2 star"
        v-on:change="e => onchangeGameDetail('event_index_2star', e)"
      ></v-select>
      <v-select
        :items="eventIndexList()"
        item-text="name"
        item-value="index"
        :value="shortStoryStore.selectedShortStory.event_index_3star"
        v-model="shortStoryStore.selectedShortStory.event_index_3star"
        label="Event index 3 star"
        v-on:change="e => onchangeGameDetail('event_index_3star', e)"
      ></v-select>
      <v-text-field
        type="number"
        :value="shortStoryStore.selectedShortStory.retry_amount"
        label="Retry Amount"
        v-on:change="e => onchangeGameDetail('retry_amount', parseInt(e))"
      ></v-text-field>
    </div>
    <div v-if="shortStoryStore.selectedShortStory.type === 2">
      <!-- Tts -->
      <v-select
        :items="Ttsmode"
        item-text="name"
        item-value="value"
        :value="shortStoryStore.selectedShortStory.mode"
        v-model="shortStoryStore.selectedShortStory.mode"
        label="Mode"
        v-on:change="e => onchangeGameDetail('mode', e)"
      ></v-select>
      <v-btn class="btn-center" @click="onclickOpenList()"
        ><v-icon>image</v-icon>Tts Image List</v-btn
      >
      <StepperManager
        :title="'Tts Image List Manager'"
        :isOpen="isOpenImageList"
        :dataArray="shortStoryStore.selectedShortStory.choice_datas"
        :defaultData="shortStoryStore.defaultImageList"
        @save="onchangeGameDetail('choice_datas', $event)"
        @close="closeList"
      ></StepperManager>
    </div>
    <div v-if="shortStoryStore.selectedShortStory.type === 3">
      <!-- MicWords -->
      <v-text-field
        type="number"
        :value="shortStoryStore.selectedShortStory.mic_duration"
        label="Microphone Time"
        v-on:change="e => onchangeGameDetail('mic_duration', parseInt(e))"
      ></v-text-field>
      <v-text-field
        :value="shortStoryStore.selectedShortStory.word_sound_uri"
        label="Word Sound URI (URL or Select file)"
        v-on:change="e => onchangeGameDetail('word_sound_uri', e)"
        :rules="[rules.validURI]"
      ></v-text-field>
      <v-btn class="btn-center" @click="onclickMusicSound('word')"
        ><v-icon>audiotrack</v-icon>Select Word Sound URI</v-btn
      >
      <v-select
        :items="eventIndexList()"
        item-text="name"
        item-value="index"
        :value="shortStoryStore.selectedShortStory.event_index_fail"
        v-model.number="shortStoryStore.selectedShortStory.event_index_fail"
        label="Event index fail"
        v-on:change="e => onchangeGameDetail('event_index_fail', parseInt(e))"
      ></v-select>
      <v-btn class="btn-center" @click="onclickOpenList('micword')"
        ><v-icon>image</v-icon>Edit Word List</v-btn
      >
      <StepperManager
        :title="'Word List Manager'"
        :isOpen="isOpenWordList"
        :dataArray="shortStoryStore.selectedShortStory.word_datas"
        :defaultData="shortStoryStore.defaultWordList"
        @save="onchangeGameDetail('word_datas', $event)"
        @close="closeList"
      ></StepperManager>
      <v-text-field
        :value="shortStoryStore.selectedShortStory.retry_amount"
        label="Retry Amount"
        v-on:change="e => onchangeGameDetail('retry_amount', parseInt(e))"
      ></v-text-field>
    </div>
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import constant from '../constant';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import StepperManager from '../../../components/StepperManager.vue';

export default {
  components: {
    ResourceSelect,
    StepperManager,
  },
  data() {
    return {
      rules: constant.rule,
      GameType: constant.GameType,
      Ttsmode: constant.Ttsmode,
      isSelectSound: false,
      selectedSoundUri: false,
      isSelectWordSound: false,
      isOpenImageList: false,
      isOpenWordList: false,
    };
  },
  // props: {
  //   onclickMusicSound: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    ...mapActions('short_story', [
      'updateGameStore',
      'setDirty',
      'clearUpdateGame',
    ]),
    closeList() {
      this.isOpenWordList = false;
      this.isOpenImageList = false;
    },
    onclickOpenList(type) {
      if (type === 'micword') this.isOpenWordList = true;
      else this.isOpenImageList = true;
    },
    eventIndexList() {
      const events = [...this.shortStoryStore.selectedShortStory.events];
      if (events.length) {
        const eventTypePlay = events.filter((e, index) => {
          if (e.type === 1) {
            e.index = index;
            return e;
          }
        });
        return eventTypePlay;
      } else return [];
    },
    onchangeGameDetail(key, value) {
      if (key === 'type') {
        const id = this.shortStoryStore.selectedShortStory._id;
        this.clearUpdateGame();
        this.updateGameStore({ key: 'id', value: id });
        const selected = { ...this.shortStoryStore.selectedShortStory };
        let defaultEventIndex;
        if (value === 1) {
          //mic
          defaultEventIndex = JSON.parse(
            JSON.stringify(this.shortStoryStore.Mic),
          );
        } else if (value === 3) {
          //micwords
          defaultEventIndex = JSON.parse(
            JSON.stringify(this.shortStoryStore.Micwords),
          );
        }
        if (defaultEventIndex) {
          for (const [key, value] of Object.entries(defaultEventIndex)) {
            if (!(key in selected)) {
              this.shortStoryStore.selectedShortStory = {
                ...this.shortStoryStore.selectedShortStory,
                [key]: value,
              };
              this.updateGameStore({ key, value });
            }
          }
        }
      }
      if (key === 'choice_datas' || key === 'word_datas') this.closeList();
      this.setDirty('game');
      this.updateGameStore({ key, value });
    },
    onclickMusicSound(type) {
      this.isSelectSound = true;
      if (type === 'word') this.isSelectWordSound = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectWordSound:
          this.shortStoryStore.selectedShortStory.word_sound_uri = uri;
          this.updateGameStore({ key: 'word_sound_uri', value: uri });
          this.isSelectWordSound = false;
          this.setDirty('game');
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
  },
};
</script>

<style scoped>
hr {
  /* width: 70%; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  border-top: 1px solid #c4c4c4;
  margin-block: revert;
}
</style>
