import http from '../../utils/httpClient';
import swal from 'sweetalert2';
import router from '../../router';
import moment from 'moment';

const state = {
  isUpdating: false,
  CREATE: {},
  UPDATE: {},
  GAME_LIST: [],
  GAME_DATA: [],
  SELECTED_GAME_LIST: {},
};
const getters = {};
const actions = {
  createGameData({ dispatch }, { ...data }) {
    const gameData = {
      ...data,
    };
    http
      .POST('/api/flyinggame', gameData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully created new game data',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('getGameList');
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed to create new game data',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getCategoryByGameListID({ commit, dispatch }, id) {
    http
      .GET(`/api/flyinggame/category?game_list_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_SELECTED_GAME_LIST_STATE', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch category name for flying game at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getGameDataByObjectID({ commit, dispatch }, objectID) {
    http
      .GET(`/api/flyinggame/?_id=${objectID}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_GAME_DATA', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch game data for flying game at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getGameData({ commit, dispatch }, id) {
    http
      .GET(`/api/flyinggame/gameData?game_list_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_GAME_DATA', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch game data for flying game at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getGameList({ commit, dispatch }) {
    http
      .GET(`/api/flyinggame/gameList`, {
        isAuthorization: true,
      })
      .then(response => {
        const formatData = response.data.map(data => ({
          ...data,
          created_at: moment(data.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(data.updated_at).format('YYYY MMM DD h:mm A'),
        }));
        commit('SET_GAME_LIST', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch category for flying game at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  updateGame({ dispatch }, { ...data }) {
    const gameData = {
      ...data,
    };
    http
      .PUT('/api/flyinggame/gameData', gameData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully update flying game',
            type: 'success',
          },
          {
            root: true,
          },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed to update flying game',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  deleteGame({ dispatch }, { gameID }) {
    swal
      .fire({
        titleText: 'Confirm Delete Game',
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'red',
        confirmButtonText: 'Remove',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/flyinggame/gameData?_id=${gameID}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete successfully',
                  type: 'success',
                },
                { root: true },
              );
              router.go();
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message:
                    'Cannot delete at this time, please try again later.',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  setCreateState({ commit }, { key, value, index }) {
    commit('SET_CREATE_STATE', { key, value, index });
  },
  setUpdateState({ commit }, { key, value, index }) {
    commit('SET_UPDATE_STATE', { key, value, index });
  },
  resetCreateState({ commit }) {
    commit('RESET_CREATED_STATE');
  },
  resetUpdateState({ commit }) {
    commit('RESET_UPDATED_STATE');
  },
  setSelectedGameListState({ commit }, data) {
    commit('SET_SELECTED_GAME_LIST_STATE', data);
  },
};
const mutations = {
  TOGGLE_UPDATING_STATE(state) {
    state.isUpdating = !state.isUpdating;
  },
  SET_SELECTED_GAME_LIST_STATE(state, value) {
    if (value) {
      state.SELECTED_GAME_LIST = value;
    } else {
      delete state.SELECTED_GAME_LIST;
    }
  },
  SET_GAME_LIST(state, value) {
    if (value) {
      state.GAME_LIST = value;
    } else {
      delete state.GAME_LIST;
    }
  },
  SET_GAME_DATA(state, value) {
    if (value) {
      state.GAME_DATA = value;
    } else {
      delete state.GAME_DATA;
    }
  },
  SET_CREATE_STATE(state, { key, value, index }) {
    state.CREATE[index] = {
      ...state.CREATE[index],
      [key]: value,
    };
  },
  SET_UPDATE_STATE(state, { key, value, index }) {
    state.UPDATE[index] = {
      ...state.UPDATE[index],
      [key]: value,
    };
  },
  RESET_CREATED_STATE(state) {
    state.CREATE = {};
  },
  RESET_UPDATED_STATE(state) {
    state.UPDATE = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
