<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light v-on:click="$router.push('/trace')">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title
            >Tracing :
            {{
              (traceStore.selectTracing && traceStore.selectTracing.category) ||
              'Loading...'
            }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text v-if="traceStore.selectTracing">
          <v-layout row grid-list-md v-if="!traceStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Blueprint</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddBlueprint">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(blueprint, index) in traceStore.selectTracing
                      .blueprints"
                    :key="blueprint._id"
                    v-on:click="onSelectBlueprint(blueprint._id)"
                    :class="
                      index === selectedBlueprintIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{
                        blueprint.text
                      }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs9 mr-10 v-if="selectedBlueprintIndex != -1">
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Blueprint Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickUpdateBlueprint">
                    <v-icon>save</v-icon>Save Blueprint
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Available for Free User"
                        v-model="blueprint.isGuestPlayable"
                        :value="blueprint.isGuestPlayable"
                        v-on:change="
                          e => onChangeBlueprintData('isGuestPlayable', e)
                        "
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Title"
                        :value="blueprint.text"
                        v-on:change="e => onChangeBlueprintData('text', e)"
                      ></v-text-field>
                    </v-flex>

                    <v-flex>
                      <v-select
                        :items="constants.CARD_TYPE_NODE_SOUND_ONLY"
                        label="Node Sound"
                        :value="blueprint.node_sound"
                        v-model="blueprint.node_sound"
                        v-on:change="
                          e => onChangeBlueprintData('node_sound', e)
                        "
                      ></v-select>
                    </v-flex>
                    <v-flex v-if="blueprint.node_sound === true">
                      <v-text-field
                        label="Sound for Node (URL or Select file)"
                        :value="blueprint.node_sound_uri"
                        v-on:change="
                          e => onChangeBlueprintData('node_sound_uri', e)
                        "
                      />
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          isUpdateNodeSound = true;
                          isUpdateBlueprint = true;
                        "
                      >
                        <v-icon>audiotrack</v-icon>Edit Sound for Each Node
                      </v-btn>
                    </v-flex>

                    <v-flex>
                      <v-select
                        :items="constants.CARD_TYPE"
                        label="Object Type"
                        :value="blueprint.type"
                        v-model="blueprint.type"
                        v-on:change="e => onChangeBlueprintData('type', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Popup Image and Sound for each node"
                        v-model="blueprint.is_popup"
                        :value="blueprint.is_popup"
                        v-on:change="e => onChangeBlueprintData('is_popup', e)"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="
                        blueprint.type == 'sound' ||
                        blueprint.type == 'image_sound'
                      "
                    >
                      <v-text-field
                        label="Sound (URL or Select file)"
                        :value="blueprint.sound_uri"
                        v-on:change="e => onChangeBlueprintData('sound_uri', e)"
                      />
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          isUpdateNodeSound = false;
                          isUpdateBlueprint = true;
                        "
                      >
                        <v-icon>audiotrack</v-icon>Edit Sound
                      </v-btn>
                    </v-flex>
                    <v-flex
                      v-if="
                        blueprint.type == 'image' ||
                        blueprint.type == 'image_sound'
                      "
                    >
                      <img
                        v-if="blueprint.img_uri"
                        :src="blueprint.img_uri"
                        :alt="blueprint.blueprint"
                        class="content_img"
                      />
                      <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                          <v-text-field
                            label="Image (URL or Select file)"
                            :value="blueprint.img_uri"
                            v-on:change="
                              e => onChangeBlueprintData('img_uri', e)
                            "
                          />
                          <span class="headline" dark>
                            <v-btn v-on:click="isEditImage = true">
                              <v-icon>image</v-icon>Edit Image
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <img
                        v-if="blueprint.finish_image_uri"
                        :src="blueprint.finish_image_uri"
                        :alt="blueprint.blueprint"
                        class="content_img"
                      />
                      <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                          <v-text-field
                            label="Finish Drawing Image (URL or Select file)"
                            :value="blueprint.finish_image_uri"
                            v-on:change="
                              e => onChangeBlueprintData('finish_image_uri', e)
                            "
                          />
                          <span class="headline" dark>
                            <v-btn
                              v-on:click="
                                isEditImage = true;
                                isFinishImageUri = true;
                              "
                            >
                              <v-icon>image</v-icon>Edit Finish Image
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Finish Drawing Sound (URL or Select file)"
                        :value="blueprint.finish_audio_uri"
                        v-on:change="
                          e => onChangeBlueprintData('finish_audio_uri', e)
                        "
                      />
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          isUpdateFinishPictureSound = true;
                          isUpdateBlueprint = true;
                        "
                      >
                        <v-icon>audiotrack</v-icon>Edit Finish Drawing Sound
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music (URL or Select file)"
                        :value="blueprint.bgm_uri"
                        v-model="blueprint.bgm_uri"
                        v-on:change="e => onChangeBlueprintData('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          isEditBGM = true;
                          isUpdateBlueprint = true;
                        "
                        >Select Sound</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout column>
                    <label>
                      <u>⌗ Lines</u>
                    </label>
                    <v-flex
                      v-for="(line, index) in lines"
                      :key="index"
                      class="elevation-2"
                      style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    >
                      <v-card-actions>
                        <label>Line {{ index + 1 }}:</label>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="index > 0"
                          icon
                          color="red"
                          dark
                          @click="onRemoveLineData(index)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-card-actions>

                      <v-flex
                        v-for="(dot, idx) in line"
                        :key="idx"
                        class="elevation-2"
                        style="
                          padding: 10px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        idx: {{ idx }}
                        <v-layout align-content-space-between>
                          <v-flex>
                            <v-text-field
                              label="X"
                              :value="dot.x"
                              v-on:change="
                                e => onChangeLineData(index, idx, 'x', e)
                              "
                            ></v-text-field>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Y"
                              :value="dot.y"
                              v-on:change="
                                e => onChangeLineData(index, idx, 'y', e)
                              "
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="lines && lines.length > 0"
                          icon
                          color="red"
                          @click="onRemoveDotData(index)"
                        >
                          <v-icon>remove</v-icon>
                        </v-btn>
                        <v-btn v-else icon color="grey">
                          <v-icon>remove</v-icon>
                        </v-btn>
                        <v-btn icon color="green" @click="onAddDotData(index)">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-flex>
                    <!-- LINE -->
                    <v-flex>
                      <v-btn color="green" dark @click="onAddLineData">
                        <v-icon>add</v-icon>Add New Line
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-btn
                    color="red"
                    dark
                    v-on:click="onClickDeleteBlueprint(blueprint._id)"
                  >
                    <v-icon>delete</v-icon>Delete this blueprint
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedBlueprintIndex != -1
          ? traceStore.selectTracing.blueprints[selectedBlueprintIndex]
              .learned_words
          : null
      "
      :incidentalWords="
        selectedBlueprintIndex != -1
          ? traceStore.selectTracing.blueprints[selectedBlueprintIndex]
              .incidental_words
          : null
      "
      :rewards="
        selectedBlueprintIndex != -1
          ? traceStore.selectTracing.blueprints[selectedBlueprintIndex].reward
          : null
      "
      :sentences="
        selectedBlueprintIndex != -1
          ? traceStore.selectTracing.blueprints[selectedBlueprintIndex].sentence
          : null
      "
      :phonics="
        selectedBlueprintIndex != -1
          ? traceStore.selectTracing.blueprints[selectedBlueprintIndex].phonic
          : null
      "
    >
    </PointData>
    <Modal
      :v-if="isAddBlueprint"
      :isOpen="isAddBlueprint"
      :close="toggleAddBlueprint"
      :save="onClickAddBlueprint"
      text="Add Blueprint"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex>
            <v-text-field
              label="Text"
              :value="blueprint.text"
              v-model="blueprint.text"
              v-on:change="e => onChangeCreateBlueprint('text', e)"
            ></v-text-field>
          </v-flex>
          <br />
          <v-flex>
            <v-select
              :items="constants.CARD_TYPE_NODE_SOUND_ONLY"
              label="Sound of Node"
              :value="blueprint.node_sound"
              v-model="blueprint.node_sound"
              v-on:change="e => onChangeCreateBlueprint('node_sound', e)"
            ></v-select>
          </v-flex>

          <v-flex v-if="blueprint.node_sound && blueprint.node_sound === true">
            <v-text-field
              label="Sound (URL or Select file)"
              :value="blueprint.node_sound_uri"
              v-model="blueprint.node_sound_uri"
              disabled
            />
            <span class="headline" dark>
              <v-btn
                dark
                v-on:click="
                  isEditSound = true;
                  isAddNodeSound = true;
                "
              >
                <v-icon>audiotrack</v-icon>Select Sound for Each Node
              </v-btn>
            </span>
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.CARD_TYPE"
              label="Object Type"
              :value="blueprint.type"
              v-model="blueprint.type"
              v-on:change="e => onChangeCreateBlueprint('type', e)"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-checkbox
              label="Popup Image and Sound for each node"
              v-model="blueprint.is_popup"
              :value="blueprint.is_popup"
              v-on:change="e => onChangeCreateBlueprint('is_popup', e)"
            ></v-checkbox>
          </v-flex>
          <v-flex
            v-if="
              blueprint.type &&
              (blueprint.type == 'sound' || blueprint.type == 'image_sound')
            "
          >
            <v-text-field
              label="Sound (URL or Select file)"
              :value="blueprint.sound_uri"
              v-model="blueprint.sound_uri"
              disabled
            />
            <span class="headline" dark>
              <v-btn
                dark
                v-on:click="
                  isEditSound = true;
                  isAddNodeSound = false;
                "
              >
                <v-icon>audiotrack</v-icon>Select Sound
              </v-btn>
            </span>
          </v-flex>
          <v-flex
            v-if="
              blueprint.type &&
              (blueprint.type == 'image' || blueprint.type == 'image_sound')
            "
          >
            <img
              v-if="blueprint.img_uri"
              :src="blueprint.img_uri"
              :alt="blueprint.blueprint"
              class="content_img"
            />
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <v-text-field
                  label="Image (URL or Select file)"
                  :value="blueprint.img_uri"
                  disabled
                />
                <span class="headline" dark>
                  <v-btn dark v-on:click="isEditImage = true">
                    <v-icon>image</v-icon>Select Image
                  </v-btn>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Background Music"
              :value="blueprint.bgm_uri"
              v-model="blueprint.bgm_uri"
              v-on:change="e => onChangeCreateBlueprint('bgm_uri', e)"
            ></v-text-field>
            <v-btn
              v-on:click="
                isEditSound = true;
                isEditBGM = true;
              "
              >Select Sound</v-btn
            >
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>

<script>
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import constants from '../../constants';

export default {
  name: 'Tracing',
  components: {
    Container,
    ResourceSelect,
    Modal,
    PointData,
  },
  computed: {
    ...mapState({
      traceStore: state => state.tracing,
      selectedBlueprintIndexStore: state =>
        state.tracing.selectedBlueprintIndex,
    }),
  },
  data: function () {
    return {
      isUpdateFinishPictureSound: false,
      isFinishImageUri: false,
      constants,
      blueprints: [],
      blueprint: {},
      lines: [],
      selectedBlueprintIndex: -1,
      isEditImageIndex: -1,
      isEditImage: false,
      isEditSound: false,
      isEditBGM: true,
      selectImageUri: '',
      selectSoundUri: '',
      isEditBlueprintResource: false,
      selectBlueprintUri: '',
      text: '',
      isDirty: false,
      isAddBlueprint: false,
      isUpdateBlueprint: false,
      isAddNodeSound: false,
      isUpdateNodeSound: false,
      selectBlueprintId: '',
      isOpenPointData: false,
    };
  },
  methods: {
    ...mapActions('tracing', [
      'fetchTracing',
      'fetchSingleTracing',
      'clearUpdateData',
      'deleteBlueprint',
      'updateCreateBlueprintData',
      'updateBlueprintData',
      'updateBlueprint',
      'addBlueprint',
      'selectCategory',
      'selectTracing',
    ]),
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.updateBlueprintData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateBlueprintData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateBlueprintData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateBlueprintData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateBlueprintData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateBlueprint();
      this.isOpenPointData = false;
    },

    onSelectBlueprint(id) {
      this.clearUpdateData();
      const blueprints = this.traceStore.selectTracing.blueprints;
      const index = blueprints.findIndex(blueprint => blueprint._id === id);
      this.selectedBlueprintIndex = index;
      this.blueprint = {
        ...blueprints[index],
      };
      this.lines = this.blueprint.lines;
      this.updateBlueprintData({
        key: '_id',
        value: id,
      });
      this.selectBlueprintId = id;
      var blueprint = document.getElementById('edit-blueprint');
      if (blueprint) {
        blueprint.load();
      }
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    onSelectBlueprintResource(uri) {
      this.selectBlueprintUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddBlueprint) {
        this.updateCreateBlueprintData({
          key: 'img_uri',
          value: uri,
        });
        this.blueprint.img_uri = uri;
      } else if (this.isFinishImageUri) {
        this.updateBlueprintData({
          key: 'finish_image_uri',
          value: uri,
        });
        this.blueprint.finish_image_uri = uri;
      } else {
        this.isDirty = true;
        this.updateBlueprintData({
          key: 'img_uri',
          value: uri,
        });
        this.blueprint.img_uri = uri;
      }
      this.selectImageUri = '';
      this.isFinishImageUri = !this.isFinishImageUri;
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isAddBlueprint && !this.isAddNodeSound && this.isEditBGM) {
        this.updateCreateBlueprintData({
          key: 'bgm_uri',
          value: uri,
        });
        this.blueprint.bgm_uri = uri;
      } else if (this.isAddBlueprint && !this.isAddNodeSound) {
        this.updateCreateBlueprintData({
          key: 'sound_uri',
          value: uri,
        });
        this.blueprint.sound_uri = uri;
      } else if (this.isAddBlueprint && this.isAddNodeSound) {
        this.updateCreateBlueprintData({
          key: 'node_sound_uri',
          value: uri,
        });
        this.blueprint.node_sound_uri = uri;
      } else if (this.isUpdateBlueprint && this.isEditBGM) {
        this.updateBlueprintData({
          key: 'bgm_uri',
          value: uri,
        });
        this.blueprint.bgm_uri = uri;
      } else if (this.isUpdateBlueprint && this.isUpdateFinishPictureSound) {
        this.updateBlueprintData({
          key: 'finish_audio_uri',
          value: uri,
        });
        this.blueprint.finish_audio_uri = uri;
        this.isUpdateFinishPictureSound = !this.isUpdateFinishPictureSound;
      } else if (this.isUpdateBlueprint && this.isUpdateNodeSound) {
        this.updateBlueprintData({
          key: 'node_sound_uri',
          value: uri,
        });
        this.blueprint.node_sound_uri = uri;
      } else {
        this.isDirty = true;
        this.updateBlueprintData({
          key: 'sound_uri',
          value: uri,
        });
        this.blueprint.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    saveEditBlueprintResource() {
      const uri = this.selectBlueprintUri;
      let blueprint = null;
      if (this.isAddBlueprint) {
        this.updateCreateBlueprintData({
          key: 'blueprint_uri',
          value: uri,
        });
        blueprint = document.getElementById('new-blueprint');
      } else {
        this.isDirty = true;
        this.updateBlueprintData({
          key: 'blueprint_uri',
          value: uri,
        });
        blueprint = document.getElementById('edit-blueprint');
      }
      this.blueprint.blueprint_uri = uri;
      this.selectBlueprintUri = '';
      this.isEditBlueprintResource = !this.isEditBlueprintResource;
      if (blueprint) {
        blueprint.load();
      }
    },
    closeEditBlueprintResource() {
      this.selectBlueprintUri = '';
      this.isEditBlueprintResource = !this.isEditBlueprintResource;
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    toggleAddBlueprint() {
      this.updateCreateBlueprintData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.blueprint = { ...this.traceStore.addBlueprintData };
      this.isAddBlueprint = !this.isAddBlueprint;
      if (!this.isAddBlueprint && this.traceStore.selectedCategoryId) {
        this.$router.push('/trace');
      }
    },
    onClickAddBlueprint() {
      const id = this.$route.query.id;
      const createBlueprintData = {
        ...this.traceStore.addBlueprintData,
      };
      this.addBlueprint({
        category_id: id,
        blueprint: createBlueprintData,
      });
      this.isAddBlueprint = false;
    },
    onClickDeleteBlueprint(id) {
      this.deleteBlueprint(id);
      this.isDirty = false;
      this.selectedBlueprintIndex = -1;
    },
    onChangeBlueprintData(key, value) {
      if (key == 'blueprint_uri') {
        var blueprint = document.getElementById('edit-blueprint');
        if (blueprint) {
          blueprint.load();
        }
      } else if ((key == 'is_popup' || key == 'isGuestPlayable') && !value) {
        value = false;
      }
      this.updateBlueprintData({
        key,
        value,
      });
    },
    onClickUpdateBlueprint() {
      const updateBlueprintData = {
        ...this.traceStore.updateBlueprintData,
      };
      this.updateBlueprint(updateBlueprintData);
      Object.keys(updateBlueprintData).forEach(key => {
        this.blueprint[key] = updateBlueprintData[key];
      });
    },
    onChangeCreateBlueprint(key, value) {
      if (key == 'is_popup' && !value) {
        value = false;
      }
      this.updateCreateBlueprintData({
        key,
        value,
      });
      this.blueprint[key] = value;
    },
    onChangeLineData(index, idx, key, value) {
      this.isDirty = true;
      const lines = [...this.lines];
      lines[index][idx][key] = value;
      this.lines = lines;
      this.updateBlueprintData({
        key: 'lines',
        value: lines,
      });
    },
    onAddLineData() {
      const lines = this.lines ? [...this.lines] : [];
      this.lines = [...lines, [{ x: '', y: '' }]];
      this.updateBlueprintData({
        key: 'lines',
        value: lines,
      });
    },
    onRemoveLineData(index) {
      this.isDirty = true;
      const lines = [...this.lines];
      lines.splice(index - 1, 1);
      this.lines = lines;
      this.updateBlueprintData({
        key: 'lines',
        value: lines,
      });
    },
    onAddDotData(index) {
      const lines = [...this.lines];
      const dots = [...lines[index], { x: '', y: '' }];
      lines[index] = dots;
      this.updateBlueprintData({
        key: 'lines',
        value: lines,
      });
      this.lines = lines;
    },
    onRemoveDotData(index) {
      this.isDirty = true;
      const lines = [...this.lines];
      const dots = [...this.lines[index]];
      dots.pop();
      lines[index] = dots;
      this.updateBlueprintData({
        key: 'lines',
        value: lines,
      });
      this.lines = lines;
    },
  },
  watch: {
    selectedBlueprintIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectBlueprint(this.$route.query.sub_id);
      }
    },
  },
  mounted() {
    if (this.$route.query.category_id) {
      this.fetchSingleTracing({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (this.traceStore.selectedCategoryId) {
      this.toggleAddBlueprint();
    } else if (Object.keys(this.traceStore.selectTracing).length < 1) {
      this.fetchSingleTracing({ id: this.$route.query.id });
    }
  },
};
</script>
