<template>
  <Container>
    <v-flex justify-start>
      <v-card v-if="!questStore.isLoadQuest">
        <v-toolbar dark>
          <v-toolbar-title> Quest Manager </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-layout row grid-list-md>
            <v-flex xs3 mr-2>
              <v-toolbar dark>
                <v-toolbar-title>Quest List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="success" v-on:click="onClickAddQuest">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list>
                <v-list-tile
                  v-for="(quest, index) in questStore.quest"
                  :key="quest._id"
                  v-on:click="onClickSelectQuest(index, quest._id)"
                  :class="index === selectedQuestIndex ? 'active' : 'inactive'"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>{{ quest.name }}</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ quest.display_quest_date }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-flex>
            <v-flex mr-2 v-if="selectedQuestIndex != -1" xs2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Activities</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="green"
                    @click="
                      onClickAddActivity();
                      selectedActivityIndex = -1;
                    "
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <draggable @end="order">
                    <v-list-tile
                      v-for="(activity, index) in questStore.quest[
                        selectedQuestIndex
                      ].activities"
                      :key="activity._id"
                      @click="onClickSelectActivity(index, activity._id)"
                      :class="
                        index === selectedActivityIndex ? 'active' : 'inactive'
                      "
                    >
                      <v-list-tile-content>
                        <v-list-tile-title
                          >{{ index + 1 }}
                          {{ activity.title }}</v-list-tile-title
                        >
                        <v-list-tile-sub-title
                          >Game type :
                          {{
                            activity.game_data[0].game_type
                          }}</v-list-tile-sub-title
                        >
                      </v-list-tile-content>
                    </v-list-tile>
                  </draggable>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              xs3
              mr-2
              v-if="selectedQuestIndex != -1 && selectedActivityIndex == -1"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Quest Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="onSaveEditQuest">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDeleteQuest(quest._id)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card>
                  <v-container>
                    <v-layout grid-list-xs column text-xs-center>
                      <v-flex>
                        <v-text-field
                          label="Quest"
                          :value="quest.name"
                          v-model="quest.name"
                          @change="v => onChangeEditQuest('name', v)"
                        ></v-text-field>

                        <v-flex>
                          <img
                            v-if="quest.image_uri"
                            :src="quest.image_uri"
                            class="content_img"
                          />
                          <v-text-field
                            label="Image URI (URL or Select file)"
                            :value="quest.image_uri"
                            v-model="quest.image_uri"
                            @change="v => onChangeEditQuest('image_uri', v)"
                          ></v-text-field>
                          <v-btn
                            v-on:click="
                              isEditImage = true;
                              isEditQuest = true;
                            "
                          >
                            <v-icon>image</v-icon>Select Image
                          </v-btn>
                        </v-flex>
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              label="Quest Date"
                              prepend-icon="event"
                              v-model="quest.edit_quest_date"
                              readonly
                              :show-current="true"
                              v-on="on"
                            ></v-text-field>
                          </template>

                          <v-date-picker
                            v-model="quest.edit_quest_date"
                            @input="menu = false"
                            @change="
                              v => onChangeEditQuest('quest_date', +new Date(v))
                            "
                            :show-current="true"
                            :min="nowDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-card>
            </v-flex>
            <v-flex mr-2 v-if="selectedActivityIndex != -1">
              <v-card dark>
                <v-toolbar>
                  <v-toolbar-title>Activity Configuration</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn @click="onSaveEditActivity" color="green">
                    <v-icon>save</v-icon>Save
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-layout column>
                      <v-flex>
                        <v-layout align-content-space-between>
                          <v-text-field
                            label="Search Activity Title"
                            v-model="keyword"
                          ></v-text-field>
                          <v-btn
                            icon
                            color="black"
                            dark
                            v-on:click="fetchLearningPathActivity(keyword)"
                          >
                            <v-icon>search</v-icon>
                          </v-btn>
                        </v-layout>
                      </v-flex>
                      <v-flex>
                        <v-select
                          :items="questStore.lpActivities"
                          label="Load Leaning Path Activity"
                          :value="select_activity"
                          v-model="select_activity"
                          v-on:change="e => onLoadActivity(e)"
                        ></v-select>
                      </v-flex>
                      <v-flex>
                        <v-btn
                          color="red"
                          dark
                          v-on:click="
                            clearLocalActivity();
                            isDisable = false;
                          "
                        >
                          <v-icon>delete</v-icon> Clear Data
                        </v-btn>
                      </v-flex>
                      <br />
                      <v-flex>
                        <v-text-field
                          label="Title"
                          :value="activity.title"
                          v-on:change="e => onChangeEditActivity('title', e)"
                          :disabled="isDisable"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Icon"
                          :value="activity.icon"
                          v-on:change="e => onChangeEditActivity('icon', e)"
                          :disabled="isDisable"
                        />
                        <v-btn
                          dark
                          color="black"
                          v-on:click="
                            isEditImage = true;
                            isEditActivity = true;
                          "
                          :disabled="isDisable"
                        >
                          <v-icon>edit</v-icon>EDIT ICON
                        </v-btn>
                        <v-img
                          :src="
                            questStore.selectedActivity.icon || activity.icon
                          "
                          class="content_img"
                        ></v-img>
                      </v-flex>
                      <v-flex>
                        <v-select
                          :items="transition"
                          label="Transition"
                          :value="activity.transition"
                          v-model="activity.transition"
                          v-on:change="
                            e => onChangeEditActivity('transition', e)
                          "
                          :disabled="isDisable"
                        ></v-select>
                      </v-flex>
                      <v-flex>
                        <v-checkbox
                          label="Skip Activity"
                          v-model="activity.skip_activity"
                          :value="activity.skip_activity"
                          v-on:change="
                            e => onChangeEditActivity('skip_activity', e)
                          "
                          :disabled="isDisable"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-checkbox
                          label="Skip (Under Construction)"
                          v-model="activity.skip"
                          :value="activity.skip"
                          v-on:change="e => onChangeEditActivity('skip', e)"
                          :disabled="isDisable"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex
                        v-for="(data, index) in activity.game_data"
                        :key="index"
                      >
                        <container>
                          <v-layout column>
                            <v-card-actions>
                              <label>
                                <b>🎯 GAME {{ index + 1 }}:</b>
                              </label>
                              <v-spacer></v-spacer>
                              <v-btn
                                v-if="
                                  activity.game_data.length > 1 && index > 0
                                "
                                icon
                                color="blue"
                                dark
                                @click="onMoveUp(index)"
                                :disabled="isDisable"
                              >
                                <v-icon>keyboard_arrow_up</v-icon>
                              </v-btn>
                              <v-btn
                                v-if="
                                  activity.game_data.length > 1 &&
                                  index < activity.game_data.length - 1
                                "
                                icon
                                color="blue"
                                dark
                                @click="onMoveDown(index)"
                                :disabled="isDisable"
                              >
                                <v-icon>keyboard_arrow_down</v-icon>
                              </v-btn>
                              <v-btn
                                v-if="activity.game_data.length > 1"
                                icon
                                color="red"
                                dark
                                @click="onDeleteGame(index)"
                                :disabled="isDisable"
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </v-card-actions>
                            <v-flex>
                              <v-select
                                :items="constants.GAME_TYPE"
                                label="Game Type"
                                :value="data.game_type"
                                v-model="data.game_type"
                                v-on:change="
                                  e => onChangeGameData('game_type', e, index)
                                "
                                :disabled="isDisable"
                              ></v-select>
                            </v-flex>
                            <v-flex>
                              <v-select
                                :items="questStore.gameType[data.game_type]"
                                label="Category"
                                :value="data.game_id"
                                v-model="data.game_id"
                                v-on:change="
                                  e => onChangeGameData('game_id', e, index)
                                "
                                :disabled="isDisable"
                              ></v-select>
                            </v-flex>

                            <v-flex
                              v-if="
                                requiredSubIdList.indexOf(data.game_type) > -1
                              "
                            >
                              <container>
                                <v-layout
                                  column
                                  class="elevation-2"
                                  style="padding: 20px"
                                >
                                  <label>
                                    <u>⌗ Sub data</u>
                                  </label>
                                  <td
                                    v-for="(id, idx) in data.sub_id_list"
                                    :key="id"
                                  >
                                    <v-layout align-content-space-between>
                                      <v-select
                                        :items="subTitleList[index]"
                                        :value="data.sub_id_list[idx]"
                                        v-on:change="
                                          e =>
                                            onChangeSubTitleData(index, idx, e)
                                        "
                                        :disabled="isDisable"
                                      ></v-select>
                                      <v-btn
                                        icon
                                        dark
                                        color="black"
                                        v-clipboard:copy="id"
                                        :disabled="isDisable"
                                      >
                                        <v-icon>filter_none</v-icon>
                                      </v-btn>
                                    </v-layout>
                                  </td>
                                  <td
                                    v-if="
                                      selectedGameDataIndex == index &&
                                      newSubId == true
                                    "
                                  >
                                    <v-layout align-content-space-between>
                                      <v-select
                                        :items="subTitleList"
                                        :value="subTitleId"
                                        v-on:change="
                                          e =>
                                            onChangeSubTitleData(
                                              index,
                                              data.sub_id_list.length,
                                              e,
                                            )
                                        "
                                        :disabled="isDisable"
                                      ></v-select>
                                      <v-btn
                                        icon
                                        dark
                                        color="black"
                                        v-clipboard:copy="subTitleId"
                                        :disabled="isDisable"
                                      >
                                        <v-icon>filter_none</v-icon>
                                      </v-btn>
                                    </v-layout>
                                  </td>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      v-if="
                                        data.sub_id_list.length > 1 || newSubId
                                      "
                                      icon
                                      color="red"
                                      @click="onRemoveSubTitle(index)"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      icon
                                      color="grey"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="!newSubId"
                                      icon
                                      color="green"
                                      @click="onAddSubTitle(index)"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      icon
                                      color="grey"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                </v-layout>
                              </container>
                            </v-flex>
                            <v-flex
                              v-if="
                                requiredVideoData.indexOf(data.game_type) > -1
                              "
                            >
                              <container v-if="data.game_type === 'MNC'">
                                <v-layout
                                  column
                                  class="elevation-2"
                                  style="padding: 20px"
                                >
                                  <label>
                                    <u>⌗ Video data</u>
                                  </label>
                                  <v-flex
                                    v-for="(vdo, idx) in data.video_data"
                                    :key="idx"
                                    class="elevation-2"
                                    style="
                                      padding: 10px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    "
                                  >
                                    <label>Video {{ idx + 1 }}:</label>
                                    <v-flex>
                                      <v-checkbox
                                        label="Is Loop?"
                                        v-model="vdo.isLoop"
                                        :value="vdo.isLoop"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'isLoop',
                                              e,
                                            )
                                        "
                                        :disabled="isDisable"
                                      ></v-checkbox>
                                    </v-flex>
                                    <v-flex>
                                      <v-autocomplete
                                        label="Video Link"
                                        v-model="vdo.video_uri"
                                        :items="minichatVideoURIsState"
                                        chips
                                        hide-details
                                        hide-no-data
                                        hide-selected
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'video_uri',
                                              e,
                                            )
                                        "
                                        :disabled="isDisable"
                                      ></v-autocomplete>
                                    </v-flex>
                                    <v-flex>
                                      <v-text-field
                                        label="Next Index"
                                        :value="vdo.nextIndex"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'nextIndex',
                                              parseInt(e),
                                            )
                                        "
                                        :disabled="isDisable"
                                      ></v-text-field>
                                    </v-flex>
                                  </v-flex>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      v-if="
                                        data.video_data &&
                                        data.video_data.length > 1
                                      "
                                      icon
                                      color="red"
                                      @click="onRemoveVideoData(index)"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      icon
                                      color="grey"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      icon
                                      color="green"
                                      @click="onAddVideoData(index)"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                </v-layout>
                              </container>
                              <container v-else>
                                <v-layout
                                  column
                                  class="elevation-2"
                                  style="padding: 20px"
                                >
                                  <label>
                                    <u>⌗ Video data</u>
                                  </label>
                                  <v-flex
                                    v-for="(vdo, idx) in data.video_data"
                                    :key="idx"
                                    class="elevation-2"
                                    style="
                                      padding: 10px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    "
                                  >
                                    <label>Video {{ idx + 1 }}:</label>
                                    <v-flex>
                                      <v-checkbox
                                        label="Is Loop?"
                                        v-model="vdo.isLoop"
                                        :value="vdo.isLoop"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'isLoop',
                                              e,
                                            )
                                        "
                                        :disabled="isDisable"
                                      ></v-checkbox>
                                    </v-flex>
                                    <v-flex>
                                      <v-text-field
                                        label="Video Link"
                                        :value="vdo.video_uri"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'video_uri',
                                              e,
                                            )
                                        "
                                        :disabled="isDisable"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex>
                                      <v-text-field
                                        label="Next Index"
                                        :value="vdo.nextIndex"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'nextIndex',
                                              parseInt(e),
                                            )
                                        "
                                        :disabled="isDisable"
                                      ></v-text-field>
                                    </v-flex>
                                  </v-flex>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      v-if="
                                        data.video_data &&
                                        data.video_data.length > 1
                                      "
                                      icon
                                      color="red"
                                      @click="onRemoveVideoData(index)"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      icon
                                      color="grey"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      icon
                                      color="green"
                                      @click="onAddVideoData(index)"
                                      :disabled="isDisable"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                </v-layout>
                              </container>
                            </v-flex>
                          </v-layout>
                        </container>
                      </v-flex>

                      <v-btn
                        color="green"
                        @click="onAddMoreGame"
                        v-if="
                          requiredSubIdList.indexOf(
                            activity.game_data[0].game_type,
                          ) > -1
                        "
                        :disabled="isDisable"
                      >
                        <v-icon>add</v-icon>Add More Game
                      </v-btn>
                    </v-layout>
                    <v-card-actions>
                      <v-btn
                        color="red"
                        @click="onClickDeleteActivity(activity._id)"
                        v-if="
                          questStore.quest[selectedQuestIndex].activities
                            .length > 1
                        "
                        :disabled="isDisable"
                      >
                        <v-icon>delete</v-icon>Delete this activity
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-toolbar dark>
          <v-toolbar-title>Loading...</v-toolbar-title>
        </v-toolbar>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
  </Container>
</template>

<style scoped>
.image_thumbnail {
  height: 50px;
  width: 50px;
  margin-top: 0px;
}
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
</style>

<script>
import { mapState, mapActions } from 'vuex';
import ResourceSelect from '../components/ResourceSelect';
import Container from '../components/Container';
import draggable from 'vuedraggable';
import constants from '../constants';
export default {
  name: 'Quest',
  components: {
    ResourceSelect,
    Container,
    draggable,
  },
  computed: {
    ...mapState({
      questStore: state => state.quest,
      minichatVideoURIsState: state => state.minichatVideoURIs,
    }),
  },
  watch: {
    minichatVideoURIsState() {
      if (
        Array.isArray(this.minichatVideoURIsState) &&
        this.minichatVideoURIsState.length
      ) {
        return this.minichatVideoURIsState.forEach(
          async (videoLink, videoIndex) => {
            await this.onAutoGenerateMinichatVideoURIs(
              videoLink,
              videoIndex,
              this.gamedataIndex,
            );
          },
        );
      } else {
        return;
      }
    },
  },
  data: function () {
    return {
      constants,
      keyword: '',
      nowDate: new Date().toISOString().slice(0, 10),
      selectImageUri: '',
      isEditImage: false,
      isEditQuest: false,
      isEditActivity: false,
      isDisable: false,
      selectedQuestIndex: -1,
      selectedActivityIndex: -1,
      menu: false,
      select_activity: {},
      activity: {},
      selectActivityId: '',
      game_data: [],
      subTitleList: [],
      selectedGameDataIndex: -1,
      newSubId: false,
      subTitleId: '',
      selectGameType: '',
      selectGameId: '',
      requiredSubIdList: [
        'WWA',
        'ESL',
        'MC',
        'CN',
        'DD',
        'TTS',
        'MEDIA',
        'MM',
        'SW',
        'TR',
        'MPU',
        'CHR',
        'LB',
        'MNC',
      ],
      requiredVideoData: ['DD', 'TTS', 'MEDIA', 'MPU', 'TR', 'TB', 'MNC'],
      transition: [
        'LearningTime',
        'GameTime',
        'TalkTime',
        'StoryTime',
        'ReviewTime',
        'SongTime',
        'PhonicsTime',
      ],
    };
  },
  methods: {
    ...mapActions('quest', [
      'fetchAllGameType',
      'fetchQuest',
      'fetchLearningPathActivity',
      'addQuest',
      'editQuest',
      'deleteQuest',
      'stateEditQuest',
      'addActivity',
      'editActivity',
      'deleteActivity',
      'stateEditActivity',
      'clearUpdateQuest',
      'clearUpdateActivity',
      'fetchMinichatVideoURIs',
      'clearMinichatVideoURIs',
    ]),
    order(e) {
      this.selectedActivityIndex = -1;
      this.orderActivity({
        currentIndex: e.oldIndex,
        newIndex: e.newIndex,
        questId: this.questStore.quest[this.selectedQuestIndex]._id,
      });
    },
    onClickSelectQuest(index, id) {
      this.selectedQuestIndex = index;
      this.stateEditQuest({ key: '_id', value: id });
      this.quest = { ...this.questStore.quest[index] };
      this.isEditQuest = true;
      this.isEditActivity = false;
      this.selectedActivityIndex = -1;
    },
    onSaveEditQuest() {
      this.editQuest(this.questStore.selectedQuest);
      this.onCloseEditQuest();
    },
    onCloseEditQuest() {
      this.clearUpdateQuest();
      this.selectedQuestIndex = -1;
      this.isEditQuest = false;
    },
    onClickDeleteQuest(id) {
      this.selectedQuestIndex = -1;
      this.deleteQuest(id);
    },
    onClickAddQuest() {
      this.addQuest();
    },
    onChangeEditQuest(key, value) {
      this.stateEditQuest({ key, value });
    },

    onClickSelectActivity(index, id) {
      this.clearMinichatVideoURIs();
      this.clearUpdateActivity();
      this.selectedGameDataIndex = -1;
      this.selectedActivityIndex = index;
      this.selectActivityId = id;
      this.activity = {};
      this.newSubId = false;
      this.activity = {
        ...this.questStore.quest[this.selectedQuestIndex].activities[index],
      };
      const game_data = [...this.activity.game_data];
      Object.keys(game_data).forEach(index => {
        this.subTitleList[index] = this.getSubData(
          game_data[index].game_type,
          game_data[index].game_id,
          game_data[index].sub_id_list,
        );
      });
      this.game_data = game_data;
      this.questStore.selectedActivity = this.activity;
      this.stateEditActivity({
        key: '_id',
        value: id,
      });
      this.isEditActivity = true;
      this.isEditQest = false;
      this.isDisable = this.activity.lp_activity_id ? true : false;
    },
    clearLocalActivity() {
      this.activity = { ...this.questStore.emptyActivity };
      Object.keys(this.activity).forEach(key => {
        this.stateEditActivity({ key: key, value: this.activity[key] });
      });
    },
    getSubData(game_type, game_id) {
      if (this.requiredSubIdList.indexOf(game_type) < 0) {
        return;
      }
      const games = this.questStore.gameType[game_type];
      const index = games.findIndex(game => game.category_id === game_id);
      if (index == -1) {
        return;
      }

      if (game_type == 'ESL') {
        const subTitleList = this.questStore.gameType[game_type][index].books;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'CN') {
        const subTitleList = this.questStore.gameType[game_type][index].nodes;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'DD') {
        const subTitleList = this.questStore.gameType[game_type][index].sets;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'CHR') {
        const subTitleList = this.questStore.gameType[game_type][index].quests;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'MC' || game_type == 'MM' || game_type == 'SW') {
        const subTitleList = this.questStore.gameType[game_type][index].decks;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'MPU') {
        const subTitleList =
          this.questStore.gameType[game_type][index].activities;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'MEDIA') {
        const subTitleList = this.questStore.gameType[game_type][index].videos;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'TTS') {
        const subTitleList =
          this.questStore.gameType[game_type][index].object_sets;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'TR') {
        const subTitleList =
          this.questStore.gameType[game_type][index].blueprints;
        return subTitleList.map(data => {
          return {
            text: data.text,
            value: data._id,
          };
        });
      } else if (game_type == 'WWA') {
        const subTitleList =
          this.questStore.gameType[game_type][index].questions;
        return subTitleList.map(data => {
          return {
            text: data.question,
            value: data._id,
          };
        });
      } else if (game_type == 'LB') {
        const subTitleList =
          this.questStore.gameType[game_type][index].label_sets;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'MNC') {
        const subTitleList =
          this.questStore.gameType[game_type][index].minichats;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      }
    },

    onSaveEditActivity() {
      this.editActivity(this.questStore.selectedActivity);
      this.onCloseEditActivity();
    },
    onCloseEditActivity() {
      this.clearUpdateActivity();
      this.selectedActivityIndex = -1;
      this.isEditActivity = false;
    },
    onClickDeleteActivity(id) {
      this.deleteActivity(id);
    },
    onClickAddActivity() {
      this.addActivity();
    },
    onChangeEditActivity(key, value) {
      this.stateEditActivity({ key, value });
    },
    onLoadActivity(lp_activity) {
      const { _id, ...activity } = { ...lp_activity };
      this.activity = { lp_activity_id: _id, ...activity };
      Object.keys(this.activity).forEach(key => {
        this.stateEditActivity({ key: key, value: this.activity[key] });
      });
      this.isDisable = true;
    },
    async onChangeSubTitleData(index, idx, value) {
      const isMinichat = this.activity.game_data[index].game_type === 'MNC';
      if (isMinichat) {
        await this.fetchMinichatVideoURIs(value);
        this.gamedataIndex = index;
      }
      this.isDirty = true;
      if (this.selectedGameDataIndex == index && this.newSubId) {
        this.newSubId = false;
        this.selectedGameDataIndex = -1;
      }
      const game_data = [...this.activity.game_data];
      game_data[index].sub_id_list[idx] = value;
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
    },
    async onAutoGenerateMinichatVideoURIs(videoURI, videoIndex, gamedataIndex) {
      const game_data = [...this.activity.game_data];
      if (videoIndex > 0) {
        this.onAddVideoData(gamedataIndex);
        game_data[gamedataIndex].video_data[videoIndex].video_uri = videoURI;
        game_data[gamedataIndex].video_data[videoIndex].isLoop = false;
        game_data[gamedataIndex].video_data[videoIndex].nextIndex = -1;
      } else {
        game_data[gamedataIndex].video_data[videoIndex].video_uri = videoURI;
        game_data[gamedataIndex].video_data[videoIndex].isLoop = false;
        game_data[gamedataIndex].video_data[videoIndex].nextIndex = -1;
      }
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
    },
    onAddSubTitle(index) {
      this.selectedGameDataIndex = index;
      this.newSubId = true;
      this.subTitleId = '';
    },
    onRemoveSubTitle(index) {
      if (this.newSubId) {
        this.newSubId = false;
        this.selectedGameDataIndex = -1;
      } else {
        this.isDirty = true;
        const game_data = [...this.activity.game_data];
        game_data[index].sub_id_list.pop();
        this.stateEditActivity({
          key: 'game_data',
          value: game_data,
        });
      }
    },
    onChangeVideoData(index, idx, key, value) {
      this.isDirty = true;
      if (this.selectedGameDataIndex == index) {
        this.selectedGameDataIndex = -1;
      }
      const game_data = [...this.activity.game_data];
      if (key == 'isLoop' && !value) {
        value = false;
      }
      game_data[index].video_data[idx][key] = value;
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
    },
    onAddVideoData(index) {
      this.selectedGameDataIndex = index;
      const game_data = [...this.activity.game_data];
      game_data[index].video_data = game_data[index].video_data
        ? [...game_data[index].video_data, { ...this.questStore.videoData }]
        : [{ ...this.questStore.videoData }];
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
    },
    onRemoveVideoData(index) {
      this.isDirty = true;
      const game_data = [...this.activity.game_data];
      game_data[index].video_data.pop();
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
    },
    onAddMoreGame() {
      const new_game_data = { ...this.questStore.gameData };
      const game_data = [...this.activity.game_data, new_game_data];
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
      this.activity.game_data = game_data;
    },
    onDeleteGame(index) {
      const game_data = this.activity.game_data;
      game_data.splice(index, 1);
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
      this.activity.game_data = game_data;
    },
    onMoveUp(index) {
      const game_data = this.activity.game_data;
      game_data.splice(index - 1, 0, game_data.splice(index, 1)[0]);
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
      this.activity.game_data = game_data;
    },
    onMoveDown(index) {
      const game_data = this.activity.game_data;
      game_data.splice(index + 1, 0, game_data.splice(index, 1)[0]);
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
      this.activity.game_data = game_data;
    },
    onChangeActivityData(key, value) {
      if ((key == 'skip' || key == 'skip_activity') && !value) {
        value = false;
      }
      this.isDirty = true;
      this.stateEditActivity({
        key,
        value,
      });
    },
    onChangeGameData(key, value, index) {
      this.isDirty = true;
      const game_data = [...this.activity.game_data];
      const hasVideoData = this.requiredVideoData.indexOf(value) > -1;
      if (key === 'game_type' && hasVideoData) {
        // Reset video_data to default value if game_type that has videoData changes
        game_data[index].video_data = [
          { video_uri: '', isLoop: false, nextIndex: -1 },
        ];
      }
      game_data[index][key] = value;
      this.stateEditActivity({
        key: 'game_data',
        value: game_data,
      });
      if (key == 'game_id') {
        // Reset state of activity when user change category
        game_data[index].video_data = [
          { video_uri: '', isLoop: false, nextIndex: -1 },
        ];
        this.subTitleList[index] = this.getSubData(
          game_data[index].game_type,
          value,
        );
      }
    },

    closeAddImage() {
      this.selectImageUri = '';
      this.isAddImage = !this.isAddImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveImage() {
      const uri = this.selectImageUri;
      if (this.isEditQuest) {
        this.onChangeEditQuest('image_uri', uri);
        this.quest.image_uri = uri;
        this.isEditQuest = false;
      } else if (this.isEditActivity) {
        this.onChangeEditActivity('icon', uri);
        this.activity.icon = uri;
        this.isEditActivity = false;
      }
      this.isEditImage = !this.isEditImage;
      this.selectImageUri = '';
    },
  },
  mounted() {
    this.fetchAllGameType();
    this.fetchQuest();
  },
};
</script>
