<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>News Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="success" v-on:click="onClickAddNews">
            <v-icon>add</v-icon>Add News
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="newsStore"
            :search="search"
            :pagination.sync="pagination"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item._id }}</td>
              <td class="text-xs-center">{{ props.item.country }}</td>
              <td class="text-xs-center">{{ props.item.language }}</td>
              <td class="text-xs-center">{{ props.item.title }}</td>
              <td class="text-xs-center">{{ props.item.content }}</td>

              <td class="text-xs-center">
                <v-img
                  lazy="props.item.image_uri"
                  class="image_thumbnail"
                  :src="props.item.image_uri"
                ></v-img>
              </td>

              <td class="text-xs-center">
                {{ props.item.display_expired_date }}
              </td>
              <td class="text-xs-center">{{ props.item.created_at }}</td>
              <td class="justify-center layout px-0">
                <v-icon small class="mr-2" @click="onClickEditNews(props.item)"
                  >edit</v-icon
                >
                <v-icon small @click="onClickDeleteNews(props.item._id)"
                  >delete</v-icon
                >
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-flex>
    <!-- Add Modal -->
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeCreate"
      title="Add News"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-select
                @change="v => onChangeAddNews('country', v)"
                :items="constant.COUNTRY"
                :value="news.country"
                v-model="news.country"
                label="Country"
              ></v-select>
              <v-select
                @change="v => onChangeAddNews('language', v)"
                :items="constant.LANGUAGE"
                :value="news.language"
                v-model="news.language"
                label="Language"
              ></v-select>
              <v-text-field
                label="Title"
                :value="news.title"
                v-model="news.title"
                @change="v => onChangeAddNews('title', v)"
              ></v-text-field>

              <v-textarea
                label="Content"
                :value="news.content"
                v-model="news.content"
                :clearable="clearable"
                @change="v => onChangeAddNews('content', v)"
              ></v-textarea>

              <v-flex>
                <img
                  v-if="news.image_uri"
                  :src="news.image_uri"
                  class="content_img"
                />
                <v-text-field
                  label="Image URI (URL or Select file)"
                  :value="news.image_uri"
                  v-model="news.image_uri"
                  @change="v => onChangeAddNews('image_uri', v)"
                ></v-text-field>
                <v-btn v-on:click="isAddImage = true">
                  <v-icon>image</v-icon>Select Image
                </v-btn>
              </v-flex>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="News Expired Date"
                    prepend-icon="event"
                    v-model="news.expired_date"
                    readonly
                    :show-current="true"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="news.expired_date"
                  @input="menu = false"
                  @change="v => onChangeAddNews('expired_date', +new Date(v))"
                  :show-current="true"
                  :min="nowDate"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <ResourceSelect
            v-if="isAddImage"
            resourceType="image"
            :isShow="isAddImage"
            :close="closeAddImage"
            :save="saveImage"
            :value="selectImageUri"
            :onSelect="onSelectImage"
          />
        </v-container>
      </v-card>
    </Modal>
    <!-- Edit Modal -->
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onSaveEdit"
      :close="onCloseEdit"
      title="Edit News"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-select
                @change="v => onChangeEditNews('country', v)"
                :items="constant.COUNTRY"
                label="Country"
                :value="news.country"
                v-model="news.country"
              ></v-select>
              <v-select
                @change="v => onChangeEditNews('language', v)"
                :items="constant.LANGUAGE"
                label="Language"
                :value="news.language"
                v-model="news.language"
              ></v-select>
              <v-text-field
                label="Title"
                :value="news.title"
                v-model="news.title"
                @change="v => onChangeEditNews('title', v)"
              ></v-text-field>

              <v-textarea
                label="Content"
                v-model="news.content"
                :clearable="clearable"
                @change="v => onChangeEditNews('content', v)"
              ></v-textarea>

              <v-flex>
                <img
                  v-if="news.image_uri"
                  :src="news.image_uri"
                  class="content_img"
                />
                <v-text-field
                  label="Image URI (URL or Select file)"
                  :value="news.image_uri"
                  v-model="news.image_uri"
                  @change="v => onChangeEditNews('image_uri', v)"
                ></v-text-field>
                <v-btn v-on:click="isEditImage = true">
                  <v-icon>image</v-icon>Select Image
                </v-btn>
              </v-flex>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="News Expired Date"
                    prepend-icon="event"
                    v-model="news.edit_expired_date"
                    readonly
                    :show-current="true"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="news.edit_expired_date"
                  @input="menu = false"
                  @change="v => onChangeEditNews('expired_date', +new Date(v))"
                  :show-current="true"
                  :min="nowDate"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <ResourceSelect
            v-if="isEditImage"
            resourceType="image"
            :isShow="isEditImage"
            :close="closeEditImage"
            :save="saveImage"
            :value="selectImageUri"
            :onSelect="onSelectImage"
          />
        </v-container>
      </v-card>
    </Modal>
  </Container>
</template>

<style scoped>
.image_thumbnail {
  height: 50px;
  width: 50px;
  margin-top: 0px;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
import ResourceSelect from '../../components/ResourceSelect';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import constant from './constant';
export default {
  name: 'News',
  components: {
    ResourceSelect,
    Container,
    Modal,
  },
  computed: {
    ...mapState({
      newsStore: state => state.news.news,
      createdNewsStore: state => state.news.createNews,
      updatedNewsStore: state => state.news.updateNews,
    }),
  },
  data: function () {
    return {
      headers: [
        { text: 'ID', value: '_id', sortable: true },
        { text: 'Country', value: 'country', align: 'left', sortable: true },
        { text: 'Language', value: 'language', sortable: true },
        { text: 'Title', value: 'title', sortable: true },
        { text: 'Content', value: 'content', sortable: true },
        { text: 'Image', value: 'image_uri', sortable: true },
        { text: 'Expired Date', value: 'display_expired_date', sortable: true },
        { text: 'Created Date', value: 'created_at', sortable: true },
        { text: 'Action', value: 'action', sortable: false, align: 'center' },
      ],
      pagination: {
        rowsPerPage: 25,
      },
      search: '',
      constant,
      clearable: true,
      isCreate: false,
      isEdit: false,
      isAddImage: false,
      isEditImage: false,
      news: {},
      nowDate: new Date().toISOString().slice(0, 10),
      menu: false,
      selectImageUri: '',
      timestamp: null,
      editedIndex: -1,
    };
  },
  watch: {},
  methods: {
    ...mapActions('news', [
      'fetchNews',
      'addNews',
      'editNews',
      'deleteNews',
      'stateCreateNews',
      'stateEditNews',
      'clearCreateState',
      'clearUpdateState',
    ]),
    onClickEditNews(item) {
      this.stateEditNews({ key: '_id', value: item._id });
      this.isEdit = true;
      this.editedIndex = this.newsStore.indexOf(item);
      this.news = Object.assign({}, item);
    },
    onSaveEdit() {
      this.editNews(this.updatedNewsStore);
      this.onCloseEdit();
    },
    onCloseEdit() {
      this.clearUpdateState();
      this.isEdit = false;
    },
    onClickDeleteNews(id) {
      this.deleteNews(id);
    },
    onClickAddNews() {
      this.news = {};
      this.isCreate = true;
    },
    onChangeEditNews(key, value) {
      this.stateEditNews({ key, value });
    },
    onChangeAddNews(key, value) {
      this.stateCreateNews({ key, value });
    },
    onSaveCreate() {
      this.addNews(this.createdNewsStore);
      this.isCreate = false;
    },
    closeCreate() {
      this.isCreate = false;
      this.clearCreateState();
    },
    closeAddImage() {
      this.selectImageUri = '';
      this.isAddImage = !this.isAddImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveImage() {
      const uri = this.selectImageUri;
      if (this.isAddImage) {
        this.stateCreateNews({
          key: 'image_uri',
          value: uri,
        });
        this.isAddImage = !this.isAddImage;
      } else if (this.isEditImage) {
        this.stateEditNews({
          key: 'image_uri',
          value: uri,
        });
        this.isEditImage = !this.isEditImage;
      }
      this.news.image_uri = uri;
      this.selectImageUri = '';
    },
  },
  mounted() {
    this.fetchNews();
  },
};
</script>
