import http from '../../utils/httpClient';
import Constants from '../../constants';
import moment from 'moment';
import swal from 'sweetalert2';
const defaultQuest = {
  name: 'New Quest',
  quest_date: null,
};
const defaultActivity = {
  title: 'New Activity',
  transition: '',
  game_data: [
    {
      game_type: 'WWA',
      game_id: '5d0a074567fc4d57a818ef52',
      sub_id_list: [''],
      video_data: [
        {
          video_uri: '',
          isLoop: false,
          nextIndex: -1,
        },
      ],
    },
  ],
  videoData: {
    video_uri: '',
    isLoop: false,
    nextIndex: -1,
  },
};

const state = {
  quest: [],
  lpActivities: [],
  gameType: '',
  minichatVideoURIs: [],
  isLoadQuest: false,
  updateQuest: {},
  selectedQuest: {},
  updateActivity: {},
  selectedActivity: {},
  emptyActivity: {
    title: '',
    transition: '',
    game_data: [
      {
        game_type: '',
        game_id: '',
        sub_id_list: [''],
        video_data: [
          {
            video_uri: '',
            isLoop: false,
            nextIndex: -1,
          },
        ],
      },
    ],
    videoData: {
      video_uri: '',
      isLoop: false,
      nextIndex: -1,
    },
  },
};
const getters = {};
const actions = {
  fetchQuest({ commit, dispatch }) {
    commit('storeToggleLoadQuest');
    http
      .GET(`/api/quest`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(quest => ({
          ...quest,
          quest_date: +new Date(quest.quest_date),
          edit_quest_date: moment(quest.quest_date).format('YYYY-MM-DD'),
          display_quest_date: moment(quest.quest_date).format(
            'YYYY MMM DD  h:mm A',
          ),
          created_at: moment(quest.created_at).format('YYYY MMM DD  h:mm A'),
          updated_at: moment(quest.updated_at).format('YYYY MMM DD  h:mm A'),
        }));
        commit('storeQuest', formatData);
        commit('storeToggleLoadQuest');
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch quest at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchAllGameType({ commit }) {
    let gameType = {};
    let constants = [...Constants.GAME_TYPE];
    constants.shift();
    constants.forEach(async game => {
      const { data } = await http.GET(
        `/api/game/category?game_type=${game.value}`,
        {
          isAuthorization: true,
        },
      );
      gameType[game.value] = data.map(gameData => {
        return {
          ...gameData,
          text: gameData.category,
          value: gameData.category_id,
        };
      });
    });
    commit('storeGameType', gameType);
  },
  fetchLearningPathActivity({ commit, dispatch }, keyword) {
    http
      .GET(`/api/learningpath/activity/search?title=${keyword}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('storeLPActivity', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch quest at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  orderActivity({ state, dispatch }, { currentIndex, newIndex, questId }) {
    const index = state.quest.findIndex(quest => quest._id === questId);
    const currentActivityOrder = state.quest[index].activities.map(
      activity => activity._id,
    );
    const temp = currentActivityOrder[newIndex];
    currentActivityOrder[newIndex] = currentActivityOrder[currentIndex];
    currentActivityOrder[currentIndex] = temp;
    http
      .PUT(
        '/api/quest',
        {
          id: state.quest[index]._id,
          activities: currentActivityOrder,
        },
        {
          isAuthorization: true,
        },
      )
      .then(({ data }) => {
        dispatch('fetchQuest', state.quest[index]._id);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot update activity order.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },

  addQuest({ dispatch }) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating Quest',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/quest', defaultQuest, {
        isAuthorization: true,
      })
      .then(data => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Quest Successfully Created',
          },
          {
            root: true,
          },
        );
        dispatch('fetchQuest');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  editQuest({ dispatch }, questData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Editing Quest',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/quest', questData, {
        isAuthorization: true,
      })
      .then(data => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Quest Successfully Updated',
          },
          {
            root: true,
          },
        );
        dispatch('fetchQuest');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteQuest({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Quest',
        showCancelButton: true,
        type: 'warning',
      })
      .then(response => {
        if (response.value) {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'info',
              message: 'Deleting Quest',
            },
            {
              root: true,
            },
          );
          http
            .DELETE(`/api/quest?_id=${id}`, {
              isAuthorization: true,
            })
            .then(data => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Quest Successfully Deleted',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchQuest');
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: error.response.data,
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },

  addActivity({ dispatch }) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating Activity',
      },
      {
        root: true,
      },
    );
    http
      .PUT(
        '/api/quest',
        { _id: state.selectedQuest._id, activity: defaultActivity },
        {
          isAuthorization: true,
        },
      )
      .then(data => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Activity Successfully Created',
          },
          {
            root: true,
          },
        );
        dispatch('fetchQuest');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  editActivity({ dispatch }, activityData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Editing Activity',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/quest/activity', activityData, {
        isAuthorization: true,
      })
      .then(data => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Activity Successfully Updated',
          },
          {
            root: true,
          },
        );
        dispatch('fetchQuest');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteActivity({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Activity',
        showCancelButton: true,
        type: 'warning',
      })
      .then(response => {
        if (response.value) {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'info',
              message: 'Deleting Activity',
            },
            {
              root: true,
            },
          );
          http
            .DELETE(`/api/quest/activity?_id=${id}`, {
              isAuthorization: true,
            })
            .then(data => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Activity Successfully Deleted',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchQuest');
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: error.response.data,
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },

  stateEditQuest({ commit }, { key, value }) {
    commit('storeUpdateQuestData', {
      key,
      value,
    });
  },
  stateEditActivity({ commit }, { key, value }) {
    commit('storeUpdateActivityData', {
      key,
      value,
    });
  },
  clearUpdateQuest({ commit }) {
    commit('clearUpdateQuest');
  },
  clearUpdateActivity({ commit }) {
    commit('clearUpdateActivity');
  },
  storeMinichatVideoURIs(state, value) {
    if (value) {
      state.minichatVideoURIs = value;
    } else {
      delete state.minichatVideoURIs;
    }
  },
  clearMinichatVideoURIs(state) {
    state.minichatVideoURIs = [];
  },
};
const mutations = {
  storeToggleLoadQuest(state) {
    state.isLoadQuest = !state.isLoadQuest;
  },
  storeUpdateQuestData(state, { key, value }) {
    state.selectedQuest = {
      ...state.selectedQuest,
      [key]: value,
    };
  },
  storeUpdateActivityData(state, { key, value }) {
    state.selectedActivity = {
      ...state.selectedActivity,
      [key]: value,
    };
  },
  clearUpdateQuest(state) {
    state.selectedQuest = {};
  },
  clearUpdateActivity(state) {
    state.selectedActivity = {};
  },
  clearMinichatVideoURIs({ commit }) {
    commit('clearMinichatVideoURIs');
  },

  fetchMinichatVideoURIs({ commit, dispatch }, minichat_id) {
    http
      .GET(`/api/minichat/node/getVideoURIs?minichat_id=${minichat_id}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeMinichatVideoURIs', response.data.videoURIs);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Minichat VideoURIs might not available in this time. Please check again later.',
          },
          {
            root: true,
          },
        );
      });
  },
  storeGameType(state, gameType) {
    state.gameType = gameType;
  },
  storeLPActivity(state, value) {
    if (value) {
      state.lpActivities = [];
      value.forEach(activity => {
        if (activity.title) {
          state.lpActivities.push({ text: activity.title, value: activity });
        }
      });
    } else {
      delete state.lpActivities;
    }
  },
  storeQuest(state, value) {
    if (value) {
      state.quest = value;
    } else {
      delete state.quest;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
