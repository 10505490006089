<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Story Video Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="createLevel">
            <v-icon>add</v-icon>Create Level
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
            @change="onSearch"
            v-model="search"
          >
          </v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <draggable @end="order">
              <v-list-tile
                v-for="story in storyStore.storyvideoList"
                :key="story._id"
                @click="() => onClickEdit(story._id)"
              >
                <v-list-tile-avatar>
                  <v-icon>book</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ story.name }}</v-list-tile-title>
                  <v-list-tile-sub-title v-if="story.activities.length > 1">
                    {{ story.activities.length }} activities
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title v-else>
                    {{ story.activities.length }} activity
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </draggable>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable';
import Container from '../../components/Container';
export default {
  name: 'StoryList',
  components: {
    Container,
    draggable,
  },
  data: function () {
    return {
      search: '',
    };
  },
  methods: {
    ...mapActions('storyvideo', [
      'fetchStoryVideo',
      'createLevel',
      'deleteLevel',
      'orderLevel',
    ]),
    order(e) {
      this.orderLevel({
        currentIndex: e.oldIndex,
        newIndex: e.newIndex,
      });
    },
    onClickEdit(id) {
      this.$router.push(`/storyvideo?id=${id}`);
    },
    onSearch(name) {
      this.fetchStoryVideo({
        name,
      });
    },
  },
  mounted() {
    this.fetchStoryVideo();
  },
  computed: {
    ...mapState({
      storyStore: state => state.storyvideo,
    }),
  },
};
</script>

<style></style>
