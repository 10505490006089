<template>
  <div>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Change Container Part</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout column>
            <v-flex>
              <v-checkbox
                v-model="selectedChoice.container_change.is_translate"
                label="Is Translate"
                @change="e => onchangeContainer('is_translate', e)"
              ></v-checkbox>
              <v-text-field
                v-if="selectedChoice.container_change.is_translate"
                label="Translate Duration"
                type="number"
                :value="selectedChoice.container_change.translate_duration"
                @change="e => onchangeContainer('translate_duration', e)"
                :rules="[rules.required]"
              ></v-text-field>
              <img
                class="image_thumbnail"
                :src="selectedChoice.container_change.image_uri"
              />
              <v-text-field
                label="Image URI (URL or Select file)"
                :value="selectedChoice.container_change.image_uri"
                v-model="selectedChoice.container_change.image_uri"
                @change="e => onchangeContainer('image_uri', e)"
                :rules="[rules.validURI]"
              ></v-text-field>
              <v-btn class="btn-center" @click="onclickEditImage('image')"
                ><v-icon>image</v-icon>Select Image URI</v-btn
              >
              <SpriteSheet
                :title="'Use Sprite Sheet'"
                :isToggle="selectedChoice.container_change.is_sprite_sheet"
                :fieldname="'Edit Sheet Data'"
                :object="selectedChoice.container_change.sprite_sheet_data"
                @onchangeToggleSprite="
                  onchangeToggleSprite('is_sprite_sheet', $event)
                "
                @saveSpriteSheetData="
                  saveSpriteSheetData('sprite_sheet_data', $event)
                "
              />
              <v-text-field
                label="Sound URI (URL or Select file)"
                :value="selectedChoice.container_change.tap_sound_uri"
                v-model="selectedChoice.container_change.tap_sound_uri"
                v-on:change="e => onchangeContainer('tap_sound_uri', e)"
                :rules="[rules.validURI]"
              ></v-text-field>
              <v-btn class="btn-center" @click="onclickMusicSound('sound')"
                ><v-icon>audiotrack</v-icon>Select Sound URI</v-btn
              >
              <v-layout row>
                <v-text-field
                  type="number"
                  label="Start X"
                  :value="selectedChoice.container_change.startX"
                  @change="e => onchangeContainer('startX', e)"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="End X"
                  :value="selectedChoice.container_change.endX"
                  @change="e => onchangeContainer('endX', e)"
                ></v-text-field>
              </v-layout>
              <v-layout row>
                <v-text-field
                  type="number"
                  label="Start Y"
                  :value="selectedChoice.container_change.startY"
                  @change="e => onchangeContainer('startY', e)"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="End Y"
                  :value="selectedChoice.container_change.endY"
                  @change="e => onchangeContainer('endY', e)"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import rules from '../../../utils/ruleValidate';
import SpriteSheet from '../../../components/SpriteSheetNew.vue';

export default {
  components: {
    ResourceSelect,
    SpriteSheet,
  },
  data() {
    return {
      isEditImage: false,
      selectImageUri: '',
      isSelectImage: {
        image: false,
      },
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectMusic: {
        sound: false,
      },
      valid: true,
      rules,
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
    selectedSetIndex() {
      return this.dragComboStore.selectedSetIndex;
    },
    selectedNodeIndex() {
      return this.dragComboStore.selectedNodeIndex;
    },
    selectedChoiceIndex() {
      return this.dragComboStore.selectedChoiceIndex;
    },
    selectedChoice() {
      return this.dragComboStore.selectedChoice;
    },
    updateChoice() {
      return this.dragComboStore.updateChoice;
    },
  },
  methods: {
    ...mapActions('dragcombo', [
      'updateSet',
      'setStateByKey',
      'deleteSetDragCombo',
      'deleteNodeDragCombo',
      'clearStateByKey',
    ]),
    onclickEditImage(type) {
      this.isEditImage = true;
      this.isSelectImage[type] = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImage.image:
          this.onchangeContainer('image_uri', uri);
          this.isSelectImage.image = false;
          // this.setDirty('game');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onclickMusicSound(type) {
      this.isSelectSound = true;
      this.isSelectMusic[type] = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectMusic.sound:
          this.onchangeContainer('tap_sound_uri', uri);
          this.isSelectMusic.sound = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onchangeContainer(key, value) {
      const container = {
        ...this.selectedChoice.container_change,
        [key]: value,
      };
      this.setStateByKey({
        statekey: 'selectedChoice',
        key: 'container_change',
        value: container,
      });
      this.setStateByKey({
        statekey: 'updateChoice',
        key: 'container_change',
        value: container,
      });
      this.setStateByKey({
        statekey: 'updateNode',
        key: 'updateChoices',
        value: this.updateChoice,
      });
      this.setStateByKey({
        statekey: 'updateNode',
        key: 'indexChoice',
        value: this.selectedChoiceIndex,
      });
    },
    onchangeToggleSprite(key, { value }) {
      this.onchangeContainer(key, value);
    },
    saveSpriteSheetData(key, { value }) {
      this.onchangeContainer(key, value);
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
</style>
