<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/connectingnode')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (connectingnodeStore.selectConnectingNode &&
                connectingnodeStore.selectConnectingNode.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="connectingnodeStore.selectConnectingNode">
          <v-layout row grid-list-md v-if="!connectingnodeStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Node</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddNode">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(node, index) in connectingnodeStore
                      .selectConnectingNode.nodes"
                    :key="node._id"
                    v-on:click="onSelectNode(node._id)"
                    :class="index === selectedNodeIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ node.name }}</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Left: {{ node.left_nodes.length }}, Right:
                        {{ node.right_nodes.length }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedNodeIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Node Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickUpdateNode">
                    <v-icon>save</v-icon>Save Node
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data</v-btn
                      >
                      <v-checkbox
                        label="Available for Free User"
                        v-model="node.isGuestPlayable"
                        :value="node.isGuestPlayable"
                        v-on:change="
                          e => onChangeNodeData('isGuestPlayable', e)
                        "
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Node Name"
                        :value="node.name"
                        v-on:change="e => onChangeNodeData('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="TTS Message"
                        :value="node.tts_message"
                        v-on:change="e => onChangeNodeData('tts_message', e)"
                      ></v-text-field>
                      <v-select
                        :items="constants.VOICE_NAME"
                        label="Voice Name"
                        :value="node.voice_name"
                        v-model="node.voice_name"
                        v-on:change="e => onChangeNodeData('voice_name', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Match Count"
                        :value="node.match_count"
                        v-on:change="
                          e => onChangeNodeData('match_count', parseInt(e))
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        v-if="node.introduction_voice_uri"
                        class="white--text"
                        color="subtitle_sound"
                        @click="openSubtitleSound()"
                        >Subtitle Sound
                      </v-btn>
                      <v-text-field
                        label="Introduction Voice (URL or Select file)"
                        :value="node.introduction_voice_uri"
                        v-model="node.introduction_voice_uri"
                        @change="
                          e => onChangeNodeData('introduction_voice_uri', e)
                        "
                      ></v-text-field>
                      <v-btn
                        @click="
                          isEditSound = true;
                          isEditIntroVoice = true;
                        "
                        ><v-icon>audiotrack</v-icon>Select Introduction
                        Voice</v-btn
                      >
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music"
                        :value="node.bgm_uri"
                        v-model="node.bgm_uri"
                        v-on:change="e => onChangeNodeData('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          isEditNode = true;
                        "
                        >Select Sound</v-btn
                      >
                    </v-flex>

                    <v-list>
                      <v-list-tile
                        v-on:click="onSelectSide('Left')"
                        :class="side === 'Left' ? 'active' : 'inactive'"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title
                            >Left Nodes ({{
                              connectingnodeStore.selectConnectingNode.nodes[
                                selectedNodeIndex
                              ].left_nodes.length
                            }})</v-list-tile-title
                          >
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile
                        v-on:click="onSelectSide('Right')"
                        :class="side === 'Right' ? 'active' : 'inactive'"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title
                            >Right Nodes ({{
                              connectingnodeStore.selectConnectingNode.nodes[
                                selectedNodeIndex
                              ].right_nodes.length
                            }})</v-list-tile-title
                          >
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                  </v-layout>
                  <v-btn
                    color="red"
                    dark
                    v-on:click="onClickDeleteNode(node._id)"
                  >
                    <v-icon>delete</v-icon>Delete this node
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs2
              mr-2
              v-if="selectedNodeIndex != -1 && choices.length > 0"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>{{ side }} Nodes</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="green"
                    v-on:click="toggleAddChoice"
                    v-if="
                      side == 'Left' &&
                      connectingnodeStore.selectConnectingNode.nodes[
                        selectedNodeIndex
                      ].left_nodes.length < 4
                    "
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="green"
                    v-on:click="toggleAddChoice"
                    v-if="
                      side == 'Right' &&
                      connectingnodeStore.selectConnectingNode.nodes[
                        selectedNodeIndex
                      ].right_nodes.length < 4
                    "
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list v-if="side == 'Left'">
                  <v-list-tile
                    v-for="(choice, index) in connectingnodeStore
                      .selectConnectingNode.nodes[selectedNodeIndex].left_nodes"
                    :key="choice._id"
                    v-on:click="onSelectChoice(choice._id)"
                    :class="
                      index === selectedChoiceIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ index + 1 }}. {{ choice.text }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        Key: {{ choice.match_key }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
                <v-list v-if="side == 'Right'">
                  <v-list-tile
                    v-for="(choice, index) in connectingnodeStore
                      .selectConnectingNode.nodes[selectedNodeIndex]
                      .right_nodes"
                    :key="choice._id"
                    v-on:click="onSelectChoice(choice._id)"
                    :class="
                      index === selectedChoiceIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ index + 1 }}. {{ choice.text }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        Key: {{ choice.match_key }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="
                selectedNodeIndex != -1 &&
                selectedChoiceIndex != -1 &&
                Object.keys(choice).length > 1
              "
              xs4
              mr-2
            >
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Choice Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickSaveChoice">
                    <v-icon>save</v-icon>Save choice
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex xs6>
                        <v-layout column>
                          <v-flex>
                            <v-text-field
                              label="Text"
                              :value="choice.text"
                              v-on:change="e => onChangeChoiceData('text', e)"
                            />
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="TTS Message"
                              :value="choice.tts_message"
                              v-on:change="
                                e => onChangeChoiceData('tts_message', e)
                              "
                            ></v-text-field>
                            <v-select
                              :items="constants.VOICE_NAME"
                              label="Voice Name"
                              :value="choice.voice_name"
                              v-model="choice.voice_name"
                              v-on:change="
                                e => onChangeChoiceData('voice_name', e)
                              "
                            ></v-select>
                          </v-flex>
                          <v-flex>
                            <v-select
                              :items="[1, 2, 3, 4]"
                              label="Match Key"
                              :value="choice.match_key"
                              v-model="choice.match_key"
                              v-on:change="
                                e =>
                                  onChangeChoiceData('match_key', parseInt(e))
                              "
                            ></v-select>
                          </v-flex>
                          <v-flex>
                            <v-select
                              :items="constants.CARD_TYPE"
                              label="Choice Type"
                              :value="choice.type"
                              v-model="choice.type"
                              v-on:change="e => onChangeChoiceData('type', e)"
                            ></v-select>
                          </v-flex>
                          <v-flex
                            v-if="
                              choice.type &&
                              (choice.type == 'sound' ||
                                choice.type == 'image_sound')
                            "
                          >
                            <v-text-field
                              label="Sound (URL or Select file)"
                              :value="choice.sound_uri"
                              v-on:change="
                                e => onChangeChoiceData('sound_uri', e)
                              "
                            />
                            <v-btn
                              color="black"
                              v-on:click="isEditSound = true"
                            >
                              <v-icon>audiotrack</v-icon> Edit Sound
                            </v-btn>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              type="number"
                              label="Scale (%)"
                              :value="choice.scale"
                              v-model="choice.scale"
                              :rules="[rules.isNumber]"
                              @change="e => onChangeChoiceData('scale', e)"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        xs6
                        v-if="
                          choice.type &&
                          (choice.type == 'image' ||
                            choice.type == 'image_sound' ||
                            choice.type == 'image_tts')
                        "
                      >
                        <img
                          :src="choice.img_uri"
                          :alt="choice.choice"
                          class="content_img"
                        />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <v-text-field
                                label="Image (URL or Select file)"
                                :value="choice.img_uri"
                                v-on:change="
                                  e => onChangeChoiceData('img_uri', e)
                                "
                              />
                              <span class="headline" dark>
                                <v-btn
                                  color="black"
                                  v-on:click="isEditImage = true"
                                >
                                  <v-icon>image</v-icon>Edit Image
                                </v-btn>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-layout>
                    <v-btn
                      v-if="choices.length > 1"
                      color="red"
                      dark
                      v-on:click="onClickDeleteChoice()"
                    >
                      <v-icon>delete</v-icon>Delete this choice
                    </v-btn>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedNodeIndex !== -1
          ? connectingnodeStore.selectConnectingNode.nodes[selectedNodeIndex]
              .learned_words
          : null
      "
      :incidentalWords="
        selectedNodeIndex !== -1
          ? connectingnodeStore.selectConnectingNode.nodes[selectedNodeIndex]
              .incidental_words
          : null
      "
      :rewards="
        selectedNodeIndex !== -1
          ? connectingnodeStore.selectConnectingNode.nodes[selectedNodeIndex]
              .reward
          : null
      "
      :sentences="
        selectedNodeIndex !== -1
          ? connectingnodeStore.selectConnectingNode.nodes[selectedNodeIndex]
              .sentence
          : null
      "
      :phonics="
        selectedNodeIndex !== -1
          ? connectingnodeStore.selectConnectingNode.nodes[selectedNodeIndex]
              .phonic
          : null
      "
    >
    </PointData>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <Modal
      :v-if="isAddNode"
      :isOpen="isAddNode"
      :isLastStep="isLastStep"
      :close="toggleAddNode"
      :save="onClickAddNode"
      title="Add Node"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center v-if="currentStep == -1">
          <v-flex>
            <v-text-field
              label="Node Name"
              :value="node.name"
              v-model="node.name"
              v-on:change="e => onChangeCreateNode('name', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="TTS Message"
              :value="node.tts_message"
              v-on:change="e => onChangeCreateNode('tts_message', e)"
            ></v-text-field>
            <v-select
              :items="constants.VOICE_NAME"
              label="Voice Name"
              :value="node.voice_name"
              v-model="node.voice_name"
              v-on:change="e => onChangeCreateNode('voice_name', e)"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Match Count"
              :value="node.match_count"
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Background Music"
              :value="node.bgm_uri"
              v-model="node.bgm_uri"
              v-on:change="e => onChangeCreateNode('bgm_uri', e)"
            ></v-text-field>
            <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
          </v-flex>
        </v-layout>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title v-if="currentStep == 0">Left Node</v-toolbar-title>
            <v-toolbar-title v-if="currentStep == 1"
              >Right Node</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            <v-flex>
              <v-text-field
                label="Text"
                :value="choice.text"
                v-model="choice.text"
                v-on:change="e => onChangeCreateChoice('text', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Match Key"
                :value="choice.match_key"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-select
                :items="constants.CARD_TYPE"
                label="Choice Type"
                :value="choice.type"
                v-model="choice.type"
                v-on:change="e => onChangeCreateChoice('type', e)"
              ></v-select>
            </v-flex>
            <v-flex
              v-if="
                choice.type &&
                (choice.type == 'sound' || choice.type == 'image_sound')
              "
            >
              <v-text-field
                label="Sound"
                :value="choice.sound_uri"
                v-model="choice.sound_uri"
                v-on:change="e => onChangeCreateChoice('sound_uri', e)"
              >
              </v-text-field>
              <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
            </v-flex>
            <v-container
              justify-center
              v-if="
                choice.type &&
                (choice.type == 'image' ||
                  choice.type == 'image_sound' ||
                  choice.type == 'image_tts')
              "
            >
              <v-layout>
                <v-flex>
                  <v-img :src="choice.img_uri" width="400" height="300">
                    <v-btn v-on:click="isEditImage = true">Select Image</v-btn>
                  </v-img>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn
            v-if="currentStep > -1"
            color="red"
            dark
            v-on:click="onClickBackStep"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentStep + 1 < choices.length"
            color="green"
            dark
            v-on:click="onClickNextStep"
            >Next</v-btn
          >
        </v-card-actions>
      </v-container>
    </Modal>
    <Modal
      :v-if="isAddChoice"
      :isOpen="isAddChoice"
      :close="toggleAddChoice"
      :save="onClickAddChoice"
      :title="'Add Choice: ' + side"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex>
            <v-text-field
              label="Text"
              :value="choice.text"
              v-model="choice.text"
              v-on:change="e => onChangeCreateSingleChoice('text', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              :items="[1, 2, 3, 4]"
              label="Match Key"
              :value="choice.match_key"
              v-model="choice.match_key"
              v-on:change="
                e => onChangeCreateSingleChoice('match_key', parseInt(e))
              "
            ></v-select>
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.CARD_TYPE"
              label="Choice Type"
              :value="choice.type"
              v-model="choice.type"
              v-on:change="e => onChangeCreateSingleChoice('type', e)"
            ></v-select>
          </v-flex>
          <v-flex
            v-if="
              choice.type &&
              (choice.type == 'sound' || choice.type == 'image_sound')
            "
          >
            <v-text-field
              label="Sound"
              :value="choice.sound_uri"
              v-model="choice.sound_uri"
              v-on:change="e => onChangeCreateSingleChoice('sound_uri', e)"
              disabled
            >
            </v-text-field>
            <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
          </v-flex>
          <v-flex
            justify-center
            v-if="
              choice.type &&
              (choice.type == 'image' ||
                choice.type == 'image_sound' ||
                choice.type == 'image_tts')
            "
          >
            <v-img :src="choice.img_uri" class="choice">
              <v-btn v-on:click="isEditImage = true">Select Image</v-btn>
            </v-img>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
.content_img {
  height: 200px;
  width: 150px;
  border-radius: 5px;
}
.choice {
  height: 200px;
  width: 150px;
  align-content: center;
  justify-content: center;
}
</style>

<script>
import PointData from '../../components/PointData';
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import swal from 'sweetalert2';
import constants from '../../constants';
import rules from '../../utils/ruleValidate';

export default {
  name: 'ConnectingNode',
  components: {
    Container,
    ResourceSelect,
    Modal,
    PointData,
  },
  computed: {
    ...mapState({
      connectingnodeStore: state => state.connectingnode,
      selectedNodeIndexStore: state => state.connectingnode.selectedNodeIndex,
    }),
  },
  data() {
    return {
      isEditIntroVoice: false,
      isOpenPointData: false,
      currentStep: -1,
      isLastStep: false,
      lastStep: 1,
      maxChoice: 2,
      side: '',
      nodes: [],
      node: {},
      choices: [],
      choice: {},
      selectedNodeIndex: -1,
      selectedChoiceIndex: -1,
      isEditImage: false,
      selectImageUri: '',
      isEditSound: false,
      isEditNode: false,
      selectSoundUri: '',
      text: '',
      isDirty: false,
      isAddChoice: false,
      isAddNode: false,
      isUpdateNode: false,
      constants,
      selectNodeId: '',
      selectChoiceId: '',
      rules,
    };
  },
  methods: {
    ...mapActions('connectingnode', [
      'fetchConnectingNode',
      'fetchSingleConnectingNode',
      'updateChoiceData',
      'updateChoice',
      'clearUpdateData',
      'deleteNode',
      'deleteChoice',
      'updateCreateNodeData',
      'updateCreateChoiceData',
      'updateCreateSingleChoiceData',
      'updateNodeData',
      'updateNode',
      'addNode',
      'addChoiceByNodeId',
      'selectCategory',
      'selectConnectingNode',
    ]),
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.node.introduction_voice_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.updateNodeData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateNodeData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateNodeData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateNodeData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateNodeData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateNode();
      this.isOpenPointData = false;
    },
    onClickNextStep() {
      this.currentStep++;
      if (this.currentStep == this.lastStep) {
        this.isLastStep = true;
      }
      this.onActiveCreateChoice();
    },
    onClickBackStep() {
      this.currentStep--;
      this.onActiveCreateChoice();
    },
    async onSelectNode(id) {
      this.clearUpdateData();
      const nodes = this.connectingnodeStore.selectConnectingNode.nodes;
      const index = nodes.findIndex(node => node._id === id);
      this.selectedNodeIndex = index;
      this.selectedChoiceIndex = -1;
      this.choices = [];
      this.choice = {};
      this.side = '';
      this.left_nodes = [...nodes[index].left_nodes];
      this.right_nodes = [...nodes[index].right_nodes];
      this.node = {
        ...nodes[index],
      };
      this.updateNodeData({
        key: '_id',
        value: id,
      });
      this.selectNodeId = id;
    },
    onSelectSide(side) {
      this.clearUpdateData();
      this.selectedChoiceIndex = -1;
      this.side = side;
      if (side == 'Left') {
        this.choices = [
          ...this.connectingnodeStore.selectConnectingNode.nodes[
            this.selectedNodeIndex
          ].left_nodes,
        ];
      } else {
        this.choices = [
          ...this.connectingnodeStore.selectConnectingNode.nodes[
            this.selectedNodeIndex
          ].right_nodes,
        ];
      }
    },
    onSelectChoice(id) {
      if (this.isDirty) {
        swal
          .fire({
            titleText:
              'You have not save this choice yet. Are you sure you want to leave?',
            type: 'info',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              this.clearUpdateData();
              this.isDirty = false;
              const choices =
                this.side == 'Left'
                  ? [
                      ...this.connectingnodeStore.selectConnectingNode.nodes[
                        this.selectedNodeIndex
                      ].left_nodes,
                    ]
                  : [
                      ...this.connectingnodeStore.selectConnectingNode.nodes[
                        this.selectedNodeIndex
                      ].right_nodes,
                    ];
              const index = choices.findIndex(choice => choice._id === id);
              this.selectedChoiceIndex = index;
              this.choice = { ...choices[index] };
              this.selectImageUri = '';
              this.updateChoiceData({
                key: '_id',
                value: id,
              });
              this.selectChoiceId = id;
            }
          });
      } else {
        this.clearUpdateData();
        this.isDirty = false;
        const choices =
          this.side == 'Left'
            ? [
                ...this.connectingnodeStore.selectConnectingNode.nodes[
                  this.selectedNodeIndex
                ].left_nodes,
              ]
            : [
                ...this.connectingnodeStore.selectConnectingNode.nodes[
                  this.selectedNodeIndex
                ].right_nodes,
              ];

        const index = choices.findIndex(choice => choice._id === id);
        this.selectedChoiceIndex = index;
        this.choice = { ...choices[index] };
        this.selectImageUri = '';
        this.updateChoiceData({
          key: '_id',
          value: id,
        });
        this.selectChoiceId = id;
      }
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    onChangeChoiceData(key, value) {
      this.isDirty = true;
      this.choice[key] = value;
      this.updateChoiceData({
        key,
        value,
      });
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddNode) {
        this.updateCreateChoiceData({
          key: 'img_uri',
          value: uri,
          index: this.currentStep,
        });
      } else if (this.isAddChoice) {
        this.updateCreateSingleChoiceData({
          key: 'img_uri',
          value: uri,
        });
      } else {
        this.isDirty = true;
        this.updateChoiceData({
          key: 'img_uri',
          value: uri,
        });
      }
      this.choice.img_uri = uri;
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isAddNode && this.currentStep > -1) {
        this.updateCreateChoiceData({
          key: 'sound_uri',
          value: uri,
          index: this.currentStep,
        });
        this.choice.sound_uri = uri;
      } else if (this.isAddNode && this.currentStep == -1) {
        this.updateCreateNodeData({
          key: 'bgm_uri',
          value: uri,
        });
        this.node.bgm_uri = uri;
      } else if (this.isAddChoice) {
        this.updateCreateSingleChoiceData({
          key: 'sound_uri',
          value: uri,
        });
        this.choice.sound_uri = uri;
      } else if (this.isEditNode) {
        this.isDirty = true;
        this.updateNodeData({
          key: 'bgm_uri',
          value: uri,
        });
        this.node.bgm_uri = uri;
        this.isEditNode = false;
      } else if (
        !this.isAddChoice &&
        !this.isAddNode &&
        this.isEditIntroVoice
      ) {
        this.isDirty = true;
        this.updateNodeData({
          key: 'introduction_voice_uri',
          value: uri,
        });
        this.node.introduction_voice_uri = uri;
      } else {
        this.isDirty = true;
        this.updateChoiceData({
          key: 'sound_uri',
          value: uri,
        });
        this.choice.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    onClickSaveChoice() {
      const updateChoiceData = {
        ...this.connectingnodeStore.updateChoiceData,
      };
      this.updateChoice(updateChoiceData);
      this.isDirty = false;
      Object.keys(updateChoiceData).forEach(key => {
        this.choice[key] = updateChoiceData[key];
      });
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    onActiveCreateChoice() {
      const choices = [...this.connectingnodeStore.addChoiceData];
      if (
        this.currentStep == choices.length &&
        this.currentStep < this.maxChoice
      ) {
        const new_choice = { ...this.connectingnodeStore.choiceData };
        this.choice = { ...new_choice };
        this.choices = [...choices, new_choice];
        this.updateCreateChoiceData({
          key: 'text',
          value: '',
          index: this.currentStep,
        });
      } else {
        this.choice = { ...choices[this.currentStep] };
        this.choices = [...choices];
      }
    },
    onChangeCreateChoice(key, value) {
      this.updateCreateChoiceData({
        key,
        value,
        index: this.currentStep,
      });
    },
    onChangeCreateSingleChoice(key, value) {
      this.updateCreateSingleChoiceData({
        key,
        value,
      });
    },
    toggleAddNode() {
      // init node data
      this.updateCreateNodeData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.node = { ...this.connectingnodeStore.addNodeData };
      // init choice data
      this.choices = [...this.connectingnodeStore.addChoiceData];
      this.isAddNode = !this.isAddNode;
      if (!this.isAddNode && this.connectingnodeStore.selectedCategoryId) {
        this.$router.push('/connectingnodes');
      }
    },
    onClickAddNode() {
      const id = this.$route.query.id;
      const createNodeData = {
        ...this.connectingnodeStore.addNodeData,
      };
      const createChoiceData = [...this.connectingnodeStore.addChoiceData];
      this.addNode({
        category_id: id,
        node: createNodeData,
        choices: createChoiceData,
      });
      this.isAddNode = false;
    },
    onClickDeleteNode(id) {
      this.deleteNode(id);
      this.isDirty = false;
      this.selectedNodeIndex = -1;
      this.choice = {};
      this.choices = [];
      this.selectedChoiceIndex = -1;
    },
    toggleAddChoice() {
      this.choice = { ...this.connectingnodeStore.choiceData };
      this.isAddChoice = !this.isAddChoice;
    },
    onClickAddChoice() {
      const id = this.$route.query.id;
      const createChoiceData = {
        ...this.connectingnodeStore.addSingleChoiceData,
      };
      this.addChoiceByNodeId({
        category_id: id,
        node_id: this.selectNodeId,
        side: this.side,
        choice: createChoiceData,
      });
      this.isAddChoice = false;
    },
    onClickDeleteChoice() {
      this.deleteChoice(this.selectChoiceId);
      this.isDirty = false;
      this.selectedChoiceIndex = -1;
      this.side = '';
      this.choices = [];
    },
    onChangeNodeData(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.updateNodeData({
        key,
        value,
      });
    },
    onClickUpdateNode() {
      const updateNodeData = {
        ...this.connectingnodeStore.updateNodeData,
      };
      this.updateNode(updateNodeData);
      Object.keys(updateNodeData).forEach(key => {
        this.node[key] = updateNodeData[key];
      });
    },
    onChangeCreateNode(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.updateCreateNodeData({
        key,
        value,
      });
    },
  },
  watch: {
    selectedNodeIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectNode(this.$route.query.sub_id);
      }
    },
  },

  mounted() {
    if (this.$route.query.category_id) {
      this.fetchSingleConnectingNode({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (this.connectingnodeStore.selectedCategoryId) {
      this.toggleAddNode();
    } else if (
      Object.keys(this.connectingnodeStore.selectConnectingNode).length < 1
    ) {
      this.fetchSingleConnectingNode({ id: this.$route.query.id });
    }
  },
};
</script>
