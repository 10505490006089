<template>
  <Modal
    v-if="isOpen"
    :isOpen="isOpen"
    :save="save"
    :close="close"
    :isLastStep="valid"
    title="Assign To Home Group Data"
  >
    <v-card>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout grid-list-xs column>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step
                  :complete="e1 > 1"
                  step="1"
                  v-if="typeTagGame === constantHomePage.typeTagGame.SpeechLab"
                  >Add Tag</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="
                    typeTagGame === constantHomePage.typeTagGame.SpeechLab
                      ? e1 > 2
                      : e1 > 1
                  "
                  :step="
                    typeTagGame === constantHomePage.typeTagGame.SpeechLab
                      ? '2'
                      : '1'
                  "
                  >Select Topic Data</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="e1 > 2"
                  :step="
                    typeTagGame === constantHomePage.typeTagGame.SpeechLab
                      ? '3'
                      : '2'
                  "
                  >Select Group Data</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step
                  v-if="
                    type !== constantHomePage.GameType.LPActivity &&
                    type !== constantHomePage.GameType.TagGame &&
                    type !== constantHomePage.GameType.PgGame
                  "
                  :complete="e1 > 3"
                  step="3"
                  >Select Game</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="e1 > 4"
                  :step="
                    type === constantHomePage.GameType.LPGame ||
                    typeTagGame === constantHomePage.typeTagGame.SpeechLab
                      ? '4'
                      : '3'
                  "
                  >Enter info</v-stepper-step
                >
                <v-divider></v-divider>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content
                  step="1"
                  v-if="typeTagGame === constantHomePage.typeTagGame.SpeechLab"
                >
                  <v-card class="mb-4 pa-3">
                    <v-text-field
                      label="tags"
                      @input="e => checkTagsExist('tags', e)"
                      :rules="[rules.required, tagNotfound]"
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      @click="e1 = 2"
                      v-if="tagNotfound === true"
                      >Next</v-btn
                    >
                  </v-card>
                </v-stepper-content>
                <v-stepper-content
                  :step="
                    typeTagGame === constantHomePage.typeTagGame.SpeechLab
                      ? '2'
                      : '1'
                  "
                >
                  <v-card class="mb-5" color="grey lighten-1">
                    <v-data-table
                      :items="pageGameStore.homePageTopicList"
                      :headers="headers"
                      :pagination.sync="paginationTopic"
                      :rows-per-page-items="[20, 50, 100]"
                    >
                      <template v-slot:items="props">
                        <tr
                          class="row-pointer"
                          @click="
                            setTopic(
                              props.item._id,
                              (paginationTopic.page - 1) *
                                paginationTopic.rowsPerPage +
                                props.index,
                            )
                          "
                          :class="
                            (paginationTopic.page - 1) *
                              paginationTopic.rowsPerPage +
                              props.index ===
                            selectedTopic
                              ? 'active'
                              : 'inactive'
                          "
                        >
                          <td>
                            {{
                              (paginationTopic.page - 1) *
                                paginationTopic.rowsPerPage +
                              props.index
                            }}
                          </td>
                          <td>
                            {{ props.item.title }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="e1 = 1"
                    v-if="
                      typeTagGame === constantHomePage.typeTagGame.SpeechLab
                    "
                    >Back</v-btn
                  >
                  <!-- <v-btn color="primary" @click="e1 = 2">
                    Continue
                  </v-btn> -->
                </v-stepper-content>

                <v-stepper-content
                  :step="
                    typeTagGame === constantHomePage.typeTagGame.SpeechLab
                      ? '3'
                      : '2'
                  "
                >
                  <v-card class="mb-5" color="grey lighten-1">
                    <v-data-table
                      :items="pageGameStore.homePageList.group_datas"
                      :headers="headers"
                      :pagination.sync="pagination"
                      :rows-per-page-items="[20, 50, 100]"
                    >
                      <template v-slot:items="props">
                        <tr
                          class="row-pointer"
                          @click="
                            setData(
                              props.item._id,
                              (pagination.page - 1) * pagination.rowsPerPage +
                                props.index,
                            )
                          "
                          :class="
                            (pagination.page - 1) * pagination.rowsPerPage +
                              props.index ===
                            selectedHome
                              ? 'active'
                              : 'inactive'
                          "
                        >
                          <td>
                            {{
                              (pagination.page - 1) * pagination.rowsPerPage +
                              props.index
                            }}
                          </td>
                          <td>
                            {{ props.item.title }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="
                      e1 =
                        typeTagGame === constantHomePage.typeTagGame.SpeechLab
                          ? 2
                          : 1
                    "
                    >back</v-btn
                  >
                </v-stepper-content>

                <v-stepper-content
                  :step="
                    typeTagGame === constantHomePage.typeTagGame.SpeechLab
                      ? '4'
                      : '3'
                  "
                >
                  <v-card class="mb-4 pa-3">
                    <div v-if="type === constantHomePage.GameType.LPGame">
                      <v-data-table
                        v-if="gameData.game_data"
                        class="row-pointer"
                        :items="gameData.game_data"
                        :headers="headersLpGame"
                        :pagination.sync="paginationGame"
                        :rows-per-page-items="[20, 50, 100]"
                      >
                        <template v-slot:items="props">
                          <tr
                            :class="
                              (paginationGame.page - 1) *
                                paginationGame.rowsPerPage +
                                props.index ===
                              selectedGame
                                ? 'active'
                                : 'inactive'
                            "
                            @click="
                              onchangPageGroup(
                                'index_activity_game',
                                (paginationGame.page - 1) *
                                  paginationGame.rowsPerPage +
                                  props.index,
                              )
                            "
                          >
                            <td>
                              {{
                                (paginationGame.page - 1) *
                                  paginationGame.rowsPerPage +
                                props.index
                              }}
                            </td>
                            <td>
                              {{ displayGameType(props.item.game_type) }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <div v-else-if="type !== constantHomePage.GameType.LPGame">
                      <v-card class="mb-4 pa-3">
                        <v-text-field
                          label="title"
                          :value="createData.title"
                          @input="e => onchangPageGroup('title', e)"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field
                          label="Image URI (URL or Select file)"
                          :value="createData.img_uri"
                          v-model="createData.img_uri"
                          @change="e => onchangPageGroup('img_uri', e)"
                          :rules="[rules.validURI]"
                        ></v-text-field>
                        <v-btn class="btn-center" @click="onclickEditImage()"
                          ><v-icon>image</v-icon>Select Icon URI</v-btn
                        >
                      </v-card>
                    </div>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="
                      e1 =
                        typeTagGame === constantHomePage.typeTagGame.SpeechLab
                          ? 3
                          : 2
                    "
                    >back</v-btn
                  >
                </v-stepper-content>

                <v-stepper-content
                  :step="
                    typeTagGame === constantHomePage.typeTagGame.SpeechLab
                      ? '5'
                      : '4'
                  "
                >
                  <v-card class="mb-4 pa-3">
                    <v-text-field
                      label="title"
                      :value="createData.title"
                      @input="e => onchangPageGroup('title', e)"
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      label="Image URI (URL or Select file)"
                      :value="createData.img_uri"
                      v-model="createData.img_uri"
                      @change="e => onchangPageGroup('img_uri', e)"
                      :rules="[rules.validURI]"
                    ></v-text-field>
                    <v-btn class="btn-center" @click="onclickEditImage()"
                      ><v-icon>image</v-icon>Select Icon URI</v-btn
                    >
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="
                      type === constantHomePage.GameType.LPActivity
                        ? (e1 = 2)
                        : (e1 = 3)
                    "
                    >back</v-btn
                  >
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-layout>
        </v-form>
      </v-container>
    </v-card>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
  </Modal>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import ruleValidate from '../../../utils/ruleValidate';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import constantHomePage from '../constant';
import constants from '../../../constants';

export default {
  components: { Modal, ResourceSelect },
  data() {
    return {
      valid: true,
      rules: ruleValidate,
      createData: {},
      headers: [
        { text: 'Index', value: 'index', sortable: false },
        { text: 'Home Group Data Title', value: 'title', sortable: false },
      ],
      headersLpGame: [
        { text: 'No.', value: 'no', sortable: false },
        { text: 'Game Type', value: 'game_type', sortable: false },
      ],
      e1: 0,
      isEditImage: false,
      selectImageUri: '',
      selectedImageUri: '',
      selectedTopic: -1,
      selectedHome: -1,
      selectedGame: -1,
      constantHomePage: constantHomePage,
      constantsGameType: constants.GAME_TYPE_ABBREV_ENUMS,
      pagination: {},
      paginationGame: {},
      paginationTopic: {},
      tagsExists: [],
      tagNotfound: '',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    gameData: {
      type: Object,
      default: () => {},
      require: true,
    },
    type: {
      type: Number,
      default: 0,
      require: true,
    },
    typeTagGame: {
      type: Number,
      default: 0,
      require: true,
    },
    typePgGame: {
      type: Number,
      default: 0,
      require: true,
    },
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
      dictionaryStore: state => state.dicphonic,
    }),
    dictionaryList() {
      return this.dictionaryStore.dictionaryList;
    },
  },
  methods: {
    ...mapActions('homepage', [
      'fetchHomePageTopicListAll',
      'createPageData',
      'selectedHomeGroupDataById',
    ]),
    save() {
      this.createPageData(this.createData);
      this.clearData();
    },
    close() {
      this.clearData();
    },
    setData(group_id, index) {
      this.selectedHome = index;
      this.e1 =
        this.typeTagGame === constantHomePage.typeTagGame.SpeechLab ? 4 : 3;
      this.createData = { ...this.createData, group_id: group_id };
    },
    setTopic(topic_id, index) {
      this.selectedTopic = index;
      this.e1 =
        this.typeTagGame === constantHomePage.typeTagGame.SpeechLab ? 3 : 2;
      this.selectedHomeGroupDataById(topic_id);
    },
    onchangPageGroup(key, value) {
      if (key === 'index_activity_game') {
        this.selectedGame = value;
        this.e1 = 4;
        this.createData.data[key] = value;
      } else this.createData = { ...this.createData, [key]: value };
    },
    onclickEditImage() {
      this.isEditImage = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      // this.createData.img_uri = uri;
      this.onchangPageGroup('img_uri', uri);
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    clearData() {
      this.$emit('close');
      this.createData = {};
      this.e1 = 0;
      this.selectedHome = -1;
      this.selectedGame = -1;
      this.selectedTopic = -1;
      this.pagination.page = 1;
      this.paginationGame.page = 1;
      this.paginationTopic.page = 1;
    },
    displayGameType(game) {
      for (const [key, value] of Object.entries(this.constantsGameType)) {
        if (game === value) {
          return key;
        }
      }
    },
    checkTagsExist(key, value) {
      value = value.toLowerCase().trim();
      if (!this.tagsExists.length) {
        this.tagsExists = [
          ...new Set(this.dictionaryList.map(({ tags }) => tags).flat(2)),
        ];
      }
      if (this.tagsExists.includes(value)) {
        const getKeyData = { ...this.createData.data };
        getKeyData[key] = [value];
        this.createData = {
          ...this.createData,
          title: value,
          data: getKeyData,
        };
        this.tagNotfound = true;
      } else this.tagNotfound = 'tags not found.';
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        switch (this.type) {
          case constantHomePage.GameType.LPGame:
            this.createData = {
              ...this.pageGameStore.defaultHomeDataCreate,
              title: this.gameData.title,
              game_id: this.gameData._id,
              type: this.type,
              data: {
                ...this.pageGameStore.defaultHomeDataCreate.data,
                index_activity_game: 0,
              },
            };
            break;
          case constantHomePage.GameType.LPActivity:
            this.createData = {
              ...this.pageGameStore.defaultHomeDataCreate,
              title: this.gameData.title,
              game_id: this.gameData._id,
              type: this.type,
            };
            break;
          case constantHomePage.GameType.PgGame:
            const { _id, name, title } = this.gameData;
            this.createData = {
              ...this.pageGameStore.defaultHomeDataCreate,
              game_id: _id,
              title:
                this.typePgGame === this.constantHomePage.typePgGame.Book
                  ? name
                  : title,
              type: this.type,
              data: {
                ...this.pageGameStore.defaultHomeDataCreate.data,
                type: this.typePgGame,
                game_id: _id,
              },
            };
            break;
          case constantHomePage.GameType.TagGame:
            this.createData = {
              ...this.pageGameStore.defaultHomeDataCreate,
              title: this.gameData.word ? this.gameData.word : '',
              type: this.type,
              data: {
                type: this.typeTagGame,
                tags:
                  this.typeTagGame === constantHomePage.typeTagGame.Dictionary
                    ? [this.gameData.word]
                    : [],
              },
            };
            break;
          default:
            break;
        }
        this.fetchHomePageTopicListAll();
      }
    },
  },
};
</script>

<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>
