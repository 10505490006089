<template>
  <Container>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark>
          <v-toolbar-title>⭐ Admin Tool Login</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              prepend-icon="person"
              label="Username"
              type="text"
              v-model="username"
              autofocus
            ></v-text-field>
            <v-text-field
              prepend-icon="lock"
              name="password"
              label="Password"
              type="password"
              v-on:keyup.enter.native="loginUser({ username, password })"
              v-model="password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-on:click="loginUser({ username, password })"
            >Login</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-flex>
  </Container>
</template>
<script>
import Container from '../components/Container';
import { mapActions } from 'vuex';
export default {
  components: {
    Container,
  },
  data: function () {
    return {
      username: '',
      password: '',
    };
  },
  methods: { ...mapActions('userInfo', ['loginUser']) },
};
</script>
