<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Level Manager For Learningpath</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" light v-on:click="isCreate = true">
            <v-icon>add</v-icon>Create New Level
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout row grid-list-md>
            <v-flex xs3 mr-2>
              <v-card>
                <v-list>
                  <v-list-tile
                    v-for="(level, index) in levelStore.level"
                    :key="level._id"
                    v-on:click="onSelectLevel(level._id)"
                    :class="
                      index === selectedLevelIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-avatar>
                      <v-icon>apps</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ level.name }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedLevelIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Level Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="onSaveEdit">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn icon color="red" v-on:click="onClickDelete(level._id)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-text-field
                        label="Level"
                        :value="level.name"
                        v-on:change="e => onChangeContent('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Start"
                        :value="level.startIndex"
                        v-on:change="
                          e => onChangeContent('startIndex', parseInt(e))
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Length"
                        :value="level.length"
                        v-on:change="
                          e => onChangeContent('length', parseInt(e))
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="End"
                        :value="
                          levelStore.updateLevel.endIndex || level.endIndex
                        "
                        disabled
                      ></v-text-field>
                    </v-flex>

                    <v-btn
                      dark
                      color="black"
                      v-on:click="
                        onClickPreview(level.startIndex, level.length)
                      "
                    >
                      <v-icon color="yellow">star</v-icon> Preview
                    </v-btn>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs6 mr-2 v-if="isPreview">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Preview Prodution</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-list>
                    <v-list-tile
                      v-for="lp in levelStore.learnpathList"
                      :key="lp._id"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title> {{ lp.name }}</v-list-tile-title>
                        <v-list-tile-sub-title>
                          Index : {{ lp.index }}
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onClickSaveLevel"
      :close="closeCreate"
      title="Create New Level"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-text-field
                label="Level Name"
                v-model="levelStore.createLevel.name"
                v-on:change="e => onChangeCreateContent('name', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Start Index"
                v-model="levelStore.createLevel.startIndex"
                v-on:change="
                  e => onChangeCreateContent('startIndex', parseInt(e))
                "
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Length"
                v-model="levelStore.createLevel.length"
                v-on:change="e => onChangeCreateContent('length', parseInt(e))"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="End Index"
                v-model="levelStore.createLevel.endIndex"
                disabled
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </Modal>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import swal from 'sweetalert2';
import Container from '../components/Container';
import Modal from '../components/Modal';

export default {
  name: 'Level',
  components: {
    Container,
    Modal,
  },
  data: function () {
    return {
      isCreate: false,
      selectedLevelIndex: -1,
      isPreview: false,
    };
  },
  methods: {
    ...mapActions('level', [
      'fetchLevels',
      'fetchLP',
      'deleteLevel',
      'createLevel',
      'updateLevel',
      'editCreateLevel',
      'selectLevel',
      'clearSelected',
      'editLevel',
    ]),
    onSelectLevel(id) {
      const levelList = this.levelStore.level;
      const index = levelList.findIndex(level => level._id === id);
      this.selectedLevelIndex = index;
      this.level = { ...levelList[index] };
      this.levelStore.updateLevel.endIndex =
        parseInt(this.levelStore.level[index].startIndex) +
        parseInt(this.levelStore.level[index].length);
      this.editLevel({
        key: '_id',
        value: id,
      });
    },
    onClickDelete(id) {
      this.deleteLevel({ id });
    },
    closeEdit() {
      this.clearSelected();
    },
    closeCreate() {
      this.isCreate = false;
    },
    onClickSaveLevel() {
      const createLevelData = { ...this.levelStore.createLevel };
      this.createLevel(createLevelData);
      this.isCreate = false;
    },
    onChangeCreateContent(key, value) {
      this.levelStore.createLevel.endIndex =
        parseInt(this.levelStore.createLevel.startIndex) +
        parseInt(this.levelStore.createLevel.length);
      this.editCreateLevel({ key, value });
    },
    onChangeContent(key, value) {
      const startIndex =
        key == 'startIndex'
          ? value
          : this.levelStore.updateLevel.startIndex
          ? this.levelStore.updateLevel.startIndex
          : this.levelStore.level[this.selectedLevelIndex].startIndex;
      const length =
        key == 'length'
          ? value
          : this.levelStore.updateLevel.length
          ? this.levelStore.updateLevel.length
          : this.levelStore.level[this.selectedLevelIndex].length;
      this.levelStore.updateLevel.endIndex =
        parseInt(startIndex) + parseInt(length);
      this.editLevel({ key, value });
    },
    onSaveEdit() {
      this.selectedLevelIndex = -1;
      const updateLevel = this.levelStore.updateLevel;
      this.updateLevel({ ...updateLevel });
    },
    onClickPreview(startIndex, length) {
      this.isPreview = !this.isPreview;
      let filter = {
        startIndex: startIndex,
        length: length,
      };
      this.fetchLP(filter);
    },
  },
  mounted: function () {
    this.fetchLevels({ page: 1, limit: 0 });
  },

  computed: {
    ...mapState({
      levelStore: state => state.level,
    }),
  },
};
</script>
