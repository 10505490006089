<template>
  <v-dialog
    v-model="isOpen"
    persistent
    transition="slide-y-transition"
    :width="widthModal"
  >
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>{{ title }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="red" fab icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-layout wrap>
          <!-- el: menu -->
          <v-flex>
            <v-radio-group
              row
              v-model="selecting"
              :disabled="disabledButton.word_learned"
            >
              <v-radio
                label="Learned Word"
                v-model="dataset.word_learned"
              ></v-radio>
              <v-radio
                label="Incidental Word"
                v-model="dataset.word_incidental"
              ></v-radio>
              <v-radio label="Reward" v-model="dataset.reward"></v-radio>
              <v-radio label="Sentence" v-model="dataset.sentence"></v-radio>
              <v-radio label="Phonic" v-model="dataset.phonic"></v-radio>
            </v-radio-group>
            <v-btn
              :disabled="disabledButton.word_learned"
              v-if="selecting === dataset.word_learned"
              color="success"
              small
              fab
              @click="increaseStep(constant.dataset.word_learned)"
              ><v-icon>add</v-icon>
            </v-btn>
            <v-btn
              v-if="selecting === dataset.word_incidental"
              color="success"
              small
              fab
              @click="increaseStep(constant.dataset.word_incidental)"
              ><v-icon>add</v-icon>
            </v-btn>
            <v-btn
              v-if="selecting === dataset.sentence"
              color="success"
              small
              fab
              @click="increaseStep(constant.dataset.sentence)"
              ><v-icon>add</v-icon>
            </v-btn>
            <v-btn
              v-if="selecting === dataset.phonic"
              color="success"
              small
              fab
              @click="increaseStep(constant.dataset.phonic)"
              ><v-icon>add</v-icon>
            </v-btn>
          </v-flex>
          <!-- el: word_learned -->
          <v-flex xs12 sm12 md12 v-if="selecting === dataset.word_learned">
            <v-stepper v-model="element.word_learned">
              <v-stepper-header>
                <template v-for="n in step.word_learned">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="element.word_learned > n"
                    :step="n"
                    :editable="editableStepper.word_learned"
                  >
                    Index {{ n }}
                  </v-stepper-step>

                  <v-divider
                    v-if="n !== step.word_learned"
                    :key="n"
                  ></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content
                  v-for="n in step.word_learned"
                  :key="`${n}-content`"
                  :step="n"
                >
                  <v-flex>
                    <v-flex>
                      <v-subheader
                        >Learned Word: Index {{ n }}
                        <v-btn
                          v-if="selecting === dataset.word_learned"
                          color="error"
                          small
                          absolute
                          fab
                          right
                          @click="
                            reduceStep(
                              constant.dataset.word_learned,
                              element.word_learned,
                            )
                          "
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-subheader>
                    </v-flex>
                    <v-form v-model="formValidation.word_learned">
                      <v-text-field
                        required
                        :rules="wordRules"
                        label="💬 Word"
                        placeholder="Text"
                        v-model="word_learned.word"
                        @change="
                          value => onChangeCreateWordLearned('word', value, n)
                        "
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        label="🗣️ Speaking"
                        placeholder="Number"
                        v-model.number="word_learned.speaking"
                        @change="
                          value =>
                            onChangeCreateWordLearned(
                              'speaking',
                              parseInt(value),
                              n,
                            )
                        "
                      ></v-text-field>
                      <v-text-field
                        required
                        type="number"
                        label="👂 Listening"
                        placeholder="Number"
                        v-model.number="word_learned.listening"
                        @change="
                          value =>
                            onChangeCreateWordLearned(
                              'listening',
                              parseInt(value),
                              n,
                            )
                        "
                      ></v-text-field>
                      <v-flex>
                        <br />
                        <v-btn
                          @click="
                            isSelectWordLearnedImage = true;
                            isSelectImage = true;
                          "
                          >Select Image</v-btn
                        ><br />
                        <v-img
                          v-if="word_learned.img_uri"
                          :src="word_learned.img_uri"
                          class="content_img"
                        />
                        <v-text-field
                          label="Image URI"
                          v-model="word_learned.img_uri"
                          @change="
                            value =>
                              onChangeTextFieldCreateImage(
                                constant.dataset.word_learned,
                                value,
                                n,
                              )
                          "
                        ></v-text-field>
                      </v-flex>
                    </v-form>
                  </v-flex>
                  <v-btn
                    :disabled="disabledButton.word_learned"
                    flat
                    @click="backStep(n, constant.dataset.word_learned)"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    :disabled="disabledButton.word_learned"
                    color="primary"
                    @click="nextStep(n, constant.dataset.word_learned)"
                  >
                    Next
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-flex>
          <!-- el: word_incidental -->
          <v-flex xs12 sm12 md12 v-if="selecting === dataset.word_incidental">
            <v-stepper v-model="element.word_incidental">
              <v-stepper-header>
                <template v-for="n in step.word_incidental">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="element.word_incidental > n"
                    :step="n"
                    editable
                  >
                    Index {{ n }}
                  </v-stepper-step>

                  <v-divider
                    v-if="n !== step.word_incidental"
                    :key="n"
                  ></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content
                  v-for="n in step.word_incidental"
                  :key="`${n}-content`"
                  :step="n"
                >
                  <v-flex>
                    <v-subheader
                      >Incidental Word: Index {{ n }}
                      <v-btn
                        v-if="selecting === dataset.word_incidental"
                        color="error"
                        small
                        absolute
                        fab
                        right
                        @click="
                          reduceStep(
                            constant.dataset.word_incidental,
                            element.word_incidental,
                          )
                        "
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-form v-model="formValidation.word_incidental">
                      <v-text-field
                        label="💬 Word"
                        placeholder="Text"
                        v-model="word_incidental.word"
                        @change="
                          value =>
                            onChangeCreateWordIncidental('word', value, n)
                        "
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        label="🗣️ Speaking"
                        placeholder="Number"
                        v-model.number="word_incidental.speaking"
                        @change="
                          value =>
                            onChangeCreateWordIncidental(
                              'speaking',
                              parseInt(value),
                              n,
                            )
                        "
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        label="👂 Listening"
                        placeholder="Number"
                        v-model.number="word_incidental.listening"
                        @change="
                          value =>
                            onChangeCreateWordIncidental(
                              'listening',
                              parseInt(value),
                              n,
                            )
                        "
                      ></v-text-field>
                    </v-form>
                  </v-flex>
                  <v-flex>
                    <br />
                    <v-btn
                      @click="
                        isSelectWordIncidentalImage = true;
                        isSelectImage = true;
                      "
                      >Select Image</v-btn
                    ><br />
                    <v-img
                      v-if="word_incidental.img_uri"
                      :src="word_incidental.img_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Image URI"
                      v-model="word_incidental.img_uri"
                      v-on:change="
                        value =>
                          onChangeTextFieldCreateImage(
                            constant.dataset.word_incidental,
                            value,
                            n,
                          )
                      "
                    ></v-text-field>
                  </v-flex>
                  <v-btn
                    flat
                    @click="backStep(n, constant.dataset.word_incidental)"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="nextStep(n, constant.dataset.word_incidental)"
                  >
                    Next
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-flex>
          <!-- el: reward -->
          <v-flex xs12 sm12 md12 v-if="selecting === dataset.reward">
            <v-stepper v-model="element.reward">
              <v-stepper-header>
                <template v-for="n in step.reward">
                  <!-- <v-stepper-step
                    :key="`${n}-step`"
                    :complete="element.reward > n"
                    :step="n"
                    editable
                  >
                    Index {{ n }}
                  </v-stepper-step> -->

                  <v-divider v-if="n !== step.reward" :key="n"></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content
                  v-for="n in step.reward"
                  :key="`${n}-content`"
                  :step="n"
                >
                  <v-flex>
                    <v-subheader>🏆 Reward</v-subheader>
                    <v-form v-model="formValidation.reward">
                      <v-text-field
                        v-if="reward[0] && reward[0].name == 'jewel'"
                        type="number"
                        label="💎 Jewel"
                        placeholder="Number"
                        v-model.number="reward[0].amount"
                        @change="
                          value =>
                            onChangeCreateReward('amount', parseInt(value), 1)
                        "
                      ></v-text-field>
                    </v-form>
                  </v-flex>
                  <!-- <v-btn
                    color="primary"
                    @click="nextStep(n, constant.dataset.reward)"
                  >
                    Next
                  </v-btn>
                  <v-btn flat @click="backStep(n, constant.dataset.reward)">
                    Back
                  </v-btn> -->
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-flex>

          <!-- el: sentence -->
          <v-flex xs12 sm12 md12 v-if="selecting === dataset.sentence">
            <v-stepper v-model="element.sentence">
              <v-stepper-header>
                <template v-for="n in step.sentence">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="element.sentence > n"
                    :step="n"
                    editable
                  >
                    Index {{ n }}
                  </v-stepper-step>

                  <v-divider v-if="n !== step.sentence" :key="n"></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content
                  v-for="n in step.sentence"
                  :key="`${n}-content`"
                  :step="n"
                >
                  <v-flex>
                    <v-subheader
                      >Sentence: Index {{ n }}
                      <v-btn
                        v-if="selecting === dataset.sentence"
                        color="error"
                        small
                        absolute
                        fab
                        right
                        @click="
                          reduceStep(
                            constant.dataset.sentence,
                            element.sentence,
                          )
                        "
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-form v-model="formValidation.sentence">
                      <v-text-field
                        label="💬 Text"
                        placeholder="Text"
                        v-model="sentence.word"
                        @change="
                          value => onChangeCreateSentence('word', value, n)
                        "
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        label="🗣️ Speaking"
                        placeholder="Number"
                        v-model.number="sentence.speaking"
                        @change="
                          value =>
                            onChangeCreateSentence(
                              'speaking',
                              parseInt(value),
                              n,
                            )
                        "
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        label="👂 Listening"
                        placeholder="Number"
                        v-model.number="sentence.listening"
                        @change="
                          value =>
                            onChangeCreateSentence(
                              'listening',
                              parseInt(value),
                              n,
                            )
                        "
                      ></v-text-field>
                    </v-form>
                  </v-flex>
                  <v-btn flat @click="backStep(n, constant.dataset.sentence)">
                    Back
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="nextStep(n, constant.dataset.sentence)"
                  >
                    Next
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-flex>
          <!-- el: phonic -->
          <v-flex xs12 sm12 md12 v-if="selecting === dataset.phonic">
            <v-stepper v-model="element.phonic">
              <v-stepper-header>
                <template v-for="n in step.phonic">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="element.phonic > n"
                    :step="n"
                    editable
                  >
                    Index {{ n }}
                  </v-stepper-step>

                  <v-divider v-if="n !== step.phonic" :key="n"></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content
                  v-for="n in step.phonic"
                  :key="`${n}-content`"
                  :step="n"
                >
                  <v-flex>
                    <v-subheader
                      >Phonic: Index {{ n }}
                      <v-btn
                        v-if="selecting === dataset.phonic"
                        color="error"
                        small
                        absolute
                        fab
                        right
                        @click="
                          reduceStep(constant.dataset.phonic, element.phonic)
                        "
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-form v-model="formValidation.phonic">
                      <v-text-field
                        label="💬 Text"
                        placeholder="Text"
                        v-model="phonic.word"
                        @change="
                          value => onChangeCreatePhonic('word', value, n)
                        "
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        label="🗣️ Speaking"
                        placeholder="Number"
                        v-model.number="phonic.speaking"
                        @change="
                          value =>
                            onChangeCreatePhonic('speaking', parseInt(value), n)
                        "
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        label="👂 Listening"
                        placeholder="Number"
                        v-model.number="phonic.listening"
                        @change="
                          value =>
                            onChangeCreatePhonic(
                              'listening',
                              parseInt(value),
                              n,
                            )
                        "
                      ></v-text-field>
                    </v-form>
                  </v-flex>
                  <v-btn flat @click="backStep(n, constant.dataset.phonic)">
                    Back
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="nextStep(n, constant.dataset.phonic)"
                  >
                    Next
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions v-if="isLastStep">
        <v-btn color="red" @click="close" flat>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="type == 'create'"
          color="green"
          dark
          @click="
            save(
              wordLearnedStore,
              wordIncidentalStore,
              rewardStore,
              sentenceStore,
              phonicStore,
            )
          "
          >Save</v-btn
        >
        <v-btn v-if="type == 'confirm'" color="green" dark @click="save"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImage"
    />
  </v-dialog>
</template>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
.resourceSelect {
  overflow-y: none;
}
</style>

<script>
import ResourceSelect from '../components/ResourceSelect';
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  name: 'PointData',
  components: {
    ResourceSelect,
  },
  mounted() {},
  beforeUpdate() {},
  updated() {},
  computed: {
    ...mapState({
      pointDataStore: state => state.pointdata,
      wordLearnedStore: state => state.pointdata.word_learned,
      wordIncidentalStore: state => state.pointdata.word_incidental,
      rewardStore: state => state.pointdata.reward,
      sentenceStore: state => state.pointdata.sentence,
      phonicStore: state => state.pointdata.phonic,
    }),
    ...mapGetters('pointdata', [
      'getWordLearnedByIndex',
      'getWordLearned',
      'getWordIncidental',
      'getReward',
    ]),
    formValidator() {
      const isFormValid = this.formValidation.word_learned;
      if (isFormValid) {
        return true;
      } else {
        return false;
      }
    },
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    save: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: 'create',
    },
    learnedWords: {
      type: Array,
      default: () => [],
    },
    incidentalWords: {
      type: Array,
      default: () => [],
    },
    rewards: {
      type: Array,
      default: () => [],
    },
    sentences: {
      type: Array,
      default: () => [],
    },
    phonics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      disabledButton: {
        word_learned: false,
        word_incidental: false,
      },
      editableStepper: {
        word_learned: true,
        word_incidental: true,
      },
      widthModal: '60%',
      wordRules: [v => !!v || 'Word is required'],
      numberRules: [v => !!v || 'Number is required'],
      nameRules: [v => !!v || 'Reward name is required'],
      formValidation: {
        word_learned: false,
        word_incidental: false,
        reward: false,
      },
      isLastStep: false,
      selectedList: [],
      title: '',
      currentStep: -1,
      isSelectImage: false,
      isSelectWordLearnedImage: false,
      isSelectWordIncidentalImage: false,
      selectedImageUri: '',
      constant: {
        dataset: {
          word_learned: 'word_learned',
          word_incidental: 'word_incidental',
          reward: 'reward',
          sentence: 'sentence',
          phonic: 'phonic',
        },
        reward_name: ['jewel'],
      },
      datasetList: [
        'word_learned',
        'word_incidental',
        'reward',
        'sentence',
        'phonic',
      ],
      selecting: 0,
      dataset: {
        word_learned: 1,
        word_incidental: 2,
        reward: 3,
        sentence: 4,
        phonic: 5,
      },
      element: {
        word_learned: 1,
        word_incidental: 1,
        reward: 1,
        sentence: 1,
        phonic: 1,
      },
      step: {
        word_learned: 1,
        word_incidental: 1,
        reward: 1,
        sentence: 1,
        phonic: 1,
      },
      word_learned: [],
      word_incidental: [],
      reward: [],
      sentence: [],
      phonic: [],
    };
  },
  watch: {
    'formValidation.word_learned'(value) {
      if (value) {
        this.editableStepper.word_learned = true;
        this.disabledButton.word_learned = false;
      } else {
        this.editableStepper.word_learned = false;
        this.disabledButton.word_learned = true;
      }
    },
    wordLearnedStore() {
      this.word_learned = this.wordLearnedStore;
    },
    wordIncidentalStore() {
      this.word_incidental = this.wordIncidentalStore;
    },
    rewardStore() {
      this.reward = this.rewardStore;
    },
    sentenceStore() {
      this.sentence = this.sentenceStore;
    },
    phonicStore() {
      this.phonic = this.phonicStore;
    },
    formValidator(value) {
      if (value) {
        this.isLastStep = true;
      } else {
        this.isLastStep = false;
      }
    },
    selecting(value) {
      switch (value) {
        case this.dataset.word_learned:
          this.element.word_learned = this.step.word_learned;
          break;
        case this.dataset.word_incidental:
          this.element.word_incidental = this.step.word_incidental;
          break;
        case this.dataset.reward:
          this.element.reward = this.step.reward;
          break;
        case this.dataset.sentence:
          this.element.sentence = this.step.sentence;
          break;
        case this.dataset.phonic:
          this.element.phonic = this.step.phonic;
          break;
        default:
          break;
      }
    },
    async isOpen(value) {
      if (value) {
        const hasExistingData =
          (this.learnedWords && this.learnedWords.length > 0) ||
          (this.incidentalWords && this.incidentalWords.length > 0) ||
          (this.rewards && this.rewards.length > 0);
        if (hasExistingData) {
          this.title = 'Edit Point Data';
          if (this.learnedWords && this.learnedWords.length > 0) {
            await this.setEditDisplayWordLearned(this.learnedWords);
            await this.initialStepForExistingDataWordLearned();
          }
          if (this.incidentalWords && this.incidentalWords.length > 0) {
            await this.setEditDisplayWordIncidental(this.incidentalWords);
            await this.initialStepForExistingDataWordIncidental();
          }
          if (this.rewards && this.rewards.length > 0) {
            await this.setEditDisplayReward(this.rewards);
            await this.initialStepForExistingDataReward();
          }
          if (this.sentences && this.sentences.length > 0) {
            await this.setEditDisplaySentence(this.sentences);
            await this.initialStepForExistingDataSentence();
          }
          if (this.phonics && this.phonics.length > 0) {
            await this.setEditDisplayPhonic(this.phonics);
            await this.initialStepForExistingDataPhonic();
          }
          this.selecting = await 1;
        } else {
          this.title = 'Create Point Data';
          this.resetAllStep();
          this.resetAllState();
          this.selecting = await 1;
        }
      } else {
        this.resetAllState();
        this.resetAllStep();
      }
    },
    'element.word_learned'(step) {
      const index = step - 1;
      if (step > this.wordLearnedStore.length) {
        this.wordLearnedStore.word = '';
        this.wordLearnedStore.speaking = 0;
        this.wordLearnedStore.listening = 0;
        this.wordLearnedStore.img_uri = '';
      } else {
        this.getWordLearnedDataAtIndex(index);
      }
    },
    'element.word_incidental'(step) {
      const index = step - 1;
      if (step > this.wordIncidentalStore.length) {
        this.wordIncidentalStore.word = '';
        this.wordIncidentalStore.speaking = 0;
        this.wordIncidentalStore.listening = 0;
        this.wordIncidentalStore.img_uri = '';
      } else {
        this.getWordIncidentalDataAtIndex(index);
      }
    },
    'element.reward'(step) {
      const index = step - 1;
      if (step > this.rewardStore.length) {
        this.rewardStore.name = '';
        this.rewardStore.amount = 0;
      } else {
        this.getRewardDataAtIndex(index);
      }
    },
    'element.sentence'(step) {
      const index = step - 1;
      if (step > this.sentenceStore.length) {
        this.sentenceStore.word = '';
        this.sentenceStore.speaking = 0;
        this.sentenceStore.listening = 0;
      } else {
        this.getSentenceDataAtIndex(index);
      }
    },
    'element.phonic'(step) {
      const index = step - 1;
      if (step > this.phonicStore.length) {
        this.phonicStore.word = '';
        this.phonicStore.speaking = 0;
        this.phonicStore.listening = 0;
      } else {
        this.getPhonicDataAtIndex(index);
      }
    },
    'step.word_learned'(val) {
      this.validateWidth(val);
      if (this.element.word_learned > val) {
        this.element.word_learned = val;
      }
    },
    'step.word_incidental'(val) {
      this.validateWidth(val);
      if (this.element.word_incidental > val) {
        this.element.word_incidental = val;
      }
    },
    'step.reward'(val) {
      this.validateWidth(val);
      if (this.element.reward > val) {
        this.element.reward = val;
      }
    },
    'step.sentence'(val) {
      this.validateWidth(val);
      if (this.element.sentence > val) {
        this.element.sentence = val;
      }
    },
    'step.phonic'(val) {
      this.validateWidth(val);
      if (this.element.phonic > val) {
        this.element.phonic = val;
      }
    },
  },
  methods: {
    ...mapActions('pointdata', [
      'resetAllState',
      'setWordLearned',
      'setWordIncidental',
      'setReward',
      'setSentence',
      'setPhonic',
      'setEditDisplayWordLearned',
      'setEditDisplayWordIncidental',
      'setEditDisplayReward',
      'setEditDisplaySentence',
      'setEditDisplayPhonic',
    ]),
    validateWidth(val) {
      if (val > 6) {
        this.widthModal = '80%';
      } else if (val <= 6) {
        this.widthModal = '60%';
      }
    },
    getRewardDataAtIndex(index) {
      this.rewardStore.name = this.rewardStore[index].name;
      this.rewardStore.amount = this.rewardStore[index].amount;
    },
    getWordIncidentalDataAtIndex(index) {
      this.wordIncidentalStore.word = this.wordIncidentalStore[index].word;
      this.wordIncidentalStore.speaking =
        this.wordIncidentalStore[index].speaking;
      this.wordIncidentalStore.img_uri =
        this.wordIncidentalStore[index].img_uri;
      this.wordIncidentalStore.listening =
        this.wordIncidentalStore[index].listening;
    },
    getWordLearnedDataAtIndex(index) {
      this.wordLearnedStore.word = this.wordLearnedStore[index].word;
      this.wordLearnedStore.speaking = this.wordLearnedStore[index].speaking;
      this.wordLearnedStore.img_uri = this.wordLearnedStore[index].img_uri;
      this.wordLearnedStore.listening = this.wordLearnedStore[index].listening;
    },
    getSentenceDataAtIndex(index) {
      this.sentenceStore.word = this.sentenceStore[index].word;
      this.sentenceStore.speaking = this.sentenceStore[index].speaking;
      this.sentenceStore.listening = this.sentenceStore[index].listening;
    },
    getPhonicDataAtIndex(index) {
      this.phonicStore.word = this.phonicStore[index].word;
      this.phonicStore.speaking = this.phonicStore[index].speaking;
      this.phonicStore.listening = this.phonicStore[index].listening;
    },
    initialStepForExistingDataWordLearned() {
      let currentIndex = this.element.word_learned - 1;
      this.getWordLearnedDataAtIndex(currentIndex);
      this.step.word_learned = this.learnedWords.length;
    },
    initialStepForExistingDataWordIncidental() {
      let currentIndex = this.element.word_incidental - 1;
      this.getWordIncidentalDataAtIndex(currentIndex);
      this.step.word_incidental = this.incidentalWords.length;
    },
    initialStepForExistingDataReward() {
      this.step.reward = 1;
    },
    initialStepForExistingDataSentence() {
      let currentIndex = this.element.sentence - 1;
      this.getSentenceDataAtIndex(currentIndex);
      this.step.sentence = this.sentences.length;
    },
    initialStepForExistingDataPhonic() {
      let currentIndex = this.element.phonic - 1;
      this.getPhonicDataAtIndex(currentIndex);
      this.step.phonic = this.phonics.length;
    },
    resetAllStep() {
      this.step.word_learned = 1;
      this.step.word_incidental = 1;
      this.step.reward = 1;
      this.step.sentence = 1;
      this.step.phonic = 1;
    },
    convertNaNToZero(value) {
      if (Number.isNaN(value)) return 0;
      return value;
    },
    onChangeCreatePhonic(key, value, index) {
      value = this.convertNaNToZero(value);
      this.setPhonic({ key, value, index: index - 1 });
    },
    onChangeCreateSentence(key, value, index) {
      value = this.convertNaNToZero(value);
      this.setSentence({ key, value, index: index - 1 });
    },
    onChangeCreateWordLearned(key, value, index) {
      value = this.convertNaNToZero(value);
      this.setWordLearned({ key, value, index: index - 1 });
    },
    onChangeCreateWordIncidental(key, value, index) {
      value = this.convertNaNToZero(value);
      this.setWordIncidental({ key, value, index: index - 1 });
    },
    async onChangeCreateReward(key, value, index) {
      await this.setReward({ key, value, index: index - 1 });
      if (value) {
        this.selectedList.push(value);
      } else {
        this.selectedList.pop();
      }
    },
    onChangeTextFieldCreateImage(key, value, index) {
      if (key === this.constant.dataset.word_learned) {
        this.word_learned.img_uri = value;
        this.setWordLearned({
          key: 'img_uri',
          value,
          index: index - 1,
        });
      } else if (key === this.constant.dataset.word_incidental) {
        this.word_incidental.img_uri = value;
        this.setWordIncidental({
          key: 'img_uri',
          value,
          index: index - 1,
        });
      }
    },
    onSelectImage(uri) {
      this.selectedImageUri = uri;
    },
    saveSelectImage() {
      const uri = this.selectedImageUri;
      if (this.isSelectImage) {
        if (this.isSelectWordLearnedImage) {
          this.word_learned.img_uri = uri;
          this.setWordLearned({
            key: 'img_uri',
            value: uri,
            index: this.element.word_learned - 1,
          });
          this.isSelectWordLearnedImage = false;
        } else if (this.isSelectWordIncidentalImage) {
          this.word_incidental.img_uri = uri;
          this.setWordIncidental({
            key: 'img_uri',
            value: uri,
            index: this.element.word_incidental - 1,
          });
          this.isSelectWordIncidentalImage = false;
        }
      }
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    closeSelectImage() {
      if (this.isSelectWordLearnedImage) {
        this.isSelectWordLearnedImage = false;
      } else if (this.isSelectWordIncidentalImage) {
        this.isSelectWordIncidentalImage = false;
      }
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    increaseStep(element) {
      const max = 10;
      if (this.datasetList.includes(element)) {
        if (this.step[element] < max) {
          this.step[element] += 1;
        }
      }
    },
    reduceStep(element, step) {
      let index = step - 1;
      if (this.datasetList.includes(element)) {
        if (this.step[element] > 1) {
          this.step[element] -= 1;
          this[element].splice(index, 1);
          this.element[element] += 1;
        }
      }
    },
    nextStep(currentStep, name) {
      if (this.datasetList.includes(name)) {
        if (currentStep === this.step[name]) {
          this.element[name] = 1;
        } else {
          this.element[name] = currentStep + 1;
        }
      }
    },
    backStep(currentStep, name) {
      if (this.datasetList.includes(name)) {
        if (currentStep > 1) {
          this.element[name] -= 1;
        }
      }
    },
  },
};
</script>
