<template>
  <div>
    <v-btn
      class="btn-center primary"
      @click="isOpen = true"
      :disabled="!cellData.length"
      ><v-icon>dashboard</v-icon>Edit Format Cell</v-btn
    >
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :close="close"
      :isLastStep="valid && !this.isLock"
      :save="save"
      type="create"
      title="Format Cell"
    >
      <v-layout row wrap>
        <v-flex>
          <v-card>
            2x2 size(512x384)<br />
            2x1 size(512x184)<br />
            1x2 size(248x384)<br />
            1x1 size(248x184)<br />
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card-text class="text-md-center">Format Cell</v-card-text>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <div class="container">
              <table @click="selectedFormatCell(0)">
                <tr>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>3</td>
                </tr>
              </table>

              <table @click="selectedFormatCell(1)">
                <tr>
                  <td colspan="2">0</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>2</td>
                </tr>
              </table>

              <table @click="selectedFormatCell(2)">
                <tr>
                  <td>0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td colspan="2">2</td>
                </tr>
              </table>

              <table @click="selectedFormatCell(3)">
                <tr>
                  <td rowspan="0">0</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>2</td>
                </tr>
              </table>

              <!-- <table @click="selectedFormatCell(4)">
                <tr>
                  <td>0</td>
                  <td rowspan="0">1</td>
                </tr>
                <tr>
                  <td>2</td>
                </tr>
              </table> -->

              <table @click="selectedFormatCell(5)">
                <tr>
                  <td style="height: 100px">0</td>
                  <td style="height: 100px">1</td>
                </tr>
              </table>

              <table @click="selectedFormatCell(6)">
                <tr>
                  <td style="width: 100px">0</td>
                </tr>
                <tr>
                  <td style="width: 100px">1</td>
                </tr>
              </table>

              <table @click="selectedFormatCell(7)">
                <tr>
                  <td style="width: 100px; height: 100px">0</td>
                </tr>
              </table>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card-text class="text-md-center">Set Format Cell</v-card-text>
        </v-flex>
        <v-flex xs12>
          <v-card align-center>
            <div v-if="!selectedCell.length">
              <v-card-text class="text-md-center">
                Please Click Format Cell
              </v-card-text>
            </div>
            <div :set="(idx = 0)" class="container" v-else>
              <table
                v-for="(item, index) in selectedCell"
                :key="index"
                @click="removeSelectedFormatCell(index)"
              >
                <div v-if="item === 0">
                  <tr>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                </div>

                <div v-if="item === 1">
                  <tr>
                    <td
                      colspan="2"
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        style="height: 110px"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td colspan="2" v-else></td>
                  </tr>
                  <tr>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                </div>

                <div v-if="item === 2">
                  <tr>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                        style="height: 110px"
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else colspan="2"></td>
                  </tr>
                </div>

                <div v-if="item === 3">
                  <tr>
                    <td
                      rowspan="0"
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else rowspan="0"></td>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                </div>

                <!-- <div v-if="item === 4">
                  <tr>
                    <td>0</td>
                    <td rowspan="0">1</td>
                  </tr>
                  <tr>
                    <td>2</td>
                  </tr>
                </div> -->

                <div v-if="item === 5">
                  <tr>
                    <td
                      style="height: 240px"
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                      style="height: 240px"
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                </div>

                <div v-if="item === 6">
                  <tr>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                      style="width: 100px"
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                      style="width: 100px"
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                </div>

                <div v-if="item === 7">
                  <tr>
                    <td
                      v-if="
                        idx <
                        pageGameStore.selectedHomePageData.cell_datas.length
                      "
                      style="width: 100px; height: 240px"
                    >
                      {{ idx }}
                      <img
                        class="image_cell"
                        :src="
                          pageGameStore.selectedHomePageData.cell_datas[idx]
                            .img_uri
                        "
                        style="width: 100px; height: 100px"
                      />
                      {{
                        pageGameStore.selectedHomePageData.cell_datas[idx++]
                          .title
                      }}
                    </td>
                    <td v-else></td>
                  </tr>
                </div>
              </table>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import constant from '../constant';
import { mapActions, mapState } from 'vuex';

export default {
  components: { Modal },
  data() {
    return {
      HCellFormat: constant.HCellFormat,
      selectedCell: [],
      cellData: [],
      isOpen: false,
      valid: true,
    };
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
      isLock: state => state.homepage.adminIsLock,
    }),
    limitFormatCell() {
      let limit = 0;
      this.selectedCell.forEach(e => {
        limit += this.formatCell(e).length;
      });
      return limit;
    },
  },
  methods: {
    ...mapActions('homepage', [
      'setStateByKey',
      'clearStateByKey',
      'updatePageGroupData',
    ]),
    selectedFormatCell(type) {
      if (!this.isLock) {
        if (this.limitFormatCell <= this.cellData.length - 1) {
          this.selectedCell.push(type);
        }
      }
    },
    removeSelectedFormatCell(index) {
      if (!this.isLock) {
        if (this.selectedCell.length) {
          // this.selectedCell = this.selectedCell.filter(i => type != i);
          this.selectedCell.splice(index, 1);
        }
      }
    },
    formatCell(type) {
      switch (type) {
        case 0: //[1x1,1x1,1x1,1x1]
          return [
            this.HCellFormat[0].value,
            this.HCellFormat[0].value,
            this.HCellFormat[0].value,
            this.HCellFormat[0].value,
          ];
        case 1: //[2x1,1x1,1x1]
          return [
            this.HCellFormat[1].value,
            this.HCellFormat[0].value,
            this.HCellFormat[0].value,
          ];
        case 2: //[1x1,1x1,2x1]
          return [
            this.HCellFormat[0].value,
            this.HCellFormat[0].value,
            this.HCellFormat[1].value,
          ];
        case 3: //[1x2,1x1,1x1]
          return [
            this.HCellFormat[2].value,
            this.HCellFormat[0].value,
            this.HCellFormat[0].value,
          ];
        // case 4: //[1x1,1x2,1x1]
        //   return `${this.HCellFormat[0].value},${this.HCellFormat[2].value},${this.HCellFormat[0].value}`;
        // break;
        case 5: //[1x2,1x2]
          return [this.HCellFormat[2].value, this.HCellFormat[2].value];
        case 6: //[2x1,2x1]
          return [this.HCellFormat[1].value, this.HCellFormat[1].value];
        case 7: //[2x2]
          return [this.HCellFormat[3].value];
        default:
          break;
      }
    },
    save() {
      this.isOpen = false;
      let formatCell = [];
      this.selectedCell.forEach(type => {
        formatCell = formatCell.concat(this.formatCell(type));
      });
      this.setStateByKey({
        statekey: 'updatePageGroup',
        key: 'selected_cell',
        value: this.selectedCell,
      });
      this.setStateByKey({
        statekey: 'updatePageGroup',
        key: 'format_cell',
        value: formatCell,
      });
      this.updatePageGroupData();
    },
    close() {
      this.selectedCell = [
        ...this.pageGameStore.selectedHomePageData.selected_cell,
      ];
      this.isOpen = false;
    },
  },
  created() {
    if (this.pageGameStore.selectedHomePageData.selected_cell) {
      this.selectedCell = [
        ...this.pageGameStore.selectedHomePageData.selected_cell,
      ];
    }
    if (this.pageGameStore.selectedHomePageData.cell_datas) {
      this.cellData = [...this.pageGameStore.selectedHomePageData.cell_datas];
    }
  },
  watch: {
    'pageGameStore.selectedHomePageData'() {
      this.selectedCell = [
        ...this.pageGameStore.selectedHomePageData.selected_cell,
      ];
      this.cellData = [...this.pageGameStore.selectedHomePageData.cell_datas];
    },
  },
};
</script>

<style scoped>
table:hover {
  cursor: pointer;
}
table {
  border-collapse: collapse;
  margin-right: 5px;
}
td {
  width: 50px;
  height: 50px;
  background-color: #00b0ff;
  text-align: center;
  border: 2px solid black;
}
.container {
  display: flex;
  flex-direction: row;
}
.image_cell {
  height: 70px;
  width: 90px;
  padding: 2px;
  margin: 0 auto;
}
</style>
