<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Tap To Say Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" v-on:click="toggleSelectCategory">
            <v-icon>add</v-icon>
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="taptosay in taptosayStore.taptosay"
              :key="taptosay.category_id"
              v-on:click="() => onClickEdit(taptosay.category_id)"
            >
              <v-list-tile-avatar>
                <v-icon>insert_emoticon</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ taptosay.category }} ({{
                    taptosay.object_sets
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ taptosay.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <Category
      :v-if="isSelectCategory"
      :isOpen="isSelectCategory"
      :close="toggleSelectCategory"
      :onSelect="onClickSelectCategory"
      :value="categoryId"
      title="Select Category"
    >
    </Category>
  </Container>
</template>
<script>
import Container from '../../components/Container';
import Category from '../../components/Category';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'TapToSayList',
  components: {
    Container,
    Category,
  },
  data: function () {
    return {
      isSelectCategory: false,
      categoryId: '',
    };
  },
  methods: {
    ...mapActions('taptosay', [
      'fetchTapToSay',
      'selectTapToSay',
      'selectCategory',
    ]),
    onClickEdit(id) {
      // this.selectTapToSay(id);
      // this.selectCategory();
      this.$router.push({
        path: '/taptosay/detail',
        query: {
          id,
        },
      });
    },
    toggleSelectCategory() {
      this.isSelectCategory = !this.isSelectCategory;
    },
    onClickSelectCategory(id) {
      this.isSelectCategory = false;
      this.selectCategory(id);
      this.selectTapToSay();
      this.$router.push({
        path: '/taptosay/detail',
        query: {
          id,
        },
      });
    },
  },
  mounted() {
    this.fetchTapToSay({
      page: 1,
      limit: 0,
    });
  },
  computed: {
    ...mapState({
      taptosayStore: state => state.taptosay,
    }),
  },
};
</script>
