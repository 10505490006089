export const marketingMenu = [
  {
    icon: 'person',
    title: 'Users',
    path: '/users',
  },
  {
    icon: 'aspect_ratio',
    title: 'Promotion Code',
    path: '/code',
  },
  {
    icon: 'audiotrack',
    title: 'Resource',
    path: '/resource',
  },
  {
    icon: 'subtitles',
    title: 'Subtitle Video Localization',
    path: '/subtitle_video_localization',
  },
  {
    icon: 'navigation',
    title: 'Content Navigation',
    path: '/content_navigation',
  },
  {
    icon: 'inventory',
    title: 'Postbox',
    path: '/postbox',
  },
  {
    icon: 'chat',
    title: 'Customer Service Chat',
    path: '/customer_service_chat',
  },
];
