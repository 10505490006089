<template>
  <div>
    <v-flex>
      <v-text-field
        disabled
        required
        :rules="nameRules"
        :error-messages="errorMessages"
        v-model="node.node_name"
        label="Node Name"
      ></v-text-field>
    </v-flex>

    <v-flex v-if="skipLegacyProcessStatus === true">
      <v-checkbox
        label="Hide Correct Star"
        v-model="node.hide_correct_star"
      ></v-checkbox>
    </v-flex>
    <v-flex>
      <v-select
        label="Start Animation"
        :items="constant.START_ANIMATION"
        v-model="node.start_animation"
      ></v-select>
    </v-flex>
    <v-flex>
      <v-combobox label="Word" v-model="node.word" chips multiple></v-combobox>
    </v-flex>
    <v-flex>
      <v-autocomplete
        label="Goto Node"
        v-model="node.goto_node"
        :items="gotoNodeStore"
        item-text="display"
        item-value="node_name"
        chips
        multiple
        hide-details
        hide-no-data
        hide-selected
      ></v-autocomplete>
    </v-flex>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constant from '../../../constant';
import constants from '../../../../../constants';

export default {
  name: 'UpdateAnswerNode',
  components: {},
  computed: {
    ...mapState({
      gotoNodeStore: state => state.minichat.gotoNodeData,
      skipLegacyProcessStatus: state =>
        state.minichat.minichatData.skip_legacy_process,
    }),
  },
  props: {
    node: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      constant,
      constants,
      nameRules: [v => !!v || 'Name is required'],
      typeRules: [v => !!(v > -1) || 'Type is required'],
      errorMessages: '',
      width: '60%',
    };
  },
  methods: {},
};
</script>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
