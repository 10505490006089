var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isOpen)?_c('Modal',{attrs:{"isOpen":_vm.isOpen,"save":_vm.save,"close":_vm.close,"isLastStep":_vm.valid,"title":"Assign To Home Group Data"}},[_c('v-card',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"grid-list-xs":"","column":""}},[_c('v-stepper',{model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[(_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab)?_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 1,"step":"1"}},[_vm._v("Add Tag")]):_vm._e(),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                    ? _vm.e1 > 2
                    : _vm.e1 > 1,"step":_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                    ? '2'
                    : '1'}},[_vm._v("Select Topic Data")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 2,"step":_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                    ? '3'
                    : '2'}},[_vm._v("Select Group Data")]),_c('v-divider'),(
                  _vm.type !== _vm.constantHomePage.GameType.LPActivity &&
                  _vm.type !== _vm.constantHomePage.GameType.TagGame &&
                  _vm.type !== _vm.constantHomePage.GameType.PgGame
                )?_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 3,"step":"3"}},[_vm._v("Select Game")]):_vm._e(),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 4,"step":_vm.type === _vm.constantHomePage.GameType.LPGame ||
                  _vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                    ? '4'
                    : '3'}},[_vm._v("Enter info")]),_c('v-divider')],1),_c('v-stepper-items',[(_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab)?_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{staticClass:"mb-4 pa-3"},[_c('v-text-field',{attrs:{"label":"tags","rules":[_vm.rules.required, _vm.tagNotfound]},on:{"input":function (e) { return _vm.checkTagsExist('tags', e); }}}),(_vm.tagNotfound === true)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.e1 = 2}}},[_vm._v("Next")]):_vm._e()],1)],1):_vm._e(),_c('v-stepper-content',{attrs:{"step":_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                    ? '2'
                    : '1'}},[_c('v-card',{staticClass:"mb-5",attrs:{"color":"grey lighten-1"}},[_c('v-data-table',{attrs:{"items":_vm.pageGameStore.homePageTopicList,"headers":_vm.headers,"pagination":_vm.paginationTopic,"rows-per-page-items":[20, 50, 100]},on:{"update:pagination":function($event){_vm.paginationTopic=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{staticClass:"row-pointer",class:(_vm.paginationTopic.page - 1) *
                            _vm.paginationTopic.rowsPerPage +
                            props.index ===
                          _vm.selectedTopic
                            ? 'active'
                            : 'inactive',on:{"click":function($event){_vm.setTopic(
                            props.item._id,
                            (_vm.paginationTopic.page - 1) *
                              _vm.paginationTopic.rowsPerPage +
                              props.index
                          )}}},[_c('td',[_vm._v(" "+_vm._s((_vm.paginationTopic.page - 1) * _vm.paginationTopic.rowsPerPage + props.index)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.title)+" ")])])]}}],null,false,3054933277)})],1),(
                    _vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                  )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.e1 = 1}}},[_vm._v("Back")]):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                    ? '3'
                    : '2'}},[_c('v-card',{staticClass:"mb-5",attrs:{"color":"grey lighten-1"}},[_c('v-data-table',{attrs:{"items":_vm.pageGameStore.homePageList.group_datas,"headers":_vm.headers,"pagination":_vm.pagination,"rows-per-page-items":[20, 50, 100]},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{staticClass:"row-pointer",class:(_vm.pagination.page - 1) * _vm.pagination.rowsPerPage +
                            props.index ===
                          _vm.selectedHome
                            ? 'active'
                            : 'inactive',on:{"click":function($event){_vm.setData(
                            props.item._id,
                            (_vm.pagination.page - 1) * _vm.pagination.rowsPerPage +
                              props.index
                          )}}},[_c('td',[_vm._v(" "+_vm._s((_vm.pagination.page - 1) * _vm.pagination.rowsPerPage + props.index)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.title)+" ")])])]}}],null,false,3371884802)})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.e1 =
                      _vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                        ? 2
                        : 1}}},[_vm._v("back")])],1),_c('v-stepper-content',{attrs:{"step":_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                    ? '4'
                    : '3'}},[_c('v-card',{staticClass:"mb-4 pa-3"},[(_vm.type === _vm.constantHomePage.GameType.LPGame)?_c('div',[(_vm.gameData.game_data)?_c('v-data-table',{staticClass:"row-pointer",attrs:{"items":_vm.gameData.game_data,"headers":_vm.headersLpGame,"pagination":_vm.paginationGame,"rows-per-page-items":[20, 50, 100]},on:{"update:pagination":function($event){_vm.paginationGame=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:(_vm.paginationGame.page - 1) *
                              _vm.paginationGame.rowsPerPage +
                              props.index ===
                            _vm.selectedGame
                              ? 'active'
                              : 'inactive',on:{"click":function($event){_vm.onchangPageGroup(
                              'index_activity_game',
                              (_vm.paginationGame.page - 1) *
                                _vm.paginationGame.rowsPerPage +
                                props.index
                            )}}},[_c('td',[_vm._v(" "+_vm._s((_vm.paginationGame.page - 1) * _vm.paginationGame.rowsPerPage + props.index)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.displayGameType(props.item.game_type))+" ")])])]}}],null,false,728848723)}):_vm._e()],1):(_vm.type !== _vm.constantHomePage.GameType.LPGame)?_c('div',[_c('v-card',{staticClass:"mb-4 pa-3"},[_c('v-text-field',{attrs:{"label":"title","value":_vm.createData.title,"rules":[_vm.rules.required]},on:{"input":function (e) { return _vm.onchangPageGroup('title', e); }}}),_c('v-text-field',{attrs:{"label":"Image URI (URL or Select file)","value":_vm.createData.img_uri,"rules":[_vm.rules.validURI]},on:{"change":function (e) { return _vm.onchangPageGroup('img_uri', e); }},model:{value:(_vm.createData.img_uri),callback:function ($$v) {_vm.$set(_vm.createData, "img_uri", $$v)},expression:"createData.img_uri"}}),_c('v-btn',{staticClass:"btn-center",on:{"click":function($event){return _vm.onclickEditImage()}}},[_c('v-icon',[_vm._v("image")]),_vm._v("Select Icon URI")],1)],1)],1):_vm._e()]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.e1 =
                      _vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                        ? 3
                        : 2}}},[_vm._v("back")])],1),_c('v-stepper-content',{attrs:{"step":_vm.typeTagGame === _vm.constantHomePage.typeTagGame.SpeechLab
                    ? '5'
                    : '4'}},[_c('v-card',{staticClass:"mb-4 pa-3"},[_c('v-text-field',{attrs:{"label":"title","value":_vm.createData.title,"rules":[_vm.rules.required]},on:{"input":function (e) { return _vm.onchangPageGroup('title', e); }}}),_c('v-text-field',{attrs:{"label":"Image URI (URL or Select file)","value":_vm.createData.img_uri,"rules":[_vm.rules.validURI]},on:{"change":function (e) { return _vm.onchangPageGroup('img_uri', e); }},model:{value:(_vm.createData.img_uri),callback:function ($$v) {_vm.$set(_vm.createData, "img_uri", $$v)},expression:"createData.img_uri"}}),_c('v-btn',{staticClass:"btn-center",on:{"click":function($event){return _vm.onclickEditImage()}}},[_c('v-icon',[_vm._v("image")]),_vm._v("Select Icon URI")],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.type === _vm.constantHomePage.GameType.LPActivity
                      ? (_vm.e1 = 2)
                      : (_vm.e1 = 3)}}},[_vm._v("back")])],1)],1)],1)],1)],1)],1)],1),(_vm.isEditImage)?_c('ResourceSelect',{attrs:{"resourceType":"image","isShow":_vm.isEditImage,"close":_vm.closeEditImage,"save":_vm.saveEditImage,"value":_vm.selectImageUri,"onSelect":_vm.onSelectImageResource}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }