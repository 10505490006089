export const translatorMenu = [
  {
    icon: 'translate',
    title: 'Localization',
    path: '/localization',
  },
  {
    icon: 'subtitles',
    title: 'Subtitle Localization',
    path: '/subtitle_localization',
  },
  {
    icon: 'subtitles',
    title: 'Subtitle Video Localization',
    path: '/subtitle_video_localization',
  },
  {
    icon: 'navigation',
    title: 'Content Navigation',
    path: '/content_navigation',
  },
];
