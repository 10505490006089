<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/tapstory')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (selectedTapstoryListStore &&
                selectedTapstoryListStore.category) ||
              ''
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="selectedTapstoryListStore">
          <v-layout row grid-list-md v-if="!updatingState">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Tapstory</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(tapstory, index) in tapstoryDataStore"
                    :key="index"
                    @click="onClickSelectTapstory(tapstory)"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ tapstory.name }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs5 mr-2 v-if="selectedTapstoryIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Tapstory Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onClickUpdateTapstory">
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <div>
                      <v-tabs
                        v-model="active"
                        color="black"
                        dark
                        slider-color="yellow"
                      >
                        <v-tab v-for="n in 4" :key="n" ripple>
                          {{ menuLabel(n - 1) }}
                        </v-tab>
                        <v-tab-item v-for="n in 4" :key="n">
                          <v-flex v-if="n === 1">
                            <v-text-field
                              label="Name"
                              v-model="tapstory.name"
                            ></v-text-field>
                            <v-text-field
                              label="Question"
                              v-model="tapstory.question"
                            ></v-text-field>
                            <v-text-field
                              label="Introduction Voice URI (URL or Select file)"
                              v-model="tapstory.introduction_voice_uri"
                            ></v-text-field>
                            <v-btn
                              @click="
                                isSelectSound = true;
                                isSelectIntroVoice = true;
                              "
                            >
                              <v-icon>audiotrack</v-icon>Select Introduction
                              Voice</v-btn
                            >
                            <v-checkbox
                              label="Show Finger"
                              v-model="tapstory.show_finger"
                            ></v-checkbox>
                            <v-text-field
                              label="Background Music URI (URL or Select file)"
                              v-model="tapstory.bgm_uri"
                            ></v-text-field>
                            <v-btn
                              @click="
                                isSelectSound = true;
                                isSelectBackgroundMusic = true;
                              "
                            >
                              <v-icon>audiotrack</v-icon>Select Background
                              Music</v-btn
                            >
                            <v-flex>
                              <img
                                v-if="tapstory.background_uri"
                                :src="tapstory.background_uri"
                                class="content_img"
                              />
                              <v-text-field
                                label="Background URI (URL or Select file)"
                                v-model="tapstory.background_uri"
                              ></v-text-field>
                              <v-btn
                                @click="
                                  isSelectImage = true;
                                  isSelectBackground = true;
                                "
                              >
                                <v-icon>image</v-icon>Select Background
                              </v-btn>
                            </v-flex>
                          </v-flex>
                          <v-flex v-if="n === 2">
                            <div class="modal-image-set">
                              <v-btn
                                class="white--text"
                                block
                                large
                                color="tapstory_image_set"
                                @click="openModalImageSet()"
                              >
                                <v-icon>category</v-icon>
                                Image Set Manager
                              </v-btn>
                            </div>
                          </v-flex>
                          <v-flex v-if="n === 3">
                            <div class="point-data">
                              <v-btn
                                block
                                large
                                class="white--text"
                                color="pointdata"
                                @click="openPointData"
                                >Point Data</v-btn
                              >
                            </div>
                          </v-flex>
                          <v-flex v-if="n === 4">
                            <!-- Subtitle -->
                          </v-flex>
                        </v-tab-item>
                      </v-tabs>
                      <div class="text-xs-center mt-3">
                        <v-btn @click="next">next tab</v-btn>
                      </div>
                    </div>
                  </v-layout>
                </v-card-text>
                <v-btn
                  block
                  color="red"
                  dark
                  @click="onClickDeleteTapstory(tapstory._id)"
                >
                  <v-icon>delete</v-icon>Delete tapstory
                </v-btn>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedTapstoryIndex !== -1 ? tapstory.learned_words : null
      "
      :incidentalWords="
        selectedTapstoryIndex !== -1 ? tapstory.incidental_words : null
      "
      :rewards="selectedTapstoryIndex !== -1 ? tapstory.reward : null"
      :sentences="selectedTapstoryIndex !== -1 ? tapstory.sentence : null"
      :phonics="selectedTapstoryIndex !== -1 ? tapstory.phonic : null"
    >
    </PointData>
    <Modal
      v-if="isOpenImageData"
      :isOpen="isOpenImageData"
      :type="'confirm'"
      :save="onSaveImageData"
      :close="closeModalImageData"
      :isLastStep="true"
      :width="width"
      title="Image Data Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          @click="increaseStep(name_list.image_data_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.image_data_manager_list">
          <v-stepper-header>
            <template v-for="n in step.image_data_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.image_data_manager_list > n"
                :step="n"
                editable
              >
                Frame {{ n }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.image_data_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.image_data_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="
                    reduceStep(
                      element.image_data_manager_list,
                      name_list.image_data_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-layout column class="elevation-2" style="padding: 20px">
                  <label>
                    <u>⌗ Image and Sound {{ step }}</u>
                  </label>
                  <v-layout align-content-space-between>
                    <v-flex>
                      <v-checkbox
                        label="Is Click"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].is_click
                        "
                      ></v-checkbox>
                      <v-text-field
                        label="Image URI (URL or Select file)"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].image_uri
                        "
                      ></v-text-field>
                      <v-btn
                        @click="
                          isSelectImage = true;
                          isSelectImageDataImageURI = true;
                        "
                      >
                        <v-icon>image</v-icon>Select Image</v-btn
                      >
                      <v-text-field
                        label="Sound URI (URL or Select file)"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].sound_uri
                        "
                      ></v-text-field>
                      <v-btn
                        @click="
                          isSelectSound = true;
                          isSelectImageDataSoundURI = true;
                        "
                      >
                        <v-icon>audiotrack</v-icon>Select Sound</v-btn
                      >
                      <v-text-field
                        label="Sound End Delay"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].sound_end_delay
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <label>
                    <u>⌗ Coordinates {{ step }}</u>
                  </label>
                  <v-layout align-content-space-between>
                    <v-flex>
                      <v-text-field
                        label="Image Start X"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].image_start_x
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Image Start Y"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].image_start_y
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout align-content-space-between>
                    <v-flex>
                      <v-text-field
                        label="Image End X"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].image_end_x
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Image End Y"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].image_end_y
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout align-content-space-between>
                    <v-flex>
                      <v-text-field
                        label="Click Start X"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].click_start_x
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Click Start Y"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].click_start_y
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout align-content-space-between>
                    <v-flex>
                      <v-text-field
                        label="Click End X"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].click_end_x
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Click End Y"
                        v-model="
                          tapstory.image_set[element.image_set_manager_list - 1]
                            .image_group[element.image_group_manager_list - 1]
                            .image_data[step - 1].click_end_y
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-flex>
                    <v-text-field
                      type="number"
                      label="Next Frame"
                      v-model.number="
                        tapstory.image_set[element.image_set_manager_list - 1]
                          .image_group[element.image_group_manager_list - 1]
                          .image_data[step - 1].next_index
                      "
                    ></v-text-field>
                  </v-flex>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-layout>
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.image_data_manager_list)"
              >
                Next
              </v-btn>
              <v-btn
                flat
                @click="backStep(step, name_list.image_data_manager_list)"
              >
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>
    <!-- :type="'confirm'" -->
    <Modal
      v-if="isOpenImageGroup"
      :isOpen="isOpenImageGroup"
      :save="onSaveImageGroup"
      :close="closeModalImageGroup"
      :isLastStep="true"
      :width="width"
      title="Image Group Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          @click="increaseStep(name_list.image_group_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.image_group_manager_list">
          <v-stepper-header>
            <template v-for="n in step.image_group_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.image_group_manager_list > n"
                :step="n"
                editable
              >
                Animation {{ n }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.image_group_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.image_group_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="
                    reduceStep(
                      element.image_group_manager_list,
                      name_list.image_group_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-text-field
                  label="Image Group Name"
                  v-model="
                    tapstory.image_set[element.image_set_manager_list - 1]
                      .image_group[step - 1].name
                  "
                ></v-text-field>
                <v-btn
                  class="white--text"
                  color="tapstory_image_data"
                  @click="openImageData()"
                  >Image Data Manager</v-btn
                >
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.image_group_manager_list)"
              >
                Next
              </v-btn>
              <v-btn
                flat
                @click="backStep(step, name_list.image_group_manager_list)"
              >
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>
    <Modal
      v-if="isOpenImageSetModal"
      :isOpen="isOpenImageSetModal"
      :save="onSaveImageSet"
      :close="closeModalImageSet"
      :isLastStep="true"
      :width="width"
      title="Image Set Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          @click="increaseStep(name_list.image_set_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.image_set_manager_list">
          <v-stepper-header>
            <template v-for="n in step.image_set_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.image_set_manager_list > n"
                :step="n"
                editable
              >
                Set {{ n }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.image_set_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.image_set_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="
                    reduceStep(
                      element.image_set_manager_list,
                      name_list.image_set_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-text-field
                  label="Set Name"
                  v-model="
                    tapstory.image_set[element.image_set_manager_list - 1]
                      .set_name
                  "
                ></v-text-field>
                <v-btn
                  color="tapstory_image_group"
                  class="white--text"
                  @click="openImageGroup()"
                  >Image Group Manager</v-btn
                >
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.image_set_manager_list)"
              >
                Next
              </v-btn>
              <v-btn
                flat
                @click="backStep(step, name_list.image_set_manager_list)"
              >
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>
  </Container>
</template>

<script>
import PointData from '../../components/PointData';
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'Tapstory',
  components: {
    Container,
    ResourceSelect,
    Modal,
    PointData,
  },
  computed: {
    ...mapState({
      tapstoryDataStore: state => state.tapstory.TAPSTORY_DATA,
      tapstoryImageSetDataStore: state =>
        state.tapstory.TAPSTORY_IMAGE_SET_DATA,
      tapstoryImageSetIDStore: state => state.tapstory.TAPSTORY_IMAGE_SET_ID,
      selectedTapstoryListStore: state => state.tapstory.SELECTED_TAPSTORY_LIST,
      updatingState: state => state.tapstory.isUpdating,
    }),
  },
  watch: {
    active(menuIndex) {
      const imageSetMenu = 1;
      if (menuIndex === imageSetMenu) {
        this.fetchImageSetData();
      }
    },
    'step.image_set_manager_list'(val) {
      this.validateWidth(val);
      if (this.element.image_set_manager_list > val) {
        this.element.image_set_manager_list = val;
      }
    },
  },
  data() {
    return {
      isSelectImageDataImageURI: false,
      isSelectImageDataSoundURI: false,
      isOpenImageData: false,
      isOpenImageGroup: false,
      maximum: {
        image_set: 5,
        image_group: 10,
        image_data: 5,
      },
      image_set: [
        {
          image_group: [
            {
              name: '',
              image_data: [
                {
                  image_start_x: '',
                  image_start_y: '',
                  image_end_x: '',
                  image_end_y: '',
                  click_start_x: '',
                  click_start_y: '',
                  click_end_x: '',
                  click_end_y: '',
                  image_uri: '',
                  sound_uri: '',
                  is_click: true,
                  sound_end_delay: '',
                  next_index: -1,
                },
              ],
            },
          ],
        },
      ],
      isOpenPointData: false,
      width: '90%',
      name_list: {
        image_set_manager_list: 'image_set_manager_list',
        image_group_manager_list: 'image_group_manager_list',
        image_data_manager_list: 'image_data_manager_list',
      },
      element: {
        image_set_manager_list: 1,
        image_group_manager_list: 1,
        image_data_manager_list: 1,
      },
      step: {
        image_set_manager_list: 1,
        image_group_manager_list: 1,
        image_data_manager_list: 1,
      },

      isOpenImageSetModal: false,
      isSelectItemHitAudio: false,
      isSelectItemImage: false,
      active: null,
      selectedSoundUri: '',
      isSelectBackground: false,
      isSelectSound: false,
      isSelectBackgroundMusic: false,
      isSelectIntroVoice: false,
      isSelectImage: false,
      selectedImageUri: '',
      selectedTapstoryIndex: -1,
      tapstory: {},
    };
  },
  methods: {
    ...mapActions('tapstory', [
      'fetchImageSetByTapstoryDataID',
      'getTapstoryList',
      'getTapstoryData',
      'getTapstoryDataByObjectID',
      'getCategoryByTapstoryListID',
      'updateTapstoryImageSetData',
      'updateTapstory',
      'deleteTapstory',
      'resetTapstoryImageSetID',
      'resetTapstoryImageSetData',
    ]),
    openImageData() {
      this.setInitialStepForImageData();
      this.isOpenImageData = true;
    },
    closeImageData() {
      this.isOpenImageData = false;
    },
    onSaveImageData() {
      this.isOpenImageData = false;
    },
    closeModalImageData() {
      this.isOpenImageData = false;
    },
    setInitialStepForImageData() {
      const lengthOfImageData =
        this.tapstory.image_set[this.element.image_set_manager_list - 1]
          .image_group[this.element.image_group_manager_list - 1].image_data
          .length;
      if (lengthOfImageData > 0) {
        this.element.image_data_manager_list = 1;
        this.step.image_data_manager_list = lengthOfImageData;
      }
    },
    setInitialStepForImageGroup() {
      const lengthOfImageGroupData =
        this.tapstory.image_set[this.element.image_set_manager_list - 1]
          .image_group.length;
      if (lengthOfImageGroupData > 0) {
        this.element.image_group_manager_list = 1;
        this.step.image_group_manager_list = lengthOfImageGroupData;
      }
    },
    openImageGroup() {
      this.setInitialStepForImageGroup();
      this.isOpenImageGroup = true;
    },
    closeImageGroup() {
      this.isOpenImageGroup = false;
    },
    async saveImageSet() {
      const updateImageSetData = {
        _id: this.tapstoryImageSetIDStore,
        image_set: this.tapstory.image_set,
      };
      await this.updateTapstoryImageSetData(updateImageSetData);
      // await this.resetTapstoryImageSetID();
      this.selectedTapstoryIndex = -1;
    },
    onSaveImageGroup() {
      this.saveImageSet();
      this.isOpenImageGroup = false;
    },
    closeModalImageGroup() {
      this.isOpenImageGroup = false;
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.tapstory.learned_words = learned_words;
      }
      if (incidental_words) {
        this.tapstory.incidental_words = incidental_words;
      }
      if (reward) {
        this.tapstory.reward = reward;
      }
      if (sentence) {
        this.tapstory.sentence = sentence;
      }
      if (phonic) {
        this.tapstory.phonic = phonic;
      }
      this.onClickUpdateTapstory();
      this.isOpenPointData = false;
    },
    async createTapstoryItem() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
    },
    validateWidth(val) {
      if (val > 6) {
        this.width = '80%';
      } else if (val <= 6) {
        this.width = '60%';
      }
    },
    onClickDeleteTapstory(id) {
      this.deleteTapstory({ tapstoryID: id });
    },
    addDefaultObjectImageSet() {
      const imageSetObject = {
        image_group: [
          {
            name: '',
            image_data: [
              {
                image_start_x: '',
                image_start_y: '',
                image_end_x: '',
                image_end_y: '',
                click_start_x: '',
                click_start_y: '',
                click_end_x: '',
                click_end_y: '',
                image_uri: '',
                sound_uri: '',
                is_click: true,
                sound_end_delay: '',
                next_index: -1,
              },
            ],
          },
        ],
      };
      this.tapstory.image_set.push(JSON.parse(JSON.stringify(imageSetObject)));
    },
    addDefaultObjectImageGroup() {
      const imageGroupObject = {
        name: '',
        image_data: [
          {
            image_start_x: '',
            image_start_y: '',
            image_end_x: '',
            image_end_y: '',
            click_start_x: '',
            click_start_y: '',
            click_end_x: '',
            click_end_y: '',
            image_uri: '',
            sound_uri: '',
            is_click: true,
            sound_end_delay: '',
            next_index: -1,
          },
        ],
      };
      this.tapstory.image_set[
        this.element.image_set_manager_list - 1
      ].image_group.push(JSON.parse(JSON.stringify(imageGroupObject)));
    },
    addDefaultObjectImageData() {
      const imageDataObject = {
        image_start_x: '',
        image_start_y: '',
        image_end_x: '',
        image_end_y: '',
        click_start_x: '',
        click_start_y: '',
        click_end_x: '',
        click_end_y: '',
        image_uri: '',
        sound_uri: '',
        is_click: true,
        sound_end_delay: '',
        next_index: -1,
      };
      this.pushImageDataObject(imageDataObject);
    },
    async pushImageDataObject(object) {
      await this.tapstory.image_set[
        this.element.image_set_manager_list - 1
      ].image_group[this.element.image_group_manager_list - 1].image_data.push(
        JSON.parse(JSON.stringify(object)),
      );
    },
    getPreviousIndexDataObject() {
      const length =
        this.tapstory.image_set[this.element.image_set_manager_list - 1]
          .image_group[this.element.image_group_manager_list - 1].image_data
          .length;
      if (length > 0) {
        const object =
          this.tapstory.image_set[this.element.image_set_manager_list - 1]
            .image_group[this.element.image_group_manager_list - 1].image_data[
            length - 1
          ];
        return object;
      }
    },
    async duplicatePrevIndexDataObject() {
      const prevObject = await this.getPreviousIndexDataObject();
      const duplicatedObject = Object.assign({}, prevObject);
      await this.pushImageDataObject(duplicatedObject);
    },
    increaseStep(list) {
      if (list === this.name_list.image_set_manager_list) {
        if (this.step[list] < this.maximum.image_set) {
          this.addDefaultObjectImageSet();
          this.step[list] += 1;
          this.element[list] = this.step[list];
        }
      } else if (list === this.name_list.image_group_manager_list) {
        if (this.step[list] < this.maximum.image_group) {
          this.addDefaultObjectImageGroup();
          this.step[list] += 1;
          this.element[list] = this.step[list];
        }
      } else if (list === this.name_list.image_data_manager_list) {
        if (this.step[list] < this.maximum.image_data) {
          this.duplicatePrevIndexDataObject();
          // this.addDefaultObjectImageData();
          this.step[list] += 1;
          this.element[list] = this.step[list];
        }
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.image_set_manager_list) {
          this.step[list] -= 1;
          this.tapstory.image_set.splice(index, 1);
          this.element[list] = this.step[list];
        } else if (list === this.name_list.image_group_manager_list) {
          this.step[list] -= 1;
          this.tapstory.image_set[
            this.element.image_set_manager_list - 1
          ].image_group.splice(index, 1);
          this.element[list] = this.step[list];
        } else if (list === this.name_list.image_data_manager_list) {
          this.tapstory.image_set[
            this.element.image_set_manager_list - 1
          ].image_group[
            this.element.image_group_manager_list - 1
          ].image_data.splice(index, 1);
          this.step[list] -= 1;
          this.element[list] = this.step[list];
        } else {
          this.step[list] -= 1;
          this.tapstory[list].splice(index, 1);
          this.element[list] = this.step[list];
        }
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },
    async onSaveImageSet() {
      const updateImageSetData = {
        _id: this.tapstoryImageSetIDStore,
        image_set: this.tapstory.image_set,
      };
      await this.updateTapstoryImageSetData(updateImageSetData);
      await this.resetTapstoryImageState();
      this.isOpenImageSetModal = false;
      this.selectedTapstoryIndex = -1;
    },
    closeModalImageSet() {
      this.isOpenImageSetModal = false;
    },
    async fetchImageSetData() {
      const tapstory_data_id = await this.tapstoryDataStore[
        this.selectedTapstoryIndex
      ]._id;
      if (this.tapstoryImageSetDataStore.length === 0) {
        await this.fetchImageSetByTapstoryDataID(tapstory_data_id);
      }
    },
    async openModalImageSet() {
      this.tapstory.image_set = await this.tapstoryImageSetDataStore;
      this.isOpenImageSetModal = true;
      this.calculateItemStep();
    },
    calculateItemStep() {
      this.element.image_set_manager_list = 1;
      if (this.tapstory.image_set.length === 0) {
        this.step.image_set_manager_list = 1;
      } else {
        this.step.image_set_manager_list = this.tapstory.image_set.length;
      }
    },
    menuLabel(index) {
      const label = ['Tapstory', 'Image Set', 'Point Data', 'Subtitle'];
      return label[index];
    },
    next() {
      const active = parseInt(this.active);
      this.active = active < 3 ? active + 1 : 0;
    },
    closeSelectSound() {
      (this.isSelectSound = false), (this.selectedSoundUri = '');
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      if (this.isSelectBackgroundMusic) {
        this.tapstory.bgm_uri = uri;
        this.isSelectBackgroundMusic = false;
      } else if (this.isSelectImageDataSoundURI) {
        this.tapstory.image_set[
          this.element.image_set_manager_list - 1
        ].image_group[this.element.image_group_manager_list - 1].image_data[
          this.element.image_data_manager_list - 1
        ].sound_uri = uri;
        this.isSelectImageDataSoundURI = false;
      } else if (this.isSelectIntroVoice) {
        this.tapstory.introduction_voice_uri = uri;
        this.isSelectIntroVoice = false;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    onSelectImageResource(uri) {
      this.selectedImageUri = uri;
    },
    closeSelectImage() {
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    saveSelectImage() {
      const uri = this.selectedImageUri;
      if (this.isSelectBackground) {
        this.tapstory.background_uri = uri;
        this.isSelectBackground = false;
      } else if (this.isSelectItemImage) {
        let index = this.element.image_set_manager_list - 1;
        this.tapstory.image_set[index].image_uri = uri;
        this.isSelectItemImage = false;
      } else if (this.isSelectImageDataImageURI) {
        this.tapstory.image_set[
          this.element.image_set_manager_list - 1
        ].image_group[this.element.image_group_manager_list - 1].image_data[
          this.element.image_data_manager_list - 1
        ].image_uri = uri;
        this.isSelectImageDataImageURI = false;
      }
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    async resetTapstoryImageState() {
      await this.resetTapstoryImageSetID();
      await this.resetTapstoryImageSetData();
    },
    resetActive() {
      this.active = 0;
    },
    onClickSelectTapstory(data) {
      this.resetActive();
      this.resetTapstoryImageState();
      const tapstoryStore = this.tapstoryDataStore;
      this.selectedTapstoryIndex = tapstoryStore.findIndex(
        tapstory => tapstory._id === data._id,
      );
      this.tapstory = tapstoryStore[this.selectedTapstoryIndex];
    },
    onClickUpdateTapstory() {
      this.updateTapstory(this.tapstory);
    },
  },
  async mounted() {
    if (Object.keys(this.selectedTapstoryListStore).length === 0) {
      if (this.$route.query && this.$route.query.sub_id) {
        await this.getTapstoryDataByObjectID(this.$route.query.sub_id);
      } else {
        await this.getTapstoryData(this.$route.query.id);
      }
      setTimeout(() => {
        this.getCategoryByTapstoryListID(
          this.tapstoryDataStore[0].tapstory_list_id,
        );
      }, 1000);
    }
  },
};
</script>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}

.modal-image-set {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

.point-data {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}
</style>
