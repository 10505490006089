<template>
  <Container>
    <v-flex>
      <v-card>
        <Header></Header>
        <v-card-text>
          <v-layout row grid-list-md>
            <GameDetail></GameDetail>
            <ListEvent></ListEvent>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </Container>
</template>

<script>
import Container from '../../components/Container';
import { mapActions, mapState } from 'vuex';
import Modal from '../../components/Modal';
import swal from 'sweetalert2';
import { Header, GameDetail, ListEvent } from './components';

export default {
  components: {
    Container,
    Header,
    GameDetail,
    ListEvent,
    // Modal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  async mounted() {
    const selectGameData = this.shortStoryStore.selectedShortStory;
    if (Object.keys(selectGameData).length === 0) {
      if (this.$route.query.id) {
        const id = this.$route.query.id;
        this.updateGameStore({ key: 'id', value: id });
        this.fetchShortStoryById(id);
      }
    }
  },
  methods: {
    ...mapActions('short_story', ['updateGameStore', 'fetchShortStoryById']),
  },
};
</script>

<style></style>
