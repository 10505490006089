<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Home Page Topic Manager </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" @click="isOpenCreateTopic = true">
            <v-icon>add</v-icon>
          </v-btn>
          <!-- <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field> -->
        </v-toolbar>
        <v-card-text class="pa-4">
          <table class="table table-striped homegroup">
            <thead class="thead-dark">
              <tr>
                <th>Index</th>
                <th>Active</th>
                <th>Title</th>
                <th>Updated_at</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <draggable
              v-model="pageGameStore.homePageTopicList"
              tag="tbody"
              @end="editIndexTopicList"
            >
              <tr
                v-for="element in pageGameStore.homePageTopicList"
                :key="element.name"
              >
                <td>{{ element.index }}</td>
                <td>
                  <v-checkbox
                    v-model="element.active"
                    @change="e => activeTopic(element._id, e)"
                  ></v-checkbox>
                </td>
                <td>{{ element.title }}</td>
                <td>{{ element.updated_at }}</td>
                <td class="tools">
                  <v-layout justify-end>
                    <v-btn
                      icon
                      color="black"
                      v-on:click="onClickEdit(element._id)"
                    >
                      <v-icon color="white">edit</v-icon>
                    </v-btn>
                  </v-layout>
                </td>
                <td class="tools">
                  <v-layout justify-end>
                    <v-btn
                      color="error"
                      @click="deleteGame(element._id)"
                      small
                      fab
                      right
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-layout>
                </td>
              </tr>
            </draggable>
          </table>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreateTopic
      :isOpen="isOpenCreateTopic"
      @close="isOpenCreateTopic = false"
    ></ModalCreateTopic>
  </Container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Container from '../../components/Container.vue';
import { ModalCreateTopic } from './components';
import swal from 'sweetalert2';
import draggable from 'vuedraggable';

export default {
  components: { Container, ModalCreateTopic, draggable },
  data() {
    return {
      headers: [
        { text: 'No.', value: 'no', sortable: false },
        { text: 'Title', value: 'name', sortable: false },
        // { text: 'Game', value: '', sortable: false },
        { text: 'Updated_at', value: 'updated_at', sortable: false },
      ],
      isOpenCreateTopic: false,
      pagination: {},
    };
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
    }),
  },
  methods: {
    ...mapActions('homepage', [
      'fetchHomePageTopicListAll',
      'clearAllState',
      'deletePageTopicData',
      'updatePageTopicData',
    ]),
    activeTopic(id, active) {
      const update = { id: id, active: active };
      this.updatePageTopicData(update);
    },
    onClickEdit(id) {
      this.$router.push(`/home_page/group?id=${id}`);
    },
    deleteGame(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        })
        .then(result => {
          if (result.value) {
            this.deletePageTopicData(id);
          }
        });
    },
    editIndexTopicList({ oldIndex, newIndex }) {
      if (oldIndex !== newIndex) {
        swal
          .fire({
            titleText: 'Are you sure change index topic.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#7a7a7a',
          })
          .then(result => {
            if (result.value) {
              const topicId = this.pageGameStore.homePageTopicList.map(
                data => data._id,
              );
              this.updatePageTopicData({ updateTopicIndex: topicId });
            } else this.fetchHomePageTopic();
          });
      }
    },
    fetchHomePageTopic() {
      this.clearAllState();
      this.fetchHomePageTopicListAll();
    },
  },
  created() {
    this.fetchHomePageTopic();
  },
};
</script>

<style lang="css" scoped>
.tools {
  width: 10px;
}
.active {
  background-color: #212121;
}
.image_thumbnail1 {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.homegroup {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.homegroup td,
.homegroup th {
  border-bottom: 1px solid #7a7a7a;
  padding: 8px;
}
.homegroup tr:hover {
  background-color: #666666;
  cursor: pointer;
}
.homegroup th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}
</style>
