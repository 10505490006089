<template>
  <v-layout row grid-list-md>
    <v-flex xs5 mr-2 v-if="shortStoryStore.selectedEventIndex != -1">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Play on start</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            v-on:click="onClickUpdateEvent()"
            :disabled="shortStoryStore.isDirty.event == false"
          >
            <v-icon>save</v-icon>Save
          </v-btn>
        </v-toolbar>
        <v-list
          v-if="
            shortStoryStore.selectedShortStory.events[
              shortStoryStore.selectedEventIndex
            ].filters != undefined
          "
        >
          <v-list-tile
            v-for="(e, index) in shortStoryStore.selectedShortStory.events[
              shortStoryStore.selectedEventIndex
            ].filters"
            :key="e._id"
            v-on:click="() => ({})"
          >
            <v-list-tile-content @click="onSelectFilterDetail(index, e._id)">
              <v-list-tile-title v-if="e.name"
                >{{ index }} {{ e.name }}
              </v-list-tile-title>
              <v-list-tile-title v-else
                >{{ index }} Filter {{ index + 1 }}</v-list-tile-title
              >
              <v-list-tile-sub-title>
                type: {{ FilterType[e.type].name }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn class="btn-center" @click="copyFilter(e._id)" flat icon>
                <v-icon>content_copy</v-icon></v-btn
              >
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
        <v-container>
          <hr />
          <v-btn color="green" v-on:click="isCreate = true">
            <v-icon>add</v-icon>Create Filter
          </v-btn>
          <v-form ref="formUpdate" v-model="valid" lazy-validation>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  :value="
                    shortStoryStore.selectedShortStory.events[
                      shortStoryStore.selectedEventIndex
                    ].name
                  "
                  label="Name"
                  v-on:change="e => onchangeEventDetail('name', e)"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  type="number"
                  :value="
                    shortStoryStore.selectedShortStory.events[
                      shortStoryStore.selectedEventIndex
                    ].duration_ms
                  "
                  label="Duration ms"
                  v-on:change="
                    e => onchangeEventDetail('duration_ms', parseInt(e))
                  "
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  :value="
                    shortStoryStore.selectedShortStory.events[
                      shortStoryStore.selectedEventIndex
                    ].sound_uri
                  "
                  label="Play sound on start (URL or Select file)"
                  v-on:change="e => onchangeEventDetail('sound_uri', e)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickSound()"
                  ><v-icon>audiotrack</v-icon>Select Play Sound on start</v-btn
                >
                <v-btn
                  color="red"
                  dark
                  @click="
                    onClickDeleteEvent(
                      shortStoryStore.selectedShortStory.events[
                        shortStoryStore.selectedEventIndex
                      ]._id,
                    )
                  "
                >
                  <v-icon>delete</v-icon>Delete this event
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </v-flex>
    <FilterDetaill :filterData="filterData"></FilterDetaill>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onClickCreateFilter"
      :close="closeCreateFilter"
      :isLastStep="valid"
      title="Create Filter"
    >
      <v-card>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-select
                  :items="FilterType"
                  item-text="name"
                  item-value="value"
                  v-model="shortStoryStore.createFilter.type"
                  label="Type"
                  :rules="[rules.required]"
                  @change="e => onchangeCreateSelectType(e)"
                ></v-select>
                <v-text-field
                  label="Name"
                  v-model="shortStoryStore.createFilter.name"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  label="Display Duration (-1 Always)*"
                  type="number"
                  v-model.number="shortStoryStore.createFilter.duration_ms"
                  :rules="[rules.required, rules.numberRule]"
                ></v-text-field>
                <v-text-field
                  label="Next Index"
                  v-model="shortStoryStore.createFilter.next_index"
                  :rules="[rules.numberRule]"
                ></v-text-field>
                <v-checkbox
                  v-model="shortStoryStore.createFilter.is_stretch"
                  label="is stretch"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </v-layout>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import Modal from '../../../components/Modal';
import FilterDetaill from './FilterDetaill.vue';
import constant from '../constant';

export default {
  components: {
    ResourceSelect,
    Modal,
    FilterDetaill,
  },
  data() {
    return {
      FilterType: constant.FilterType,
      filterData: {},
      isCreate: false,
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectSoundBtn: false,
      valid: true,
      rules: constant.rule,
      onchange: false,
    };
  },
  props: {
    // selectedEventIndex: {
    //   type: Number,
    //   default: -1,
    // }
    eventId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    ...mapActions('short_story', [
      'updateEventShortStory',
      'clearUpdateEvent',
      'updateEventStore',
      'clearCreateFilter',
      'createFilterShortStory',
      'clearSelectedEventIndex',
      'clearSelectedFilterIndex',
      'setSelectedFilterIndex',
      'updateFilterStore',
      'clearUpdateFilter',
      'setDirty',
      'clearDirty',
      'deleteEventShortStory',
      'setCreateFilter',
      'duplicateFilter',
    ]),
    copyFilter(id) {
      const game_id = this.shortStoryStore.updateGameDetail.id;
      this.duplicateFilter({ id, game_id });
    },
    onchangeCreateSelectType(value) {
      // this.clearCreateFilter();
      let defaultData,
        {
          Basic,
          SnapFace,
          FaceNPoint,
          FaceCap,
          Sunglasses,
          Particle,
          CoverFace,
          Scale,
        } = this.shortStoryStore;
      if (value === 0) defaultData = Basic;
      if (value === 1) defaultData = SnapFace;
      if (value === 2) defaultData = FaceNPoint;
      if (value === 3) defaultData = FaceCap;
      if (value === 4) defaultData = Sunglasses;
      if (value === 5) defaultData = Particle;
      if (value === 6) defaultData = CoverFace;
      if (value === 7) defaultData = Scale;
      for (const [key, value] of Object.entries(defaultData)) {
        this.setCreateFilter({ key, value });
      }
    },
    onClickUpdateEvent() {
      if (this.$refs.formUpdate.validate()) {
        swal
          .fire({
            titleText: 'Are you sure.',
            type: 'warning',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              const updateData = { ...this.shortStoryStore.updateGameEvent };
              this.updateEventShortStory(updateData);
              this.clearUpdateEvent();
              this.updateEventStore({ key: 'id', value: this.eventId });
              this.clearDirty('event');
            }
          });
      }
    },
    onSelectFilterDetail(index, id) {
      if (this.shortStoryStore.isDirty.filter) {
        swal.fire({
          titleText: 'You must save filter.',
          type: 'warning',
        });
      } else {
        const indexFilter = index;
        const indexEvent = this.shortStoryStore.selectedEventIndex;
        this.setSelectedFilterIndex(indexFilter);
        this.filterData = {
          ...this.shortStoryStore.selectedShortStory.events[indexEvent].filters[
            indexFilter
          ],
        };
        this.checkDataDefaultFilter(this.filterData.type);
        this.clearUpdateFilter();
        this.updateFilterStore({ key: 'id', value: id });
        // this.clearDirty('event');
      }
    },
    checkDataDefaultFilter(type) {
      let defaultData = {};
      const {
        Basic,
        SnapFace,
        FaceNPoint,
        FaceCap,
        Sunglasses,
        Particle,
        CoverFace,
        Scale,
        createFilter,
      } = this.shortStoryStore;
      if (type === 0) defaultData = Basic;
      if (type === 1) defaultData = SnapFace;
      if (type === 2) defaultData = FaceNPoint;
      if (type === 3) defaultData = FaceCap;
      if (type === 4) defaultData = Sunglasses;
      if (type === 5) defaultData = Particle;
      if (type === 6) defaultData = CoverFace;
      if (type === 7) defaultData = Scale;
      for (const [key, value] of Object.entries(defaultData)) {
        if (!(key in this.filterData)) this.filterData[key] = value;
      }
      // for (const [key, value] of Object.entries(createFilter)) {
      //   if (!(key in this.filterData)) this.filterData[key] = value;
      // }
      for (const [key, value] of Object.entries(createFilter.image)) {
        if (!(key in this.filterData.image)) this.filterData.image[key] = value;
      }
    },
    onclickSound() {
      this.isSelectSound = true;
      this.isSelectSoundBtn = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectSoundBtn:
          this.shortStoryStore.selectedShortStory.events[
            this.shortStoryStore.selectedEventIndex
          ].sound_uri = uri;
          this.updateEventStore({ key: 'sound_uri', value: uri });
          this.setDirty('event');
          this.isSelectSoundBtn = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onchangeEventDetail(key, value) {
      this.updateEventStore({ key, value });
      this.setDirty('event');
    },
    onClickUpdateFilter() {},
    onClickCreateFilter() {
      if (this.$refs.form.validate()) {
        const createFilter = this.shortStoryStore.createFilter;
        const event_id = this.shortStoryStore.updateGameEvent.id;
        this.createFilterShortStory({
          event_id: event_id,
          ...createFilter,
        });
        this.clearCreateFilter();
        this.isCreate = false;
        this.clearSelectedFilterIndex();
      }
    },
    closeCreateFilter() {
      this.clearCreateFilter();
      this.isCreate = false;
    },
    onClickDeleteEvent(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteEventShortStory(id);
            this.clearSelectedEventIndex();
            this.clearSelectedFilterIndex();
            this.clearDirty('event');
            this.clearDirty('filter');
          }
        });
    },
  },
  // watch: {
  // selectedEventIndex(val) {
  //   if (val) {
  //     this.selectedEvent = [
  //       ...this.shortStoryStore.selectedShortStory.events,
  //     ];
  //     this.selectedEvent = { ...this.selectedEvent[this.selectedEventIndex] };
  //   }
  // },
  // },
};
</script>

<style scoped>
hr {
  border-top: 1px solid #c4c4c4;
  margin-block: revert;
}
</style>
