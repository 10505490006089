<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Payment Store Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex>
            <v-autocomplete
              class="auto-complete"
              label="Select Platform"
              v-model="selectedPlatform"
              :items="platformList"
              chips
              hide-details
              hide-no-data
              hide-selected
            ></v-autocomplete>
          </v-flex>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn small fab icon @click="fetchData()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card v-if="!isMapLocalizedWordsStatus">
          <v-data-table
            :headers="header"
            :items="paymentStore"
            :search="search"
            :pagination.sync="pagination"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td v-if="!props.item.is_available" class="f-grey text-xs-center">
                {{ props.item.product_id }}
              </td>
              <td v-if="!props.item.is_available" class="f-grey text-xs-center">
                {{
                  props.item.localizedDisplayTitle
                    ? props.item.localizedDisplayTitle
                    : props.item.title
                }}
              </td>
              <td v-if="!props.item.is_available" class="f-grey text-xs-center">
                {{
                  props.item.localizedDisplayDescription
                    ? props.item.localizedDisplayDescription
                    : props.item.description
                }}
              </td>
              <td v-if="!props.item.is_available" class="f-grey text-xs-center">
                {{
                  props.item.localizedDisplayMonthlyBill
                    ? props.item.localizedDisplayMonthlyBill
                    : props.item.monthlyBill
                }}
              </td>
              <td v-if="!props.item.is_available" class="f-grey text-xs-center">
                {{ props.item.amountMonth }}
              </td>
              <td v-if="!props.item.is_available" class="f-grey text-xs-center">
                {{ props.item.displayPrice }}
              </td>
              <td v-if="!props.item.is_available" class="f-grey text-xs-center">
                {{
                  props.item.localizedDisplayPriceTag
                    ? props.item.localizedDisplayPriceTag
                    : props.item.price_tag
                }}
                <!-- {{ props.item.price_tag }} -->
              </td>
              <td v-if="!props.item.is_available" class="f-grey text-xs-center">
                {{ props.item.platform }}
              </td>
              <td
                v-if="!props.item.is_available"
                class="f-grey justify-center layout px-0"
              >
                <v-icon small class="mr-2" @click="onClickEdit(props.item)"
                  >edit</v-icon
                >
              </td>
              <!-- Wrap row with grey font by is_available  -->
              <td v-if="props.item.is_available" class="text-xs-center">
                {{ props.item.product_id }}
              </td>
              <td v-if="props.item.is_available" class="text-xs-center">
                {{
                  props.item.localizedDisplayTitle
                    ? props.item.localizedDisplayTitle
                    : props.item.title
                }}
              </td>
              <td v-if="props.item.is_available" class="text-xs-center">
                {{
                  props.item.localizedDisplayDescription
                    ? props.item.localizedDisplayDescription
                    : props.item.description
                }}
              </td>
              <td v-if="props.item.is_available" class="text-xs-center">
                {{
                  props.item.localizedDisplayMonthlyBill
                    ? props.item.localizedDisplayMonthlyBill
                    : props.item.monthlyBill
                }}
              </td>
              <td v-if="props.item.is_available" class="text-xs-center">
                {{ props.item.amountMonth }}
              </td>
              <td v-if="props.item.is_available" class="text-xs-center">
                {{ props.item.displayPrice }}
              </td>
              <td v-if="props.item.is_available" class="text-xs-center">
                {{
                  props.item.localizedDisplayPriceTag
                    ? props.item.localizedDisplayPriceTag
                    : props.item.price_tag
                }}
                <!-- {{ props.item.price_tag }} -->
              </td>
              <td v-if="props.item.is_available" class="text-xs-center">
                {{ props.item.platform }}
              </td>
              <td
                v-if="props.item.is_available"
                class="justify-center layout px-0"
              >
                <v-icon small class="mr-2" @click="onClickEdit(props.item)"
                  >edit</v-icon
                >
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card v-else>
          <v-toolbar dark>
            <v-toolbar-title>Loading...</v-toolbar-title>
          </v-toolbar>
          <v-progress-linear :indeterminate="true"></v-progress-linear>
        </v-card>
      </v-card>
    </v-flex>
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onSaveEdit"
      :close="onCloseEdit"
      :isLastStep="true"
      title="Edit"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-form v-model="isFormValid">
              <v-layout row>
                <v-flex shink pa-1>
                  <v-switch
                    label="Is Available"
                    v-model="payment.is_available"
                  ></v-switch>
                  <v-text-field
                    readonly
                    disabled
                    label="Product ID"
                    v-model="payment.product_id"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    disabled
                    label="Amount Month"
                    v-model="payment.amountMonth"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    disabled
                    label="Display Price"
                    v-model="payment.displayPrice"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    disabled
                    label="Platform"
                    v-model="payment.platform"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <div class="pa-3">
                <h2>Localizable</h2>
              </div>
              <v-layout>
                <v-flex pa-1>
                  <v-text-field
                    :rules="requiredRule"
                    label="Title"
                    v-model="payment.title"
                  ></v-text-field>
                  <v-text-field
                    label="Description"
                    v-model="payment.description"
                  ></v-text-field>
                  <v-text-field
                    label="Monthly Bill"
                    v-model="payment.monthlyBill"
                  ></v-text-field>
                  <v-text-field
                    label="Price Tag"
                    v-model="payment.price_tag"
                  ></v-text-field>
                </v-flex>
                <v-flex pa-1 class="flex-end">
                  <v-layout row>
                    <v-text-field
                      readonly
                      :rules="requiredRule"
                      label="Title"
                      :value="payment.localizedDisplayTitle"
                    >
                    </v-text-field>
                    <v-icon
                      small
                      class="mr-2"
                      @click="viewLocalize(payment.localizedDisplayTitle)"
                      >edit</v-icon
                    >
                  </v-layout>
                  <v-layout row>
                    <v-text-field
                      readonly
                      label="Description"
                      :value="payment.localizedDisplayDescription"
                    ></v-text-field>

                    <v-icon
                      small
                      class="mr-2"
                      @click="viewLocalize(payment.localizedDisplayDescription)"
                      >edit</v-icon
                    >
                  </v-layout>
                  <v-layout row>
                    <v-text-field
                      readonly
                      label="Monthly Bill"
                      :value="payment.localizedDisplayMonthlyBill"
                    ></v-text-field>

                    <v-icon
                      small
                      class="mr-2"
                      @click="viewLocalize(payment.localizedDisplayMonthlyBill)"
                      >edit</v-icon
                    >
                  </v-layout>
                  <v-layout row>
                    <v-text-field
                      readonly
                      label="Price Tag"
                      :value="payment.localizedDisplayPriceTag"
                    ></v-text-field>

                    <v-icon
                      small
                      class="mr-2"
                      @click="viewLocalize(payment.localizedDisplayPriceTag)"
                      >edit</v-icon
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-layout>
        </v-container>
      </v-card>
    </Modal>
  </Container>
</template>

<script>
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  name: 'PaymentStore',
  components: {
    Container,
    Modal,
  },
  watch: {
    selectedPlatform(value) {
      this.fetchPaymentStore(value);
    },
  },
  computed: {
    ...mapState({
      paymentStore: state => state.paymentstore.PAYMENT_STORE_DATA,
      userLevel: state => state.userInfo.userData.role_id,
    }),
    ...mapGetters('paymentstore', ['isMapLocalizedWordsStatus']),
  },
  data() {
    return {
      selectedLanguage: 'English',
      selectedPlatform: 'ios',
      platformList: ['ios', 'android'],
      requiredRule: [v => !!v || 'This field is required'],
      isFormValid: false,
      editedIndex: -1,
      localizedDisplay: {},
      payment: {},
      search: '',
      isEdit: false,
      pagination: {
        descending: true,
        rowsPerPage: 25,
        sortBy: 'is_available',
      },
      header: [
        {
          text: 'Product ID',
          value: 'product_id',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Title',
          value: 'title',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Monthly Bill',
          value: 'monthlyBill',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Amount Month',
          value: 'amountMonth',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Display Price',
          value: 'displayPrice',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Price Tag',
          value: 'price_tag',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Platform',
          value: 'platform',
          align: 'center',
          sortable: true,
        },
        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ],
    };
  },
  methods: {
    ...mapActions('paymentstore', ['fetchPaymentStore', 'updatePaymentStore']),
    viewLocalize(word) {
      let path = '/localization';
      let routeData = this.$router.resolve({
        path: path,
        query: { viewLocalize: word },
      });
      window.open(routeData.href, '_blank');
    },
    onClickEdit(item) {
      this.isEdit = true;
      this.editedIndex = this.paymentStore.indexOf(item);
      this.payment = Object.assign({}, item);
    },
    onSaveEdit() {
      const {
        localizedDisplayTitle,
        localizedDisplayDescription,
        localizedDisplayMonthlyBill,
        localizedDisplayPriceTag,
        ...updateData
      } = this.payment;
      this.updatePaymentStore(updateData);
      this.onCloseEdit();
      this.fetchData();
    },
    onCloseEdit() {
      this.clearUpdateState();
      this.isEdit = false;
    },
    clearUpdateState() {
      this.payment = {};
    },
    fetchData() {
      this.fetchPaymentStore(this.selectedPlatform);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scope>
.auto-complete {
  font-weight: bold;
}
.flex-end {
  align-self: flex-end;
}
.f-grey {
  color: darkgray;
}
</style>
