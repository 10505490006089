<template>
  <div>
    <v-text-field
      label="Word"
      :value="data[index].word"
      v-model="data[index].word"
      :rules="[rules.required]"
    ></v-text-field>
    <v-select
      :items="eventIndexList()"
      item-text="name"
      item-value="index"
      :value="data[index].event_index"
      v-model="data[index].event_index"
      label="Play Event Index"
      :rules="[rules.required]"
    ></v-select>
  </div>
</template>

<script>
// import ResourceSelect from '../../../components/ResourceSelect.vue';
import constant from '../constant';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    // ResourceSelect,
  },
  data() {
    return {
      rules: constant.rule,
      FilterType: constant.FilterType,
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    eventIndexList() {
      const events = [...this.shortStoryStore.selectedShortStory.events];
      if (events.length) {
        const eventTypePlay = events.filter((e, index) => {
          if (e.type === 1) {
            e.index = index;
            return e;
          }
        });
        return eventTypePlay;
      } else return [];
    },
  },
};
</script>

<style scoped>
.key-image {
  background-color: #333333;
  text-align: center;
}
</style>
