<template>
  <div>
    <v-btn icon color="green" v-on:click="addData()">
      <v-icon>add</v-icon>
    </v-btn>
    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="currentStep > n"
            :step="n"
            editable
          >
            <!-- item {{ n }} -->
          </v-stepper-step>

          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
          <v-form>
            <v-card v-if="currentStep > 0">
              <v-toolbar dark>
                <v-toolbar-title>items {{ currentStep }}</v-toolbar-title>
                <v-btn
                  v-if="steps > 1"
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="onClickRemoveByIndex(currentStep - 1)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-container v-if="data[currentStep - 1]">
                <v-layout>
                  <v-flex>
                    <v-form ref="formItem" v-model="valid" lazy-validation>
                      <div>
                        <v-text-field
                          label="Word"
                          :value="data[currentStep - 1].word"
                          v-model="data[currentStep - 1].word"
                          :rules="[rules.required]"
                          @change="onchangeItem()"
                        ></v-text-field>
                        <img
                          v-if="data[currentStep - 1].image_uri"
                          :src="data[currentStep - 1].image_uri"
                          :alt="data[currentStep - 1].image_uri"
                          class="content_img"
                        />
                        <v-text-field
                          label="Image URI (URL or Select file)"
                          :value="data[currentStep - 1].image_uri"
                          v-model="data[currentStep - 1].image_uri"
                          :rules="[rules.validURI]"
                          @change="onchangeItem()"
                        ></v-text-field>
                        <v-btn dark @click="setResource('image')"
                          ><v-icon>image</v-icon>Select Image</v-btn
                        >
                        <!-- <v-text-field
                          label="Hit Audio URI (URL or Select file)"
                          :value="data[currentStep - 1].hit_audio_uri"
                          v-model="data[currentStep - 1].hit_audio_uri"
                          :rules="[rules.validURI]"
                          @change="onchangeItem()"
                        ></v-text-field>
                        <v-btn dark @click="setResource('sound')"
                          ><v-icon>audiotrack</v-icon>Select Hit Audio</v-btn
                        > -->
                        <v-text-field
                          type="number"
                          label="Scale (%)"
                          :value="data[currentStep - 1].scale"
                          v-model="data[currentStep - 1].scale"
                          :rules="[rules.isNumber]"
                          @change="onchangeItem()"
                        ></v-text-field>
                        <v-text-field
                          label="TTS Message"
                          :value="data[currentStep - 1].tts_message"
                          v-model="data[currentStep - 1].tts_message"
                          @change="onchangeItem()"
                        ></v-text-field>
                        <v-select
                          :items="constants.VOICE_NAME"
                          label="Voice Name"
                          :value="data[currentStep - 1].voice_name"
                          v-model="data[currentStep - 1].voice_name"
                          @change="onchangeItem()"
                        ></v-select>
                      </div>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="currentStep > 1"
                color="red"
                dark
                @click="onClickBackStep()"
                >Previous</v-btn
              >
              <v-btn
                v-if="currentStep < steps"
                color="primary"
                dark
                @click="onClickNextStep()"
                >Next</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../../components/ResourceSelect.vue';
import rules from '../../../../utils/ruleValidate';
import constants from '../../../../constants';

export default {
  name: 'itemInfo',
  components: { ResourceSelect },
  computed: {
    ...mapState({
      runningGameStore: state => state.running_game,
      items_info: state => state.running_game.selectedRunningGame.items_info,
      // defaultCreate: state => state.running_game.defaultCreate.items_info,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  data() {
    return {
      constants,
      rules,
      currentStep: 1,
      steps: 1,
      data: [],
      valid: true,
      resource: {
        image: {
          isEdit: false,
          selectUri: '',
        },
        sound: {
          isEdit: false,
          selectUri: '',
        },
      },
      resourceType: '',
    };
  },
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('running_game', ['setStateByKey']),
    limitData() {
      return 10;
    },
    addData() {
      const defaultData = JSON.parse(
        JSON.stringify(this.runningGameStore.defaultCreate.items_info[0]),
      );
      if (this.data.length < this.limitData()) {
        this.data.push(defaultData);
        this.currentStep = this.data.length;
        this.steps = this.data.length;
      }
    },
    onClickRemoveByIndex(index) {
      swal
        .fire({
          titleText: `Are your sure item ${index + 1}?`,
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.data.splice(index, 1);
            this.onchangeItem();
            this.steps = this.data.length;
            if (this.currentStep === 1) {
              this.currentStep = 1;
            } else this.currentStep -= 1;
          }
        });
    },
    onClickNextStep() {
      if (this.currentStep < this.steps) {
        this.currentStep += 1;
      }
    },
    onClickBackStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    onchangeItem() {
      this.$emit('validate', this.valid);
      if (this.$refs.formItem[0].validate()) {
        this.isCreate
          ? this.setStateByKey({
              statekey: 'createRunningGame',
              key: 'items_info',
              value: this.data,
            })
          : this.setStateByKey({
              statekey: 'updateRunningGame',
              key: 'items_info',
              value: this.data,
            });
      }
    },
    setResource(resource) {
      //resource = image||sound||video
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      const index = this.currentStep - 1;
      switch (resourceType) {
        case 'image':
          this.data[index].image_uri = uri;
          break;
        case 'sound':
          this.data[index].hit_audio_uri = uri;
          break;
        default:
          break;
      }
      this.onchangeItem();
      this.closeEditResource();
    },
    closeEditResource() {
      this.resource[this.resourceType].isEdit = false;
      this.resource[this.resourceType].selectUri = '';
      this.resourceType = '';
    },
  },
  mounted() {
    if (this.isCreate) {
      this.data = JSON.parse(
        JSON.stringify(this.runningGameStore.defaultCreate.items_info),
      );
    } else {
      this.data = JSON.parse(JSON.stringify(this.items_info));
      this.steps = this.data.length;
    }
  },
};
</script>
