<template>
  <v-flex mr-2 xs3>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Choice Detail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="onClickUpdateChoice()"
          :disabled="!selectedNode._id"
          color="green"
        >
          <v-icon>save</v-icon>Save
        </v-btn>
      </v-toolbar>
      <v-card-text class="pb-0">
        <v-container grid-list-xs text-xs-center>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout column>
              <v-flex>
                <v-text-field
                  label="Name"
                  :value="selectedChoice.name"
                  @change="e => onchangeChoice('name', e)"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-checkbox
                  v-model="selectedChoice.is_fake_choice"
                  label="is fake choice"
                  @change="e => onchangeChoice('is_fake_choice', e)"
                ></v-checkbox>
                <v-select
                  :items="effectType"
                  item-text="name"
                  item-value="value"
                  :value="selectedChoice.type"
                  v-model.number="selectedChoice.type"
                  label="Effect Type"
                  @change="e => onchangeChoice('type', parseInt(e))"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <MainPart></MainPart>
    <ActionPart v-if="!selectedChoice.is_fake_choice"></ActionPart>
    <ChangeContainerPart
      v-if="!selectedChoice.is_fake_choice"
    ></ChangeContainerPart>

    <!--<ChoicePart></ChoicePart> -->
    <!-- <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    /> -->
    <ModalCreate
      :isOpen="isCreate"
      :title="title"
      @close="closeCreate()"
    ></ModalCreate>
  </v-flex>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
// import ResourceSelect from '../../../components/ResourceSelect.vue';
import rules from '../../../utils/ruleValidate';
import ModalCreate from './ModalCreate.vue';
import MainPart from './MainPart.vue';
import ActionPart from './ActionPart.vue';
import ChangeContainerPart from './ChangeContainerPart.vue';

export default {
  components: {
    // ResourceSelect,
    ModalCreate,
    MainPart,
    ActionPart,
    ChangeContainerPart,
  },
  data() {
    return {
      valid: true,
      rules,
      isCreate: false,
      title: '',
      choice: {},
      effectType: [
        { name: 'None', value: 0 },
        { name: 'Smoke', value: 1 },
        { name: 'Star', value: 2 },
      ],
      // updateChoice: {},
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
    selectedSetIndex() {
      return this.dragComboStore.selectedSetIndex;
    },
    selectedNodeIndex() {
      return this.dragComboStore.selectedNodeIndex;
    },
    selectedChoiceIndex() {
      return this.dragComboStore.selectedChoiceIndex;
    },
    selectedChoice() {
      return this.dragComboStore.selectedChoice;
    },
    updateChoice() {
      return this.dragComboStore.updateChoice;
    },
    selectedNode() {
      return this.dragComboStore.selectedDragCombo.sets[this.selectedSetIndex]
        .nodes[this.selectedNodeIndex];
    },
  },
  methods: {
    ...mapActions('dragcombo', ['updateNodeDragCombo', 'setStateByKey']),
    onClickUpdateChoice() {
      if (this.$refs.form.validate()) {
        // swal
        //   .fire({
        //     titleText: 'Are you sure.',
        //     type: 'warning',
        //     showCancelButton: true,
        //   })
        //   .then(result => {
        //     if (result.value) {
        this.updateNodeDragCombo();
      }
      //     });
      // }
    },
    onchangeChoice(key, value) {
      this.setStateByKey({
        statekey: 'updateChoice',
        key: key,
        value: value,
      });
      this.setStateByKey({
        statekey: 'updateNode',
        key: 'updateChoices',
        value: this.updateChoice,
      });
      this.setStateByKey({
        statekey: 'updateNode',
        key: 'indexChoice',
        value: this.selectedChoiceIndex,
      });
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
</style>
