<template>
  <div>
    <v-flex>
      <div class="modal-item">
        <v-btn block large color="primary" @click="openModalItem()">
          <v-icon>category</v-icon>
          Item Manager
        </v-btn>
      </div>
    </v-flex>
    <v-flex>
      <v-text-field
        required
        :rules="nameRules"
        :error-messages="errorMessages"
        v-model="node.node_name"
        label="Node Name"
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-text-field
        type="number"
        label="Delay"
        v-model="node.delay"
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-checkbox label="Hide Rope" v-model="node.hide_rope"></v-checkbox>
    </v-flex>
    <v-flex>
      <v-autocomplete
        label="Goto Node"
        v-model="node.goto_node"
        :items="gotoNodeStore"
        item-text="display"
        item-value="node_name"
        chips
        multiple
        hide-details
        hide-no-data
        hide-selected
      ></v-autocomplete>
    </v-flex>

    <v-flex>
      <img
        v-if="node.background_uri"
        :src="node.background_uri"
        :alt="node.node"
        class="content_img"
      />
      <v-text-field
        label="Background URI (URL or Select file)"
        v-model="node.background_uri"
      ></v-text-field>
      <v-btn
        @click="
          isSelectImage = true;
          isUpdateNode = true;
          isBackgroundUri = true;
        "
      >
        <v-icon>image</v-icon>Select Background
      </v-btn>
    </v-flex>
    <br />
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeEditImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeEditSound"
      :save="saveSelectSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <Modal
      v-if="isOpenItemModal"
      :isOpen="isOpenItemModal"
      :save="onSaveItem"
      :close="closeModalItem"
      :isLastStep="true"
      :width="width"
      title="Item Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          @click="increaseStep(name_list.item_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.item_manager_list">
          <v-stepper-header>
            <template v-for="n in step.item_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.item_manager_list > n"
                :step="n"
                editable
              >
                Item {{ n }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.item_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.item_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="
                    reduceStep(
                      element.item_manager_list,
                      name_list.item_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-text-field
                  label="Name"
                  v-model="node.item[step - 1].name"
                ></v-text-field>
                <v-select
                  required
                  label="Detect Animation"
                  :items="constant.ITEM_ANIMATION"
                  v-model.number="node.item[step - 1].detect_animation"
                ></v-select>
                <v-select
                  required
                  label="Receive Animation"
                  :items="constant.ITEM_ANIMATION"
                  v-model.number="node.item[step - 1].receive_animation"
                ></v-select>
                <v-flex>
                  <v-text-field
                    label="Receive Sound URI (URL or Select file)"
                    v-model="node.item[step - 1].sound_uri"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      isSelectSound = true;
                      isSelectItemSound = true;
                    "
                  >
                    <v-icon>audiotrack</v-icon>Select Item Sound</v-btn
                  >
                </v-flex>
                <img
                  v-if="node.item[step - 1].image_uri"
                  :src="node.item[step - 1].image_uri"
                  class="content_img"
                />
                <v-text-field
                  label="Image URI (URL or Select file)"
                  v-model="node.item[step - 1].image_uri"
                ></v-text-field>
                <v-btn
                  dark
                  @click="
                    isSelectItemImage = true;
                    isSelectImage = true;
                  "
                >
                  <v-icon>image</v-icon>Select Item Image
                </v-btn>
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.item_manager_list)"
              >
                Next
              </v-btn>
              <v-btn flat @click="backStep(step, name_list.item_manager_list)">
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ResourceSelect from '../../../../../components/ResourceSelect';
import Modal from '../../../../../components/Modal';
import constant from '../../../constant';
export default {
  name: 'CreateItemNode',
  components: {
    ResourceSelect,
    Modal,
  },
  computed: {
    ...mapState({
      gotoNodeStore: state => state.minichat.gotoNodeData,
    }),
  },
  watch: {
    'step.item_manager_list'(val) {
      this.validateWidth(val);
      if (this.element.item_manager_list > val) {
        this.element.item_manager_list = val;
      }
    },
  },
  props: {
    node: {
      type: Object,
      default: () => [],
    },
    item: {
      type: Array,
      default: () => [
        {
          name: '',
          image_uri: '',
          sound_uri: '',
          detect_animation: 0,
          receive_animation: 0,
        },
      ],
    },
  },
  data() {
    return {
      hide_rope: false,
      constant,
      nameRules: [v => !!v || 'Name is required'],
      errorMessages: '',
      selectImageUri: '',
      selectSoundUri: '',
      isUpdateNode: false,
      isBackgroundUri: false,
      isSelectSound: false,
      isSelectImage: false,
      width: '60%',
      isOpenItemModal: false,

      name_list: {
        item_manager_list: 'item_manager_list',
      },
      element: {
        item_manager_list: 1,
      },
      step: {
        item_manager_list: 1,
      },
    };
  },
  methods: {
    validateWidth(val) {
      if (val > 6) {
        this.width = '80%';
      } else if (val <= 6) {
        this.width = '60%';
      }
    },
    addDefaultObjectItem() {
      const formatObject = {
        name: '',
        image_uri: '',
        sound_uri: '',
        detect_animation: 0,
        receive_animation: 0,
      };
      this.node.item.push(formatObject);
    },
    increaseStep(list) {
      const max = 5;
      if (this.step[list] < max) {
        if (list === this.name_list.item_manager_list) {
          this.addDefaultObjectItem();
        }
        this.step[list] += 1;
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.item_manager_list) {
          this.step[list] -= 1;
          this.node.item.splice(index, 1);
        }
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },

    onSaveItem() {
      this.isOpenItemModal = false;
    },
    closeModalItem() {
      this.isOpenItemModal = false;
    },
    initialDefaultItem() {
      this.node.item = [
        {
          name: '',
          image_uri: '',
          sound_uri: '',
          detect_animation: 0,
          receive_animation: 0,
        },
      ];
    },
    openModalItem() {
      this.isOpenItemModal = true;
      if (!this.node.item) {
        this.initialDefaultItem();
      }
      this.calculateItemStep();
    },
    calculateItemStep() {
      if (this.node.item.length === 0) {
        this.step.item_manager_list = 1;
      } else {
        this.step.item_manager_list = this.node.item.length;
      }
    },
    saveSelectSound() {
      const uri = this.selectSoundUri;
      if (this.isSelectItemSound) {
        let index = this.element.item_manager_list - 1;
        this.node.item[index].sound_uri = uri;
      }
      this.isSelectItemSound = !this.isSelectItemSound;
      this.isSelectSound = !this.isSelectSound;
    },
    closeEditSound() {
      this.isSelectSound = false;
      this.selectSoundUri = '';
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    saveSelectImage() {
      const uri = this.selectImageUri;
      if (this.isUpdateNode && this.isBackgroundUri) {
        this.node.background_uri = uri;
      } else if (this.isSelectItemImage) {
        let index = this.element.item_manager_list - 1;
        this.node.item[index].image_uri = uri;
      }
      this.selectImageUri = '';
      this.isUpdateNode = !this.isUpdateNode;
      this.isBackgroundUri = !this.isBackgroundUri;
      this.isSelectItemImage = !this.isSelectItemImage;
      this.isSelectImage = !this.isSelectImage;
    },
    closeEditImage() {
      this.isSelectImage = false;
      this.selectImageUri = '';
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
  },
};
</script>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
