<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Postbox Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="createNewEventMessage()">
            Create New Message
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-btn small fab icon @click="fetchEventMessage()">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="header"
            :items="eventMessageStore"
            :search="search"
            :pagination.sync="pagination"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item.created_at }}</td>
              <td class="text-xs-center">{{ props.item.title }}</td>
              <td class="text-xs-center">
                {{ props.item.mapped_name_type }}
              </td>
              <td class="text-xs-center">
                {{ props.item.is_active }}
              </td>
              <td class="text-xs-center">
                {{ props.item.text }}
              </td>
              <td class="text-xs-center">
                {{ props.item.schedule_event_data.level }}
              </td>
              <td class="text-xs-center">
                {{ props.item.schedule_event_data.unit }}
              </td>
              <td class="text-xs-center">
                {{ props.item.schedule_event_data.lesson }}
              </td>
              <td class="text-xs-center">
                {{ props.item.schedule_event_data.volume }}
              </td>
              <td class="text-xs-center">
                {{ props.item.schedule_event_data.is_success }}
              </td>
              <td class="justify-center layout px-0">
                <v-icon small class="mr-2" @click="onClickEditModal(props.item)"
                  >edit</v-icon
                >
                <v-icon
                  v-if="userLevel === 99"
                  small
                  @click="
                    onClickDeleteEventMessage(props.item._id, props.item.text)
                  "
                  >delete</v-icon
                >
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-flex>
    <!-- Create Modal -->
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="onCloseCreate"
      :width="widthModalCreate"
      :isLastStep="create.isCreateFormValid"
      title="Postbox Create Modal"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-form ref="createForm" v-model="create.isCreateFormValid">
            <v-flex>
              <v-switch
                label="Is Active"
                v-model="create.eventMessage.is_active"
              ></v-switch>
              <v-autocomplete
                required
                :rules="requiredRule"
                label="Send When"
                v-model="create.eventMessage.type"
                :items="Constant.MESSAGE_SCHEDULE_TYPE_LIST"
                chips
                hide-details
                hide-no-data
                hide-selected
              ></v-autocomplete>
              <v-text-field
                required
                :rules="requiredRule"
                label="Title"
                v-model="create.eventMessage.title"
              ></v-text-field>
              <div
                style="padding-top: 10px; padding-bottom: 10px"
                v-if="
                  create.eventMessage.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.TIME
                "
              >
                <v-layout grid-list-xs column text-xs-center row>
                  <v-flex grow pa-1>
                    <v-date-picker
                      v-model="create.eventMessage.active_date"
                      :min="datepicker.min"
                      :landscape="datepicker.landscape"
                      :reactive="datepicker.reactive"
                      :show-week="datepicker.show_week"
                    ></v-date-picker>
                  </v-flex>
                  <v-flex shrink pa-1>
                    <div
                      v-if="
                        create.eventMessage.type ===
                        Constant.MESSAGE_SCHEDULE_TYPE.TIME
                      "
                    >
                      <v-time-picker
                        v-model="create.eventMessage.active_time"
                        :landscape="timepicker.landscape"
                        :format="timepicker.format"
                      ></v-time-picker>
                    </div>
                  </v-flex>
                </v-layout>
              </div>

              <div
                v-if="
                  create.eventMessage.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.END_LESSON
                "
              >
                <CreateLesson :object="create.eventMessage"> </CreateLesson>
              </div>

              <div
                v-if="
                  create.eventMessage.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.END_UNIT
                "
              >
                <CreateUnit :object="create.eventMessage"> </CreateUnit>
              </div>

              <div
                v-if="
                  create.eventMessage.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.END_LEVEL
                "
              >
                <CreateLevel :object="create.eventMessage"></CreateLevel>
              </div>

              <div
                v-if="
                  create.eventMessage.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.NOT_LAUNCH_THE_APP
                "
              >
                <v-text-field
                  :rules="volumeMinMaxRules"
                  type="number"
                  label="Volume (Minutes)"
                  v-model.number="create.eventMessage.volume"
                ></v-text-field>
              </div>

              <div
                v-if="
                  create.eventMessage.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.WEEKLY_GOAL
                "
              >
                <v-text-field
                  :rules="volumeMinMaxRules"
                  type="number"
                  label="Volume (Minutes)"
                  v-model.number="create.eventMessage.volume"
                ></v-text-field>
                <v-switch
                  label="Is Success"
                  v-model="create.eventMessage.is_success"
                ></v-switch>
              </div>

              <v-textarea
                :rules="requiredRule"
                label="Message Overview"
                v-model="create.eventMessage.text"
                :value="create.eventMessage.text"
              ></v-textarea>

              <div style="padding-bottom: 20px">
                <h2>Tags Helper</h2>
              </div>
              <v-spacer></v-spacer>
              <v-btn color="info" @click="addTags('create', 'text')"
                >Add Text</v-btn
              >
              <v-btn color="success" @click="addTags('create', 'img')"
                >Add Image</v-btn
              >
              <v-btn color="error" @click="addTags('create', 'video')"
                >Add Video</v-btn
              >
              <v-btn color="warning" @click="addTags('create', 'btn')"
                >Add Button</v-btn
              >
              <v-btn color="warning" @click="addTags('create', 'btn_dp')"
                >Add Button Deep Link</v-btn
              >
              <v-flex>
                <v-autocomplete
                  label="Select Language"
                  v-model="selectedLanguage"
                  :items="languageList"
                  chips
                  hide-details
                  hide-no-data
                  hide-selected
                ></v-autocomplete>
                <v-btn
                  v-if="selectedLanguage"
                  color="info"
                  @click="addLanguageTag('create', selectedLanguage)"
                  >Add Language</v-btn
                >
              </v-flex>
              <v-spacer></v-spacer>
            </v-flex>
          </v-form>
        </v-layout>
      </v-container>
    </Modal>
    <!-- Edit Modal -->
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onSaveEdit"
      :close="onCloseEdit"
      :width="widthModalEdit"
      :isLastStep="edit.isEditFormValid"
      title="Postbox Edit Modal"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-form ref="editForm" v-model="edit.isEditFormValid">
            <v-flex>
              <v-switch
                label="Is Active"
                v-model="edit.eventMessage.is_active"
              ></v-switch>
              <v-autocomplete
                disabled
                required
                :rules="requiredRule"
                label="Send When"
                v-model="edit.eventMessage.schedule_event_data.type"
                :items="Constant.MESSAGE_SCHEDULE_TYPE_LIST"
                chips
                hide-details
                hide-no-data
                hide-selected
              ></v-autocomplete>
              <v-text-field
                required
                :rules="requiredRule"
                label="Title"
                v-model="edit.eventMessage.title"
              ></v-text-field>
              <div
                style="padding-top: 10px; padding-bottom: 10px"
                v-if="
                  edit.eventMessage.schedule_event_data.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.TIME
                "
              >
                <v-layout grid-list-xs column text-xs-center row>
                  <v-flex grow pa-1>
                    <v-date-picker
                      v-model="
                        edit.eventMessage.schedule_event_data.active_date
                      "
                      :min="datepicker.min"
                      :landscape="datepicker.landscape"
                      :reactive="datepicker.reactive"
                      :show-week="datepicker.show_week"
                    ></v-date-picker>
                  </v-flex>
                  <v-flex shrink pa-1>
                    <div
                      v-if="
                        edit.eventMessage.schedule_event_data.type ===
                        Constant.MESSAGE_SCHEDULE_TYPE.TIME
                      "
                    >
                      <v-time-picker
                        v-model="
                          edit.eventMessage.schedule_event_data.active_time
                        "
                        :landscape="timepicker.landscape"
                        :format="timepicker.format"
                      ></v-time-picker>
                    </div>
                  </v-flex>
                </v-layout>
              </div>

              <div
                v-if="
                  edit.eventMessage.schedule_event_data.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.END_LESSON
                "
              >
                <v-text-field
                  :rules="minMaxRules"
                  type="number"
                  label="Level Number"
                  v-model.number="edit.eventMessage.schedule_event_data.level"
                ></v-text-field>
                <v-text-field
                  :rules="minMaxRules"
                  type="number"
                  label="Unit Number"
                  v-model.number="edit.eventMessage.schedule_event_data.unit"
                ></v-text-field>
                <v-text-field
                  :rules="minMaxRules"
                  type="number"
                  label="Lesson Number"
                  v-model.number="edit.eventMessage.schedule_event_data.lesson"
                ></v-text-field>
              </div>

              <div
                v-if="
                  edit.eventMessage.schedule_event_data.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.END_UNIT
                "
              >
                <v-text-field
                  :rules="minMaxRules"
                  type="number"
                  label="Level Number"
                  v-model.number="edit.eventMessage.schedule_event_data.level"
                ></v-text-field>
                <v-text-field
                  :rules="minMaxRules"
                  type="number"
                  label="Unit Number"
                  v-model.number="edit.eventMessage.schedule_event_data.unit"
                ></v-text-field>
              </div>

              <div
                v-if="
                  edit.eventMessage.schedule_event_data.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.END_LEVEL
                "
              >
                <v-text-field
                  :rules="minMaxRules"
                  type="number"
                  label="Level Number"
                  v-model.number="edit.eventMessage.schedule_event_data.level"
                ></v-text-field>
              </div>

              <div
                v-if="
                  edit.eventMessage.schedule_event_data.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.NOT_LAUNCH_THE_APP
                "
              >
                <v-text-field
                  :rules="volumeMinMaxRules"
                  type="number"
                  label="Volume (Minutes)"
                  v-model.number="edit.eventMessage.schedule_event_data.volume"
                ></v-text-field>
              </div>

              <div
                v-if="
                  edit.eventMessage.schedule_event_data.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.WEEKLY_GOAL
                "
              >
                <v-text-field
                  :rules="volumeMinMaxRules"
                  type="number"
                  label="Volume (Minutes)"
                  v-model.number="edit.eventMessage.schedule_event_data.volume"
                ></v-text-field>
                <v-switch
                  label="Is Success"
                  v-model="edit.eventMessage.schedule_event_data.is_success"
                ></v-switch>
              </div>

              <v-textarea
                :rules="requiredRule"
                label="Message Overview"
                v-model="edit.eventMessage.text"
                :value="edit.eventMessage.text"
              ></v-textarea>

              <div style="padding-bottom: 20px">
                <h2>Tags Helper</h2>
              </div>
              <v-spacer></v-spacer>
              <v-btn color="info" @click="addTags('edit', 'text')"
                >Add Text</v-btn
              >
              <v-btn color="success" @click="addTags('edit', 'img')"
                >Add Image</v-btn
              >
              <v-btn color="error" @click="addTags('edit', 'video')"
                >Add Video</v-btn
              >
              <v-btn color="warning" @click="addTags('edit', 'btn')"
                >Add Button</v-btn
              >
              <v-btn color="warning" @click="addTags('edit', 'btn_dp')"
                >Add Button Deep Link</v-btn
              >
              <v-flex>
                <v-autocomplete
                  label="Select Language"
                  v-model="selectedLanguage"
                  :items="languageList"
                  chips
                  hide-details
                  hide-no-data
                  hide-selected
                ></v-autocomplete>
                <v-btn
                  v-if="selectedLanguage"
                  color="info"
                  @click="addLanguageTag('edit', selectedLanguage)"
                  >Add Language</v-btn
                >
              </v-flex>
              <v-spacer></v-spacer>
            </v-flex>
          </v-form>
        </v-layout>
      </v-container>
    </Modal>
  </Container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LANGUAGES } from '../../constants/localization/languageList';
import moment from 'moment';
import Modal from '../../components/Modal';
import Container from '../../components/Container';
import Constant from '../../constants/postbox/postbox';

import CreateLevel from './components/create/level';
import CreateUnit from './components/create/unit';
import CreateLesson from './components/create/lesson';
import Swal from 'sweetalert2';

export default {
  name: 'Postbox',
  components: {
    Container,
    Modal,

    CreateLevel,
    CreateUnit,
    CreateLesson,
  },
  computed: {
    ...mapState({
      eventMessageStore: state => state.postbox.EVENT_MESSAGE,
      userLevel: state => state.userInfo.userData.role_id,
    }),
  },
  data() {
    return {
      watcherTypeCreateModal: null,
      activeDateDropDownWatcherEditModal: null,
      selectedLanguage: '',
      languageList: LANGUAGES,
      widthModalEdit: '60%',
      editedIndex: -1,
      edit: {
        isEditFormValid: false,
        eventMessage: {},
      },
      isEdit: false,
      volumeMinMaxRules: [
        v => (v && v >= 0) || v === 0 || 'Min number should be above -1',
      ],
      minMaxRules: [
        v => !!v || 'Number is required but cannot be zero',
        v => (v && v >= 1) || 'Min number should be above 0',
        v => (v && v <= 100) || 'Max number should not be above 100',
      ],
      requiredRule: [v => !!v || 'This field is required'],
      timepicker: {
        landscape: false,
        format: '24hr',
      },
      datepicker: {
        min: moment().subtract(1, 'days').format(),
        landscape: false,
        reactive: true,
        show_week: true,
      },
      Constant,
      row: {
        default: 3,
      },
      widthModalCreate: '60%',
      create: {
        isCreateFormValid: false,
        eventMessage: {},
      },
      isCreate: false,
      search: '',
      pagination: {
        rowsPerPage: 25,
      },
      header: [
        {
          text: 'Created at',
          value: 'created_at',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Title',
          value: 'title',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Schedule Event Type',
          value: 'mapped_name_type',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Is Active',
          value: 'is_active',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Text',
          value: 'text',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Level',
          value: 'level',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Unit',
          value: 'unit',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Lesson',
          value: 'lesson',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Volume',
          value: 'volume',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Is Success',
          value: 'is_success',
          align: 'center',
          sortable: true,
        },

        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ],
    };
  },
  methods: {
    ...mapActions('postbox', [
      'fetchEventMessage',
      'createEventMessage',
      'updateEventMessage',
      'deleteEventMessage',
    ]),
    watchTypeCreateModal() {
      this.watcherTypeCreateModal = this.$watch(
        'create.eventMessage.type',
        value => {
          switch (value) {
            case Constant.MESSAGE_SCHEDULE_TYPE.INVITATION_REWARD:
              this.eventMessageStore.forEach(element => {
                if (
                  element.schedule_event_data.type ===
                  Constant.MESSAGE_SCHEDULE_TYPE.INVITATION_REWARD
                ) {
                  Swal.fire({
                    title: 'Invitation Reward Already Exists!',
                    type: 'warning',
                    text: 'it can have only one, please use feature edit instead.',
                    timer: 7000,
                  });
                  const setTypeNone = () => {
                    this.create.eventMessage = {
                      is_active: false,
                      text: '',
                      type: Constant.MESSAGE_SCHEDULE_TYPE.NONE,
                      level: -1,
                      unit: -1,
                      lesson: -1,
                      volume: 0,
                      is_success: true,
                    };
                    this.onCloseCreate();
                  };
                  setTypeNone();
                }
              });
              const initialRequiredFieldsInvitationReward = () =>
                (this.create.eventMessage = {
                  is_active: false,
                  type: value,
                  text: '<English><text>The invitation system give you [ticket_reward_amount] tickets from [parent_email].\n You can use this ticket for join Class.</text><btn_dp link=edit_deeplink_here align=right>Claim</btn_dp></English>',
                  level: 1,
                  unit: -1,
                  lesson: -1,
                  volume: 0,
                  is_success: true,
                });
              initialRequiredFieldsInvitationReward();
              break;
            case Constant.MESSAGE_SCHEDULE_TYPE.END_LEVEL:
              const initialRequiredFieldsLevel = () =>
                (this.create.eventMessage = {
                  is_active: false,
                  type: value,
                  text: '',
                  level: 1,
                  unit: -1,
                  lesson: -1,
                  volume: 0,
                  is_success: true,
                });
              initialRequiredFieldsLevel();
              break;
            case Constant.MESSAGE_SCHEDULE_TYPE.END_UNIT:
              const initialRequiredFieldsUnit = () =>
                (this.create.eventMessage = {
                  is_active: false,
                  type: value,
                  text: '',
                  level: 1,
                  unit: 1,
                  lesson: -1,
                  volume: 0,
                  is_success: true,
                });
              initialRequiredFieldsUnit();
              break;
            case Constant.MESSAGE_SCHEDULE_TYPE.END_LESSON:
              const initialRequiredFieldsLesson = () =>
                (this.create.eventMessage = {
                  is_active: false,
                  type: value,
                  text: '',
                  level: 1,
                  unit: 1,
                  lesson: 1,
                  volume: 0,
                  is_success: true,
                });
              initialRequiredFieldsLesson();
              break;
            default:
              const initialRequiredFields = () =>
                (this.create.eventMessage = {
                  is_active: false,
                  text: '',
                  type: value,
                  level: -1,
                  unit: -1,
                  lesson: -1,
                  volume: 0,
                  is_success: true,
                });
              initialRequiredFields();
              break;
          }
        },
      );
    },
    async resetForm(name) {
      if (this.$refs && this.$refs[name]) {
        await this.$refs[name].reset();
      }
    },
    async createNewEventMessage() {
      this.watchTypeCreateModal();
      this.isCreate = true;
      setTimeout(() => {
        this.resetForm('createForm');
      }, 200);
    },
    addLanguageTag(modal_name, language_tag) {
      const tag = {
        language: {
          placeholder: ``,
          begin: `<${language_tag}>`,
          end: `</${language_tag}>`,
        },
      };
      const result =
        tag.language.begin + tag.language.placeholder + tag.language.end;
      switch (modal_name) {
        case 'create':
          this.create.eventMessage.text += result;
          break;
        case 'edit':
          this.edit.eventMessage.text += result;
          break;
        default:
          break;
      }
    },
    addTags(modal_name, tag_name) {
      const tag = {
        img: {
          placeholder: `edit image url here`,
          begin: `<${tag_name} w=800 h=400 align=center>`,
          end: `</${tag_name}>`,
        },
        video: {
          placeholder: `edit video url here`,
          begin: `<${tag_name} img=edit_video_icon_here w=800 h=400 align=center>`,
          end: `</${tag_name}>`,
        },
        btn: {
          placeholder: `Button Text`,
          begin: `<${tag_name} link=edit_button_link_here align=right>`,
          end: `</${tag_name}>`,
        },
        btn_dp: {
          placeholder: `Button Text`,
          begin: `<${tag_name} link=edit_deeplink_here align=right>`,
          end: `</${tag_name}>`,
        },
        text: {
          placeholder: `edit text here`,
          begin: `<${tag_name}>`,
          end: `</${tag_name}>`,
        },
      };
      const result =
        tag[tag_name].begin + tag[tag_name].placeholder + tag[tag_name].end;
      switch (modal_name) {
        case 'create':
          this.create.eventMessage.text += result;
          break;
        case 'edit':
          this.edit.eventMessage.text += result;
          break;
        default:
          break;
      }
    },
    async formatActiveDateTimeForCreateModal() {
      this.create.eventMessage.active_date
        ? (this.create.eventMessage.active_date = this.formatDateTime(
            this.create.eventMessage.active_date,
            this.formatTimeByMoment(this.create.eventMessage.active_date),
          ))
        : (this.create.eventMessage.active_date = new Date());
    },
    destroyWatcherTypeCreateModal() {
      this.watcherTypeCreateModal();
    },
    onSaveCreate() {
      this.formatActiveDateTimeForCreateModal();
      const createObject = {
        ...this.create.eventMessage,
      };
      this.createEventMessage(createObject);
      this.isCreate = false;
      this.destroyWatcherTypeCreateModal();
    },
    onCloseCreate() {
      this.isCreate = false;
      this.destroyWatcherTypeCreateModal();
    },
    formatTimeByMoment(active_date) {
      const formatActiveDate = moment(active_date).format('YYYY-MM-DD HH:mm');
      const time = formatActiveDate.substr(10, 6);
      return time;
    },
    formatDateByMoment(active_date) {
      const formatActiveDate = moment(active_date).format('YYYY-MM-DD HH:mm');
      const date = formatActiveDate.substr(0, 10);
      return date;
    },
    watchDropDownActiveDateModalEdit(active_date) {
      this.activeDateDropDownWatcherEditModal = this.$watch(
        'edit.eventMessage.schedule_event_data.type',
        () => {
          if (
            this.edit.eventMessage.schedule_event_data.type ===
            Constant.MESSAGE_SCHEDULE_TYPE.TIME
          ) {
            this.formatActiveDateTimeForEditModal(active_date);
          }
        },
      );
    },
    formatActiveDateTimeForEditModal(active_date) {
      this.edit.eventMessage.schedule_event_data.active_date =
        this.formatDateByMoment(active_date);
      this.edit.eventMessage.schedule_event_data.active_time =
        this.formatTimeByMoment(active_date);
    },
    async onClickEditModal(item) {
      this.watchDropDownActiveDateModalEdit(
        item.schedule_event_data.active_date,
      );
      this.editedIndex = this.eventMessageStore.indexOf(item);
      this.edit.eventMessage = await Object.assign({}, item);
      if (
        item.schedule_event_data.type === Constant.MESSAGE_SCHEDULE_TYPE.TIME
      ) {
        this.formatActiveDateTimeForEditModal(
          item.schedule_event_data.active_date,
        );
      }
      this.isEdit = true;
    },
    onCloseEdit() {
      this.activeDateDropDownWatcherEditModal();
      this.edit.eventMessage = {};
      this.isEdit = false;
    },
    formatDateTime(date, time) {
      return moment(date + ' ' + time).toISOString();
    },
    formatActiveDateTimeEditModal() {
      this.edit.eventMessage.schedule_event_data.active_date =
        this.formatDateTime(
          this.edit.eventMessage.schedule_event_data.active_date,
          this.edit.eventMessage.schedule_event_data.active_time,
        );
    },
    onSaveEdit() {
      this.formatActiveDateTimeEditModal();
      const updateObject = {
        _id: this.edit.eventMessage._id,
        title: this.edit.eventMessage.title,
        text: this.edit.eventMessage.text,
        is_active: this.edit.eventMessage.is_active,
        schedule_event_data: {
          type: this.edit.eventMessage.schedule_event_data.type,
          active_date: this.edit.eventMessage.schedule_event_data.active_date,
          level: this.edit.eventMessage.schedule_event_data.level,
          unit: this.edit.eventMessage.schedule_event_data.unit,
          lesson: this.edit.eventMessage.schedule_event_data.lesson,
          volume: this.edit.eventMessage.schedule_event_data.volume,
          is_success: this.edit.eventMessage.schedule_event_data.is_success,
        },
      };
      this.updateEventMessage(updateObject);
      this.onCloseEdit();
    },
    onClickDeleteEventMessage(_id, text) {
      this.deleteEventMessage({ _id: _id, text: text });
    },
  },
  created() {
    this.fetchEventMessage();
  },
};
</script>

<style></style>
