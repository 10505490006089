<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Snap Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="success" light v-on:click="onClickIsModalSnapManager()">
            <v-icon>add</v-icon>Add Snap
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="category in snapList"
              :key="category._id"
              @click="onClickSelect(category._id)"
            >
              <v-list-tile-avatar>
                <v-icon>import_contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ category.category }} ({{
                    category.number_of_game
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ category.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <SnapManager
      :isOpen="isModalSnapManager"
      :closeModal="closeModalSnapManager"
      :isCreate="isCreate"
      @saveModal="onSaveCreate($event)"
    ></SnapManager>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../components/Container';
import swal from 'sweetalert2';
import SnapManager from './components/SnapManager.vue';

export default {
  name: 'Category',
  components: {
    Container,
    SnapManager,
  },
  computed: {
    ...mapState({
      snapStore: state => state.snap,
      snapList: state => state.snap.snapList,
      selectedSnap: state => state.snap.selectedSnap,
    }),
  },
  data: function () {
    return {
      isCreate: false,
      isModalSnapManager: false,
      search: '',
    };
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('snap', ['fetchSnapCategory', 'setStateByKey', 'createSnap']),
    onClickSelect(game_list_id) {
      this.$router.push({
        path: '/snap/detail',
        query: {
          game_list_id,
        },
      });
    },
    onSaveCreate(createData) {
      const createSnapData = { ...createData };
      this.createSnap(createSnapData);
      this.closeModalSnapManager();
    },
    async onClickIsModalSnapManager() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
      this.isModalSnapManager = true;
    },
    closeModalSnapManager() {
      this.isModalSnapManager = false;
      this.isCreate = false;
    },
  },
  async mounted() {
    await this.fetchSnapCategory({ page: 1, limit: 0 });
  },
};
</script>
