import moment from 'moment';
import http from '../../utils/httpClient';
import swal from 'sweetalert2';

const defaultPhonicSound = {
  index: 0,
  text: '',
  soundUri: '',
  repeat: 1,
  playWith: -1,
  skip: false,
};
const state = {
  dictionaryList: [],
  isLoadDictionary: false,
  selectedWord: {},
  createWord: {
    word_ch: '',
    word: '',
    img_uri: '',
    system_phone: '',
    system_phone_ch: '',
    tags: [],
    phonicSound: [
      {
        index: 0,
        text: '',
        soundUri: '',
        repeat: 1,
        playWith: -1,
        skip: false,
      },
    ],
    is_sprite_sheet: false,
    sprite_sheet_data: { column: 1, row: 1, frame_ms: 200, loop_count: -1 },
  },
  speakingWord: '',
};
const getters = {};
const actions = {
  searchTags({ commit, dispatch }, { tags }) {
    const tag = tags.join();
    http
      .GET(`/api/dicphonic/tags?tags=${tag}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(dictionary => ({
          ...dictionary,
          created_at: moment(dictionary.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(dictionary.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeDictionary', formatData);
      })
      .catch(() => {
        commit('clearDictionaryList');
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Search tag not found.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchSpeakingWord({ commit, dispatch }, { category, key, type }) {
    http
      .GET(`/api/library/word/targettranscription?category=${category}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const value = data;
        if (type === 'create') {
          commit('storeCreateWord', {
            key,
            value,
          });
        } else {
          commit('storeUpdateWord', {
            key,
            value,
          });
        }
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message
              ? err.response.data.message
              : 'Cannot get system phonic. Please try again.',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchSpeakingWordChinese({ commit, dispatch }, { word_ch, key, type }) {
    http
      .GET(`/api/library/word/targettranscriptionchinese?word_ch=${word_ch}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const value = data;
        if (type === 'create') {
          commit('storeCreateWord', {
            key,
            value,
          });
        } else {
          commit('storeUpdateWord', {
            key,
            value,
          });
        }
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message
              ? err.response.data.message
              : 'Cannot get system phonic chinese. Please try again.',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchDictionary({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('storeIsloadDictionary');
    http
      .GET(`/api/dicphonic?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(dictionary => ({
          ...dictionary,
          created_at: moment(dictionary.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(dictionary.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeDictionary', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch dictionary at this time. Please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => {
        commit('storeIsloadDictionary');
      });
  },
  addNewWord({ dispatch }, wordData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Adding new word',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/dicphonic', wordData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchDictionary', {});
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Add new word successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  updateWord({ dispatch }, { data, tag }) {
    delete data.created_at;
    delete data.updated_at;
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Updating word',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/dicphonic', data, {
        isAuthorization: true,
      })
      .then(() => {
        if (tag)
          dispatch('searchTags', {
            tags: tag,
          });
        else dispatch('fetchDictionary', {});
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update word successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteWordById({ dispatch }, { id }) {
    swal
      .fire({
        titleText: 'Confirm Delete Word',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/dicphonic?id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete word successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchDictionary', {});
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Error: Cannot delete this word. Please try again.',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  editUpdateWord({ commit }, { key, value }) {
    commit('storeUpdateWord', {
      key,
      value,
    });
  },
  editUpdatePhonicSound({ commit }, { key, value, index }) {
    commit('storeUpdatePhonicSound', {
      key,
      value,
      index,
    });
  },
  addUpdatePhonicSound({ commit }) {
    commit('storeAddUpdatePhonicSound', { ...defaultPhonicSound });
  },
  editCreateWord({ commit }, { key, value }) {
    commit('storeCreateWord', {
      key,
      value,
    });
  },
  editCreatePhonicSound({ commit }, { key, value, index }) {
    commit('storeCreatePhonicSound', {
      key,
      value,
      index,
    });
  },
  addCreatePhonicSound({ commit }) {
    commit('storeAddCreatePhonicSound', { ...defaultPhonicSound });
  },
  selectedWord({ commit }, id) {
    commit('storeSeleteWord', id);
  },
  clearSelectData({ commit }) {
    commit('clearSelectWord');
  },
  clearCreateWord({ commit }) {
    commit('clearCreateWordStore');
  },
  clearDictionaryList({ commit }) {
    commit('clearDictionaryList');
  },
};
const mutations = {
  storeDictionary(state, dictionary) {
    state.dictionaryList = dictionary.map(res => ({
      ...res,
      created_at: moment
        .unix(new Date(res.created_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
      updated_at: moment
        .unix(new Date(res.updated_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
    }));
  },
  storeIsloadDictionary(state) {
    state.isLoadDictionary = !state.isLoadDictionary;
  },
  storeUpdateWord(state, { key, value }) {
    state.selectedWord = {
      ...state.selectedWord,
      [key]: value,
    };
  },
  storeUpdatePhonicSound(state, { key, value, index }) {
    state.selectedWord.phonicSound[index] = {
      ...state.selectedWord.phonicSound[index],
      [key]: value,
    };
  },
  storeAddUpdatePhonicSound(state, data) {
    const phonicSoundLength = state.selectedWord.phonicSound.length - 1;
    const lastIndex = state.selectedWord.phonicSound[phonicSoundLength].index;
    const newData = { ...data, index: lastIndex + 1 };
    state.selectedWord.phonicSound.push(newData);
  },
  storeCreateWord(state, { key, value }) {
    state.createWord = {
      ...state.createWord,
      [key]: value,
    };
  },
  storeCreatePhonicSound(state, { key, value, index }) {
    state.createWord.phonicSound[index] = {
      ...state.createWord.phonicSound[index],
      [key]: value,
    };
  },
  storeAddCreatePhonicSound(state, data) {
    const phonicSoundLength = state.createWord.phonicSound.length - 1;
    const lastIndex = state.createWord.phonicSound[phonicSoundLength].index;
    const newData = { ...data, index: lastIndex + 1 };
    state.createWord.phonicSound.push(newData);
  },
  storeSeleteWord(state, id) {
    const Index = state.dictionaryList.findIndex(word => word._id === id);
    state.selectedWordIndex = Index;
    state.selectedWord = state.dictionaryList[Index];
  },
  clearSelectWord(state) {
    state.selectedWord = {};
  },
  clearCreateWordStore(state) {
    // state.createWord.word = '';
    // state.createWord.soundUri = '';
    // state.createWord.system_phone = '';
    // state.createWord.img_uri = '';
    state.createWord = {};
    state.createWord.phonicSound = [{ ...defaultPhonicSound }];
    state.createWord.is_sprite_sheet = false;
    state.createWord.sprite_sheet_data = {
      column: 1,
      row: 1,
      frame_ms: 200,
      loop_count: -1,
    };
  },
  clearDictionaryList(state) {
    state.dictionaryList = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
