<template>
  <div>
    <v-btn block color="brown" @click="openModalItem"> Hole Manager </v-btn>
    <Modal
      v-if="isOpenItemModal"
      :isOpen="isOpenItemModal"
      :save="onSaveItem"
      :close="closeModalItem"
      :isLastStep="true"
      :width="width"
      title="Hole Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          disabled
          @click="increaseStep(name_list.hole_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.hole_manager_list">
          <v-stepper-header>
            <template v-for="n in step.hole_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.hole_manager_list > n"
                :step="n"
                editable
              >
                {{ n === 1 ? `Low` : `High` }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.hole_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.hole_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  disabled
                  @click="
                    reduceStep(
                      element.hole_manager_list,
                      name_list.hole_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <div>
                  <img
                    v-if="hole[step - 1].image_uri"
                    :src="hole[step - 1].image_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Image URI (URL or Select file)"
                    v-model="hole[step - 1].image_uri"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      isImageList = true;
                      isSelectImage = true;
                    "
                  >
                    <v-icon>image</v-icon>Select Image
                  </v-btn>
                </div>
                <div>
                  <img
                    v-if="hole[step - 1].mouth_uri"
                    :src="hole[step - 1].mouth_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Mouth Image URI (URL or Select file)"
                    v-model="hole[step - 1].mouth_uri"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      isMouthImage = true;
                      isSelectImage = true;
                    "
                  >
                    <v-icon>image</v-icon>Select Mouth Image
                  </v-btn>
                </div>
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.hole_manager_list)"
              >
                Next
              </v-btn>
              <v-btn flat @click="backStep(step, name_list.hole_manager_list)">
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>

    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeEditImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeEditSound"
      :save="saveSelectSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import ResourceSelect from '../../../../components/ResourceSelect.vue';
export default {
  name: 'UpdateHole',
  components: {
    Modal,
    ResourceSelect,
  },
  computed: {},
  props: {
    hole: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMouthImage: false,
      isUpdate: false,
      isSelectImage: false,
      isSelectSound: false,
      selectImageUri: '',
      selectSoundUri: '',
      maxHole: 2,
      name_list: {
        hole_manager_list: 'hole_manager_list',
      },
      element: {
        hole_manager_list: 1,
      },
      step: {
        hole_manager_list: 1,
      },
      isOpenItemModal: false,
      width: '60%',
    };
  },
  methods: {
    saveSelectImage() {
      const uri = this.selectImageUri;
      const index = this.element.hole_manager_list - 1;
      if (this.isImageList) {
        this.hole[index].image_uri = uri;
      } else if (this.isMouthImage) {
        this.hole[index].mouth_uri = uri;
      }
      this.selectImageUri = '';
      this.isImageList = false;
      this.isSelectImage = false;
    },
    closeEditImage() {
      this.isSelectImage = false;
      this.selectImageUri = '';
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectSoundUri;
      if (this.isUpdate) {
        const index = this.element.hole_manager_list - 1;
        // not implemented
        // this.hole[index].bgm_uri = uri;
      }
      this.selectSoundUri = '';
      this.isUpdate = false;
      this.isSelectSound = false;
    },
    closeEditSound() {
      this.isSelectSound = false;
      this.selectSoundUri = '';
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    increaseStep(list) {
      if (this.step[list] < this.maxHole) {
        if (list === this.name_list.hole_manager_list) {
          this.initialDefaultItem();
        }
        this.step[list] += 1;
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.hole_manager_list) {
          this.step[list] -= 1;
          this.hole.splice(index, 1);
        }
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },
    openModalItem() {
      this.isOpenItemModal = true;
      this.calculateItemStep();
    },
    calculateItemStep() {
      if (this.hole.length === 0) {
        this.setDefaultItemByMaximumValue();
        this.step.hole_manager_list = this.maxHole;
      } else {
        this.step.hole_manager_list = this.hole.length;
      }
    },
    setDefaultItemByMaximumValue() {
      for (let index = 0; index < this.maxHole; index++) {
        const formatObject = {
          image_uri: '',
          mouth_uri: '',
        };
        this.hole.push(formatObject);
      }
    },
    initialDefaultItem() {
      const formatObject = {
        image_uri: '',
        mouth_uri: '',
      };
      this.hole.push(formatObject);
    },
    onSaveItem() {
      this.isOpenItemModal = false;
    },
    closeModalItem() {
      this.isOpenItemModal = false;
    },
  },
};
</script>

<style></style>
