<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/wordwalks')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (wordwalkStore.selectWordwalk &&
                wordwalkStore.selectWordwalk.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="wordwalkStore.selectWordwalk">
          <v-layout row grid-list-md v-if="!wordwalkStore.isUpdateAnswerData">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Question</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddQuestion">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(question, index) in wordwalkStore.selectWordwalk
                      .questions"
                    :key="question._id"
                    v-on:click="onSelectQuestion(question._id)"
                    :class="
                      index === selectedQuestionIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{
                        question.question
                      }}</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Number of answers
                        {{ question.answers.length }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedQuestionIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Question Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="onClickUpdateQuestion">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDeleteQuestion(question._id)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-flex>
                  <v-btn
                    class="white--text"
                    color="pointdata"
                    @click="openPointData"
                    >Point Data
                  </v-btn>
                </v-flex>
                <v-img :src="question.img_uri">
                  <v-container>
                    <v-layout>
                      <v-flex>
                        <v-btn
                          icon
                          color="white"
                          v-on:click="
                            isEditImage = true;
                            isUpdateQuestion = true;
                          "
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-img>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-text-field
                        label="Question"
                        :value="question.question"
                        v-on:change="e => onChangeQuestionData('question', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      ><v-btn
                        v-if="question.introduction_voice_uri"
                        class="white--text"
                        color="subtitle_sound"
                        @click="openSubtitleSound()"
                        >Subtitle Sound
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-card color="#4d4d4d">
                        <v-card-title class="text-h5">
                          <v-flex>
                            <v-text-field
                              label="TTS Message"
                              :value="question.tts_message"
                              v-model="question.tts_message"
                              v-on:change="
                                e => onChangeQuestionData('tts_message', e)
                              "
                            ></v-text-field>
                            <v-select
                              :items="constants.VOICE_NAME"
                              label="Voice Name"
                              :value="question.voice_name"
                              v-model="question.voice_name"
                              v-on:change="
                                e => onChangeQuestionData('voice_name', e)
                              "
                            ></v-select>
                            <p class="text-xs-center">or</p>
                            <v-text-field
                              label="Question Sound (URL or Select file)"
                              :value="question.introduction_voice_uri"
                              v-model="question.introduction_voice_uri"
                              @change="
                                e =>
                                  onChangeQuestionData(
                                    'introduction_voice_uri',
                                    e,
                                  )
                              "
                            ></v-text-field>
                            <!-- Notes:> Question Sound = Introduction Voice -->
                            <v-btn
                              @click="
                                isEditSound = true;
                                isEditIntroVoice = true;
                              "
                              ><v-icon>audiotrack</v-icon>Select Question
                              Sound</v-btn
                            >
                          </v-flex>
                        </v-card-title>
                      </v-card>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music (URL or Select file)"
                        :value="question.bgm_uri"
                        v-model="question.bgm_uri"
                        @change="e => onChangeQuestionData('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn
                        @click="
                          isEditSound = true;
                          isEditBackgroundMusic = true;
                        "
                        ><v-icon>audiotrack</v-icon>Select Background
                        Music</v-btn
                      >
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="constants.QUESTION_TYPE"
                        label="Question Type"
                        :value="question.question_type"
                        v-model="question.question_type"
                        v-on:change="
                          e => onChangeQuestionData('question_type', e)
                        "
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="constants.ANSWER_TYPE"
                        label="Answer Type"
                        :value="question.answer_type"
                        v-model="question.answer_type"
                        v-on:change="e => onChangeAnswerType(e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Score"
                        :value="question.score"
                        type="number"
                        v-on:change="
                          e => onChangeQuestionData('score', parseInt(e))
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="characters"
                        label="Character"
                        :value="question.character"
                        v-model="question.character"
                        v-on:change="e => onChangeQuestionData('character', e)"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs2
              mr-2
              v-if="answers.length > 0 && selectedQuestionIndex != -1"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Answer</v-toolbar-title>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(answer, index) in wordwalkStore.selectWordwalk
                      .questions[selectedQuestionIndex].answers"
                    :key="answer._id"
                    v-on:click="onSelectAnswer(answer._id)"
                    :class="
                      index === selectedAnswerIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ index + 1 }}. {{ answer.answer }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title v-if="question.answer_type == 2">
                        Key: {{ answer.match_key }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-avatar
                      v-if="
                        question.answer_type == 0 && answer.is_correct == true
                      "
                    >
                      <v-icon color="green">check_circle</v-icon>
                    </v-list-tile-avatar>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="
                Object.keys(answer).length > 1 && selectedQuestionIndex != -1
              "
              xs4
              mr-2
            >
              <v-card dark v-if="selectedAnswerIndex !== -1">
                <v-toolbar>
                  <v-toolbar-title>Answer detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickSaveAnswer">
                    <v-icon>save</v-icon>Save Answer
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex xs6>
                        <v-layout column>
                          <v-flex>
                            <v-text-field
                              label="Answer"
                              :value="answer.answer"
                              v-on:change="e => onChangeAnswerData('answer', e)"
                            />
                          </v-flex>
                          <v-flex v-if="question.answer_type == 0">
                            <v-checkbox
                              label="Correct Answer"
                              v-model="answer.is_correct"
                              :value="answer.is_correct"
                              v-on:change="e => onChangeCorrectAnswer(e)"
                            >
                            </v-checkbox>
                          </v-flex>
                          <v-flex v-if="question.answer_type == 1">
                            <v-text-field
                              label="Order"
                              :value="answer.order"
                              v-on:change="e => onChangeAnswerData('order', e)"
                              disabled
                            />
                          </v-flex>
                          <v-flex v-if="question.answer_type == 2">
                            <v-select
                              :items="[1, 2]"
                              label="Match Key"
                              :value="answer.match_key"
                              v-model="answer.match_key"
                              v-on:change="
                                e => onChangeAnswerData('match_key', e)
                              "
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6>
                        <img
                          :src="answer.img_uri"
                          :alt="answer.answer"
                          class="content_img"
                        />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <span class="headline" dark>
                                <v-btn
                                  fab
                                  icon
                                  small
                                  color="black"
                                  v-on:click="isEditImage = true"
                                >
                                  <v-icon>edit</v-icon>
                                </v-btn>
                                <v-btn
                                  fab
                                  icon
                                  small
                                  color="black"
                                  v-on:click="onClickFullscreen(answer.img_uri)"
                                >
                                  <v-icon>fullscreen</v-icon>
                                </v-btn>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      title="Point Data"
      :learnedWords="
        selectedQuestionIndex != -1
          ? wordwalkStore.selectWordwalk.questions[selectedQuestionIndex]
              .learned_words
          : null
      "
      :incidentalWords="
        selectedQuestionIndex != -1
          ? wordwalkStore.selectWordwalk.questions[selectedQuestionIndex]
              .incidental_words
          : null
      "
      :rewards="
        selectedQuestionIndex != -1
          ? wordwalkStore.selectWordwalk.questions[selectedQuestionIndex].reward
          : null
      "
      :sentences="
        selectedQuestionIndex != -1
          ? wordwalkStore.selectWordwalk.questions[selectedQuestionIndex]
              .sentence
          : null
      "
      :phonics="
        selectedQuestionIndex != -1
          ? wordwalkStore.selectWordwalk.questions[selectedQuestionIndex].phonic
          : null
      "
    >
    </PointData>
    <Modal
      :v-if="isAddQuestion"
      :isOpen="isAddQuestion"
      :isLastStep="isLastStep"
      :close="toggleAddQuestion"
      :save="onClickAddQuestion"
      title="Add Question"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center v-if="!isAddAnswer">
          <v-flex justify-center>
            <v-img :src="question.img_uri" min-height="250" max-height="250">
              <v-btn v-on:click="isEditImage = true">Select Image</v-btn>
            </v-img>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Question"
              :value="question.question"
              v-model="question.question"
              v-on:change="e => onChangeCreateQuestion('question', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="TTS Message"
              :value="question.tts_message"
              v-model="question.tts_message"
              v-on:change="e => onChangeCreateQuestion('tts_message', e)"
            ></v-text-field>
            <v-select
              :items="constants.VOICE_NAME"
              label="Voice Name"
              :value="question.voice_name"
              v-model="question.voice_name"
              v-on:change="e => onChangeCreateQuestion('voice_name', e)"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.QUESTION_TYPE"
              label="Question Type"
              :value="question.question_type"
              v-model="question.question_type"
              v-on:change="e => onChangeCreateQuestion('question_type', e)"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.ANSWER_TYPE"
              label="Answer Type"
              :value="question.answer_type"
              v-model="question.answer_type"
              v-on:change="e => onChangeAnswerType(e)"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Score"
              :value="question.score"
              v-model="question.score"
              v-on:change="e => onChangeCreateQuestion('score', parseInt(e))"
            >
            </v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              :items="characters"
              label="Character"
              :value="question.character"
              v-model="question.character"
              v-on:change="e => onChangeCreateQuestion('character', e)"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-card v-if="isAddAnswer">
          <v-toolbar dark>
            <v-toolbar-title> Answer {{ currentStep + 1 }} </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container justify-center>
              <v-layout>
                <v-flex>
                  <v-img :src="answer.img_uri" width="400" height="300">
                    <v-btn v-on:click="isEditImage = true">Select Image</v-btn>
                  </v-img>
                </v-flex>
              </v-layout>
            </v-container>
            <v-flex>
              <v-text-field
                label="Answer"
                :value="answer.answer"
                v-model="answer.answer"
                v-on:change="e => onChangeCreateAnswer(e, 'answer')"
              >
              </v-text-field>
            </v-flex>
            <v-flex v-if="question.answer_type.value == 0">
              <v-checkbox
                label="Correct Answer"
                :value="answer.is_correct"
                v-model="answer.is_correct"
                v-on:change="e => onChangeCreateAnswer('is_correct', e)"
              >
              </v-checkbox>
            </v-flex>
            <v-flex v-if="question.answer_type.value == 2">
              <v-select
                :items="[1, 2]"
                label="Match Key"
                :value="answer.match_key"
                v-model="answer.match_key"
                v-on:change="e => onChangeCreateAnswer('match_key', e)"
              >
              </v-select>
            </v-flex>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn
            v-if="currentStep > -1"
            color="red"
            dark
            v-on:click="onClickBackStep"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentStep < 3 && !isLastStep"
            color="green"
            dark
            v-on:click="onClickNextStep"
            >Next</v-btn
          >
        </v-card-actions>
      </v-container>
    </Modal>
  </Container>
</template>

<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
</style>

<script>
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import swal from 'sweetalert2';
import constants from '../../constants';

export default {
  name: 'Wordwalk',
  components: {
    Container,
    ResourceSelect,
    Modal,
    PointData,
  },
  computed: {
    ...mapState({
      wordwalkStore: state => state.wordwalks,
      selectedQuestionIndexStore: state =>
        state.wordwalks.selectedQuestionIndex,
    }),
  },
  data() {
    return {
      isEditBackgroundMusic: false,
      isEditSound: false,
      selectSoundUri: '',
      isEditIntroVoice: false,
      currentStep: -1,
      isLastStep: false,
      answerType: 0,
      answers: [],
      answer: {},
      question: {},
      selectedQuestionIndex: -1,
      selectedAnswerIndex: -1,
      isEditImage: false,
      selectImageUri: '',
      text: '',
      isDirty: false,
      isAddAnswer: false,
      isAddQuestion: false,
      isUpdateQuestion: false,
      constants,
      selectQuestionId: '',
      isOpenPointData: false,
      characters: ['Nana', 'Maxi', 'Big Blue', 'Noodle Monster'],
    };
  },
  methods: {
    ...mapActions('wordwalks', [
      'fetchWordwalk',
      'fetchSingleWordwalk',
      'updateAnswerData',
      'updateAnswer',
      'clearUpdateData',
      'deleteQuestion',
      'createQuestionData',
      'createAnswerData',
      'updateQuestionData',
      'updateQuestion',
      'addQuestion',
      'selectCategory',
      'selectWordwalk',
    ]),
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = false;
      this.isEditIntroVoice = false;
      this.isEditBackgroundMusic = false;
    },
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.question.introduction_voice_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.updateQuestionData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateQuestionData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateQuestionData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateQuestionData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateQuestionData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateQuestion();
      this.isOpenPointData = false;
    },

    onClickNextStep() {
      this.currentStep++;
      if (this.currentStep > -1) {
        this.isAddAnswer = true;
      }
      if (this.currentStep == 3) {
        this.isLastStep = true;
      }
      this.onActiveCreateAnswer();
    },
    onClickBackStep() {
      this.currentStep--;
      this.isLastStep = false;
      if (this.currentStep == -1) {
        this.isAddAnswer = false;
      }
      this.onActiveCreateAnswer();
    },
    async onSelectQuestion(id) {
      this.clearUpdateData();
      const questions = this.wordwalkStore.selectWordwalk.questions;
      const index = questions.findIndex(question => question._id === id);
      this.selectedQuestionIndex = index;
      this.selectedAnswerIndex = -1;
      this.answers = [...questions[index].answers];
      this.question = {
        ...questions[index],
      };
      this.updateQuestionData({
        key: '_id',
        value: id,
      });
      this.selectQuestionId = id;
    },
    onSelectAnswer(id) {
      if (this.isDirty) {
        swal
          .fire({
            titleText:
              'You have not save this answer yet. Are you sure you want to leave?',
            type: 'info',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              this.isDirty = false;
              const answers =
                this.wordwalkStore.selectWordwalk.questions[
                  this.selectedQuestionIndex
                ].answers;
              const index = answers.findIndex(answer => answer._id === id);
              this.selectedAnswerIndex = index;
              this.answer = { ...answers[index] };
              this.selectImageUri = '';
              this.clearUpdateData();
              this.updateAnswerData({
                key: '_id',
                value: id,
              });
            }
          });
      } else {
        const answers =
          this.wordwalkStore.selectWordwalk.questions[
            this.selectedQuestionIndex
          ].answers;
        const index = answers.findIndex(answer => answer._id === id);
        this.selectedAnswerIndex = index;
        this.answer = { ...answers[index] };
        this.selectImageUri = '';
        this.clearUpdateData();
        this.updateAnswerData({
          key: '_id',
          value: id,
        });
      }
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onChangeCorrectAnswer(e) {
      this.isDirty = true;
      this.answer.is_correct = e ? e : false;
      this.updateAnswerData({
        key: 'is_correct',
        value: this.answer.is_correct,
      });
    },
    onChangeAnswerData(key, value) {
      this.isDirty = true;
      this.updateAnswerData({
        key,
        value,
      });
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isEditIntroVoice) {
        this.updateQuestionData({
          key: 'introduction_voice_uri',
          value: uri,
        });
        this.question.introduction_voice_uri = uri;
        this.isEditIntroVoice = false;
      } else if (this.isEditBackgroundMusic) {
        this.updateQuestionData({
          key: 'bgm_uri',
          value: uri,
        });
        this.question.bgm_uri = uri;
        this.isEditBackgroundMusic = false;
      }
      this.selectSoundUri = '';
      this.isEditSound = false;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddAnswer) {
        this.createAnswerData(
          {
            key: 'img_uri',
            value: uri,
          },
          this.currentStep,
        );
        this.answer.img_uri = uri;
      } else if (this.isUpdateQuestion) {
        this.isUpdateQuestion = false;
        this.updateQuestionData({
          key: 'img_uri',
          value: uri,
        });
        this.question.img_uri = uri;
      } else if (this.isAddQuestion) {
        this.createQuestionData({
          key: 'img_uri',
          value: uri,
        });
        this.question.img_uri = uri;
      } else {
        this.isDirty = true;
        this.updateAnswerData({
          key: 'img_uri',
          value: uri,
        });
        this.answer.img_uri = uri;
      }
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    onClickSaveAnswer() {
      this.selectedAnswerIndex = -1;
      const updateAnswerData = {
        ...this.wordwalkStore.updateAnswerData,
      };
      this.updateAnswer(updateAnswerData);
      this.isDirty = false;
      Object.keys(updateAnswerData).forEach(key => {
        this.answer[key] = updateAnswerData[key];
      });
      this.fetchSingleWordwalk({ id: this.$route.query.id });
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    onActiveCreateAnswer() {
      const answers = [...this.wordwalkStore.addAnswerData];
      if (this.currentStep > 0) this.answer = answers[this.currentStep];
      else this.answer = answers[0];
    },
    onChangeCreateAnswer(value, key) {
      this.createAnswerData(
        {
          key,
          value,
        },
        this.currentStep,
      );
    },
    toggleAddQuestion() {
      this.selectedQuestionIndex = -1;
      this.selectedAnswerIndex = -1;
      // init question data
      const question = {
        ...this.wordwalkStore.questionData,
      };
      this.question = { category_id: this.$route.query.id, ...question };
      Object.keys(question).forEach(key => {
        this.createQuestionData({
          key: key,
          value: question[key],
        });
      });
      // init answer data
      const answers = [...this.wordwalkStore.addAnswerData];
      this.createAnswerData({
        key: 'answers',
        value: answers,
      });
      this.isAddQuestion = !this.isAddQuestion;
      if (!this.isAddQuestion && this.wordwalkStore.selectedCategoryId) {
        this.$router.push('/wordwalks');
      }
    },
    onClickAddQuestion() {
      const id = this.$route.query.id;
      const createQuestionData = {
        ...this.wordwalkStore.addQuestionData,
      };
      const createAnswerData = [...this.wordwalkStore.addAnswerData];
      this.addQuestion({
        category_id: id,
        question: createQuestionData,
        answers: createAnswerData,
      });
      this.isAddQuestion = false;
      this.isAddAnswer = false;
    },
    onClickDeleteQuestion(id) {
      this.deleteQuestion(id);
      this.isDirty = false;
      this.selectedQuestionIndex = -1;
      this.answer = {};
      this.answers = [];
      this.selectedAnswerIndex = -1;
    },
    onChangeAnswerType(e) {
      if (this.isAddQuestion) {
        this.createQuestionData({
          key: 'answer_type',
          value: e,
        });
        this.question.answer_type = e;
      } else {
        this.updateQuestionData({
          key: 'answer_type',
          value: e,
        });
        this.question.answer_type = e;
      }
    },
    onChangeQuestionData(key, value) {
      this.updateQuestionData({
        key,
        value,
      });
    },
    onClickUpdateQuestion() {
      const updateQuestionData = {
        ...this.wordwalkStore.updateQuestionData,
      };
      Object.keys(updateQuestionData).forEach(key => {
        this.question[key] = updateQuestionData[key];
      });
      this.updateQuestion(updateQuestionData);
    },
    onChangeCreateQuestion(key, value) {
      this.createQuestionData({
        key,
        value,
      });
    },
  },
  watch: {
    selectedQuestionIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectQuestion(this.$route.query.sub_id);
      }
    },
  },
  mounted() {
    if (this.$route.query.category_id) {
      this.fetchSingleWordwalk({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (this.wordwalkStore.selectedCategoryId) {
      this.toggleAddQuestion();
    } else if (Object.keys(this.wordwalkStore.selectWordwalk).length < 1) {
      this.fetchSingleWordwalk({ id: this.$route.query.id });
    }
  },
};
</script>
