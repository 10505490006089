<template>
  <div>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Container Part</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout column>
            <v-flex>
              <img class="image_thumbnail" :src="selectedContainer.image_uri" />
              <v-text-field
                label="Image URI (URL or Select file)"
                :value="selectedContainer.image_uri"
                v-model="selectedContainer.image_uri"
                @change="e => onchangeNode('image_uri', e)"
                :rules="[rules.validURI]"
              ></v-text-field>
              <v-btn class="btn-center" @click="onclickEditImage()"
                ><v-icon>image</v-icon>Select Image URI</v-btn
              >
              <SpriteSheet
                :title="'Use Container Sprite Sheet'"
                :isToggle="selectedContainer.is_sprite_sheet"
                :fieldname="'Edit Sheet Data'"
                :object="selectedContainer.sprite_sheet_data"
                @onchangeToggleSprite="
                  onchangeToggleSprite('is_sprite_sheet', $event)
                "
                @saveSpriteSheetData="
                  saveSpriteSheetData('sprite_sheet_data', $event)
                "
              />
              <v-flex>
                <v-text-field
                  label="TTS Message"
                  :value="selectedContainer.tts_message"
                  v-on:change="e => onchangeNode('tts_message', e)"
                ></v-text-field>
                <v-select
                  :items="constants.VOICE_NAME"
                  label="Voice Name"
                  :value="selectedContainer.voice_name"
                  v-model="selectedContainer.voice_name"
                  v-on:change="e => onchangeNode('voice_name', e)"
                ></v-select>
              </v-flex>
              <v-text-field
                label="Sound URI (URL or Select file)"
                :value="selectedContainer.tap_sound_uri"
                v-model="selectedContainer.tap_sound_uri"
                v-on:change="e => onchangeNode('tap_sound_uri', e)"
                :rules="[rules.validURI]"
              ></v-text-field>
              <v-btn class="btn-center" @click="onclickMusicSound()"
                ><v-icon>audiotrack</v-icon>Select Sound URI</v-btn
              >
              <v-layout row>
                <v-text-field
                  type="number"
                  label="Start X"
                  :value="selectedContainer.startX"
                  @change="e => onchangeNode('startX', e)"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="End X"
                  :value="selectedContainer.endX"
                  @change="e => onchangeNode('endX', e)"
                ></v-text-field>
              </v-layout>
              <v-layout row>
                <v-text-field
                  type="number"
                  label="Start Y"
                  :value="selectedContainer.startY"
                  @change="e => onchangeNode('startY', e)"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="End Y"
                  :value="selectedContainer.endY"
                  @change="e => onchangeNode('endY', e)"
                ></v-text-field>
              </v-layout>
              <v-text-field
                type="number"
                label="correct count (-1 = click all correct choice to end node)"
                :value="selectedContainer.correct_count"
                v-model="selectedContainer.correct_count"
                @change="e => onchangeNode('correct_count', parseInt(e))"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import rules from '../../../utils/ruleValidate';
import SpriteSheet from '../../../components/SpriteSheetNew.vue';
import constants from '../../../constants';

export default {
  components: {
    ResourceSelect,
    SpriteSheet,
  },
  data() {
    return {
      constants,
      isEditImage: false,
      selectImageUri: '',
      isSelectImage: false,
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectMusic: false,
      valid: true,
      rules,
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
    selectedSetIndex() {
      return this.dragComboStore.selectedSetIndex;
    },
    selectedNodeIndex() {
      return this.dragComboStore.selectedNodeIndex;
    },
    selectedContainer() {
      return this.dragComboStore.selectedDragCombo.sets[this.selectedSetIndex]
        .nodes[this.selectedNodeIndex].container;
    },
    selectedNode() {
      return this.dragComboStore.selectedDragCombo.sets[this.selectedSetIndex]
        .nodes[this.selectedNodeIndex];
    },
    setList() {
      return this.dragComboStore.selectedDragCombo.sets;
    },
  },
  methods: {
    ...mapActions('dragcombo', [
      'updateSet',
      'setStateByKey',
      'deleteSetDragCombo',
      'deleteNodeDragCombo',
      'clearStateByKey',
    ]),
    onclickEditImage() {
      this.isEditImage = true;
      this.isSelectImage = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImage:
          this.selectedContainer.image_uri = uri;
          this.onchangeNode('image_uri', uri);
          this.isSelectImage = false;
          // this.setDirty('game');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onclickMusicSound(type) {
      this.isSelectSound = true;
      this.isSelectMusic = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectMusic:
          this.selectedContainer.tap_sound_uri = uri;
          this.onchangeNode('tap_sound_uri', uri);
          this.isSelectMusic = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onchangeNode(key, value) {
      this.setNodeData(key, value);
      this.setStateByKey({
        statekey: 'updateNode',
        key: 'container',
        value: this.selectedContainer,
      });
    },
    onchangeToggleSprite(key, { value }) {
      this.selectedContainer[key] = value;
      this.onchangeNode(key, value);
    },
    saveSpriteSheetData(key, { value }) {
      this.selectedContainer[key] = value;
      this.onchangeNode(key, value);
    },
    setNodeData(key, value) {
      const containers = { ...this.selectedContainer, [key]: value };
      const nodes = { ...this.selectedNode, container: containers };
      let sets = this.setList;
      sets[this.selectedSetIndex].nodes[this.selectedNodeIndex] = {
        ...nodes,
      };
      this.setStateByKey({
        statekey: 'selectedDragCombo',
        key: 'sets',
        value: sets,
      });
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
</style>
