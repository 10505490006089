<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/mini_games')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Driving Game Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="success" light v-on:click="showModalCreate()">
            <v-icon>add</v-icon>Add Driving Game
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="category in drivingGameList"
              :key="category._id"
              @click="onClickSelect(category._id)"
            >
              <v-list-tile-avatar>
                <v-icon>import_contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ category.category }} ({{
                    category.number_of_game
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ category.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreateUpdate
      :isOpen="isShowModal"
      :isCreate="isCreate"
      @close="isShowModal = false"
    ></ModalCreateUpdate>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../../components/Container';
import swal from 'sweetalert2';
import { ModalCreateUpdate } from './components';

export default {
  name: 'DrivingGameCategory',
  components: {
    Container,
    ModalCreateUpdate,
  },
  computed: {
    ...mapState({
      drivingGameStore: state => state.driving_game,
      drivingGameList: state => state.driving_game.drivingGameList,
      selectedDrivingGame: state => state.driving_game.selectedDrivingGame,
      isLoadDrivingGame: state => state.driving_game.isLoadDrivingGame,
      defaultCreate: state => state.driving_game.defaultCreate,
    }),
  },
  data: function () {
    return {
      search: '',
      isCreate: false,
      isShowModal: false,
    };
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('driving_game', [
      'fetchDrivingCategory',
      'setStateByKey',
      'createDrivingGame',
    ]),
    showModalCreate() {
      this.isShowModal = true;
      this.isCreate = true;
      this.setStateByKey({
        statekey: 'createDrivingGame',
        value: this.defaultCreate,
      });
    },
    onClickSelect(game_list_id) {
      this.$router.push({
        path: '/driving_game/detail',
        query: {
          game_list_id,
        },
      });
    },
  },
  async mounted() {
    await this.fetchDrivingCategory({ page: 1, limit: 0 });
  },
};
</script>
