import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
import Constants from '../../constants';
import httpClient from '../../utils/httpClient';

const defaultActivity = {
  title: 'New Activity',
  transition: '',
  game_data: [
    {
      game_type: 'WWA',
      game_id: '5d0a074567fc4d57a818ef52',
      sub_id_list: [''],
      video_data: [
        {
          video_uri: '',
          isLoop: false,
          nextIndex: -1,
        },
      ],
    },
  ],
  videoData: {
    video_uri: '',
    isLoop: false,
    nextIndex: -1,
  },
};

const state = {
  storygameList: [],
  selectedStoryGame: {},
  updateLevel: {},
  updateActivity: {},
  isLoadStoryGame: false,
  createActivity: { title: '', game_data: [{ game_type: '', game_id: '' }] },
  gameData: { game_type: '', game_id: '', sub_id_list: [''] },
  minichatVideoURIs: [],
};
const getters = {};
const actions = {
  fetchMinichatVideoURIs({ commit, dispatch }, minichat_id) {
    http
      .GET(`/api/minichat/node/getVideoURIs?minichat_id=${minichat_id}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeMinichatVideoURIs', response.data.videoURIs);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Minichat VideoURIs might not available in this time. Please check again later.',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchStoryGame({ commit, dispatch }, filter) {
    commit('storeToggleLoadStoryGame');
    let uri = `/api/storygame?`;
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          uri += `&${key}=${filter[key]}`;
        }
      });
    }
    http
      .GET(uri, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeStoryGame', response.data);
        commit('storeToggleLoadStoryGame');
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Cannot fetch stry game. Please try again',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchSingleStoryGame({ commit, dispatch }, id) {
    commit('storeToggleLoadStoryGame');
    http
      .GET(`/api/storygame?id=${id}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeToggleLoadStoryGame');
        commit('storeStoryGame', response.data);
        commit('storeSelectedStoryGamebyId', id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Cannot fetch stry game please try again',
          },
          {
            root: true,
          },
        );
      });
  },
  selectStoryGame({ commit }, id) {
    commit('storeSelectedStoryGamebyId', id);
  },
  updateLevelData({ commit }, { key, value }) {
    commit('storeUpdateLevel', {
      key,
      value,
    });
  },
  updateLevel({ dispatch, state }) {
    swal
      .fire({
        titleText: 'Confirm changes?',
        showCancelButton: true,
        type: 'question',
      })
      .then(result => {
        if (result.value) {
          http
            .PUT(
              '/api/storygame',
              {
                ...state.updateLevel,
              },
              {
                isAuthorization: true,
              },
            )
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Update Level Success',
                },
                {
                  root: true,
                },
              );
              router.push('/storygame');
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: 'Cannot update unit please try again',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  updateActivityData({ commit }, { key, value }) {
    commit('storeAcitivityData', {
      key,
      value,
    });
  },
  updateActivity({ commit, dispatch, state }) {
    http
      .PUT('/api/storygame/activity', state.updateActivity, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update Activity Success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleStoryGame', state.selectedStoryGame._id);
        commit('clearStoreUpdateActivity');
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Activity Failed Please Try again',
          },
          {
            root: true,
          },
        );
      });
  },
  deleteLevel({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Are you sure to delete this unit',
        type: 'question',
        showCancelButton: true,
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/storygame?id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Delete Level Success',
                },
                {
                  root: true,
                },
              );
              router.push('/storygames');
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: 'Delete Level Failed Please Try again',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  deleteActivity({ dispatch, state }) {
    swal
      .fire({
        titleText: 'Are you sure you want to delete this Activity ?',
        type: 'question',
        showCancelButton: true,
      })
      .then(async result => {
        if (result.value) {
          try {
            const { data: deleteActivityResult } = await http.DELETE(
              `/api/storygame/activity?id=${state.updateActivity.id}`,
              {
                isAuthorization: true,
              },
            );

            const rawCurrentAcitivityId = Object.values(
              state.selectedStoryGame.activities,
            );

            const currentActivityId = rawCurrentAcitivityId.map(
              acitivity => acitivity._id,
            );
            let updateLevel = {
              ...state.selectedStoryGame,
              id: state.selectedStoryGame._id,
              activities: [
                ...currentActivityId.filter(
                  id => deleteActivityResult.id !== id,
                ),
              ],
            };

            delete updateLevel._id;
            const { data: resultUpdateLevel } = await http.PUT(
              '/api/storygame',
              updateLevel,
              {
                isAuthorization: true,
              },
            );

            dispatch(
              'appConfig/toggleAlert',
              {
                type: 'success',
                message: 'Delete Activity Success',
              },
              {
                root: true,
              },
            );
            dispatch('fetchSingleStoryGame', state.selectedStoryGame._id);
          } catch (error) {
            dispatch(
              'appConfig/toggleAlert',
              {
                type: 'error',
                message: 'Delete Activity Failed.Please try again',
              },
              {
                root: true,
              },
            );
          }
        }
      });
  },
  async createActivity({ dispatch, state }) {
    try {
      const { data: resultActivity } = await http.POST(
        '/api/storygame/activity',
        { ...defaultActivity },
        {
          isAuthorization: true,
        },
      );
      const rawCurrentAcitivityId = Object.values(
        state.selectedStoryGame.activities,
      );
      let currentActivityId = rawCurrentAcitivityId.map(
        acitivity => acitivity._id,
      );
      let updateLevel = {
        ...state.selectedStoryGame,
        id: state.selectedStoryGame._id,
        activities: [...currentActivityId, resultActivity.id],
      };
      delete updateLevel._id;
      const { data: resultUpdateLevel } = await http.PUT(
        '/api/storygame',
        updateLevel,
        {
          isAuthorization: true,
        },
      );
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'success',
          message: 'Create Acitivity Complete',
        },
        {
          root: true,
        },
      );
      dispatch('fetchSingleStoryGame', state.selectedStoryGame._id);
    } catch (error) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'error',
          message: 'Create Acitivity Failed Please Try again',
        },
        {
          root: true,
        },
      );
    }
  },
  async createLevel({ dispatch, state }) {
    try {
      const { data: CreateActivityData } = await http.POST(
        '/api/storygame/activity',
        {
          ...defaultActivity,
        },
        {
          isAuthorization: true,
        },
      );

      const { data: CreateLevelData } = await http.POST(
        '/api/storygame',
        {
          img_uri: '',
          name: 'New Level',
          activities: [CreateActivityData.id],
        },
        {
          isAuthorization: true,
        },
      );

      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'success',
          message: 'Create Level Success',
        },
        {
          root: true,
        },
      );
      dispatch('fetchStoryGame');
    } catch (error) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'error',
          message: 'Create Level Failed',
        },
        {
          root: true,
        },
      );
    }
  },
  orderLevel({ state, dispatch }, { currentIndex, newIndex }) {
    const unitList = state.storygameList;
    const movedItem = unitList.splice(currentIndex, 1)[0];
    unitList.splice(newIndex, 0, movedItem);

    Object.keys(unitList).forEach(index => {
      httpClient
        .PUT(
          '/api/storygame/units',
          {
            id: unitList[index]._id,
            index: parseInt(index),
          },
          {
            isAuthorization: true,
          },
        )
        .then(() => {})
        .catch(error => {
          throw new Error(error);
        })
        .finally(() => {
          if (index == unitList.length - 1) {
            dispatch('fetchStoryGame');
          }
        });
    });
  },
  orderActivity({ state, dispatch }, { currentIndex, newIndex }) {
    const currentActivityOrder = state.selectedStoryGame.activities.map(
      activity => activity._id,
    );
    const temp = currentActivityOrder[newIndex];
    currentActivityOrder[newIndex] = currentActivityOrder[currentIndex];
    currentActivityOrder[currentIndex] = temp;
    httpClient
      .PUT(
        '/api/storygame/level',
        {
          id: state.selectedStoryGame._id,
          activities: currentActivityOrder,
        },
        {
          isAuthorization: true,
        },
      )
      .then(() => {
        dispatch('fetchSingleStoryGame', state.selectedStoryGame._id);
      })
      .catch(() => {
        dispatch('fetchSingleStoryGame', state.selectedStoryGame._id);
      });
  },
  clearUpdateActivityData({ commit }) {
    commit('clearStoreUpdateActivity');
  },
  clearUpdateLevelData({ commit }) {
    commit('clearStoreUpdateLevel');
  },
  fetchAllGameType({ commit }) {
    let gameType = {};
    let constants = [...Constants.GAME_TYPE];
    constants.shift();
    constants.forEach(async game => {
      const { data } = await http.GET(
        `/api/game/category?game_type=${game.value}`,
        {
          isAuthorization: true,
        },
      );
      gameType[game.value] = data.map(gameData => {
        return {
          ...gameData,
          text: gameData.category,
          value: gameData.category_id,
        };
      });
    });
    commit('storeGameType', gameType);
  },
};
const mutations = {
  storeMinichatVideoURIs(state, value) {
    if (value) {
      state.minichatVideoURIs = value;
    } else {
      delete state.minichatVideoURIs;
    }
  },
  clearMinichatVideoURIs(state) {
    state.minichatVideoURIs = [];
  },
  storeStoryGame(state, storyList = []) {
    if (storyList.length > 0) {
      state.storygameList = storyList.map(lp => ({
        ...lp,
        created_at: moment
          .unix(new Date(lp.created_at) * 0.001)
          .format('MMMM D YYYY h:mm a'),
        updated_at: moment
          .unix(new Date(lp.updated_at) * 0.001)
          .format('MMMM D YYYY h:mm a'),
      }));
    } else {
      delete state.storygameList;
    }
  },
  async storeSelectedStoryGamebyId(state, id) {
    try {
      const index = state.storygameList.findIndex(lp => lp._id === id);
      const storygame = state.storygameList[index];
      state.selectedStoryGame = { ...storygame };
    } catch (error) {}
  },
  storeUpdateLevel(state, { key, value }) {
    state.updateLevel = {
      ...state.updateLevel,
      [key]: value,
    };
  },
  clearSelectStoryGame(state) {
    state.selectedStoryGame = {};
    state.storygameList = [];
  },
  storeAcitivityData(state, { key, value }) {
    state.updateActivity = {
      ...state.updateActivity,
      [key]: value,
    };
  },
  storeToggleLoadStoryGame(state) {
    state.isLoadStoryGame = !state.isLoadStoryGame;
  },
  storeCreateActivity(state, { key, value }) {
    if (value) {
      state.createActivity[key] = value;
    } else {
      delete state.createActivity[key];
    }
  },
  storeLevelData(state, { key, value }) {
    if (value) {
      state.updateLevel[key] = value;
    } else {
      delete state.updateLevel[key];
    }
  },
  storeGameType(state, gameType) {
    state.gameType = gameType;
  },
  clearStoreUpdateLevel(state) {
    state.updateLevel = {};
  },
  clearStoreUpdateActivity(state) {
    var id = state.updateActivity.id;
    state.updateActivity = {};
    state.updateActivity.id = id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
