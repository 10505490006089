export default {
  theme: { CITY: 0, JUNGLE: 1, SPACE: 2 },
  character: { MAXI: 0, NANA: 1, NODDLE: 2 },
  themeDropdown: [
    { name: 'CITY', value: 0 },
    { name: 'JUNGLE', value: 1 },
    { name: 'SPACE', value: 2 },
  ],
  characterDropdown: [
    { name: 'MAXI', value: 0 },
    { name: 'NANA', value: 1 },
    { name: 'NODDLE', value: 2 },
  ],

  // { MAXI: 0,JUNGLE:1, SPACE:0},
  // { name: 'NANA', value: 1 },
  // { name: 'NODDLE', value: 2 },
};
