<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light @click="$router.go(-1)">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (minichatStore.minichatData && minichatStore.minichatData.name) ||
              'Loading...'
            }}
            <v-btn icon color="green" @click="onClickAddNode">
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="nodeStore">
          <v-layout row grid-list-md>
            <v-flex xs6 mr-2>
              <v-card>
                <v-list>
                  <v-list-tile
                    v-for="(node, index) in nodeStore"
                    :key="node.node_id"
                    @click="onSelectNode(node.node_id, index)"
                    :class="index === selectedNodeIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-avatar>
                      <v-icon
                        v-if="
                          node.isSelected &&
                          !(node.node_name === selectedStartNode)
                        "
                        style="color: #4cae50"
                        >info</v-icon
                      >
                      <v-icon
                        v-else-if="node.node_name === selectedStartNode"
                        style="color: gold"
                        >star</v-icon
                      >
                      <v-icon v-else>info</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{
                        node.node_name
                      }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              xs6
              mr-2
              v-if="!isEmpty(nodeDataStore) && selectedNodeIndex !== -1"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Node Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="primary"
                    @click="duplicateSelectedNode(node)"
                  >
                    <v-icon>content_copy</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" @click="onSaveEdit">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="red"
                    @click="onClickDelete(node._id, node.minichat_id)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <div>
                  <v-card-text>
                    <v-layout column>
                      <!-- Type List: Show All But Disabled for Update -->
                      <v-flex>
                        <v-select
                          disabled
                          required
                          label="Type"
                          v-model="node.type"
                          :rules="typeRules"
                          :items="constant.NODE_TYPE_LIST"
                        ></v-select>
                      </v-flex>

                      <div v-if="node.type === constant.NODE_TYPE.QUESTION">
                        <UpdateQuestionNode
                          :node="node"
                          @resetIndex="resetIndex"
                        >
                        </UpdateQuestionNode>
                      </div>

                      <div v-else-if="node.type === constant.NODE_TYPE.ANSWER">
                        <UpdateAnswerNode :node="node"> </UpdateAnswerNode>
                      </div>

                      <div
                        v-else-if="node.type === constant.NODE_TYPE.ANIMATION"
                      >
                        <UpdateAnimationNode
                          :node="node"
                          @resetIndex="resetIndex"
                        >
                        </UpdateAnimationNode>
                      </div>

                      <div v-else-if="node.type === constant.NODE_TYPE.IMAGE">
                        <UpdateImageNode :node="node" @resetIndex="resetIndex">
                        </UpdateImageNode>
                      </div>

                      <div v-else-if="node.type === constant.NODE_TYPE.VIDEO">
                        <UpdateVideoNode :node="node"> </UpdateVideoNode>
                      </div>

                      <div
                        v-else-if="
                          node.type === constant.NODE_TYPE.QUESTION_POPUP
                        "
                      >
                        <UpdateQuestionPopupNode :node="node">
                        </UpdateQuestionPopupNode>
                      </div>

                      <div
                        v-else-if="node.type === constant.NODE_TYPE.IMAGE_POPUP"
                      >
                        <UpdateImagePopupNode :node="node">
                        </UpdateImagePopupNode>
                      </div>

                      <div
                        v-else-if="node.type === constant.NODE_TYPE.INTERACTION"
                      >
                        <UpdateInteractionNode :node="node">
                        </UpdateInteractionNode>
                      </div>

                      <div v-else-if="node.type === constant.NODE_TYPE.ITEM">
                        <UpdateItemNode :node="node"> </UpdateItemNode>
                      </div>

                      <div v-else-if="node.type === constant.NODE_TYPE.CARD">
                        <UpdateCardNode :node="node"> </UpdateCardNode>
                      </div>

                      <div v-else-if="node.type === constant.NODE_TYPE.SOUND">
                        <UpdateSoundNode :node="node"> </UpdateSoundNode>
                      </div>
                    </v-layout>
                  </v-card-text>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <Modal
        v-if="duplicateNode.create.open"
        :isOpen="duplicateNode.create.open"
        :save="onSaveCreateDuplicateNode"
        :close="closeCreateDuplicateNode"
        :isLastStep="duplicateNode.create.valid"
        title="Create Duplicate Node"
      >
        <v-form ref="form" v-model="duplicateNode.create.valid">
          <v-flex>
            <v-text-field
              readonly
              disabled
              v-model.trim="node.node_name"
              label="Selected Node"
            ></v-text-field>
            <v-text-field
              required
              :rules="duplicateNode.requiredRules"
              :error-messages="duplicateNode.errorMessages"
              v-model.trim="duplicateNode.create.model.node_name"
              label="Please enter new node name"
            ></v-text-field>
          </v-flex>
        </v-form>
      </Modal>
      <!-- Create Node of MiniChat -->
      <Modal
        v-if="isCreate"
        :isOpen="isCreate"
        :save="onSaveCreate"
        :close="closeCreate"
        :isLastStep="valid"
        title="Create Node of Mini Chat"
      >
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-container>
              <v-layout grid-list-xs column text-xs-center>
                <!-- Type Create Condition -->
                <v-flex>
                  <v-select
                    v-if="!skipLegacyProcessStatus"
                    :items="constant.TYPE_FOR_SKIP_LEGACY_PROCESS_IS_FALSE"
                    label="Type"
                    :value="node.type"
                    v-model="node.type"
                    @change="e => onSetDefault('type', e)"
                    required
                    :rules="typeRules"
                  ></v-select>
                  <v-select
                    v-else-if="skipLegacyProcessStatus === true"
                    :items="constant.TYPE_FOR_SKIP_LEGACY_PROCESS_IS_TRUE"
                    label="Type"
                    :value="node.type"
                    v-model="node.type"
                    @change="e => onSetDefault('type', e)"
                    required
                    :rules="typeRules"
                  ></v-select>
                  <v-select
                    v-else
                    :items="constant.TYPE"
                    label="Type"
                    :value="node.type"
                    v-model="node.type"
                    @change="e => onSetDefault('type', e)"
                    required
                    :rules="typeRules"
                  ></v-select>
                </v-flex>
                <div v-if="node.type === constant.NODE_TYPE.QUESTION">
                  <CreateQuestionNode
                    v-if="node.type === constant.NODE_TYPE.QUESTION"
                    :node="node"
                  >
                  </CreateQuestionNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.ANSWER">
                  <CreateAnswerNode
                    v-if="node.type === constant.NODE_TYPE.ANSWER"
                    :node="node"
                  >
                  </CreateAnswerNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.ANIMATION">
                  <CreateAnimationNode
                    v-if="node.type === constant.NODE_TYPE.ANIMATION"
                    :node="node"
                  >
                  </CreateAnimationNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.IMAGE">
                  <CreateImageNode
                    v-if="node.type === constant.NODE_TYPE.IMAGE"
                    :node="node"
                  >
                  </CreateImageNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.VIDEO">
                  <CreateVideoNode
                    v-if="node.type === constant.NODE_TYPE.VIDEO"
                    :node="node"
                  >
                  </CreateVideoNode>
                </div>
                <div
                  v-else-if="node.type === constant.NODE_TYPE.QUESTION_POPUP"
                >
                  <CreateQuestionPopupNode
                    v-if="node.type === constant.NODE_TYPE.QUESTION_POPUP"
                    :node="node"
                  >
                  </CreateQuestionPopupNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.IMAGE_POPUP">
                  <CreateImagePopupNode
                    v-if="node.type === constant.NODE_TYPE.IMAGE_POPUP"
                    :node="node"
                  >
                  </CreateImagePopupNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.INTERACTION">
                  <CreateInteractionNode
                    v-if="node.type === constant.NODE_TYPE.INTERACTION"
                    :node="node"
                  >
                  </CreateInteractionNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.ITEM">
                  <CreateItemNode
                    v-if="node.type === constant.NODE_TYPE.ITEM"
                    :node="node"
                  >
                  </CreateItemNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.CARD">
                  <CreateCardNode
                    v-if="node.type === constant.NODE_TYPE.CARD"
                    :node="node"
                  >
                  </CreateCardNode>
                </div>
                <div v-else-if="node.type === constant.NODE_TYPE.SOUND">
                  <CreateSoundNode
                    v-if="node.type === constant.NODE_TYPE.SOUND"
                    :node="node"
                  >
                  </CreateSoundNode>
                </div>
              </v-layout>
            </v-container>
          </v-card>
        </v-form>
      </Modal>
    </v-flex>
  </Container>
</template>
<style>
/* .active {
  background-color: gainsboro;
}
.inactive {
  background-color: white;
} */
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
<script>
// This component is intended to be node of the minichat (the smallest unit).
import UpdateQuestionNode from './components/update/node_type/question';
import UpdateAnswerNode from './components/update/node_type/answer';
import UpdateAnimationNode from './components/update/node_type/animation';
import UpdateImageNode from './components/update/node_type/image';
import UpdateVideoNode from './components/update/node_type/video';
import UpdateQuestionPopupNode from './components/update/node_type/question_popup';
import UpdateImagePopupNode from './components/update/node_type/image_popup';
import UpdateInteractionNode from './components/update/node_type/interaction';
import UpdateItemNode from './components/update/node_type/item';
import UpdateCardNode from './components/update/node_type/card';
import UpdateSoundNode from './components/update/node_type/sound';

import CreateQuestionNode from './components/create/node_type/question';
import CreateAnswerNode from './components/create/node_type/answer';
import CreateAnimationNode from './components/create/node_type/animation';
import CreateImageNode from './components/create/node_type/image';
import CreateVideoNode from './components/create/node_type/video';
import CreateQuestionPopupNode from './components/create/node_type/question_popup';
import CreateImagePopupNode from './components/create/node_type/image_popup';
import CreateInteractionNode from './components/create/node_type/interaction';
import CreateItemNode from './components/create/node_type/item';
import CreateCardNode from './components/create/node_type/card';
import CreateSoundNode from './components/create/node_type/sound';

import Container from '../../components/Container';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import constant from '../MiniChat/constant';
import Swal from 'sweetalert2';

export default {
  name: 'MiniChat',
  components: {
    Container,
    Modal,
    UpdateQuestionNode,
    UpdateAnswerNode,
    UpdateAnimationNode,
    UpdateImageNode,
    UpdateVideoNode,
    UpdateQuestionPopupNode,
    UpdateImagePopupNode,
    UpdateInteractionNode,
    UpdateItemNode,
    UpdateCardNode,
    UpdateSoundNode,
    CreateQuestionNode,
    CreateAnswerNode,
    CreateAnimationNode,
    CreateImageNode,
    CreateVideoNode,
    CreateQuestionPopupNode,
    CreateImagePopupNode,
    CreateInteractionNode,
    CreateItemNode,
    CreateCardNode,
    CreateSoundNode,
  },
  computed: {
    ...mapState({
      minichatStore: state => state.minichat,
      nodeStore: state => state.minichat.nodeList,
      nodeDataStore: state => state.minichat.nodeData,
      gotoNodeStore: state => state.minichat.gotoNodeData,
      selectedStartNode: state => state.minichat.minichatData.start_node,
      skipLegacyProcessStatus: state =>
        state.minichat.minichatData.skip_legacy_process,
    }),
  },
  data() {
    return {
      duplicateNode: {
        create: { open: false, valid: false, model: null },
        requiredRules: [v => !!v || 'This field is required'],
        errorMessages: '',
      },
      selectedNodeIndex: -1,
      valid: false,
      typeRules: [v => !!(v > -1) || 'Type is required'],
      constant,
      node: {},
      errorMessages: '',
      isCreate: false,
    };
  },
  watch: {
    nodeStore() {
      return (this.node = this.nodeStore);
    },
    nodeDataStore() {
      return (this.node = this.nodeDataStore);
    },
  },
  methods: {
    ...mapActions('minichat', [
      'addNode',
      'updateNode',
      'updateNodeData',
      'deleteNode',
      'fetchNodes',
      'fetchNodeList',
      'fetchMinichatData',
      'clearNodeStateCreation',
      'clearImageStateCreation',
      'clearUpdateNodeState',
      'clearNodeDataState',
    ]),
    onSaveCreateDuplicateNode() {
      const nodeData = this.duplicateNode.create.model;
      const minichatId = this.minichatStore.minichatData._id;
      const alertConfirm = () => {
        Swal.fire({
          titleText: 'Confirm Create Duplicate Node',
          html: `You are about to duplicate this node as a new name <br /><br /> <h2><b>"${nodeData.node_name}"</b></h2>`,
          confirmButtonColor: 'primary',
          confirmButtonText: 'Create',
          showCancelButton: true,
          type: 'warning',
        }).then(async result => {
          if (result.value) {
            const { _id, created_at, updated_at, ...data } = nodeData;
            await this.addNode(data);
            this.fetchNodeList(minichatId);
            this.clearNodeDataState();
            this.closeCreateDuplicateNode();
          }
        });
      };
      alertConfirm();
    },
    closeCreateDuplicateNode() {
      this.duplicateNode.create.open = false;
    },
    duplicateSelectedNode(node) {
      this.duplicateNode.create.open = true;
      this.duplicateNode.create.model = node;
      const duplicatedObject = Object.assign({}, node);
      this.duplicateNode.create.model = duplicatedObject;
      this.duplicateNode.create.model.node_name = null;
    },
    resetIndex() {
      this.selectedNodeIndex = -1;
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    async onSelectNode(id, index) {
      if (this.selectedNodeIndex !== index) {
        this.selectedNodeIndex = index;
        this.clearUpdateNodeState();
        this.fetchNodes(id);
        this.updateNodeData({
          key: '_id',
          value: id,
        });
      }
    },
    onClickDelete(node_id, minichat_id) {
      this.deleteNode({ node_id, minichat_id });
    },
    onClickAddNode() {
      this.clearNodeStateCreation();
      this.clearImageStateCreation();
      this.isCreate = true;
      this.node = { ...this.minichatStore.addNodeData };
      this.node.minichat_id = this.minichatStore.selectMiniChat._id;
    },
    onSetDefault(key, value) {
      if (key === 'type') {
        if (
          value === constant.NODE_TYPE.QUESTION ||
          value === constant.NODE_TYPE.ANSWER
        ) {
          this.node.hide_correct_star = false;
        } else if (value === constant.NODE_TYPE.VIDEO) {
          this.node.after_video_action = 1;
          this.node.video_position = 1;
          this.node.close_video_in_the_end = true;
          this.node.wait_video_end = true;
        } else if (value === constant.NODE_TYPE.IMAGE_POPUP) {
          this.node.image_uri = [];
        } else if (value === constant.NODE_TYPE.ITEM) {
          this.node.hide_rope = false;
        } else if (value === constant.NODE_TYPE.SOUND) {
          this.node.wait_sound_end = true;
        }
      }
    },
    async onSaveEdit() {
      const updated = this.node;
      const minichat_id = this.nodeDataStore.minichat_id;
      await this.updateNode({ ...updated });
      this.clearNodeDataState();
      this.fetchNodeList(minichat_id);
    },
    async onSaveCreate() {
      const minichatId = this.minichatStore.minichatData._id;
      const nodeData = {
        ...this.node,
        minichat_id: minichatId,
      };
      await this.addNode(nodeData);
      this.fetchNodeList(minichatId);
      this.clearNodeDataState();
      this.isCreate = false;
    },
    closeCreate() {
      this.isCreate = false;
      this.node = this.nodeDataStore;
      this.clearNodeStateCreation();
    },
  },
  async mounted() {
    this.clearNodeDataState();
    if (Object.keys(this.minichatStore.nodeList).length < 1) {
      await this.fetchMinichatData(this.$route.query.id);
      await this.fetchNodeList(this.$route.query.id);
    }
  },
};
</script>
