<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid && validChoiceInfo"
      :title="isCreate ? 'Create Quiz Game' : 'Update Quiz Game'"
    >
      <v-card>
        <v-container>
          <v-form
            ref="formUpdate"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-autocomplete
                  v-if="isCreate"
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="data.category_id"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('category_id', e)"
                ></v-autocomplete>
                <v-text-field
                  label="Title"
                  :value="data.title"
                  v-model="data.title"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('title', e)"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <ChoiceInfo
                  @validate="validChoiceInfo = $event"
                  :isCreate="isCreate"
                >
                </ChoiceInfo>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';
import ResourceSelect from '../../../../components/ResourceSelect';
import constants from './constants';
import ChoiceInfo from './ChoiceInfo.vue';

export default {
  components: {
    Modal,
    ResourceSelect,
    ChoiceInfo,
  },
  data() {
    return {
      constants,
      valid: true,
      validChoiceInfo: false,
      rules,
      data: {},
      resource: {
        image: {
          isEdit: false,
          selectUri: '',
        },
        // sound: {
        //   isEdit: false,
        //   selectUri: '',
        // },
      },
      resourceType: '',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      QuizGameStore: state => state.quiz_game,
      selectedQuizGame: state => state.quiz_game.selectedQuizGame,
      QuizGameList() {
        const [data] = this.QuizGameStore.quizGameList;
        return data;
      },
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('quiz_game', [
      'createQuizGame',
      'updateQuizGame',
      'setStateByKey',
      'clearStateByKey',
    ]),
    save() {
      if (this.$refs.formUpdate.validate()) {
        this.isCreate ? this.createQuizGame() : this.updateQuizGame();
        this.close();
      }
    },
    close() {
      this.isCreate
        ? this.clearStateByKey('createQuizGame')
        : this.clearStateByKey('updateQuizGame');
      this.$emit('close');
    },
    setResource(resource, isType) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isQuestionImage) {
            this.data.question_url = uri;
            this.onchangeUpdate('question_url', uri);
          }
          break;
        case 'sound':
          break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
    onchangeUpdate(key, value) {
      this.setStateByKey({
        statekey: this.isCreate ? 'createQuizGame' : 'updateQuizGame',
        key: key,
        value: value,
      });
    },
    async fetchCategories() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
    },
    set_category_id() {
      if (this.isCreate && 'category_id' in this.QuizGameList) {
        this.data.category_id = this.QuizGameList.category_id;
        this.onchangeUpdate('category_id', this.QuizGameList.category_id);
      }
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.isCreate) this.fetchCategories();
        this.data = this.isCreate
          ? JSON.parse(JSON.stringify({ ...this.QuizGameStore.defaultCreate }))
          : JSON.parse(JSON.stringify({ ...this.selectedQuizGame }));
        this.set_category_id();
      }
    },
  },
};
</script>

<style></style>
