<template>
  <div>
    <Modal
      :isOpen="isOpen"
      :close="closeModal"
      :save="saveModal"
      title="Content Manager"
    >
      <div>
        <v-flex xs12 sm12 md12 style="text-align: right">
          <v-btn
            color="success"
            small
            fab
            @click="increaseStep(name_list.content_manager_list)"
            ><v-icon>add</v-icon>
          </v-btn>
        </v-flex>
        <v-stepper v-model="element.content_manager_list">
          <v-stepper-header>
            <template v-for="n in step.content_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.content_manager_list > n"
                :step="n"
                editable
              >
                <!-- {{ n }} -->
              </v-stepper-step>

              <v-divider
                v-if="n !== step.content_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.content_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-subheader>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="reduceStep(step, name_list.content_manager_list)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-subheader>
              <div style="text-align: -webkit-center">
                <v-img
                  v-if="images[step - 1]"
                  :src="images[step - 1]"
                  class="content_img"
                />
                <v-text-field
                  label="Image URI (URL or Select file)"
                  v-model="images[step - 1]"
                ></v-text-field>
                <v-btn dark @click="isSelectImage = true">
                  <v-icon>audiotrack</v-icon>Select Image
                </v-btn>
                <SpriteSheet
                  :title="'Use Container Sprite Sheet'"
                  :isToggle="choice.is_container_sprite_sheet_data[step - 1]"
                  :fieldname="'Edit Container Sheet Data'"
                  :object="choice.container_sprite_sheet_data[step - 1]"
                  :index="step - 1"
                  @onchangeToggleSprite="
                    $emit(
                      'onchangeToggleSprite',
                      'is_container_sprite_sheet_data',
                      'container_sprite_sheet_data',
                      $event,
                    )
                  "
                  @saveSpriteSheetData="
                    $emit(
                      'saveSpriteSheetData',
                      'container_sprite_sheet_data',
                      $event,
                    )
                  "
                />
              </div>
              <div
                v-if="
                  choice.is_tts_message &&
                  choice.tts_message &&
                  choice.voice_name
                "
                style="text-align: -webkit-center"
              >
                <v-checkbox
                  label="TTS Message"
                  v-model="choice.is_tts_message[step - 1]"
                ></v-checkbox>
                <div v-if="choice.is_tts_message[step - 1]">
                  <v-text-field
                    label="TTS Message"
                    v-model="choice.tts_message[step - 1]"
                  ></v-text-field>
                  <v-select
                    :items="constants.VOICE_NAME"
                    label="Voice Name"
                    :value="choice.voice_name[step - 1]"
                    v-model="choice.voice_name[step - 1]"
                  ></v-select>
                </div>
                <div v-else>
                  <v-text-field
                    label="Sound URI (URL or Select file)"
                    v-model="sounds[step - 1]"
                  ></v-text-field>
                  <v-btn dark @click="isSelectSound = true">
                    <v-icon>audiotrack</v-icon>Select Sound
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>
            <v-btn
              color="primary"
              @click="
                nextStep(
                  element.content_manager_list,
                  name_list.content_manager_list,
                )
              "
            >
              next
            </v-btn>
          </v-stepper-items>
        </v-stepper>
        <ResourceSelect
          v-if="isSelectImage"
          resourceType="image"
          :isShow="isSelectImage"
          :close="closeSelectImage"
          :save="saveSelectImage"
          :value="selectImageUri"
          :onSelect="onSelectImage"
        />
        <ResourceSelect
          v-if="isSelectSound"
          resourceType="sound"
          :isShow="isSelectSound"
          :close="closeSelectSound"
          :save="saveSelectSound"
          :value="selectSoundUri"
          :onSelect="onSelectSound"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../components/Modal.vue';
import ResourceSelect from '../components/ResourceSelect.vue';
import SpriteSheet from '../components/SpriteSheetNew.vue';
import constants from '../constants';

export default {
  name: 'ContentManager',
  components: {
    ResourceSelect,
    Modal,
    SpriteSheet,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: () => false,
    },
    images: {
      type: Array,
      default: () => [],
    },
    sounds: {
      type: Array,
      default: () => [],
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    saveModal: {
      type: Function,
      default: () => {},
    },
    choice: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      constants,
      modalWatcher: null,
      isSelectSound: false,
      selectSoundUri: '',
      isSelectImage: false,
      selectImageUri: '',
      name_list: {
        content_manager_list: 'content_manager_list',
      },
      element: {
        content_manager_list: 1,
      },
      step: {
        content_manager_list: 1,
      },
      keyUpdate: [
        'images',
        'sounds',
        'is_container_sprite_sheet_data',
        'container_sprite_sheet_data',
        'tts_message',
        'is_tts_message',
        'voice_name',
      ],
    };
  },
  methods: {
    intialStepper() {
      this.step.content_manager_list = this.images.length;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    saveSelectImage() {
      const uri = this.selectImageUri;
      const defineArray = () => {
        this.images = [];
      };
      if (!this.images) {
        defineArray();
      }
      const index = this.element.content_manager_list - 1;
      this.images[index] = uri;
      this.selectImageUri = '';
      this.isSelectImage = false;
    },
    saveSelectSound() {
      const uri = this.selectSoundUri;
      const defineArray = () => {
        this.sounds = [];
      };
      if (!this.sounds) {
        defineArray();
      }
      const index = this.element.content_manager_list - 1;
      this.sounds[index] = uri;
      this.selectSoundUri = '';
      this.isSelectSound = false;
    },
    closeSelectImage() {},
    increaseStep(list) {
      const max = 15;
      if (this.step[list] < max) {
        this.step[list] += 1;
        this.element.content_manager_list = this.step[list];
        this.addData();
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.content_manager_list) {
          this.step[list] -= 1;
          this.element.content_manager_list = this.step[list];
          this.removeData(index);
        }
      }
    },
    removeData(index) {
      this.keyUpdate.forEach(key => {
        switch (key) {
          case 'images':
            this[key].splice(index, 1);
            break;
          case 'sounds':
            this[key].splice(index, 1);
            break;
          default:
            this.choice[key].splice(index, 1);
            break;
        }
      });
    },
    addData() {
      this.keyUpdate.forEach(key => {
        switch (key) {
          case 'images':
            this[key].push('');
            break;
          case 'sounds':
            this[key].push('');
            break;
          default:
            this.choice[key].push(
              key === 'is_container_sprite_sheet_data' ||
                key === 'is_tts_message'
                ? false
                : key === 'container_sprite_sheet_data'
                ? {
                    column: 1,
                    row: 1,
                    frame_ms: 0,
                    loop_count: -1,
                  }
                : '',
            );
            break;
        }
      });
    },
  },
  created() {
    this.modalWatcher = this.$watch('isOpen', value => {
      if (value) {
        this.intialStepper();
      } else {
        this.modalWatcher();
      }
    });
  },
  beforeDestroy() {
    this.modalWatcher();
  },
};
</script>

<style></style>
