import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  matchcard: [],
  selectMatchCard: {},
  selectedDeckIndex: -1,
  selectedCategoryId: '',
  updateCardData: {},
  updateDeckData: {},
  isUpdating: false,
  cardData: {
    text: '',
    match_key: 0,
    type: '',
  },
  addCardData: [
    {
      text: '',
      match_key: 0,
      type: '',
    },
    {
      text: '',
      match_key: 0,
      type: '',
    },
    {
      text: '',
      match_key: 0,
      type: '',
    },
    {
      text: '',
      match_key: 0,
      type: '',
    },
  ],
  addDeckData: {
    name: '',
    is_close_deck: false,
  },
};
const getters = {};
const actions = {
  fetchMatchCard({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/matchcard?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(wordWalk => ({
          ...wordWalk,
          created_at: moment(wordWalk.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(wordWalk.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeMatchCard', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch matchcard in this time please type again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectMatchCard({ commit }, id) {
    commit('storeSelectMatchCard', { id, sub_id: '' });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleMatchCard({ commit, dispatch }, { id, sub_id }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/matchcard?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(matchcard => ({
          ...matchcard,
          created_at: moment(matchcard.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(matchcard.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeMatchCard', formatData);
        commit('storeSelectMatchCard', { id, sub_id });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch matchcard at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  updateCardData({ commit }, { key, value }) {
    commit('storeUpdateCardData', {
      key,
      value,
    });
  },
  updateDeckData({ commit }, { key, value }) {
    commit('storeUpdateDeckData', {
      key,
      value,
    });
  },
  updateCard({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/choice',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Card Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleMatchCard', {
            id: state.selectMatchCard.category_id,
          });
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update card. Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdateData');
  },

  deleteDeck({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Deck',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/matchcard/deck?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleMatchCard', {
                id: state.selectMatchCard.category_id,
              });
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  deleteCard({ dispatch, state }, id) {
    http
      .DELETE(`/api/choice?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete Successfully',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchSingleMatchCard', {
          id: state.selectMatchCard.category_id,
        });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete please try again later !',
            type: 'error',
          },
          { root: true },
        );
      });
  },
  updateCreateCardData({ commit }, { key, value, index }) {
    commit('storeCreateCardData', {
      key,
      value,
      index,
    });
  },
  updateCreateSingleCardData({ commit }, { key, value }) {
    commit('storeCreateSingleCardData', {
      key,
      value,
    });
  },
  updateDeck({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT('/api/matchcard/deck', updateData, {
          isAuthorization: true,
        })
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Deck success',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleMatchCard', {
            id: state.selectMatchCard.category_id,
          });
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Deck failed please try again later',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  updateCreateDeckData({ commit }, { key, value }) {
    commit('storeCreateDeckData', {
      key,
      value,
    });
  },
  addDeck({ dispatch }, { category_id, ...data }) {
    const createDeckData = {
      category_id,
      deck: {
        ...data.deck,
      },
      choice: [...data.cards],
    };
    http
      .POST('/api/matchcard', createDeckData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Deck success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleMatchCard', { id: category_id });
        router.go({
          path: '/matchcard/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Deck failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
      });
  },
  addCardByDeckId({ dispatch }, { category_id, deck_id, ...data }) {
    const createCardData = {
      _id: deck_id,
      choice: { ...data.card },
    };
    http
      .POST('/api/matchcard/deck', createCardData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Card success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleMatchCard', { id: category_id });
        router.go({
          path: '/matchcard/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Card failed',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeMatchCard(state, matchcardList) {
    state.matchcard = matchcardList;
  },
  storeSelectMatchCard(state, { id, sub_id }) {
    if (id) {
      const index = state.matchcard.findIndex(
        matchcard => matchcard.category_id === id,
      );
      state.selectMatchCard = state.matchcard[index];
      if (sub_id) {
        const deckIndex = state.selectMatchCard.decks.findIndex(
          deck => deck._id === sub_id,
        );
        state.selectedDeckIndex = deckIndex;
      }
    } else {
      delete state.selectMatchCard;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateCardData(state, { key, value }) {
    state.updateCardData = {
      ...state.updateCardData,
      [key]: value,
    };
  },
  storeCreateCardData(state, { key, value, index }) {
    state.addCardData[index] = {
      ...state.addCardData[index],
      [key]: value,
    };
  },
  storeCreateSingleCardData(state, { key, value }) {
    state.addSingleCardData = {
      ...state.addSingleCardData,
      [key]: value,
    };
  },
  storeUpdateDeckData(state, { key, value }) {
    state.updateDeckData = {
      ...state.updateDeckData,
      [key]: value,
    };
  },
  storeCreateDeckData(state, { key, value }) {
    state.addDeckData = {
      ...state.addDeckData,
      [key]: value,
    };
  },
  clearStoreUpdateData(state) {
    Object.keys(state.updateCardData).forEach(key => {
      if (key != '_id') {
        delete state.updateCardData[key];
      }
    });
    Object.keys(state.updateDeckData).forEach(key => {
      if (key != '_id') {
        delete state.updateDeckData[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
