<template>
  <v-app :dark="enableDarkMode">
    <Toolbar @toggleDarkTheme="toggleDarkTheme" />
    <v-content>
      <v-alert
        :value="appConfig.alert.active"
        :type="appConfig.alert.type"
        transition="slide-y-transition"
        >{{ appConfig.alert.message }}</v-alert
      >
      <router-view />
    </v-content>
    <Sidebar />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Toolbar from './components/Toolbar';
import Sidebar from './components/Sidebar';
export default {
  name: 'App',
  components: {
    Toolbar,
    Sidebar,
  },
  data() {
    return {
      enableDarkMode: true,
    };
  },
  computed: {
    ...mapState({
      appConfig: state => state.appConfig,
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions('appConfig', ['toggleDrawer']),
    toggleDarkTheme() {
      this.enableDarkMode = !this.enableDarkMode;
    },
    autoToggleDrawer() {
      if (this.currentRouteName === 'home') {
        this.toggleDrawer();
      }
    },
  },
  mounted() {
    this.autoToggleDrawer();
  },
};
</script>
