import http from '../../utils/httpClient';
import swal from 'sweetalert2';
import router from '../../router';
import moment from 'moment';

const state = {
  isUpdating: false,
  CREATE: {},
  UPDATE: {},
  GAME_LIST: [],
  GAME_DATA: [],
  SELECTED_GAME_LIST: {},
  defaultData: {
    maze: {},
    item: {
      is_collect_item_to_end: true,
    },
    player: {
      is_idle_sprite_sheet: false,
      idle_sprite_sheet_data: {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      },
      is_move_sprite_sheet: false,
      move_sprite_sheet_data: {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      },
    },
    finish: {
      is_start_sprite_sheet: false,
      start_sprite_sheet_data: {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      },
      is_finish_sprite_sheet: false,
      finish_sprite_sheet_data: {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      },
    },
  },
};
const getters = {
  gameListStore(state) {
    return state.GAME_LIST;
  },
  gameDataStore(state) {
    return state.GAME_DATA;
  },
  selectedGameListStore(state) {
    return state.SELECTED_GAME_LIST;
  },
};
const actions = {
  getGameDataByObjectId({ commit, dispatch }, objectID) {
    ///api/mazegame/gamedatabyid
    http
      .GET(`/api/mazegame/gamedatabyid?_id=${objectID}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_GAME_DATA', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch game data for maze game at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  createGameByListId({ commit, dispatch }, data) {
    const gameData = {
      ...data,
    };
    http
      .POST('/api/mazegame/gamedata', gameData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully created new game data',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('getGameData', gameData.game_list_id);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              error.response.data.message || 'Failed to create new game data',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getCategoryByGameListId({ commit, dispatch }, id) {
    http
      .GET(`/api/mazegame/gamelist?game_list_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_SELECTED_GAME_LIST_STATE', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch category name for slide puzzle at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  createGameData({ commit, dispatch }, { ...data }) {
    const gameData = {
      ...data,
    };
    http
      .POST('/api/mazegame', gameData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully created new game data',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('getGameList');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              error.response.data.message || 'Failed to create new game data',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  // getCategoryByGameListID({ commit, dispatch }, id) {
  //   commit('SET_GAME_LIST', respose);
  // },
  // getGameDataByObjectID({ commit, dispatch }, id) {
  //   commit('SET_GAME_DATA', respose);
  // },
  getGameList({ commit, dispatch }) {
    http
      .GET(`/api/mazegame`, {
        isAuthorization: true,
      })
      .then(response => {
        const formatData = response.data.map(data => ({
          ...data,
          created_at: moment(data.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(data.updated_at).format('YYYY MMM DD h:mm A'),
        }));
        commit('SET_GAME_LIST', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch category for slide puzzle at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getGameData({ commit, dispatch }, id) {
    http
      .GET(`/api/mazegame/gamedata?game_list_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_GAME_DATA', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch game data for Maze Game at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  setSelectedGameListState({ commit }, data) {
    commit('SET_SELECTED_GAME_LIST_STATE', data);
  },
  updateGame({ commit, dispatch }, data) {
    delete data.created_at;
    delete data.updated_at;
    const gameData = {
      ...data,
    };
    http
      .PUT('/api/mazegame/gamedata', gameData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully update maze game',
            type: 'success',
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              error.response.data.message || 'Failed to update maze game',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  deleteGame({ dispatch }, { id }) {
    http
      .DELETE(`/api/mazegame/gamedata?id=${id}`, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete successfully',
            type: 'success',
          },
          { root: true },
        );
        router.go();
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete at this time, please try again later.',
            type: 'error',
          },
          { root: true },
        );
      });
  },
};
const mutations = {
  CREATE_GAME_DATA(state, value) {
    if (value) {
      state.GAME_LIST.push(value);
    }
  },
  SET_GAME_LIST(state, value) {
    if (value) {
      state.GAME_LIST = value;
    } else {
      delete state.GAME_LIST;
    }
  },
  SET_GAME_DATA(state, value) {
    if (value) {
      value.forEach(e => {
        if (Object.keys(e.item).length === 0) {
          e.item = state.defaultData.item;
        }
        if (Object.keys(e.player).length === 0) {
          e.player = state.defaultData.player;
        }
        if (Object.keys(e.finish).length === 0) {
          e.finish = state.defaultData.finish;
        }
      });
      state.GAME_DATA = value;
    } else {
      delete state.GAME_DATA;
    }
  },
  SET_SELECTED_GAME_LIST_STATE(state, value) {
    if (value) {
      state.SELECTED_GAME_LIST = value;
    } else {
      delete state.SELECTED_GAME_LIST;
    }
  },
  SET_CREATE_STATE(state, { key, value, index }) {
    state.CREATE[index] = {
      ...state.CREATE[index],
      [key]: value,
    };
  },
  SET_UPDATE_STATE(state, { value, index }) {
    // state.GAME_DATA[index] = { ...value };
    state.UPDATE[index] = {
      ...state.UPDATE[index],
      [key]: value,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
