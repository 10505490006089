<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/galaxychat')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (galaxyChatStore.selectGalaxyChat &&
                galaxyChatStore.selectGalaxyChat.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="galaxyChatStore.selectGalaxyChat">
          <v-layout row grid-list-md v-if="!galaxyChatStore.isUpdating">
            <v-flex mr-2 xs3>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Galaxy Chat Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      onClickUpdateGalaxyChat(
                        galaxyChatStore.selectGalaxyChat._id,
                      )
                    "
                    color="green"
                  >
                    <v-icon>save</v-icon>Save
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex>
                        <v-checkbox
                          label="Available for Free User"
                          v-model="
                            galaxyChatStore.selectGalaxyChat.isGuestPlayable
                          "
                          :value="
                            galaxyChatStore.selectGalaxyChat.isGuestPlayable
                          "
                          v-on:change="
                            e => onChangeGalaxyChat('isGuestPlayable', e)
                          "
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Title"
                          :value="galaxyChatStore.selectGalaxyChat.title"
                          @change="e => onChangeGalaxyChat('title', e)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Stage"
                          :value="galaxyChatStore.selectGalaxyChat.stage"
                          @change="
                            e => onChangeGalaxyChat('stage', parseInt(e))
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="World"
                          :value="galaxyChatStore.selectGalaxyChat.world"
                          @change="
                            e => onChangeGalaxyChat('world', parseInt(e))
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-card-actions>
                        <v-btn
                          color="red"
                          dark
                          @click="onClickDeleteGalaxyChat"
                        >
                          <v-icon>delete</v-icon>Delete this
                        </v-btn>
                      </v-card-actions>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs2 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Quest</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="onClickAddQuest">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(quest, index) in galaxyChatStore.selectGalaxyChat
                      .quests"
                    :key="quest._id"
                    v-on:click="onSelectQuest(quest._id)"
                    :class="
                      index === selectedQuestIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ quest.name }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedQuestIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Quest Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickUpdateQuest">
                    <v-icon>save</v-icon>Save Quest
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Quest Name"
                        :value="quest.name"
                        v-on:change="e => onChangeQuestData('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Tier"
                        :value="quest.tier"
                        v-on:change="
                          e => onChangeQuestData('tier', parseInt(e))
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Gift"
                        :value="quest.gift"
                        v-on:change="
                          e => onChangeQuestData('gift', parseInt(e))
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-img
                        v-if="quest.image"
                        :src="quest.image"
                        width="300"
                        height="300"
                      ></v-img>
                      <v-text-field
                        label="Image"
                        :value="quest.image"
                        v-model="quest.image"
                        v-on:change="e => onChangeQuestData('image', e)"
                      ></v-text-field>
                      <v-btn v-on:click="isEditImage = true"
                        >Select Image</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-btn
                    color="red"
                    dark
                    v-on:click="onClickDeleteQuest(quest._id)"
                  >
                    <v-icon>delete</v-icon>Delete this quest
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs5 mr-2 v-if="selectedQuestIndex != -1">
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Mission Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickSaveMission">
                    <v-icon>save</v-icon>Save Mission
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex v-if="custom">
                        <v-flex>
                          <v-jsoneditor
                            class="editor"
                            :plus="false"
                            height="600px"
                            :options="editorOption"
                            :value="mission || {}"
                          />
                        </v-flex>
                      </v-flex>
                      <v-flex xs6 v-else>
                        <v-layout column>
                          <v-flex> </v-flex>
                          <!-- OUTPUT -->
                          <v-flex
                            v-for="(output, index) in mission.output"
                            class="elevation-2"
                            :key="output"
                          >
                            <container>
                              <v-layout column>
                                <v-card-actions>
                                  <label
                                    ><b>Output: {{ index + 1 }}:</b></label
                                  >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    v-if="index > 0"
                                    color="red"
                                    dark
                                    @click="onDeleteOutput(index)"
                                  >
                                    <v-icon>delete</v-icon>Delete this output
                                  </v-btn>
                                </v-card-actions>
                                <!-- TEXT DATA - TXTS -->
                                <v-flex>
                                  <label><b>Text Data</b></label>
                                  <v-flex
                                    v-for="(text, textIndex) in output.txtData
                                      .txts"
                                    class="elevation-1"
                                    :key="text"
                                    style="padding: 10px; margin-bottom: 5px"
                                  >
                                    <v-card-actions>
                                      <label>Text {{ textIndex + 1 }}:</label>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        icon
                                        color="red"
                                        v-on:click="
                                          output.txtData.txts.splice(
                                            textIndex,
                                            1,
                                          )
                                        "
                                      >
                                        <v-icon>delete</v-icon>
                                      </v-btn>
                                    </v-card-actions>
                                    <v-flex
                                      v-for="(t, tIndex) in text"
                                      :key="t"
                                    >
                                      <v-text-field
                                        :label="
                                          'Sentence ' + (tIndex + 1) + ':'
                                        "
                                        :value="t"
                                        v-on:change="
                                          e =>
                                            onChangeOutputData('t', e, {
                                              outputIndex: index,
                                              textIndex: textIndex,
                                              tIndex: tIndex,
                                            })
                                        "
                                      />
                                    </v-flex>
                                    <v-btn
                                      icon
                                      color="green"
                                      v-on:click="
                                        output.txtData.txts[textIndex].push([
                                          '',
                                        ])
                                      "
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="text.length > 1"
                                      icon
                                      color="red"
                                      dark
                                      @click="
                                        output.txtData.txts[textIndex].pop()
                                      "
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                  </v-flex>
                                  <v-flex>
                                    <v-btn
                                      color="green"
                                      v-on:click="
                                        onChangeOutputData(
                                          'txts',
                                          galaxyChatStore.txts,
                                          { outputIndex: index },
                                        )
                                      "
                                    >
                                      <v-icon>add</v-icon>Add Text
                                    </v-btn>
                                  </v-flex>
                                </v-flex>
                                <!-- TEXT DATA - OBJECTS -->
                                <v-flex>
                                  <label><b>Text Object</b></label>
                                  <v-flex
                                    v-for="(object, objectIndex) in output
                                      .txtData.txtObjects"
                                    :key="object"
                                    class="elevation-1"
                                    style="padding: 10px; margin-bottom: 5px"
                                  >
                                    <v-card-actions>
                                      <label
                                        >Object {{ objectIndex + 1 }}:</label
                                      >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        icon
                                        color="red"
                                        v-on:click="
                                          output.txtData.txtObjects.splice(
                                            objectIndex,
                                            1,
                                          )
                                        "
                                      >
                                        <v-icon>delete</v-icon>
                                      </v-btn>
                                    </v-card-actions>
                                    <!-- LIBRARY -->
                                    <v-flex
                                      v-for="(
                                        library, libraryIndex
                                      ) in object.libraries"
                                      :key="library"
                                    >
                                      <v-select
                                        :items="galaxyChatStore.libraryList"
                                        :label="
                                          'Library Category ' +
                                          (libraryIndex + 1) +
                                          ':'
                                        "
                                        :value="library"
                                        v-on:change="
                                          e =>
                                            onChangeOutputData('libraries', e, {
                                              outputIndex: index,
                                              objectIndex: objectIndex,
                                              libraryIndex: libraryIndex,
                                            })
                                        "
                                      ></v-select>
                                    </v-flex>
                                    <v-card-actions>
                                      <v-btn
                                        color="green"
                                        v-on:click="
                                          output.txtData.txtObjects[
                                            objectIndex
                                          ].libraries.push('')
                                        "
                                      >
                                        <v-icon>add</v-icon>Add Library
                                      </v-btn>
                                      <v-btn
                                        v-if="object.libraries.length > 1"
                                        color="red"
                                        dark
                                        @click="
                                          output.txtData.txtObjects[
                                            objectIndex
                                          ].libraries.pop()
                                        "
                                      >
                                        Delete Last Library<v-icon
                                          >remove</v-icon
                                        >
                                      </v-btn>
                                    </v-card-actions>
                                    <!-- ARTICLE -->
                                    <v-flex>
                                      <v-checkbox
                                        label="Article"
                                        v-model="object.article"
                                        :value="object.article"
                                        v-on:change="
                                          e =>
                                            onChangeOutputData('article', e, {
                                              outputIndex: index,
                                              objectIndex: objectIndex,
                                            })
                                        "
                                      >
                                      </v-checkbox>
                                    </v-flex>
                                    <!-- OPTIONS -->
                                    <v-flex
                                      v-for="option in object.options"
                                      :key="option"
                                    >
                                      <v-text-field
                                        label="Options"
                                        :value="option"
                                        v-on:change="
                                          e =>
                                            onChangeOutputData(
                                              'options',
                                              parseInt(e),
                                            )
                                        "
                                      />
                                    </v-flex>
                                    <v-card-actions>
                                      <v-btn
                                        color="green"
                                        v-on:click="
                                          output.txtData.txtObjects[
                                            objectIndex
                                          ].options.push(0)
                                        "
                                      >
                                        <v-icon>add</v-icon> Add Option
                                      </v-btn>
                                      <v-btn
                                        v-if="object.options.length > 1"
                                        color="red"
                                        dark
                                        @click="
                                          output.txtData.txtObjects[
                                            objectIndex
                                          ].options.pop()
                                        "
                                      >
                                        Delete Last Option<v-icon
                                          >remove</v-icon
                                        >
                                      </v-btn>
                                    </v-card-actions>
                                  </v-flex>
                                  <v-flex>
                                    <v-btn
                                      color="green"
                                      v-on:click="
                                        onChangeOutputData(
                                          'txtObjects',
                                          galaxyChatStore.txtObjectData,
                                          { outputIndex: index },
                                        )
                                      "
                                    >
                                      <v-icon>add</v-icon>Add Text Object
                                    </v-btn>
                                  </v-flex>
                                </v-flex>
                                <!-- INSIDE TEXT DATA -->
                                <v-flex>
                                  <v-checkbox
                                    label="Show?"
                                    v-model="output.txtData.isShow"
                                    :value="output.txtData.isShow"
                                    v-on:change="
                                      e => onChangeOutputData('isShow', e)
                                    "
                                  >
                                  </v-checkbox>
                                </v-flex>
                                <v-flex>
                                  <v-checkbox
                                    label="Speak?"
                                    v-model="output.txtData.isSpeak"
                                    :value="output.txtData.isSpeak"
                                    v-on:change="
                                      e => onChangeOutputData('isSpeak', e)
                                    "
                                  >
                                  </v-checkbox>
                                </v-flex>
                                <v-flex>
                                  <v-checkbox
                                    label="Hide Image?"
                                    v-model="output.txtData.hideImage"
                                    :value="output.txtData.hideImage"
                                    v-on:change="
                                      e => onChangeOutputData('hideImage', e)
                                    "
                                  >
                                  </v-checkbox>
                                </v-flex>
                                <!-- OUTSIDE TEXT DATA -->
                                <v-flex>
                                  <v-text-field
                                    label="Talker"
                                    :value="output.nTalker"
                                    v-on:change="
                                      e =>
                                        onChangeOutputData(
                                          'nTalker',
                                          parseInt(e),
                                        )
                                    "
                                  />
                                </v-flex>
                              </v-layout>
                            </container>
                          </v-flex>
                          <!-- nTalking -->
                          <v-flex
                            v-for="talking in mission.nTalking"
                            :key="talking"
                          >
                            <v-text-field
                              label="nTalking"
                              :value="talking"
                              v-on:change="
                                e =>
                                  onChangeMissionData('nTalking', parseInt(e))
                              "
                            />
                          </v-flex>
                          <v-card-actions>
                            <v-btn
                              color="green"
                              v-on:click="mission.nTalking.push(0)"
                            >
                              <v-icon>add</v-icon> Add n Talking
                            </v-btn>
                            <v-btn
                              v-if="mission.nTalking.length > 1"
                              color="red"
                              dark
                              @click="mission.nTalking.pop()"
                            >
                              Delete Last n Talking<v-icon>remove</v-icon>
                            </v-btn>
                          </v-card-actions>
                          <!-- nAction -->
                          <v-flex
                            v-for="action in mission.nAction"
                            :key="action"
                          >
                            <v-text-field
                              label="nAction"
                              :value="action"
                              v-on:change="
                                e => onChangeMissionData('nAction', parseInt(e))
                              "
                            />
                          </v-flex>
                          <v-card-actions>
                            <v-btn
                              color="green"
                              v-on:click="mission.nAction.push(0)"
                            >
                              <v-icon>add</v-icon> Add n Action
                            </v-btn>
                            <v-btn
                              v-if="mission.nAction.length > 1"
                              color="red"
                              dark
                              @click="mission.nAction.pop()"
                            >
                              Delete Last n Action<v-icon>remove</v-icon>
                            </v-btn>
                          </v-card-actions>
                          <!-- nActive -->
                          <v-flex>
                            <v-text-field
                              label="nActive"
                              :value="mission.nActive"
                              v-on:change="
                                e => onChangeMissionData('nActive', parseInt(e))
                              "
                            />
                          </v-flex>

                          <!-- INPUT -->
                          <v-flex
                            v-for="(input, index) in mission.input"
                            :key="input"
                            class="elevation-2"
                          >
                            <container>
                              <v-layout column>
                                <v-card-actions>
                                  <label
                                    ><b>Input: {{ index + 1 }}:</b></label
                                  >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="red"
                                    dark
                                    @click="onDeleteInput(index)"
                                  >
                                    <v-icon>delete</v-icon>Delete this input
                                  </v-btn>
                                </v-card-actions>
                                <v-flex>
                                  <v-btn
                                    v-if="!input"
                                    color="green"
                                    dark
                                    v-on:click="
                                      onChangeInputData(
                                        'input',
                                        galaxyChatStore.inputData,
                                        { inputIndex: index },
                                      )
                                    "
                                  >
                                    Add input data
                                  </v-btn>
                                  <v-btn
                                    v-if="input"
                                    color="grey"
                                    dark
                                    v-on:click="
                                      onChangeInputData('input', null, {
                                        inputIndex: index,
                                      })
                                    "
                                  >
                                    Make null
                                  </v-btn>
                                </v-flex>
                                <!-- EXPECT ANSWERS -->
                                <v-flex
                                  v-if="
                                    input &&
                                    input.expectAnswers &&
                                    input.expectAnswers.length > 0
                                  "
                                >
                                  <v-flex
                                    v-for="(
                                      answer, answerIndex
                                    ) in input.expectAnswers"
                                    class="elevation-1"
                                    :key="answer"
                                    style="padding: 10px; margin-bottom: 5px"
                                  >
                                    <label
                                      >Expect Answer
                                      {{ answerIndex + 1 }}:</label
                                    >
                                    <v-flex>
                                      <v-text-field
                                        :label="
                                          'Answer ' + (answerIndex + 1) + ':'
                                        "
                                        :value="answer"
                                        v-on:change="
                                          e =>
                                            onChangeInputData(
                                              'expectAnswers',
                                              e,
                                              {
                                                inputIndex: inputIndex,
                                                answerIndex: answerIndex,
                                              },
                                            )
                                        "
                                      />
                                    </v-flex>
                                  </v-flex>
                                  <v-flex>
                                    <v-btn
                                      icon
                                      color="green"
                                      v-on:click="
                                        input.expectAnswers.push([''])
                                      "
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="input.expectAnswers.length > 1"
                                      icon
                                      color="red"
                                      dark
                                      @click="input.expectAnswers.pop()"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-flex>
                                <!-- EXPECT COMMENTS -->
                                <v-flex
                                  v-if="
                                    input &&
                                    input.expectComments &&
                                    input.expectComments.length > 0
                                  "
                                >
                                  <v-flex
                                    v-for="(
                                      comment, commentIndex
                                    ) in input.expectComments"
                                    :key="comment"
                                    class="elevation-1"
                                    style="padding: 10px; margin-bottom: 5px"
                                  >
                                    <label
                                      >Expect Comment
                                      {{ commentIndex + 1 }}:</label
                                    >
                                    <v-flex>
                                      <v-text-field
                                        :label="
                                          'Expect Comment ' +
                                          (commentIndex + 1) +
                                          ':'
                                        "
                                        :value="comment"
                                        v-on:change="
                                          e =>
                                            onChangeInputData(
                                              'expectComments',
                                              e,
                                              {
                                                inputIndex: inputIndex,
                                                commentIndex: commentIndex,
                                              },
                                            )
                                        "
                                      />
                                    </v-flex>
                                  </v-flex>
                                  <v-flex>
                                    <v-btn
                                      icon
                                      color="green"
                                      v-on:click="
                                        input.expectComments.push([''])
                                      "
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="input.expectComments.length > 1"
                                      icon
                                      color="red"
                                      dark
                                      @click="input.expectComments.pop()"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-flex>
                                <!-- RETRY COMMENT -->
                                <v-flex
                                  v-if="input && input.retryComment.length > 0"
                                >
                                  <v-flex
                                    v-for="(
                                      comment, commentIndex
                                    ) in input.retryComment"
                                    :key="comment"
                                    class="elevation-1"
                                    style="padding: 10px; margin-bottom: 5px"
                                  >
                                    <label
                                      >Retry Comment
                                      {{ commentIndex + 1 }}:</label
                                    >
                                    <v-flex>
                                      <v-text-field
                                        :label="
                                          'Comment ' + (commentIndex + 1) + ':'
                                        "
                                        :value="comment"
                                        v-on:change="
                                          e =>
                                            onChangeInputData(
                                              'retryComment',
                                              e,
                                              {
                                                inputIndex: inputIndex,
                                                commentIndex: commentIndex,
                                              },
                                            )
                                        "
                                      />
                                    </v-flex>
                                  </v-flex>
                                  <v-flex>
                                    <v-btn
                                      icon
                                      color="green"
                                      v-on:click="input.retryComment.push([''])"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="input.retryComment.length > 1"
                                      icon
                                      color="red"
                                      dark
                                      @click="input.retryComment.pop()"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-flex>
                                <!-- IS SPEAK -->
                                <v-flex v-if="input">
                                  <v-checkbox
                                    label="Speak?"
                                    v-model="mission.isSpeak"
                                    :value="mission.isSpeak"
                                    v-on:change="
                                      e => onChangeInputData('isSpeak', e)
                                    "
                                  >
                                  </v-checkbox>
                                </v-flex>
                                <!-- SKIP REPEAT -->
                                <v-flex v-if="input">
                                  <v-checkbox
                                    label="skipRepeat?"
                                    v-model="mission.skipRepeat"
                                    :value="mission.skipRepeat"
                                    v-on:change="
                                      e => onChangeInputData('skipRepeat', e)
                                    "
                                  >
                                  </v-checkbox>
                                </v-flex>
                              </v-layout>
                            </container>
                          </v-flex>

                          <!-- DELAY -->
                          <v-flex
                            v-for="(delay, delayIndex) in mission.delay"
                            :key="delay"
                            class="elevation-2"
                          >
                            <container>
                              <v-layout column>
                                <label>Index {{ delayIndex + 1 }}:</label>
                                <v-flex>
                                  <v-text-field
                                    :label="'Index ' + (delayIndex + 1) + ':'"
                                    :value="delay"
                                    v-on:change="
                                      e => onChangeMissionData('delay', e)
                                    "
                                  />
                                </v-flex>
                                <v-flex>
                                  <v-btn
                                    icon
                                    color="green"
                                    v-on:click="mission.delay.push([''])"
                                  >
                                    <v-icon>add</v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="mission.delay.length > 1"
                                    icon
                                    color="red"
                                    dark
                                    @click="mission.delay.pop()"
                                  >
                                    <v-icon>remove</v-icon>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </container>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      title="Point Data"
      :learnedWords="
        selectedQuestIndex != -1
          ? galaxyChatStore.selectGalaxyChat.quests[selectedQuestIndex]
              .learned_words
          : null
      "
      :incidentalWords="
        selectedQuestIndex != -1
          ? galaxyChatStore.selectGalaxyChat.quests[selectedQuestIndex]
              .incidental_words
          : null
      "
      :rewards="
        selectedQuestIndex != -1
          ? galaxyChatStore.selectGalaxyChat.quests[selectedQuestIndex].reward
          : null
      "
      :sentences="
        selectedQuestIndex != -1
          ? galaxyChatStore.selectGalaxyChat.quests[selectedQuestIndex].sentence
          : null
      "
      :phonics="
        selectedQuestIndex != -1
          ? galaxyChatStore.selectGalaxyChat.quests[selectedQuestIndex].phonic
          : null
      "
    >
    </PointData>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
</style>

<script>
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
import { mapState, mapActions } from 'vuex';
import swal from 'sweetalert2';

export default {
  name: 'GalaxyChat',
  components: {
    Container,
    ResourceSelect,
    PointData,
  },
  computed: {
    ...mapState({
      galaxyChatStore: state => state.galaxychat,
      selectedQuestIndexStore: state => state.galaxychat.selectedQuestIndex,
    }),
  },
  data: function () {
    return {
      mission: {},
      quests: [],
      quest: {},
      selectedQuestIndex: -1,
      text: '',
      isDirty: false,
      selectQuestId: '',
      isEditImage: false,
      selectImageUri: '',

      custom: true,
      editorOption: {
        onChangeText: e => this.onChangeCustomMissionData(e),
        mode: 'code',
      },
      validJSON: true,
      isOpenPointData: false,
    };
  },
  methods: {
    ...mapActions('galaxychat', [
      'fetchGalaxyChat',
      'fetchSingleGalaxyChat',
      'fetchMission',
      'fetchLibrary',
      'updateMissionData',
      'updateMission',
      'clearUpdateData',
      'deleteQuest',
      'deleteMission',
      'deleteGalaxyChat',
      'updateGalaxyChatData',
      'updateQuestData',
      'updateQuest',
      'updateGalaxyChat',
      'addQuest',
      'addMissionByQuestId',
      'selectCategory',
      'selectGalaxyChat',
    ]),
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.updateQuestData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateQuestData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateQuestData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateQuestData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateQuestData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateQuest();
      this.isOpenPointData = false;
    },

    onSelectQuest(id) {
      this.clearUpdateData();
      const quests = this.galaxyChatStore.selectGalaxyChat.quests;
      const index = quests.findIndex(quest => quest._id === id);
      this.selectedQuestIndex = index;
      this.quest = { ...quests[index] };
      const { _id, created_at, updated_at, ...mission } = {
        ...quests[index].mission,
      };
      this.mission = mission;
      this.updateQuestData({
        key: '_id',
        value: id,
      });
      this.updateMissionData({
        key: '_id',
        value: _id,
      });
      this.selectQuestId = id;
      this.validJSON = true;
    },

    onChangeGalaxyChat(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.isDirty = true;
      this.updateGalaxyChatData({
        key,
        value,
      });
    },
    // onChangeMissionData(key, value, { talkingIndex }) {
    //   this.isDirty = true;
    //   if (key == 'nTalking') {
    //     this.mission.nTalking[talkingIndex] = value;
    //   } else {
    //     this.mission[key] = value;
    //   }
    //   this.updateMissionData({
    //     key,
    //     value,
    //   });
    // },
    onChangeCustomMissionData(e) {
      try {
        this.isDirty = true;
        this.updateMissionData({
          key: 'data',
          value: JSON.parse(e),
        });
        this.validJSON = true;
      } catch (error) {
        this.validJSON = false;
        throw new Error(error);
      }
    },

    onChangeOutputData(
      key,
      value,
      {
        outputIndex,
        textIndex,
        tIndex,
        objectIndex,
        libraryIndex,
        optionIndex,
      },
    ) {
      if (key == 'txts') {
        this.mission.output[outputIndex].txtData.txts = value;
      } else if (key == 't') {
        this.mission.output[outputIndex].txtData.txts[tIndex] = value;
      } else if (key == 'txtObjects') {
        this.mission.output[outputIndex].txtData.txtObjects = value;
      } else if (key == 'article') {
        this.mission.output[outputIndex].txtData.txtObjects[objectIndex][key] =
          value ? value : false;
      } else if (key == 'libraries') {
        this.mission.output[outputIndex].txtData.txtObjects[
          objectIndex
        ].libraries[libraryIndex] = value;
      } else if (key == 'options') {
        this.mission.output[outputIndex].txtData.txtObjects[
          objectIndex
        ].options[optionIndex] = value;
      } else if (key == 'isShow' || key == 'isSpeak' || key == 'hideImage') {
        this.mission.output[outputIndex].txtData.txtData[textIndex][key] =
          value;
      } else {
        this.mission.output[key] = value;
      }
      this.updateMissionData({
        key: 'output',
        value: this.mission.output,
      });
    },
    onChangeInputData(key, value, { inputIndex, answerIndex, commentIndex }) {
      if (key == 'input') {
        this.mission.input[inputIndex] = value;
      } else if (key == 'expectAnswers') {
        this.mission.input[inputIndex][key][answerIndex] = value;
      } else if (key == 'expectComments' || key == 'retryComment') {
        this.mission.input[inputIndex][key][commentIndex] = value;
      } else {
        this.mission.input[key] = value;
      }
      this.updateMissionData({
        key: 'input',
        value: this.mission.input,
      });
    },
    onChangeMissionData(key, value) {
      this.isDirty = true;
      this.mission[key] = value;
      this.updateMissionData({
        key,
        value,
      });
    },
    onClickSaveMission() {
      if (!this.validJSON) {
        swal
          .fire({
            titleText: 'Invalid JSON data',
            showCancelButton: false,
            type: 'error',
          })
          .then(result => {});
      } else {
        const updateMissionData = {
          ...this.galaxyChatStore.updateMissionData,
        };
        this.updateMission(updateMissionData);
        this.isDirty = false;
        this.selectedQuestIndex = -1;
        Object.keys(updateMissionData).forEach(key => {
          this.mission[key] = updateMissionData[key];
        });
      }
    },
    onChangeCreateMission(key, value) {
      this.updateCreateMissionData({
        key,
        value,
      });
    },
    onClickAddQuest() {
      const id = this.$route.query.id;
      const createQuestData = {
        ...this.galaxyChatStore.addQuestData,
      };
      const createMissionData = {
        ...this.galaxyChatStore.addMissionData,
      };
      this.addQuest({
        category_id: id,
        quest: createQuestData,
        mission: createMissionData,
      });
    },
    onClickAddGalaxyChat(id) {
      const galaxyChat = { ...this.galaxyChatStore.addGalaxyChatData };
      const createQuestData = {
        ...this.galaxyChatStore.addQuestData,
      };
      const createMissionData = {
        ...this.galaxyChatStore.addMissionData,
      };
      this.addQuest({
        ...galaxyChat,
        category_id: id,
        quest: createQuestData,
        mission: createMissionData,
      });
    },
    onClickDeleteQuest(id) {
      this.deleteQuest(id);
      this.isDirty = false;
      this.selectedQuestIndex = -1;
      this.mission = {};
    },
    onChangeQuestData(key, value) {
      this.updateQuestData({
        key,
        value,
      });
    },
    onClickUpdateQuest() {
      const updateQuestData = {
        ...this.galaxyChatStore.updateQuestData,
      };
      this.updateQuest(updateQuestData);
      this.selectedQuestIndex = -1;
      this.mission = {};
    },
    onClickUpdateGalaxyChat(id) {
      const updateGalaxyChatData = {
        _id: id,
        ...this.galaxyChatStore.updateGalaxyChatData,
      };
      this.updateGalaxyChat(updateGalaxyChatData);
    },
    onClickDeleteGalaxyChat() {
      this.deleteGalaxyChat(this.galaxyChatStore.selectGalaxyChat._id);
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      this.updateQuestData({
        key: 'image',
        value: uri,
      });
      this.quest.image = uri;
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
  },
  watch: {
    selectedQuestIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectQuest(this.$route.query.sub_id);
      }
    },
  },

  mounted() {
    if (this.$route.query.category_id) {
      this.fetchSingleGalaxyChat({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (this.galaxyChatStore.selectedCategoryId) {
      this.onClickAddGalaxyChat(this.galaxyChatStore.selectedCategoryId);
    } else {
      this.fetchSingleGalaxyChat({ id: this.$route.query.id });
    }
  },
};
</script>
