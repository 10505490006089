<template>
  <v-container fluid fill-height>
    <v-layout align-start justify-center>
      <slot />
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: 'Container',
};
</script>
