export default {
  SLIDE_PUZZLE_SIZE: [
    {
      text: '2x2',
      value: 2,
    },
    {
      text: '3x3',
      value: 3,
    },
    {
      text: '4x4',
      value: 4,
    },
  ],
};
