const state = {
  word_learned: [],
  word_incidental: [],
  reward: [],
  sentence: [],
  phonic: [],
};
const getters = {
  getWordLearnedByIndex: (state, getters) => index => {
    const { word, speaking, listening, img_uri } =
      getters.getWordLearned[index];
    return {
      word,
      speaking,
      listening,
      img_uri,
    };
  },
  getWordLearned(state) {
    return state.word_learned;
  },
  getWordIncidental(state) {
    return state.word_incidental;
  },
  getReward(state) {
    return state.reward;
  },
};
const actions = {
  resetAllState({ commit }) {
    commit('RESET_ALL_STATE');
  },
  setWordLearned({ commit }, { key, value, index }) {
    commit('SET_WORD_LEARNED', { key, value, index });
  },
  setWordIncidental({ commit }, { key, value, index }) {
    commit('SET_WORD_INCIDENTAL', { key, value, index });
  },
  setReward({ commit }, { key, value, index }) {
    commit('SET_REWARD', { key, value, index });
  },
  setSentence({ commit }, { key, value, index }) {
    commit('SET_SENTENCE', { key, value, index });
  },
  setPhonic({ commit }, { key, value, index }) {
    commit('SET_PHONIC', { key, value, index });
  },
  setEditDisplayWordLearned({ commit }, payload) {
    commit('SET_EDIT_DISPLAY_WORD_LEARNED', payload);
  },
  setEditDisplayWordIncidental({ commit }, payload) {
    commit('SET_EDIT_DISPLAY_WORD_INCIDENTAL', payload);
  },
  setEditDisplayReward({ commit }, payload) {
    commit('SET_EDIT_DISPLAY_REWARD', payload);
  },
  setEditDisplaySentence({ commit }, payload) {
    commit('SET_EDIT_DISPLAY_SENTENCE', payload);
  },
  setEditDisplayPhonic({ commit }, payload) {
    commit('SET_EDIT_DISPLAY_PHONIC', payload);
  },
  loadPointData({ commit }, { word_learned, word_incidental, reward }) {
    commit('LOAD_ALL_STATE', { word_learned, word_incidental, reward });
  },
};
const mutations = {
  LOAD_ALL_STATE(state, { word_learned, word_incidental, reward }) {
    state.word_learned = [...word_learned];
    state.word_incidental = [...word_incidental];
    state.reward = [...reward];
  },
  RESET_ALL_STATE(state) {
    state.word_learned = [
      {
        word: '',
        speaking: 0,
        listening: 0,
      },
    ];
    state.word_incidental = [
      {
        word: '',
        speaking: 0,
        listening: 0,
      },
    ];
    state.reward = [{ name: 'jewel', amount: 0 }];
    state.sentence = [{ word: '', speaking: 0, listening: 0 }];
    state.phonic = [{ word: '', speaking: 0, listening: 0 }];
  },
  SET_EDIT_DISPLAY_WORD_LEARNED(state, payload) {
    state.word_learned = payload;
  },
  SET_EDIT_DISPLAY_WORD_INCIDENTAL(state, payload) {
    state.word_incidental = payload;
  },
  SET_EDIT_DISPLAY_REWARD(state, payload) {
    state.reward = payload;
  },
  SET_EDIT_DISPLAY_SENTENCE(state, payload) {
    state.sentence = payload;
  },
  SET_EDIT_DISPLAY_PHONIC(state, payload) {
    state.phonic = payload;
  },
  SET_WORD_LEARNED(state, { key, value, index }) {
    state.word_learned[index] = {
      ...state.word_learned[index],
      [key]: value,
    };
  },
  SET_WORD_INCIDENTAL(state, { key, value, index }) {
    state.word_incidental[index] = {
      ...state.word_incidental[index],
      [key]: value,
    };
  },
  SET_REWARD(state, { key, value, index }) {
    state.reward[index] = {
      ...state.reward[index],
      [key]: value,
    };
  },
  SET_SENTENCE(state, { key, value, index }) {
    state.sentence[index] = {
      ...state.sentence[index],
      [key]: value,
    };
  },
  SET_PHONIC(state, { key, value, index }) {
    state.phonic[index] = {
      ...state.phonic[index],
      [key]: value,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
