import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  dragComboCategoryList: [],
  dragComboList: [],
  selectedDragCombo: {},
  selectedChoice: {},
  selectedSetIndex: -1,
  selectedNodeIndex: -1,
  selectedChoiceIndex: -1,
  updateDetail: {},
  updateSet: {},
  updateNode: {},
  updateChoice: {},
  category_id: '',
  isCopyJson: false,
  defaultCreateGameDetail: {
    category_id: '',
    title: '',
    introduction_voice_uri: '',
    background_uri: '',
    bgm_uri: '',
    sets: [],
    learned_words: '',
    incidental_words: '',
    sentence: null,
    phonic: null,
    reward: null,
  },
  defaultCreateSets: {
    dragcombo_id: '',
    name: '',
    nodes: [],
  },
  defaultCreateNodes: {
    set_id: '',
    name: '',
    container_outline_color: '',
    container: {
      image_uri: '',
      is_sprite_sheet: false,
      sprite_sheet_data: {
        column: 0,
        row: 0,
        frame_ms: 200,
        loop_count: -1,
      },
      tap_sound_uri: '',
      correct_count: -1,
      startX: '0',
      startY: '0',
      endX: '0',
      endY: '0',
    },
    choices: [],
  },
  defaultCreateChoice: {
    name: '',
    is_fake_choice: false,
    image_uri: '',
    is_sprite_sheet: false,
    sprite_sheet_data: {
      column: 0,
      row: 0,
      frame_ms: 200,
      loop_count: -1,
    },
    startX: '0',
    startY: '0',
    endX: '0',
    endY: '0',
    drag_image_uri: '',
    is_drag_sprite_sheet: false,
    drag_sprite_sheet_data: {
      column: 0,
      row: 0,
      frame_ms: 200,
      loop_count: -1,
    },
    drag_sound_uri: '',
    action_image_uri: '',
    action_position: {
      startX: '0',
      startY: '0',
      endX: '0',
      endY: '0',
    },
    is_action_sprite_sheet: false,
    action_sprite_sheet_data: {
      column: 0,
      row: 0,
      frame_ms: 200,
      loop_count: -1,
    },
    action_sound_uri: '',
    action_duration: '',
    type: 0,
    container_change: {
      image_uri: '',
      is_sprite_sheet: false,
      sprite_sheet_data: {
        column: 0,
        row: 0,
        frame_ms: 200,
        loop_count: -1,
      },
      tap_sound_uri: '',
      is_translate: false,
      translate_duration: '',
      startX: '0',
      startY: '0',
      endX: '0',
      endY: '0',
    },
  },
};
const getters = {};
const actions = {
  async selectedDragComboById({ commit, dispatch }, id) {
    http
      .GET(`/api/dragcombo?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const [formatData] = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('setStateByKey', {
          statekey: 'selectedDragCombo',
          key: null,
          value: formatData,
        });
        dispatch('setStateByKey', {
          statekey: 'updateDetail',
          key: 'id',
          value: formatData._id,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async fetchDragComboCategoryList(
    { commit, dispatch },
    { page = 1, limit = 0 },
  ) {
    http
      .GET(`/api/dragcombo/list?page=${page}&limit=${limit}&isWeb=true`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'dragComboCategoryList',
          key: null,
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async fetchDragComboList(
    { commit, dispatch },
    { category_id, page = 1, limit = 0 },
  ) {
    http
      .GET(
        `/api/dragcombo?category_id=${category_id}&page=${page}&limit=${limit}&isWeb=true`,
        {
          isAuthorization: true,
        },
      )
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'category_id',
          value: category_id,
        });
        dispatch('setStateByKey', {
          statekey: 'dragComboList',
          key: null,
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async createDragComboCategory({ commit, dispatch }, createData) {
    http
      .POST(`/api/dragcombo/list`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchDragComboCategoryList', {
          page: 1,
          limit: 0,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Drag Combo successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot create drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async createDragCombo({ commit, dispatch }, createData) {
    http
      .POST(`/api/dragcombo`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        // dispatch('clearStateValueDefualt', 'defaultCreateGameDetail');
        dispatch('fetchDragComboList', {
          category_id: state.category_id,
          page: 1,
          limit: 0,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Drag Combo successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot create drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async createSets({ commit, dispatch }, createData) {
    createData ? createData : (createData = state.defaultCreateSets);
    http
      .POST(`/api/dragcombo/set`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        // dispatch('clearStateValueDefualt', 'defaultCreateSets');
        const id = state.updateDetail._id
          ? state.updateDetail._id
          : state.updateDetail.id;
        dispatch('selectedDragComboById', id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create sets drag combo successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create sets drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async createNodes({ commit, dispatch }, createData) {
    createData ? createData : (createData = state.defaultCreateNodes);
    http
      .POST(`/api/dragcombo/node`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        // dispatch('clearStateValueDefualt', 'defaultCreateNodes');
        const id = state.updateDetail._id
          ? state.updateDetail._id
          : state.updateDetail.id;
        dispatch('selectedDragComboById', id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create node drag combo successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create node drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async deleteDragCombo({ commit, dispatch }, category_id) {
    http
      .DELETE(`/api/dragcombo?id=${category_id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'updateSet');
        dispatch('clearStateByKey', 'updateNode');
        dispatch('clearStateByKey', 'updateChoice');
        dispatch('fetchDragComboList', {
          category_id: state.category_id,
          page: 1,
          limit: 0,
        });
        // if (state.category_id)
        router.push(`/drag_combo/list?category_id=${state.category_id}`);
        // else router.push(`/drag_combo`);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete Drag Combo Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete home group page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async deleteSetDragCombo({ commit, dispatch }, id) {
    http
      .DELETE(`/api/dragcombo/set?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const id = state.updateDetail._id
          ? state.updateDetail._id
          : state.updateDetail.id;
        dispatch('clearStateByKey', 'selectedSetIndex');
        dispatch('clearStateByKey', 'selectedNodeIndex');
        dispatch('clearStateByKey', 'selectedChoiceIndex');
        dispatch('clearStateByKey', 'updateSet');
        dispatch('clearStateByKey', 'updateNode');
        dispatch('clearStateByKey', 'updateChoice');
        dispatch('selectedDragComboById', id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete Set Drag Combo Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete set drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async deleteNodeDragCombo({ commit, dispatch }, id) {
    http
      .DELETE(`/api/dragcombo/node?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const id = state.updateDetail._id
          ? state.updateDetail._id
          : state.updateDetail.id;
        dispatch('clearStateByKey', 'selectedChoiceIndex');
        dispatch('clearStateByKey', 'selectedNodeIndex');
        dispatch('clearStateByKey', 'updateNode');
        dispatch('clearStateByKey', 'updateChoice');
        dispatch('selectedDragComboById', id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete node drag combo successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete node drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async updateDragComboDetail({ commit, dispatch }, updateData) {
    updateData ? updateData : (updateData = state.updateDetail);
    http
      .PUT(`/api/dragcombo`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (state.updateDetail.id || state.updateDetail._id) {
          const id = state.updateDetail._id
            ? state.updateDetail._id
            : state.updateDetail.id;
          dispatch('selectedDragComboById', id);
          dispatch('clearStateByKey', 'updateDetail');
        } else
          dispatch('fetchDragComboList', {
            category_id: state.category_id,
            page: 1,
            limit: 0,
          });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update DragCombo Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot update home page game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async updateSetDragCombo({ commit, dispatch }, updateData) {
    updateData ? updateData : (updateData = state.updateSet);
    http
      .PUT(`/api/dragcombo/set`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const id = state.updateDetail._id
          ? state.updateDetail._id
          : state.updateDetail.id;
        dispatch('selectedDragComboById', id);
        dispatch('clearStateByKey', 'updateSet');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update set drag combo successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot update set drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async updateNodeDragCombo({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateNode;
    http
      .PUT(`/api/dragcombo/node`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const id = state.updateDetail._id
          ? state.updateDetail._id
          : state.updateDetail.id;
        dispatch('selectedDragComboById', id);
        dispatch('clearStateByKey', 'updateNode');
        dispatch('clearStateByKey', 'updateChoice');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update node drag combo Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot update node drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
    // if (Array.isArray(state[statekey])) {
    //   state[statekey] = [];
    // } else {
    //   switch (typeof state[statekey]) {
    //     case 'string':
    //       state[statekey] = '';
    //       break;
    //     case 'number':
    //       state[statekey] = -1;
    //       break;
    //     case 'boolean':
    //       state[statekey] = false;
    //       break;
    //     case 'object':
    //       for (const [key, value] of Object.entries(state[statekey])) {
    //         if (key != 'id') {
    //           delete state[statekey][key];
    //         }
    //       }
    //       break;
    //   }
    // }
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
