import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import Resource from './views/Resource.vue';
import Users from './views/Users.vue';
import Book from './views/Books';
import Level from './views/Level.vue';
import Learningpath from './views/Learningpath';
import Wordwalk from './views/Wordwalk';
import Category from './views/Category';
import MatchCard from './views/MatchCard';
import ConnectingNode from './views/ConnectingNode';
import TapToSay from './views/TapToSay';
import DragAndDrop from './views/DragAndDrop';
import Media from './views/Media';
import MixAndMatch from './views/MixAndMatch';
import Swipe from './views/Swipe';
import Labeling from './views/Labeling';
import GalaxyChat from './views/GalaxyChat';
import Code from './views/Code';
import Tracing from './views/Tracing';
import TracingBezier from './views/TracingBezier';
import MicPopUp from './views/MicPopUp';
import Dashboard from './views/Dashboard';
import MiniChat from './views/MiniChat';
import { ROLE_NAME, ROLE_NUMBER } from './utils/role';
import store from './store/';
import Swal from 'sweetalert2';
import Library from './views/Library';
import Dictionary from './views/Dictionary';
import DicPhonic from './views/DicPhonic';
import MoveData from './views/MoveData';
import StoryGame from './views/StoryGame';
import StoryVideo from './views/StoryVideo';
import News from './views/News';
import Quest from './views/Quest';
import Localization from './views/Localization';
import SubtitleLocalization from './views/SubtitleLocalization';
import SubtitleVideoLocalization from './views/SubtitleVideoLocalization';
import SubtitleSoundLocalization from './views/SubtitleSoundLocalization';
import Shop from './views/Shop';
import LearningMedia from './views/LearningMedia';
import FlyingGame from './views/FlyingGame';
import TapStory from './views/TapStory';
import ContentNavigation from './views/ContentNavigation';
import PaymentStore from './views/PaymentStore';
import Postbox from './views/Postbox';
import CustomerServiceChat from './views/CustomerServiceChat';
import SlidePuzzle from './views/SlidePuzzle';
import WhackAMole from './views/WhackAMole';
import MazeGame from './views/MazeGame';
import ShortStory from './views/ShortStory';
import HomePage from './views/HomePage';
import DragCombo from './views/DragCombo';
import SpeechLabTag from './views/SpeechLabTag';
import Snap from './views/Snap';
import MiniGames from './views/MiniGames';
import RunningGame from './views/MiniGames/RunningGame';
import DrivingGame from './views/MiniGames/DrivingGame';
import SequenceGame from './views/MiniGames/SequenceGame';
import QuizGame from './views/MiniGames/QuizGame';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/resource',
      name: 'resource',
      component: Resource,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Marketing,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/code',
      name: 'code',
      component: Code,
      beforeEnter: requireAuth,
      meta: {
        permission: [ROLE_NAME.Developer, ROLE_NAME.Marketing, ROLE_NAME.Admin],
      },
    },
    {
      path: '/movedata',
      name: 'movedata',
      component: MoveData,
      beforeEnter: requireAuth,
      meta: { permission: [ROLE_NAME.Developer, ROLE_NAME.Admin] },
    },
    {
      path: '/dictionary',
      name: 'dictionary',
      component: Dictionary,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/dicphonic',
      name: 'dicphonic',
      component: DicPhonic,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Marketing,
          ROLE_NAME.CustomerService,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/books',
      name: 'BookShelfList',
      component: Book.BookShelfList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/book/detail',
      name: 'BookShelf',
      component: Book.BookShelf,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/level',
      name: 'Level',
      component: Level,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/learningpaths',
      name: 'LearningPathList',
      component: Learningpath.LearningpathList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/learningpath',
      name: 'LearningPath',
      component: Learningpath.Learningpath,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/storygames',
      name: 'StoryGameList',
      component: StoryGame.StoryGameList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/storygame',
      name: 'StoryGame',
      component: StoryGame.StoryGame,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/storyvideos',
      name: 'StoryVideoList',
      component: StoryVideo.StoryVideoList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/storyvideo',
      name: 'StoryVideo',
      component: StoryVideo.StoryVideo,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/wordwalks',
      name: 'WordwalkList',
      component: Wordwalk.WordwalkList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/wordwalk/detail',
      name: 'Wordwalk',
      component: Wordwalk.Wordwalk,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/category',
      name: 'Category',
      component: Category,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/matchcards',
      name: 'MatchCardList',
      component: MatchCard.MatchCardList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/matchcard/detail',
      name: 'MatchCard',
      component: MatchCard.MatchCard,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/micpopup',
      name: 'MicPopUpList',
      component: MicPopUp.MicPopUpList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/micpopup/detail',
      name: 'MicPopUp',
      component: MicPopUp.MicPopUp,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/connectingnode',
      name: 'ConnectingNodeList',
      component: ConnectingNode.ConnectingNodeList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/connectingnode/detail',
      name: 'ConnectingNode',
      component: ConnectingNode.ConnectingNode,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/taptosay',
      name: 'TapToSayList',
      component: TapToSay.TapToSayList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/taptosay/detail',
      name: 'TapToSay',
      component: TapToSay.TapToSay,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/draganddrop',
      name: 'DragAndDropList',
      component: DragAndDrop.DragAndDropList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/draganddrop/detail',
      name: 'DragAndDrop',
      component: DragAndDrop.DragAndDrop,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/media',
      name: 'MediaList',
      component: Media.MediaList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/media/detail',
      name: 'Media',
      component: Media.Media,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/mixandmatch',
      name: 'MixAndMatchList',
      component: MixAndMatch.MixAndMatchList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/mixandmatch/detail',
      name: 'MixAndMatch',
      component: MixAndMatch.MixAndMatch,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/swipe',
      name: 'SwipeList',
      component: Swipe.SwipeList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/swipe/detail',
      name: 'Swipe',
      component: Swipe.Swipe,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/labeling',
      name: 'LabelList',
      component: Labeling.LabelList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/labeling/detail',
      name: 'Label',
      component: Labeling.Label,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/trace',
      name: 'TracingList',
      component: Tracing.TracingList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/trace/detail',
      name: 'Tracing',
      component: Tracing.Tracing,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/tracebezier',
      name: 'TracingBezierList',
      component: TracingBezier.TracingBezierList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/tracebezier/detail',
      name: 'TracingBezier',
      component: TracingBezier.TracingBezier,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard.Dashboard,
      beforeEnter: requireAuth,
      meta: { permission: [ROLE_NAME.Developer, ROLE_NAME.Admin] },
    },
    {
      path: '/library',
      name: 'LibraryList',
      component: Library.LibraryList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/library/detail',
      name: 'Library',
      component: Library.Library,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/minichat',
      name: 'MiniChatCategory',
      component: MiniChat.MiniChatCategory,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/minichat/list',
      name: 'MiniChatList',
      component: MiniChat.MiniChatList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/minichat/detail',
      name: 'MiniChat',
      component: MiniChat.MiniChat,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/news',
      name: 'News',
      component: News.News,
      beforeEnter: requireAuth,
      meta: { permission: [ROLE_NAME.Developer, ROLE_NAME.Admin] },
    },
    {
      path: '/shop',
      name: 'Shop',
      component: Shop.Shop,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/quest',
      name: 'Quest',
      component: Quest,
      beforeEnter: requireAuth,
      meta: { permission: [ROLE_NAME.Developer, ROLE_NAME.Admin] },
    },
    {
      path: '/galaxychat',
      name: 'GalaxyChatList',
      component: GalaxyChat.GalaxyChatList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/galaxychat/detail',
      name: 'GalaxyChat',
      component: GalaxyChat.GalaxyChat,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/localization',
      name: 'Localization',
      component: Localization.Localization,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Translator,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/subtitle_localization',
      name: 'Subtitle Localization',
      component: SubtitleLocalization.SubtitleLocalization,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Translator,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/subtitle_video_localization',
      name: 'Subtitle Video Localization',
      component: SubtitleVideoLocalization.SubtitleVideoLocalization,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Translator,
          ROLE_NAME.Marketing,
          ROLE_NAME.Media,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/subtitle_sound_localization',
      name: 'Subtitle Sound Localization',
      component: SubtitleSoundLocalization.SubtitleSoundLocalization,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Translator,
          ROLE_NAME.Marketing,
          ROLE_NAME.Media,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/learningmedia',
      name: 'LearningMediaList',
      component: LearningMedia.LearningMediaList,
      beforeEnter: requireAuth,
      meta: { permission: [ROLE_NAME.Developer, ROLE_NAME.Admin] },
    },
    {
      path: '/learningmedia/detail',
      name: 'LearningMedia',
      component: LearningMedia.LearningMedia,
      beforeEnter: requireAuth,
      meta: { permission: [ROLE_NAME.Developer, ROLE_NAME.Admin] },
    },
    {
      path: '/flyinggame',
      name: 'FlyingGameList',
      component: FlyingGame.FlyingGameList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/flyinggame/detail',
      name: 'FlyingGame',
      component: FlyingGame.FlyingGame,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/tapstory',
      name: 'TapStoryList',
      component: TapStory.TapStoryList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Media,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/tapstory/detail',
      name: 'TapStory',
      component: TapStory.TapStory,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Media,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/content_navigation',
      name: 'ContentNavigation',
      component: ContentNavigation.ContentNavigation,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Translator,
          ROLE_NAME.Marketing,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/payment_store',
      name: 'PaymentStore',
      component: PaymentStore.PaymentStore,
      beforeEnter: requireAuth,
      meta: {
        permission: [ROLE_NAME.Developer, ROLE_NAME.Marketing, ROLE_NAME.Admin],
      },
    },
    {
      path: '/postbox',
      name: 'Postbox',
      component: Postbox.Postbox,
      beforeEnter: requireAuth,
      meta: {
        permission: [ROLE_NAME.Developer, ROLE_NAME.Marketing, ROLE_NAME.Admin],
      },
    },
    {
      path: '/customer_service_chat',
      name: 'CustomerServiceChatList',
      component: CustomerServiceChat.CustomerServiceChatList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.CustomerService,
          ROLE_NAME.Marketing,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/customer_service_chat/detail',
      name: 'CustomerServiceChat',
      component: CustomerServiceChat.CustomerServiceChat,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.CustomerService,
          ROLE_NAME.Marketing,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/slide_puzzle',
      name: 'SlidePuzzleList',
      component: SlidePuzzle.SlidePuzzleList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/slide_puzzle/detail',
      name: 'SlidePuzzle',
      component: SlidePuzzle.SlidePuzzle,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/whack_a_mole',
      name: 'WhackAMoleList',
      component: WhackAMole.WhackAMoleList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/whack_a_mole/detail',
      name: 'WhackAMole',
      component: WhackAMole.WhackAMole,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/maze_game',
      name: 'MazeGameList',
      component: MazeGame.MazeGameList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          // ROLE_NAME.ContentCreator,
          // ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/maze_game/detail',
      name: 'MazeGame',
      component: MazeGame.MazeGame,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          // ROLE_NAME.ContentCreator,
          // ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/short_story',
      name: 'ShortStoryList',
      component: ShortStory.ShortStoryList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          // ROLE_NAME.ContentCreator,
          // ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/short_story/detail',
      name: 'ShortStory',
      component: ShortStory.ShortStory,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          // ROLE_NAME.ContentCreator,
          // ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/home_page',
      name: 'HomePageList',
      component: HomePage.HomePageTopicList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/home_page/group',
      name: 'HomePageGroupList',
      component: HomePage.HomePageList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/home_page/detail',
      name: 'HomePage',
      component: HomePage.HomePageDetail,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/drag_combo',
      name: 'DragComboCategory',
      component: DragCombo.DragComboListCategory,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          // ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/drag_combo/list',
      name: 'DragComboList',
      component: DragCombo.DragComboList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          // ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/drag_combo/detail',
      name: 'DragCombo',
      component: DragCombo.DragCombo,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.ContentCreator,
          // ROLE_NAME.Media,
          ROLE_NAME.Admin,
        ],
      },
    },
    {
      path: '/speech_lab_tag',
      name: 'SpeechLabTag',
      component: SpeechLabTag.SpeechLabTag,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/snap',
      name: 'SnapList',
      component: Snap.SnapList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/snap/detail',
      name: 'Snap',
      component: Snap.SnapGame,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/mini_games',
      name: 'MiniGameCatagory',
      component: MiniGames.MiniGamesCatagory,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/running_game',
      name: 'RunningGameList',
      component: RunningGame.RunningGameList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/running_game/detail',
      name: 'RunningGameDetail',
      component: RunningGame.RunningGame,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/driving_game',
      name: 'DrivingGameList',
      component: DrivingGame.DrivingGameList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/driving_game/detail',
      name: 'DrivingGameDetail',
      component: DrivingGame.DrivingGame,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/sequence_game',
      name: 'SequenceGameList',
      component: SequenceGame.SequenceGameList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/sequence_game/detail',
      name: 'SequenceGameDetail',
      component: SequenceGame.SequenceGame,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/quiz_game',
      name: 'QuizGameList',
      component: QuizGame.QuizGameList,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
    {
      path: '/quiz_game/detail',
      name: 'QuizGameDetail',
      component: QuizGame.QuizGame,
      beforeEnter: requireAuth,
      meta: {
        permission: [
          ROLE_NAME.Developer,
          ROLE_NAME.Admin,
          ROLE_NAME.ContentCreator,
          ROLE_NAME.Media,
        ],
      },
    },
  ],
});

async function requireAuth(to, from, next) {
  const userLevel = await getRoleId();
  const permissionList = to.meta.permission;
  let userRole = null;
  if (userLevel === ROLE_NUMBER.Developer) {
    userRole = ROLE_NAME.Developer;
  }
  if (userLevel === ROLE_NUMBER.Media) {
    userRole = ROLE_NAME.Media;
  }
  if (userLevel === ROLE_NUMBER.Marketing) {
    userRole = ROLE_NAME.Marketing;
  }
  if (userLevel === ROLE_NUMBER.ContentCreator) {
    userRole = ROLE_NAME.ContentCreator;
  }
  if (userLevel === ROLE_NUMBER.Translator) {
    userRole = ROLE_NAME.Translator;
  }
  if (userLevel === ROLE_NUMBER.CustomerService) {
    userRole = ROLE_NAME.CustomerService;
  }
  if (userLevel === ROLE_NUMBER.Admin) {
    userRole = ROLE_NAME.Admin;
  }

  if (permissionList.includes(userRole)) {
    next();
  } else {
    Swal.fire({
      title: 'Authorized Personnel Only ',
      type: 'error',
      text: 'You are not authorized!.',
      timer: 3000,
    });
    next('/');
  }
}

async function getRoleId() {
  let user = await getUserState();
  user = JSON.parse(JSON.stringify(user));
  const role = user.userData.role_id;
  return role;
}

function getUserState() {
  return new Promise(resolve => {
    if (store.state.userInfo === undefined) {
      const unwatch = store.watch(
        () => store.state.userInfo,
        value => {
          unwatch();
          resolve(value);
        },
      );
    } else {
      resolve(store.state.userInfo);
    }
  });
}

export default router;
