export default {
  MESSAGE_SCHEDULE_TYPE: {
    NONE: 1,
    TIME: 2,
    END_LESSON: 3,
    END_UNIT: 4,
    END_LEVEL: 5,
    NOT_LAUNCH_THE_APP: 6,
    RUN_OUT_OF_FREE_CONTENT: 7,
    WEEKLY_GOAL: 8,
    INVITATION_REWARD: 9,
  },
  MESSAGE_SCHEDULE_TYPE_LIST: [
    {
      text: 'None',
      value: 1,
    },
    {
      text: 'Time',
      value: 2,
    },
    {
      text: 'End Lesson',
      value: 3,
    },
    {
      text: 'End Unit',
      value: 4,
    },
    {
      text: 'End Level',
      value: 5,
    },
    {
      text: 'Not Launch The App',
      value: 6,
    },
    {
      text: 'Run Out of Free Content',
      value: 7,
    },
    {
      text: 'Weekly Goal',
      value: 8,
    },
    {
      text: 'Invitation Reward',
      value: 9,
    },
  ],
};
