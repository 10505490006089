<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Dictionary Manager</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex xs3>
                <v-text-field
                  outline
                  v-model="search"
                  label="Filter by word"
                  append-icon="search"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-data-table
            v-if="!dictionaryStore.isLoadDictionary"
            :items="dictionaryStore.dictionaryList"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>{{ props.item.word }}</td>
              <td>{{ props.item.en }}</td>
              <td>{{ props.item.th }}</td>
              <td>{{ props.item.ch }}</td>
              <td>
                <v-layout align-content-space-between>
                  <v-btn
                    class="btn-center"
                    @click="homeData(props.item._id)"
                    flat
                    icon
                  >
                    <v-icon>home</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    color="black"
                    v-on:click="onClickEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>

    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="isEdit ? dictionaryStore.selectedWord.learned_words : null"
      :incidentalWords="
        isEdit ? dictionaryStore.selectedWord.incidental_words : null
      "
      :rewards="isEdit ? dictionaryStore.selectedWord.reward : null"
      :sentences="isEdit ? dictionaryStore.selectedWord.sentence : null"
      :phonics="isEdit ? dictionaryStore.selectedWord.phonic : null"
    >
    </PointData>
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onClickUpdateWord"
      :close="closeEdit"
      title="Edit Word"
      :isLastStep="valid && validWordStore"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-flex>
                <v-btn
                  class="white--text"
                  color="pointdata"
                  @click="openPointData"
                  >Point Data
                </v-btn>
              </v-flex>
              <v-flex>
                <v-checkbox
                  label="Available for Free User"
                  v-model="dictionaryStore.selectedWord.isGuestPlayable"
                  :value="dictionaryStore.selectedWord.isGuestPlayable"
                  v-on:change="e => onChangeUpdateWord('isGuestPlayable', e)"
                >
                </v-checkbox>
              </v-flex>
              <v-flex>
                <v-text-field
                  :rules="[rules.required, rules.wordEN]"
                  :error-messages="answer"
                  :append-icon="isChecking ? 'done' : 'error'"
                  label="Word"
                  v-model="dictionaryStore.selectedWord.word"
                  v-on:input="
                    e => {
                      onChangeUpdateWord('word', e);
                      onChangeAlphabet(e);
                    }
                  "
                ></v-text-field>
              </v-flex>
              <v-flex d-flex>
                <v-text-field
                  label="TTS Message"
                  v-model="dictionaryStore.selectedWord.tts_message"
                  v-on:change="e => onChangeUpdateWord('tts_message', e)"
                ></v-text-field>
                <v-select
                  :items="constants.VOICE_NAME"
                  label="Voice Name"
                  :value="dictionaryStore.selectedWord.voice_name"
                  v-model="dictionaryStore.selectedWord.voice_name"
                  v-on:change="e => onChangeUpdateWord('voice_name', e)"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-text-field
                  label="EN"
                  v-model="dictionaryStore.selectedWord.en"
                  v-on:change="e => onChangeUpdateWord('en', e)"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  label="TH"
                  v-model="dictionaryStore.selectedWord.th"
                  v-on:change="e => onChangeUpdateWord('th', e)"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  label="CH"
                  v-model="dictionaryStore.selectedWord.ch"
                  v-on:change="e => onChangeUpdateWord('ch', e)"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-container grid-list-xs text-xs-center>
                  <img
                    :src="
                      dictionaryStore.selectedWord.img_uri
                        ? dictionaryStore.selectedWord.img_uri
                        : ''
                    "
                    class="content_img"
                  />
                </v-container>
                <v-text-field
                  label="Image (URL or Select file)"
                  :value="dictionaryStore.selectedWord.img_uri"
                  :rules="[rules.required, rules.validURI]"
                  v-on:change="e => onChangeUpdateWord('img_uri', e)"
                />
                <span class="headline" dark>
                  <v-btn color="black" dark v-on:click="isEditImage = true">
                    <v-icon>image</v-icon>Edit Image
                  </v-btn>
                </span>
              </v-flex>
              <v-flex>
                <v-autocomplete
                  label="Background Color"
                  v-model="dictionaryStore.selectedWord.bg_color"
                  :value="dictionaryStore.selectedWord.bg_color"
                  :items="colorStore"
                  item-text="color_name"
                  item-value="hex"
                  v-on:change="e => onChangeUpdateWord('bg_color', e)"
                ></v-autocomplete>
              </v-flex>
              <v-flex>
                <v-text-field
                  label="Sound (URL or Select file)"
                  :value="dictionaryStore.selectedWord.sound_uri"
                  :rules="[rules.validURI]"
                  v-on:change="e => onChangeUpdateWord('sound_uri', e)"
                />
                <v-btn color="black" dark v-on:click="isEditSound = true">
                  <v-icon>audiotrack</v-icon>Edit Sound
                </v-btn>
              </v-flex>
            </v-form>
          </v-layout>
        </v-container>
      </v-card>
    </Modal>
    <HomeDataAssign
      :isOpen="isOpenHomeData"
      :gameData="dictionaryStore.selectedWord"
      :type="constantHomePage.GameType.TagGame"
      @close="isOpenHomeData = false"
    ></HomeDataAssign>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import swal from 'sweetalert2';
import Container from '../components/Container';
import PointData from '../components/PointData';
import Modal from '../components/Modal';
import constants from '../constants';
import _ from 'lodash';
import ResourceSelect from '../components/ResourceSelect';
import HomeDataAssign from './HomePage/components/HomeDataAssign.vue';
import constantHomePage from './HomePage/constant';
import rules from '../utils/ruleValidate';

export default {
  name: 'Dictionary',
  components: {
    Container,
    Modal,
    PointData,
    ResourceSelect,
    HomeDataAssign,
  },
  data: function () {
    return {
      constants,
      search: '',
      headers: [
        { text: 'Word', value: 'word', sortable: true },
        { text: 'EN', value: 'en', sortable: false },
        { text: 'TH', value: 'th', sortable: false },
        { text: 'CH', value: 'ch', sortable: false },
        { text: '', value: '', sortable: false },
      ],
      isCreate: false,
      isEdit: false,
      isEditImage: false,
      selectImageUri: '',
      isEditSound: false,
      selectSoundUri: '',
      answer: '',
      valid: true,
      rules,
      isOpenPointData: false,
      isChecking: true,
      originalWord: [],
      checkOriginalWord: '',
      isOpenHomeData: false,
      constantHomePage: constantHomePage,
    };
  },
  watch: {
    validWordStore(val) {
      val ? (this.answer = '') : (this.answer = 'Duplicate word is detected.');
    },
    'dictionaryStore.dictionaryList'(val) {
      this.originalWord = val.map(e => {
        return { word: e.word, id: e._id };
      });
    },
    'dictionaryStore.selectedWord'(val) {
      this.checkOriginalWord = this.originalWord
        .filter(e => {
          return e.id == val._id;
        })
        .map(e => e.word)
        .join('');
    },
    // 'form.word'() {
    // this.answer = 'Checking word now...';
    // this.isChecking = false;
    //   this.debouncedValidateWord();
    //   this.debouncedErrorMessage();
    // },
  },
  methods: {
    ...mapActions('appConfig', ['toggleAlert']),
    ...mapActions('dictionary', [
      'fetchDictionary',
      'fetchDictionaryByWord',
      'addNewWord',
      'updateWord',
      'deleteWordById',
      'editUpdateWord',
      'editCreateWord',
      'selectedWord',
      'clearSelectData',
      'validateDuplicateWord',
      'clearisValidWord',
    ]),
    ...mapActions('library', ['fetchColorData']),
    homeData(id) {
      this.isOpenHomeData = true;
      this.selectedWord(id);
      // this.typeHomeData = constantHomePage.GameType.TagGame;
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.editUpdateWord({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.editUpdateWord({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.editUpdateWord({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.editUpdateWord({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.editUpdateWord({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateWord();
      this.isOpenPointData = false;
    },
    validateWord() {
      let word = this.dictionaryStore.selectedWord.word;
      this.validateDuplicateWord(word);
    },
    validateWordFlag() {
      if (this.validWordStore === false) {
        this.isChecking = false;
        this.answer = 'Duplicate word is detected.';
      } else {
        this.isChecking = true;
        this.answer = '';
      }
    },
    onClickEdit(id) {
      this.clearSelectData();
      this.isEdit = true;
      this.selectedWord(id);
      this.clearisValidWord();
    },
    onClickDelete(item) {
      let id = item._id;
      let word = item.word;
      this.deleteWordById({ id, word });
    },
    closeCreate() {
      this.isCreate = false;
    },
    onClickSaveWord() {
      const createWordData = { ...this.dictionaryStore.createWord };
      this.addNewWord(createWordData);
      this.isCreate = false;
    },
    onChangeCreateWord(key, value) {
      this.editCreateWord({ key, value });
    },
    closeEdit() {
      this.isEdit = false;
      this.clearisValidWord();
      this.answer = '';
    },
    onClickUpdateWord() {
      if (this.$refs.form.validate()) {
        const updateWordData = { ...this.dictionaryStore.selectedWord };
        this.updateWord(updateWordData);
        this.isEdit = false;
        this.clearisValidWord();
        this.answer = '';
      }
    },
    maskToLowerCase(value) {
      if (!value) return '';
      value = value.toString();
      return value.toLocaleLowerCase();
    },
    onChangeUpdateWord(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      if (key === 'word') {
        if (value === '') {
          this.isChecking = false;
          this.answer = 'Please enter the word.';
        } else if (value === this.checkOriginalWord) {
          this.isChecking = true;
          this.answer = '';
        } else {
          this.validateWord();
          this.validateWordFlag();
        }
      }
      this.editUpdateWord({ key, value });
    },
    onChangeAlphabet(word) {
      if (this.isCreate == true) {
        this.editCreateWord({
          key: 'alphabet',
          value: word.substring(0, 1).toUpperCase(),
        });
      } else if (this.isEdit == true) {
        this.editUpdateWord({
          key: 'alphabet',
          value: word.substring(0, 1).toUpperCase(),
        });
      }
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isCreate) {
        this.editCreateWord({
          key: 'img_uri',
          value: uri,
        });
        this.dictionaryStore.selectedWord.img_uri = uri;
      } else {
        this.editUpdateWord({
          key: 'img_uri',
          value: uri,
        });
        this.dictionaryStore.selectedWord.img_uri = uri;
      }
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isCreate) {
        this.editCreateWord({
          key: 'sound_uri',
          value: uri,
        });
        this.dictionaryStore.selectedWord.sound_uri = uri;
      } else {
        this.editUpdateWord({
          key: 'sound_uri',
          value: uri,
        });
        this.dictionaryStore.selectedWord.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
  },
  created: function () {
    // this.debouncedValidateWord = _.debounce(this.validateWord, 500);
    // this.debouncedErrorMessage = _.debounce(this.validateWordFlag, 900);
  },
  mounted: function () {
    if (this.$route.query.word) {
      this.fetchColorData();
      this.fetchDictionaryByWord({ word: this.$route.query.word });
      this.search = this.$route.query.word;
    } else {
      this.fetchColorData();
      this.fetchDictionary({ page: 1 });
    }
  },
  computed: {
    ...mapState({
      dictionaryStore: state => state.dictionary,
      userInfoStore: state => state.userInfo,
      validWordStore: state => state.dictionary.isValidWord,
      colorStore: state => state.library.colorData,
    }),
    form() {
      return {
        word: this.dictionaryStore.selectedWord.word,
        wordEN: this.dictionaryStore.selectedWord.en,
        wordTH: this.dictionaryStore.selectedWord.th,
      };
    },
  },
};
</script>
