<template>
  <Modal
    :isOpen="isShow"
    :close="close"
    :save="save || close"
    :title="title[resourceType]"
    :type="type"
  >
    <v-card v-if="resourceType === 'image'">
      <v-card-actions>
        <v-text-field
          label="Name"
          v-on:change="e => onChangeFilter(e, 'name')"
          solo
          v-model="filter.name"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          label="Legacy Tag"
          v-on:change="e => onChangeFilter(e, 'tag')"
          solo
          v-model="filter.tag"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-combobox
          v-model="filter.tags"
          hide-selected
          solo
          hint="Maximum of 5 tags"
          label="Tags"
          multiple
          small-chips
          v-on:change="e => onChangeFilter(e, 'tags')"
        >
        </v-combobox>
        <v-spacer></v-spacer>
        <v-select
          v-model="filter.color"
          v-on:change="e => onChangeFilter(e, 'color')"
          solo
          label="Color"
          :items="colors"
        ></v-select>
        <v-spacer></v-spacer>
        <v-btn icon color="black" dark v-on:click="onClickSearch">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card-actions>
      <v-spacer></v-spacer>
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex
            v-for="image in resourceStore.resourcesList"
            :key="image._id"
            xs2
          >
            <v-card flat tile>
              <v-img
                v-if="exploreMode"
                :src="image.image_uri"
                height="100px"
                v-on:click="onSelect(image.image_uri)"
                v-clipboard:copy="image.image_uri"
                v-clipboard:success="onClickCopies"
              ></v-img>
              <v-img
                v-else
                :src="image.image_uri"
                height="100px"
                v-on:click="onSelect(image.image_uri)"
              >
                <v-container justify-center>
                  <v-layout>
                    <v-flex>
                      <v-btn
                        fab
                        v-if="image.image_uri === value"
                        color="green"
                        dark
                        >Select</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-img>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-card v-if="resourceType === 'sound'">
      <v-card-actions>
        <v-text-field
          append-icon="search"
          label="Tag"
          v-on:change="e => onChangeFilter(e, 'tag')"
          solo
          v-model="filter.tag"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="search"
          label="File Name"
          v-on:change="e => onChangeFilter(e, 'name')"
          solo
          v-model="filter.name"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-combobox
          v-model="filter.tags"
          hide-selected
          solo
          hint="Maximum of 5 tags"
          label="Tags"
          multiple
          small-chips
          v-on:change="e => onChangeFilter(e, 'tags')"
        >
        </v-combobox>
        <v-spacer></v-spacer>
        <v-btn icon color="black" dark v-on:click="onClickSearch">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card-actions>
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex v-for="sound in resourceStore.resourcesList" :key="sound._id">
            <v-card>
              <v-card-title>{{ sound.name }}</v-card-title>
              <v-card-text>
                <aplayer
                  mutex
                  mini
                  :music="{
                    src: sound.sound_uri,
                  }"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="exploreMode"
                  v-clipboard:success="onClickCopies"
                  v-clipboard:copy="sound.sound_uri"
                  :color="sound.sound_uri === value ? 'green' : 'grey'"
                  >Select</v-btn
                >
                <v-btn
                  v-else
                  v-on:click="onSelect(sound.sound_uri)"
                  :color="sound.sound_uri === value ? 'green' : 'grey'"
                  >Select</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-card v-if="resourceType === 'video'">
      <v-card-actions>
        <v-text-field
          append-icon="search"
          label="Tag"
          v-on:change="e => onChangeFilter(e, 'tag')"
          solo
          v-model="filter.tag"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="search"
          label="File Name"
          v-on:change="e => onChangeFilter(e, 'name')"
          solo
          v-model="filter.name"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-combobox
          v-model="filter.tags"
          hide-selected
          solo
          hint="Maximum of 5 tags"
          label="Tags"
          multiple
          small-chips
          v-on:change="e => onChangeFilter(e, 'tags')"
        >
        </v-combobox>
        <v-spacer></v-spacer>
        <v-btn icon color="black" dark v-on:click="onClickSearch">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-layout>
          <v-flex
            class="mr-2"
            v-for="video in resourceStore.resourcesList"
            :key="video._id"
          >
            <v-card>
              <v-card-text>
                <video width="300" height="200" controls>
                  <source :src="video.video_uri" />
                </video>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="exploreMode"
                  v-clipboard:success="onClickCopies"
                  v-clipboard:copy="video.video_uri"
                  :color="video.video_uri === value ? 'green' : 'grey'"
                  >Select</v-btn
                >
                <v-btn
                  v-else
                  v-on:click="onSelect(video.video_uri)"
                  :color="video.video_uri === value ? 'green' : 'grey'"
                  >Select</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Aplayer from 'vue-aplayer';
import Modal from './Modal';
import Constants from '../constants';

export default {
  name: 'ResourceSelector',
  components: {
    Modal,
    Aplayer,
  },
  props: [
    'resourceType',
    'isShow',
    'close',
    'save',
    'value',
    'onSelect',
    'exploreMode',
    'music',
  ],
  data: function () {
    return {
      title: {
        image: 'Image Select',
        sound: 'Sound Select',
        video: 'Video Select',
      },
      type: 'confirm',
      filter: {},
      colors: Constants.COLORS.map(color => ({
        value: color.text,
        text: color.text,
      })),
    };
  },
  computed: {
    ...mapState({
      resourceStore: state => state.resource,
    }),
  },
  methods: {
    ...mapActions('resource', ['fetchResources', 'searchTags']),
    onClickCopies() {
      alert('Copied to Clipboard');
    },
    searchByTag() {
      if (Array.isArray(this.filter.tags) && this.filter.tags.length) {
        this.searchTags({
          type: this.resourceType,
          tags: this.filter.tags,
        });
      }
    },
    onChangeFilter(e, key) {
      if (key === 'tags') {
        this.searchByTag();
      } else {
        if (e) {
          this.filter = {
            ...this.filter,
            [key]: e,
          };
        } else {
          delete this.filter[key];
        }
        this.fetchResources({
          type: this.resourceType,
          page: 1,
          limit: 30,
          filter: this.filter,
        });
      }
    },
    onClickSearch() {
      this.fetchResources({
        type: this.resourceType,
        page: 1,
        limit: 30,
        filter: this.filter,
      });
    },
  },
  mounted() {
    this.fetchResources({
      type: this.resourceType,
      page: 1,
      limit: 30,
    });
  },
};
</script>
