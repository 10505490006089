<template>
  <div>
    <v-flex>
      <v-text-field
        label="System Phonic"
        :value="speakingWord"
        v-model="speakingWord"
        disabled
      />
      <v-btn
        color="black"
        dark
        @click="$emit('getSpeakingWord')"
        :disabled="validated == '' || null"
      >
        System Phonic Chinese
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
export default {
  props: {
    speakingWord: {
      type: String,
      require: true,
      // default: true,
    },
    validated: {
      type: String,
      // default: false,
    },
    language: {
      type: String,
      require: true,
      // default: true,
    },
  },
};
</script>
