export default {
  theme: { FOREST: 0, DESERT: 1, SEA: 2 },
  difficulty: { EASY: 0, NORMAL: 1, HARD: 2 },
  control: { STACK: 'STACK', DIRECTION: 'DIRECTION' },
  themeDropdown: [
    { name: 'FOREST', value: 0 },
    { name: 'DESERT', value: 1 },
    { name: 'SEA', value: 2 },
  ],
  difficultyDropdown: [
    { name: 'EASY', value: 0 },
    { name: 'NORMAL', value: 1 },
    { name: 'HARD', value: 2 },
  ],
  controlDropdown: [
    { name: 'STACK', value: 'STACK' },
    { name: 'DIRECTION', value: 'DIRECTION' },
  ],

  // { MAXI: 0,JUNGLE:1, SPACE:0},
  // { name: 'NANA', value: 1 },
  // { name: 'NODDLE', value: 2 },
};
