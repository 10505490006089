import moment from 'moment';
import http from '../../utils/httpClient';
import swal from 'sweetalert2';

const state = {
  category: [],
  isLoadCategory: false,
  selectCategory: {},
  updateCategory: {},
  createCategory: {},
};
const getters = {};
const actions = {
  fetchCategory({ commit, dispatch }, { page = 1, limit = 0 }) {
    http
      .GET(`/api/category?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(cateogory => ({
          ...cateogory,
          created_at: moment(cateogory.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(cateogory.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeCategory', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch categories in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectedCategory', id);
  },
  editCategory({ commit }, { key, value }) {
    commit('storeEditCategory', {
      key,
      value,
    });
  },
  editCreateCategory({ commit }, { key, value }) {
    commit('storeCreateCategory', {
      key,
      value,
    });
  },
  clearSelected({ commit }) {
    commit('clearSelectState');
  },
  clearCreate({ commit }) {
    commit('clearCreateState');
  },
  updateCategory({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'info',
          message: 'Updating category',
        },
        {
          root: true,
        },
      );
      http
        .PUT('/api/category', { ...updateData }, { isAuthorization: true })
        .then(() => {
          dispatch('fetchCategory', {});
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'success',
              message: 'Update complete',
            },
            {
              root: true,
            },
          );
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'error',
              message: 'Update Error',
            },
            {
              root: true,
            },
          );
          throw new Error(err);
        });
    }
  },
  createCategory({ dispatch }, createCategory) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating category',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/category', createCategory, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchCategory', {});
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Category complete',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteCategory({ dispatch }, { id }) {
    swal
      .fire({
        titleText: 'Confirm Delete Category',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/category?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchCategory', {});
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
              throw new Error(err);
            });
        }
      });
  },
};
const mutations = {
  storeCategory(state, category) {
    state.category = category.map(res => ({
      ...res,
      created_at: moment
        .unix(res.created_at * 0.001)
        .format('MMMM D YYYY h:mm a'),
      updated_at: moment
        .unix(res.updated_at * 0.001)
        .format('MMMM D YYYY h:mm a'),
    }));
  },
  storeIsloadCategory(state) {
    state.isLoadCategory = !state.isLoadCategory;
  },
  storeSelectedCategory(state, selectCategoryId) {
    const index = state.category.findIndex(
      category => category._id === selectCategoryId,
    );
    state.selectCategory = {
      ...state.category[index],
    };
  },
  storeEditCategory(state, { key, value }) {
    state.updateCategory = {
      ...state.updateCategory,
      [key]: value,
    };
  },

  clearSelectState(state) {
    state.selectCategory = {};
    state.updateCategory = {};
  },

  storeCreateCategory(state, { key, value }) {
    state.createCategory = {
      ...state.createCategory,
      [key]: value,
    };
  },

  clearCreateState(state) {
    state.createCategory = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
