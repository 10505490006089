import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  library: [],
  selectLibrary: {},
  selectedCategoryId: '',
  updateWordData: {},
  updateGroupData: {},
  isUpdating: false,
  wordData: {
    word: '',
    img_uri: '',
    sound_uri: '',
    homophone: '',
    plural: '',
    article: '',
    pronoun: 0,
  },
  addWordData: {
    word: '',
    img_uri: '',
    sound_uri: '',
    homophone: '',
    plural: '',
    article: '',
    pronoun: 0,
  },
  addGroupData: {
    name: '',
  },
  colorData: {},
  isValidWord: false,
};
const getters = {};
const actions = {
  validateDuplicateWord({ commit, dispatch }, word) {
    http
      .GET(`/api/library/word/validateWord?word=${word}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeDuplicateWord', response.data.valid);
      })
      .catch(error => {
        if (error.response.status === 409) {
          commit('storeDuplicateWord', error.response.data.valid);
        }
      });
  },
  fetchColorData({ commit, dispatch }) {
    http
      .GET(`/api/color`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeColor', response.data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  fetchLibrary({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/library?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(library => ({
          ...library,
          created_at: moment(library.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(library.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeLibrary', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch library in this time. Please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectLibrary({ commit }, id) {
    commit('storeSelectLibrary', id);
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleLibrary({ commit, dispatch }, id) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/library?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(library => ({
          ...library,
          created_at: moment(library.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(library.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeLibrary', formatData);
        commit('storeSelectLibrary', id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch library at this time. Please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  updateWordData({ commit }, { key, value }) {
    commit('storeUpdateWordData', {
      key,
      value,
    });
  },
  updateGroupData({ commit }, { key, value }) {
    commit('storeUpdateGroupData', {
      key,
      value,
    });
  },
  updateWord({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/library/word',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: data,
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleLibrary', state.selectLibrary.category_id);
        })
        .catch(error => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message:
                error.response.data.message ||
                'Failed to update word, please try again later.',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdateWordData');
  },

  deleteGroup({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Group',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/library/group?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleLibrary', state.selectLibrary.category_id);
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  deleteWord({ dispatch, state }, { groupId, wordId }) {
    swal
      .fire({
        titleText: 'Confirm Delete Word',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/library/word?_id=${wordId}&group_id=${groupId}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleLibrary', state.selectLibrary.category_id);
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  updateCreateWordData({ commit }, { key, value }) {
    commit('storeCreateWordData', {
      key,
      value,
    });
  },
  updateGroup({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT('/api/library/group', updateData, {
          isAuthorization: true,
        })
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Group success',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleLibrary', state.selectLibrary.category_id);
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Group failed please try again later',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  updateCreateGroupData({ commit }, { key, value }) {
    commit('storeCreateGroupData', {
      key,
      value,
    });
  },
  addGroup({ dispatch }, { category_id, ...data }) {
    const createGroupData = {
      category_id,
      group: {
        ...data.group,
      },
      word: { ...data.word },
    };
    http
      .POST('/api/library', createGroupData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Group success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('clearStoreCreateGroupData');
        dispatch('fetchSingleLibrary', category_id);
        router.go({
          path: '/library/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Group failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
      });
  },
  addWordByGroupId({ dispatch }, { category_id, group_id, ...data }) {
    const createWordData = {
      _id: group_id,
      word: { ...data.word },
    };
    http
      .POST('/api/library/group', createWordData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Word success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleLibrary', category_id);
        router.go({
          path: '/library/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: err.response.data.message,
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  addExistingWordByGroupId({ dispatch }, { category_id, group_id, ...data }) {
    const createWordData = {
      _id: group_id,
      ...data,
    };
    http
      .PUT('/api/library/group', createWordData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Word success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleLibrary', category_id);
        router.go({
          path: '/library/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: err.response.data.message,
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  clearStoreCreateGroupData({ commit }) {
    commit('clearStoreCreateGroupData');
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeLibrary(state, libraryList) {
    state.library = libraryList;
  },
  storeColor(state, colorList) {
    state.colorData = colorList;
  },
  storeDuplicateWord(state, word) {
    state.isValidWord = word;
  },
  storeSelectLibrary(state, value) {
    if (value) {
      const index = state.library.findIndex(
        library => library.category_id === value,
      );
      state.selectLibrary = state.library[index];
    } else {
      delete state.selectLibrary;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateWordData(state, { key, value }) {
    state.updateWordData = {
      ...state.updateWordData,
      [key]: value,
    };
  },
  storeCreateWordData(state, { key, value }) {
    state.addWordData = {
      ...state.addWordData,
      [key]: value,
    };
  },
  storeUpdateGroupData(state, { key, value }) {
    state.updateGroupData = {
      ...state.updateGroupData,
      [key]: value,
    };
  },
  storeCreateGroupData(state, { key, value }) {
    state.addGroupData = {
      ...state.addGroupData,
      [key]: value,
    };
  },
  clearStoreCreateGroupData(state) {
    state.addGroupData = { name: '' };
  },
  clearStoreUpdateWordData(state) {
    Object.keys(state.updateWordData).forEach(key => {
      if (key != '_id') {
        delete state.updateWordData[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
