import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import VueClipboard from 'vue-clipboard2';
import VJsoneditor from 'v-jsoneditor';
import './plugins/vuetify';
import './plugins/cookie';
import App from './App.vue';
import router from './router';
import store from './store/';
import './registerServiceWorker';
import { sync } from 'vuex-router-sync';
import '@fortawesome/fontawesome-free/css/all.css';

sync(store, router);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueLazyload);
Vue.use(VJsoneditor);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
