import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  tracing: [],
  selectTracing: {},
  selectedCategoryId: '',
  updateBlueprintData: {},
  selectedBlueprintIndex: -1,
  isUpdating: false,
  addBlueprintData: {
    text: '',
    lines: [[{ x: '', y: '' }]],
  },
};
const getters = {};
const actions = {
  fetchTracing({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/trace?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(trace => ({
          ...trace,
          created_at: moment(trace.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(trace.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeTracing', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch tracing at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectTracing({ commit }, id) {
    commit('storeSelectTracing', { id, sub_id: '' });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleTracing({ commit, dispatch }, { id, sub_id }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/trace?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(trace => ({
          ...trace,
          created_at: moment(trace.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(trace.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeTracing', formatData);
        commit('storeSelectTracing', { id, sub_id });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch trace at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  updateBlueprintData({ commit }, { key, value }) {
    commit('storeUpdateBlueprintData', {
      key,
      value,
    });
  },
  updateBlueprint({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/trace/blueprint',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Blueprint Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleTracing', {
            id: state.selectTracing.category_id,
            sub_id: '',
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update set. Please try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdateBlueprintData');
  },
  deleteBlueprint({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Blueprint',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/trace/blueprint?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleTracing', {
                id: state.selectTracing.category_id,
                sub_id: '',
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  updateCreateBlueprintData({ commit }, { key, value }) {
    commit('storeCreateBlueprintData', {
      key,
      value,
    });
  },
  addBlueprint({ dispatch }, { category_id, ...data }) {
    const createBlueprintData = {
      category_id,
      blueprint: {
        ...data.blueprint,
      },
    };
    http
      .POST('/api/trace', createBlueprintData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Blueprint success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleTracing', { id: category_id, sub_id: '' });
        router.go({
          path: '/trace/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Blueprint failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeTracing(state, traceList) {
    state.tracing = traceList;
  },
  storeSelectTracing(state, { id, sub_id }) {
    if (id) {
      const index = state.tracing.findIndex(
        tracing => tracing.category_id === id,
      );
      state.selectTracing = state.tracing[index];
      if (sub_id) {
        const blueprintIndex = state.selectTracing.blueprints.findIndex(
          blueprint => blueprint._id === sub_id,
        );
        state.selectedBlueprintIndex = blueprintIndex;
      }
    } else {
      delete state.selectTracing;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateBlueprintData(state, { key, value }) {
    state.updateBlueprintData = {
      ...state.updateBlueprintData,
      [key]: value,
    };
  },
  storeCreateBlueprintData(state, { key, value }) {
    if (value || value == 0) {
      state.addBlueprintData = {
        ...state.addBlueprintData,
        [key]: value,
      };
    } else {
      delete state.addBlueprintData[key];
    }
  },
  clearStoreUpdateBlueprintData(state) {
    Object.keys(state.updateBlueprintData).forEach(key => {
      if (key != '_id') {
        delete state.updateBlueprintData[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
