<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Resource Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" light v-on:click="onClickAddResource">
            <v-icon>add</v-icon>
            Add New {{ showType }}
          </v-btn>
          <v-btn
            v-if="showType == 'video'"
            color="success"
            light
            v-on:click="
              isExist = true;
              onClickAddResource();
            "
          >
            <v-icon>add</v-icon>Add Existing video
          </v-btn>
          <template v-slot:extension>
            <v-tabs dark centered slider-color="white">
              <v-tab
                dark
                v-for="i in typeList"
                :key="i"
                v-on:click="onChangeTab(i)"
                >{{ i }}</v-tab
              >
            </v-tabs>
          </template>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex xs3>
                <v-layout row>
                  <v-flex grow pa-1>
                    <v-radio-group v-model="searchSwitcher" row>
                      <v-radio label="Name" value="name"></v-radio>
                      <v-radio label="Legacy Tag" value="legacy_tag"></v-radio>
                      <v-radio label="Tag" value="tag"></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex shrink pa-1>
                    <v-switch
                      v-model="darkThemeSwitch"
                      label="Dark Mode"
                    ></v-switch>
                  </v-flex>
                </v-layout>
                <v-text-field
                  v-if="searchSwitcher === 'name'"
                  v-model="search"
                  label="Search by name"
                  append-icon="search"
                  v-on:keyup.enter="searchByName"
                ></v-text-field>
                <v-text-field
                  v-if="searchSwitcher === 'legacy_tag'"
                  v-model="search"
                  label="Search by legacy tag"
                  append-icon="search"
                  v-on:keyup.enter="searchByLegacyTag"
                ></v-text-field>
                <v-combobox
                  v-if="searchSwitcher === 'tag'"
                  v-model="searchtag.model"
                  :items="searchtag.items"
                  :search-input.sync="searchtag.search"
                  hide-selected
                  hint="Maximum of 10 tags"
                  label="Search by tag"
                  multiple
                  persistent-hint
                  small-chips
                  v-on:keyup.enter="searchByTag"
                >
                </v-combobox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-data-table
            v-if="!resourceStore.isLoadResouces"
            :items="resourceStore.resourcesList"
            :headers="headers[showType]"
            :search="search"
            :dark="darkThemeSwitch"
            :pagination.sync="pagination"
          >
            <template v-slot:items="props">
              <td>{{ props.item.name }}</td>
              <td v-if="showType === 'image' || showType === 'video'">
                <img
                  lazy="props.item.image_uri"
                  class="image_thumbnail"
                  :src="props.item.image_uri || props.item.thumbnail_uri"
                  :alt="props.item.name || props.item._id"
                />
              </td>
              <td v-if="showType === 'sound'">
                <aplayer
                  mini
                  mutex
                  :music="{
                    title: props.item.name,
                    artist: props.item.user_id,
                    src: props.item.sound_uri,
                  }"
                />
              </td>
              <td>{{ props.item.tag }}</td>
              <!-- legacy tag -->
              <td>
                <v-chip
                  v-if="formatDisplayTag(props.item.tags)"
                  color="primary"
                  text-color="white"
                  >{{ formatDisplayTag(props.item.tags) }}</v-chip
                >
                <v-chip
                  v-if="!formatDisplayTag(props.item.tags)"
                  color="grey"
                  text-color="white"
                  >tag not found</v-chip
                >
              </td>
              <!-- <td>{{ props.item.tags }}</td> -->
              <td v-if="showType === 'image'">{{ props.item.color }}</td>
              <td>
                <v-btn
                  icon
                  v-clipboard:copy="
                    props.item.image_uri ||
                    props.item.sound_uri ||
                    props.item.video_uri
                  "
                >
                  <v-icon>link</v-icon>
                </v-btn>
              </td>
              <td>{{ props.item.updated_at }}</td>
              <td>
                <v-layout align-content-space-between>
                  <v-btn
                    icon
                    color="black"
                    v-on:click="viewResource(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item._id)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <!-- Edit Item -->
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onSaveEdit"
      :close="closeEdit"
      title="Edit Resource"
    >
      <v-card>
        <v-img
          v-if="showType === 'image' || showType === 'video'"
          :src="
            resourceStore.selectResource.image_uri ||
            preview_img ||
            resourceStore.selectResource.thumbnail_uri
          "
          class="image_edit"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline" dark>
                  <v-btn
                    v-if="showType !== 'image'"
                    fab
                    icon
                    small
                    color="white"
                    v-on:click="toggleEditThumbnail"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    icon
                    small
                    v-on:click="
                      onClickViewImage(
                        resourceStore.selectResource.image_uri ||
                          resourceStore.selectResource.thumbnail_uri,
                      )
                    "
                    color="white"
                  >
                    <v-icon>fullscreen</v-icon>
                  </v-btn>
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-container>
          <v-layout grid-list-xs text-xs-center column>
            <!-- <v-flex v-if="resourceStore.selectResource.video_uri">
              <video width="800" height="450" controls>
                <source :src="resourceStore.selectResource.video_uri" />
              </video>
            </v-flex>
            <v-flex v-if="resourceStore.selectResource.sound_uri">
              <aplayer
                mutex
                :music="{
                  title: resourceStore.selectResource.name,
                  artist: resourceStore.selectResource.user_id,
                  src: resourceStore.selectResource.sound_uri,
                }"
              />
            </v-flex>
            <v-flex class="mt-2">
              <FileInput
                :accept="'video/*,image/*,audio/*'"
                :onSelect="onChangeContent"
                :filename="content_filename"
                placeholder="Select File"
              />
            </v-flex>-->
            <v-flex>
              <v-text-field
                label="Name"
                :value="resourceStore.selectResource.name"
                v-on:change="e => handleChangeField(e, 'name')"
              />
            </v-flex>
            <v-flex>
              <v-text-field
                disabled
                label="Tag"
                :value="resourceStore.selectResource.tag"
                v-model="resourceStore.selectResource.tag"
                v-on:change="e => handleChangeField(e, 'tag')"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-combobox
                :search-input.sync="tag.model"
                hide-selected
                :value="resourceStore.selectResource.tags"
                v-model="resourceStore.selectResource.tags"
                hint="Maximum of 10 tags"
                label="Add some tags"
                multiple
                persistent-hint
                small-chips
                v-on:change="e => handleChangeField(e, 'tags')"
              >
                <!-- <template v-slot:no-data>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        No results matching "<strong>{{ tag.search }}</strong
                        >". Press <kbd>enter</kbd> to create a new one
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template> -->
              </v-combobox>
            </v-flex>
            <v-flex v-if="resourceStore.selectResource.color">
              <v-select
                :items="constants.COLORS"
                label="Color"
                :value="resourceStore.selectResource.color"
                v-model="resourceStore.selectResource.color"
                v-on:change="e => handleChangeField(e, 'color')"
              ></v-select>
            </v-flex>
            <v-flex>
              <v-layout>
                <v-flex>
                  <v-text-field
                    v-if="
                      resourceStore.selectResource.image_uri ||
                      resourceStore.selectResource.sound_uri
                    "
                    label="Resource url"
                    :value="
                      resourceStore.selectResource.image_uri ||
                      resourceStore.selectResource.sound_uri
                    "
                  />
                  <v-text-field
                    v-if="resourceStore.selectResource.video_uri"
                    label="Resource url"
                    :value="resourceStore.selectResource.video_uri"
                    v-on:change="e => handleChangeField(e, 'video_uri')"
                  />
                  <v-btn
                    dark
                    color="black"
                    v-clipboard:copy="
                      resourceStore.selectResource.video_uri ||
                      resourceStore.selectResource.image_uri ||
                      resourceStore.selectResource.sound_uri
                    "
                    >Copy Clipboard</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </Modal>
    <!-- Create Resource -->
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeCreate"
      :isLastStep="tag.model.length > 0"
      title="Create Resource"
    >
      <v-card>
        <v-container>
          <v-layout justify-center column>
            <v-flex class="mt-2" v-if="isExist">
              <v-flex>
                <v-text-field
                  box
                  :value="resourceStore.createResource.file_name"
                  label="Name"
                  v-on:change="e => handleChangeCreateField(e, 'file_name')"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  box
                  :value="resourceStore.createResource.video_uri"
                  label="Video Link"
                  v-on:change="e => handleChangeCreateField(e, 'video_uri')"
                ></v-text-field>
              </v-flex>
            </v-flex>
            <v-flex class="mt-2" v-else>
              <FileInput
                :accept="'video/*,image/*,audio/*'"
                :onSelect="onChangeCreateContent"
                :filename="content_filename"
                placeholder="Choose content file (Max size: 2 MB)"
              />
            </v-flex>
            <v-flex class="mt-2" v-if="showType === 'video'">
              <FileInput
                :accept="'image/*'"
                :onSelect="onChangeCreateThumbnail"
                :filename="thumbnail_filename"
                placeholder="Choose image file (Max size: 2 MB)"
              />
            </v-flex>
            <v-combobox
              v-model="tag.model"
              :items="tag.items"
              :search-input.sync="tag.search"
              hide-selected
              hint="Maximum of 10 tags"
              label="Add some tags"
              multiple
              persistent-hint
              small-chips
              v-on:change="e => handleChangeCreateField(e, 'tags')"
            >
              <!-- <template v-slot:no-data>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      No results matching "<strong>{{ tag.search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template> -->
            </v-combobox>
            <v-flex v-if="showType === 'image'">
              <v-select
                :items="constants.COLORS"
                label="Color"
                :value="resourceStore.createResource.color"
                v-model="resourceStore.createResource.color"
                v-on:change="e => handleChangeCreateField(e, 'color')"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </Modal>
    <UploadDialog
      :isShow="isEditThumbnail"
      :close="cancelEditThumbnail"
      :save="onChangeThumbnail"
      :onSelect="onSelectThumbnail"
      title="Upload Thumbnail"
      :filename="thumbnail_filename"
      :accept="'image/*'"
    />
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import swal from 'sweetalert2';
import Aplayer from 'vue-aplayer';
import Container from '../components/Container';
import Modal from '../components/Modal';
import FileInput from '../components/FileInput';
import UploadDialog from '../components/UploadDialog';
import constants from '../constants';

export default {
  name: 'Resource',
  components: {
    Aplayer,
    Container,
    Modal,
    FileInput,
    UploadDialog,
  },
  data() {
    return {
      darkThemeSwitch: false,
      searchSwitcher: 'tag',
      pagination: {
        rowsPerPage: 25,
      },
      searchtag: {
        items: [],
        model: [],
        search: '',
      },
      tag: {
        items: [],
        model: [],
        search: '',
      },
      constants,
      search: '',
      content_filename: '',
      thumbnail_filename: '',
      thumbnail: '',
      preview_img: '',
      isExist: false,
      isEdit: false,
      isEditThumbnail: false,
      isCreate: false,
      typeList: ['image', 'video', 'sound'],
      showType: 'image',
      headers: {
        image: [
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: 'name',
          },
          { text: 'Image', value: 'image_uri', sortable: false },
          { text: 'Legacy Tag', value: 'tag', sortable: true },
          { text: 'Tag', value: 'tag', sortable: true },
          { text: 'Color', value: 'color', sortable: true },
          { text: 'Resource uri', value: 'tag', sortable: false },
          { text: 'Updated Date', value: 'updated_at', sortable: true },
          { text: '', value: '', sortable: false },
        ],
        sound: [
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: 'name',
          },
          { text: 'Sound', value: 'sound_uri', sortable: false },
          { text: 'Legacy Tag', value: 'tag', sortable: true },
          { text: 'Tag', value: 'tag', sortable: true },
          { text: 'Resource Uri', value: 'tag', sortable: false },
          { text: 'Update Date', value: 'updated_at', sortable: true },
          { text: '', value: '', sortable: false },
        ],
        video: [
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: 'name',
          },
          { text: 'Thumbnail', value: 'thumbnail_uri', sortable: false },
          { text: 'Legacy Tag', value: 'tag', sortable: true },
          { text: 'Tag', value: 'tag', sortable: true },
          { text: 'Resource Uri', value: 'tag', sortable: false },
          { text: 'Update Date', value: 'updated_at', sortable: true },
          { text: '', value: '', sortable: false },
        ],
      },
    };
  },
  watch: {
    'resourceStore.selectResource.tags'(val) {
      if (val.length > 10) {
        this.$nextTick(() => this.resourceStore.selectResource.tags.pop());
      }
    },
    'tag.model'(val) {
      if (val.length > 10) {
        this.$nextTick(() => this.tag.model.pop());
      }
    },
    'searchtag.model'(val) {
      if (val.length > 10) {
        this.$nextTick(() => this.searchtag.model.pop());
      }
    },
    searchSwitcher(val) {
      this.clearResourceState();
      if (val === 'tag') {
        this.search = '';
      }
      if (val === 'name' || val === 'legacy_tag') {
        this.searchtag.model = '';
      }
    },
  },
  methods: {
    ...mapActions('appConfig', ['toggleAlert']),
    ...mapActions('resource', [
      'fetchResources',
      'deleteResources',
      'selectResource',
      'editResource',
      'clearSelected',
      'updateResource',
      'editResourceInfo',
      'editCreateResource',
      'clearCreate',
      'createResource',
      'searchTags',
      'searchByKey',
      'clearResourceState',
    ]),
    formatDisplayTag(value) {
      if (value) {
        return value.toString().split(',').join(' | ');
      } else {
        return null;
      }
    },
    searchByName() {
      if (this.search) {
        this.searchByKey({
          type: this.showType,
          key: this.searchSwitcher,
          value: this.search,
        });
      } else {
        this.clearResourceState();
      }
    },
    searchByLegacyTag() {
      if (this.search) {
        this.searchByKey({
          type: this.showType,
          key: 'tag',
          value: this.search,
        });
      } else {
        this.clearResourceState();
      }
    },
    searchByTag() {
      if (Array.isArray(this.searchtag.model) && this.searchtag.model.length) {
        this.searchTags({
          type: this.showType,
          tags: this.searchtag.model,
        });
      } else {
        this.clearResourceState();
      }
    },
    onClickDelete(id) {
      this.deleteResources({ id, currentType: this.showType });
    },
    async onChangeTab(tab) {
      this.showType = tab;
      const hasWordInSearchField =
        (Array.isArray(this.searchtag.model) && this.searchtag.model.length) ||
        this.search;
      if (hasWordInSearchField) {
        if (this.searchSwitcher === 'tag') {
          await this.searchByTag();
        } else if (this.searchSwitcher === 'name') {
          await this.searchByName();
        } else {
          await this.searchByLegacyTag();
        }
      } else {
        this.clearResourceState();
      }
    },
    viewResource(id) {
      this.isEdit = !this.isEdit;
      this.selectResource(id);
      this.editResource({ key: 'id', value: id });
    },
    closeEdit() {
      this.isEdit = false;
      this.clearSelected();
    },
    closeCreate() {
      this.isExist = false;
      this.isCreate = false;
      this.clearCreate();
    },
    closeCrop() {
      this.isCropImage = false;
    },
    onChangeContent(e) {
      if (this.showType === 'video') {
        if (e.target.files[0].type.includes('image')) {
          swal.fire('Type file is invalid', 'please choose again', 'warning');
        } else {
          this.editResource({ key: 'content', value: e.target.files[0] });
        }
      } else {
        this.editResource({ key: 'content', value: e.target.files[0] });
      }
      this.content_filename = e.target.files[0].name;
      this.editResource({ key: 'content', value: e.target.files[0] });
      this.editResource({ key: 'contentType', value: e.target.files[0].type });
    },

    onChangeCreateContent(e) {
      const selectedFileName = e.target.files[0].name;
      const currentResources = this.resourceStore.resourcesList;
      const filteredNameList = [];
      if (currentResources) {
        currentResources.forEach(resources => {
          filteredNameList.push(resources.name);
        });
      }
      if (
        filteredNameList.indexOf(selectedFileName) > -1 ||
        filteredNameList.includes(selectedFileName)
      ) {
        swal.fire({
          title: 'Filename is exist, please rename your file.',
          type: 'warning',
        });
        this.closeCreate();
      } else {
        if (this.showType === 'video') {
          if (
            e.target.files[0].type === 'image/png' ||
            e.target.files[0].type === 'image/jpg'
          ) {
            swal.fire('Type file is invalid', 'please choose again', 'warning');
            this.closeCreate();
          } else {
            this.editCreateResource({
              key: 'content',
              value: e.target.files[0],
            });
          }
        } else if (this.showType === 'sound') {
          if (e.target.files[0].type !== 'audio/mpeg') {
            swal.fire(
              'Invalid file extension',
              'Accept only mp3 or (audio/mpeg), please try again',
              'warning',
            );
            this.closeCreate();
          } else {
            this.editCreateResource({
              key: 'content',
              value: e.target.files[0],
            });
          }
        } else {
          this.editCreateResource({ key: 'content', value: e.target.files[0] });
        }
        this.content_filename = e.target.files[0].name;
        this.editCreateResource({
          key: 'file_name',
          value: e.target.files[0].name,
        });
        this.editCreateResource({
          key: 'contentType',
          value: e.target.files[0].type,
        });
      }
    },
    onClickViewImage(link) {
      window.open(link, '_blank');
    },
    toggleEditThumbnail() {
      this.isEditThumbnail = !this.isEditThumbnail;
    },
    cancelEditThumbnail() {
      this.preview_img = '';
      this.toggleEditThumbnail();
    },
    onChangeThumbnail() {
      this.toggleEditThumbnail();
      this.editResource({ key: 'thumbnail', value: this.thumbnail });
    },
    onSelectThumbnail(e) {
      this.thumbnail = e.target.files[0];
      this.preview_img = URL.createObjectURL(this.thumbnail);
      this.thumbnail_filename = e.target.files[0].name;
    },
    onChangeCreateThumbnail(e) {
      this.thumbnail_filename = e.target.files[0].name;
      this.editCreateResource({ key: 'thumbnail', value: e.target.files[0] });
    },
    onSaveEdit() {
      const updateResource = this.resourceStore.updateResource;
      const type = this.showType;
      if (this.searchSwitcher === 'tag') {
        this.editResourceInfo({ ...updateResource, type });
        this.searchByTag();
      } else if (this.searchSwitcher === 'name') {
        this.editResourceInfo({ ...updateResource, type });
        this.searchByName();
      } else {
        this.editResourceInfo({ ...updateResource, type });
        this.searchByLegacyTag();
      }
      this.isEdit = false;
    },
    clearWhitespace(value) {
      return value.split(' ').join('');
    },
    handleChangeField(value, key) {
      if (key === 'tags') {
        let noneWhitespace = [];
        value.forEach(v => {
          value = this.clearWhitespace(v);
          noneWhitespace.push(value);
        });
        this.resourceStore.selectResource.tags = noneWhitespace;
        this.editResource({ key, value: noneWhitespace });
      } else {
        this.editResource({ key, value });
      }
    },
    handleChangeCreateField(value, key) {
      if (key === 'tags') {
        let noneWhitespace = [];
        value.forEach(v => {
          value = this.clearWhitespace(v);
          noneWhitespace.push(value);
        });
        this.tag.model = noneWhitespace;
        this.editCreateResource({ key, value: noneWhitespace });
      } else {
        this.editCreateResource({ key, value });
      }
    },
    onSaveCreate() {
      let validateFields = [];
      if (this.showType === 'image') {
        validateFields = ['tags', 'color', 'content'];
      } else if (this.showType === 'video') {
        validateFields = ['tags'];
      }
      const createResourceData = { ...this.resourceStore.createResource };
      const validateResult = validateFields.map(
        field =>
          createResourceData.hasOwnProperty(field) &&
          createResourceData[field] !== '',
      );
      if (validateResult.includes(false)) {
        swal.fire('Please complete the form', '', 'info');
      } else {
        this.createResource(createResourceData);
        this.searchSwitcher = 'tag';
        this.searchtag.model = createResourceData.tags;
        this.isCreate = false;
      }
      this.isExist = false;
    },
    onClickAddResource() {
      this.content_filename = '';
      this.thumbnail_filename = '';
      this.tag.model = '';
      this.isCreate = true;
      this.clearCreate();
      this.editCreateResource({
        key: 'type',
        value: this.showType,
      });
      const { userData } = this.userInfoStore;

      this.editCreateResource({
        key: 'user_id',
        value: userData._id,
      });
    },
  },
  mounted: function () {},

  computed: {
    ...mapState({
      resourceStore: state => state.resource,
      userInfoStore: state => state.userInfo,
    }),
  },
};
</script>
<style>
.image_thumbnail {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.image_edit {
  height: 200px;
}
</style>
