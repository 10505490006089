export const ROLE_NAME = {
  Developer: 'developer',
  Media: 'media',
  Marketing: 'marketing',
  ContentCreator: 'content-creator',
  CustomerService: 'customer-service',
  Translator: 'translator',
  Customer: 'customer',
  Reseller: 'reseller',
  Schoolversion: 'school-version',
  Admin: 'admin',
};

export const ROLE_NUMBER = {
  Developer: 99,
  Media: 98,
  Marketing: 97,
  ContentCreator: 96,
  CustomerService: 51,
  Translator: 50,
  Customer: 2,
  Reseller: 30,
  Schoolversion: 10,
  Admin: 100,
};
