export default {
  required: value => {
    if (value === 0) return true;
    return !!value || 'Required.';
  },
  validtag: value => {
    if (value instanceof Array && value.length == 0) return 'Required';
    return !!value || 'Required.';
  },
  validURI: value => {
    if (/^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/.test(value)) return true;
    else if (
      /[ ]/.test(value) ||
      value === '' ||
      /[’]/.test(value) ||
      value == undefined
    )
      return true;
    else return 'URI must be valid';
  },
  numberRule: value => {
    if (!isNaN(parseFloat(value)) && value >= -1) return true;
    return 'The number must be greater than equal -1';
  },
  numberRowColumn: value => {
    if (!isNaN(parseFloat(value)) && value >= 1) return true;
    return 'The number must be greater than equal 1';
  },
  numberFloat: value => {
    if (!isNaN(parseFloat(value)) && value >= 0 && value <= 1) return true;
    return 'The number are between 0-1.';
  },
  isDecimal: value => {
    if (!isNaN(parseFloat(value)) && value.toString().includes('.'))
      return 'The number is demical.';
    return true;
  },
  validVideo: value => {
    if (value === '' || value === undefined) {
      return true;
    }
    if (value !== undefined) {
      if (value.includes('.mp4')) return true;
      else return 'file .mp4 only.';
    } else return '';
  },
  isNumber: value => {
    if (!isNaN(parseFloat(value))) return true;
    return 'Number Only';
  },
  word: value => {
    const pattern = /[\w]/g;
    return pattern.test(value) || 'Invalid word';
  },
  wordEN: value => {
    const pattern = /^[^-\sก-๛๐-๙0-9._#-][a-zA-Z0-9._#-\s-]*$/gm;
    return pattern.test(value) || 'Invalid word';
  },
  wordTH: value => {
    const pattern = /^[^-\sa-zA-Z0-9._#-][ก-๛๐-๙0-9._#-\s-]*$/gm;
    return pattern.test(value) || 'Invalid word';
  },
};
