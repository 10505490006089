<template>
  <Modal
    v-if="isOpenSticker"
    :isOpen="isOpenSticker"
    :close="closeModalSticker"
    :isLastStep="data.length > 0"
    :save="saveModalEditSticker"
    type="create"
    title="Page Index : Stickers"
  >
    <div>
      <v-btn icon color="green" v-on:click="toggleAddSticker">
        <v-icon>add</v-icon>
      </v-btn>

      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="n in data.length">
            <v-stepper-step
              :key="`${n}-step`"
              :complete="currentStep > n"
              :step="n"
              editable
            >
              <!-- Sticker {{ n }} -->
            </v-stepper-step>

            <v-divider v-if="n !== data.length" :key="n"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-for="n in data.length"
            :key="`${n}-content`"
            :step="n"
          >
            <v-form>
              <v-card v-if="currentStep - 1 > -1">
                <v-toolbar dark>
                  <v-toolbar-title>Image {{ currentStep }}</v-toolbar-title>
                  <v-btn
                    color="error"
                    small
                    absolute
                    fab
                    right
                    @click="onClickRemoveStickerByIndex(currentStep - 1)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container v-if="data[currentStep - 1]">
                  <v-layout>
                    <v-flex>
                      <div>
                        <img
                          :src="data[currentStep - 1].img_uri"
                          class="content_img"
                        />
                        <v-text-field
                          label="Image URI (URL or Select file)"
                          :value="data[currentStep - 1].img_uri"
                          v-model="data[currentStep - 1].img_uri"
                        ></v-text-field>
                      </div>
                      <v-btn v-on:click="isEditImage = true">
                        <v-icon>image</v-icon>Select Image
                      </v-btn>
                      <SpriteSheet
                        :title="'Use Sprite Sheet'"
                        :isToggleContainerSprite="isSpriteSheet"
                        :object="data[currentStep - 1].sprite_sheet_data"
                        :type="'book_sticker'"
                        :currentStep="currentStep - 1"
                        @onchangeSprite="onChangeUseSpriteSheet"
                        @saveToggleSpriteSheet="saveSpiteSheet"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <v-text-field
                        type="number"
                        label="startX"
                        :value="data[currentStep - 1].startX"
                        v-model="data[currentStep - 1].startX"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        type="number"
                        label="endX"
                        :value="data[currentStep - 1].endX"
                        v-model="data[currentStep - 1].endX"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <v-text-field
                        type="number"
                        label="startY"
                        :value="data[currentStep - 1].startY"
                        v-model="data[currentStep - 1].startY"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        type="number"
                        label="endY"
                        :value="data[currentStep - 1].endY"
                        v-model="data[currentStep - 1].endY"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="currentStep - 1 > 0"
                  color="red"
                  dark
                  @click="onClickBackStep()"
                  >Previous</v-btn
                >
                <v-btn
                  v-if="currentStep - 1 < data.length - 1"
                  color="primary"
                  dark
                  @click="onClickNextStep()"
                  >Next</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
  </Modal>
</template>

<script>
import Modal from '../components/Modal.vue';
import swal from 'sweetalert2';
import ResourceSelect from './ResourceSelect';
import { mapActions } from 'vuex';
import SpriteSheet from '../components/SpriteSheet.vue';

export default {
  name: 'ModalStickers',
  components: {
    Modal,
    ResourceSelect,
    SpriteSheet,
  },
  data() {
    return {
      currentStep: 1,
      isEditImage: false,
      selectImageUri: '',
      isSpriteSheet: [],
      data: [],
      backupData: [],
      addDataSticker: [],
    };
  },
  props: {
    isOpenSticker: {
      type: Boolean,
      default: false,
      required: true,
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
    // closeSticker: {
    //   type: Function,
    //   default: () => {},
    // },
  },
  methods: {
    // ...mapActions('books', ['updatePageData']),
    toggleAddSticker() {
      this.data.push({
        // _id: this.genId(),
        img_uri: '',
        startX: '0',
        startY: '0',
        endX: '0',
        endY: '0',
        is_sprite_sheet: false,
        sprite_sheet_data: {
          column: 1,
          row: 1,
          frame_ms: 0,
          loop_count: -1,
        },
      });
      this.currentStep = this.data.length;
    },
    saveSpiteSheet({ value }) {
      this.data[this.currentStep - 1].sprite_sheet_data = value;
    },
    onChangeUseSpriteSheet() {
      this.data[this.currentStep - 1].is_sprite_sheet =
        this.isSpriteSheet[this.currentStep - 1];
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
      this.data[this.currentStep - 1].img_uri = uri;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    onClickRemoveStickerByIndex(index) {
      swal
        .fire({
          titleText: `Remove data at index ${index + 1}?`,
          text: 'This operation cannot be undone. Would you like to proceed?',
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.data.splice(index, 1);
            if (this.currentStep - 1 > 0) {
              this.currentStep -= 1;
            }
          }
        });
    },
    saveModalEditSticker() {
      this.$emit('saveModalEditSticker', [...this.data]);
    },
    onClickNextStep() {
      if (this.currentStep - 1 < this.data.length - 1) {
        this.currentStep += 1;
      }
    },
    onClickBackStep() {
      if (this.currentStep - 1 >= 0) {
        this.currentStep -= 1;
      }
    },
    closeModalSticker() {
      this.$emit('closeModalSticker', this.backupData);
    },
    genId() {
      const ObjectId = (
        m = Math,
        d = Date,
        h = 16,
        s = s => m.floor(s).toString(h),
      ) =>
        s(d.now() / 1000) +
        ' '.repeat(h).replace(/./g, () => s(m.random() * h));
      return ObjectId();
    },
  },
  created() {
    this.backupData = JSON.parse(JSON.stringify(this.dataArray));
    if (this.dataArray.length) {
      // let copyData = [];
      // for (const e of this.dataArray) {
      //   copyData = [...copyData, { ...e }];
      // }
      this.data = [...this.dataArray];
      this.data.forEach(e => {
        this.isSpriteSheet.push(e.is_sprite_sheet);
      });
    } else {
      this.data.push({
        // _id: this.genId(),
        img_uri: '',
        startX: '0',
        startY: '0',
        endX: '0',
        endY: '0',
        is_sprite_sheet: false,
        sprite_sheet_data: {
          column: 1,
          row: 1,
          frame_ms: 0,
          loop_count: -1,
        },
      });
      this.isSpriteSheet.push(false);
    }
  },
};
</script>
