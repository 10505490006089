export const mediaMenu = [
  {
    icon: 'audiotrack',
    title: 'Resource',
    path: '/resource',
  },
  {
    icon: 'movie',
    title: 'Media',
    path: '/media',
  },
  {
    icon: 'list',
    title: 'Level Manager',
    path: '/level',
  },
  {
    icon: 'import_contacts',
    title: 'Library',
    path: '/library',
  },
  {
    icon: 'list_alt',
    title: 'Dictionary',
    path: '/dictionary',
  },
  {
    icon: 'list_alt',
    title: 'Dictionary - Phonic',
    path: '/dicphonic',
  },
  {
    icon: 'timeline',
    title: 'Learning Path',
    path: '/learningpaths',
  },
  {
    icon: 'timeline',
    title: 'Story Mode - Game',
    path: '/storygames',
  },
  {
    icon: 'timeline',
    title: 'Story Mode - Video',
    path: '/storyvideos',
  },
  {
    icon: 'apps',
    title: 'Category',
    path: '/category',
  },
  {
    icon: 'book',
    title: 'Book',
    path: '/books',
  },
  {
    icon: 'widgets',
    title: 'Connecting Node',
    path: '/connectingnode',
  },
  {
    icon: 'get_app',
    title: 'Drag And Drop',
    path: '/draganddrop',
  },
  {
    icon: 'view_module',
    title: 'Match Card',
    path: '/matchcards',
  },
  {
    icon: 'mic',
    title: 'Mic Popup',
    path: '/micpopup',
  },
  {
    icon: 'view_module',
    title: 'Mix And Match',
    path: '/mixandmatch',
  },
  {
    icon: 'compare_arrows',
    title: 'Swipe',
    path: '/swipe',
  },
  {
    icon: 'label',
    title: 'Labeling',
    path: '/labeling',
  },
  {
    icon: 'insert_emoticon',
    title: 'Tap To Say',
    path: '/taptosay',
  },
  {
    icon: 'rounded_corner',
    title: 'Tracing',
    path: '/trace',
  },
  {
    icon: 'rounded_corner',
    title: 'TracingBezier',
    path: '/tracebezier',
  },
  {
    icon: 'accessibility',
    title: 'Quiz To Go',
    path: '/wordwalks',
  },
  {
    icon: 'chat',
    title: 'Mini Chat',
    path: '/minichat',
  },
  {
    icon: 'chat',
    title: 'Galaxy Chat',
    path: '/galaxychat',
  },
  {
    icon: 'sports_esports',
    title: 'Flying Game',
    path: '/flyinggame',
  },
  {
    icon: 'insert_emoticon',
    title: 'Tap Story',
    path: '/tapstory',
  },
];
