<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/micpopup')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title
            >Mic Popup :
            {{
              (micpopupStore.selectMicPopUp &&
                micpopupStore.selectMicPopUp.category) ||
              'Loading...'
            }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text v-if="micpopupStore.selectMicPopUp">
          <v-layout row grid-list-md v-if="!micpopupStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Mic Activity</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddActivity">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(activity, index) in micpopupStore.selectMicPopUp
                      .activities"
                    :key="activity._id"
                    v-on:click="onSelectActivity(activity._id)"
                    :class="
                      index === selectedActivityIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ activity.name }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs9 mr-10 v-if="selectedActivityIndex != -1">
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Mic Activity Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickUpdateActivity">
                    <v-icon>save</v-icon>Save Mic Activity
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <CopyIdForm
                        :game_id="getGameId"
                        :category_id="getCategoryId"
                      ></CopyIdForm>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Available for Free User"
                        v-model="activity.isGuestPlayable"
                        :value="activity.isGuestPlayable"
                        v-on:change="
                          e => onChangeActivityData('isGuestPlayable', e)
                        "
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Name"
                        :value="activity.name"
                        v-on:change="e => onChangeActivityData('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Word"
                        :value="activity.word"
                        v-on:change="e => onChangeActivityData('word', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Question"
                        :value="activity.question"
                        v-on:change="e => onChangeActivityData('question', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="constants.CARD_TYPE"
                        label="Activity Type"
                        :value="activity.type"
                        v-model="activity.type"
                        v-on:change="e => onChangeActivityData('type', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        v-if="activity.sound_uri"
                        class="white--text"
                        color="subtitle_sound"
                        @click="openSubtitleSound()"
                        >Subtitle Sound
                      </v-btn>
                    </v-flex>
                    <v-flex
                      v-if="
                        activity.type == 'sound' ||
                        activity.type == 'image_sound'
                      "
                    >
                      <v-text-field
                        label="Sound for question (URL or Select file)"
                        :value="activity.sound_uri"
                        v-on:change="e => onChangeActivityData('sound_uri', e)"
                      />
                      <v-btn v-on:click="isEditSound = true">
                        <v-icon>audiotrack</v-icon>Select Sound
                      </v-btn>
                    </v-flex>
                    <v-flex
                      v-if="
                        activity.type == 'image' ||
                        activity.type == 'image_sound'
                      "
                    >
                      <img
                        v-if="activity.img_uri"
                        :src="activity.img_uri"
                        :alt="activity.activity"
                        class="content_img"
                      />
                      <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                          <v-text-field
                            label="Image (URL or Select file)"
                            :value="activity.img_uri"
                            v-on:change="
                              e => onChangeActivityData('img_uri', e)
                            "
                          />
                          <span class="headline" dark>
                            <v-btn v-on:click="isEditImage = true">
                              <v-icon>image</v-icon>Select Image
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music (URL or Select file)"
                        :value="activity.bgm_uri"
                        v-model="activity.bgm_uri"
                        v-on:change="e => onChangeActivityData('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          isEditBGM = true;
                        "
                        ><v-icon>audiotrack</v-icon>Select Background
                        Music</v-btn
                      >
                    </v-flex>

                    <!-- Background Image URI Update -->
                    <v-flex>
                      <img
                        v-if="activity.background_image_uri"
                        :src="activity.background_image_uri"
                        :alt="activity.background_image_uri"
                        class="content_img"
                      />
                      <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                          <v-text-field
                            label="Background Image (URL or Select file)"
                            :value="activity.background_image_uri"
                            v-on:change="
                              e =>
                                onChangeActivityData('background_image_uri', e)
                            "
                          />
                          <span class="headline" dark>
                            <v-btn
                              @click="
                                isEditImage = true;
                                isEditBackgroundImage = true;
                              "
                            >
                              <v-icon>image</v-icon>Select Background Image
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-btn
                    color="red"
                    dark
                    v-on:click="onClickDeleteActivity(activity._id)"
                  >
                    <v-icon>delete</v-icon>Delete this activity
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedActivityIndex != -1
          ? micpopupStore.selectMicPopUp.activities[selectedActivityIndex]
              .learned_words
          : null
      "
      :incidentalWords="
        selectedActivityIndex != -1
          ? micpopupStore.selectMicPopUp.activities[selectedActivityIndex]
              .incidental_words
          : null
      "
      :rewards="
        selectedActivityIndex != -1
          ? micpopupStore.selectMicPopUp.activities[selectedActivityIndex]
              .reward
          : null
      "
      :sentences="
        selectedActivityIndex != -1
          ? micpopupStore.selectMicPopUp.activities[selectedActivityIndex]
              .sentence
          : null
      "
      :phonics="
        selectedActivityIndex != -1
          ? micpopupStore.selectMicPopUp.activities[selectedActivityIndex]
              .phonic
          : null
      "
    >
    </PointData>
    <Modal
      :v-if="isAddActivity"
      :isOpen="isAddActivity"
      :close="toggleAddActivity"
      :save="onClickAddActivity"
      text="Add Activity"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex>
            <v-text-field
              label="Name"
              :value="activity.name"
              v-model="activity.name"
              v-on:change="e => onChangeCreateActivity('name', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Word"
              :value="activity.word"
              v-model="activity.word"
              v-on:change="e => onChangeCreateActivity('word', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Question"
              :value="activity.question"
              v-model="activity.question"
              v-on:change="e => onChangeCreateActivity('question', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.CARD_TYPE"
              label="Object Type"
              :value="activity.type"
              v-model="activity.type"
              v-on:change="e => onChangeCreateActivity('type', e)"
            ></v-select>
          </v-flex>
          <v-flex
            v-if="
              activity.type &&
              (activity.type == 'sound' || activity.type == 'image_sound')
            "
          >
            <v-text-field
              label="Sound for question (URL or Select file)"
              :value="activity.sound_uri"
              v-model="activity.sound_uri"
              disabled
            />
            <span class="headline" dark>
              <v-btn dark v-on:click="isEditSound = true">
                <v-icon>audiotrack</v-icon>Select Sound
              </v-btn>
            </span>
          </v-flex>
          <v-flex
            v-if="
              activity.type &&
              (activity.type == 'image' || activity.type == 'image_sound')
            "
          >
            <img
              v-if="activity.img_uri"
              :src="activity.img_uri"
              :alt="activity.activity"
              class="content_img"
            />
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <v-text-field
                  label="Image (URL or Select file)"
                  :value="activity.img_uri"
                  disabled
                />
                <span class="headline" dark>
                  <v-btn dark v-on:click="isEditImage = true">
                    <v-icon>image</v-icon>Select Image
                  </v-btn>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Background Music (URL or Select file)"
              :value="activity.bgm_uri"
              v-model="activity.bgm_uri"
              v-on:change="e => onChangeCreateActivity('bgm_uri', e)"
            ></v-text-field>
            <v-btn
              v-on:click="
                isEditSound = true;
                isEditBGM = true;
              "
              ><v-icon>audiotrack</v-icon>Select Background Music</v-btn
            >
          </v-flex>
          <!-- Background Image URI Create-->
          <v-flex>
            <img
              v-if="activity.background_image_uri"
              :src="activity.background_image_uri"
              :alt="activity.background_image_uri"
              class="content_img"
            />
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <v-text-field
                  label="Background Image (URL or Select file)"
                  :value="activity.background_image_uri"
                  v-on:change="
                    e => onChangeCreateActivity('background_image_uri', e)
                  "
                />
                <span class="headline" dark>
                  <v-btn
                    @click="
                      isEditImage = true;
                      isEditBackgroundImage = true;
                    "
                  >
                    <v-icon>image</v-icon>Select Background Image
                  </v-btn>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
</style>
<script>
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import constants from '../../constants';
import CopyIdForm from '../../components/CopyIdForm.vue';

export default {
  name: 'MicPopUp',
  components: {
    Container,
    ResourceSelect,
    Modal,
    PointData,
    CopyIdForm,
  },
  computed: {
    ...mapState({
      micpopupStore: state => state.micpopup,
      selectedActivityIndexStore: state => state.micpopup.selectedActivityIndex,
    }),
    getGameId() {
      const activityIndex = this.selectedActivityIndex;
      return this.micpopupStore.selectMicPopUp.activities[activityIndex]._id;
    },
    getCategoryId() {
      return this.micpopupStore.selectMicPopUp.category_id;
    },
  },
  data() {
    return {
      isEditBackgroundImage: false,
      constants,
      activity: [],
      // activity: {},
      selectedActivityIndex: -1,
      isEditImage: false,
      isEditSound: false,
      isEditBGM: true,
      selectImageUri: '',
      selectSoundUri: '',
      text: '',
      isDirty: false,
      isAddActivity: false,
      isUpdateActivity: false,
      selectActivityId: '',
      isOpenPointData: false,
    };
  },
  methods: {
    ...mapActions('micpopup', [
      'fetchMicPopUp',
      'fetchSingleMicPopUp',
      'clearUpdateData',
      'deleteActivity',
      'updateCreateActivityData',
      'updateActivityData',
      'updateActivity',
      'addActivity',
      'selectCategory',
      'selectMicPopUp',
    ]),
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.activity.sound_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.updateActivityData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateActivityData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateActivityData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateActivityData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateActivityData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateActivity();
      this.isOpenPointData = false;
    },

    async onSelectActivity(id) {
      this.clearUpdateData();
      const activity = this.micpopupStore.selectMicPopUp.activities;
      const index = activity.findIndex(activity => activity._id === id);
      this.selectedActivityIndex = index;
      this.activity = {
        ...activity[index],
      };
      this.updateActivityData({
        key: '_id',
        value: id,
      });
      this.selectActivityId = id;
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    toggleAddActivity() {
      this.updateCreateActivityData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.activity = { ...this.micpopupStore.addActivityData };
      this.isAddActivity = !this.isAddActivity;
      if (!this.isAddActivity && this.micpopupStore.selectedCategoryId) {
        this.$router.push('/micpopup');
      }
    },
    onClickAddActivity() {
      const id = this.$route.query.id;
      const createActivityData = {
        ...this.micpopupStore.addActivityData,
      };
      this.addActivity({
        category_id: id,
        activity: createActivityData,
      });
      this.isAddActivity = false;
    },
    onClickDeleteActivity(id) {
      this.deleteActivity(id);
      this.isDirty = false;
      this.selectedActivityIndex = -1;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddActivity && !this.isEditBackgroundImage) {
        this.updateCreateActivityData({
          key: 'img_uri',
          value: uri,
        });
        this.activity.img_uri = uri;
      } else if (!this.isAddActivity && this.isEditBackgroundImage) {
        this.updateActivityData({
          key: 'background_image_uri',
          value: uri,
        });
        this.activity.background_image_uri = uri;
        this.isEditBackgroundImage = !this.isEditBackgroundImage;
      } else if (this.isAddActivity && this.isEditBackgroundImage) {
        this.updateCreateActivityData({
          key: 'background_image_uri',
          value: uri,
        });
        this.activity.background_image_uri = uri;
        this.isEditBackgroundImage = !this.isEditBackgroundImage;
      } else {
        this.isDirty = true;
        this.updateActivityData({
          key: 'img_uri',
          value: uri,
        });
        this.activity.img_uri = uri;
      }
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isAddActivity && this.isEditBGM) {
        this.updateCreateActivityData({
          key: 'bgm_uri',
          value: uri,
        });
        this.activity.bgm_uri = uri;
      } else if (this.isAddActivity) {
        this.updateCreateActivityData({
          key: 'sound_uri',
          value: uri,
        });
        this.activity.sound_uri = uri;
      } else if (!this.isAddActivity && this.isEditBGM) {
        this.updateActivityData({
          key: 'bgm_uri',
          value: uri,
        });
        this.activity.bgm_uri = uri;
      } else {
        this.isDirty = true;
        this.updateActivityData({
          key: 'sound_uri',
          value: uri,
        });
        this.activity.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    onChangeActivityData(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.updateActivityData({
        key,
        value,
      });
    },
    onClickUpdateActivity() {
      const updateActivityData = {
        ...this.micpopupStore.updateActivityData,
      };
      this.updateActivity(updateActivityData);
      Object.keys(updateActivityData).forEach(key => {
        this.activity[key] = updateActivityData[key];
      });
    },
    onChangeCreateActivity(key, value) {
      this.updateCreateActivityData({
        key,
        value,
      });
      this.activity[key] = value;
    },
  },
  watch: {
    selectedActivityIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectActivity(this.$route.query.sub_id);
      }
    },
  },
  mounted() {
    if (this.$route.query.category_id) {
      this.fetchSingleMicPopUp({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (this.micpopupStore.selectedCategoryId) {
      this.toggleAddActivity();
    } else if (Object.keys(this.micpopupStore.selectMicPopUp).length < 1) {
      this.fetchSingleMicPopUp({ id: this.$route.query.id });
    }
  },
};
</script>
