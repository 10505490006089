<template>
  <Modal :isOpen="isShow" :close="close" :save="save" :title="title">
    <FileInput
      :onSelect="onSelect"
      :filename="filename"
      :accept="accept"
      placeholder="Choose file"
    />
  </Modal>
</template>

<script>
import Modal from './Modal';
import FileInput from './FileInput';
export default {
  name: 'UploadDialog',
  components: {
    Modal,
    FileInput,
  },
  props: ['onSelect', 'filename', 'isShow', 'close', 'save', 'title', 'accept'],
};
</script>
