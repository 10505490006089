<template>
  <v-dialog
    v-model="isOpen"
    persistent
    transition="slide-y-transition"
    width="60%"
  >
    <v-card v-if="categoryListStore.length">
      <v-toolbar dark>
        <v-toolbar-title> 🌈 {{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="red" fab icon v-on:click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list>
          <v-list-tile
            v-for="category in categoryStore.category"
            :key="category._id"
            v-on:click="() => onSelect(category._id)"
          >
            <v-list-tile-avatar>
              <v-icon>accessibility</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ category.category }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'Category',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Untitled',
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  watch: {
    isOpen(open) {
      if (open) {
        this.getCategoryList();
      }
    },
  },
  computed: {
    ...mapState({
      categoryStore: state => state.category,
      categoryListStore: state => state.category.category,
    }),
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    getCategoryList() {
      if (this.categoryListStore.length === 0) {
        return this.fetchCategory({
          page: 1,
          limit: 0,
          filter: this.filter,
        });
      }
    },
    onChangeFilter(e, key) {
      if (e) {
        this.filter = {
          ...this.filter,
          [key]: e,
        };
      } else {
        delete this.filter[key];
      }

      this.fetchCategory({
        page: 1,
        limit: 0,
        filter: this.filter,
      });
    },
  },
  mounted() {},
};
</script>
