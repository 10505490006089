<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Book Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="bookShelf in bookStore.bookshelf"
              :key="bookShelf._id"
              v-on:click="() => onClickEdit(bookShelf._id)"
            >
              <v-list-tile-avatar>
                <v-icon>book</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ bookShelf.category }} ({{
                    bookShelf.books.length
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ bookShelf.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
  </Container>
</template>
<script>
import Container from '../../components/Container';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'BookShelfList',
  components: {
    Container,
  },
  data: function () {
    return {};
  },
  methods: {
    ...mapActions('books', ['fetchBookShelf', 'selectBookshelf']),
    onClickEdit(id) {
      this.selectBookshelf(id);
      this.$router.push({ path: '/book/detail', query: { id } });
    },
  },
  mounted() {
    this.fetchBookShelf({ page: 1, limit: 0 });
  },
  computed: {
    ...mapState({
      bookStore: state => state.books,
    }),
  },
};
</script>
