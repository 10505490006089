import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  quizGameList: [],
  selectedQuizGame: {},
  createQuizGame: {},
  updateQuizGame: {},
  isLoadQuizGame: true,
  defaultCreate: {
    category_id: '',
    title: '',
    question_list: [
      {
        question_text: '',
        question_url: '',
        choice_type: 0,
        choice: ['', '', '', ''],
        answer_index: 0,
        answer_stt: '',
      },
    ],
  },
};
const getters = {};
const actions = {
  fetchQuizGameList({ commit, dispatch }) {
    const query = { params: { isWeb: true } };
    return http
      .GET(`/api/quiz_game`, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        // dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'quizGameList',
          value: data,
        });
      });
    // .catch(err => {
    //   dispatch(
    //     'appConfig/toggleAlert',
    //     {
    //       message: 'Cannot fetch Quiz game in this time. please try again.',
    //       type: 'error',
    //     },
    //     {
    //       root: true,
    //     },
    //   );
    //   throw new Error(err);
    // })
    // .finally(() => {
    //   dispatch('setStateByKey', {
    //     statekey: 'isLoadQuizGame',
    //     key: null,
    //     value: false,
    //   });
    // });
  },
  fetchQuizGameCategory(
    { commit, dispatch },
    { page = 1, limit = 0, quiz_game_id, game_list_id, category_id },
  ) {
    const query = {
      params: quiz_game_id
        ? { page, limit, quiz_game_id }
        : game_list_id
        ? { page, limit, id: game_list_id }
        : category_id
        ? { page, limit, category_id }
        : { page, limit, isWeb: true },
    };
    return http
      .GET(`/api/quiz_game/category`, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => {
          e = {
            ...e,
            created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
            updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
          };
          if (e.quiz_games) {
            e.quiz_games = e.quiz_games.map(data => ({
              ...data,
              created_at: moment(data.created_at).format('MMMM D YYYY h:mm a'),
              updated_at: moment(data.updated_at).format('MMMM D YYYY h:mm a'),
            }));
          }
          return { ...e };
        });
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'quizGameList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch Quiz game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadQuizGame',
          key: null,
          value: false,
        });
      });
  },
  createQuizGame({ commit, dispatch }, createData) {
    createData = createData ? createData : state.createQuizGame;
    http
      .POST(`/api/quiz_game`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const game_list_id = state.quizGameList[0]._id;
        if (game_list_id) {
          dispatch('fetchQuizGameCategory', { game_list_id });
        } else {
          router.go({
            path: '/quiz_game',
          });
          dispatch('fetchQuizGameCategory');
        }
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Quiz game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot create Quiz game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteQuizGame({ commit, dispatch }, id) {
    http
      .DELETE(`/api/quiz_game?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'updateQuizGame');
        const game_list_id = state.quizGameList[0]._id;
        dispatch('fetchQuizGameCategory', {
          game_list_id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete Quiz game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete quiz game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateQuizGame({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateQuizGame;
    delete updateData.created_at;
    delete updateData.updated_at;
    delete updateData.game_list_id;
    http
      .PUT(`/api/quiz_game`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const game_list_id = state.quizGameList[0]._id;
        dispatch('fetchQuizGameCategory', {
          game_list_id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update Quiz game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot update Quiz game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
