import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  mixandmatch: [],
  selectMixAndMatch: {},
  selectedDeckIndex: -1,
  selectedCategoryId: '',
  updateCardData: {},
  updateDeckData: {},
  isUpdating: false,
  cardData: {
    text: '',
    missing_index: '0',
  },
  addCardData: [
    {
      text: '',
      missing_index: '0',
    },
  ],
  addDeckData: {
    name: '',
  },
};
const getters = {};
const actions = {
  fetchMixAndMatch({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/mixandmatch?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(mixandmatch => ({
          ...mixandmatch,
          created_at: moment(mixandmatch.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(mixandmatch.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeMixAndMatch', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch mixandmatch in this time please type again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectMixAndMatch({ commit }, id) {
    commit('storeSelectMixAndMatch', { id, sub_id: '' });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleMixAndMatch({ commit, dispatch }, { id, sub_id }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/mixandmatch?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(mixandmatch => ({
          ...mixandmatch,
          created_at: moment(mixandmatch.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(mixandmatch.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeMixAndMatch', formatData);
        commit('storeSelectMixAndMatch', { id, sub_id });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch mixandmatch at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  updateCardData({ commit }, { key, value }) {
    commit('storeUpdateCardData', {
      key,
      value,
    });
  },
  updateDeckData({ commit }, { key, value }) {
    commit('storeUpdateDeckData', {
      key,
      value,
    });
  },
  updateCard({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/choice',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Card Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleMixAndMatch', {
            id: state.selectMixAndMatch.category_id,
          });
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update card. Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdateData');
  },

  deleteDeck({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Deck',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/mixandmatch/deck?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleMixAndMatch', {
                id: state.selectMixAndMatch.category_id,
              });
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  deleteCard({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Card',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/choice?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleMixAndMatch', {
                id: state.selectMixAndMatch.category_id,
              });
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete card please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  updateCreateCardData({ commit }, { key, value, index }) {
    commit('storeCreateCardData', {
      key,
      value,
      index,
    });
  },
  updateCreateSingleCardData({ commit }, { key, value }) {
    commit('storeCreateSingleCardData', {
      key,
      value,
    });
  },
  updateDeck({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT('/api/mixandmatch/deck', updateData, {
          isAuthorization: true,
        })
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Deck success',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleMixAndMatch', {
            id: state.selectMixAndMatch.category_id,
          });
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Deck failed please try again later',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  updateCreateDeckData({ commit }, { key, value }) {
    commit('storeCreateDeckData', {
      key,
      value,
    });
  },
  addDeck({ dispatch }, { category_id, ...data }) {
    const createDeckData = {
      category_id,
      deck: {
        ...data.deck,
      },
      choice: [...data.cards],
    };
    http
      .POST('/api/mixandmatch', createDeckData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Deck success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleMixAndMatch', { id: category_id });
        router.go({
          path: '/mixandmatch/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Deck failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
      });
  },
  addCardByDeckId({ dispatch }, { category_id, deck_id, ...data }) {
    const createCardData = {
      _id: deck_id,
      choice: { ...data.card },
    };
    http
      .POST('/api/mixandmatch/deck', createCardData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Card success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleMixAndMatch', { id: category_id });
        router.go({
          path: '/mixandmatch/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Card failed',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeMixAndMatch(state, mixandmatchList) {
    state.mixandmatch = mixandmatchList;
  },
  storeSelectMixAndMatch(state, { id, sub_id }) {
    if (id) {
      const index = state.mixandmatch.findIndex(
        mixandmatch => mixandmatch.category_id === id,
      );
      state.selectMixAndMatch = state.mixandmatch[index];
      if (sub_id) {
        const deckIndex = state.selectMixAndMatch.decks.findIndex(
          deck => deck._id === sub_id,
        );
        state.selectedDeckIndex = deckIndex;
      }
    } else {
      delete state.selectMixAndMatch;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateCardData(state, { key, value }) {
    state.updateCardData = {
      ...state.updateCardData,
      [key]: value,
    };
  },
  storeCreateCardData(state, { key, value, index }) {
    state.addCardData[index] = {
      ...state.addCardData[index],
      [key]: value,
    };
  },
  storeCreateSingleCardData(state, { key, value }) {
    state.addSingleCardData = {
      ...state.addSingleCardData,
      [key]: value,
    };
  },
  storeUpdateDeckData(state, { key, value }) {
    state.updateDeckData = {
      ...state.updateDeckData,
      [key]: value,
    };
  },
  storeCreateDeckData(state, { key, value }) {
    state.addDeckData = {
      ...state.addDeckData,
      [key]: value,
    };
  },
  clearStoreUpdateData(state) {
    Object.keys(state.updateCardData).forEach(key => {
      if (key != '_id') {
        delete state.updateCardData[key];
      }
    });
    Object.keys(state.updateDeckData).forEach(key => {
      if (key != '_id') {
        delete state.updateDeckData[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
