import http from '../../utils/httpClient';
import swal from 'sweetalert2';
import moment from 'moment';
import { LANGUAGES } from '../../constants/localization/languageList';
const state = {
  isValidKey: false,
  language: [],
  languageJSONtoCSV: [],
  uploadResult: {},
  createLanguage: {},
  updateLanguage: {},
  updateLanguagePrev: {},
  LOCALIZED_WORDS: [],
};
const getters = {
  // localizedWords(state) {
  //   return state.LOCALIZED_WORDS;
  // },
};
const actions = {
  fetchAllReference({ dispatch }, selectedLanguage) {
    http
      .POST(
        `/api/localization/fetchAllReference`,
        { language: selectedLanguage },
        {
          isAuthorization: true,
        },
      )
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: `All empty fields of ${selectedLanguage} was successfully replaced by English.`,
          },
          {
            root: true,
          },
        );
        dispatch('fetchLanguage', selectedLanguage);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  fetchLocalizedWords({ commit, dispatch }, param) {
    http
      .GET(`/api/localization/localized?${param}`, {
        isAuthorization: true,
      })
      .then(response => {
        dispatch('paymentstore/mapLocalizedWords', response.data, {
          root: true,
        });
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  checkDuplicateKey({ commit }, ID) {
    http
      .GET(`/api/localization/duplicate?ID=${ID}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeDuplicateID', response.data.valid);
      })
      .catch(error => {
        if (error.response.status === 409) {
          commit('storeDuplicateID', error.response.data.valid);
        }
      });
  },
  fetchLanguage({ commit, dispatch }, language) {
    http
      .GET(`/api/localization/specific?language=${language}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const languageData = data.data.map(language => ({
          ...language,
          created_at: moment(language.created_at).format('YYYY/MM/DD h:mm A'),
          updated_at: moment(language.updated_at).format('YYYY/MM/DD h:mm A'),
        }));
        commit('storeLanguage', languageData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch language at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  uploadLanguageDataToS3({ commit, dispatch }, language) {
    const languageData = {
      language: language,
    };
    const timestamp = {
      updated_localized: new Date(),
    };
    swal
      .fire({
        titleText: `Confirm Upload '${language}'`,
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'primary',
        confirmButtonText: 'Upload',
        showCancelButton: true,
        type: 'warning',
      })
      .then(response => {
        if (response.value) {
          http
            .POST(`/api/localization/upload`, languageData, {
              isAuthorization: true,
            })
            .then(response => {
              const formatReturnObject = {
                content: response.data,
                status: response.status,
              };
              commit('storeUploadResult', formatReturnObject);
              dispatch('publishTimestamp', timestamp);
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: `${language} Successfully Uploaded`,
                },
                {
                  root: true,
                },
              );
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message:
                    'Cannot upload language file at this time, please try again later.',
                  type: 'error',
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  convertJSONtoCSV({ commit, dispatch }, language) {
    http
      .GET(`/api/localization/json?language=${language}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        let languageData = data;
        commit('storeLanguageJSONtoCSV', languageData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot convert JSON to CSV at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  addLanguage({ dispatch }, { languageData, selectedLanguage }) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Adding Language',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/localization', languageData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Language Successfully Created',
          },
          {
            root: true,
          },
        );
        dispatch('fetchLanguage', selectedLanguage);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  publishTimestamp({ dispatch }, timestamp) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Publishing to Server',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/localization/publish', timestamp, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Timestamp and file has been updated',
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  publishToProduction({ dispatch }) {
    const timestamp = {
      updated_localized: new Date(),
    };
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Publishing to Production Server',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/localization/publish', timestamp, {
        isAuthorization: true,
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data.message,
          },
          {
            root: true,
          },
        );
      });
    http
      .PUT_PROD('/api/localization/publish', timestamp, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Timestamp and file has been updated',
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  editLanguage({ dispatch }, { updateData, selectedLanguage }) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Updating Language',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/localization', updateData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Language Successfully Updated',
          },
          {
            root: true,
          },
        );
        dispatch('fetchLanguage', selectedLanguage);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteLanguage({ dispatch }, { id, ID, selectedLanguage }) {
    swal
      .fire({
        titleText: `Confirm Delete '${ID}'`,
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'red',
        confirmButtonText: 'Remove',
        showCancelButton: true,
        type: 'warning',
      })
      .then(response => {
        if (response.value) {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'info',
              message: 'Deleting Language',
            },
            {
              root: true,
            },
          );
          http
            .DELETE(`/api/localization?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Language Successfully Deleted',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchLanguage', selectedLanguage);
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: error.response.data,
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  statePreviousData({ commit }, { key, value }) {
    commit('storeUpdateLanguagePrevData', {
      key,
      value,
    });
  },
  stateEditLanguage({ commit }, { key, value }) {
    commit('storeUpdateLanguageData', {
      key,
      value,
    });
  },
  stateCreateLanguage({ commit }, { key, value }) {
    commit('storeCreateLanguageData', {
      key,
      value,
    });
  },
  validateLanguage(value) {
    let key = Object.keys(value)[1];
    const languageList = LANGUAGES;
    if (languageList.includes(key)) {
      return key;
    }
  },
  clearCreateState({ commit }) {
    commit('clearCreateState');
  },
  clearUpdateState({ commit }) {
    commit('clearUpdateState');
  },
  clearUpdatePrevState({ commit }) {
    commit('clearUpdatePrevState');
  },
};
const mutations = {
  storeLocalizedWords(state, payload) {
    state.LOCALIZED_WORDS = payload;
  },
  storeDuplicateID(state, ID) {
    state.isValidKey = ID;
  },
  storeLanguage(state, value) {
    if (value) {
      state.language = value;
    } else {
      delete state.language;
    }
  },
  storeUploadResult(state, value) {
    if (value) {
      state.uploadResult = value;
    } else {
      delete state.uploadResult;
    }
  },
  storeLanguageJSONtoCSV(state, value) {
    if (value) {
      state.languageJSONtoCSV = value;
    } else {
      delete state.languageJSONtoCSV;
    }
  },
  storeCreateLanguageData(state, { key, value }) {
    state.createLanguage = {
      ...state.createLanguage,
      [key]: value,
    };
  },
  storeUpdateLanguagePrevData(state, { key, value }) {
    state.updateLanguagePrev = {
      ...state.updateLanguagePrev,
      [key]: value,
    };
  },
  storeUpdateLanguageData(state, { key, value }) {
    state.updateLanguage = {
      ...state.updateLanguage,
      [key]: value,
    };
  },
  clearCreateState(state) {
    state.createLanguage = {};
  },
  clearUpdateState(state) {
    state.updateLanguage = {};
  },
  clearUpdatePrevState(state) {
    state.updateLanguagePrev = {};
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
