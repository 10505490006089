import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
const state = {
  bookshelf: [],
  selectBookshelf: {},
  selectedBookIndex: -1,
  updatePageData: {},
  updateBookData: {},
  isUpdatePageData: false,
  addPageData: {},
  addBookData: {},
};
const getters = {};
const actions = {
  fetchBookShelf({ commit, dispatch }, { page = 1, limit = 0 }) {
    http
      .GET(`/api/bookshelf?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(bookShelf => ({
          ...bookShelf,
          created_at: moment(bookShelf.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(bookShelf.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeBookshelf', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch books in this time please type again',
            type: 'error',
          },
          { root: true },
        );
        throw new Error(err);
      });
  },
  selectBookshelf({ commit }, id) {
    commit('storeSelectBookshelf', id);
  },
  fetchSingleBook({ commit }, id) {
    commit('toggleStoreIsUpdatePageData');

    http
      .GET(`/api/bookshelf?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(bookShelf => ({
          ...bookShelf,
          created_at: moment(bookShelf.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(bookShelf.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeBookshelf', formatData);
        commit('storeSelectBookshelf', id);
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => commit('toggleStoreIsUpdatePageData'));
  },
  fetchSingleBookByCategory({ commit }, { category_id, sub_id }) {
    commit('toggleStoreIsUpdatePageData');

    http
      .GET(`/api/bookshelf?category_id=${category_id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(bookShelf => ({
          ...bookShelf,
          created_at: moment(bookShelf.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(bookShelf.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeBookshelf', formatData);
        commit('storeSelectBookshelfByCategoryId', sub_id);
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => commit('toggleStoreIsUpdatePageData'));
  },
  updatePageData({ commit }, { key, value }) {
    commit('storeUpdatePageData', { key, value });
  },
  updateBookData({ commit }, { key, value }) {
    commit('storeUpdateBookData', { key, value });
  },
  updatePage({ dispatch, state }, updateData) {
    http
      .PUT('/api/bookshelf/pages', { ...updateData }, { isAuthorization: true })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Update Page Success.',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchSingleBook', state.selectBookshelf._id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Update Page Failed Please Try again',
            type: 'error',
          },
          { root: true },
        );
        throw new Error(err);
      });
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdatePageData');
  },
  deletePage({ dispatch, state }, id) {
    http
      .DELETE(`/api/bookshelf/pages?id=${id}`, { isAuthorization: true })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete Successfully',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchSingleBook', state.selectBookshelf._id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete please try again later !',
            type: 'error',
          },
          { root: true },
        );
        throw new Error(err);
      });
  },
  addPage({ dispatch }, { id, data }) {
    const addPageData = {
      book_id: id,
      page: { ...data },
    };
    http
      .POST('/api/bookshelf/pages', addPageData, { isAuthorization: true })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Page success',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchSingleBook', state.selectBookshelf._id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Error cannot create page please try again',
            type: 'error',
          },
          { root: true },
        );
        throw new Error(err);
      });
  },
  createPageData({ commit }, { key, value }) {
    commit('storeCreatePageData', { key, value });
  },
  updateBook({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT('/api/bookshelf/books', updateData, { isAuthorization: true })
        .then(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Book success',
              type: 'success',
            },
            { root: true },
          );
          dispatch('fetchSingleBook', state.selectBookshelf._id);
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Book failed please try again later',
              type: 'error',
            },
            { root: true },
          );
          throw new Error(err);
        });
    }
  },
  updateCreateBookData({ commit }, { key, value }) {
    commit('storeCreateBookData', { key, value });
  },
  addBook({ dispatch, state }, { bookshelf_id, ...data }) {
    const pages = [
      { text: 'Please Change Text Here!!', img_uri: '', sound_uri: '' },
    ];
    const createBookData = {
      bookshelf_id,
      book: { ...data, read_count: 0 },
      pages,
    };
    http
      .POST('/api/bookshelf/books', createBookData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Book success',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchSingleBook', state.selectBookshelf._id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Book failed',
            type: 'error',
          },
          { root: true },
        );
        throw new Error(err);
      });
  },
  deleteBook({ dispatch, state }, id) {
    http
      .DELETE(`/api/bookshelf/books?id=${id}`, { isAuthorization: true })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete Book Successfully',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchSingleBook', state.selectBookshelf._id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: err.response.data.message,
            type: 'error',
          },
          { root: true },
        );
        throw new Error(err);
      });
  },
  clearCreatePage({ commit }) {
    commit('clearStoreCreatePageData');
  },
  clearCreateBook({ commit }) {
    commit('clearStoreCreateBookData');
  },
};
const mutations = {
  toggleStoreIsUpdatePageData(state) {
    state.isUpdatePageData = !state.isUpdatePageData;
  },
  storeBookshelf(state, bookshelfList) {
    state.bookshelf = bookshelfList;
  },
  storeSelectBookshelf(state, id) {
    const index = state.bookshelf.findIndex(book => book._id === id);
    state.selectBookshelf = state.bookshelf[index];
  },
  storeSelectBookshelfByCategoryId(state, sub_id) {
    state.selectBookshelf = state.bookshelf[0];
    const index = state.selectBookshelf.books.findIndex(
      book => book._id === sub_id,
    );
    state.selectedBookIndex = index;
  },
  storeUpdatePageData(state, { key, value }) {
    if (value) {
      state.updatePageData = { ...state.updatePageData, [key]: value };
    } else {
      delete state.updatePageData[key];
    }
  },
  storeCreatePageData(state, { key, value }) {
    if (value) {
      state.addPageData = { ...state.addPageData, [key]: value };
    } else {
      delete state.addPageData[key];
    }
  },
  storeUpdateBookData(state, { key, value }) {
    state.updateBookData = { ...state.updateBookData, [key]: value };
  },
  storeCreateBookData(state, { key, value }) {
    if (value) {
      state.addBookData = { ...state.addBookData, [key]: value };
    } else {
      delete state.addBookData[key];
    }
  },
  clearStoreUpdatePageData(state) {
    state.updatePageData = {};
  },
  clearStoreCreatePageData(state) {
    state.addPageData = {};
  },
  clearStoreCreateBookData(state) {
    state.addBookData = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
