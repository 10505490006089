import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  wordwalk: [],
  selectWordwalk: {},
  selectedCategoryId: '',
  selectedQuestionIndex: -1,
  updateAnswerData: {},
  updateQuestionData: {},
  isUpdateAnswerData: false,
  addAnswerData: [
    {
      answer: '',
      order: 1,
      matchkey: 0,
      is_correct: false,
    },
    {
      answer: '',
      order: 2,
      matchkey: 0,
      is_correct: false,
    },
    {
      answer: '',
      order: 3,
      matchkey: 0,
      is_correct: false,
    },
    {
      answer: '',
      order: 4,
      matchkey: 0,
      is_correct: false,
    },
  ],
  questionData: {
    question: 'Type you question here!!',
    question_type: 0,
    answer_type: 0,
    score: 100,
  },
  addQuestionData: {},
};
const getters = {};
const actions = {
  fetchWordwalk({ commit, dispatch }, { page = 1, limit = 0 }) {
    http
      .GET(`/api/wordwalk?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(wordWalk => ({
          ...wordWalk,
          created_at: moment(wordWalk.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(wordWalk.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeWordwalk', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch wordwalk in this time please type again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  selectWordwalk({ commit }, id) {
    commit('storeSelectWordwalk', { id, sub_id: '' });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleWordwalk({ commit }, { id, sub_id }) {
    commit('toggleStoreIsUpdateAnswerData');
    http
      .GET(`/api/wordwalk?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(wordwalk => ({
          ...wordwalk,
          created_at: moment(wordwalk.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(wordwalk.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeWordwalk', formatData);
        commit('storeSelectWordwalk', { id, sub_id });
        if (data.length == 0) {
          router.push('/wordwalks');
        }
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => commit('toggleStoreIsUpdateAnswerData'));
  },
  updateAnswerData({ commit }, { key, value }) {
    commit('storeUpdateAnswerData', {
      key,
      value,
    });
  },
  updateQuestionData({ commit }, { key, value }) {
    commit('storeUpdateQuestionData', {
      key,
      value,
    });
  },
  updateAnswer({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/wordwalk/answers',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Answer Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          // dispatch('fetchSingleWordwalk', state.selectWordwalk.category_id);
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Answer Failed Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdateData');
  },

  deleteQuestion({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Question',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/wordwalk/questions?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleWordwalk', {
                id: state.selectWordwalk.category_id,
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  createAnswerData({ commit }, { key, value }, index) {
    commit(
      'storeCreateAnswerData',
      {
        key,
        value,
      },
      index,
    );
  },
  updateQuestion({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT('/api/wordwalk/questions', updateData, {
          isAuthorization: true,
        })
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Question success',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleWordwalk', {
            id: state.selectWordwalk.category_id,
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Question failed please try again later',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  createQuestionData({ commit }, { key, value }) {
    commit('storeCreateQuestionData', {
      key,
      value,
    });
  },
  addQuestion({ dispatch }, { category_id, ...data }) {
    const createQuestionData = {
      category_id,
      question: {
        ...data.question,
      },
      answers: [...data.answers],
    };
    http
      .POST('/api/wordwalk', createQuestionData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Question success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleWordwalk', category_id);
        router.go({
          path: '/wordwalk/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Question failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  toggleStoreIsUpdateAnswerData(state) {
    state.isUpdateAnswerData = !state.isUpdateAnswerData;
  },
  storeWordwalk(state, wordwalkList) {
    state.wordwalk = wordwalkList;
  },
  storeSelectWordwalk(state, { id, sub_id }) {
    if (id) {
      const index = state.wordwalk.findIndex(
        wordwalk => wordwalk.category_id === id,
      );
      state.selectWordwalk = state.wordwalk[index];
      if (sub_id) {
        const questionIndex = state.selectWordwalk.questions.findIndex(
          question => question._id === sub_id,
        );
        state.selectedQuestionIndex = questionIndex;
      }
    } else {
      delete state.selectWordwalk;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateAnswerData(state, { key, value }) {
    if (value || value == 0 || value == false) {
      state.updateAnswerData = {
        ...state.updateAnswerData,
        [key]: value,
      };
    } else {
      delete state.updateAnswerData[key];
    }
  },
  storeCreateAnswerData(state, { key, value }, index) {
    if (value || value == 0 || value == false) {
      state.addAnswerData[index] = {
        ...state.addAnswerData[index],
        [key]: value,
      };
    } else {
      delete state.addAnswerData[index][key];
    }
  },
  storeUpdateQuestionData(state, { key, value }) {
    if (value || value == 0) {
      state.updateQuestionData = {
        ...state.updateQuestionData,
        [key]: value,
      };
    } else {
      delete state.updateQuestionData[key];
    }
  },
  storeCreateQuestionData(state, { key, value }) {
    if (value || value == 0) {
      state.addQuestionData = {
        ...state.addQuestionData,
        [key]: value,
      };
    } else {
      delete state.addQuestionData[key];
    }
  },
  clearStoreUpdateData(state) {
    state.updateAnswerData = {};
    state.updateQuestionData = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
