import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  taptosay: [],
  selectTapToSay: {},
  selectedCategoryId: '',
  selectedSetIndex: -1,
  updateObjectData: {},
  updateSetData: {},
  isUpdating: false,
  objectData: {
    name: '',
    text: '',
    action: '',
    is_correct: false,
  },
  addObjectData: [
    {
      name: '',
      text: '',
      is_correct: false,
    },
  ],
  addSetData: {
    name: '',
  },
  eventData: {
    startX: '',
    startY: '',
    endX: '',
    endY: '',
    nextIndex: 0,
  },
  editImageData: {
    image_uri: [],
  },
};
const getters = {};
const actions = {
  fetchTapToSay({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/taptosay/category?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(taptosay => ({
          ...taptosay,
          created_at: moment(taptosay.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(taptosay.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeTapToSay', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch taptosay in this time please type again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectTapToSay({ commit }, data) {
    commit('storeSelectTapToSay', data);
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleTapToSay({ commit, dispatch }, { id, sub_id }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/taptosay?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const [formatData] = data.map(taptosay => ({
          ...taptosay,
          created_at: moment(taptosay.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(taptosay.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeSelectTapToSay', { ...formatData, sub_id });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch taptosay at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  updateEditImage({ commit }, { key, value, index }) {
    commit('storeEditImageData', {
      key,
      value,
      index,
    });
  },
  updateObjectData({ commit }, { key, value, index }) {
    commit('storeUpdateObjectData', {
      key,
      value,
    });
  },
  updateSetData({ commit }, { key, value }) {
    commit('storeUpdateSetData', {
      key,
      value,
    });
  },
  updateObject({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/taptosay/tapobject',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Object Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleTapToSay', {
            id: state.selectTapToSay.category_id,
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update set. Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateSetData({ commit }) {
    commit('clearStoreUpdateSetData');
  },
  clearUpdateObjectData({ commit }) {
    commit('clearStoreUpdateObjectData');
  },

  deleteSet({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Set',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/taptosay/tapset?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleTapToSay', {
                id: state.selectTapToSay.category_id,
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  deleteObject({ dispatch, state }, id) {
    http
      .DELETE(`/api/taptosay/tapobject?_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete object successfully',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchSingleTapToSay', {
          id: state.selectTapToSay.category_id,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete object. Please try again later!',
            type: 'error',
          },
          { root: true },
        );
      });
  },
  updateCreateObjectData({ commit }, { key, value, index }) {
    commit('storeCreateObjectData', {
      key,
      value,
      index,
    });
  },
  updateCreateSingleObjectData({ commit }, { key, value }) {
    commit('storeCreateSingleObjectData', {
      key,
      value,
    });
  },
  updateSet({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT('/api/taptosay/tapset', updateData, {
          isAuthorization: true,
        })
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Set success',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleTapToSay', {
            id: state.selectTapToSay.category_id,
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Set failed please try again later',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  updateCreateSetData({ commit }, { key, value }) {
    commit('storeCreateSetData', {
      key,
      value,
    });
  },
  addSet({ dispatch }, { category_id, ...data }) {
    const createSetData = {
      category_id,
      tapSet: {
        ...data.set,
      },
      tapObject: [...data.objects],
    };
    http
      .POST('/api/taptosay', createSetData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Set success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleTapToSay', { id: category_id });
        router.go({
          path: '/taptosay/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Set failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
      });
  },
  addObjectBySetId({ dispatch }, { category_id, set_id, ...data }) {
    const createObjectData = {
      _id: set_id,
      tapObject: { ...data.object },
    };
    http
      .POST('/api/taptosay/tapset', createObjectData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Object success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        // TODO
        dispatch('fetchSingleTapToSay', { id: category_id });
        // router.go({
        //   path: '/taptosay/detail',
        //   query: {
        //     id: category_id,
        //   },
        // });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Object failed',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeTapToSay(state, taptosayList) {
    state.taptosay = taptosayList;
  },
  storeSelectTapToSay(state, data) {
    if (data) {
      state.selectTapToSay = data;
      if (data.sub_id) {
        const setIndex = state.selectTapToSay.object_sets.findIndex(
          set => set._id === data.sub_id,
        );
        state.selectedSetIndex = setIndex;
      }
      delete state.selectTapToSay.sub_id;
    } else {
      delete state.selectTapToSay;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeEditImageData(state, { key, value, index }) {
    state.editImageData[index] = {
      ...state.editImageData[index],
      [key]: value,
    };
  },
  storeUpdateObjectData(state, { key, value }) {
    state.updateObjectData = {
      ...state.updateObjectData,
      [key]: value,
    };
  },
  storeCreateObjectData(state, { key, value, index }) {
    state.addObjectData[index] = {
      ...state.addObjectData[index],
      [key]: value,
    };
  },
  storeCreateSingleObjectData(state, { key, value }) {
    state.addSingleObjectData = {
      ...state.addSingleObjectData,
      [key]: value,
    };
  },
  storeUpdateSetData(state, { key, value }) {
    state.updateSetData = {
      ...state.updateSetData,
      [key]: value,
    };
  },
  storeCreateSetData(state, { key, value }) {
    state.addSetData = {
      ...state.addSetData,
      [key]: value,
    };
  },
  clearStoreUpdateSetData(state) {
    Object.keys(state.updateSetData).forEach(key => {
      if (key != '_id') {
        delete state.updateSetData[key];
      }
    });
  },
  clearStoreUpdateObjectData(state) {
    Object.keys(state.updateObjectData).forEach(key => {
      if (key != '_id') {
        delete state.updateObjectData[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
