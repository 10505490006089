<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Promotion Code Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" light v-on:click="isCreate = true">
            <v-icon>add</v-icon>Generate New Code
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex xs3>
                <v-text-field
                  outline
                  v-model="search"
                  label="Filter by Campaign"
                  append-icon="search"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-data-table
            v-if="!codeStore.isLoadCode"
            :items="codeStore.codeList"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.code }}</td>
              <td>
                <v-btn icon v-clipboard:copy="props.item.code">
                  <v-icon>link</v-icon>
                </v-btn>
              </td>
              <td>{{ props.item.username }}</td>
              <td>{{ props.item.campaign }}</td>
              <td>{{ props.item.expired_date }}</td>
              <td>
                <v-select
                  :items="constants.CODE_TYPE"
                  :value="props.item.type"
                  readonly
                  append-icon=""
                >
                </v-select>
              </td>
              <td>{{ props.item.days }}</td>
              <td>
                <v-btn
                  icon
                  color="red"
                  v-on:click="onClickDelete(props.item._id)"
                >
                  <v-icon color="white">remove</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onClickSaveCode"
      :close="closeCreate"
      title="Generate New Code"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-text-field
                label="Campaign"
                v-model="codeStore.createCode.campaign"
                v-on:change="e => onChangeCreateContent('campaign', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-select
                :items="constants.CODE_TYPE"
                label="Code Type"
                :value="codeStore.createCode.type"
                v-model="codeStore.createCode.type"
                v-on:change="e => onChangeCreateContent('type', e)"
              >
              </v-select>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Amount"
                v-model="codeStore.createCode.amount"
                v-on:change="e => onChangeCreateContent('amount', e)"
              ></v-text-field>
            </v-flex>
            <v-flex
              v-if="codeStore.createCode.type == 'subscription.free.trial'"
            >
              <v-text-field
                label="Days"
                v-model="codeStore.createCode.days"
                v-on:change="e => onChangeCreateContent('days', parseInt(e))"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </Modal>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import swal from 'sweetalert2';
import Container from '../components/Container';
import Modal from '../components/Modal';
import constants from '../constants';

export default {
  name: 'Code',
  components: {
    Container,
    Modal,
  },
  data: function () {
    return {
      constants,
      search: '',
      headers: [
        { text: 'Index', value: 'index', sortable: false },
        { text: 'Code', value: 'code', sortable: false },
        { text: '', value: '', sortable: false },
        { text: 'Username', value: 'username', sortable: false },
        { text: 'Campaign', value: 'campaign', sortable: true },
        { text: 'Expired Date', value: 'expired_date', sortable: true },
        { text: 'Type', value: 'type', sortable: true },
        { text: 'Day', value: 'days', sortable: true },
        // { text: 'Created Date', value: 'created_at', sortable: true },
        // { text: 'Updated Date', value: 'updated_at', sortable: true },
        { text: '', value: '', sortable: false },
      ],
      isCreate: false,
    };
  },
  methods: {
    ...mapActions('appConfig', ['toggleAlert']),
    ...mapActions('code', [
      'fetchCodes',
      'deleteCode',
      'generateCode',
      'editCreateCode',
    ]),
    onClickDelete(id) {
      this.deleteCode({ id });
    },
    closeCreate() {
      this.isCreate = false;
    },
    onClickSaveCode() {
      const createCodeData = { ...this.codeStore.createCode };
      this.generateCode(createCodeData);
      this.isCreate = false;
    },
    onChangeCreateContent(key, value) {
      this.editCreateCode({ key, value });
    },
  },
  mounted: function () {
    this.fetchCodes({ page: 1, limit: 0 });
  },

  computed: {
    ...mapState({
      codeStore: state => state.code,
      userInfoStore: state => state.userInfo,
    }),
  },
};
</script>
