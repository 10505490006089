<template>
  <div>
    <v-card-text>
      <v-layout row>
        <v-flex xs9>
          <v-text-field
            disabled
            readonly
            label="_id"
            :value="game_id"
          ></v-text-field>
        </v-flex>
        <v-flex xs3>
          <v-btn
            icon
            small
            class="white--text"
            color="primary"
            @click="copyToClipboard(game_id)"
            ><v-icon small>content_copy</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs9>
          <v-text-field
            disabled
            readonly
            label="category_id"
            :value="category_id"
          ></v-text-field
        ></v-flex>
        <v-flex xs3>
          <v-btn
            icon
            small
            class="white--text"
            color="primary"
            @click="copyToClipboard(category_id)"
            ><v-icon small>content_copy</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
  </div>
</template>

<script>
export default {
  // data() {
  //   return {
  //     isJson: true,
  //   };
  // },
  name: 'Clipboard',
  props: {
    game_id: {
      type: String,
      default: '',
    },
    category_id: {
      type: String,
      default: '',
    },
  },
  methods: {
    copyToClipboard(id) {
      if (id) {
        this.$copyText(id).then(
          () => {
            this.$store.dispatch(
              'appConfig/toggleAlert',
              {
                message: 'Copied to clipboard',
                type: 'info',
              },
              { root: true },
            );
          },
          () => {
            this.$store.dispatch(
              'appConfig/toggleAlert',
              {
                message: 'Cannot copy to clipboard at this time',
                type: 'error',
              },
              { root: true },
            );
          },
        );
      }
    },
  },
};
</script>

<style></style>
