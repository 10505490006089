<template>
  <div>
    <v-flex>
      <v-text-field
        required
        :rules="nameRules"
        :error-messages="errorMessages"
        v-model="node.node_name"
        label="Node Name"
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-text-field
        type="number"
        label="Delay"
        v-model="node.delay"
      ></v-text-field>
    </v-flex>

    <v-flex>
      <v-btn @click="openModalItem"> <v-icon>image</v-icon>Select Image </v-btn>
    </v-flex>
    <!-- Position -->
    <v-flex>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-select
              :disabled="node.image_uri && node.image_uri.length < 1"
              :items="constant.IMAGE_LAYOUT_POSITION"
              label="Image Layout Position"
              v-model="node.image_position"
            ></v-select>
          </span>
        </template>
        <span v-if="node.image_uri && node.image_uri.length < 1"
          >Please select image to allow this function.</span
        >
        <span v-else>Now you can select the image layout position.</span>
      </v-tooltip>
    </v-flex>

    <v-flex>
      <v-autocomplete
        label="Goto Node"
        v-model="node.goto_node"
        :items="gotoNodeStore"
        item-text="display"
        item-value="node_name"
        chips
        multiple
        hide-details
        hide-no-data
        hide-selected
      ></v-autocomplete>
    </v-flex>

    <v-flex>
      <img
        v-if="node.background_uri"
        :src="node.background_uri"
        :alt="node.node"
        class="content_img"
      />
      <v-text-field
        label="Background URI (URL or Select file)"
        v-model="node.background_uri"
      ></v-text-field>
      <v-btn
        @click="
          isSelectImage = true;
          isBackgroundUri = true;
        "
      >
        <v-icon>image</v-icon>Select Background
      </v-btn>
    </v-flex>

    <Modal
      v-if="isOpenItemModal"
      :isOpen="isOpenItemModal"
      :save="onSaveItem"
      :close="closeModalItem"
      :isLastStep="true"
      :width="width"
      title="Item Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          @click="increaseStep(name_list.image_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.image_manager_list">
          <v-stepper-header>
            <template v-for="n in step.image_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.image_manager_list > n"
                :step="n"
                editable
              >
                Item {{ n }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.image_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.image_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="
                    reduceStep(
                      element.image_manager_list,
                      name_list.image_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <img
                  v-if="node.image_uri[step - 1]"
                  :src="node.image_uri[step - 1]"
                  class="content_img"
                />
                <v-text-field
                  label="Image URI (URL or Select file)"
                  v-model="node.image_uri[step - 1]"
                ></v-text-field>
                <v-btn
                  dark
                  @click="
                    isImageList = true;
                    isSelectImage = true;
                  "
                >
                  <v-icon>image</v-icon>Select Item Image
                </v-btn>
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.image_manager_list)"
              >
                Next
              </v-btn>
              <v-btn flat @click="backStep(step, name_list.image_manager_list)">
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>

    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeEditImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '../../../../../components/Modal';
import ResourceSelect from '../../../../../components/ResourceSelect';
import constant from '../../../constant';
export default {
  name: 'CreateImagePopupNode',
  components: {
    ResourceSelect,
    Modal,
  },
  computed: {
    ...mapState({
      gotoNodeStore: state => state.minichat.gotoNodeData,
      skipLegacyProcessStatus: state =>
        state.minichat.minichatData.skip_legacy_process,
    }),
  },
  props: {
    node: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      isImageList: false,
      name_list: {
        image_manager_list: 'image_manager_list',
      },
      element: {
        image_manager_list: 1,
      },
      step: {
        image_manager_list: 1,
      },

      isOpenItemModal: false,
      maxImage: 3,

      constant,
      nameRules: [v => !!v || 'Name is required'],
      typeRules: [v => !!(v > -1) || 'Type is required'],
      errorMessages: '',
      selectImageUri: '',
      isBackgroundUri: false,
      isSelectImage: false,
      width: '60%',
    };
  },
  methods: {
    increaseStep(list) {
      if (this.step[list] < this.maxImage) {
        this.step[list] += 1;
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.image_manager_list) {
          this.step[list] -= 1;
          this.node.image_uri.splice(index, 1);
        }
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },
    onSaveItem() {
      this.isOpenItemModal = false;
    },
    closeModalItem() {
      this.isOpenItemModal = false;
    },
    initialDefaultItem() {
      this.node.image_uri = [];
    },
    openModalItem() {
      this.isOpenItemModal = true;
      if (!this.node.image_uri) {
        this.initialDefaultItem();
      }
      this.calculateItemStep();
    },
    calculateItemStep() {
      if (this.node.image_uri.length === 0) {
        this.step.image_manager_list = 1;
      } else {
        this.step.image_manager_list = this.node.image_uri.length;
      }
    },

    saveSelectImage() {
      const uri = this.selectImageUri;
      if (this.isBackgroundUri) {
        this.node.background_uri = uri;
      } else if (this.isImageList) {
        const index = this.element.image_manager_list - 1;
        this.node.image_uri[index] = uri;
      }
      this.selectImageUri = '';
      this.isBackgroundUri = false;
      this.isImageList = false;
      this.isSelectImage = false;
    },
    closeEditImage() {
      this.isSelectImage = false;
      this.selectImageUri = '';
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
  },
};
</script>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
