var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-flex',[_c('v-text-field',{attrs:{"disabled":"","required":"","rules":_vm.nameRules,"error-messages":_vm.errorMessages,"label":"Node Name"},model:{value:(_vm.node.node_name),callback:function ($$v) {_vm.$set(_vm.node, "node_name", $$v)},expression:"node.node_name"}})],1),_c('v-flex',[_c('div',{staticClass:"modal-item"},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){return _vm.openModalItem()}}},[_c('v-icon',[_vm._v("category")]),_vm._v(" Item Manager ")],1)],1)]),_c('v-flex',[_c('v-text-field',{attrs:{"type":"number","label":"Delay"},model:{value:(_vm.node.delay),callback:function ($$v) {_vm.$set(_vm.node, "delay", $$v)},expression:"node.delay"}})],1),_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Hide Rope"},model:{value:(_vm.node.hide_rope),callback:function ($$v) {_vm.$set(_vm.node, "hide_rope", $$v)},expression:"node.hide_rope"}})],1),_c('v-flex',[_c('v-autocomplete',{attrs:{"label":"Goto Node","items":_vm.gotoNodeStore,"item-text":"display","item-value":"node_name","chips":"","multiple":"","hide-details":"","hide-no-data":"","hide-selected":""},model:{value:(_vm.node.goto_node),callback:function ($$v) {_vm.$set(_vm.node, "goto_node", $$v)},expression:"node.goto_node"}})],1),_c('v-flex',[(_vm.node.background_uri)?_c('img',{staticClass:"content_img",attrs:{"src":_vm.node.background_uri,"alt":_vm.node.node}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Background URI (URL or Select file)"},model:{value:(_vm.node.background_uri),callback:function ($$v) {_vm.$set(_vm.node, "background_uri", $$v)},expression:"node.background_uri"}}),_c('v-btn',{on:{"click":function($event){_vm.isSelectImage = true;
        _vm.isUpdateNode = true;
        _vm.isBackgroundUri = true;}}},[_c('v-icon',[_vm._v("image")]),_vm._v("Select Background ")],1)],1),_c('br'),(_vm.isSelectImage)?_c('ResourceSelect',{attrs:{"resourceType":"image","isShow":_vm.isSelectImage,"close":_vm.closeEditImage,"save":_vm.saveSelectImage,"value":_vm.selectImageUri,"onSelect":_vm.onSelectImage}}):_vm._e(),(_vm.isSelectSound)?_c('ResourceSelect',{attrs:{"resourceType":"sound","isShow":_vm.isSelectSound,"close":_vm.closeEditSound,"save":_vm.saveSelectSound,"value":_vm.selectSoundUri,"onSelect":_vm.onSelectSound}}):_vm._e(),(_vm.isOpenItemModal)?_c('Modal',{attrs:{"isOpen":_vm.isOpenItemModal,"save":_vm.onSaveItem,"close":_vm.closeModalItem,"isLastStep":true,"width":_vm.width,"title":"Item Manager"}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-btn',{attrs:{"color":"success","small":"","fab":""},on:{"click":function($event){return _vm.increaseStep(_vm.name_list.item_manager_list)}}},[_c('v-icon',[_vm._v("add")])],1),_c('v-stepper',{model:{value:(_vm.element.item_manager_list),callback:function ($$v) {_vm.$set(_vm.element, "item_manager_list", $$v)},expression:"element.item_manager_list"}},[_c('v-stepper-header',[_vm._l((_vm.step.item_manager_list),function(n){return [_c('v-stepper-step',{key:(n + "-step"),attrs:{"complete":_vm.element.item_manager_list > n,"step":n,"editable":""}},[_vm._v(" Item "+_vm._s(n)+" ")]),(n !== _vm.step.item_manager_list)?_c('v-divider',{key:n}):_vm._e()]})],2),_c('v-stepper-items',_vm._l((_vm.step.item_manager_list),function(step){return _c('v-stepper-content',{key:(step + "-content"),attrs:{"step":step}},[_c('v-flex',[_c('v-btn',{attrs:{"color":"error","small":"","absolute":"","fab":"","right":""},on:{"click":function($event){return _vm.reduceStep(
                    _vm.element.item_manager_list,
                    _vm.name_list.item_manager_list
                  )}}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.node.item[step - 1].name),callback:function ($$v) {_vm.$set(_vm.node.item[step - 1], "name", $$v)},expression:"node.item[step - 1].name"}}),_c('v-select',{attrs:{"required":"","label":"Detect Animation","items":_vm.constant.ITEM_ANIMATION},model:{value:(_vm.node.item[step - 1].detect_animation),callback:function ($$v) {_vm.$set(_vm.node.item[step - 1], "detect_animation", _vm._n($$v))},expression:"node.item[step - 1].detect_animation"}}),_c('v-select',{attrs:{"required":"","label":"Receive Animation","items":_vm.constant.ITEM_ANIMATION},model:{value:(_vm.node.item[step - 1].receive_animation),callback:function ($$v) {_vm.$set(_vm.node.item[step - 1], "receive_animation", _vm._n($$v))},expression:"node.item[step - 1].receive_animation"}}),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Receive Sound URI (URL or Select file)"},model:{value:(_vm.node.item[step - 1].sound_uri),callback:function ($$v) {_vm.$set(_vm.node.item[step - 1], "sound_uri", $$v)},expression:"node.item[step - 1].sound_uri"}}),_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){_vm.isSelectSound = true;
                    _vm.isSelectItemSound = true;}}},[_c('v-icon',[_vm._v("audiotrack")]),_vm._v("Select Item Sound")],1)],1),(_vm.node.item[step - 1].image_uri)?_c('img',{staticClass:"content_img",attrs:{"src":_vm.node.item[step - 1].image_uri}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Image URI (URL or Select file)"},model:{value:(_vm.node.item[step - 1].image_uri),callback:function ($$v) {_vm.$set(_vm.node.item[step - 1], "image_uri", $$v)},expression:"node.item[step - 1].image_uri"}}),_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){_vm.isSelectItemImage = true;
                  _vm.isSelectImage = true;}}},[_c('v-icon',[_vm._v("image")]),_vm._v("Select Item Image ")],1),_c('SpriteSheet',{attrs:{"title":'is sprite sheet',"isToggle":_vm.node.item[step - 1].is_sprite_sheet,"fieldname":'sprite sheet data',"object":_vm.node.item[step - 1].sprite_sheet_data,"index":step - 1},on:{"onchangeToggleSprite":function($event){return _vm.onchangeToggleSprite(
                    'is_sprite_sheet',
                    'sprite_sheet_data',
                    $event
                  )},"saveSpriteSheetData":function($event){return _vm.saveSpriteSheetData('sprite_sheet_data', $event)}}})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextStep(step, _vm.name_list.item_manager_list)}}},[_vm._v(" Next ")]),_c('v-btn',{attrs:{"flat":""},on:{"click":function($event){return _vm.backStep(step, _vm.name_list.item_manager_list)}}},[_vm._v(" Back ")])],1)}),1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }