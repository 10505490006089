<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light @click="$router.push('/minichat')">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Mini Chat Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>

        <!-- <v-card-text> -->
        <v-card-text v-if="minichatsStore">
          <v-layout row grid-list-md>
            <v-flex xs7 mr-2>
              <v-card>
                <v-list>
                  <v-list-tile
                    v-for="(minichat, index) in minichatsStore"
                    :key="minichat.minichat_id"
                    @click="onSelectMiniChat(minichat.minichat_id, index)"
                  >
                    <!-- :class="
                      index === selectedMiniChatIndex ? 'active' : 'inactive'
                    " -->
                    <v-list-tile-avatar>
                      <v-icon>view_module</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{
                        minichat.minichat_name
                      }}</v-list-tile-title>
                      <v-list-tile-sub-title
                        >Created at
                        {{ minichat.created_at }}</v-list-tile-sub-title
                      >
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>

            <v-flex
              xs6
              mr-2
              v-if="!isEmpty(minichatDataStore) && selectedMiniChatIndex !== -1"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Mini Chat Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" @click="onSaveEdit">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn icon color="red" @click="onClickDelete(minichat._id)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data</v-btn
                      >
                      <v-btn
                        color="primary"
                        @click="onClickNodeManager(minichat._id)"
                        >Node Manager</v-btn
                      >
                    </v-flex>
                    <v-flex
                      v-if="
                        usernameStore === 'ekie' ||
                        usernameStore === 'hinzahawa'
                      "
                    >
                      <!-- NOTE: boolean skip_legacy_process for game dev username = ekie only -->
                      <v-checkbox
                        label="Skip Legacy Process"
                        v-model="minichat.skip_legacy_process"
                        :value="minichat.skip_legacy_process"
                        v-on:change="
                          e => onChangeContent('skip_legacy_process', e)
                        "
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Available for Free User"
                        v-model="minichat.isGuestPlayable"
                        :value="minichat.isGuestPlayable"
                        v-on:change="e => onChangeContent('isGuestPlayable', e)"
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Name"
                        :value="minichat.name"
                        v-model="minichat.name"
                        :rules="nameRules"
                        v-on:change="e => onChangeContent('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="constant.MAIN_CHARACTER"
                        label="Main Character"
                        :value="minichat.main_character"
                        v-model="minichat.main_character"
                        v-on:change="e => onChangeContent('main_character', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="constant.CHARACTER_POSITION"
                        label="Position"
                        :value="minichat.position"
                        v-model="minichat.position"
                        v-on:change="e => onChangeContent('position', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="constant.CHARACTER_SIZE"
                        label="Size"
                        :value="minichat.size"
                        v-model="minichat.size"
                        v-on:change="e => onChangeContent('size', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="startNodeStore"
                        label="Start Node"
                        :value="minichat.start_node"
                        v-model="minichat.start_node"
                        v-on:change="e => onChangeContent('start_node', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <img
                        v-if="minichat.background_uri"
                        :src="minichat.background_uri"
                        :alt="minichat.minichat"
                        class="content_img"
                      />
                      <v-text-field
                        label="Background Image (URL or Select file)"
                        :value="minichat.background_uri"
                        v-on:change="e => onChangeContent('background_uri', e)"
                      ></v-text-field>
                      <span class="headline" dark>
                        <v-btn
                          @click="
                            isEditImage = true;
                            isUpdateMiniChat = true;
                            isBackgroundUri = true;
                          "
                        >
                          <v-icon>image</v-icon>Select
                        </v-btn>
                      </span>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music"
                        v-model="minichat.bgm_uri"
                      ></v-text-field>
                      <v-btn @click="isEditSound = true">Select Sound</v-btn>
                    </v-flex>
                    <ResourceSelect
                      v-if="isEditImage"
                      resourceType="image"
                      :isShow="isEditImage"
                      :close="closeEditImage"
                      :save="saveEditImage"
                      :value="selectImageUri"
                      :onSelect="onSelectImage"
                    />
                    <ResourceSelect
                      v-if="isEditSound"
                      resourceType="sound"
                      :isShow="isEditSound"
                      :close="closeEditSound"
                      :save="saveEditSound"
                      :value="selectSoundUri"
                      :onSelect="onSelectSound"
                    />
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>

    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeAddMinichat"
      :isLastStep="valid"
      title="Create Mini Chat"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-checkbox
                  type="checkbox"
                  label="Skip Legacy Process"
                  v-model="minichat.skip_legacy_process"
                >
                </v-checkbox>
              </v-flex>
              <v-flex>
                <v-text-field
                  :rules="nameRules"
                  label="Name"
                  v-model="minichat.name"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-select
                  label="Main Character"
                  v-model="minichat.main_character"
                  :items="constant.MAIN_CHARACTER"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                  label="Position"
                  v-model="minichat.position"
                  :items="constant.CHARACTER_POSITION"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                  label="Size"
                  v-model="minichat.size"
                  :items="constant.CHARACTER_SIZE"
                ></v-select>
              </v-flex>
              <v-flex>
                <img
                  v-if="minichat.background_uri"
                  :src="minichat.background_uri"
                  :alt="minichat.minichat"
                  class="content_img"
                />
                <v-text-field
                  label="Background Image (URL or Select file)"
                  v-model="minichat.background_uri"
                ></v-text-field>
                <span class="headline" dark>
                  <v-btn @click="isEditImage = true">
                    <v-icon>image</v-icon>Select
                  </v-btn>
                </span>
              </v-flex>
              <v-flex>
                <v-text-field
                  label="Background Music"
                  v-model="minichat.bgm_uri"
                ></v-text-field>
                <v-btn @click="isEditSound = true">Select Sound</v-btn>
              </v-flex>
              <ResourceSelect
                v-if="isEditImage"
                resourceType="image"
                :isShow="isEditImage"
                :close="closeEditImage"
                :save="saveEditImage"
                :value="selectImageUri"
                :onSelect="onSelectImage"
              />
              <ResourceSelect
                v-if="isEditSound"
                resourceType="sound"
                :isShow="isEditSound"
                :close="closeEditSound"
                :save="saveEditSound"
                :value="selectSoundUri"
                :onSelect="onSelectSound"
              />
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <!-- PointData -->
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        minichatDataStore && minichatDataStore.learned_words
          ? minichatDataStore.learned_words
          : null
      "
      :incidentalWords="
        minichatDataStore && minichatDataStore.incidental_words
          ? minichatDataStore.incidental_words
          : null
      "
      :rewards="
        minichatDataStore && minichatDataStore.reward
          ? minichatDataStore.reward
          : null
      "
      :sentences="
        minichatDataStore && minichatDataStore.sentence
          ? minichatDataStore.sentence
          : null
      "
      :phonics="
        minichatDataStore && minichatDataStore.phonic
          ? minichatDataStore.phonic
          : null
      "
    >
    </PointData>
  </Container>
</template>
<style>
/* .active {
  background-color: gainsboro;
}
.inactive {
  background-color: white;
} */
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
<script>
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import { mapActions, mapState } from 'vuex';
import constant from '../MiniChat/constant';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
export default {
  name: 'MiniChatList',
  components: {
    Container,
    Modal,
    ResourceSelect,
    PointData,
  },
  data: function () {
    return {
      isOpenPointData: false,
      isBackgroundUri: false,
      isUpdateMiniChat: false,
      selectedMiniChatIndex: -1,
      selectImageUri: '',
      selectSoundUri: '',
      isEditImage: false,
      isEditSound: false,
      valid: false,
      nameRules: [v => !!v || 'Name is required'],
      constant,
      minichat: {},
      node: {},
      isCreate: false,
    };
  },
  methods: {
    ...mapActions('minichat', [
      'fetchMiniChat',
      'selectMiniChat',
      'selectCategory',
      'clearMiniChatStateCreation',
      'addMiniChat',
      'updateCreateMiniChat',
      'updateCreateNode',
      'clearMindMapState',
      'getMindMap',
      'clearUpdateMiniChatState',
      'clearSelectedMiniChatState',
      'deleteMiniChat',
      'updateMiniChat',
      'updateMiniChatData',
      'clearSelectedCategoryId',
      'fetchMinichatInCategory',
      'fetchMinichatData',
      'clearMinichatDataState',
      'fetchNodeList',
      'fetchStartNode',
      'resetMinichatDataState',
    ]),
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.updateMiniChatData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateMiniChatData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateMiniChatData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateMiniChatData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateMiniChatData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onSaveEdit();
      this.selectedMiniChatIndex = -1;
      this.isOpenPointData = false;
    },
    async openAddMinichat() {
      this.updateCreateMiniChat({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.updateCreateMiniChat({
        key: 'skip_legacy_process',
        value: true,
      });
      this.minichat = await { ...this.minichatStore.addMiniChatData };
      this.isCreate = true;
    },
    closeAddMinichat() {
      this.clearMiniChatStateCreation();
      this.clearSelectedCategoryId();
      this.isCreate = false;
      this.$router.push('/minichat');
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isCreate) {
        this.updateCreateMiniChat({
          key: 'background_uri',
          value: uri,
        });
        this.minichat.background_uri = uri;
      } else if (this.isUpdateMiniChat && this.isBackgroundUri) {
        this.updateMiniChatData({
          key: 'background_uri',
          value: uri,
        });
        this.minichat.background_uri = uri;
      }
      this.isUpdateMiniChat = !this.isUpdateMiniChat;
      this.isBackgroundUri = !this.isBackgroundUri;
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isCreate) {
        this.updateCreateMiniChat({
          key: 'bgm_uri',
          value: uri,
        });
      } else {
        this.updateMiniChatData({
          key: 'bgm_uri',
          value: uri,
        });
      }
      this.minichat.bgm_uri = uri;
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    onChangeContent(key, value) {
      if (
        (key === 'skip_legacy_process' || key == 'isGuestPlayable') &&
        !value
      ) {
        value = false;
      }
      this.updateMiniChatData({ key, value });
    },
    onChangeAddMiniChatData(key, value) {
      if (key === 'skip_legacy_process' && !value) {
        value = false;
      }
      this.updateCreateMiniChat({ key, value });
    },
    validateAndSetDefaultValueBeforeCreate() {
      this.minichat.category_id = this.minichatStore.selectedCategoryId;
      this.minichat.node_list = [];
      this.minichat.main_character
        ? this.minichat.main_character
        : (this.minichat.main_character = 0);
      this.minichat.position
        ? this.minichat.position
        : (this.minichat.position = 0);
      this.minichat.size ? this.minichat.size : (this.minichat.size = 0);
    },
    async onSaveCreate() {
      await this.validateAndSetDefaultValueBeforeCreate();
      await this.addMiniChat({
        ...this.minichat,
      });
      this.$router.push({
        path: '/minichat',
      });
      this.isCreate = false;
      this.clearMiniChatStateCreation();
      this.clearSelectedCategoryId();
    },
    async onSaveEdit() {
      const updateMiniChatData = this.minichatStore.updateMiniChatData;
      await this.updateMiniChat({ ...updateMiniChatData });
    },
    onClickDelete(id) {
      this.deleteMiniChat(id);
    },
    onClickNodeManager(id) {
      this.fetchNodeList(id);
      this.$router.push({
        path: '/minichat/detail',
        query: {
          id,
        },
      });
    },
    onSelectMiniChat(id, index) {
      if (this.selectedMiniChatIndex !== index) {
        this.selectedMiniChatIndex = index;
        this.fetchMinichatData(id);
        this.fetchStartNode(id);
        this.clearUpdateMiniChatState();
        this.updateMiniChatData({
          key: '_id',
          value: id,
        });
      }
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },
  computed: {
    ...mapState({
      minichatStore: state => state.minichat,
      minichatsStore: state => state.minichat.minichats,
      minichatDataStore: state => state.minichat.minichatData,
      startNodeStore: state => state.minichat.startNodeList,
      usernameStore: state => state.userInfo.userData.username,
      selectedMinichatIndexStore: state => state.minichat.selectedMinichatIndex,
    }),
  },
  watch: {
    minichatDataStore(val) {
      if (val) {
        return (this.minichat = this.minichatDataStore);
      } else {
        this.$router.push('/minichat');
      }
    },
    selectedMinichatIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectMiniChat(this.$route.query.sub_id);
      }
    },
  },
  mounted() {
    this.clearMinichatDataState();
    this.clearMindMapState();
    if (this.$route.query.category_id) {
      this.fetchMinichatInCategory({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (this.minichatStore.selectedCategoryId) {
      this.openAddMinichat();
    } else if (Object.keys(this.minichatStore.selectedCategoryId).length < 1) {
      this.fetchMinichatInCategory({
        id: this.$route.query.id,
        sub_id: '',
      });
    }
  },
};
</script>
