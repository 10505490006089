<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/maze_game')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (selectedGameListStore && selectedGameListStore.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="selectedGameListStore">
          <v-layout row grid-list-md>
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Game</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" @click="isCreate = true">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(game, index) in gameDataStore"
                    :key="index"
                    @click="onClickSelectGame(game)"
                    :class="index === selectedGameIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ game.title }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs5 mr-2 v-if="selectedGameIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Game Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onClickUpdateGame">
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <div>
                      <v-tabs
                        v-model="active"
                        color="black"
                        dark
                        slider-color="yellow"
                      >
                        <v-tab v-for="n in 6" :key="n" ripple>
                          {{ menuLabel(n - 1) }}
                        </v-tab>
                        <v-tab-item v-for="n in 6" :key="n">
                          <v-flex v-if="n === 1">
                            <Game :game="game" />
                          </v-flex>
                          <v-flex v-if="n === 2">
                            <Maze :maze="maze" />
                          </v-flex>
                          <v-flex v-if="n === 3">
                            <Player
                              :player="player"
                              @onchangeToggleSprite="onchangeToggleSpritePlayer"
                              @saveSpriteSheetData="saveSpriteSheetDataPlayer"
                            />
                          </v-flex>
                          <v-flex v-if="n === 4">
                            <Finish
                              :finish="finish"
                              @onchangeToggleSprite="onchangeToggleSpriteFinish"
                              @saveSpriteSheetData="saveSpriteSheetDataFinish"
                            />
                          </v-flex>
                          <v-flex v-if="n === 5">
                            <Item
                              :item="item"
                              :isOpen="isOpenItemManager"
                              :closeModal="closeModalItemManager"
                              :title="'Item Manager'"
                              @onchangeCheckBoxItem="onchangeCheckBoxItem"
                              @save="saveDataItem"
                              @close="cancelSaveDataItem"
                            />
                          </v-flex>
                          <v-flex v-if="n === 6">
                            <div class="point-data">
                              <v-btn
                                block
                                large
                                class="white--text"
                                color="pointdata"
                                @click="isOpenPointData = true"
                                >Point Data</v-btn
                              >
                            </div>
                            <v-divider></v-divider>
                          </v-flex>
                        </v-tab-item>
                      </v-tabs>
                      <div class="text-xs-center mt-3">
                        <v-btn @click="next">next tab</v-btn>
                      </div>
                    </div>
                  </v-layout>
                </v-card-text>
                <v-btn
                  block
                  color="red"
                  dark
                  @click="onClickDeleteGame(game._id)"
                >
                  <v-icon>delete</v-icon>Delete game
                </v-btn>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeModalCreate"
      :isLastStep="valid"
      title="Create Game"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  label="Title"
                  v-model="creategame.title"
                  :rules="requiredRule"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <!-- <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    /> -->
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="selectedGameIndex !== -1 ? game.learned_words : null"
      :incidentalWords="selectedGameIndex !== -1 ? game.incidental_words : null"
      :rewards="selectedGameIndex !== -1 ? game.reward : null"
      :sentences="selectedGameIndex !== -1 ? game.sentence : null"
      :phonics="selectedGameIndex !== -1 ? game.phonic : null"
    >
    </PointData>
  </Container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Container from '../../components/Container.vue';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
import { Game, Player, Finish, Item, Maze } from './components/';
import swal from 'sweetalert2';
import Modal from '../../components/Modal.vue';

export default {
  name: 'MazeGame',
  components: {
    Container,
    // ResourceSelect,
    PointData,
    Game,
    Player,
    Finish,
    Item,
    Maze,
    Modal,
  },
  data() {
    return {
      selectedGameIndex: -1,
      active: null,
      isOpenPointData: false,
      isOpenItemManager: false,
      mazeSize: [4, 5, 6, 7, 8],
      game: {},
      maze: {},
      player: {},
      finish: {},
      item: {},
      creategame: {},
      valid: true,
      isCreate: false,
      requiredRule: [v => !!v || 'This field is required'],
    };
  },
  computed: {
    // ...mapState(['maze_game']),
    ...mapGetters('maze_game', [, 'gameDataStore', 'selectedGameListStore']),
  },
  methods: {
    ...mapActions('maze_game', [
      'updateGame',
      'updateGameKey',
      'deleteGame',
      'getGameData',
      'getCategoryByGameListId',
      'createGameByListId',
      'getGameDataByObjectId',
    ]),
    onClickSelectGame(data) {
      const gameStore = this.gameDataStore;
      this.selectedGameIndex = gameStore.findIndex(
        game => game._id === data._id,
      );
      this.game = gameStore[this.selectedGameIndex];
      this.maze = this.game.maze;
      this.player = this.game.player;
      this.finish = this.game.finish;
      this.item = this.game.item;
    },
    onClickUpdateGame() {
      swal
        .fire({
          titleText: 'Are you sure.',
          type: 'warning',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
            const data = {
              ...this.game,
              maze: this.maze,
              player: this.player,
              finish: this.finish,
              item: this.item,
            };
            this.isOpenPointData = false;
            this.updateGame(data);
          }
        });
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.game.learned_words = learned_words;
      }
      if (incidental_words) {
        this.game.incidental_words = incidental_words;
      }
      if (reward) {
        this.game.reward = reward;
      }
      if (sentence) {
        this.game.sentence = sentence;
      }
      if (phonic) {
        this.game.phonic = phonic;
      }
      this.onClickUpdateGame();
      // this.isOpenPointData = false;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    menuLabel(index) {
      const label = [
        'Game',
        'Maze',
        'Player',
        'Finish Line',
        'Item',
        'Point Data',
      ];
      return label[index];
    },
    next() {
      const active = parseInt(this.active);
      this.active = active < 5 ? active + 1 : 0;
    },
    createMazeGame() {},
    onClickDeleteGame(id) {
      swal
        .fire({
          titleText: 'Confirm Delete Game',
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteGame({ id: id });
          }
        });
    },
    onchangeCheckBoxItem(toggle) {
      this.item.is_collect_item_to_end = toggle;
    },
    openModalItemManager() {
      this.isOpenItemManager = true;
    },
    closeModalItemManager() {
      this.isOpenItemManager = false;
    },
    onchangeToggleSpritePlayer(key, key_data, { value }) {
      this.player[key] = value;
    },
    onchangeToggleSpriteFinish(key, key_data, { value }) {
      this.finish[key] = value;
    },
    saveSpriteSheetDataPlayer(key, { value }) {
      this.player[key] = value;
    },
    saveSpriteSheetDataFinish(key, { value }) {
      this.finish[key] = value;
    },
    saveDataItem(data) {
      this.item.item_set = [...data];
    },
    cancelSaveDataItem(data) {
      this.item.item_set = [...data];
    },
    closeModalCreate() {
      this.isCreate = false;
      this.creategame = {};
    },
    onSaveCreate() {
      this.creategame.game_list_id = this.$route.query.id;
      this.createGameByListId(this.creategame);
      this.isCreate = false;
      this.creategame = {};
    },
  },
  async mounted() {
    if (Object.keys(this.selectedGameListStore).length === 0) {
      if (this.$route.query.category_id && this.$route.query.sub_id) {
        await this.getGameDataByObjectId(this.$route.query.sub_id);
      } else {
        await this.getGameData(this.$route.query.id);
      }
      setTimeout(() => {
        this.getCategoryByGameListId(this.gameDataStore[0].game_list_id);
      }, 1000);
    }
  },
};
</script>
