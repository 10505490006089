<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/running_game')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ runningGameList.category }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="showModalCreate()">
            <v-icon>add</v-icon>New Running Game
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            v-if="!isLoadRunningGame"
            :items="runningGameList.running_games"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>
                <v-layout justify-center>{{ props.index + 1 }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.title }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  <v-chip color="primary" text-color="white">{{
                    formatDisplayCharacter(props.item.character)
                  }}</v-chip>
                </v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  <v-chip color="primary" text-color="white">{{
                    formatDisplayTheme(props.item.theme)
                  }}</v-chip></v-layout
                >
              </td>
              <td>
                <v-layout justify-center>{{
                  props.item.correct_count
                }}</v-layout>
              </td>
              <td>
                <v-layout justify-center> {{ props.item.updated_at }}</v-layout>
              </td>
              <td>
                <v-layout justify-end>
                  <v-btn
                    icon
                    color="black"
                    @click="onClickAddHomePage(props.item._id)"
                  >
                    <v-icon>home</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="black"
                    v-on:click="onClickEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item._id)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreateUpdate
      :isOpen="isShowModal"
      :isCreate="isCreate"
      @close="isShowModal = false"
    ></ModalCreateUpdate>
    <HomeDataAssign
      :isOpen="isAddHomepage"
      :gameData="selectedRunningGame"
      :type="constantHomePage.GameType.PgGame"
      :typePgGame="constantHomePage.typePgGame.RunningGame"
      @close="isAddHomepage = false"
    ></HomeDataAssign>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../../components/Container';
import { ModalCreateUpdate } from './components';
import rules from '../../../utils/ruleValidate';
import swal from 'sweetalert2';
import constants from './components/constants';
import HomeDataAssign from '../../HomePage/components/HomeDataAssign.vue';
import constantHomePage from '../../HomePage/constant';

export default {
  name: 'RunningGame',
  components: {
    Container,
    ModalCreateUpdate,
    HomeDataAssign,
  },
  data() {
    return {
      constants,
      constantHomePage,
      search: '',
      headers: [
        {
          text: 'No.',
          value: 'no.',
          align: 'center',
          sortable: false,
        },
        {
          text: 'title',
          value: 'title',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Character',
          value: 'character',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Theme',
          value: 'theme',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Correct Count',
          value: 'correct_count',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
          align: 'center',
          sortable: false,
        },
      ],
      isCreate: false,
      isShowModal: false,
      rules,
      isAddHomepage: false,
    };
  },
  async mounted() {
    const { id, game_list_id, category_id, sub_id } = this.$route.query;
    if (id) {
      await this.fetchRunningGameList({ id });
      this.onClickEdit(id);
    } else if (game_list_id) {
      await this.fetchRunningGameList({
        game_list_id,
      });
    } else if (category_id && sub_id) {
      await this.fetchRunningGameList({
        category_id,
      });
      this.onClickEdit(sub_id);
    }
  },
  computed: {
    ...mapState({
      runningGameStore: state => state.running_game,
      runningGameList: state => state.running_game.runningGameList,
      selectedRunningGame: state => state.running_game.selectedRunningGame,
      isLoadRunningGame: state => state.running_game.isLoadRunningGame,
      defaultCreate: state => state.driving_game.defaultCreate,
    }),
  },
  methods: {
    ...mapActions('running_game', [
      'fetchRunningGameList',
      'deleteRunningGame',
      'setStateByKey',
    ]),
    setSelectedRunningGame(id) {
      const [selectedData] = this.runningGameList.running_games.filter(
        ({ _id }) => _id == id,
      );
      if (selectedData) {
        this.setStateByKey({
          statekey: 'selectedRunningGame',
          value: selectedData,
        });
      }
    },
    onClickAddHomePage(id) {
      this.setSelectedRunningGame(id);
      this.isAddHomepage = true;
    },
    onClickEdit(id) {
      this.setSelectedRunningGame(id);
      this.setStateByKey({
        statekey: 'updateRunningGame',
        key: 'id',
        value: id,
      });
      this.isShowModal = true;
      this.isCreate = false;
    },
    onClickDelete(id) {
      swal
        .fire({
          titleText: 'Are you sure.',
          type: 'warning',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
            this.deleteRunningGame(id);
          }
        });
    },
    formatDisplayTheme(value) {
      if (value >= 0) {
        switch (value) {
          case constants.theme.CITY:
            return 'CITY';
          case constants.theme.JUNGLE:
            return 'JUNGEL';
          case constants.theme.SPACE:
            return 'SPACE';
          default:
            return null;
        }
      } else {
        return null;
      }
    },
    formatDisplayCharacter(value) {
      if (value >= 0) {
        switch (value) {
          case constants.character.MAXI:
            return 'MAXI';
          case constants.character.NANA:
            return 'NANA';
          case constants.character.NODDLE:
            return 'NODDLE';
          default:
            return null;
        }
      } else {
        return null;
      }
    },
    showModalCreate() {
      this.isShowModal = true;
      this.isCreate = true;
      this.setStateByKey({
        statekey: 'createRunningGame',
        value: this.defaultCreate,
      });
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}
</style>
