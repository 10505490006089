<template>
  <v-toolbar dark>
    <v-toolbar-side-icon
      v-if="userInfo.isLogin"
      @click.stop="toggleDrawer"
    ></v-toolbar-side-icon>
    <v-toolbar-title>
      <v-flex>
        ⭐Galaxy Kids Admin Tool
        <v-chip color="red" text-color="white">Development</v-chip>
      </v-flex>
    </v-toolbar-title>
    <v-flex shrink pt-3 ml-4>
      <v-switch v-model="darkThemeSwitch" label="Dark Mode"></v-switch>
    </v-flex>
    <v-flex shrink pt-3 ml-4>
      <v-switch
        v-if="isSwitchServer"
        v-model="serverSwitch"
        @change="e => onClickSwitchServer(e)"
        :label="`${server} Server`"
      ></v-switch>
    </v-flex>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn v-if="!userInfo.isLogin" flat v-on:click="onClickLogin"
        >Login</v-btn
      >
      <v-menu
        v-if="userInfo.isLogin"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on }">
          <v-btn flat dark v-on="on" v-if="userInfo.userData.role_id === 100">
            {{ userInfo.userData.username }} &nbsp; | &nbsp; Admin</v-btn
          >
          <v-btn flat dark v-on="on" v-if="userInfo.userData.role_id === 99">
            {{ userInfo.userData.username }} &nbsp; | &nbsp; Developer</v-btn
          >
          <v-btn flat dark v-on="on" v-if="userInfo.userData.role_id === 98">
            {{ userInfo.userData.username }} &nbsp; | &nbsp; Media</v-btn
          >
          <v-btn flat dark v-on="on" v-if="userInfo.userData.role_id === 97">
            {{ userInfo.userData.username }} &nbsp; | &nbsp; Marketing</v-btn
          >
          <v-btn flat dark v-on="on" v-if="userInfo.userData.role_id === 96">
            {{ userInfo.userData.username }} &nbsp; | &nbsp; Content
            Creator</v-btn
          >
          <v-btn flat dark v-on="on" v-if="userInfo.userData.role_id === 50">
            {{ userInfo.userData.username }} &nbsp; | &nbsp; Translator</v-btn
          >
          <v-btn flat dark v-on="on" v-if="userInfo.userData.role_id === 51">
            {{ userInfo.userData.username }} &nbsp; | &nbsp; Customer
            Service</v-btn
          >
        </template>
        <v-card>
          <v-list>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>{{
                  userInfo.userData.username | capitalize
                }}</v-list-tile-title>
                <v-list-tile-title v-if="userInfo.userData.role_id === 100">
                  Admin
                </v-list-tile-title>
                <v-list-tile-title v-if="userInfo.userData.role_id === 99">
                  Developer
                </v-list-tile-title>
                <v-list-tile-title v-if="userInfo.userData.role_id === 98">
                  Media
                </v-list-tile-title>
                <v-list-tile-title v-if="userInfo.userData.role_id === 97">
                  Marketing
                </v-list-tile-title>
                <v-list-tile-title v-if="userInfo.userData.role_id === 96">
                  Content Creator
                </v-list-tile-title>
                <v-list-tile-title v-if="userInfo.userData.role_id === 50">
                  Translator
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn dark color="red" v-on:click="logoutUser">Logout</v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
          <v-divider></v-divider>
          <!-- <v-list>
            <v-list-tile>
                <v-list-tile-action>
                  <v-switch v-model="message" color="purple"></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>Enable messages</v-list-tile-title>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <v-switch v-model="hints" color="purple"></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>Enable hints</v-list-tile-title>
              </v-list-tile>
          </v-list>-->
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn flat @click="menu = false">Cancel</v-btn>
            <v-btn color="primary" flat @click="menu = false">Save</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar-items>
    <!-- <v-toolbar-items class="hidden-sm-and-down">
      <v-btn flat>Link Three</v-btn>
    </v-toolbar-items>-->
  </v-toolbar>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import jwtDecode from 'jwt-decode';
import config from '../config';

export default {
  name: 'Toolbar',
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    ...mapActions('userInfo', ['logoutUser', 'loginUser']),
    ...mapMutations('userInfo', ['storeLoginInfo']),
    ...mapActions('appConfig', ['toggleDrawer']),

    onClickLogin: function () {
      this.$router.push('/login').catch(error => {
        // throw new Error(error);
      });
    },
    onClickSwitchServer(isTrusted) {
      if (isTrusted) {
        this.server = 'Production';
        window.$cookies.set('server', config.cookiesHashUrlServer.production);
      } else {
        this.server = 'Development';
        window.$cookies.set('server', config.cookiesHashUrlServer.development);
      }
      this.$router.go();
    },
    checkSwichServer() {
      const role = this.userInfo.userData.role_id;
      if (role > 0 && role <= 50) {
        // clear the cookies
        window.$cookies.remove('server');
      }
      if (this.$cookies.get('server')) {
        this.server =
          this.$cookies.get('server') === config.cookiesHashUrlServer.production
            ? 'Production'
            : 'Development';
        this.serverSwitch =
          this.$cookies.get('server') === config.cookiesHashUrlServer.production
            ? true
            : false;
      } else {
        this.server = 'Development';
        this.serverSwitch = false;
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
    }),
    isSwitchServer() {
      const role = this.userInfo.userData.role_id;
      if (Object.keys(this.$route.query).length > 0 || role <= 50 || !role)
        return false;
      else return true;
    },
  },
  watch: {
    darkThemeSwitch(value) {
      if (value) {
        this.$emit('toggleDarkTheme');
      } else {
        this.$emit('toggleDarkTheme');
      }
    },
  },
  data() {
    return {
      darkThemeSwitch: true,
      serverSwitch: false,
      server: 'Development',
    };
  },
  mounted: function () {
    if (this.$cookies.get('token')) {
      const token = this.$cookies.get('token');
      const userData = jwtDecode(token);
      this.storeLoginInfo({ ...userData });
    } else {
      if (this.$router.currentRoute.path !== '/login') {
        this.$router.push('/login');
      }
    }
    this.checkSwichServer();
  },
};
</script>
<style>
.username {
  margin: 20px;
}
</style>
