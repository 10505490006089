<template>
  <Modal
    v-if="isOpen"
    :isOpen="isOpen"
    :save="save"
    :close="close"
    :isLastStep="valid"
    title="Create Home Group Data"
  >
    <v-card>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-text-field
                :value="createData.title"
                v-model="createData.title"
                label="title"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
    </v-card>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import ruleValidate from '../../../utils/ruleValidate';

export default {
  components: { Modal },
  data() {
    return {
      valid: true,
      rules: ruleValidate,
      createData: {},
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
    }),
  },
  methods: {
    ...mapActions('homepage', ['createGroupPageData']),
    save() {
      this.$emit('close');
      this.createGroupPageData(this.createData);
      this.createData = {};
    },
    close() {
      this.$emit('close');
      this.createData = {};
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.createData = {
          ...this.pageGameStore.defaultCreateHomeDataList,
          topic_id: this.pageGameStore.updatePageTopic.id,
        };
      }
    },
  },
};
</script>

<style></style>
