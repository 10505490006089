var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[_c('v-flex',[(!_vm.storyStore.isLoadStoryGame)?_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","fab":"","color":"white","small":""},on:{"click":_vm.onClickBack}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.storyStore.selectedStoryGame.name))])],1),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","grid-list-md":""}},[_c('v-flex',{attrs:{"mr-2":"","xs3":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Level Detail")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.onClickUpdateLevel}},[_c('v-icon',[_vm._v("save")]),_vm._v("Save ")],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-xs":"","text-xs-center":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Available for Free User","value":_vm.storyStore.selectedStoryGame.isGuestPlayable},on:{"change":function (e) { return _vm.onChangeLevel('isGuestPlayable', e); }},model:{value:(_vm.storyStore.selectedStoryGame.isGuestPlayable),callback:function ($$v) {_vm.$set(_vm.storyStore.selectedStoryGame, "isGuestPlayable", $$v)},expression:"storyStore.selectedStoryGame.isGuestPlayable"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Name","value":_vm.storyStore.selectedStoryGame.name},on:{"change":function (value) { return _vm.onChangeLevel('name', value); }}})],1),_c('v-flex',[_c('v-layout',[_c('v-flex',{attrs:{"mt-3":""}},[_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){_vm.isEditImage = true;
                              _vm.isEditLevel = true;}}},[_c('v-icon',[_vm._v("edit")]),_vm._v("EDIT IMAGE ")],1)],1)],1),_c('v-img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                          _vm.storyStore.updateLevel.img_uri ||
                          _vm.storyStore.selectedStoryGame.img_uri
                        ),expression:"\n                          storyStore.updateLevel.img_uri ||\n                          storyStore.selectedStoryGame.img_uri\n                        "}],staticClass:"content_img",attrs:{"src":_vm.storyStore.updateLevel.img_uri ||
                          _vm.storyStore.selectedStoryGame.img_uri}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":_vm.onClickDeleteLevel}},[_c('v-icon',[_vm._v("delete")]),_vm._v("Delete this level ")],1)],1)],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"mr-2":"","xs2":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Activities")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){_vm.onClickCreateActivity();
                    _vm.selectedActivityIndex = -1;}}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-list',[_c('draggable',{on:{"end":_vm.order}},_vm._l((_vm.storyStore.selectedStoryGame
                      .activities),function(activity,index){return _c('v-list-tile',{key:activity._id,class:index === _vm.selectedActivityIndex ? 'active' : 'inactive',on:{"click":function($event){return _vm.onSelectActivity(index, activity._id)}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(index + 1)+" "+_vm._s(activity.title))]),_c('v-list-tile-sub-title',[_vm._v("Game type : "+_vm._s(activity.game_data[0].game_type))])],1)],1)}),1)],1)],1)],1),(_vm.selectedActivityIndex != -1)?_c('v-flex',{attrs:{"mr-2":""}},[_c('v-card',{attrs:{"dark":""}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Activity Detail")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.onUpdateActivity}},[_c('v-icon',[_vm._v("save")]),_vm._v("Save ")],1)],1),_c('v-card-text',[_c('v-container',[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Available for Free User","value":_vm.activity.isGuestPlayable},on:{"change":function (e) { return _vm.onChangeActivityData('isGuestPlayable', e); }},model:{value:(_vm.activity.isGuestPlayable),callback:function ($$v) {_vm.$set(_vm.activity, "isGuestPlayable", $$v)},expression:"activity.isGuestPlayable"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Title","value":_vm.activity.title},on:{"change":function (e) { return _vm.onChangeActivityData('title', e); }}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Icon","value":_vm.storyStore.updateActivity.icon || _vm.activity.icon},on:{"change":function (e) { return _vm.onChangeActivityData('icon', e); }}}),_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){_vm.isEditImage = true;
                          _vm.isEditActivity = true;}}},[_c('v-icon',[_vm._v("edit")]),_vm._v("EDIT ICON ")],1),_c('v-img',{staticClass:"content_img",attrs:{"src":_vm.storyStore.updateActivity.icon || _vm.activity.icon}})],1),_c('v-flex',[_c('v-select',{attrs:{"items":_vm.transition,"label":"Transition","value":_vm.activity.transition},on:{"change":function (e) { return _vm.onChangeActivityData('transition', e); }},model:{value:(_vm.activity.transition),callback:function ($$v) {_vm.$set(_vm.activity, "transition", $$v)},expression:"activity.transition"}})],1),_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Skip","value":_vm.activity.skip},on:{"change":function (e) { return _vm.onChangeActivityData('skip', e); }},model:{value:(_vm.activity.skip),callback:function ($$v) {_vm.$set(_vm.activity, "skip", $$v)},expression:"activity.skip"}})],1),_vm._l((_vm.activity.game_data),function(data,index){return _c('v-flex',{key:data},[_c('container',[_c('v-layout',{attrs:{"column":""}},[_c('v-card-actions',[_c('label',[_c('b',[_vm._v("🎯 GAME "+_vm._s(index + 1)+":")])]),_c('v-spacer'),(
                                _vm.activity.game_data.length > 1 && index > 0
                              )?_c('v-btn',{attrs:{"icon":"","color":"blue","dark":""},on:{"click":function($event){return _vm.onMoveUp(index)}}},[_c('v-icon',[_vm._v("keyboard_arrow_up")])],1):_vm._e(),(
                                _vm.activity.game_data.length > 1 &&
                                index < _vm.activity.game_data.length - 1
                              )?_c('v-btn',{attrs:{"icon":"","color":"blue","dark":""},on:{"click":function($event){return _vm.onMoveDown(index)}}},[_c('v-icon',[_vm._v("keyboard_arrow_down")])],1):_vm._e(),(_vm.activity.game_data.length > 1)?_c('v-btn',{attrs:{"icon":"","color":"red","dark":""},on:{"click":function($event){return _vm.onDeleteGame(index)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()],1),_c('v-flex',[_c('v-select',{attrs:{"items":_vm.constants.GAME_TYPE,"label":"Game Type","value":data.game_type},on:{"change":function (e) { return _vm.onChangeGameData('game_type', e, index); }},model:{value:(data.game_type),callback:function ($$v) {_vm.$set(data, "game_type", $$v)},expression:"data.game_type"}})],1),_c('v-flex',[_c('v-select',{attrs:{"items":_vm.storyStore.gameType[data.game_type],"label":"Category","value":data.game_id},on:{"change":function (e) { return _vm.onChangeGameData('game_id', e, index); }},model:{value:(data.game_id),callback:function ($$v) {_vm.$set(data, "game_id", $$v)},expression:"data.game_id"}})],1),(
                              _vm.requiredSubIdList.indexOf(data.game_type) > -1
                            )?_c('v-flex',[_c('container',[_c('v-layout',{staticClass:"elevation-2",staticStyle:{"padding":"20px"},attrs:{"column":""}},[_c('label',[_c('u',[_vm._v("⌗ Sub data")])]),_vm._l((data.sub_id_list),function(id,idx){return _c('td',{key:id},[_c('v-layout',{attrs:{"align-content-space-between":""}},[_c('v-select',{attrs:{"items":_vm.subTitleList[index],"value":data.sub_id_list[idx]},on:{"change":function (e) { return _vm.onChangeSubTitleData(index, idx, e); }}}),_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(id),expression:"id",arg:"copy"}],attrs:{"icon":"","dark":"","color":"black"}},[_c('v-icon',[_vm._v("filter_none")])],1)],1)],1)}),(
                                    _vm.selectedGameDataIndex == index &&
                                    _vm.newSubId == true
                                  )?_c('td',[_c('v-layout',{attrs:{"align-content-space-between":""}},[_c('v-select',{attrs:{"items":_vm.subTitleList,"value":_vm.subTitleId},on:{"change":function (e) { return _vm.onChangeSubTitleData(
                                            index,
                                            data.sub_id_list.length,
                                            e
                                          ); }}}),_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.subTitleId),expression:"subTitleId",arg:"copy"}],attrs:{"icon":"","dark":"","color":"black"}},[_c('v-icon',[_vm._v("filter_none")])],1)],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),(
                                      data.sub_id_list.length > 1 || _vm.newSubId
                                    )?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.onRemoveSubTitle(index)}}},[_c('v-icon',[_vm._v("remove")])],1):_c('v-btn',{attrs:{"icon":"","color":"grey"}},[_c('v-icon',[_vm._v("remove")])],1),(!_vm.newSubId)?_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.onAddSubTitle(index)}}},[_c('v-icon',[_vm._v("add")])],1):_c('v-btn',{attrs:{"icon":"","color":"grey"}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1)],1):_vm._e(),(
                              _vm.requiredVideoData.indexOf(data.game_type) > -1
                            )?_c('v-flex',[(data.game_type === 'MNC')?_c('container',[_c('v-layout',{staticClass:"elevation-2",staticStyle:{"padding":"20px"},attrs:{"column":""}},[_c('label',[_c('u',[_vm._v("⌗ Video data")])]),_vm._l((data.video_data),function(vdo,idx){return _c('v-flex',{key:idx,staticClass:"elevation-2",staticStyle:{"padding":"10px","margin-top":"5px","margin-bottom":"5px"}},[_c('label',[_vm._v("Video "+_vm._s(idx + 1)+":")]),_c('v-flex',[_c('v-checkbox',{attrs:{"readonly":"","disabled":"","label":"Is Loop?","value":vdo.isLoop},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'isLoop',
                                            e
                                          ); }},model:{value:(vdo.isLoop),callback:function ($$v) {_vm.$set(vdo, "isLoop", $$v)},expression:"vdo.isLoop"}}),_c('v-checkbox',{attrs:{"label":"Show Subtitle Video","value":vdo.show_subtitle_video},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'show_subtitle_video',
                                            e
                                          ); }},model:{value:(vdo.show_subtitle_video),callback:function ($$v) {_vm.$set(vdo, "show_subtitle_video", $$v)},expression:"vdo.show_subtitle_video"}})],1),_c('v-flex',[_c('v-autocomplete',{attrs:{"readonly":"","disabled":"","label":"Video Link","items":_vm.minichatVideoURIsState,"chips":"","hide-details":"","hide-no-data":"","hide-selected":""},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'video_uri',
                                            e
                                          ); }},model:{value:(vdo.video_uri),callback:function ($$v) {_vm.$set(vdo, "video_uri", $$v)},expression:"vdo.video_uri"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"readonly":"","disabled":"","label":"Next Index","value":vdo.nextIndex},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'nextIndex',
                                            parseInt(e)
                                          ); }}})],1)],1)}),_c('v-card-actions',[_c('v-spacer'),(
                                      data.video_data &&
                                      data.video_data.length > 1
                                    )?_c('v-btn',{attrs:{"disabled":"","readonly":"","icon":"","color":"red"},on:{"click":function($event){return _vm.onRemoveVideoData(index)}}},[_c('v-icon',[_vm._v("remove")])],1):_c('v-btn',{attrs:{"icon":"","color":"grey"}},[_c('v-icon',[_vm._v("remove")])],1),_c('v-btn',{attrs:{"disabled":"","readonly":"","icon":"","color":"green"},on:{"click":function($event){return _vm.onAddVideoData(index)}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1):_c('container',[_c('v-layout',{staticClass:"elevation-2",staticStyle:{"padding":"20px"},attrs:{"column":""}},[_c('label',[_c('u',[_vm._v("⌗ Video data")])]),_vm._l((data.video_data),function(vdo,idx){return _c('v-flex',{key:idx,staticClass:"elevation-2",staticStyle:{"padding":"10px","margin-top":"5px","margin-bottom":"5px"}},[_c('label',[_vm._v("Video "+_vm._s(idx + 1)+":")]),_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Is Loop?","value":vdo.isLoop},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'isLoop',
                                            e
                                          ); }},model:{value:(vdo.isLoop),callback:function ($$v) {_vm.$set(vdo, "isLoop", $$v)},expression:"vdo.isLoop"}}),_c('v-checkbox',{attrs:{"label":"Show Subtitle Video","value":vdo.show_subtitle_video},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'show_subtitle_video',
                                            e
                                          ); }},model:{value:(vdo.show_subtitle_video),callback:function ($$v) {_vm.$set(vdo, "show_subtitle_video", $$v)},expression:"vdo.show_subtitle_video"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Video Link","value":vdo.video_uri},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'video_uri',
                                            e
                                          ); }}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Next Index","value":vdo.nextIndex},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'nextIndex',
                                            parseInt(e)
                                          ); }}})],1)],1)}),_c('v-card-actions',[_c('v-spacer'),(
                                      data.video_data &&
                                      data.video_data.length > 1
                                    )?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.onRemoveVideoData(index)}}},[_c('v-icon',[_vm._v("remove")])],1):_c('v-btn',{attrs:{"icon":"","color":"grey"}},[_c('v-icon',[_vm._v("remove")])],1),_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.onAddVideoData(index)}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1)],1):_vm._e()],1)],1)],1)}),(
                        _vm.requiredSubIdList.indexOf(
                          _vm.activity.game_data[0].game_type
                        ) > -1
                      )?_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.onAddMoreGame}},[_c('v-icon',[_vm._v("add")]),_vm._v("Add More Game ")],1):_vm._e(),_c('v-flex',[_c('h2',[_vm._v("Custom Configuration")]),_c('v-jsoneditor',{staticClass:"editor",attrs:{"plus":false,"height":"400px","options":_vm.editorOption,"value":_vm.activity.config || {}}})],1)],2),_c('v-card-actions',[(
                        _vm.storyStore.selectedStoryGame.activities.length > 1
                      )?_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.onClickDeleteActivity(_vm.activity._id)}}},[_c('v-icon',[_vm._v("delete")]),_vm._v("Delete this activity ")],1):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)],1)],1):_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Loading...")])],1),_c('v-progress-linear',{attrs:{"indeterminate":true}})],1)],1),(_vm.isEditImage)?_c('ResourceSelect',{attrs:{"resourceType":"image","isShow":_vm.isEditImage,"close":_vm.closeEditImage,"value":_vm.selectImageUri,"onSelect":_vm.onSelectImage,"save":_vm.onSaveResource}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }