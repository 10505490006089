<template>
  <div>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Action Part</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout column>
            <v-flex>
              <img
                class="image_thumbnail"
                :src="selectedChoice.action_image_uri"
              />
              <v-text-field
                label="Image URI (URL or Select file)"
                :value="selectedChoice.action_image_uri"
                v-model="selectedChoice.action_image_uri"
                @change="e => onchangeChoice('action_image_uri', e)"
                :rules="[rules.validURI]"
              ></v-text-field>
              <v-btn class="btn-center" @click="onclickEditImage('action')"
                ><v-icon>image</v-icon>Select Image URI</v-btn
              >
              <SpriteSheet
                :title="'Use Sprite Sheet'"
                :isToggle="selectedChoice.is_action_sprite_sheet"
                :fieldname="'Edit Sheet Data'"
                :object="selectedChoice.action_sprite_sheet_data"
                @onchangeToggleSprite="
                  onchangeToggleSprite('is_action_sprite_sheet', $event)
                "
                @saveSpriteSheetData="
                  saveSpriteSheetData('action_sprite_sheet_data', $event)
                "
              />

              <v-layout row>
                <v-text-field
                  type="number"
                  label="Start X"
                  :value="selectedChoice.action_position.startX"
                  @change="e => onchangeActionPosition('startX', e)"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="End X"
                  :value="selectedChoice.action_position.endX"
                  @change="e => onchangeActionPosition('endX', e)"
                ></v-text-field>
              </v-layout>
              <v-layout row>
                <v-text-field
                  type="number"
                  label="Start Y"
                  :value="selectedChoice.action_position.startY"
                  @change="e => onchangeActionPosition('startY', e)"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="End Y"
                  :value="selectedChoice.action_position.endY"
                  @change="e => onchangeActionPosition('endY', e)"
                ></v-text-field>
              </v-layout>
              <v-flex>
                <v-text-field
                  label="TTS Message"
                  :value="selectedChoice.action_tts_message"
                  v-on:change="e => onchangeChoice('action_tts_message', e)"
                ></v-text-field>
                <v-select
                  :items="constants.VOICE_NAME"
                  label="Voice Name"
                  :value="selectedChoice.action_voice_name"
                  v-model="selectedChoice.action_voice_name"
                  v-on:change="e => onchangeChoice('action_voice_name', e)"
                ></v-select>
              </v-flex>
              <v-text-field
                label="Action Sound URI (URL or Select file)"
                :value="selectedChoice.action_sound_uri"
                v-model="selectedChoice.action_sound_uri"
                v-on:change="e => onchangeChoice('action_sound_uri', e)"
                :rules="[rules.validURI]"
              ></v-text-field>
              <v-btn class="btn-center" @click="onclickMusicSound('action')"
                ><v-icon>audiotrack</v-icon>Select Sound URI</v-btn
              >
              <v-text-field
                label="Action Duaration (ms)"
                type="number"
                :value="selectedChoice.action_duration"
                v-model="selectedChoice.action_duration"
                @change="e => onchangeChoice('action_duration', e)"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import rules from '../../../utils/ruleValidate';
import SpriteSheet from '../../../components/SpriteSheetNew.vue';
import constants from '../../../constants';

export default {
  components: {
    ResourceSelect,
    SpriteSheet,
  },
  data() {
    return {
      constants,
      isEditImage: false,
      selectImageUri: '',
      isSelectImage: {
        action: false,
      },
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectMusic: {
        action: false,
      },
      valid: true,
      rules,
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
    selectedSetIndex() {
      return this.dragComboStore.selectedSetIndex;
    },
    selectedNodeIndex() {
      return this.dragComboStore.selectedNodeIndex;
    },
    selectedChoiceIndex() {
      return this.dragComboStore.selectedChoiceIndex;
    },
    selectedChoice() {
      return this.dragComboStore.selectedChoice;
    },
    updateChoice() {
      return this.dragComboStore.updateChoice;
    },
  },
  methods: {
    ...mapActions('dragcombo', [
      'updateSet',
      'setStateByKey',
      'deleteSetDragCombo',
      'deleteNodeDragCombo',
      'clearStateByKey',
    ]),
    onclickEditImage(type) {
      this.isEditImage = true;
      this.isSelectImage[type] = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImage.action:
          this.onchangeChoice('action_image_uri', uri);
          this.isSelectImage.action = false;
          // this.setDirty('game');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onclickMusicSound(type) {
      this.isSelectSound = true;
      this.isSelectMusic[type] = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectMusic.action:
          this.onchangeChoice('action_sound_uri', uri);
          this.isSelectMusic.action = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onchangeActionPosition(key, value) {
      const action_position = {
        ...this.selectedChoice.action_position,
        [key]: value,
      };
      this.onchangeChoice('action_position', action_position);
    },
    onchangeChoice(key, value) {
      this.setStateByKey({
        statekey: 'selectedChoice',
        key: key,
        value: value,
      });
      this.setStateByKey({
        statekey: 'updateChoice',
        key: key,
        value: value,
      });
      this.setStateByKey({
        statekey: 'updateNode',
        key: 'updateChoices',
        value: this.updateChoice,
      });
      this.setStateByKey({
        statekey: 'updateNode',
        key: 'indexChoice',
        value: this.selectedChoiceIndex,
      });
    },
    onchangeToggleSprite(key, { value }) {
      this.onchangeChoice(key, value);
    },
    saveSpriteSheetData(key, { value }) {
      this.onchangeChoice(key, value);
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
</style>
