<template>
  <v-dialog
    v-model="isOpen"
    persistent
    transition="slide-y-transition"
    :width="width"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>🔨 {{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="red" fab icon v-on:click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="resourceSelect">
        <slot />
      </v-card-text>
      <v-card-actions v-if="isLastStep">
        <v-btn color="red" v-on:click="close" flat>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="type == 'create'" color="green" dark v-on:click="save"
          >Save</v-btn
        >
        <v-btn v-if="type == 'confirm'" color="green" dark v-on:click="save"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.resourceSelect {
  overflow-y: none;
}
</style>

<script>
export default {
  name: 'Modal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isLastStep: {
      type: Boolean,
      default: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    save: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: 'create',
    },
    title: {
      type: String,
      default: 'Untitled',
    },
    width: {
      type: String,
      default: '60%',
    },
  },
};
</script>
