<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Maze Game Manager </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" @click="createMazeGame">
            <v-icon>add</v-icon>
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="mazeGame in gameListStore"
              :key="mazeGame._id"
              @click="onClickSelect(mazeGame)"
            >
              <v-list-tile-avatar>
                <v-icon>import_contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ mazeGame.category }} ({{
                    mazeGame.number_of_game
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ mazeGame.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeModalCreate"
      :isLastStep="valid"
      title="Create Game"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-autocomplete
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  :rules="requiredRule"
                  v-model="maze_game.category_id"
                ></v-autocomplete>
                <v-text-field
                  label="Title"
                  v-model="maze_game.title"
                  :rules="requiredRule"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
  </Container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Container from '../../components/Container.vue';
import Modal from '../../components/Modal.vue';
// import ResourceSelect from '../../components/ResourceSelect.vue';

export default {
  name: 'MazeGameList',
  components: { Container, Modal },
  data() {
    return {
      isCreate: false,
      valid: false,
      requiredRule: [v => !!v || 'This field is required'],
      maze_game: {
        maze: {},
        item: {},
        player: {},
        finish: {},
      },
    };
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('maze_game', [
      'getGameList',
      'getGameData',
      'setSelectedGameListState',
      'createGameData',
    ]),
    onClickSelect(data) {
      const id = data._id;
      this.getGameData(id);
      this.setSelectedGameListState(data);
      this.$router.push({
        path: '/maze_game/detail',
        query: {
          id,
        },
      });
    },
    closeModalCreate() {
      this.isCreate = false;
      this.maze_game = {
        maze: {},
        item: {},
        player: {},
        finish: {},
      };
    },
    onSaveCreate() {
      this.isCreate = false;
      const gameObject = {
        ...this.maze_game,
      };
      this.createGameData(gameObject);
    },
    async createMazeGame() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
    },
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
    }),
    ...mapGetters('maze_game', ['gameListStore', 'gameDataStore']),
  },
  mounted() {
    this.getGameList();
  },
};
</script>
