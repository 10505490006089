import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  draganddrop: [],
  selectDragAndDrop: {},
  selectedSetIndex: -1,
  selectedCategoryId: '',
  updateChoiceData: {},
  updateSetData: {},
  isUpdating: false,
  addChoiceData: [
    {
      match_key: 1,
    },
    {
      match_key: 1,
      images: [''],
    },
  ],
  addSetData: {},
  eventData: {
    startX: '',
    startY: '',
    endX: '',
    endY: '',
    nextIndex: 0,
  },
};
const getters = {};
const actions = {
  fetchDragAndDrop({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/draganddrop/category?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(draganddrop => ({
          ...draganddrop,
          created_at: moment(draganddrop.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(draganddrop.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeDragAndDrop', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch draganddrop at this time. Please type again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectDragAndDrop({ commit }, data) {
    commit('storeSelectDragAndDrop', data);
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleDragAndDrop({ commit, dispatch }, { id, sub_id }) {
    commit('toggleStoreUpdate');
    return http
      .GET(`/api/draganddrop?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const [formatData] = data.map(draganddrop => ({
          ...draganddrop,
          created_at: moment(draganddrop.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(draganddrop.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeSelectDragAndDrop', { ...formatData, sub_id });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch draganddrop at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  updateChoiceData({ commit }, { key, value }) {
    commit('storeUpdateChoiceData', {
      key,
      value,
    });
  },
  updateSetData({ commit }, { key, value }) {
    commit('storeUpdateSetData', {
      key,
      value,
    });
  },
  updateSet({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/draganddrop/set',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Set Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleDragAndDrop', {
            id: state.selectDragAndDrop.category_id,
          });
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update set. Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  updateChoice({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/choice',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Choice Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleDragAndDrop', {
            id: state.selectDragAndDrop.category_id,
          });
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update card. Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateData({ commit }, keyObject) {
    commit('clearStoreUpdateData', keyObject);
  },

  deleteSet({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Set',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/draganddrop/set?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleDragAndDrop', {
                id: state.selectDragAndDrop.category_id,
              });
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  deleteChoice({ dispatch, state }, id) {
    http
      .DELETE(`/api/choice?_id=${id}`, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete Successfully',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchSingleDragAndDrop', {
          id: state.selectDragAndDrop.category_id,
        });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete please try again later !',
            type: 'error',
          },
          { root: true },
        );
      });
  },
  updateCreateChoiceData({ commit }, { key, value, index }) {
    commit('storeCreateChoiceData', {
      key,
      value,
      index,
    });
  },
  updateCreateSingleChoiceData({ commit }, { key, value }) {
    commit('storeCreateSingleChoiceData', {
      key,
      value,
    });
  },
  updateCreateSetData({ commit }, { key, value }) {
    commit('storeCreateSetData', {
      key,
      value,
    });
  },
  addSet({ dispatch }, { category_id, ...data }) {
    const createSetData = {
      category_id,
      set: {
        ...data.set,
      },
      containers: [{ ...data.choices[0] }],
      choices: [{ ...data.choices[1] }],
    };
    http
      .POST('/api/draganddrop', createSetData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Set success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleDragAndDrop', { id: category_id });
        router.go({
          path: '/draganddrop/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Set failed',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  addChoiceBySetId({ dispatch }, { category_id, set_id, type, choice }) {
    const createCardData = {
      _id: set_id,
    };
    if (type == 'Container') {
      createCardData.containers = [{ ...choice }];
    } else {
      createCardData.choices = [{ ...choice }];
    }
    http
      .POST('/api/draganddrop/set', createCardData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Choice success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleDragAndDrop', { id: category_id });
        // router.go({
        //   path: '/draganddrop/detail',
        //   query: {
        //     id: category_id,
        //   },
        // });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Choice failed',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  clearCreateData({ commit }) {
    commit('clearCreateData');
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeDragAndDrop(state, draganddropList) {
    state.draganddrop = draganddropList;
  },
  storeSelectDragAndDrop(state, data) {
    if (data) {
      state.selectDragAndDrop = data;
      if (data.sub_id) {
        const setIndex = state.selectDragAndDrop.sets.findIndex(
          set => set._id === data.sub_id,
        );
        state.selectedSetIndex = setIndex;
      }
      delete state.selectDragAndDrop.sub_id;
    } else {
      delete state.selectDragAndDrop;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateChoiceData(state, { key, value }) {
    if (value || value == 0 || value == false) {
      state.updateChoiceData = {
        ...state.updateChoiceData,
        [key]: value,
      };
    } else {
      delete state.updateChoiceData[key];
    }
  },
  storeCreateChoiceData(state, { key, value, index }) {
    if (value || value == 0 || value == false || value == '') {
      state.addChoiceData[index] = {
        ...state.addChoiceData[index],
        [key]: value,
      };
    } else {
      delete state.addChoiceData[index][key];
    }
  },
  storeCreateSingleChoiceData(state, { key, value }) {
    if (value || value == 0 || value == false) {
      state.addSingleChoiceData = {
        ...state.addSingleChoiceData,
        [key]: value,
      };
    } else {
      delete state.addSingleChoiceData[key];
    }
  },
  storeUpdateSetData(state, { key, value }) {
    state.updateSetData = {
      ...state.updateSetData,
      [key]: value,
    };
  },
  storeCreateSetData(state, { key, value }) {
    if (value || value == 0) {
      state.addSetData = {
        ...state.addSetData,
        [key]: value,
      };
    } else {
      delete state.addSetData[key];
    }
  },
  clearStoreUpdateData(state, keyObject) {
    Object.keys(state[keyObject]).forEach(key => {
      if (key != '_id') {
        delete state[keyObject][key];
      }
    });
    // Object.keys(state.updateChoiceData).forEach(key => {
    //   if (key != '_id') {
    //     delete state.updateChoiceData[key];
    //   }
    // });
    // Object.keys(state.updateSetData).forEach(key => {
    //   if (key != '_id') {
    //     delete state.updateSetData[key];
    //   }
    // });
  },
  clearCreateData(state) {
    state.addSetData = {};
    state.addChoiceData = [
      {
        match_key: 1,
      },
      {
        match_key: 1,
        images: [''],
      },
    ];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
