import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';

const state = {
  item: [],
  createItem: {},
  updateItem: {},
  selectItem: {},
};
const getters = {};
const actions = {
  fetchItem({ commit, dispatch }) {
    http
      .GET(`/api/shop/item`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(item => ({
          ...item,
          created_at: moment(item.created_at).format('YYYY MMM DD  h:mm A'),
          updated_at: moment(item.updated_at).format('YYYY MMM DD  h:mm A'),
        }));
        commit('storeItem', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch item at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },

  addItem({ dispatch }, itemData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating Item',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/shop/item', itemData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Item Successfully Created',
          },
          {
            root: true,
          },
        );
        dispatch('fetchItem');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  editItem({ dispatch }, itemData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating Item',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/shop/item', itemData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Item Successfully Updated',
          },
          {
            root: true,
          },
        );
        dispatch('fetchItem');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteItem({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Item',
        showCancelButton: true,
        type: 'warning',
      })
      .then(response => {
        if (response.value) {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'info',
              message: 'Deleting Item',
            },
            {
              root: true,
            },
          );
          http
            .DELETE(`/api/shop/item?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Item Successfully Deleted',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchItem');
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: error.response.data,
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  stateCreateItem({ commit }, { key, value }) {
    commit('storeCreateItemData', {
      key,
      value,
    });
  },
  stateEditItem({ commit }, { key, value }) {
    commit('storeUpdateItemData', {
      key,
      value,
    });
  },
  clearCreateState({ commit }) {
    commit('clearCreateState');
  },
  clearUpdateState({ commit }) {
    commit('clearUpdateState');
  },
};
const mutations = {
  storeCreateItemData(state, { key, value }) {
    state.createItem = {
      ...state.createItem,
      [key]: value ? value : false,
    };
  },
  storeUpdateItemData(state, { key, value }) {
    state.updateItem = {
      ...state.updateItem,
      [key]: value ? value : false,
    };
  },
  clearUpdateState(state) {
    state.updateItem = {};
  },
  clearCreateState(state) {
    state.createItem = {};
  },
  storeItem(state, value) {
    if (value) {
      state.item = value;
    } else {
      delete state.item;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
