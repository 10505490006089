import swal from 'sweetalert2';
import moment from 'moment';
import http from '../../utils/httpClient';

function formatImageAsBase64(imageFile) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = error => {
      reject(error);
    };
  });
}

const state = {
  resourcesList: [],
  isLoadResouces: false,
  selectResource: {},
  updateResource: {},
  createResource: {},
};
const getters = {};
const actions = {
  searchTags({ commit, dispatch }, { type, tags }) {
    let params = [];
    tags.forEach(t => {
      params.push(`&tags[]=${t}`);
    });
    const tag = params.toString().split(',').join('');
    http
      .GET(`/api/resource/tags?type=${type}${tag}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('storeResource', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot search tag at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  searchByKey({ commit, dispatch }, { type, key, value }) {
    http
      .GET(`/api/resource/name?type=${type}&key=${key}&value=${value}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('storeResource', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot search filename at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchResources(
    { commit, dispatch },
    { type = 'image', page = 1, limit = 0, filter },
  ) {
    let uri = `/api/resource?page=${page}&limit=${limit}&type=${type}`;

    if (filter) {
      Object.keys(filter).forEach(key => {
        uri += `&${key}=${filter[key]}`;
      });
    }
    commit('storeIsloadResource');
    http
      .GET(uri, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeResource', response.data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch resource in this time please type again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => {
        commit('storeIsloadResource');
      });
  },
  deleteResources({ dispatch }, { id, currentType }) {
    swal
      .fire({
        title: 'Are you sure to delete',
        text: `id: ${id}`,
        type: 'question',
        showCancelButton: true,
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/resource?id=${id}`, {
              isAuthorization: true,
            })
            .then(response => {
              swal
                .fire(
                  'Delete Resource Success',
                  `id: ${response.data}`,
                  'success',
                )
                .then(() => {
                  // dispatch('fetchResources', {
                  //   type: currentType,
                  //   page: 1,
                  //   limit: 0,
                  // });
                });
            })
            .catch(error => {
              swal.fire(
                'Delete Resource Failed',
                `Code: ${error.response.status}`,
                'error',
              );
            });
        }
      });
  },
  selectResource({ commit }, id) {
    commit('storeSelectedResource', id);
  },
  editResource({ commit }, { key, value }) {
    if (value && value.type === 'image/png') {
      formatImageAsBase64(value)
        .then(base64String => {
          commit('storeEditResource', {
            key,
            value: base64String,
          });
        })
        .catch(error => {
          throw new Error(error);
        });
    } else {
      commit('storeEditResource', {
        key,
        value,
      });
    }
  },
  editCreateResource({ commit }, { key, value }) {
    if (value && value.type === 'image/png') {
      formatImageAsBase64(value)
        .then(base64String => {
          commit('storeCreateResource', {
            key,
            value: base64String,
          });
        })
        .catch(error => {
          throw new Error(error);
        });
    } else {
      commit('storeCreateResource', {
        key,
        value,
      });
    }
  },
  clearSelected({ commit }) {
    commit('clearSelectState');
  },
  clearCreate({ commit }) {
    commit('clearCreateState');
  },
  clearResourceState({ commit }) {
    commit('clearResourceState');
  },
  updateResource({ dispatch }, updateResource) {
    const { type, ...updateData } = updateResource;
    let formData = new FormData();
    if (Object.keys(updateData).length > 1) {
      const formatData = Object.keys(updateData).map(async key => {
        if (updateData[key].type && updateData[key].type.includes('image')) {
          const base64String = await formatImageAsBase64(updateData[key]);
          return {
            key,
            value: base64String,
          };
        } else {
          return {
            key,
            value: updateData[key],
          };
        }
      });

      Promise.all(formatData).then(result => {
        result.forEach(({ key, value }) => {
          formData.append(key, value);
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'info',
            message: 'Updating resource',
          },
          {
            root: true,
          },
        );
        http
          .PUT('/api/resource', formData, {
            isAuthorization: true,
          })
          .then(() => {
            dispatch('fetchResources', {
              type,
            });
            dispatch(
              'appConfig/toggleAlert',
              {
                type: 'success',
                message: 'Update complete',
              },
              {
                root: true,
              },
            );
          })
          .catch(() => {
            dispatch(
              'appConfig/toggleAlert',
              {
                type: 'error',
                message: 'Update Error',
              },
              {
                root: true,
              },
            );
          });
      });
    }
  },
  editResourceInfo({ dispatch }, updateResource) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Updating resource',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/resource/info', updateResource, {
        isAuthorization: true,
      })
      .then(() => {
        // TODO: fetch from only tag search not all data.
        // dispatch('fetchResources', {
        //   type: updateResource.type,
        // });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update complete',
          },
          {
            root: true,
          },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Error',
          },
          {
            root: true,
          },
        );
      });
  },
  createResource({ commit, dispatch }, createResource) {
    const formatData = Object.keys(createResource).map(async key => {
      if (
        createResource[key].type &&
        createResource[key].type.includes('image')
      ) {
        const base64String = await formatImageAsBase64(createResource[key]);
        return {
          key,
          value: base64String,
        };
      } else {
        return {
          key,
          value: createResource[key],
        };
      }
    });

    commit('storeIsloadResource');
    Promise.all(formatData).then(result => {
      let formData = new FormData();
      result.forEach(({ key, value }) => {
        if (key === 'tags') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'info',
          message: 'Creating resource',
        },
        {
          root: true,
        },
      );
      http
        .POST('/api/resource', formData, {
          isAuthorization: true,
        })
        .then(() => {
          dispatch('searchTags', {
            type: createResource.type,
            tags: createResource.tags,
          });
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'success',
              message: 'Create Resource complete',
            },
            {
              root: true,
            },
          );
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'error',
              message:
                err.response.data.message ||
                'Failed to upload resource, invalid data.',
            },
            {
              root: true,
            },
          );
        })
        .finally(() => {
          commit('storeIsloadResource');
        });
    });
  },
};
const mutations = {
  storeResource(state, resource) {
    state.resourcesList = resource.map(res => ({
      ...res,
      created_at: moment
        .unix(new Date(res.created_at) * 0.001)
        .format('YYYY MMMM D h:mm a'),
      updated_at: moment
        .unix(new Date(res.updated_at) * 0.001)
        .format('YYYY MMMM D h:mm a'),
    }));
  },
  storeIsloadResource(state) {
    state.isLoadResouces = !state.isLoadResouces;
  },
  storeSelectedResource(state, selectResourceId) {
    const index = state.resourcesList.findIndex(
      res => res._id === selectResourceId,
    );
    state.selectResource = {
      ...state.resourcesList[index],
    };
  },
  storeEditResource(state, { key, value }) {
    state.updateResource = {
      ...state.updateResource,
      [key]:
        key == 'content' ||
        key == 'thumbnail' ||
        key == 'video_uri' ||
        key == 'tags'
          ? value
          : value.toLowerCase(),
    };
  },

  clearSelectState(state) {
    state.selectResource = {};
    state.updateResource = {};
  },

  storeCreateResource(state, { key, value }) {
    state.createResource = {
      ...state.createResource,
      [key]:
        key == 'content' ||
        key == 'thumbnail' ||
        key == 'video_uri' ||
        key == 'tags'
          ? value
          : value.toLowerCase(),
    };
  },

  clearCreateState(state) {
    state.createResource = {};
  },
  clearResourceState(state) {
    state.resourcesList = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
