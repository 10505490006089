<template>
  <div>
    <v-flex xs12 sm12 md12 style="text-align: right">
      <v-btn
        color="success"
        small
        fab
        @click="increaseStep(name_list.wrong_question_manager_list)"
        ><v-icon>add</v-icon>
      </v-btn>
    </v-flex>
    <v-stepper v-model="element.wrong_question_manager_list">
      <v-stepper-header>
        <template v-for="n in step.wrong_question_manager_list">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="element.wrong_question_manager_list > n"
            :step="n"
            editable
          >
            <!-- {{ n }} -->
          </v-stepper-step>

          <v-divider
            v-if="n !== step.wrong_question_manager_list"
            :key="n"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="step in step.wrong_question_manager_list"
          :key="`${step}-content`"
          :step="step"
        >
          <v-subheader>
            <v-btn
              color="error"
              small
              absolute
              fab
              right
              @click="reduceStep(step, name_list.wrong_question_manager_list)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-subheader>
          <div
            v-if="
              parenObject &&
              parenObject.wrong_image_uri &&
              parenObject.wrong_image_uri[step - 1]
            "
            style="text-align: -webkit-center"
          >
            <v-img
              v-if="parenObject.wrong_image_uri[step - 1]"
              :src="parenObject.wrong_image_uri[step - 1]"
              class="content_img"
            />
            <v-text-field
              label="Wrong Question URI (URL or Select file)"
              v-model="parenObject.wrong_image_uri[step - 1]"
            ></v-text-field>
          </div>
          <v-btn dark @click="isSelectImage = true">
            <v-icon>audiotrack</v-icon>Select Wrong Question Image
          </v-btn>
        </v-stepper-content>
        <v-btn
          color="primary"
          @click="
            nextStep(
              element.wrong_question_manager_list,
              name_list.wrong_question_manager_list,
            )
          "
        >
          next
        </v-btn>
      </v-stepper-items>
    </v-stepper>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect';
export default {
  name: 'WrongQuestionManager',
  components: {
    ResourceSelect,
  },
  computed: {
    ...mapState({}),
  },
  watch: {
    parenObject(object) {
      if (object && object.wrong_image_uri && object.wrong_image_uri.length) {
        this.step.wrong_question_manager_list = object.wrong_image_uri.length;
      } else {
        this.step.wrong_question_manager_list = 1;
      }
    },
    'step.wrong_question_manager_list'(step) {
      this.validateWidth(step);
      if (this.element.wrong_question_manager_list > step) {
        this.element.wrong_question_manager_list = step;
      }
    },
  },
  props: {
    parenObject: {
      type: Object,
      default: () => {},
    },
    action: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isDirty: Boolean,
      nameRules: [v => !!v || 'Name is required'],
      errorMessages: '',
      selectImageUri: '',
      isUpdateNode: false,
      isBackgroundUri: false,
      isSelectImage: false,
      width: '60%',

      name_list: {
        wrong_question_manager_list: 'wrong_question_manager_list',
      },
      element: {
        wrong_question_manager_list: 1,
      },
      step: {
        wrong_question_manager_list: 1,
      },
    };
  },
  methods: {
    closeSelectImage() {
      this.isSelectImage = false;
    },
    validateWidth(val) {
      if (val > 6) {
        this.width = '80%';
      } else if (val <= 6) {
        this.width = '60%';
      }
    },
    increaseStep(list) {
      const max = 5;
      if (this.step[list] < max) {
        this.step[list] += 1;
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.wrong_question_manager_list) {
          this.step[list] -= 1;
          this.parenObject.wrong_image_uri.splice(index, 1);
        }
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },
    saveSelectImage() {
      const uri = this.selectImageUri;
      const defineArray = () => {
        this.parenObject.wrong_image_uri = [];
      };
      if (!this.parenObject.wrong_image_uri) {
        defineArray();
      }
      if (this.action === 'add') {
        const index = this.element.wrong_question_manager_list - 1;
        this.parenObject.wrong_image_uri[index] = uri;
        this.selectImageUri = '';
        this.isSelectImage = false;
        this.validateAddedWrongQuestionImageArray();
      } else if (this.action === 'edit') {
        const index = this.element.wrong_question_manager_list - 1;
        this.parenObject.wrong_image_uri[index] = uri;
        this.selectImageUri = '';
        this.isSelectImage = false;
        this.validateEdittedWrongQuestionImageArray();
      }
    },
    validateAddedWrongQuestionImageArray() {
      if (this.parenObject.wrong_image_uri.length > 0) {
        this.$emit(
          'wrongQuestionAddImageArray',
          this.parenObject.wrong_image_uri,
        );
        this.isDirty = true;
      } else {
        this.isDirty = false;
      }
      this.$emit('addedStatus', this.isDirty);
    },
    validateEdittedWrongQuestionImageArray() {
      if (this.parenObject.wrong_image_uri.length > 0) {
        this.$emit(
          'wrongQuestionEditImageArray',
          this.parenObject.wrong_image_uri,
        );
        this.isDirty = true;
      } else {
        this.isDirty = false;
      }
      this.$emit('edittedStatus', this.isDirty);
    },
    closeEditImage() {
      this.isSelectImage = false;
      this.selectImageUri = '';
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
  },
};
</script>

<style scoped>
.content_img {
  height: 200px;
  width: 200px;
  border-radius: 5px;
}
</style>
