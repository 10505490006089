import http from '../../utils/httpClient';
import Constants from '../../constants';
const state = {
  gameType: {},
  updateAcitivityData: {},
};
const getters = {};
const actions = {
  async fetchAllGameType({ commit }) {
    let gameType = {};
    let constants = [...Constants.GAME_TYPE];
    constants.shift();
    constants.forEach(async game => {
      const { data } = await http.GET(
        `/api/game/category?game_type=${game.value}`,
        {
          isAuthorization: true,
        },
      );
      gameType[game.value] = data.map(gameData => {
        if (game.value === 'CHR') {
          return {
            ...gameData,
            text: gameData.title,
            value: gameData._id,
          };
        } else {
          return {
            ...gameData,
            text: gameData.category,
            value: gameData.category_id,
          };
        }
      });
    });
    commit('storeGameType', gameType);
  },
};
const mutations = {
  storeGameType(state, gameType) {
    state.gameType = gameType;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
