<template>
  <div>
    <v-btn
      small
      class="white--text"
      color="primary"
      @click="copyToClipboard(objectToCopy)"
      ><v-icon small>content_copy</v-icon>Copy JSON
    </v-btn>
    <v-btn
      v-if="havePaste"
      small
      class="white--text"
      color="#FF8F00"
      @click="pasteFromClipboard"
      ><v-icon small>download</v-icon>Paste Data
    </v-btn>
  </div>
</template>

<script>
export default {
  // data() {
  //   return {
  //     isJson: true,
  //   };
  // },
  name: 'Clipboard',
  props: {
    objectToCopy: {
      type: Object,
      default: () => {},
    },
    havePaste: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copyToClipboard(object) {
      const JSONObject = JSON.stringify(object);
      if (JSONObject) {
        this.$copyText(JSONObject).then(
          () => {
            // this.isJson = false;
            this.$store.dispatch(
              'appConfig/toggleAlert',
              {
                message: 'Copied to clipboard',
                type: 'info',
              },
              { root: true },
            );
          },
          () => {
            this.$store.dispatch(
              'appConfig/toggleAlert',
              {
                message: 'Cannot copy to clipboard at this time',
                type: 'error',
              },
              { root: true },
            );
          },
        );
      }
    },
    async pasteFromClipboard() {
      const isJson = data => {
        try {
          if (JSON.parse(data)) {
            return true;
          }
        } catch (error) {
          return false;
        }
      };
      let jsonData = await navigator.clipboard.readText();
      if (isJson(jsonData)) {
        jsonData = JSON.parse(jsonData);
        this.$emit('pasteFromClipboard', { ...jsonData });
      }
    },
  },
};
</script>

<style></style>
