<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Mix And Match Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" v-on:click="toggleSelectCategory">
            <v-icon>add</v-icon>
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="mixandmatch in mixandmatchStore.mixandmatch"
              :key="mixandmatch.category_id"
              v-on:click="() => onClickEdit(mixandmatch.category_id)"
            >
              <v-list-tile-avatar>
                <v-icon>view_module</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ mixandmatch.category }} ({{
                    mixandmatch.decks.length
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at
                  {{ mixandmatch.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <Category
      :v-if="isSelectCategory"
      :isOpen="isSelectCategory"
      :close="toggleSelectCategory"
      :onSelect="onClickSelectCategory"
      :value="categoryId"
      title="Select Category"
    >
    </Category>
  </Container>
</template>
<script>
import Container from '../../components/Container';
import Category from '../../components/Category';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'MixAndMatchList',
  components: {
    Container,
    Category,
  },
  data: function () {
    return {
      isSelectCategory: false,
      categoryId: '',
    };
  },
  methods: {
    ...mapActions('mixandmatch', [
      'fetchMixAndMatch',
      'selectMixAndMatch',
      'selectCategory',
    ]),
    onClickEdit(id) {
      this.selectMixAndMatch(id);
      this.selectCategory();
      this.$router.push({
        path: '/mixandmatch/detail',
        query: {
          id,
        },
      });
    },
    toggleSelectCategory() {
      this.isSelectCategory = !this.isSelectCategory;
    },
    onClickSelectCategory(id) {
      this.isSelectCategory = false;
      this.selectCategory(id);
      this.selectMixAndMatch();
      this.$router.push({
        path: '/mixandmatch/detail',
        query: {
          id,
        },
      });
    },
  },
  mounted() {
    this.fetchMixAndMatch({
      page: 1,
      limit: 0,
    });
  },
  computed: {
    ...mapState({
      mixandmatchStore: state => state.mixandmatch,
    }),
  },
};
</script>
