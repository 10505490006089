import http from '../../utils/httpClient';
import swal from 'sweetalert2';
import router from '../../router';
import moment from 'moment';

const state = {
  isUpdating: false,
  CREATE: {},
  UPDATE: {},
  TAPSTORY_LIST: [],
  TAPSTORY_DATA: [],
  TAPSTORY_IMAGE_SET_DATA: [],
  TAPSTORY_IMAGE_SET_ID: null,
  SELECTED_TAPSTORY_LIST: {},
};
const getters = {};
const actions = {
  fetchImageSetByTapstoryDataID({ commit }, tapstory_data_id) {
    http
      .GET(
        `/api/tapstory/tapstoryImageSet?tapstory_data_id=${tapstory_data_id}`,
        {
          isAuthorization: true,
        },
      )
      .then(({ data }) => {
        if (data.length) {
          commit('SET_TAPSTORY_IMAGE_SET_ID', data[0]._id);
          commit('SET_TAPSTORY_IMAGE_SET_DATA', data[0].image_set);
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  createTapstoryData({ dispatch }, { ...data }) {
    const tapstoryData = {
      ...data,
    };
    http
      .POST('/api/tapstory', tapstoryData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully created new tapstory data',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('getTapstoryList');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed to create new tapstory data',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getCategoryByTapstoryListID({ commit, dispatch }, id) {
    http
      .GET(`/api/tapstory/category?tapstory_list_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_SELECTED_TAPSTORY_LIST_STATE', data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch category name for tapstory at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getTapstoryDataByObjectID({ commit, dispatch }, objectID) {
    http
      .GET(`/api/tapstory/?_id=${objectID}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_TAPSTORY_DATA', data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch tapstory data for tapstory at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getTapstoryData({ commit, dispatch }, id) {
    http
      .GET(`/api/tapstory/tapstoryData?tapstory_list_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_TAPSTORY_DATA', data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch tapstory data for tapstory at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  getTapstoryList({ commit, dispatch }) {
    http
      .GET(`/api/tapstory/tapstoryList`, {
        isAuthorization: true,
      })
      .then(response => {
        const formatData = response.data.map(data => ({
          ...data,
          created_at: moment(data.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(data.updated_at).format('YYYY MMM DD h:mm A'),
        }));
        commit('SET_TAPSTORY_LIST', formatData);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch category for tapstory at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  updateTapstoryImageSetData({ dispatch }, updateData) {
    http
      .PATCH('/api/tapstory/tapstoryImageSetData', updateData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully update tapstory image set data',
            type: 'success',
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed to update tapstory image set data',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  updateTapstory({ dispatch }, { ...data }) {
    const tapstoryData = {
      ...data,
    };
    http
      .PUT('/api/tapstory/tapstoryData', tapstoryData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully update tapstory',
            type: 'success',
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed to update tapstory',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  deleteTapstory({ dispatch }, { tapstoryID }) {
    swal
      .fire({
        titleText: 'Confirm Delete Tapstory',
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'red',
        confirmButtonText: 'Remove',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/tapstory/tapstoryData?_id=${tapstoryID}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete successfully',
                  type: 'success',
                },
                { root: true },
              );
              router.go();
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message:
                    'Cannot delete at this time, please try again later.',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  setCreateState({ commit }, { key, value, index }) {
    commit('SET_CREATE_STATE', { key, value, index });
  },
  setUpdateState({ commit }, { key, value, index }) {
    commit('SET_UPDATE_STATE', { key, value, index });
  },
  resetTapstoryImageSetID({ commit }) {
    commit('RESET_TAPSTORY_IMAGE_SET_ID');
  },
  resetTapstoryImageSetData({ commit }) {
    commit('RESET_TAPSTORY_IMAGE_SET_DATA');
  },
  resetCreateState({ commit }) {
    commit('RESET_CREATED_STATE');
  },
  resetUpdateState({ commit }) {
    commit('RESET_UPDATED_STATE');
  },
  setSelectedTapstoryListState({ commit }, data) {
    commit('SET_SELECTED_TAPSTORY_LIST_STATE', data);
  },
};
const mutations = {
  TOGGLE_UPDATING_STATE(state) {
    state.isUpdating = !state.isUpdating;
  },
  SET_SELECTED_TAPSTORY_LIST_STATE(state, value) {
    if (value) {
      state.SELECTED_TAPSTORY_LIST = value;
    } else {
      delete state.SELECTED_TAPSTORY_LIST;
    }
  },
  SET_TAPSTORY_LIST(state, value) {
    if (value) {
      state.TAPSTORY_LIST = value;
    } else {
      delete state.TAPSTORY_LIST;
    }
  },
  SET_TAPSTORY_DATA(state, value) {
    if (value) {
      state.TAPSTORY_DATA = value;
    } else {
      delete state.TAPSTORY_DATA;
    }
  },
  SET_TAPSTORY_IMAGE_SET_DATA(state, value) {
    if (value) {
      state.TAPSTORY_IMAGE_SET_DATA = value;
    } else {
      delete state.TAPSTORY_IMAGE_SET_DATA;
    }
  },
  SET_TAPSTORY_IMAGE_SET_ID(state, value) {
    if (value) {
      state.TAPSTORY_IMAGE_SET_ID = value;
    } else {
      delete state.TAPSTORY_IMAGE_SET_ID;
    }
  },
  SET_CREATE_STATE(state, { key, value, index }) {
    state.CREATE[index] = {
      ...state.CREATE[index],
      [key]: value,
    };
  },
  SET_UPDATE_STATE(state, { key, value, index }) {
    state.UPDATE[index] = {
      ...state.UPDATE[index],
      [key]: value,
    };
  },
  RESET_CREATED_STATE(state) {
    state.CREATE = {};
  },
  RESET_UPDATED_STATE(state) {
    state.UPDATE = {};
  },
  RESET_TAPSTORY_IMAGE_SET_ID(state) {
    state.TAPSTORY_IMAGE_SET_ID = null;
  },
  RESET_TAPSTORY_IMAGE_SET_DATA(state) {
    state.TAPSTORY_IMAGE_SET_DATA = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
