export const customerServiceMenu = [
  {
    icon: 'person',
    title: 'Users',
    path: '/users',
  },
  {
    icon: 'chat',
    title: 'Customer Service Chat',
    path: '/customer_service_chat',
  },
];
