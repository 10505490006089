import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  galaxychat: [],
  missionList: [],
  libraryList: [],
  selectedQuestIndex: -1,
  selectGalaxyChat: {},
  selectedCategoryId: '',
  updateMissionData: {},
  updateQuestData: {},
  isUpdating: false,
  addGalaxyChatData: {
    title: '',
    stage: 0,
    world: 1,
  },
  addMissionData: {},
  addQuestData: {
    name: 'New Quest',
  },
  missionData: {
    text: '',
    article: '',
  },
  txts: [[]],
  txtObjectData: [
    {
      libraries: [''],
      article: false,
      options: [0],
    },
  ],
  inputData: {
    expectAnswers: [''],
    expectComments: [''],
    retryComment: [''],
    isSpeak: true,
  },
};
const getters = {};
const actions = {
  fetchGalaxyChat({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/galaxychat?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(galaxyChat => ({
          ...galaxyChat,
          created_at: moment(galaxyChat.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(galaxyChat.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeGalaxyChat', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch galaxy chat in this time please type again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectGalaxyChat({ commit }, id) {
    commit('storeSelectGalaxyChat', { id, sub_id: '' });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleGalaxyChat({ commit, dispatch }, { id, sub_id }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/galaxychat?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(galaxyChat => ({
          ...galaxyChat,
          created_at: moment(galaxyChat.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(galaxyChat.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeGalaxyChat', formatData);
        commit('storeSelectGalaxyChat', { id, sub_id });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch galaxy chat at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  fetchLibrary({ commit, dispatch }, { page = 1, limit = 0 }) {
    http
      .GET(`/api/library?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(library => ({
          text: library.category,
          value: library.category_id,
        }));
        commit('storeLibrary', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch library at this time. Please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  updateMissionData({ commit }, { key, value }) {
    commit('storeUpdateMissionData', {
      key,
      value,
    });
  },
  updateCustomMissionData({ commit }, { data }) {
    commit('storeUpdateCustomMissionData', {
      data,
    });
  },
  updateQuestData({ commit }, { key, value }) {
    commit('storeUpdateQuestData', {
      key,
      value,
    });
  },
  updateGalaxyChatData({ commit }, { key, value }) {
    commit('storeUpdateGalaxyChatData', {
      key,
      value,
    });
  },
  updateGalaxyChat({ dispatch }, updateData) {
    http
      .PUT('/api/galaxychat', updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Edit Galaxy Chat success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleGalaxyChat', {
          id: state.selectGalaxyChat.category_id,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Edit Galaxy Chat failed please try again later',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },

  deleteGalaxyChat({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Galaxy Chat',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/galaxychat?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Galaxy Chat Successfully',
                  type: 'success',
                },
                { root: true },
              );
              router.push('/galaxychat');
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete galaxy chat please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  updateMission({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/galaxychat/mission',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Mission Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleGalaxyChat', {
            id: state.selectGalaxyChat.category_id,
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update mission. Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdateQuestData');
    commit('clearStoreUpdateMissionData');
  },

  deleteQuest({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Quest',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/galaxychat/quest?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleGalaxyChat', {
                id: state.selectGalaxyChat.category_id,
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete quest. Please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  deleteMission({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Mission',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/choice?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleGalaxyChat', {
                id: state.selectGalaxyChat.category_id,
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete mission please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  updateCreateMissionData({ commit }, { key, value, index }) {
    commit('storeCreateMissionData', {
      key,
      value,
      index,
    });
  },
  updateCreateSingleMissionData({ commit }, { key, value }) {
    commit('storeCreateSingleMissionData', {
      key,
      value,
    });
  },
  updateQuest({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT('/api/galaxychat/quest', updateData, {
          isAuthorization: true,
        })
        .then(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Quest success',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleGalaxyChat', {
            id: state.selectGalaxyChat.category_id,
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Edit Quest failed please try again later',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  updateCreateQuestData({ commit }, { key, value }) {
    commit('storeCreateQuestData', {
      key,
      value,
    });
  },
  addQuest({ dispatch }, { category_id, quest, mission, ...data }) {
    const createQuestData = {
      category_id,
      quest: quest,
      mission: mission,
      ...data,
    };
    http
      .POST('/api/galaxychat', createQuestData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Quest success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleGalaxyChat', { id: category_id });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Quest failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
      });
  },
  addMissionByQuestId({ dispatch }, { category_id, quest_id, ...data }) {
    const createMissionData = {
      _id: quest_id,
      choice: { ...data.mission },
    };
    http
      .POST('/api/galaxychat/quest', createMissionData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Mission success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleGalaxyChat', { id: category_id });
        router.go({
          path: '/galaxychat/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Mission failed',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeGalaxyChat(state, galaxyChatList) {
    state.galaxychat = galaxyChatList;
  },
  storeMission(state, missionList) {
    state.missionList = missionList;
  },
  storeLibrary(state, libraryList) {
    libraryList;
    state.libraryList = libraryList;
  },
  storeSelectGalaxyChat(state, { id, sub_id }) {
    if (id) {
      const index = state.galaxychat.findIndex(
        galaxychat => galaxychat.category_id === id,
      );
      state.selectGalaxyChat = state.galaxychat[index];
      if (sub_id) {
        const questIndex = state.selectGalaxyChat.quests.findIndex(
          quest => quest._id === sub_id,
        );
        state.selectedQuestIndex = questIndex;
      }
    } else {
      delete state.selectGalaxyChat;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateMissionData(state, { key, value }) {
    if (key == 'data') {
      state.updateMissionData = {
        ...state.updateMissionData,
        ...value,
      };
    } else {
      state.updateMissionData = {
        ...state.updateMissionData,
        [key]: value,
      };
    }
  },
  storeUpdateCustomeMissionData(state, { data }) {
    state.updateMissionData = {
      ...data,
    };
  },
  storeCreateMissionData(state, { key, value, index }) {
    state.addMissionData[index] = {
      ...state.addMissionData[index],
      [key]: value,
    };
  },
  storeCreateSingleMissionData(state, { key, value }) {
    state.addSingleMissionData = {
      ...state.addSingleMissionData,
      [key]: value,
    };
  },
  storeUpdateQuestData(state, { key, value }) {
    state.updateQuestData = {
      ...state.updateQuestData,
      [key]: value,
    };
  },
  storeCreateQuestData(state, { key, value }) {
    state.addQuestData = {
      ...state.addQuestData,
      [key]: value,
    };
  },
  storeUpdateGalaxyChatData(state, { key, value }) {
    state.updateGalaxyChatData = {
      ...state.updateGalaxyChatData,
      [key]: value,
    };
  },
  storeCreateGalaxyChatData(state, { key, value }) {
    state.addGalaxyChatData = {
      ...state.addGalaxyChatData,
      [key]: value,
    };
  },
  clearStoreUpdateQuestData(state) {
    state.updateQuestData = {};
    Object.keys(state.updateQuestData).forEach(key => {
      if (key != '_id') {
        delete state.updateQuestData[key];
      }
    });
  },
  clearStoreUpdateMissionData(state) {
    state.updateMissionData = {};
    Object.keys(state.updateMissionData).forEach(key => {
      if (key != '_id') {
        delete state.updateMissionData[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
