<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid"
      :title="'Create Speech Lab Tag'"
    >
      <v-card>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-checkbox
                  label="Available for Free User"
                  v-model="createData.isGuestPlayable"
                  :value="createData.isGuestPlayable"
                  @change="
                    e => {
                      createData.isGuestPlayable = e ? true : false;
                    }
                  "
                />
                <v-checkbox
                  label="Hide Content"
                  v-model="createData.is_hide"
                  :value="createData.is_hide"
                  @change="
                    e => {
                      createData.is_hide = e ? true : false;
                    }
                  "
                />
                <v-text-field
                  label="Tag Name"
                  :value="createData.tag_name"
                  v-model="createData.tag_name"
                  @input="
                    e => {
                      createData.tag_name = e.toLowerCase().trim();
                      checkTagPhonic(e);
                    }
                  "
                  :rules="[rules.required, checkTagDicphonicExists]"
                ></v-text-field>
                <v-text-field
                  :value="createData.display_name"
                  v-model="createData.display_name"
                  label="Display Name"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  type="number"
                  :value="createData.play_count"
                  v-model.number="createData.play_count"
                  label="Play Count"
                  :rules="[rules.required, rules.isNumber]"
                ></v-text-field>
                <v-select
                  :items="constants.status"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  :value="createData.status"
                  v-model="createData.status"
                  :rules="[rules.required]"
                ></v-select>
                <v-text-field
                  type="number"
                  :value="createData.level"
                  v-model.number="createData.level"
                  label="Level"
                  :rules="[rules.required, rules.isNumber]"
                ></v-text-field>
                <img class="image_thumbnail" :src="createData.cover_image" />
                <v-text-field
                  label="Cover Image (URL or Select file)"
                  :value="createData.cover_image"
                  v-model="createData.cover_image"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="isEditImage = true"
                  ><v-icon>image</v-icon>Select Cover Image URI</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
  </div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../utils/ruleValidate';
import ResourceSelect from '../../../components/ResourceSelect';
import constants from './constants';

export default {
  components: { Modal, ResourceSelect },
  data() {
    return {
      constants,
      valid: true,
      rules,
      createData: {},
      selectImageUri: '',
      isEditImage: false,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      speechLabTagStore: state => state.speech_lab_tag,
      defaultCreate: state => state.speech_lab_tag.defaultCreate,
      checkTagDicphonicExists: state =>
        state.speech_lab_tag.checkTagDicphonicExists,
    }),
  },
  methods: {
    ...mapActions('speech_lab_tag', [
      'createSpeechLabTag',
      'searchTagsDicphonic',
    ]),
    save() {
      if (this.$refs.form.validate()) {
        this.createSpeechLabTag(this.createData);
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      this.createData.cover_image = uri;
      this.closeEditImage();
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = false;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    checkTagPhonic(tag) {
      this.searchTagsDicphonic({ tags: [tag] });
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.createData = { ...this.defaultCreate };
      }
    },
  },
};
</script>

<style></style>
