export default {
  HCellType: [
    { name: 'Lerning Path Game', value: 0 },
    { name: 'Lerning Path Activity', value: 1 },
    { name: 'Play Ground Game', value: 2 },
    { name: 'Tag Game', value: 3 },
  ],
  HCellFormat: [
    { name: 'Cell1x1', value: 0 },
    { name: 'Cell2x1', value: 1 },
    { name: 'Cell1x2', value: 2 },
    { name: 'Cell2x2', value: 3 },
  ],
  GameType: {
    LPGame: 0,
    LPActivity: 1,
    PgGame: 2,
    TagGame: 3,
  },
  typeTagGame: {
    Dictionary: 0,
    SpeechLab: 1,
    ShortStory: 2,
  },
  typePgGame: {
    Book: 0,
    Video: 1,
    SnapGame: 2,
    RunningGame: 3,
    DrivingGame: 4,
    SequenceGame: 5,
    QuizGame: 6,
  },
};
