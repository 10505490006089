<template>
  <div>
    <v-btn class="btn-center" @click="isOpen = true">Trigger Manager</v-btn>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :close="close"
      :isLastStep="valid"
      :save="save"
      type="create"
      title="Trigger Manager"
    >
      <div>
        <v-layout row grid-list-md>
          <v-flex mr-2 xs4>
            <v-card>
              <v-toolbar dark>
                <v-toolbar-title>List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="green" v-on:click="toggleAddData()">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list v-if="data.length">
                <v-list-tile
                  v-for="(e, index) in data"
                  :key="index"
                  class="row-pointer"
                >
                  <v-list-tile-content @click="onSelectTringger(index)">
                    <v-list-tile-title>{{ e.name }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-flex>
          <v-flex mr-2 xs8 v-if="selectedIndex != -1">
            <v-card>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-toolbar dark>
                    <v-toolbar-title
                      >Image {{ selectedIndex + 1 }}</v-toolbar-title
                    >
                    <v-btn
                      v-if="data.length > 0"
                      color="error"
                      small
                      absolute
                      fab
                      right
                      @click="onClickRemoveStickerByIndex(selectedIndex)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-container
                    grid-list-xs
                    text-xs-center
                    v-if="data[selectedIndex]"
                  >
                    <v-layout>
                      <v-flex>
                        <v-text-field
                          label="Name"
                          :value="data[selectedIndex].name"
                          v-model="data[selectedIndex].name"
                          @change="e => onchangeTriggerImage('name', e)"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-select
                          :items="getTriggerType()"
                          item-text="name"
                          item-value="value"
                          :value="data[selectedIndex].type"
                          v-model="data[selectedIndex].type"
                          label="Type"
                          @change="e => onchangeTriggerImage('type', e)"
                        ></v-select>
                        <v-text-field
                          label="Face_ratio"
                          :value="data[selectedIndex].face_ratio"
                          v-model="data[selectedIndex].face_ratio"
                          @change="e => onchangeTriggerImage('face_ratio', e)"
                          :rules="[rules.numberRule]"
                        ></v-text-field>
                        <v-select
                          :items="FacePosition"
                          item-text="name"
                          item-value="value"
                          :value="data[selectedIndex].face_point_index"
                          v-model.number="data[selectedIndex].face_point_index"
                          label="Face Point Index"
                          @change="
                            e =>
                              onchangeTriggerImage(
                                'face_point_index',
                                parseInt(e),
                              )
                          "
                        ></v-select>
                        <img
                          class="image_thumbnail"
                          :src="data[selectedIndex].image_uri"
                        />
                        <v-text-field
                          label="Image URI (URL or Select file)"
                          :value="data[selectedIndex].image_uri"
                          v-model="data[selectedIndex].image_uri"
                          @change="e => onchangeTriggerImage('image_uri', e)"
                          :rules="[rules.validURI]"
                        ></v-text-field>
                        <v-btn class="btn-center" @click="onclickEditImage()"
                          ><v-icon>image</v-icon>Select Image URI</v-btn
                        >
                        <v-layout row>
                          <v-text-field
                            label="Columns"
                            type="number"
                            :value="data[selectedIndex].columns"
                            v-model.number="data[selectedIndex].columns"
                            @change="
                              e => onchangeTriggerImage('columns', parseInt(e))
                            "
                            :rules="[rules.required, rules.numberRowColumn]"
                          ></v-text-field>
                          <v-text-field
                            label="Rows"
                            type="number"
                            :value="data[selectedIndex].rows"
                            v-model.number="data[selectedIndex].rows"
                            @change="
                              e => onchangeTriggerImage('rows', parseInt(e))
                            "
                            :rules="[rules.required, rules.numberRowColumn]"
                          ></v-text-field>
                        </v-layout>
                        <v-layout row>
                          <v-text-field
                            label="Loop (-1 Infinite)"
                            type="number"
                            :value="data[selectedIndex].loop"
                            v-model.number="data[selectedIndex].loop"
                            @change="
                              e => onchangeTriggerImage('loop', parseInt(e))
                            "
                            :rules="[rules.required, rules.numberRule]"
                          ></v-text-field>
                          <v-text-field
                            label="Frame_ms"
                            type="number"
                            :value="data[selectedIndex].frame_ms"
                            v-model.number="data[selectedIndex].frame_ms"
                            @change="
                              e => onchangeTriggerImage('frame_ms', parseInt(e))
                            "
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-layout>
                        <v-text-field
                          :value="data[selectedIndex].sound_uri"
                          v-model="data[selectedIndex].sound_uri"
                          label="Sound URI (URL or Select file)"
                          v-on:change="
                            e => onchangeTriggerImage('sound_uri', e)
                          "
                          :rules="[rules.validURI]"
                        ></v-text-field>
                        <v-btn class="btn-center" @click="onclickMusicSound()"
                          ><v-icon>audiotrack</v-icon>Select Sound URI</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
      <ResourceSelect
        v-if="isEditImage"
        resourceType="image"
        :isShow="isEditImage"
        :save="saveEditImage"
        :close="closeEditImage"
        :value="selectImageUri"
        :onSelect="onSelectImageResource"
      />
      <ResourceSelect
        v-if="isSelectSound"
        resourceType="sound"
        :isShow="isSelectSound"
        :close="closeSelectSound"
        :save="saveSelectSound"
        :value="selectedSoundUri"
        :onSelect="onSelectSoundResource"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import { mapActions, mapState } from 'vuex';
import constant from '../constant';
import swal from 'sweetalert2';

export default {
  components: {
    Modal,
    ResourceSelect,
  },
  data() {
    return {
      selectedIndex: -1,
      TriggerType: constant.TriggerType,
      FacePosition: constant.FacePosition,
      TriggerTypeSelect: [],
      isOpen: false,
      isEditImage: false,
      selectImageUri: '',
      isSelectImageBtn: false,
      data: [],
      addData: [],
      valid: true,
      rules: constant.rule,
      backupData: [],
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectSoundBtn: false,
    };
  },
  props: {
    dataGame: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Manager',
    },
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    ...mapActions('short_story', [
      'updateFilterShortStory',
      'updateFilterStore',
      'clearUpdateFilter',
      'setDirty',
    ]),
    getTriggerType() {
      return this.TriggerType.filter(elem => {
        if (elem.name === 'None') return elem;
        return !this.TriggerTypeSelect.filter(
          ({ id }) => id !== this.selectedIndex,
        )
          .map(({ value }) => value)
          .includes(elem.value);
      });
    },
    onSelectTringger(index) {
      this.selectedIndex = index;
    },
    onchangeTriggerImage(key, value) {
      if (key === 'type') {
        //clear old value
        this.TriggerTypeSelect = [...this.TriggerTypeSelect].filter(
          ({ id }) => id !== this.selectedIndex,
        );
        if (value !== 0) {
          const data = {
            id: this.selectedIndex,
            value,
          };
          this.TriggerTypeSelect = [...this.TriggerTypeSelect, data];
        }
      }
      this.setDirty('filter');
    },
    addCreateData() {
      const createData = JSON.parse(
        JSON.stringify(this.shortStoryStore.defaultSSImage),
      );
      if (this.data.length) {
        this.data.forEach((e, index) => {
          if (index === this.data.length - 1) {
            let numberName = parseInt(e.name.replace('Trigger', ''));
            createData.name = `Trigger${numberName + 1}`;
          }
        });
      } else createData.name = `Trigger1`;
      this.data.push(createData);
      this.selectedIndex = this.data.length - 1;
      this.setDirty('filter');
    },
    toggleAddData() {
      if (this.data.length < 5) {
        this.addCreateData();
      }
    },
    onclickEditImage() {
      this.isEditImage = true;
      this.isSelectImageBtn = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImageBtn:
          this.data[this.selectedIndex].image_uri = uri;
          // this.updateFilterStore({
          //   key: 'trigger_images',
          //   value: this.data,
          // });
          this.isSelectImageBtn = false;
          this.setDirty('filter');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onclickMusicSound() {
      this.isSelectSound = true;
      this.isSelectSoundBtn = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectSoundBtn:
          this.data[this.selectedIndex].sound_uri = uri;
          this.isSelectSoundBtn = false;
          this.setDirty('filter');
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onClickRemoveStickerByIndex(index) {
      swal
        .fire({
          titleText: `Remove data at image ${index + 1}?`,
          text: 'Would you like to remove?',
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.data.splice(index, 1);
            this.TriggerTypeSelect = this.TriggerTypeSelect.map(elem => {
              if (elem.id > index) return { ...elem, id: elem.id - 1 };
              return elem;
            });
            this.TriggerTypeSelect = [...this.TriggerTypeSelect].filter(
              ({ id }) => id !== this.selectedIndex,
            );
            this.setDirty('filter');
            this.selectedIndex -= 1;
          }
        });
    },
    save() {
      if (this.data.length > 0) {
        if (this.$refs.form.validate()) {
          this.data = [...this.data];
          this.selectedIndex = -1;
          this.isOpen = false;
          this.updateFilterStore({
            key: 'trigger_images',
            value: this.data,
          });
          this.$emit('changeTriggerData', this.data);
        }
      } else {
        this.data = [...this.data];
        this.selectedIndex = -1;
        this.isOpen = false;
        this.updateFilterStore({
          key: 'trigger_images',
          value: this.data,
        });
        this.$emit('changeTriggerData', this.data);
      }
    },
    close() {
      this.isOpen = false;
      this.selectedIndex = -1;
      this.data = this.backupData;
    },
  },
  mounted() {
    if (this.dataGame.length) {
      this.data = JSON.parse(JSON.stringify(this.dataGame));
    }
  },
  watch: {
    dataGame(val, old) {
      if (val.length) {
        this.data = JSON.parse(JSON.stringify(val));
      } else {
        this.data = [];
      }
    },
    data(val) {
      this.backupData = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer:hover {
  cursor: pointer;
  background-color: #4f4f4f;
}
</style>
