<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/learningmedia')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (selectedLearningMediaState &&
                selectedLearningMediaState.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="selectedLearningMediaState">
          <v-layout row grid-list-md v-if="!updatingState">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Group</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" @click="createLearningMediaGroup">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(group, index) in selectedLearningMediaState.groups"
                    :key="group._id"
                    @click="onSelectGroup(group._id)"
                    :class="
                      index === selectedGroupIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ group.name }}</v-list-tile-title>
                      <!-- <v-list-tile-sub-title v-if="group.videos.length > 1">
                        {{ group.videos.length }} videos
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title v-else>
                        {{ group.videos.length }} videos
                      </v-list-tile-sub-title> -->
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <!-- TODO -->
            <v-flex xs3 mr-2 v-if="selectedGroupIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Group Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onClickUpdateGroup">
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data</v-btn
                      >
                      <v-text-field
                        label="Group Name"
                        v-model="group.name"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-btn color="red" dark @click="onClickDeleteGroup(group._id)">
                  <v-icon>delete</v-icon>Delete group
                </v-btn>
              </v-card>
            </v-flex>
            <!-- v-if="selectedGroupIndex != -1" -->
            <v-flex xs2 mr-2 v-if="false">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Video</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="videos.length < 8"
                    icon
                    color="green"
                    @click="
                      onCreateVideo(
                        selectedLearningMediaState.groups[selectedGroupIndex],
                      )
                    "
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list v-if="videos.length > 0">
                  <v-list-tile
                    v-for="(video, index) in selectedLearningMediaState.groups[
                      selectedGroupIndex
                    ].videos"
                    :key="video._id"
                    @click="onSelectVideo(video._id)"
                    :class="
                      index === selectedVideoIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Video {{ index + 1 }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <!-- v-if="Object.keys(video).length > 1 && selectedGroupIndex != -1" -->
            <v-flex v-if="false" xs4 mr-2>
              <v-card dark>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-toolbar dark>
                    <v-toolbar-title>Video Detail</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green"
                      :disabled="!valid"
                      @click="onClickUpdateVideo"
                    >
                      <v-icon>save</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-layout column>
                      <v-btn
                        @click="
                          isSelectVideo = true;
                          isUpdateVideo = true;
                        "
                      >
                        Select Video
                      </v-btn>
                      <!-- <video id="edit-video" width="600" height="350" controls> -->
                      <video id="edit-video" height="250" controls>
                        <source :src="video.video_uri" />
                      </video>
                      <v-flex>
                        <v-text-field
                          label="Video URI"
                          v-model="video.video_uri"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-btn
                      color="red"
                      dark
                      :disabled="lastVideoInGroup"
                      @click="onClickDeleteVideo(video._id)"
                    >
                      <v-icon>delete</v-icon>Delete Video
                    </v-btn>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isSelectVideo"
      resourceType="video"
      :isShow="isSelectVideo"
      :close="closeSelectVideo"
      :save="saveSelectVideo"
      :value="selectedVideoUri"
      :onSelect="onSelectVideoResource"
    />
    <Modal
      v-if="isCreateGroup"
      :isOpen="isCreateGroup"
      :save="onSaveCreateGroup"
      :close="closeModalCreateGroup"
      :isLastStep="valid"
      title="Create Group"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  disabled
                  label="Category Name"
                  :value="selectedLearningMediaState.category"
                ></v-text-field>
                <v-text-field
                  required
                  label="Group Name"
                  v-model="groupmodel.group.name"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <Modal
      v-if="isCreateVideo"
      :isOpen="isCreateVideo"
      :save="onSaveCreateVideo"
      :close="closeModalCreateVideo"
      :isLastStep="valid"
      title="Create Learning Media"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  disabled
                  label="Category Name"
                  :value="selectedLearningMediaState.category"
                ></v-text-field>
                <v-text-field disabled label="Group Name" :value="group.name">
                </v-text-field>
                <v-btn
                  @click="
                    isCreateVideo = true;
                    isSelectVideo = true;
                  "
                >
                  Select Video
                </v-btn>
                <v-flex>
                  <video id="create-video" height="350" controls>
                    <source :src="videomodel.video.video_uri" />
                  </video>
                </v-flex>
                <v-text-field
                  required
                  label="Video URI"
                  v-model="videomodel.video.video_uri"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedGroupIndex !== -1
          ? selectedLearningMediaState.groups[this.selectedGroupIndex]
              .learned_words
          : null
      "
      :incidentalWords="
        selectedGroupIndex !== -1
          ? selectedLearningMediaState.groups[this.selectedGroupIndex]
              .incidental_words
          : null
      "
      :rewards="
        selectedGroupIndex !== -1
          ? selectedLearningMediaState.groups[this.selectedGroupIndex].reward
          : null
      "
      :sentences="
        selectedGroupIndex !== -1
          ? selectedLearningMediaState.groups[this.selectedGroupIndex].sentence
          : null
      "
      :phonics="
        selectedGroupIndex !== -1
          ? selectedLearningMediaState.groups[this.selectedGroupIndex].phonic
          : null
      "
    >
    </PointData>
  </Container>
</template>

<script>
import PointData from '../../components/PointData';
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  name: 'LearningMedia',
  components: {
    Container,
    Modal,
    ResourceSelect,
    PointData,
  },
  computed: {
    ...mapState({
      learningMediaStore: state => state.learningmedia,
      updatingState: state => state.learningmedia.isUpdating,
      selectedLearningMediaState: state =>
        state.learningmedia.SELECTED_LEARNING_MEDIA,
      selectedCategoryIdState: state =>
        state.learningmedia.SELECTED_CATEGORY_ID,
      createState: state => state.learningmedia.CREATE,
      readState: state => state.learningmedia.READ,
      updateState: state => state.learningmedia.UPDATE,
      deleteState: state => state.learningmedia.DELETE,
      selectedGroupIndexStore: state => state.learningmedia.selectedGroupIndex,
    }),
    ...mapGetters('learningmedia', ['getUpdatedGroup', 'getUpdatedVideo']),
  },
  data() {
    return {
      isOpenPointData: false,
      lastVideoInGroup: false,
      video: {},
      videos: [],
      valid: false,
      groupmodel: {
        group: {
          name: '',
        },
      },
      videomodel: {
        video: {
          video_uri: '',
        },
      },
      group: {},
      selectedVideoUri: '',
      isSelectVideo: false,
      isUpdateVideo: false,
      isCreateVideo: false,
      isCreateGroup: false,
      selectedGroupIndex: -1,
      selectedVideoIndex: -1,
    };
  },
  methods: {
    ...mapActions('learningmedia', [
      'setCreateState',
      'setCreateVideoState',
      'setUpdateGroupState',
      'setUpdateVideoState',
      'resetCreateState',
      'resetCreateVideoState',
      'fetchLearningMedia',
      'createVideo',
      'createGroup',
      'updateGroup',
      'setSelectedCategoryState',
      'setSelectedLearningMediaState',
      'fetchSingleLearningMedia',
      'updateVideo',
      'deleteVideo',
      'deleteGroup',
    ]),
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.setUpdateGroupState({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.setUpdateGroupState({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.setUpdateGroupState({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.setUpdateGroupState({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.setUpdateGroupState({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateGroup();
      this.isOpenPointData = false;
    },
    validateLastVideoLeft() {
      let groupStore =
        this.selectedLearningMediaState.groups[this.selectedGroupIndex].videos;
      if (groupStore.length === 1) {
        this.lastVideoInGroup = true;
      } else {
        this.lastVideoInGroup = false;
      }
    },
    saveSelectVideo() {
      const uri = this.selectedVideoUri;
      let video = null;
      if (this.isSelectVideo && this.isCreateVideo) {
        this.setCreateVideoState({
          key: 'video_uri',
          value: uri,
        });
        this.videomodel.video.video_uri = uri;
        video = document.getElementById('create-video');
      } else if (this.isSelectVideo && this.isUpdateVideo) {
        this.setUpdateVideoState({
          key: 'video_uri',
          value: uri,
        });
        video = document.getElementById('edit-video');
        this.video.video_uri = uri;
      }
      this.isSelectVideo = false;
      this.selectedVideoUri = '';
      if (video) {
        video.load();
      }
    },
    onSelectVideoResource(uri) {
      this.selectedVideoUri = uri;
    },
    closeSelectVideo() {
      this.isSelectVideo = false;
      this.selectedVideoUri = '';
    },
    onClickDeleteGroup(groupID) {
      this.deleteGroup({ groupID: groupID });
    },
    onClickDeleteVideo(videoID) {
      this.deleteVideo({ videoID: videoID });
    },
    onClickUpdateVideo() {
      this.updateVideo({
        _id: this.video._id,
        video_uri: this.video.video_uri,
      });
    },
    closeModalCreateVideo() {
      this.resetCreateState();
      this.isCreateVideo = false;
    },
    closeModalCreateGroup() {
      this.resetCreateState();
      this.isCreateGroup = false;
    },
    resetGroupForm() {
      this.groupmodel = {
        group: {
          name: '',
        },
      };
    },
    onCreateVideo(groupData) {
      this.isCreateVideo = true;
      this.group = { ...groupData };
      this.videomodel.video.video_uri = '';
      this.resetCreateVideoState();
    },
    onSelectVideo(id) {
      const videos = [
        ...this.selectedLearningMediaState.groups[this.selectedGroupIndex]
          .videos,
      ];
      const index = videos.findIndex(video => video._id === id);
      this.selectedVideoIndex = index;
      this.video = videos[index];
      const video = document.getElementById('edit-video');
      if (video) {
        video.load();
      }
    },
    onClickUpdateGroup() {
      const updateGroupData = {
        _id: this.group._id,
        name: this.group.name,
        ...this.getUpdatedGroup,
      };
      this.updateGroup(updateGroupData);
      this.selectedGroupIndex = -1;
      this.selectedVideoIndex = -1;
      this.video = {};
      this.videos = [];
    },
    onSaveCreateGroup() {
      this.isCreateGroup = false;
      this.createGroup({
        category_id: this.selectedCategoryIdState,
        ...this.groupmodel,
      });
    },
    onSaveCreateVideo() {
      this.isCreateVideo = false;
      this.createVideo({
        _id: this.group._id,
        ...this.videomodel,
      });
    },
    onSelectGroup(id) {
      const groups = this.selectedLearningMediaState.groups;
      const index = groups.findIndex(group => group._id === id);
      this.selectedGroupIndex = index;
      this.selectedVideoIndex = -1;
      this.video = {};
      this.videos = [];
      this.videos = [...groups[index].videos];
      this.group = {
        ...groups[index],
      };
      this.setUpdateGroupState({
        key: '_id',
        value: id,
      });
      this.validateLastVideoLeft();
    },
    createLearningMediaGroup() {
      this.selectedGroupIndex = -1;
      this.setCreateState({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.resetGroupForm();
      this.isCreateGroup = true;
    },
  },
  watch: {
    selectedGroupIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectGroup(this.$route.query.sub_id);
      }
    },
  },

  async mounted() {
    if (this.$route.query.category_id) {
      await this.fetchSingleLearningMedia({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (Object.keys(this.selectedLearningMediaState).length === 0) {
      await this.fetchSingleLearningMedia({ id: this.$route.query.id });
    }
  },
};
</script>

<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
</style>
