import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  speechLabTagList: [],
  selectedSpeechLabTag: {},
  updateSpeechLabTag: {},
  isLoadSpeechLabTag: true,
  defaultCreate: {
    tag_name: '',
    play_count: 5,
    cover_image: '',
    display_name: '',
    status: 0,
    level: 0,
  },
  checkTagDicphonicExists: true,
};
const getters = {};
const actions = {
  fetchSpeechLabTagList({ commit, dispatch }, { page = 1, limit = 0 }) {
    http
      .GET(`/api/speedlabtags?isWeb=true&page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'speechLabTagList',
          key: null,
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch drag combo in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadSpeechLabTag',
          key: null,
          value: false,
        });
      });
  },
  searchTagsDicphonic({ commit, dispatch }, { tags }) {
    http
      .GET(`/api/dicphonic/tags?tags=${tags}`, {
        isAuthorization: true,
      })
      .then(({ status, data }) => {
        const exists =
          data !== '' || status === 200
            ? true
            : 'tags name not found, please try another.';
        dispatch('setStateByKey', {
          statekey: 'checkTagDicphonicExists',
          key: null,
          value: exists,
        });
      });
    // .catch(() => {
    //   dispatch(
    //     'appConfig/toggleAlert',
    //     {
    //       message: 'Search tag not found.',
    //       type: 'error',
    //     },
    //     {
    //       root: true,
    //     },
    //   );
    //   throw new Error(err);
    // });
  },
  createSpeechLabTag({ commit, dispatch }, createData) {
    http
      .POST(`/api/speedlabtags`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchSpeechLabTagList', {
          page: 1,
          limit: 0,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create speech lab tag successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create speech lab tag in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteSpeechLabTag({ commit, dispatch }, id) {
    http
      .DELETE(`/api/speedlabtags?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'updateSpeechLabTag');
        dispatch('fetchSpeechLabTagList', {
          page: 1,
          limit: 0,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete speech lab tag successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete speech lab tag in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateSpeechLabTag({ commit, dispatch }, updateData) {
    updateData ? updateData : (updateData = state.updateSpeechLabTag);
    delete updateData.created_at;
    delete updateData.updated_at;
    http
      .PUT(`/api/speedlabtags`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchSpeechLabTagList', {
          page: 1,
          limit: 0,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update speech lab tag successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot update speech lab tag in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
