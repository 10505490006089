import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
const state = {
  pager: {},
  userList: [],
  showUserList: [],
  isFetchUserList: false,
  selectedUser: {},
  updateUserData: {},
  updateChildrenData: {},
};
const getters = {};
const actions = {
  fetchUsersByChildName(
    { commit, dispatch },
    config = { limit: 0, page: 1, key: '', value: '' },
  ) {
    commit('toggleIsFetchUserList');
    http
      .GET(
        `/api/users/infoByChildData?${config.key}=${config.value}&limit=${config.limit}&page=${config.page}`,
        {
          isAuthorization: true,
        },
      )
      .then(async ({ data }) => {
        const formattedData = data.pageOfItems.map(item => ({
          ...item,
          tier: item.tier,
          created_at: moment(item.created_at).format('YYYY MMM D h:mm A'),
          updated_at: moment(item.updated_at).format('YYYY MMM D h:mm A'),
          last_login: moment(item.last_login).format('YYYY MMM D h:mm A'),
          subscription_start_date: moment(item.subscription_start_date).format(
            'YYYY MMM D h:mm A',
          ),
          subscription_end_date: moment(item.subscription_end_date).format(
            'YYYY MMM D h:mm A',
          ),
          // subscription_end:
          //   new Date(
          //     moment(item.subscription_end).format('YYYY MMM D h:mm A'),
          //   ) > new Date(moment(new Date()).format('YYYY MMM D h:mm A'))
          //     ? moment(item.subscription_end).format('YYYY MMM D h:mm A')
          //     : '',
          // cancel_date: item.cancel_date
          //   ? moment(item.cancel_date).format('YYYY MMM D h:mm A')
          //   : '',
        }));

        const promiseFetchChirdrenData = formattedData.map(async user => {
          if (user.children) {
            const children = user.children.map(async child => {
              const { data } = await http.GET(
                `/api/users/children?id=${child}`,
                { isAuthorization: true },
              );
              const [childrenData] = data;
              return childrenData;
            });

            return {
              ...user,
              children: await Promise.all(children),
            };
          } else {
            return {
              ...user,
              children: [],
            };
          }
        });
        const usersData = {
          pager: data.pager,
          pageOfItems: await Promise.all(promiseFetchChirdrenData),
        };
        commit('storeUsers', usersData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch user data in this time, please try again later.',
            type: 'error',
          },
          { root: true },
        );
      })
      .finally(() => commit('toggleIsFetchUserList'));
  },
  fetchUsers({ commit, dispatch }, config = { page: 1, key: '', value: '' }) {
    commit('toggleIsFetchUserList');
    http
      .GET(
        `/api/users?${config.key ? config.key + '=' : ''}${
          config.value ? config.value + '&' : ''
        }page=${config.page}`,
        {
          isAuthorization: true,
        },
      )
      .then(async ({ data }) => {
        const formattedData = data.pageOfItems.map(item => ({
          ...item,
          tier: item.tier,
          created_at: moment(item.created_at).format('YYYY MMM D h:mm A'),
          updated_at: moment(item.updated_at).format('YYYY MMM D h:mm A'),
          last_login: moment(item.last_login).format('YYYY MMM D h:mm A'),
          subscription_start_date: moment(item.subscription_start_date).format(
            'YYYY MMM D h:mm A',
          ),
          subscription_end_date: moment(item.subscription_end_date).format(
            'YYYY MMM D h:mm A',
          ),
          deletion_time: moment(item.deletion_time).format('YYYY MMM D h:mm A'),
          // subscription_end:
          //   new Date(
          //     moment(item.subscription_end).format('YYYY MMM D h:mm A'),
          //   ) > new Date(moment(new Date()).format('YYYY MMM D h:mm A'))
          //     ? moment(item.subscription_end).format('YYYY MMM D h:mm A')
          //     : '',
          // cancel_date: item.cancel_date
          //   ? moment(item.cancel_date).format('YYYY MMM D h:mm A')
          //   : '',
        }));

        const promiseFetchChirdrenData = formattedData.map(async user => {
          if (user.children) {
            const children = user.children.map(async child => {
              const { data } = await http.GET(
                `/api/users/children?id=${child}`,
                { isAuthorization: true },
              );
              const [childrenData] = data;
              return childrenData;
            });

            return {
              ...user,
              children: await Promise.all(children),
            };
          } else {
            return {
              ...user,
              children: [],
            };
          }
        });
        const usersData = {
          pager: data.pager,
          pageOfItems: await Promise.all(promiseFetchChirdrenData),
        };
        commit('storeUsers', usersData);
      })
      .catch(error => {
        const errorMessage = error.response.data.error;
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              errorMessage ||
              'Cannot fetch user data in this time, please try again later.',
            type: 'error',
          },
          { root: true },
        );
      })
      .finally(() => commit('toggleIsFetchUserList'));
  },
  deletionTimeUser({ commit, dispatch }) {
    http
      .PUT(
        `/api/users/deletion_time`,
        {},
        {
          isAuthorization: true,
        },
      )
      .then(async data => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete user by deletion_time success',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchUsers', { page: 1, key: 'deletion_time', value: true });
      })
      .catch(error => {
        const errorMessage = error.response.data.error;
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              errorMessage ||
              'Cannot delete user by deletion_time in this time, please try again later.',
            type: 'error',
          },
          { root: true },
        );
      });
  },
  selectedUser({ commit }, id) {
    commit('storeSeleteUser', id);
  },
  updateUserData({ commit }, { key, value }) {
    commit('storeUpdateUser', { key, value });
  },
  clearSelectData({ commit }) {
    commit('clearSelectUser');
  },
  updateUser({ dispatch }, data) {
    let updateData = { ...data };

    Object.keys(updateData).forEach(key => {
      if (!typeof updateData[key] === 'boolean' && !updateData[key]) {
        delete updateData[key];
      }
    });

    http
      .PUT('/api/users', updateData, { isAuthorization: true })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Updata user data sucess',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Updata user data failed',
            type: 'error',
          },
          { root: true },
        );
      })
      .finally(() => dispatch('fetchUsers'));
  },
  deleteUser({ dispatch }, id) {
    swal
      .fire({
        title: 'Are you sure to delete',
        text: `id: ${id}`,
        type: 'question',
        showCancelButton: true,
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/users?id=${id}`, { isAuthorization: true })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete user data sucess',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchUsers');
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete user data failed',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  updateChildrenData({ commit }, { key, value }) {
    commit('storeUpdateChildren', { key, value });
  },
  updateChildren({ dispatch, state }) {
    let updateData = { ...state.updateChildrenData };
    Object.keys(updateData).forEach(key => {
      if (!typeof updateData[key] === 'boolean' && !updateData[key]) {
        delete updateData[key];
      }
    });
    http
      .PUT('/api/users/children', updateData, { isAuthorization: true })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Updata children data sucess',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Updata children data failed',
            type: 'error',
          },
          { root: true },
        );
      })
      .finally(() => dispatch('fetchUsers'));
  },
  deleteChildren({ dispatch, state }, index) {
    swal
      .fire({
        titleText: 'Are you sure to delete child data ?',
        type: 'question',
        showCancelButton: true,
      })
      .then(result => {
        if (result.value) {
          const id = state.selectedUser.children[index]._id;
          http
            .DELETE(`/api/users/children?id=${id}`, { isAuthorization: true })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete children data success',
                  type: 'success',
                },
                { root: true },
              );
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete children data failed',
                  type: 'error',
                },
                { root: true },
              );
            })
            .finally(() => dispatch('fetchUsers'));
        }
      });
  },
  addFreeDays({ dispatch }, { parent_id, free_days }) {
    http
      .POST(
        '/api/payment/subscription',
        { parent_id, free_days },
        {
          isAuthorization: true,
        },
      )
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add subscription day successfully.',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchUsers');
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: err.response.data.message,
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  storeUsers(state, userList) {
    state.userList = [...userList.pageOfItems];
    state.showUserList = [...userList.pageOfItems];
    state.pager = userList.pager;
  },
  toggleIsFetchUserList(state) {
    state.isFetchUserList = !state.isFetchUserList;
  },
  storeSeleteUser(state, id) {
    const Index = state.showUserList.findIndex(user => user._id === id);
    state.selectedUser = state.showUserList[Index];
  },
  clearSelectUser(state) {
    state.selectedUser = {};
    state.storeUpdateUser = {};
  },
  storeUpdateUser(state, { key, value }) {
    state.updateUserData = {
      ...state.updateUserData,
      [key]: value,
    };
  },
  storeUpdateChildren(state, { key, value }) {
    state.updateChildrenData = {
      ...state.updateChildrenData,
      [key]: value,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
