export default {
  TYPE: [
    {
      text: 'Ball',
      value: 'Ball',
    },
    {
      text: 'Box Animation',
      value: 'Box Animation',
    },
    {
      text: 'Doll',
      value: 'Doll',
    },
    {
      text: 'Flower',
      value: 'Flower',
    },
    {
      text: 'Duck Water Toy',
      value: 'Duck Water Toy',
    },
    {
      text: 'Radio',
      value: 'Radio',
    },
    {
      text: 'Instrument',
      value: 'Instrument',
    },
    {
      text: 'Clock',
      value: 'Clock',
    },
    {
      text: 'Table',
      value: 'Table',
    },
    {
      text: 'Car Toy',
      value: 'Car Toy',
    },
    {
      text: 'Fireworks',
      value: 'Fireworks',
    },
  ],
};
