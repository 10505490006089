<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/labeling')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            (labelingStore.selectLabeling &&
              labelingStore.selectLabeling.category) ||
            'Loading...'
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="labelingStore.selectLabeling">
          <v-layout row grid-list-md v-if="!labelingStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Label Set</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddLabelSet">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(labelSet, index) in labelingStore.selectLabeling
                      .label_sets"
                    :key="labelSet._id"
                    v-on:click="onSelectLabelSet(labelSet._id)"
                    :class="
                      index === selectedLabelSetIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ labelSet.name }}</v-list-tile-title>
                      <v-list-tile-sub-title v-if="labelSet.cards.length > 1"
                        >{{
                          labelSet.cards.length
                        }}
                        cards</v-list-tile-sub-title
                      >
                      <v-list-tile-sub-title v-else
                        >{{ labelSet.cards.length }} card</v-list-tile-sub-title
                      >
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedLabelSetIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Label Set Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickUpdateLabelSet">
                    <v-icon>save</v-icon>Save Label Set
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex>
                        <v-btn
                          class="white--text"
                          color="pointdata"
                          @click="openPointData"
                          >Point Data
                        </v-btn>
                        <Clipboard :objectToCopy="labelSet"> </Clipboard>
                      </v-flex>
                      <v-flex>
                        <v-checkbox
                          label="Available for Free User"
                          v-model="labelSet.isGuestPlayable"
                          :value="labelSet.isGuestPlayable"
                          v-on:change="
                            e => onChangeLabelSetData('isGuestPlayable', e)
                          "
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Label Set Name"
                          :value="labelSet.name"
                          v-on:change="e => onChangeLabelSetData('name', e)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Theme Color"
                          :value="labelSet.theme_color"
                          v-on:change="
                            e => onChangeLabelSetData('theme_color', e)
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-btn
                          v-if="labelSet.introduction_voice_uri"
                          class="white--text"
                          color="subtitle_sound"
                          @click="openSubtitleSound()"
                          >Subtitle Sound
                        </v-btn>
                        <v-text-field
                          label="Introduction Voice (URL or Select file)"
                          :value="labelSet.introduction_voice_uri"
                          v-model="labelSet.introduction_voice_uri"
                          @change="
                            e =>
                              onChangeLabelSetData('introduction_voice_uri', e)
                          "
                        ></v-text-field>
                        <v-btn
                          @click="
                            isEditSound = true;
                            isEditIntroVoice = true;
                          "
                          ><v-icon>audiotrack</v-icon>Select Introduction
                          Voice</v-btn
                        >
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Introduction Word(tts message)"
                          :value="labelSet.tts_message"
                          v-on:change="
                            e => onChangeLabelSetData('tts_message', e)
                          "
                        ></v-text-field>
                        <v-select
                          :items="constants.VOICE_NAME"
                          label="Voice Name"
                          :value="labelSet.voice_name"
                          v-model="labelSet.voice_name"
                          v-on:change="
                            e => onChangeLabelSetData('voice_name', e)
                          "
                        ></v-select>
                      </v-flex>
                      <v-flex xs6>
                        <v-btn
                          color="black"
                          dark
                          v-on:click="
                            isEditImage = true;
                            selectImage = 'background_uri';
                            isUpdateLabelSet = true;
                          "
                        >
                          <v-icon>image</v-icon>Edit Background Image
                        </v-btn>
                        <img
                          :src="labelSet.background_uri"
                          class="content_img"
                        />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <v-text-field
                                label="Background Image (URL or Select file)"
                                :value="labelSet.background_uri"
                                v-on:change="
                                  e => onChangeLabelSetData('background_uri', e)
                                "
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Background Music"
                          :value="labelSet.bgm_uri"
                          v-model="labelSet.bgm_uri"
                          v-on:change="e => onChangeLabelSetData('bgm_uri', e)"
                        ></v-text-field>
                        <v-btn
                          v-on:click="
                            isEditSound = true;
                            isEditBGM = true;
                          "
                          >Select Sound</v-btn
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout column class="elevation-2" style="padding: 20px">
                      <label>
                        <u>⌗ Rec.Event</u>
                      </label>
                      <td
                        v-for="(event, index) in rectEvent"
                        :key="index"
                        class="elevation-2"
                        style="
                          padding: 10px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <label>Event {{ index + 1 }}:</label>
                        <v-layout align-content-space-between>
                          <v-flex>
                            <v-text-field
                              label="Start X"
                              :value="event.startX"
                              v-on:change="
                                e => onChangeEventData(index, 'startX', e)
                              "
                            ></v-text-field>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="End X"
                              :value="event.endX"
                              v-on:change="
                                e => onChangeEventData(index, 'endX', e)
                              "
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout align-content-space-between>
                          <v-flex>
                            <v-text-field
                              label="Start Y"
                              :value="event.startY"
                              v-on:change="
                                e => onChangeEventData(index, 'startY', e)
                              "
                            ></v-text-field>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="End Y"
                              :value="event.endY"
                              v-on:change="
                                e => onChangeEventData(index, 'endY', e)
                              "
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </td>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="rectEvent && rectEvent.length > 0"
                          icon
                          color="red"
                          @click="onRemoveEventData"
                        >
                          <v-icon>remove</v-icon>
                        </v-btn>
                        <v-btn v-else icon color="grey">
                          <v-icon>remove</v-icon>
                        </v-btn>
                        <v-btn icon color="green" @click="onAddEventData">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-layout>
                    <v-btn
                      color="red"
                      dark
                      v-on:click="onClickDeleteLabelSet(labelSet._id)"
                    >
                      <v-icon>delete</v-icon>Delete this labelSet
                    </v-btn>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs2
              mr-2
              v-if="cards.length > 0 && selectedLabelSetIndex != -1"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Card</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="green"
                    v-on:click="toggleAddCard"
                    v-if="cards.length < 8"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(card, index) in labelingStore.selectLabeling
                      .label_sets[selectedLabelSetIndex].cards"
                    :key="card._id"
                    v-on:click="onSelectCard(card._id)"
                    :class="index === selectedCardIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ index + 1 }}. {{ card.name }}</v-list-tile-title
                      >
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="Object.keys(card).length > 1 && selectedLabelSetIndex != -1"
              xs4
              mr-2
            >
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Card Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickSaveCard">
                    <v-icon>save</v-icon>Save card
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex xs6>
                        <v-layout column>
                          <v-flex>
                            <v-checkbox
                              label="Clickable"
                              v-model="card.clickable"
                              :value="card.clickable"
                              v-on:change="
                                e => onChangeCardData('clickable', e)
                              "
                            ></v-checkbox>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Name"
                              :value="card.name"
                              v-on:change="e => onChangeCardData('name', e)"
                            />
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Sound (URL or Select file)"
                              :value="card.sound_uri"
                              v-on:change="
                                e => onChangeCardData('sound_uri', e)
                              "
                            />
                            <v-btn
                              color="black"
                              v-on:click="isEditSound = true"
                            >
                              <v-icon>audiotrack</v-icon>Edit Sound
                            </v-btn>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Introduction Word(tts message)"
                              :value="card.tts_message"
                              v-on:change="
                                e => onChangeCardData('tts_message', e)
                              "
                            ></v-text-field>
                            <v-select
                              :items="constants.VOICE_NAME"
                              label="Voice Name"
                              :value="card.voice_name"
                              v-model="card.voice_name"
                              v-on:change="
                                e => onChangeCardData('voice_name', e)
                              "
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6>
                        <img
                          :src="card.image_holder_uri"
                          :alt="card.card"
                          class="content_img"
                        />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <v-text-field
                                label="Seek Image (URL or Select file)"
                                :value="card.image_holder_uri"
                                v-on:change="
                                  e => onChangeCardData('image_holder_uri', e)
                                "
                              />
                              <span class="headline" dark>
                                <v-btn
                                  color="black"
                                  v-on:click="
                                    isEditImage = true;
                                    selectImage = 'image_holder_uri';
                                  "
                                >
                                  <v-icon>image</v-icon>Edit Seek Image
                                </v-btn>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                      <v-flex xs6>
                        <img
                          :src="card.image_colour_uri"
                          :alt="card.card"
                          class="content_img"
                        />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <v-text-field
                                label="Show Image (URL or Select file)"
                                :value="card.image_colour_uri"
                                v-on:change="
                                  e => onChangeCardData('image_colour_uri', e)
                                "
                              />
                              <span class="headline" dark>
                                <v-btn
                                  color="black"
                                  v-on:click="
                                    isEditImage = true;
                                    selectImage = 'image_colour_uri';
                                  "
                                >
                                  <v-icon>image</v-icon>Edit Show Image
                                </v-btn>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          type="number"
                          label="Show Image Size"
                          :value="card.image_colour_size"
                          v-model="card.image_colour_size"
                          v-on:change="
                            e => onChangeCardData('image_colour_size', e)
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Show Image Sound (URL or Select file)"
                          :value="card.image_colour_sound_uri"
                          v-on:change="
                            e => onChangeCardData('image_colour_sound_uri', e)
                          "
                        />
                        <v-btn
                          color="black"
                          v-on:click="
                            isEditSound = true;
                            isEditFoundSound = true;
                          "
                        >
                          <v-icon>audiotrack</v-icon>Edit Found Sound
                        </v-btn>
                      </v-flex>
                      <!-- <v-flex>
                        <label>Increase second image size:</label>
                        <br />
                        <input
                          type="radio"
                          id="zero"
                          :value="0"
                          v-model="increase_size"
                          class="radio-button"
                          v-on:change="onChangeImageSize()"
                        />
                        <label for="zero">not increase</label>
                        <input
                          type="radio"
                          id="ten"
                          :value="10"
                          v-model="increase_size"
                          class="radio-button"
                          v-on:change="onChangeImageSize()"
                        />
                        <label for="ten">10%</label>
                        <input
                          type="radio"
                          id="twenty"
                          :value="20"
                          v-model="increase_size"
                          class="radio-button"
                          v-on:change="onChangeImageSize()"
                        />
                        <label for="twenty">20%</label>
                        <input
                          type="radio"
                          id="fifty"
                          :value="50"
                          v-model="increase_size"
                          class="radio-button"
                          v-on:change="onChangeImageSize()"
                        />
                        <label for="fifty">50%</label>
                        <br />
                        <br />
                      </v-flex> -->
                      <!-- <v-flex>
                        <label>Nana Popup</label>
                        <v-select
                          :items="constants.POPUP_ANIMATION"
                          label="Action"
                          :value="nana_pop.action"
                          v-model="nana_pop.action"
                          v-on:change="
                            e =>
                              onChangePopupData('action', e, selectedCardIndex)
                          "
                        ></v-select>
                      </v-flex>
                      <v-flex>
                        <v-select
                          :items="constants.POPUP_TYPE"
                          label="type"
                          :value="nana_pop.type"
                          v-model="nana_pop.type"
                          v-on:change="
                            e => onChangePopupData('type', e, selectedCardIndex)
                          "
                        ></v-select>
                      </v-flex>
                      <v-flex v-if="nana_pop.type == 1">
                        <v-text-field
                          label="Text"
                          :value="nana_pop.objectData"
                          v-on:change="
                            e =>
                              onChangePopupData(
                                'objectData',
                                e,
                                selectedCardIndex,
                              )
                          "
                        />
                      </v-flex> -->
                    </v-layout>
                  </v-container>
                  <v-btn
                    v-if="cards.length > 1"
                    color="red"
                    dark
                    v-on:click="onClickDeleteCard(card._id)"
                  >
                    <v-icon>delete</v-icon>Delete this card
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedLabelSetIndex != -1
          ? labelingStore.selectLabeling.label_sets[selectedLabelSetIndex]
              .learned_words
          : null
      "
      :incidentalWords="
        selectedLabelSetIndex != -1
          ? labelingStore.selectLabeling.label_sets[selectedLabelSetIndex]
              .incidental_words
          : null
      "
      :rewards="
        selectedLabelSetIndex != -1
          ? labelingStore.selectLabeling.label_sets[selectedLabelSetIndex]
              .reward
          : null
      "
      :sentences="
        selectedLabelSetIndex != -1
          ? labelingStore.selectLabeling.label_sets[selectedLabelSetIndex]
              .sentence
          : null
      "
      :phonics="
        selectedLabelSetIndex != -1
          ? labelingStore.selectLabeling.label_sets[selectedLabelSetIndex]
              .phonic
          : null
      "
    >
    </PointData>
    <Modal
      :v-if="isAddLabelSet"
      :isOpen="isAddLabelSet"
      :isLastStep="isLastStep"
      :close="toggleAddLabelSet"
      :save="onClickAddLabelSet"
      title="Add LabelSet"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center v-if="currentStep == -1">
          <v-flex>
            <v-text-field
              label="Name"
              :value="labelSet.name"
              v-model="labelSet.name"
              v-on:change="e => onChangeCreateLabelSet('name', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Theme Color"
              :value="labelSet.theme_color"
              v-model="labelSet.theme_color"
              v-on:change="e => onChangeCreateLabelSet('theme_color', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Introduction Word(tts message)"
              :value="labelSet.tts_message"
              v-on:change="e => onChangeCreateLabelSet('tts_message', e)"
            ></v-text-field>
            <v-select
              :items="constants.VOICE_NAME"
              label="Voice Name"
              :value="labelSet.voice_name"
              v-model="labelSet.voice_name"
              v-on:change="e => onChangeCreateLabelSet('voice_name', e)"
            ></v-select>
          </v-flex>
          <v-flex xs6>
            <img :src="labelSet.background_uri" class="content_img" />
          </v-flex>
          <v-flex xs6 align-end flexbox>
            <v-text-field
              label="Background Image (URL or Select file)"
              :value="labelSet.background_uri"
              v-on:change="e => onChangeCreateLabelSet('background_uri', e)"
            />
          </v-flex>
          <v-flex>
            <v-btn
              color="black"
              dark
              v-on:click="
                isEditImage = true;
                selectImage = 'background_uri';
              "
            >
              <v-icon>image</v-icon>Edit Background Image
            </v-btn>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Background Music"
              :value="labelSet.bgm_uri"
              v-model="labelSet.bgm_uri"
              v-on:change="e => onChangeCreateLabelSet('bgm_uri', e)"
            ></v-text-field>
            <v-btn
              v-on:click="
                isEditSound = true;
                isEditBGM = true;
              "
              >Select Sound</v-btn
            >
          </v-flex>
        </v-layout>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title>Card {{ currentStep + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-flex>
              <v-checkbox
                label="Clickable"
                v-model="card.clickable"
                :value="card.clickable"
                v-on:change="e => onChangeCreateCard('clickable', e)"
              ></v-checkbox>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Name"
                :value="card.name"
                v-model="card.name"
                v-on:change="e => onChangeCreateCard('name', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Sound"
                :value="card.sound_uri"
                v-model="card.sound_uri"
                v-on:change="e => onChangeCreateCard('sound_uri', e)"
                disabled
              ></v-text-field>
              <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
            </v-flex>
            <v-text-field
              label="Introduction Word(tts message)"
              :value="card.tts_message"
              v-on:change="e => onChangeCreateCard('tts_message', e)"
            ></v-text-field>
            <v-select
              :items="constants.VOICE_NAME"
              label="Voice Name"
              :value="card.voice_name"
              v-model="card.voice_name"
              v-on:change="e => onChangeCreateCard('voice_name', e)"
            ></v-select>
            <v-container justify-center>
              <v-layout>
                <v-flex>
                  <v-img :src="card.image_holder_uri" width="400" height="300">
                    <v-btn
                      v-on:click="
                        isEditImage = true;
                        selectImage = 'image_holder_uri';
                      "
                      >Select Seek Image</v-btn
                    >
                  </v-img>
                </v-flex>
              </v-layout>
            </v-container>
            <v-container justify-center>
              <v-layout>
                <v-flex>
                  <v-img :src="card.image_colour_uri" width="400" height="300">
                    <v-btn
                      v-on:click="
                        isEditImage = true;
                        selectImage = 'image_colour_uri';
                      "
                      >Select Show Image</v-btn
                    >
                  </v-img>
                </v-flex>
              </v-layout>
            </v-container>
            <label>Nana Popup</label>
            <v-flex>
              <v-select
                :items="constants.POPUP_ANIMATION"
                label="Action"
                :value="nana_pop.action"
                v-model="nana_pop.action"
                v-on:change="e => onChangeCreatePopupData('action', e, index)"
              ></v-select>
            </v-flex>
            <v-flex>
              <v-select
                :items="constants.POPUP_TYPE"
                label="type"
                :value="nana_pop.type"
                v-model="nana_pop.type"
                v-on:change="e => onChangeCreatePopupData('type', e, index)"
              ></v-select>
            </v-flex>
            <v-flex v-if="nana_pop.type == 1">
              <v-text-field
                label="Text"
                :value="nana_pop.objectData"
                v-on:change="
                  e => onChangeCreatePopupData('objectData', e, index)
                "
              />
            </v-flex>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn
            v-if="currentStep > -1"
            color="red"
            dark
            v-on:click="onClickBackStep"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentStep + 1 < cards.length"
            color="green"
            dark
            v-on:click="onClickNextStep"
            >Next</v-btn
          >
          <v-btn
            v-if="currentStep + 1 == cards.length && currentStep + 1 < maxCard"
            color="green"
            dark
            v-on:click="onClickNextStep"
            >Add More Card</v-btn
          >
        </v-card-actions>
      </v-container>
    </Modal>
    <Modal
      :v-if="isAddCard"
      :isOpen="isAddCard"
      :close="toggleAddCard"
      :save="onClickAddCard"
      title="Add Card"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex>
            <v-checkbox
              label="Clickable"
              v-model="card.clickable"
              :value="card.clickable"
              v-on:change="e => onChangeCreateSingleCard('clickable', e)"
            ></v-checkbox>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Text"
              :value="card.name"
              v-model="card.name"
              v-on:change="e => onChangeCreateSingleCard('name', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Sound"
              :value="card.sound_uri"
              v-model="card.sound_uri"
              v-on:change="e => onChangeCreateSingleCard('sound_uri', e)"
            ></v-text-field>
            <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Introduction Word(tts message)"
              :value="card.tts_message"
              v-on:change="e => onChangeCreateSingleCard('tts_message', e)"
            ></v-text-field>
            <v-select
              :items="constants.VOICE_NAME"
              label="Voice Name"
              :value="card.voice_name"
              v-model="card.voice_name"
              v-on:change="e => onChangeCreateSingleCard('voice_name', e)"
            ></v-select>
          </v-flex>
          <v-container justify-center>
            <v-layout>
              <v-flex>
                <v-img
                  :src="card.image_holder_uri"
                  width="300"
                  height="300"
                ></v-img>
                <v-text-field
                  label="Seek Image"
                  :value="card.image_holder_uri"
                  v-model="card.image_holder_uri"
                  v-on:change="
                    e => onChangeCreateSingleCard('image_holder_uri', e)
                  "
                ></v-text-field>
                <v-btn
                  v-on:click="
                    isEditImage = true;
                    selectImage = 'image_holder_uri';
                  "
                  >Select Image</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
          <v-container justify-center>
            <v-layout>
              <v-flex>
                <v-img
                  :src="card.image_colour_uri"
                  width="300"
                  height="300"
                ></v-img>
                <v-text-field
                  label="Show Image"
                  :value="card.image_colour_uri"
                  v-model="card.image_colour_uri"
                  v-on:change="
                    e => onChangeCreateSingleCard('image_colour_uri', e)
                  "
                ></v-text-field>
                <v-btn
                  v-on:click="
                    isEditImage = true;
                    selectImage = 'image_colour_uri';
                  "
                  >Select Image</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
          <label>Nana Popup</label>
          <v-flex>
            <v-select
              :items="constants.POPUP_ANIMATION"
              label="Action"
              :value="nana_pop.action"
              v-model="nana_pop.action"
              v-on:change="
                e => onChangeCreateSinglePopupData('action', e, index)
              "
            ></v-select>
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.POPUP_TYPE"
              label="type"
              :value="nana_pop.type"
              v-model="nana_pop.type"
              v-on:change="e => onChangeCreateSinglePopupData('type', e, index)"
            ></v-select>
          </v-flex>
          <v-flex v-if="nana_pop.type == 1">
            <v-text-field
              label="Text"
              :value="nana_pop.objectData"
              v-on:change="
                e => onChangeCreateSinglePopupData('objectData', e, index)
              "
            />
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
.radio-button {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
<script>
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import swal from 'sweetalert2';
import constants from '../../constants';
import Clipboard from '../../components/Clipboard.vue';

export default {
  name: 'Labeling',
  components: {
    Clipboard,
    Container,
    ResourceSelect,
    Modal,
    PointData,
  },
  computed: {
    ...mapState({
      labelingStore: state => state.labeling,
      selectedSetIndexStore: state => state.labeling.selectedSetIndex,
    }),
  },
  data() {
    return {
      isEditIntroVoice: false,
      currentStep: -1,
      isLastStep: false,
      maxCard: 5,
      cards: [],
      card: {},
      rectEvent: [],
      nana_pop: {},
      labelSet: {},
      selectedLabelSetIndex: -1,
      selectedCardIndex: -1,
      selectImage: '',
      isEditImage: false,
      selectImageUri: '',
      isEditSound: false,
      isEditBGM: false,
      isEditFoundSound: false,
      selectSoundUri: '',
      text: '',
      isDirty: false,
      isAddCard: false,
      isAddLabelSet: false,
      isUpdateLabelSet: false,
      constants,
      selectLabelSetId: '',
      increase_size: 0,
      isOpenPointData: false,
    };
  },
  methods: {
    ...mapActions('labeling', [
      'fetchLabeling',
      'fetchSingleLabeling',
      'updateCardData',
      'updateCard',
      'clearUpdateData',
      'deleteLabelSet',
      'deleteCard',
      'updateCreateLabelSetData',
      'updateCreateCardData',
      'updateCreateSingleCardData',
      'updateLabelSetData',
      'updateLabelSet',
      'addLabelSet',
      'addCardByLabelSetId',
      'selectCategory',
      'selectLabeling',
    ]),
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.labelSet.introduction_voice_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.updateLabelSetData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateLabelSetData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateLabelSetData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateLabelSetData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateLabelSetData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateLabelSet();
      this.isOpenPointData = false;
    },

    onClickNextStep() {
      this.currentStep++;
      if (this.currentStep === 2) {
        this.isLastStep = true;
      }
      this.onActiveCreateCard();
    },
    onClickBackStep() {
      this.currentStep--;
      this.onActiveCreateCard();
    },
    async onSelectLabelSet(id) {
      const label_sets = this.labelingStore.selectLabeling.label_sets;
      const index = label_sets.findIndex(labelSet => labelSet._id === id);
      this.selectedLabelSetIndex = index;
      this.selectedCardIndex = -1;
      this.cards = [];
      this.card = {};
      this.cards = [...label_sets[index].cards];
      let formatLabelSet = {};
      Object.keys(label_sets[index]).forEach(key => {
        formatLabelSet[key] = label_sets[index][key];
      });
      this.labelSet = {
        ...formatLabelSet,
      };
      this.rectEvent = this.labelSet.rectEvent;
      this.updateLabelSetData({
        key: '_id',
        value: id,
      });
      this.selectLabelSetId = id;
    },
    onSelectCard(id) {
      if (this.isDirty) {
        swal
          .fire({
            titleText:
              'You have not save this card yet. Are you sure you want to leave?',
            type: 'info',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              this.clearUpdateData();
              this.isDirty = false;
              const cards = [
                ...this.labelingStore.selectLabeling.label_sets[
                  this.selectedLabelSetIndex
                ].cards,
              ];
              const index = cards.findIndex(card => card._id === id);
              this.selectedCardIndex = index;
              this.card = cards[index];
              this.nana_pop =
                this.card.nana_pop && this.card.nana_pop.length > 0
                  ? this.card.nana_pop[0]
                  : {};
              this.selectImageUri = '';
              this.increase_size = this.card.increase_size
                ? this.card.increase_size
                : 0;
              this.updateCardData({
                key: '_id',
                value: id,
              });
            }
          });
      } else {
        this.clearUpdateData();
        const cards = [
          ...this.labelingStore.selectLabeling.label_sets[
            this.selectedLabelSetIndex
          ].cards,
        ];
        const index = cards.findIndex(card => card._id === id);
        this.selectedCardIndex = index;
        this.card = cards[index];
        this.nana_pop =
          this.card.nana_pop && this.card.nana_pop.length > 0
            ? this.card.nana_pop[0]
            : {};
        this.selectImageUri = '';
        this.increase_size = this.card.increase_size
          ? this.card.increase_size
          : 0;
        this.updateCardData({
          key: '_id',
          value: id,
        });
      }
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    onChangeCardData(key, value) {
      value = this.setNullToFalseOnBoolean(key, value);
      this.isDirty = true;
      this.card[key] = value;
      this.updateCardData({
        key,
        value,
      });
    },
    onChangeImageSize() {
      this.updateCardData({
        key: 'increase_size',
        value: this.increase_size,
      });
    },
    onChangePopupData(key, value) {
      this.isDirty = true;
      this.nana_pop[key] = value;
      this.updateCardData({
        key: 'nana_pop',
        value: [this.nana_pop],
      });
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddLabelSet && this.currentStep < 0) {
        this.updateCreateLabelSetData({
          key: this.selectImage,
          value: uri,
          index: this.currentStep,
        });
        this.labelSet.background_uri = uri;
      } else if (this.isAddLabelSet && this.currentStep > -1) {
        this.updateCreateCardData({
          key: this.selectImage,
          value: uri,
          index: this.currentStep,
        });
      } else if (this.isAddCard) {
        this.updateCreateSingleCardData({
          key: this.selectImage,
          value: uri,
        });
      } else if (this.isUpdateLabelSet) {
        this.updateLabelSetData({
          key: this.selectImage,
          value: uri,
        });
        this.labelSet.background_uri = uri;
        this.isUpdateLabelSet = !this.isUpdateLabelSet;
      } else {
        this.isDirty = true;
        this.updateCardData({
          key: this.selectImage,
          value: uri,
        });
      }
      // Update btn name
      if (this.selectImage === 'image_holder_uri') {
        this.card.image_holder_uri = uri;
      } else if (this.selectImage === 'image_colour_uri') {
        this.card.image_colour_uri = uri;
      } else if (this.selectImage === 'background_uri') {
        this.card.background_uri = uri;
      }
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isEditIntroVoice) {
        this.updateLabelSetData({
          key: 'introduction_voice_uri',
          value: uri,
        });
        this.labelSet.introduction_voice_uri = uri;
        this.isEditIntroVoice = false;
      } else if (this.isAddLabelSet && this.isEditBGM) {
        this.updateCreateLabelSetData({
          key: 'bgm_uri',
          value: uri,
        });
        this.labelSet.bgm_uri = uri;
      } else if (this.isAddCard) {
        this.updateCreateSingleCardData({
          key: 'sound_uri',
          value: uri,
        });
        this.card.sound_uri = uri;
      } else if (!this.isAddLabelSet && !this.isAddCard && this.isEditBGM) {
        this.updateLabelSetData({
          key: 'bgm_uri',
          value: uri,
        });
        this.labelSet.bgm_uri = uri;
      } else if (this.isEditFoundSound) {
        this.updateCardData({
          key: 'image_colour_sound_uri',
          value: uri,
        });
        this.card.image_colour_sound_uri = uri;
        this.isEditFoundSound = !this.isEditFoundSound;
      } else if (this.isAddLabelSet && this.currentStep > -1) {
        this.updateCreateCardData({
          key: 'sound_uri',
          value: uri,
          index: this.currentStep,
        });
        this.card.sound_uri = uri;
      } else {
        this.isDirty = true;
        this.updateCardData({
          key: 'sound_uri',
          value: uri,
        });
        this.card.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    onClickSaveCard() {
      const updateCardData = {
        ...this.labelingStore.updateCardData,
      };
      this.updateCard(updateCardData);
      this.isDirty = false;
      Object.keys(updateCardData).forEach(key => {
        this.card[key] = updateCardData[key];
      });
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    onActiveCreateCard() {
      const cards = [...this.labelingStore.addCardData];
      if (this.currentStep == cards.length && this.currentStep < this.maxCard) {
        const new_card = { ...this.labelingStore.cardData };
        this.card = { ...new_card };
        this.cards = [...cards, new_card];
        this.updateCreateCardData({
          key: 'name',
          value: '',
          index: this.currentStep,
        });
        // set default clickable to true
        this.updateCreateCardData({
          key: 'clickable',
          value: true,
          index: this.currentStep,
        });
      } else {
        this.card = { ...cards[this.currentStep] };
        this.cards = [...cards];
      }
    },
    onChangeCreateCard(key, value) {
      value = this.setNullToFalseOnBoolean(key, value);
      this.card[key] = value;
      this.updateCreateCardData({
        key,
        value,
        index: this.currentStep,
      });
    },
    onChangeCreatePopupData(key, value) {
      this.updateCreateCardData({
        key: 'nana_pop',
        value: [this.nana_pop],
        index: this.currentStep,
      });
    },
    setNullToFalseOnBoolean(key, value) {
      if (key === 'clickable' && !value) {
        return false;
      } else {
        return value;
      }
    },
    onChangeCreateSingleCard(key, value) {
      value = this.setNullToFalseOnBoolean(key, value);
      this.card[key] = value;
      this.updateCreateSingleCardData({
        key,
        value,
      });
    },
    onChangeCreateSinglePopupData(key, value) {
      this.updateCreateCardData({
        key: 'nana_pop',
        value: [this.nana_pop],
      });
    },
    toggleAddLabelSet() {
      this.selectedLabelSetIndex = -1;
      this.selectedCardIndex = -1;
      // init labelSet data
      this.updateCreateLabelSetData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.labelSet = { ...this.labelingStore.addLabelSetData };
      // init card data
      this.cards = [...this.labelingStore.addCardData];
      this.isAddLabelSet = !this.isAddLabelSet;
      if (!this.isAddLabelSet && this.labelingStore.selectedCategoryId) {
        this.$router.push('/labeling');
      }
    },
    onClickAddLabelSet() {
      const id = this.$route.query.id;
      const createLabelSetData = {
        ...this.labelingStore.addLabelSetData,
      };
      const createCardData = [...this.labelingStore.addCardData];
      this.addLabelSet({
        category_id: id,
        labelSet: createLabelSetData,
        cards: createCardData,
      });
      this.isAddLabelSet = false;
    },
    onClickDeleteLabelSet(id) {
      this.deleteLabelSet(id);
      this.isDirty = false;
      this.selectedLabelSetIndex = -1;
      this.card = {};
      this.cards = [];
      this.selectedCardIndex = -1;
    },
    toggleAddCard() {
      this.selectedLabelSetIndex = -1;
      this.selectedCardIndex = -1;
      this.card = { ...this.labelingStore.cardData };
      // set default clickable to true
      this.updateCreateSingleCardData({
        key: 'clickable',
        value: true,
      });
      this.isAddCard = !this.isAddCard;
    },
    onClickAddCard() {
      const id = this.$route.query.id;
      const createCardData = {
        ...this.labelingStore.addSingleCardData,
      };
      this.addCardByLabelSetId({
        category_id: id,
        labelset_id: this.selectLabelSetId,
        card: createCardData,
      });
      this.isAddCard = false;
    },
    onClickDeleteCard(id) {
      this.deleteCard(id);
      this.isDirty = false;
      this.selectedCardIndex = -1;
      this.card = {};
    },
    onChangeLabelSetData(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.updateLabelSetData({
        key,
        value,
      });
    },
    onClickUpdateLabelSet() {
      const updateLabelSetData = {
        ...this.labelingStore.updateLabelSetData,
      };
      this.updateLabelSet(updateLabelSetData);
      this.selectedLabelSetIndex = -1;
      this.selectedCardIndex = -1;
      this.card = {};
      this.cards = [];
      this.labelingStore.updateLabelSetData = {};
    },
    onChangeCreateLabelSet(key, value) {
      this.updateCreateLabelSetData({
        key,
        value,
      });
    },
    onChangeEventData(index, key, value) {
      this.isDirty = true;
      const rectEvent = [...this.rectEvent];
      rectEvent[index][key] = value;
      this.rectEvent = rectEvent;
      this.updateLabelSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onAddEventData(index) {
      const rectEvent = this.rectEvent ? [...this.rectEvent] : [];
      this.rectEvent = [...rectEvent, { ...this.labelingStore.eventData }];
      this.updateLabelSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onRemoveEventData(index) {
      this.isDirty = true;
      const rectEvent = [...this.rectEvent];
      rectEvent.pop();
      this.rectEvent = rectEvent;
      this.updateLabelSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
  },
  watch: {
    selectedSetIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectLabelSet(this.$route.query.sub_id);
      }
    },
  },
  mounted() {
    const id = this.$route.query.id || this.$route.query.category_id,
      sub_id = this.$route.query.sub_id;
    if (this.labelingStore.selectedCategoryId) {
      this.toggleAddLabelSet();
    } else if (id) {
      this.fetchSingleLabeling({
        id,
        sub_id,
      });
    }
  },
};
</script>
