<template>
  <v-toolbar dark>
    <v-btn icon color="white" light @click="onClickBack()">
      <v-icon>arrow_back</v-icon>
    </v-btn>
    <v-toolbar-title>
      {{
        (shortStoryStore.selectedShortStory &&
          shortStoryStore.selectedShortStory.name) ||
        'Loading...'
      }}
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    ...mapActions('short_story', [
      'clearUpdateGame',
      'clearUpdateEvent',
      'clearUpdateFilter',
      'clearSelectedFilterIndex',
      'clearSelectedEventIndex',
      'clearShortStoryListByTags',
      'clearDirty',
    ]),
    onClickBack() {
      this.clearUpdateGame();
      this.clearUpdateEvent();
      this.clearUpdateFilter();
      this.clearSelectedFilterIndex();
      this.clearSelectedEventIndex();
      this.clearShortStoryListByTags();
      this.clearDirty();
      this.$router.push('/short_story');
    },
  },
};
</script>

<style></style>
