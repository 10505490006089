<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Speech Lab Tag</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="isCreate = true">
            <v-icon>add</v-icon>New Speech Lab Tag
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex xs3>
                <v-text-field
                  outline
                  v-model="search"
                  label="Search by tag"
                  append-icon="search"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-data-table
            v-if="!isLoadSpeechLabTag"
            :items="speechLabTagList"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>
                <v-layout justify-center>
                  <div>
                    <v-checkbox
                      class="checkbox-free"
                      v-model="props.item.isGuestPlayable"
                      disabled
                    ></v-checkbox>
                  </div>
                </v-layout>
              </td>
              <td>
                <v-layout justify-center>{{
                  props.item.display_name
                }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  <img
                    v-if="props.item.cover_image"
                    lazy="props.item.cover_image"
                    class="image_thumbnail"
                    :src="props.item.cover_image"
                  />
                  <img v-else class="content_img" />
                </v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  <v-chip
                    v-if="formatDisplayTag(props.item.tag_name)"
                    color="primary"
                    text-color="white"
                    >{{ formatDisplayTag(props.item.tag_name) }}</v-chip
                  >
                  <v-chip
                    v-if="!formatDisplayTag(props.item.tag_name)"
                    color="grey"
                    text-color="white"
                    >tag not found</v-chip
                  >
                </v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.play_count }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  {{
                    props.item.status !== undefined
                      ? constants.status[props.item.status].name
                      : ''
                  }}
                </v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.level }}</v-layout>
              </td>
              <td>
                <v-layout justify-center> {{ props.item.updated_at }}</v-layout>
              </td>
              <td>
                <v-layout justify-end>
                  <v-btn
                    icon
                    color="black"
                    v-on:click="onClickEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item._id)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreate :isOpen="isCreate" @close="isCreate = false"></ModalCreate>
    <ModalUpdate :isOpen="isUpdate" @close="isUpdate = false"></ModalUpdate>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../components/Container';
import { ModalCreate, ModalUpdate } from './components';
import rules from '../../utils/ruleValidate';
import swal from 'sweetalert2';
import constants from './components/constants';

export default {
  name: 'SpeechLabTag',
  components: {
    Container,
    ModalCreate,
    ModalUpdate,
  },
  data() {
    return {
      constants,
      search: '',
      headers: [
        {
          text: 'Available for Free User',
          value: 'isGuestPlayable',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Display Name',
          value: 'display_name',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Cover Image',
          value: 'cover_img',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Tag Name',
          value: 'tag_name',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Play Count',
          value: 'play_count',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Level',
          value: 'level',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
          align: 'center',
          sortable: false,
        },
        { text: '', value: '', sortable: false },
      ],
      isCreate: false,
      isUpdate: false,
      rules,
    };
  },
  mounted() {
    this.fetchSpeechLabTagList({ page: 1, limit: 0 });
  },
  computed: {
    ...mapState({
      speechLabTagStore: state => state.speech_lab_tag,
      speechLabTagList: state => state.speech_lab_tag.speechLabTagList,
      isLoadSpeechLabTag: state => state.speech_lab_tag.isLoadSpeechLabTag,
    }),
  },
  methods: {
    ...mapActions('appConfig', ['toggleAlert']),
    ...mapActions('speech_lab_tag', [
      'fetchSpeechLabTagList',
      'deleteSpeechLabTag',
      'setStateByKey',
    ]),
    onClickEdit(id) {
      const [selectedData] = this.speechLabTagList.filter(
        data => data._id === id,
      );
      if (selectedData) {
        this.setStateByKey({
          statekey: 'selectedSpeechLabTag',
          value: selectedData,
        });
        this.isUpdate = true;
      }
    },
    onClickDelete(id) {
      swal
        .fire({
          titleText: 'Are you sure.',
          type: 'warning',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
            this.deleteSpeechLabTag(id);
          }
        });
    },
    formatDisplayTag(value) {
      if (value) {
        return value.toString().split(',').join(' | ');
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}
</style>
