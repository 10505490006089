<template>
  <v-layout row grid-list-md>
    <v-flex class="pb-4" xs6>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title> Quiz Game </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            light
            :disabled="!validate"
            color="success"
            @click="saveTopic()"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-center">
          <v-form ref="form_quiz" v-model="validate" lazy-validation>
            <v-layout row grid-list-md>
              <v-flex>
                <v-text-field
                  label="Title"
                  :value="pageGameStore.homePageList.quiz_game_title"
                  v-model.trim="pageGameStore.homePageList.quiz_game_title"
                  @change="e => onchangTopic('quiz_game_title', e.trim())"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-autocomplete
                  v-model="pageGameStore.homePageList.quiz_game_id"
                  :items="quizGameList"
                  item-text="title"
                  item-value="_id"
                  color="green"
                  :search-input.sync="searchQuizGame"
                  flat
                  hide-no-data
                  hide-details
                  label="Quiz Game Id (Search)"
                  prepend-icon="mdi-database-search"
                  @change="e => onchangTopic('quiz_game_id', e)"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
            <img
              v-if="pageGameStore.homePageList.quiz_game_image_uri"
              :src="pageGameStore.homePageList.quiz_game_image_uri"
              :alt="pageGameStore.homePageList.quiz_game_image_uri"
              class="content_img"
            />
            <v-text-field
              label="Image URI (URL or Select file)"
              :value="pageGameStore.homePageList.quiz_game_image_uri"
              v-model="pageGameStore.homePageList.quiz_game_image_uri"
              :rules="[rules.validURI]"
              @change="e => onchangTopic('quiz_game_image_uri', e)"
            ></v-text-field>
            <v-btn dark @click="setResource('image')"
              ><v-icon>image</v-icon>Select Image</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import rules from '../../../utils/ruleValidate';
import ResourceSelect from '../../../components/ResourceSelect.vue';

export default {
  name: 'quizgameHomeTopic',
  components: { ResourceSelect },
  data() {
    return {
      rules,
      resource: {
        image: {
          isEdit: false,
          selectUri: '',
        },
        // sound: {
        //   isEdit: false,
        //   selectUri: '',
        // },
      },
      resourceType: '',
      searchQuizGame: null,
      validate: true,
    };
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
      quizGameList: state => state.quiz_game.quizGameList,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  methods: {
    ...mapActions('homepage', ['updatePageTopicData', 'setStateByKey']),
    ...mapActions('quiz_game', ['fetchQuizGameList']),
    onchangTopic(key, value) {
      if (this.$refs.form_quiz.validate())
        this.setStateByKey({
          statekey: 'updatePageTopic',
          key: key,
          value: value,
        });
    },
    saveTopic() {
      const updateData = { ...this.pageGameStore.updatePageTopic };
      this.updatePageTopicData(updateData);
      this.isTitleTopic = false;
    },
    setResource(resource) {
      //resource = image||sound||video
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          this.pageGameStore.homePageList.quiz_game_image_uri = uri;
          this.onchangTopic('quiz_game_image_uri', uri);
          break;
        // case 'sound':
        //   break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      this.resource[this.resourceType].isEdit = false;
      this.resource[this.resourceType].selectUri = '';
      this.resourceType = '';
    },
  },
  // created() {
  // },
};
</script>
