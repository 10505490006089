<template>
  <div>
    <v-content>
      <v-checkbox
        label="collect item to end"
        :input-value="item.is_collect_item_to_end"
        @change="e => this.$emit('onchangeCheckBoxItem', e)"
      ></v-checkbox>
      <v-btn
        block
        large
        class="white--text"
        color="blue darken-3"
        @click="isOpen = true"
        ><v-icon>space_dashboard</v-icon>Item Manager</v-btn
      >
      <ModalItemManager
        v-if="isOpen"
        :isOpen="isOpen"
        :dataGame="item.item_set"
        :title="title"
        @save="save"
        @close="close"
      />
      <!-- <v-flex> </v-flex> -->
    </v-content>
  </div>
</template>

<script>
import ModalItemManager from '../../../components/ModalManager.vue';
export default {
  name: 'Item',
  components: { ModalItemManager },
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    save(data) {
      this.isOpen = false;
      this.$emit('save', data);
    },
    close(data) {
      this.isOpen = false;
      this.$emit('close', data);
    },
  },
};
</script>
