<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title
            >Move Data from Development to Production</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn dark icon v-on="on">
                <v-icon>more_horiz</v-icon>
              </v-btn>
            </template>

            <v-flex v-if="!isProcessingState">
              <v-list>
                <v-btn color="error" light @click="openMoveAll()">
                  <v-icon>autorenew</v-icon> &nbsp;&nbsp;Move all data (from the
                  list in backend)
                </v-btn>
              </v-list>
            </v-flex>
          </v-menu>
        </v-toolbar>
        <v-card-text style="text-align: center">
          <section>
            <div v-if="!isProcessingState">
              <h3>Server Status</h3>
              <v-switch
                v-if="serverStatusStore.status"
                :label="serverStatusLabel"
                v-model="serverStatus"
                @click="closeServer()"
              ></v-switch>
              <v-switch
                v-if="!serverStatusStore.status"
                :label="serverStatusLabel"
                v-model="serverStatus"
                error-messages=" "
                @click="openServer()"
              ></v-switch>
            </div>
          </section>
          Data migration will take time for a while, depending on the size of
          data.
          <v-flex v-if="!isProcessingState">
            <v-btn
              color="warning"
              light
              @click="openMoveAllExceptLearningPath()"
            >
              <v-icon>autorenew</v-icon> &nbsp;&nbsp;Move all except Learning
              Path
            </v-btn>
            <v-btn color="primary" light @click="openUnitSelection()">
              <v-icon>autorenew</v-icon> &nbsp;&nbsp;Move only learning path
            </v-btn>
          </v-flex>
          <v-progress-linear v-else :indeterminate="true"> </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>

    <Modal
      v-if="isMoveAll"
      :isOpen="isMoveAll"
      :save="moveAll"
      :close="closeMoveAll"
      :isLastStep="credentialValid"
      title="Move All Data : Please verify your authority"
      type="confirm"
    >
      <v-card>
        <v-card-text>
          <v-form ref="credential" v-model="credentialValid">
            <v-text-field
              prepend-icon="person"
              :rules="requiredRules"
              label="Username"
              type="text"
              v-model="username"
              autofocus
            ></v-text-field>
            <v-text-field
              prepend-icon="lock"
              :rules="requiredRules"
              name="password"
              label="Password"
              type="password"
              v-model="password"
              v-on:keyup.enter.native="moveAllData({ username, password })"
            ></v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </Modal>

    <Modal
      v-if="isMoveAllExceptLearningPath"
      :isOpen="isMoveAllExceptLearningPath"
      :save="moveAllExceptLearningPath"
      :close="closeMoveAllExceptLearningPath"
      :isLastStep="credentialValid"
      title="Move All Except Learning Path : Please verify your authority"
      type="confirm"
    >
      <v-card>
        <v-card-text>
          <v-form ref="credential" v-model="credentialValid">
            <v-text-field
              prepend-icon="person"
              required
              :rules="requiredRules"
              label="Username"
              type="text"
              v-model="username"
              autofocus
            ></v-text-field>
            <v-text-field
              prepend-icon="lock"
              required
              :rules="requiredRules"
              name="password"
              label="Password"
              type="password"
              v-model="password"
              v-on:keyup.enter.native="
                moveAllDataExceptLearningPath({ username, password })
              "
            ></v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </Modal>

    <Modal
      v-if="isMoveOnlyLearningPath"
      :isOpen="isMoveOnlyLearningPath"
      :save="moveOnlyLearningPath"
      :close="closeMoveOnlyLearningPath"
      :isLastStep="credentialValid"
      title="Move Only Learning Path : Please verify your authority"
      type="confirm"
    >
      <v-card>
        <v-card-text>
          <v-form ref="credential" v-model="credentialValid">
            <v-text-field
              required
              :rules="requiredRules"
              prepend-icon="person"
              label="Username"
              type="text"
              v-model="username"
              autofocus
            ></v-text-field>
            <v-text-field
              required
              :rules="requiredRules"
              prepend-icon="lock"
              name="password"
              label="Password"
              type="password"
              v-model="password"
            ></v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </Modal>

    <Modal
      v-if="isSelectUnit"
      :isOpen="isSelectUnit"
      :save="submitSelectedUnit"
      :close="closeSelectUnitAndClearSelectedUnit"
      width="80%"
      title="Unit Selection"
      type="confirm"
    >
      <v-card>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="unitListSotre"
            :pagination.sync="pagination"
            select-all
            item-key="_id"
            class="elevation-1"
          >
            <template v-slot:headers="props">
              <tr>
                <th>
                  <v-checkbox
                    :input-value="props.all"
                    :indeterminate="props.indeterminate"
                    primary
                    hide-details
                    @click.stop="toggleAll"
                  ></v-checkbox>
                </th>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="[
                    'column sortable',
                    pagination.descending ? 'desc' : 'asc',
                    header.value === pagination.sortBy ? 'active' : '',
                  ]"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:items="props">
              <tr
                :active="props.selected"
                @click="props.selected = !props.selected"
              >
                <td>
                  <v-checkbox
                    :input-value="props.selected"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>{{ props.item.index }}</td>
                <td>{{ props.item._id }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.isPublish }}</td>
                <td>{{ props.item.isGuestPlayable }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </Modal>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../components/Container';
import Modal from '../components/Modal';
import Swal from 'sweetalert2';
export default {
  name: 'MoveData',
  components: {
    Container,
    Modal,
  },
  data() {
    return {
      serverStatus: false,
      requiredRules: [v => !!v || 'Field is required'],
      credentialValid: false,
      selectedUnitIds: [],
      isMoveAll: false,
      isMoveAllExceptLearningPath: false,
      isMoveOnlyLearningPath: false,
      isSelectUnit: false,
      username: '',
      password: '',

      pagination: {
        sortBy: 'name',
        rowsPerPage: 25,
      },
      selected: [],
      headers: [
        {
          text: 'Index',
          align: 'left',
          value: 'index',
        },
        {
          text: 'ID',
          align: 'left',
          value: '_id',
        },
        {
          text: 'Unit',
          align: 'left',
          value: 'name',
        },
        {
          text: 'Is Publish',
          align: 'left',
          value: 'isPublish',
        },
        {
          text: 'Is Guest Playable',
          align: 'left',
          value: 'isGuestPlayable',
        },
      ],
    };
  },
  watch: {
    // 0 toggle server status
    serverStatusStore(value) {
      if (value.status) {
        this.serverStatus = true;
      } else {
        this.serverStatus = false;
      }
    },
    // 1 backup production
    backupProductionStatus(done) {
      if (done) {
        this.backupDevelopmentDatabase(this.userConfigStore);
      }
    },
    // 2 backup development
    backupDevelopmentStatus(done) {
      if (done) {
        this.backupProductionDatabaseWithTimestamp(this.userConfigStore);
      }
    },
    // 3 backup production with timestamp
    backupProductionWithTimestampStatus(done) {
      if (done) {
        this.backupDevelopmentDatabaseWithTimestamp(this.userConfigStore);
      }
    },
    // 4 backup development with timestamp
    backupDevelopmentWithTimestampStatus(done) {
      if (done) {
        this.restoreDevelopmentToProductionDatabase(this.userConfigStore);
      }
    },
    // 5 restore development to production
    restoreDevelopmentToProductionStatus(done) {
      if (done) {
        this.resetMigrationStatus();
        this.removeUserConfig();
        this.resetMigrationStatus();
        Swal.fire(
          'Migrate database',
          'Done restore development to production.',
          'info',
        );
      }
    },
  },
  methods: {
    ...mapActions('appConfig', ['toggleAlert']),
    ...mapActions('movedata', [
      'moveAllData',
      'moveAllDataExceptLearningPath',
      'moveOnlyDataLearningPath',
      'getUnitList',
      'backupDevelopmentDatabaseWithTimestamp',
      'backupDevelopmentDatabase',
      'backupProductionDatabaseWithTimestamp',
      'backupProductionDatabase',
      'restoreDevelopmentToProductionDatabase',
      'setUserConfig',
      'removeUserConfig',
      'resetMigrationStatus',
      'getServerStatus',
      'updateServerStatus',
    ]),
    fetchServerStatus() {
      this.getServerStatus();
    },
    closeServer() {
      // TODO: swal confirm toggle
      this.updateServerStatus({
        _id: this.serverStatusStore._id,
        status: false,
      });
    },
    openServer() {
      // TODO: swal confirm toggle
      this.updateServerStatus({
        _id: this.serverStatusStore._id,
        status: true,
      });
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.unitListSotre.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

    // Open Modal
    openMoveAll() {
      this.removeUserConfig();
      this.isMoveAll = true;
    },
    openMoveAllExceptLearningPath() {
      this.removeUserConfig();
      this.isMoveAllExceptLearningPath = true;
    },
    openUnitSelection() {
      this.clearSelectedUnit();
      this.getUnitList();
      this.isSelectUnit = true;
    },
    openMoveOnlyLearningPath() {
      this.isMoveOnlyLearningPath = true;
    },

    // Close Modal
    closeMoveAll() {
      this.resetCredentialForm();
      this.isMoveAll = false;
    },
    closeMoveAllExceptLearningPath() {
      this.resetCredentialForm();
      this.isMoveAllExceptLearningPath = false;
    },
    closeSelectUnitAndClearSelectedUnit() {
      this.closeSelectUnit();
      this.clearSelectedUnit();
    },
    closeSelectUnit() {
      this.isSelectUnit = false;
    },
    clearSelectedUnit() {
      this.selected = [];
    },
    closeMoveOnlyLearningPath() {
      this.resetCredentialForm();
      this.clearFilterUnitIds();
      this.isMoveOnlyLearningPath = false;
    },
    resetCredentialForm() {
      this.$refs.credential.reset();
    },

    // API Caller
    moveAll() {
      const moveAllConfig = {
        username: this.username,
        password: this.password,
        isFilterLearningPathOut: 'NO',
      };
      this.setUserConfig(moveAllConfig);
      this.backupProductionDatabase(this.userConfigStore);
      this.resetCredentialForm();
      this.isMoveAll = false;
    },
    async moveAllExceptLearningPath() {
      const moveAllExceptLearningPathConfig = {
        username: this.username,
        password: this.password,
        isFilterLearningPathOut: 'YES',
      };
      this.setUserConfig(moveAllExceptLearningPathConfig);
      this.backupProductionDatabase(this.userConfigStore);
      this.resetCredentialForm();
      this.isMoveAllExceptLearningPath = false;
    },
    async moveOnlyLearningPath() {
      await this.filterUnitIds();
      const request = {
        username: this.username,
        password: this.password,
        selectedUnitIds: this.selectedUnitIds,
      };
      await this.moveOnlyDataLearningPath(request);
      await this.clearFilterUnitIds();
      await this.resetCredentialForm();
      this.isMoveOnlyLearningPath = false;
    },
    filterUnitIds() {
      return this.selected.forEach(({ _id }) => {
        this.selectedUnitIds.push(_id);
      });
    },
    clearFilterUnitIds() {
      return (this.selectedUnitIds = []);
    },
    submitSelectedUnit() {
      if (this.selected.length > 0) {
        Swal.fire({
          titleText: 'Confirm Migrate Learning Path',
          text: 'Total Selected Unit: ' + this.selected.length,
          confirmButtonColor: 'red',
          confirmButtonText: 'Migrate',
          showCancelButton: true,
          type: 'warning',
        }).then(result => {
          if (result.value) {
            this.closeSelectUnit();
            this.openMoveOnlyLearningPath();
          }
        });
      } else {
        Swal.fire('Please Select Unit!', '', 'info');
      }
    },
  },

  computed: {
    ...mapState({
      serverStatusStore: state => state.movedata.SERVER_STATUS,
      dataStore: state => state.movedata,
      unitListSotre: state => state.movedata.UNITS,
      isProcessingState: state => state.movedata.isProcessing,
      userConfigStore: state => state.movedata.userConfig,
      backupDevelopmentStatus: state => state.movedata.doneBackupDevelopment,
      backupProductionStatus: state => state.movedata.doneBackupProduction,
      backupProductionWithTimestampStatus: state =>
        state.movedata.doneBackupProductionWithTimestamp,
      backupDevelopmentWithTimestampStatus: state =>
        state.movedata.doneBackupDevelopmentWithTimestamp,
      restoreDevelopmentToProductionStatus: state =>
        state.movedata.doneRestoreDevelopmentToProduction,
    }),
    serverStatusLabel() {
      if (this.serverStatusStore.status) {
        return `ON`;
      } else {
        return `OFF`;
      }
    },
  },
  created() {
    this.fetchServerStatus();
  },
};
</script>
<style scoped>
section {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
