<template>
  <div>
    <v-text-field label="Title" v-model="game.title"></v-text-field>
    <v-flex>
      <v-btn
        v-if="game.introduction_voice_uri"
        class="white--text"
        color="subtitle_sound"
        @click="openSubtitleSound"
        >Subtitle Sound
      </v-btn>
      <v-text-field
        label="Introduction Voice (URL or Select file)"
        v-model="game.introduction_voice_uri"
      ></v-text-field>
      <v-btn @click="onclickIntroVoice"
        ><v-icon>audiotrack</v-icon>Select Introduction Voice</v-btn
      >
      <img :src="game.background_uri" class="content_img" />
      <v-text-field
        label="Background Image URI (URL or Select file)"
        v-model="game.background_uri"
      ></v-text-field>
      <v-btn @click="onclickBackgroundImage"
        ><v-icon>image</v-icon>Select Background Image</v-btn
      >
      <v-text-field label="Line Color" v-model="game.line_color"></v-text-field>
      <v-text-field
        label="Background Music URI (URL or Select file)"
        v-model="game.bgm_uri"
      ></v-text-field>
      <v-btn @click="onclickBackgroundMusic"
        ><v-icon>audiotrack</v-icon>Select Background Music</v-btn
      >
    </v-flex>
    <v-flex>
      <v-select
        type="number"
        :items="mazeSize"
        label="Maze Size"
        v-model="game.maze_size"
      ></v-select>
    </v-flex>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </div>
</template>

<script>
import ResourceSelect from '../../../components/ResourceSelect.vue';

export default {
  data() {
    return {
      mazeSize: [4, 5, 6, 7, 8],
      isSelectSound: false,
      isSelectImage: false,
      isSelectMainBackground: false,
      isSelectBgm: false,
      isSelectIntroVoice: false,
      selectedSoundUri: '',
      selectedImageUri: '',
    };
  },
  components: {
    ResourceSelect,
  },
  props: {
    game: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.game.introduction_voice_uri },
      });
      window.open(routeData.href, '_blank');
    },
    onclickBackgroundImage() {
      this.isSelectImage = true;
      this.isSelectMainBackground = true;
    },
    saveSelectImage() {
      const uri = this.selectedImageUri;
      switch (true) {
        case this.isSelectMainBackground:
          this.game.background_uri = uri;
          this.isSelectMainBackground = false;
          break;
        default:
          break;
      }
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    onSelectImageResource(uri) {
      this.selectedImageUri = uri;
    },
    closeSelectImage() {
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    onclickBackgroundMusic() {
      this.isSelectSound = true;
      this.isSelectBgm = true;
    },
    onclickIntroVoice() {
      this.isSelectSound = true;
      this.isSelectIntroVoice = true;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectBgm:
          this.game.bgm_uri = uri;
          this.isSelectBgm = false;
          break;
        case this.isSelectIntroVoice:
          this.game.introduction_voice_uri = uri;
          this.isSelectIntroVoice = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
  },
};
</script>
<style scoped>
.content_img {
  border-radius: 15px;
  padding: 10px;
  /* margin: 0 auto; */
  display: flex;
}
</style>
