<template>
  <div>
    <v-btn block color="yellow darken-4" @click="openModalItem">
      Mole Manager
    </v-btn>
    <Modal
      v-if="isOpenItemModal"
      :isOpen="isOpenItemModal"
      :save="onSaveItem"
      :close="closeModalItem"
      :isLastStep="true"
      :width="width"
      title="Mole Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          disabled
          @click="increaseStep(name_list.mole_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.mole_manager_list">
          <v-stepper-header>
            <template v-for="n in step.mole_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.mole_manager_list > n"
                :step="n"
                editable
              >
                {{ n === 1 ? `Gem Mole` : `Mole ${n - 1}` }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.mole_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.mole_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  disabled
                  @click="
                    reduceStep(
                      element.mole_manager_list,
                      name_list.mole_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <div>
                  <v-checkbox
                    v-model="mole[step - 1].is_gem"
                    label="is gem"
                    type="checkbox"
                  ></v-checkbox>
                  <img
                    v-if="mole[step - 1].image_uri"
                    :src="mole[step - 1].image_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Image URI (URL or Select file)"
                    v-model="mole[step - 1].image_uri"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      isImageList = true;
                      isSelectImage = true;
                    "
                  >
                    <v-icon>image</v-icon>Select Image
                  </v-btn>
                </div>
                <div>
                  <v-text-field
                    label="Appear Sound (tts message)"
                    v-model="mole[step - 1].appear_tts_message"
                  ></v-text-field>
                  <v-select
                    :items="constants.VOICE_NAME"
                    label="Appear Voice Name"
                    :value="mole[step - 1].appear_voice_name"
                    v-model="mole[step - 1].appear_voice_name"
                  ></v-select>
                </div>
                <div>
                  <v-text-field
                    label="Appear Sound URI (URL or Select file)"
                    v-model="mole[step - 1].appear_sound_uri"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      isSelectSound = true;
                      isUpdate = true;
                    "
                  >
                    <v-icon>audiotrack</v-icon>Select Appear Sound
                  </v-btn>
                </div>
                <div>
                  <v-text-field
                    label="Tap Sound (tts message)"
                    v-model="mole[step - 1].tts_message"
                  ></v-text-field>
                  <v-select
                    :items="constants.VOICE_NAME"
                    label="Tap Voice Name"
                    :value="mole[step - 1].voice_name"
                    v-model="mole[step - 1].voice_name"
                  ></v-select>
                </div>
                <div>
                  <v-text-field
                    label="Tap Sound URI (URL or Select file)"
                    v-model="mole[step - 1].tap_sound_uri"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      isSelectSound = true;
                      isTapSoundUri = true;
                    "
                  >
                    <v-icon>audiotrack</v-icon>Select Tap Sound
                  </v-btn>
                </div>
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.mole_manager_list)"
              >
                Next
              </v-btn>
              <v-btn flat @click="backStep(step, name_list.mole_manager_list)">
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>

    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeEditImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeEditSound"
      :save="saveSelectSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import ResourceSelect from '../../../../components/ResourceSelect.vue';
import constants from '../../../../constants';

export default {
  name: 'UpdateMole',
  components: {
    Modal,
    ResourceSelect,
  },
  computed: {},
  props: {
    mole: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      constants,
      isTapSoundUri: false,
      isUpdate: false,
      isSelectImage: false,
      isSelectSound: false,
      selectImageUri: '',
      selectSoundUri: '',
      maxMole: 4,
      name_list: {
        mole_manager_list: 'mole_manager_list',
      },
      element: {
        mole_manager_list: 1,
      },
      step: {
        mole_manager_list: 1,
      },
      isOpenItemModal: false,
      width: '60%',
    };
  },
  methods: {
    saveSelectImage() {
      const uri = this.selectImageUri;
      if (this.isImageList) {
        const index = this.element.mole_manager_list - 1;
        this.mole[index].image_uri = uri;
      }
      this.selectImageUri = '';
      this.isImageList = false;
      this.isSelectImage = false;
    },
    closeEditImage() {
      this.isSelectImage = false;
      this.selectImageUri = '';
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectSoundUri;
      const index = this.element.mole_manager_list - 1;
      if (this.isUpdate) {
        this.mole[index].appear_sound_uri = uri;
      } else if (this.isTapSoundUri) {
        this.mole[index].tap_sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isUpdate = false;
      this.isTapSoundUri = false;
      this.isSelectSound = false;
    },
    closeEditSound() {
      this.isSelectSound = false;
      this.selectSoundUri = '';
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    increaseStep(list) {
      if (this.step[list] < this.maxMole) {
        if (list === this.name_list.mole_manager_list) {
          this.initialDefaultItem();
        }
        this.step[list] += 1;
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.mole_manager_list) {
          this.step[list] -= 1;
          this.mole.splice(index, 1);
        }
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },
    openModalItem() {
      this.isOpenItemModal = true;
      this.calculateItemStep();
    },
    calculateItemStep() {
      if (this.mole.length === 0) {
        this.setDefaultItemByMaximumValue();
        this.step.mole_manager_list = this.maxMole;
      } else {
        this.step.mole_manager_list = this.mole.length;
      }
    },
    setDefaultItemByMaximumValue() {
      for (let index = 0; index < this.maxMole; index++) {
        const formatObject = {
          image_uri: '',
          appear_sound_uri: '',
          tap_sound_uri: '',
        };
        this.mole.push(formatObject);
      }
    },
    initialDefaultItem() {
      const formatObject = {
        image_uri: '',
        appear_sound_uri: '',
        tap_sound_uri: '',
      };
      this.mole.push(formatObject);
    },
    onSaveItem() {
      this.isOpenItemModal = false;
    },
    closeModalItem() {
      this.isOpenItemModal = false;
    },
  },
};
</script>

<style></style>
