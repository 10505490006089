import http from '../../utils/httpClient';
const state = {
  statistics: {},
  dailyStats: {},
  weeklyStats: {},
  monthlyStats: {},
  yearlyStats: {},
  totalStats: {},
};
const getters = {};
const actions = {
  fetchDailyStats({ commit, dispatch }) {
    http
      .GET(`/api/stats/daily`, { isAuthorization: true })
      .then(response => {
        commit('dailyStore', response.data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch dailyStats at this time, please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(error);
      });
  },
  fetchWeeklyStats({ commit, dispatch }) {
    http
      .GET(`/api/stats/weekly`, { isAuthorization: true })
      .then(response => {
        commit('weeklyStore', response.data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch weeklyStats at this time, please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(error);
      });
  },
  fetchMonthlyStats({ commit, dispatch }) {
    http
      .GET(`/api/stats/monthly`, { isAuthorization: true })
      .then(response => {
        commit('monthlyStore', response.data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch monthlyStats at this time, please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(error);
      });
  },
  fetchYearlyStats({ commit, dispatch }) {
    http
      .GET(`/api/stats/yearly`, { isAuthorization: true })
      .then(response => {
        commit('yearlyStore', response.data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch yearlyStats at this time, please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(error);
      });
  },
  fetchTotalStats({ commit, dispatch }) {
    http
      .GET(`/api/stats/total`, { isAuthorization: true })
      .then(response => {
        commit('totalStore', response.data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch totalStats at this time, please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(error);
      });
  },
  fetchNumberOfUsers({ commit, dispatch }) {
    http
      .GET(`/api/stats/numberOfUsers`, { isAuthorization: true })
      .then(response => {
        commit('statisticStore', response.data);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch totalStats at this time, please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(error);
      });
  },
};
const mutations = {
  statisticStore(state, payload) {
    state.statistics = payload;
  },
  dailyStore(state, payload) {
    state.dailyStats = payload;
  },
  weeklyStore(state, payload) {
    state.weeklyStats = payload;
  },
  monthlyStore(state, payload) {
    state.monthlyStats = payload;
  },
  yearlyStore(state, payload) {
    state.yearlyStats = payload;
  },
  totalStore(state, payload) {
    state.totalStats = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
