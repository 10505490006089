import axios from 'axios';
import config from '../config';
import store from '../store';

const api_server = () => {
  const cookiesServer = window.$cookies.get('server');
  if (cookiesServer === config.cookiesHashUrlServer.production)
    return 'prod_address';
  else return 'server_address';
};
const server_address = api_server();

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch('userInfo/revokeAccess');
    }
    return Promise.reject(error);
  },
);

export default {
  GET: (endpoint, { option = {}, isAuthorization = false }) => {
    let options = { ...option };
    if (isAuthorization) {
      const token = window.$cookies.get('token');
      if (token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            authorization: token,
          },
        };
      } else {
        store.dispatch('userInfo/revokeAccess');
      }
    }
    return axios.get(`${config[server_address]}${endpoint}`, options);
  },
  POST: (endpoint, data, { option = {}, isAuthorization = false }) => {
    let options = { ...option };
    if (isAuthorization) {
      const token = window.$cookies.get('token');
      if (token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            authorization: token,
          },
        };
      } else {
        store.dispatch('userInfo/revokeAccess');
      }
    }
    return axios.post(`${config[server_address]}${endpoint}`, data, options);
  },
  PUT: (endpoint, data, { option = {}, isAuthorization = false }) => {
    let options = { ...option };
    if (isAuthorization) {
      const token = window.$cookies.get('token');
      if (token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            authorization: token,
          },
        };
      } else {
        store.dispatch('userInfo/revokeAccess');
      }
    }
    return axios.put(`${config[server_address]}${endpoint}`, data, options);
  },
  PATCH: (endpoint, data, { option = {}, isAuthorization = false }) => {
    let options = { ...option };
    if (isAuthorization) {
      const token = window.$cookies.get('token');
      if (token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            authorization: token,
          },
        };
      } else {
        store.dispatch('userInfo/revokeAccess');
      }
    }
    return axios.patch(`${config[server_address]}${endpoint}`, data, options);
  },
  DELETE: (endpoint, { option = {}, isAuthorization = false }) => {
    let options = { ...option };
    if (isAuthorization) {
      const token = window.$cookies.get('token');
      if (token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            authorization: token,
          },
        };
      } else {
        store.dispatch('userInfo/revokeAccess');
      }
    }
    return axios.delete(`${config[server_address]}${endpoint}`, options);
  },
  PUT_PROD: (endpoint, data, { option = {}, isAuthorization = false }) => {
    let options = { ...option };
    if (isAuthorization) {
      const token = window.$cookies.get('token');
      if (token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            authorization: token,
          },
        };
      } else {
        store.dispatch('userInfo/revokeAccess');
      }
    }
    return axios.put(`${config.prod_address}${endpoint}`, data, options);
  },
};
