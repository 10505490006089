var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-flex',{staticStyle:{"text-align":"right"},attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-btn',{attrs:{"color":"success","small":"","fab":""},on:{"click":function($event){return _vm.increaseStep(_vm.name_list.wrong_question_manager_list)}}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-stepper',{model:{value:(_vm.element.wrong_question_manager_list),callback:function ($$v) {_vm.$set(_vm.element, "wrong_question_manager_list", $$v)},expression:"element.wrong_question_manager_list"}},[_c('v-stepper-header',[_vm._l((_vm.step.wrong_question_manager_list),function(n){return [_c('v-stepper-step',{key:(n + "-step"),attrs:{"complete":_vm.element.wrong_question_manager_list > n,"step":n,"editable":""}}),(n !== _vm.step.wrong_question_manager_list)?_c('v-divider',{key:n}):_vm._e()]})],2),_c('v-stepper-items',[_vm._l((_vm.step.wrong_question_manager_list),function(step){return _c('v-stepper-content',{key:(step + "-content"),attrs:{"step":step}},[_c('v-subheader',[_c('v-btn',{attrs:{"color":"error","small":"","absolute":"","fab":"","right":""},on:{"click":function($event){return _vm.reduceStep(step, _vm.name_list.wrong_question_manager_list)}}},[_c('v-icon',[_vm._v("delete")])],1)],1),(
            _vm.parenObject &&
            _vm.parenObject.wrong_image_uri &&
            _vm.parenObject.wrong_image_uri[step - 1]
          )?_c('div',{staticStyle:{"text-align":"-webkit-center"}},[(_vm.parenObject.wrong_image_uri[step - 1])?_c('v-img',{staticClass:"content_img",attrs:{"src":_vm.parenObject.wrong_image_uri[step - 1]}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Wrong Question URI (URL or Select file)"},model:{value:(_vm.parenObject.wrong_image_uri[step - 1]),callback:function ($$v) {_vm.$set(_vm.parenObject.wrong_image_uri, step - 1, $$v)},expression:"parenObject.wrong_image_uri[step - 1]"}})],1):_vm._e(),_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){_vm.isSelectImage = true}}},[_c('v-icon',[_vm._v("audiotrack")]),_vm._v("Select Wrong Question Image ")],1)],1)}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextStep(
            _vm.element.wrong_question_manager_list,
            _vm.name_list.wrong_question_manager_list
          )}}},[_vm._v(" next ")])],2)],1),(_vm.isSelectImage)?_c('ResourceSelect',{attrs:{"resourceType":"image","isShow":_vm.isSelectImage,"close":_vm.closeSelectImage,"save":_vm.saveSelectImage,"value":_vm.selectImageUri,"onSelect":_vm.onSelectImage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }