<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light @click="onClickBack()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            <div v-if="!isTitleTopic">
              {{
                (pageGameStore.homePageList &&
                  pageGameStore.homePageList.title) ||
                'Loading...'
              }}
              <v-btn icon light @click="isTitleTopic = true">
                <v-icon color="white">edit</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-layout>
                <v-flex>
                  <v-text-field
                    placeholder="title"
                    :value="pageGameStore.homePageList.title"
                    @change="e => onchangTopic('title', e)"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <v-btn icon light color="success" @click="saveTopic()">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="closeTitle()">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" @click="isOpenCreateGroup = true">
            <v-icon>add</v-icon>
          </v-btn>
          <!-- <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field> -->
        </v-toolbar>
        <v-card-text class="pa-4">
          <QuizGameHomeTopic></QuizGameHomeTopic>
          <table class="table table-striped homegroup">
            <thead class="thead-dark">
              <tr>
                <th>Index</th>
                <th>Lock</th>
                <th>Title</th>
                <th>Image</th>
                <th>Updated_at</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <draggable
              v-model="pageGameStore.homePageList.group_datas"
              tag="tbody"
              @end="editIndexGroupList"
            >
              <tr
                v-for="(element, index) in pageGameStore.homePageList
                  .group_datas"
                :key="element.name"
              >
                <td>{{ index }}</td>
                <td>
                  <v-checkbox
                    v-model="element.isLock"
                    @change="e => onchangeGroupUpdate(element._id, 'isLock', e)"
                    :disabled="roleUser !== 100"
                  ></v-checkbox>
                </td>
                <td>{{ element.title }}</td>
                <td>
                  <img
                    v-if="element.img_uri"
                    class="image_thumbnail1"
                    lazy="element.img_uri"
                    :src="element.img_uri"
                  />
                  <img v-else class="content_img" />
                </td>
                <td>{{ element.updated_at }}</td>
                <td class="tools">
                  <v-layout justify-end>
                    <v-btn
                      icon
                      color="black"
                      v-on:click="onClickEdit(element._id)"
                    >
                      <v-icon color="white">edit</v-icon>
                    </v-btn>
                  </v-layout>
                </td>
                <td class="tools">
                  <v-layout justify-end>
                    <v-btn
                      color="error"
                      @click="deleteGame(element._id)"
                      small
                      fab
                      right
                      :disabled="roleUser === 100 ? false : element.isLock"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-layout>
                </td>
              </tr>
            </draggable>
          </table>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreateGroup
      :isOpen="isOpenCreateGroup"
      @close="isOpenCreateGroup = false"
    ></ModalCreateGroup>
  </Container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Container from '../../components/Container.vue';
import { ModalCreateGroup, QuizGameHomeTopic } from './components/';
import swal from 'sweetalert2';
import draggable from 'vuedraggable';

export default {
  components: { Container, ModalCreateGroup, draggable, QuizGameHomeTopic },
  data() {
    return {
      headers: [
        { text: 'Index', value: 'index', sortable: false },
        { text: 'Lock', value: 'isLock', sortable: false },
        { text: 'Title', value: 'name', sortable: false },
        { text: 'Img', value: 'img', sortable: false },
        { text: 'Updated_at', value: 'updated_at', sortable: false },
      ],
      isOpenCreateGroup: false,
      pagination: {},
      isTitleTopic: false,
      // searchQuizGame: null,
    };
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
      roleUser: state => state.userInfo.userData.role_id,
      isLock: state => state.homepage.adminIsLock,
    }),
  },
  methods: {
    ...mapActions('homepage', [
      'selectedHomeGroupDataById',
      'clearAllState',
      'deletePageGroupData',
      'updatePageTopicData',
      'setStateByKey',
      'clearStateByKey',
      'updatePageGroupData',
    ]),
    ...mapActions('quiz_game', ['fetchQuizGameList']),
    onchangeGroupUpdate(id, key, value) {
      const updateData = { id, [key]: value };
      this.updatePageGroupData(updateData);
    },
    onClickEdit(id) {
      this.$router.push(`/home_page/detail?id=${id}`);
    },
    deleteGame(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        })
        .then(result => {
          if (result.value) {
            this.deletePageGroupData(id);
          }
        });
    },
    onClickBack() {
      this.$router.push('/home_page');
    },
    onchangTopic(key, value) {
      this.setStateByKey({
        statekey: 'updatePageTopic',
        key: key,
        value: value,
      });
    },
    saveTopic() {
      const updateData = { ...this.pageGameStore.updatePageTopic };
      this.updatePageTopicData(updateData);
      this.isTitleTopic = false;
    },
    closeTitle() {
      delete this.pageGameStore.updatePageTopic.title;
      this.isTitleTopic = false;
    },
    editIndexGroupList(event) {
      if (event.oldDraggableIndex !== event.newDraggableIndex) {
        swal
          .fire({
            titleText: 'Are you sure change index group.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#7a7a7a',
          })
          .then(result => {
            if (result.value) {
              const group_datas =
                this.pageGameStore.homePageList.group_datas.map(
                  home => home._id,
                );
              this.setStateByKey({
                statekey: 'updatePageTopic',
                key: 'group_datas',
                value: group_datas,
              });
              this.updatePageTopicData();
            } else this.fetchSelectHomeGroupDataById();
          });
      }
    },
    fetchSelectHomeGroupDataById() {
      if (this.$route.query.id) {
        const id = this.$route.query.id;
        this.clearAllState();
        this.fetchQuizGameList();
        this.selectedHomeGroupDataById(id);
      }
    },
  },
  created() {
    this.fetchSelectHomeGroupDataById();
  },
};
</script>

<style scoped>
.row-pointer:hover {
  cursor: pointer;
}
.tools {
  width: 10px;
}
.imageGroup {
  height: 100px;
  width: 100px;
  border-radius: 15px;
  padding: 10px;
}
.active {
  background-color: #212121;
}
.image_thumbnail1 {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}

.homegroup {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.homegroup td,
.homegroup th {
  border-bottom: 1px solid #7a7a7a;
  padding: 8px;
}

.homegroup tr:hover {
  background-color: #666666;
  cursor: pointer;
}

.homegroup th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}
</style>
