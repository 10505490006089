<template>
  <div>
    <v-flex>
      <v-text-field
        required
        :rules="nameRules"
        :error-messages="errorMessages"
        v-model="node.node_name"
        label="Node Name"
      ></v-text-field>
    </v-flex>
    <v-flex v-if="skipLegacyProcessStatus === true">
      <v-checkbox
        label="Hide Correct Star"
        v-model="node.hide_correct_star"
      ></v-checkbox>
    </v-flex>
    <v-flex>
      <v-select
        label="Start Animation"
        :items="constant.START_ANIMATION"
        v-model="node.start_animation"
      ></v-select>
    </v-flex>
    <v-flex>
      <v-select
        label="End Animation"
        :items="constant.END_ANIMATION"
        v-model="node.end_animation"
      ></v-select>
    </v-flex>
    <v-flex>
      <v-text-field
        type="number"
        label="Delay"
        v-model="node.delay"
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-combobox label="Word" v-model="node.word" chips multiple></v-combobox>
    </v-flex>
    <v-flex>
      <v-text-field
        label="Audio URI (URL or Select file)"
        v-model="node.audio_uri"
      ></v-text-field>
      <v-btn
        @click="
          isSelectSound = true;
          isCreate = true;
        "
      >
        <v-icon>audiotrack</v-icon>Select Audio
      </v-btn>
    </v-flex>
    <v-flex>
      <v-autocomplete
        label="Goto Node"
        v-model="node.goto_node"
        :items="gotoNodeStore"
        item-text="display"
        item-value="node_name"
        chips
        multiple
        hide-details
        hide-no-data
        hide-selected
      ></v-autocomplete>
    </v-flex>

    <v-flex>
      <img
        v-if="node.background_uri"
        :src="node.background_uri"
        :alt="node.node"
        class="content_img"
      />
      <v-text-field
        label="Background URI (URL or Select file)"
        v-model="node.background_uri"
      ></v-text-field>
      <v-btn
        @click="
          isSelectImage = true;
          isCreate = true;
          isBackgroundUri = true;
        "
      >
        <v-icon>image</v-icon>Select Background
      </v-btn>
    </v-flex>
    <br />
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeEditImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeEditSound"
      :save="saveSelectSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ResourceSelect from '../../../../../components/ResourceSelect';
import constant from '../../../constant';
export default {
  name: 'CreateQuestionNode',
  components: {
    ResourceSelect,
  },
  computed: {
    ...mapState({
      gotoNodeStore: state => state.minichat.gotoNodeData,
      skipLegacyProcessStatus: state =>
        state.minichat.minichatData.skip_legacy_process,
    }),
  },
  props: {
    node: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      constant,
      nameRules: [v => !!v || 'Name is required'],
      errorMessages: '',
      selectImageUri: '',
      selectSoundUri: '',
      isBackgroundUri: false,
      isSelectSound: false,
      isSelectImage: false,
      width: '60%',
    };
  },
  methods: {
    saveSelectSound() {
      const uri = this.selectSoundUri;
      if (this.isCreate) {
        this.node.audio_uri = uri;
      }
      this.isCreate = !this.isCreate;
      this.isSelectSound = !this.isSelectSound;
    },
    closeEditSound() {
      this.isSelectSound = false;
      this.selectSoundUri = '';
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    saveSelectImage() {
      const uri = this.selectImageUri;
      if (this.isCreate && this.isBackgroundUri) {
        this.node.background_uri = uri;
      }
      this.selectImageUri = '';
      this.isCreate = !this.isCreate;
      this.isBackgroundUri = !this.isBackgroundUri;
      this.isSelectImage = !this.isSelectImage;
    },
    closeEditImage() {
      this.isSelectImage = false;
      this.selectImageUri = '';
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
  },
};
</script>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
