const state = {
  drawer: false,
  alert: { message: '', type: 'success', active: false },
};
const getters = {};
const actions = {
  toggleDrawer({ commit }) {
    commit('storeToggleDrawer');
  },
  toggleAlert({ commit }, alertInfo) {
    commit('storeToggleAlert', alertInfo);
  },
};
const mutations = {
  storeToggleDrawer(state) {
    state.drawer = !state.drawer;
  },
  storeToggleAlert(state, { message, type, duration = 5000 }) {
    state.alert = { message, type, active: true };
    setTimeout(() => {
      state.alert.active = false;
    }, duration);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
