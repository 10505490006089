import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';

const state = {
  news: [],
  createNews: {},
  updateNews: {},
  selectNews: {},
};
const getters = {};
const actions = {
  fetchNews({ commit, dispatch }) {
    http
      .GET(`/api/dashboard/news`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(news => ({
          ...news,
          expired_date: +new Date(news.expired_date),
          edit_expired_date: moment(news.expired_date).format('YYYY-MM-DD'),
          display_expired_date: moment(news.expired_date).format(
            'YYYY MMM DD  h:mm A',
          ),
          created_at: moment(news.created_at).format('YYYY MMM DD  h:mm A'),
          updated_at: moment(news.updated_at).format('YYYY MMM DD  h:mm A'),
        }));
        commit('storeNews', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch news at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },

  addNews({ dispatch }, newsData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating News',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/dashboard/news', newsData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'News Successfully Created',
          },
          {
            root: true,
          },
        );
        dispatch('fetchNews');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  editNews({ dispatch }, newsData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating News',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/dashboard/news', newsData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'News Successfully Updated',
          },
          {
            root: true,
          },
        );
        dispatch('fetchNews');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteNews({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete News',
        showCancelButton: true,
        type: 'warning',
      })
      .then(response => {
        if (response.value) {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'info',
              message: 'Deleting News',
            },
            {
              root: true,
            },
          );
          http
            .DELETE(`/api/dashboard/news?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'News Successfully Deleted',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchNews');
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: error.response.data,
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
  stateCreateNews({ commit }, { key, value }) {
    commit('storeCreateNewsData', {
      key,
      value,
    });
  },
  stateEditNews({ commit }, { key, value }) {
    commit('storeUpdateNewsData', {
      key,
      value,
    });
  },
  clearCreateState({ commit }) {
    commit('clearCreateState');
  },
  clearUpdateState({ commit }) {
    commit('clearUpdateState');
  },
};
const mutations = {
  storeCreateNewsData(state, { key, value }) {
    state.createNews = {
      ...state.createNews,
      [key]: value,
    };
  },
  storeUpdateNewsData(state, { key, value }) {
    state.updateNews = {
      ...state.updateNews,
      [key]: value,
    };
  },
  clearUpdateState(state) {
    state.updateNews = {};
  },
  clearCreateState(state) {
    state.createNews = {};
  },
  storeNews(state, value) {
    if (value) {
      state.news = value;
    } else {
      delete state.news;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
