import moment from 'moment';
import http from '../../utils/httpClient';
import swal from 'sweetalert2';

const state = {
  level: [],
  createLevel: {
    name: '',
    startIndex: 0,
    length: 1,
    endIndex: 1,
  },
  selectLevel: {},
  updateLevel: {},
  learnpathList: [],
};
const getters = {};
const actions = {
  fetchLevels({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('storeIsloadLevel');
    http
      .GET(`/api/learningpath/level?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(level => ({
          ...level,
          created_at: moment(level.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(level.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeLevel', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch levels at this time. Please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => {
        commit('storeIsloadLevel');
      });
  },
  fetchLP({ commit, dispatch }, filter) {
    let uri = `/api/learningpath?isWeb=true&`;

    if (filter) {
      Object.keys(filter).forEach(key => {
        uri += `&${key}=${filter[key]}`;
      });
    }
    http
      .GET(uri, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeLearningPath', response.data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  createLevel({ dispatch }, createLevel) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating level',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/learningpath/level', createLevel, {
        isAuthorization: true,
      })
      .then(data => {
        dispatch('fetchLevels', {});
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Generate Level complete',
          },
          {
            root: true,
          },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Error',
          },
          {
            root: true,
          },
        );
      });
  },
  updateLevel({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'info',
          message: 'Updating level',
        },
        {
          root: true,
        },
      );
      http
        .PUT(
          '/api/learningpath/level',
          { ...updateData },
          { isAuthorization: true },
        )
        .then(response => {
          dispatch('fetchLevels', {});
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'success',
              message: 'Update complete',
            },
            {
              root: true,
            },
          );
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'error',
              message: 'Update Error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  deleteLevel({ dispatch }, { id }) {
    swal
      .fire({
        titleText: 'Confirm Delete Level',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/learningpath/level?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchLevels', {});
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  editCreateLevel({ commit }, { key, value }) {
    commit('storeCreateLevel', {
      key,
      value,
    });
  },
  selectLevel({ commit }, id) {
    commit('storeSelectedLevel', id);
  },
  editLevel({ commit }, { key, value }) {
    commit('storeEditLevel', {
      key,
      value,
    });
  },
};
const mutations = {
  storeLevel(state, level) {
    state.level = level.map(res => ({
      ...res,
      expired_date: moment
        .unix(new Date(res.expired_date) * 0.001)
        .format('MMMM D YYYY h:mm a'),
      created_at: moment
        .unix(new Date(res.created_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
      updated_at: moment
        .unix(new Date(res.updated_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
    }));
  },
  storeIsloadLevel(state) {
    state.isLoadLevel = !state.isLoadLevel;
  },
  storeCreateLevel(state, { key, value }) {
    state.createLevel = {
      ...state.createLevel,
      [key]: value,
    };
  },
  storeSelectedLevel(state, selectLevelId) {
    const index = state.level.findIndex(level => level._id === selectLevelId);
    state.selectLevel = {
      ...state.level[index],
    };
  },
  storeEditLevel(state, { key, value }) {
    state.updateLevel = {
      ...state.updateLevel,
      [key]: value,
    };
  },

  clearSelectState(state) {
    state.selectLevel = {};
    state.updateLevel = {};
  },

  storeLearningPath(state, lpList = []) {
    if (lpList.length > 0) {
      state.learnpathList = lpList.filter(lp => lp.isPublish == true);
    } else {
      delete state.learnpathList;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
