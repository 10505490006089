<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title
            >Whack A Mole Manager {{ announcement }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" @click="createWhackAmoleGroup">
            <v-icon>add</v-icon>
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="whackAmole in gameListStore"
              :key="whackAmole._id"
              @click="onClickSelect(whackAmole)"
            >
              <v-list-tile-avatar>
                <v-icon>import_contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ whackAmole.category }} ({{
                    whackAmole.number_of_game
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ whackAmole.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeModalCreate"
      :isLastStep="valid"
      title="Create Game"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-autocomplete
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  :rules="requiredRule"
                  v-model="whack_a_mole.category_id"
                ></v-autocomplete>
                <v-text-field
                  label="Title"
                  v-model="whack_a_mole.title"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
  </Container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import ResourceSelect from '../../components/ResourceSelect';
export default {
  name: 'WhackAMole',
  components: { Container, Modal, ResourceSelect },
  data() {
    return {
      announcement: '(Available on v3.4)',
      element: {
        sub_background: 1,
      },
      step: {
        sub_background: 1,
      },
      isSelectSubBackground: false,
      isOpenModalSubBackground: false,
      isSelectSound: false,
      selectSoundUri: '',
      isSelectParticleUri: false,
      isSelectImage: false,
      selectImageUri: '',
      requiredRule: [v => !!v || 'This field is required'],
      isCreate: false,
      valid: false,
      whack_a_mole: {
        level: [],
        mole: [],
        hole: [],
      },
    };
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      slidePuzzleStore: state => state.whack_a_mole,
      gameListStore: state => state.whack_a_mole.GAME_LIST,
      createState: state => state.whack_a_mole.CREATE,
    }),
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('whack_a_mole', [
      'resetCreateState',
      'createGameData',
      'getGameList',
      'getGameData',
      'setSelectedGameListState',
    ]),
    onClickSelect(data) {
      const id = data._id;
      this.getGameData(id);
      this.setSelectedGameListState(data);
      this.$router.push({
        path: '/whack_a_mole/detail',
        query: {
          id,
        },
      });
    },
    async createWhackAmoleGroup() {
      // this.reset();
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
    },
    openModalSubBackground() {
      this.isOpenModalSubBackground = true;
    },
    addDefaultObjectSubBackground() {
      const formatObject = {
        sub_background_uri: '',
      };
      this.whack_a_mole.sub_background_list.push(formatObject);
    },
    increaseStep() {
      const max = 3;
      if (this.step.sub_background < max) {
        this.step.sub_background += 1;
        this.addDefaultObjectSubBackground();
      }
    },
    reduceStep(step) {
      let index = step - 1;
      if (this.step.sub_background > 1) {
        this.step.sub_background -= 1;
        this.whack_a_mole.sub_background_list.splice(index, 1);
      }
    },
    nextStep(currentStep) {
      if (currentStep === this.step.sub_background) {
        this.element.sub_background = 1;
      } else {
        this.element.sub_background = currentStep + 1;
      }
    },
    backStep(currentStep) {
      if (currentStep > 1) {
        this.element.sub_background -= 1;
      }
    },
    onSaveSubBackground() {
      this.isOpenModalSubBackground = false;
    },
    closeModalSubBackground() {
      this.isOpenModalSubBackground = false;
    },
    closeSelectSound() {
      this.selectSoundUri = '';
      this.isSelectSound = !this.isSelectSound;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    reset() {
      this.$refs.form.reset();
      this.resetStep();
      this.resetValidation();
    },
    resetStep() {
      this.step.sub_background = 1;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    closeModalCreate() {
      this.isCreate = false;
    },
    onSaveCreate() {
      this.isCreate = false;
      const gameObject = {
        ...this.whack_a_mole,
      };
      this.createGameData(gameObject);
    },
  },
  mounted() {
    this.getGameList();
  },
};
</script>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
