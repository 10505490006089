import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  selectedCheckboxEvent: [],
  shortStoryList: [],
  shortStoryListByTags: [],
  selectedShortStory: {},
  createGame: {
    image_uri: '',
    icon_uri: '',
    music_uri: '',
    type: '',
    tags: [],
    available: false,
    duration_ms: 45000,
    events: [],
  },
  createFilter: {
    name: '',
    is_stretch: true,
    next_index: -1,
    duration_ms: -1,
    sound_uri: '',
    image: {
      image_uri: '',
      columns: 1,
      rows: 1,
      loop: -1,
      frame_ms: 200,
      face_ratio: '1',
      type: 0,
      face_point_index: -1,
      pivot: {
        x: '50',
        y: '50',
      },
    },
    trigger_images: [],
  },
  createEvent: {
    name: '',
    type: '',
    sound_uri: '',
    duration_ms: '',
    filters: [],
  },
  updateGameDetail: {},
  updateGameEvent: {},
  updateGameFilter: {},
  selectedEventIndex: -1,
  selectedFilterIndex: -1,
  defaultSSImage: {
    name: '',
    image_uri: '',
    columns: 1,
    rows: 1,
    loop: -1,
    frame_ms: 200,
    face_ratio: '1',
    type: 0,
    face_point_index: -1,
  },
  isDirty: {
    game: false,
    event: false,
    filter: false,
  },
  defaultImageList: {
    start_image: {
      image: {
        image_uri: '',
        columns: 1,
        rows: 1,
        loop: -1,
        frame_ms: 200,
        face_ratio: '1',
        type: 0,
        face_point_index: -1,
      },
      anchor: {
        min: { x: '0', y: '0' },
        max: { x: '0', y: '0' },
      },
    },
    correct_image: {
      image: {
        image_uri: '',
        columns: 1,
        rows: 1,
        loop: -1,
        frame_ms: 200,
        face_ratio: '1',
        type: 0,
        face_point_index: -1,
      },
      anchor: {
        min: { x: '0', y: '0' },
        max: { x: '0', y: '0' },
      },
    },
    name: '',
    is_clickable: false,
    play_event_index: -1,
  },
  defaultWordList: {
    word: '',
    event_index: '',
  },
  //default type game
  Mic: {
    event_index_fail: -1,
    event_index_1star: -1,
    event_index_2star: -1,
    event_index_3star: -1,
    retry_amount: 0,
  },
  Micwords: { retry_amount: 0 },
  //default type filter
  Basic: {
    anchor: { min: { x: '0', y: '0' }, max: { x: '0', y: '0' } },
  },
  SnapFace: {},
  FaceNPoint: {
    anchor: { min: { x: '0', y: '0' }, max: { x: '0', y: '0' } },
    point: { x: '0', y: '0' },
    end_point: { x: '0', y: '0' },
    duration_ms_transition: '',
    start_scale: '',
    end_scale: '',
  },
  FaceCap: {
    left_anchor_min: { x: '0', y: '0' },
    right_anchor_min: { x: '0', y: '0' },
  },
  Sunglasses: {
    left_anchor_min: { x: '0', y: '0' },
    right_anchor_min: { x: '0', y: '0' },
  },
  Particle: {
    is_loop: true,
    rate: '100',
    max: 5000,
    life_time: '1',
    speed: '1',
    play_duration_ms: 5,
    size_over_life_time_scale_a: '1',
    size_over_life_time_scale_b: '1',
    rotate_speed: '0',
    point: { x: '0', y: '0' },
  },
  CoverFace: {},
  Scale: {},
};
const getters = {};
const actions = {
  fetchShortStoryById({ commit, dispatch }, id) {
    if (id != undefined) {
      http
        .GET(`/api/short_story/tools?id=${id}`, {
          isAuthorization: true,
        })
        .then(({ data }) => {
          data.created_at = moment(data.created_at).format(
            'MMMM D YYYY h:mm a',
          );
          data.updated_at = moment(data.updated_at).format(
            'MMMM D YYYY h:mm a',
          );
          dispatch('clearSelectedCheckboxEvent');
          commit('set_select_game', data);
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message:
                'Cannot fetch short story game in this time. please try again.',
              type: 'error',
            },
            {
              root: true,
            },
          );
          throw new Error(err);
        });
    }
  },
  fetchShortStoryList({ commit, dispatch }) {
    //api
    http
      .GET(`/api/short_story/tools`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('set_game_list', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch short story game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  fetchShortStoryListByTags({ commit, dispatch }, tags) {
    //api
    const tag = tags.join();
    http
      .GET(`/api/short_story/search?tags=${tag}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('set_game_list_tag', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch short story game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  createGameShortStory({ commit, dispatch }, data) {
    //api
    http
      .POST('/api/short_story', data, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchShortStoryList', {});
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Game Successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Create Game Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  createEventShortStory({ commit, dispatch }, data) {
    //api
    const game_id = data.game_id;
    http
      .POST('/api/short_story/event', data, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchShortStoryById', game_id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Game Successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Create Game Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  createFilterShortStory({ commit, dispatch }, data) {
    //api
    const game_id = state.updateGameDetail.id;
    http
      .POST('/api/short_story/filter', data, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchShortStoryById', game_id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Filter Successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Create Filter Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateGameShortStory({ commit, dispatch }, data) {
    //api
    delete data.created_at;
    delete data.updated_at;
    const id = data.id;
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Updating word',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/short_story', data, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchShortStoryById', id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  updateEventShortStory({ commit, dispatch }, data) {
    //api
    delete data.created_at;
    delete data.updated_at;
    const game_id = state.updateGameDetail.id;
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Updating Event',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/short_story/event', data, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchShortStoryById', game_id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update event successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  updateFilterShortStory({ commit, dispatch }, data) {
    //api
    delete data.created_at;
    delete data.updated_at;
    const game_id = state.updateGameDetail.id;
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Updating Event',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/short_story/filter', data, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchShortStoryById', game_id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update filter successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteGameShortStory({ commit, dispatch }, { id, tags }) {
    http
      .DELETE(`/api/short_story?id=${id}`, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete Game successfully',
            type: 'success',
          },
          { root: true },
        );
        router.push(`/short_story`);
        tags !== undefined
          ? dispatch('fetchShortStoryListByTags', tags)
          : dispatch('fetchShortStoryList');
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete at this time, please try again later.',
            type: 'error',
          },
          { root: true },
        );
      });
  },
  deleteFilterShortStory({ commit, dispatch }, id) {
    const game_id = state.updateGameDetail.id;
    http
      .DELETE(`/api/short_story/filter?id=${id}`, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchShortStoryById', game_id);
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete filter successfully',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete at this time, please try again later.',
            type: 'error',
          },
          { root: true },
        );
      });
  },
  deleteEventShortStory({ commit, dispatch }, id) {
    const game_id = state.updateGameDetail.id;
    http
      .DELETE(`/api/short_story/event?id=${id}`, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchShortStoryById', game_id);
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Delete Event successfully',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete at this time, please try again later.',
            type: 'error',
          },
          { root: true },
        );
      });
  },
  duplicateEventandFilter({ commit, dispatch }, { id, game_id }) {
    http
      .POST(
        '/api/short_story/event/duplicate',
        { id: id },
        {
          isAuthorization: true,
        },
      )
      .then(() => {
        dispatch('fetchShortStoryById', game_id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Game Successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Create Game Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  duplicateFilter({ commit, dispatch }, { id, game_id }) {
    http
      .POST(
        '/api/short_story/filter/duplicate',
        { id: id },
        {
          isAuthorization: true,
        },
      )
      .then(() => {
        dispatch('fetchShortStoryById', game_id);
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Game Successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Create Game Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setCreateFilter({ commit, dispatch }, { key, value }) {
    commit('create_filter_store', { key, value });
  },
  updateEventStore({ commit, dispatch }, { key, value }) {
    commit('update_event_store', { key, value });
  },
  updateGameStore({ commit, dispatch }, { key, value }) {
    commit('update_game_store', { key, value });
  },
  updateFilterStore({ commit, dispatch }, { key, value }) {
    commit('update_filter_store', { key, value });
  },
  clearShortStoryListByTags({ commit }) {
    commit('clear_game_list_tag');
  },
  clearCreateGame({ commit }) {
    commit('clear_create_game');
  },
  clearCreateFilter({ commit }) {
    commit('clear_create_filter');
  },
  clearCreateEvent({ commit }) {
    commit('clear_create_event');
  },
  clearSelectedGame({ commit }) {
    commit('clear_seleted_game');
  },
  clearUpdateGame({ commit }) {
    commit('clear_update_game');
  },
  clearUpdateEvent({ commit }) {
    commit('clear_update_event');
  },
  clearUpdateFilter({ commit }) {
    commit('clear_update_filter');
  },
  clearSelectedCheckboxEvent({ commit }) {
    commit('clear_selected_checkbox_event');
  },
  setSelectedEventIndex({ commit, dispatch }, value) {
    commit('set_selectedEventIndex', parseInt(value));
  },
  setSelectedFilterIndex({ commit, dispatch }, value) {
    commit('set_selectedFilterIndex', parseInt(value));
  },
  clearSelectedEventIndex({ commit }) {
    commit('clear_selectedEventIndex');
  },
  clearSelectedFilterIndex({ commit }) {
    commit('clear_selectedFilterIndex');
  },
  setDirty({ commit }, type) {
    commit('set_dirty', type);
  },
  clearDirty({ commit }, type) {
    commit('clear_dirty', type);
  },
};
const mutations = {
  set_game_list(state, value) {
    if (value) {
      state.shortStoryList = value;
    }
  },
  set_game_list_tag(state, value) {
    if (value) {
      state.shortStoryListByTags = value;
    }
  },
  set_select_game(state, value) {
    if (value) {
      if (value.events.length) {
        value.events.forEach((element, index) => {
          if (element.is_auto === true) {
            state.selectedCheckboxEvent.push(index);
          }
        });
      }
      state.selectedShortStory = value;
    }
  },
  create_filter_store(state, { key, value }) {
    if (value) {
      state.createFilter = { ...state.createFilter, [key]: value };
    }
  },
  update_game_store(state, { key, value }) {
    if (key) {
      state.updateGameDetail = { ...state.updateGameDetail, [key]: value };
    }
  },
  update_event_store(state, { key, value }) {
    if (key) {
      state.updateGameEvent = { ...state.updateGameEvent, [key]: value };
    }
  },
  update_filter_store(state, { key, value }) {
    if (key) {
      state.updateGameFilter = { ...state.updateGameFilter, [key]: value };
    }
  },
  set_selectedEventIndex(state, value) {
    state.selectedEventIndex = value;
  },
  set_selectedFilterIndex(state, value) {
    state.selectedFilterIndex = value;
  },
  clear_seleted_game(state) {
    state.selectedShortStory = {};
  },
  clear_game_list_tag(state) {
    state.shortStoryListByTags = [];
  },
  clear_create_game(state) {
    state.createGame = {
      image_uri: '',
      icon_uri: '',
      music_uri: '',
      type: '',
      tags: [],
      available: false,
      duration_ms: 45000,
      events: [],
    };
  },
  clear_update_game(state) {
    state.updateGameDetail = {};
  },
  clear_update_event(state) {
    state.updateGameEvent = {};
  },
  clear_create_event(state) {
    state.createEvent = {
      name: '',
      type: '',
      sound_uri: '',
      duration_ms: '',
      filters: [],
    };
  },
  clear_update_filter(state) {
    state.updateGameFilter = {};
  },
  clear_create_filter(state) {
    state.createFilter = {
      name: '',
      is_stretch: true,
      next_index: -1,
      duration_ms: -1,
      sound_uri: '',
      image: {
        image_uri: '',
        columns: 1,
        rows: 1,
        loop: -1,
        frame_ms: 200,
        face_ratio: '1',
        type: 0,
        face_point_index: -1,
        pivot: {
          x: '50',
          y: '50',
        },
      },
      trigger_images: [],
    };
  },
  clear_selectedEventIndex(state) {
    state.selectedEventIndex = -1;
  },
  clear_selectedFilterIndex(state) {
    state.selectedFilterIndex = -1;
  },
  set_dirty(state, type) {
    state.isDirty[type] = true;
  },
  clear_dirty(state, type) {
    if (type === undefined)
      state.isDirty = { game: false, event: false, filter: false };
    else state.isDirty[type] = false;
  },
  clear_selected_checkbox_event(state) {
    state.selectedCheckboxEvent = [];
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
