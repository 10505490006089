<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Mini Chat Category</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" v-on:click="openSelectCategory">
            <v-icon>add</v-icon>
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="category in minichatCategoryStore"
              :key="category.category_id"
              v-on:click="() => onClickEdit(category.category_id)"
            >
              <v-list-tile-avatar>
                <v-icon>chat</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ category.category_name }} ({{
                    category.minichats
                  }})</v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <Category
      :v-if="isSelectCategory"
      :isOpen="isSelectCategory"
      :close="closeCreateCategory"
      :onSelect="onClickSelectCategory"
      :value="categoryId"
      title="Select Category"
    ></Category>
  </Container>
</template>

<script>
import Container from '../../components/Container';
import Category from '../../components/Category';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MiniChatCategory',
  components: {
    Container,
    Category,
  },
  computed: {
    ...mapState({
      minichatStore: state => state.minichat,
      mindmapStore: state => state.minichat.mindmap,
      minichatCategoryStore: state => state.minichat.minichatCategory,
    }),
  },
  data: function () {
    return {
      isSelectCategory: false,
      categoryId: '',
    };
  },
  methods: {
    ...mapActions('minichat', [
      'fetchMiniChat',
      'selectMiniChat',
      'selectCategory',
      'fetchMiniChatByCategoryId',
      'fetchMinichatCategory',
      'fetchMinichatInCategory',
    ]),
    openSelectCategory() {
      this.isSelectCategory = true;
    },
    closeCreateCategory() {
      this.isSelectCategory = false;
    },
    onClickEdit(id) {
      this.fetchMinichatInCategory({ id: id, sub_id: '' });
      this.$router.push({
        path: '/minichat/list',
        query: {
          id,
        },
      });
    },
    onClickSelectCategory(id) {
      this.isSelectCategory = false;
      this.selectCategory(id);
      this.$router.push({
        path: '/minichat/list',
        query: {
          id,
        },
      });
    },
  },
  mounted() {
    this.fetchMinichatCategory();
  },
};
</script>

<style></style>
