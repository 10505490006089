<template>
  <v-flex xs5 mr-2>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Home Page Data</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="onClickUpdateDetail()"
          color="green"
          :disabled="!valid || isLock"
        >
          <v-icon>save</v-icon>Save
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card-text>
      <v-container>
        <v-form ref="formUpdate" v-model="valid" lazy-validation>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-checkbox
                v-model="pageGameStore.selectedHomePageDetail.isGuestPlayable"
                label="Available for Free User"
                @change="e => onchangePageDetail('isGuestPlayable', e)"
                :disabled="isLock"
              ></v-checkbox>
              <v-checkbox
                v-model="pageGameStore.selectedHomePageDetail.randomable"
                label="Randomable"
                @change="e => onchangePageDetail('randomable', e)"
                :disabled="isLock"
              ></v-checkbox>
              <v-text-field
                label="_id"
                :value="pageGameStore.selectedHomePageDetail._id"
                disabled
              ></v-text-field>
              <v-text-field
                label="Title"
                :value="pageGameStore.selectedHomePageDetail.title"
                :rules="[rules.required]"
                @change="e => onchangePageDetail('title', e)"
                :disabled="isLock"
              ></v-text-field>
              <!-- <v-select
                :items="HCellFormat"
                item-text="name"
                item-value="value"
                v-model="pageGameStore.selectedHomePageDetail.format"
                label="Cell Format"
                :rules="[rules.required]"
                @change="e => onchangePageDetail('format', e)"
              ></v-select> -->
              <img
                class="image_thumbnail"
                :src="pageGameStore.selectedHomePageDetail.img_uri"
              />
              <v-text-field
                label="Image URI (URL or Select file)"
                :value="pageGameStore.selectedHomePageDetail.img_uri"
                v-model="pageGameStore.selectedHomePageDetail.img_uri"
                @change="e => onchangePageDetail('img_uri', e)"
                :rules="[rules.validURI]"
                :disabled="isLock"
              ></v-text-field>
              <v-btn
                class="btn-center"
                @click="onclickEditImage()"
                :disabled="isLock"
                ><v-icon>image</v-icon>Select Image URI</v-btn
              >
              <v-data-table
                v-if="
                  pageGameStore.selectedHomePageDetail.type ===
                    constantHomePage.GameType.LPGame ||
                  pageGameStore.selectedHomePageDetail.type ===
                    constantHomePage.GameType.LPActivity
                "
                :items="[
                  {
                    name: 'activity_index',
                    value:
                      pageGameStore.selectedHomePageDetail.data.activity_index,
                  },
                  {
                    name: 'unit_index',
                    value: pageGameStore.selectedHomePageDetail.data.unit_index,
                  },
                  {
                    name: 'lesson_index',
                    value:
                      pageGameStore.selectedHomePageDetail.data.lesson_index,
                  },
                  {
                    name: 'level_index',
                    value:
                      pageGameStore.selectedHomePageDetail.data.level_index,
                  },
                ]"
                class="elevation-1"
                hide-actions
                hide-headers
              >
                <template v-slot:items="props">
                  <td class="text-xs-left">
                    {{ props.item.name }}
                  </td>
                  <td>{{ props.item.value }}</td>
                </template>
              </v-data-table>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>{{
                    pageGameStore.infoGame
                  }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </v-card>
              <v-card-text>
                <div
                  v-if="
                    pageGameStore.selectedHomePageDetail.type !==
                    constantHomePage.GameType.TagGame
                  "
                >
                  <v-layout row>
                    <v-layout column>
                      <v-text-field
                        label="_id"
                        :value="pageGameStore.selectedHomePageDetail.data._id"
                        disabled
                      ></v-text-field>
                    </v-layout>
                    <v-layout column>
                      <v-btn
                        v-if="pageGameStore.selectedHomePageDetail.data.unit_id"
                        icon
                        dark
                        color="black"
                        v-on:click="
                          onClickOpenGameWindow({
                            unitId:
                              pageGameStore.selectedHomePageDetail.data.unit_id,
                            lessonIndex:
                              pageGameStore.selectedHomePageDetail.data
                                .lesson_index,
                            activityIndex:
                              pageGameStore.selectedHomePageDetail.data
                                .activity_index,
                          })
                        "
                      >
                        <v-icon>open_in_new</v-icon>
                      </v-btn>
                      <v-btn
                        v-else-if="
                          pageGameStore.selectedHomePageDetail.data.bookshelf_id
                        "
                        icon
                        dark
                        color="black"
                        v-on:click="
                          onClickOpenGameWindow({
                            bookshelf_id:
                              pageGameStore.selectedHomePageDetail.data
                                .bookshelf_id,
                            book_id:
                              pageGameStore.selectedHomePageDetail.data._id,
                          })
                        "
                      >
                        <v-icon>open_in_new</v-icon></v-btn
                      >
                      <v-btn
                        v-else-if="
                          pageGameStore.selectedHomePageDetail.data.medias_id
                        "
                        icon
                        dark
                        color="black"
                        v-on:click="
                          onClickOpenGameWindow({
                            medias_id:
                              pageGameStore.selectedHomePageDetail.data
                                .medias_id,
                            video_id:
                              pageGameStore.selectedHomePageDetail.data._id,
                          })
                        "
                      >
                        <v-icon>open_in_new</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        icon
                        dark
                        color="black"
                        v-on:click="
                          onClickOpenGameWindow({
                            id: pageGameStore.selectedHomePageDetail.data._id,
                          })
                        "
                      >
                        <v-icon>open_in_new</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-layout>
                  <v-layout row>
                    <v-text-field
                      label="Name"
                      :value="pageGameStore.selectedHomePageDetail.data.name"
                      @change="e => onchangePageDetail('name', e)"
                      :disabled="isLock"
                    ></v-text-field>
                  </v-layout>
                  <v-layout row>
                    <v-text-field
                      v-if="
                        pageGameStore.selectedHomePageDetail.type ===
                        constantHomePage.GameType.PgGame
                      "
                      label="Type"
                      :value="showTypePgGame"
                      disabled
                    ></v-text-field>
                  </v-layout>
                </div>

                <div
                  v-if="
                    pageGameStore.selectedHomePageDetail.type ===
                    constantHomePage.GameType.TagGame
                  "
                >
                  <v-layout row>
                    <v-layout column>
                      <v-text-field
                        label="Tags"
                        :value="pageGameStore.selectedHomePageDetail.data.tags"
                        disabled
                      ></v-text-field>
                    </v-layout>
                    <v-layout column>
                      <v-btn
                        v-if="pageGameStore.selectedHomePageDetail.data.tags"
                        icon
                        dark
                        color="black"
                        v-on:click="
                          onClickOpenGameWindow({
                            tag: pageGameStore.selectedHomePageDetail.data.tags,
                            type: pageGameStore.selectedHomePageDetail.data
                              .type,
                          })
                        "
                      >
                        <v-icon>open_in_new</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-layout>
                  <v-layout row>
                    <v-text-field
                      label="Type"
                      :value="showTypeTagGame"
                      disabled
                    ></v-text-field>
                  </v-layout>
                </div>
              </v-card-text>
              <v-card-text>
                <v-btn
                  color="red"
                  dark
                  @click="
                    onClickDeleteHomeData(
                      pageGameStore.selectedHomePageDetail._id,
                    )
                  "
                  :disabled="isLock"
                >
                  <v-icon>delete</v-icon>Delete This Home Data
                </v-btn>
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
    </v-card-text>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
  </v-flex>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import constant from '../constant';
import ruleValidate from '../../../utils/ruleValidate';
import ResourceSelect from '../../../components/ResourceSelect.vue';
// import Modal from '../../../components/Modal.vue';
import swal from 'sweetalert2';
import constantHomePage from '../constant';

export default {
  components: { ResourceSelect },
  data() {
    return {
      HCellType: constant.HCellType,
      HCellFormat: constant.HCellFormat,
      valid: true,
      rules: ruleValidate,
      isEditImage: false,
      isSelectImg: false,
      selectImageUri: '',
      constantHomePage,
    };
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
      isLock: state => state.homepage.adminIsLock,
    }),
    typeInDataGame() {
      return this.pageGameStore.selectedHomePageDetail.data.type;
    },
    showTypePgGame() {
      if (this.typeInDataGame === constantHomePage.typePgGame.Book)
        return 'Book';
      else if (this.typeInDataGame === constantHomePage.typePgGame.SnapGame)
        return 'SnapGame';
      else if (this.typeInDataGame === constantHomePage.typePgGame.RunningGame)
        return 'RunningGame';
      else if (this.typeInDataGame === constantHomePage.typePgGame.DrivingGame)
        return 'DrivingGame';
      else if (this.typeInDataGame === constantHomePage.typePgGame.SequenceGame)
        return 'SequenceGame';
      else if (this.typeInDataGame === constantHomePage.typePgGame.QuizGame)
        return 'QuizGame';
      else return 'Video';
    },
    showTypeTagGame() {
      if (this.typeInDataGame === constantHomePage.typeTagGame.ShortStory)
        return 'ShortStory';
      else if (this.typeInDataGame === constantHomePage.typeTagGame.SpeechLab)
        return 'SpeechLab';
      else return 'Dictionary';
    },
  },
  methods: {
    ...mapActions('homepage', [
      'setStateByKey',
      'updatePageData',
      'deletePageHomeData',
    ]),
    onClickOpenGameWindow({
      unitId,
      lessonIndex,
      activityIndex,
      tag,
      type,
      medias_id,
      video_id,
      book_id,
      bookshelf_id,
      id,
    }) {
      let path = '',
        routeData;
      if (tag && type >= 0) {
        if (type === constantHomePage.typeTagGame.SpeechLab) {
          path = '/dicphonic';
          routeData = this.$router.resolve({
            path: path,
            query: { tags: tag },
          });
        } else if (type === constantHomePage.typeTagGame.Dictionary) {
          path = '/dictionary';
          routeData = this.$router.resolve({
            path: path,
            query: { word: tag },
          });
        } else {
          path = '/short_story';
          routeData = this.$router.resolve({
            path: path,
            query: { word: tag },
          });
        }
      } else if (medias_id && video_id) {
        path = '/media/detail';
        routeData = this.$router.resolve({
          path: path,
          query: { id: medias_id, video_id: video_id },
        });
      } else if (book_id && bookshelf_id) {
        path = '/book/detail';
        routeData = this.$router.resolve({
          path: path,
          query: { id: bookshelf_id, book_id: book_id },
        });
      } else if (id) {
        switch (this.typeInDataGame) {
          case constantHomePage.typePgGame.SnapGame:
            path = '/snap/detail';
            break;
          case constantHomePage.typePgGame.RunningGame:
            path = '/running_game/detail';
            break;
          case constantHomePage.typePgGame.DrivingGame:
            path = '/driving_game/detail';
            break;
          case constantHomePage.typePgGame.SequenceGame:
            path = '/sequence_game/detail';
            break;
          case constantHomePage.typePgGame.QuizGame:
            path = '/quiz_game/detail';
            break;
          default:
            break;
        }
        routeData = this.$router.resolve({
          path: path,
          query: { id },
        });
      } else {
        path = '/learningpath';
        routeData = this.$router.resolve({
          path: path,
          query: { id: unitId, lessonIndex, activityIndex },
        });
      }
      window.open(routeData.href, '_blank');
    },
    onClickDeleteHomeData(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        })
        .then(result => {
          if (result.value) {
            this.deletePageHomeData(id);
          }
        });
    },
    onClickUpdateDetail() {
      if (this.$refs.formUpdate.validate()) {
        // swal
        // .fire({
        //   titleText: 'Are you sure.',
        //   type: 'warning',
        //   showCancelButton: true,
        //   cancelButtonColor: '#d33',
        // })
        // .then(result => {
        //   if (result.value) {
        const update = { ...this.pageGameStore.updatePageDetail };
        this.updatePageData(update);
        //   }
        // });
      }
    },
    onchangePageDetail(key, value) {
      if (key === 'name') {
        value = {
          [key]: value,
        };
        key = 'data';
      }
      this.setStateByKey({
        statekey: 'updatePageDetail',
        key: key,
        value: value,
      });
    },
    onclickEditImage() {
      this.isEditImage = true;
      this.isSelectImg = true;
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImg:
          this.pageGameStore.selectedHomePageDetail.img_uri = uri;
          this.onchangePageDetail('img_uri', uri);
          this.isSelectImg = false;
          // this.setDirty('game');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
  },
};
</script>

<style>
.active {
  background-color: #212121;
}
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
</style>
