<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Tapstory List Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" @click="createTapstoryGroup">
            <v-icon>add</v-icon>
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="tapStory in tapstoryListStore"
              :key="tapStory._id"
              @click="onClickSelect(tapStory)"
            >
              <v-list-tile-avatar>
                <v-icon>import_contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ tapStory.category }} ({{
                    tapStory.number_of_tapstory
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ tapStory.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeModalCreate"
      :isLastStep="valid"
      title="Create Tapstory"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-autocomplete
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  :rules="requiredRule"
                  v-model="tapstory.category_id"
                ></v-autocomplete>
                <v-text-field
                  required
                  :rules="requiredRule"
                  label="Tapstory Name"
                  v-model="tapstory.name"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
  </Container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import ResourceSelect from '../../components/ResourceSelect';
export default {
  name: 'TapStoryList',
  components: { Container, Modal, ResourceSelect },
  data() {
    return {
      isSelectSound: false,
      selectSoundUri: '',
      isSelectImage: false,
      selectImageUri: '',
      tapstory: {
        category_id: '',
        name: '',
        bgm_uri: '',
        background_uri: '',
        question_uri: '',
        question: '',
        show_finger: true,
        image_set: [
          {
            image_group: [
              {
                name: '',
                image_data: [
                  {
                    image_start_x: '',
                    image_start_y: '',
                    image_end_x: '',
                    image_end_y: '',
                    click_start_x: '',
                    click_start_y: '',
                    click_end_x: '',
                    click_end_y: '',
                    image_uri: '',
                    sound_uri: '',
                    is_click: true,
                    sound_end_delay: '1',
                    next_index: -1,
                  },
                ],
              },
            ],
          },
        ],
      },
      requiredRule: [v => !!v || 'This field is required'],
      isCreate: false,
      valid: false,
    };
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      tapStoryStore: state => state.tapstory,
      tapstoryListStore: state => state.tapstory.TAPSTORY_LIST,
      createState: state => state.tapstory.CREATE,
    }),
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('tapstory', [
      'resetCreateState',
      'createTapstoryData',
      'getTapstoryList',
      'getTapstoryData',
      'setSelectedTapstoryListState',
    ]),
    onClickSelect(data) {
      const id = data._id;
      this.getTapstoryData(id);
      this.setSelectedTapstoryListState(data);
      this.$router.push({
        path: '/tapstory/detail',
        query: {
          id,
        },
      });
    },
    async createTapstoryGroup() {
      // this.reset();
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
    },
    closeSelectSound() {
      this.selectSoundUri = '';
      this.isSelectSound = !this.isSelectSound;
    },
    saveSelectSound() {
      const uri = this.selectSoundUri;
      this.tapstory.bgm_uri = uri;
      this.selectSoundUri = '';
      this.isSelectSound = !this.isSelectSound;
    },
    closeSelectImage() {
      this.selectImageUri = '';
      this.isSelectImage = !this.isSelectImage;
    },
    saveSelectImage() {
      const uri = this.selectImageUri;
      this.tapstory.background_uri = uri;
      this.selectImageUri = '';
      this.isSelectImage = !this.isSelectImage;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    reset() {
      this.$refs.form.reset();
      this.resetStep();
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    closeModalCreate() {
      this.isCreate = false;
    },
    onSaveCreate() {
      this.isCreate = false;
      const tapstoryObject = {
        ...this.tapstory,
      };
      this.createTapstoryData(tapstoryObject);
    },
  },
  mounted() {
    this.getTapstoryList();
  },
};
</script>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
