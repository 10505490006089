<template>
  <div>
    <v-stepper v-model="element.question">
      <v-stepper-header id="stepper-header">
        <template v-for="n in steps.question">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="stepComplete(n)"
            :step="n"
            :color="stepStatus(n)"
            editable
            :rules="[value => !!stepValidate.question[n - 1]]"
            @click="onClickStep(n - 1)"
          >
            Question {{ n }}
          </v-stepper-step>

          <v-divider v-if="n !== steps.question" :key="n"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="questionStep in steps.question"
          :key="`${questionStep}-content`"
          :step="questionStep"
        >
          <v-form
            ref="formQuestion"
            v-model="stepValidate.question[questionStep - 1]"
            lazy-validation
          >
            <v-btn
              v-if="steps.question < questionMax"
              color="success"
              small
              fab
              @click="addData('question_list', questionStep - 1)"
              ><v-icon>add</v-icon>
            </v-btn>
            <v-btn
              v-if="steps.question > 1"
              color="error"
              small
              fab
              @click="removeData('question_list', questionStep - 1)"
              ><v-icon>delete</v-icon>
            </v-btn>
            <v-card
              v-if="data.question_list"
              class="ma-2 pa-4"
              id="card-choice"
            >
              <v-layout row wrap>
                <v-flex xs8>
                  <v-text-field
                    label="Question Text"
                    :value="data.question_list[questionStep - 1].question_text"
                    v-model="data.question_list[questionStep - 1].question_text"
                    :rules="[rules.required]"
                    @change="onchangeUpdate()"
                  ></v-text-field
                ></v-flex>
                <v-flex xs4>
                  <v-checkbox
                    label="Add Image"
                    v-model="
                      data.question_list[questionStep - 1].is_question_url
                    "
                    @change="onchangeUpdate()"
                  ></v-checkbox>
                </v-flex>
                <v-flex>
                  <div
                    v-if="
                      data.question_list[questionStep - 1].is_question_url ===
                      true
                    "
                  >
                    <img
                      :src="data.question_list[questionStep - 1].question_url"
                      :alt="data.question_list[questionStep - 1].question_url"
                      class="content_img"
                    />
                    <v-text-field
                      label="Question URL (URL or Select file)"
                      :value="data.question_list[questionStep - 1].question_url"
                      v-model.trim="
                        data.question_list[questionStep - 1].question_url
                      "
                      :rules="[rules.required]"
                      @change="onchangeUpdate()"
                    ></v-text-field>
                    <v-btn
                      dark
                      @click="
                        setResource(
                          'image',
                          'isQuestionImage',
                          questionStep - 1,
                        )
                      "
                    >
                      <v-icon>image</v-icon>Select Question Image
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>

              <v-toolbar dark tabs>
                <v-toolbar-title>choice</v-toolbar-title>
              </v-toolbar>
              <v-select
                label="Choice Type"
                :items="constants.ANSWER_TYPE_DROPDOWN"
                item-text="name"
                item-value="value"
                v-model="data.question_list[questionStep - 1].choice_type"
                :rules="[rules.required]"
                @change="onchangeUpdate()"
              ></v-select>

              <!-- choice -->
              <div
                class="choiceStep"
                v-if="
                  data.question_list[questionStep - 1].choice_type !==
                  constants.ANSWER_TYPE.STT
                "
              >
                <v-stepper v-model="element.choice" color="green">
                  <v-stepper-header>
                    <template v-for="n in steps.choice">
                      <v-stepper-step
                        :key="`${n}-step`"
                        :complete="stepComplete(n, 'choice')"
                        :step="n"
                        :rules="[value => !!stepValidate.choice[n - 1]]"
                        :color="stepStatus(n, 'choice')"
                        editable
                      >
                        Choice {{ n }}
                      </v-stepper-step>

                      <v-divider v-if="n !== steps.choice" :key="n"></v-divider>
                    </template>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content
                      v-for="choiceStep in steps.choice"
                      :key="`${choiceStep}-content`"
                      :step="choiceStep"
                    >
                      <!-- <v-btn
                      v-if="steps.choice < questionMax"
                      color="success"
                      small
                      fab
                      @click="addData('choice', questionStep - 1)"
                      ><v-icon>add</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="steps.choice > 3"
                      color="error"
                      small
                      fab
                      @click="
                        removeData('choice', questionStep - 1, choiceStep - 1)
                      "
                      ><v-icon>delete</v-icon>
                    </v-btn> -->
                      <v-card>
                        <v-form
                          ref="formChoice"
                          v-model="stepValidate.choice[choiceStep - 1]"
                          lazy-validation
                        >
                          <v-text-field
                            v-if="
                              data.question_list[questionStep - 1]
                                .choice_type === constants.ANSWER_TYPE.TEXT
                            "
                            class="ma-2"
                            label="Text Choice"
                            :value="
                              data.question_list[questionStep - 1].choice[
                                choiceStep - 1
                              ]
                            "
                            v-model="
                              data.question_list[questionStep - 1].choice[
                                choiceStep - 1
                              ]
                            "
                            :rules="[rules.required]"
                            @change="onchangeUpdate()"
                          ></v-text-field>
                          <div v-else class="pt-3">
                            <img
                              :src="
                                data.question_list[questionStep - 1].choice[
                                  choiceStep - 1
                                ]
                              "
                              :alt="
                                data.question_list[questionStep - 1].choice[
                                  choiceStep - 1
                                ]
                              "
                              class="content_img"
                            />
                            <v-text-field
                              class="ma-2"
                              label="Image Uri"
                              :value="
                                data.question_list[questionStep - 1].choice[
                                  choiceStep - 1
                                ]
                              "
                              v-model="
                                data.question_list[questionStep - 1].choice[
                                  choiceStep - 1
                                ]
                              "
                              :rules="[rules.validURI]"
                              @change="onchangeUpdate()"
                            ></v-text-field>
                            <v-btn
                              dark
                              @click="
                                setResource(
                                  'image',
                                  'isChoiceImage',
                                  questionStep - 1,
                                  choiceStep - 1,
                                )
                              "
                            >
                              <v-icon>image</v-icon>Select Choice Image
                            </v-btn>
                          </div>
                        </v-form>
                      </v-card>

                      <v-btn
                        v-if="choiceStep !== ChoiceMax"
                        color="primary"
                        @click="nextStep(choiceStep, 'choice')"
                      >
                        Next
                      </v-btn>

                      <v-btn
                        v-if="choiceStep !== 1"
                        flat
                        @click="reduceStep(choiceStep, 'choice')"
                        >Back</v-btn
                      >
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </div>
              <v-text-field
                v-else
                label="Answer Stt"
                v-model.trim="data.question_list[questionStep - 1].answer_stt"
                :rules="[rules.required]"
                @change="onchangeUpdate()"
              ></v-text-field>
              <v-radio-group
                v-if="
                  data.question_list[questionStep - 1].choice_type !==
                  constants.ANSWER_TYPE.STT
                "
                v-model.number="
                  data.question_list[questionStep - 1].answer_index
                "
                row
                @change="onchangeUpdate()"
              >
                Answer Index
                <div v-for="choiceIndex in 4" :key="choiceIndex">
                  <v-radio
                    :label="`choice${choiceIndex}`"
                    :value="choiceIndex - 1"
                  ></v-radio>
                </div>
              </v-radio-group>
            </v-card>

            <v-btn
              color="primary"
              v-if="steps.question > 1"
              @click="nextStep(questionStep, 'question')"
            >
              Next
            </v-btn>

            <v-btn
              flat
              v-if="questionStep > 1"
              @click="reduceStep(questionStep, 'question')"
              >Back</v-btn
            >
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <br />
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../../components/ResourceSelect.vue';
import constants from './constants';
import rules from '../../../../utils/ruleValidate';

export default {
  name: 'ChoiceInfo',
  components: {
    ResourceSelect,
  },
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      QuizGameStore: state => state.quiz_game,
      // question_list: state => state.quiz_game.selectedQuizGame.question_list,
      // choices: state => state.quiz_game.selectedQuizGame.question_list,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  data() {
    return {
      constants,
      // isQuestionImage: false,
      element: {
        choice: 1,
        question: 1,
      },
      steps: {
        choice: 1,
        question: 1,
      },
      questionMax: 10,
      ChoiceMax: 4,
      data: {},
      index: { question: 0, choice: 0 },
      rules,
      resourceType: '',
      resource: {
        // sound: {
        //   isEdit: false,
        //   selectUri: '',
        //   isBgmSound: false,
        // },
        image: {
          isEdit: false,
          selectUri: '',
          isQuestionImage: false,
          isChoiceImage: false,
        },
      },
      stepValidate: {
        question: [],
        choice: [],
      },
    };
  },
  methods: {
    ...mapActions('quiz_game', ['setStateByKey']),
    stepComplete(step, type) {
      return type === 'choice'
        ? this.element.choice > step
        : this.element.question > step;
    },
    stepStatus(step, type) {
      return type === 'choice'
        ? this.element.choice > step
          ? 'green'
          : 'blue'
        : this.element.question > step
        ? 'green'
        : 'blue';
    },
    validate() {
      let validQuestion = this.$refs.formQuestion.map(e => e.validate());
      let validChioce = this.$refs.formChoice.map(e => e.validate());
      let valid = validQuestion.concat(validChioce);
      return !valid.includes(false);
    },
    onchangeUpdate() {
      this.$emit('validate', this.validate());
      if (this.validate()) {
        this.isCreate
          ? this.setStateByKey({
              statekey: 'createQuizGame',
              key: 'question_list',
              value: this.data.question_list,
            })
          : this.setStateByKey({
              statekey: 'updateQuizGame',
              key: 'question_list',
              value: this.data.question_list,
            });
      }
    },
    onClickStep(indexQuestion, resetChoice = true) {
      this.steps.choice = this.data.question_list[indexQuestion].choice.length;
      if (resetChoice) this.element.choice = 1;
    },
    intialStepper() {
      if (this.data.question_list.length > 0) {
        this.resizeStepperHeader(this.data.question_list.length);
        this.steps.question = this.data.question_list.length;
        this.data.question_list.forEach(() => {
          this.stepValidate.question.push(true);
        });
        if (this.data.question_list[0].choice.length > 0) {
          this.data.question_list[0].choice.forEach(() => {
            this.stepValidate.choice.push(true);
          });
          this.steps.choice = this.data.question_list[0].choice.length;
        } else this.steps.choice = this.ChoiceMax;
      } else this.steps.question = 1;
    },
    setResource(resource, isType, indexQuestion, indexChoice) {
      //resource = image||sound||video
      //isType = isQuestionImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
      this.index.question = indexQuestion != undefined ? indexQuestion : 0;
      this.index.choice = indexChoice != undefined ? indexChoice : 0;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isQuestionImage) {
            this.data.question_list[this.index.question].question_url = uri;
          } else
            this.data.question_list[this.index.question].choice[
              this.index.choice
            ] = uri;
          this.onchangeUpdate();
          break;
        // case 'sound':
        //   if (this.resource[resourceType].isBgmSound) {
        //     this.data.bgm_uri = uri;
        //   }
        //   break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
    nextStep(currentStep, key) {
      if (currentStep === this.steps[key]) this.element[key] = 1;
      else this.element[key] = currentStep + 1;
    },
    reduceStep(currentStep, key) {
      if (currentStep === 1) this.element[key] = this.ChoiceMax;
      else this.element[key]--;
    },
    removeData(key, indexQuestion, indexChoice) {
      if (key === 'question_list') {
        if (this.data[key].length > 1) {
          this.data[key].splice(indexQuestion, 1);
          this.onchangeUpdate();
          if (this.element.question === 1) {
            this.steps.question--;
            this.element.question = 1;
            this.onClickStep(0);
          } else {
            this.steps.question--;
            this.element.question--;
            this.onClickStep(indexQuestion - 1);
          }
        }
      }
      // else {
      //   if (this.data.question_list[indexQuestion].choice.length > 1) {
      //     this.data.question_list[indexQuestion].choice.splice(indexChoice, 1);
      //     if (this.element.choice === 1) {
      //       this.steps.choice--;
      //       this.element.choice = 1;
      //     } else {
      //       this.steps.choice--;
      //       this.element.choice--;
      //     }
      //   }
      // }
    },
    addData(key, indexQuestion) {
      if (key === 'question_list') {
        if (this.data[key].length < this.questionMax) {
          const object = JSON.parse(
            JSON.stringify(this.QuizGameStore.defaultCreate.question_list[0]),
          );
          this.data[key].push(object);
          this.steps.question = this.data[key].length;
          this.onClickStep(indexQuestion + 1);
          this.element.question++;
          this.resizeStepperHeader(this.data[key].length);
        }
      }
      // else {
      //   if (
      //     this.data.question_list[indexQuestion].choice.length < this.questionMax
      //   ) {
      //     this.data.question_list[indexQuestion].choice.push('');
      //     this.onClickStep(indexQuestion, false);
      //     this.element.choice =
      //       this.data.question_list[indexQuestion].choice.length;
      //   }
      // }
    },
    resizeStepperHeader(dataLength) {
      if (dataLength > 5)
        document.getElementById('stepper-header').style.height = '145px';
    },
  },
  mounted() {
    this.element.question = 1;
    this.element.choice = 1;
    this.data = this.isCreate
      ? JSON.parse(JSON.stringify(this.QuizGameStore.createQuizGame))
      : JSON.parse(JSON.stringify(this.QuizGameStore.selectedQuizGame));
    this.intialStepper();
  },
};
</script>

<style></style>
