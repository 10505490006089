<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Learning Media Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" @click="createLearningMediaGroup">
            <v-icon>add</v-icon>
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="learningmedia in readState"
              :key="learningmedia.category_id"
              @click="() => onClickUpdate(learningmedia.category_id)"
            >
              <v-list-tile-avatar>
                <v-icon>import_contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ learningmedia.category }} ({{
                    learningmedia.groups.length
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at
                  {{ learningmedia.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeModalCreate"
      :isLastStep="valid"
      title="Create Group"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-autocomplete
                  chips
                  required
                  :rules="requiredRule"
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="learningmedia.category_id"
                ></v-autocomplete>
                <v-text-field
                  required
                  :rules="requiredRule"
                  label="Group Name"
                  v-model="learningmedia.group.name"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
  </Container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
export default {
  name: 'LearningMedia',
  components: { Container, Modal },
  data() {
    return {
      requiredRule: [v => !!v || 'This field is required'],
      valid: false,
      isCreate: false,
      isSelectCategory: false,
      learningmedia: {
        category_id: '',
        group: {
          name: '',
        },
      },
    };
  },
  computed: {
    ...mapState({
      learningMediaStore: state => state.learningmedia,
      createState: state => state.learningmedia.CREATE,
      readState: state => state.learningmedia.READ,
      categoryListStore: state => state.category.category,
    }),
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('learningmedia', [
      'setCreateState',
      'resetCreateState',
      'fetchLearningMedia',
      'createGroup',
      'setSelectedCategoryState',
      'setSelectedLearningMediaState',
    ]),
    async createLearningMediaGroup() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
    },
    onClickUpdate(id) {
      this.setSelectedLearningMediaState(id);
      this.setSelectedCategoryState(id);
      this.$router.push({
        path: '/learningmedia/detail',
        query: {
          id,
        },
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    closeModalCreate() {
      this.resetCreateState();
      this.reset();
      this.isCreate = false;
    },
    onSaveCreate() {
      this.isCreate = false;
      this.createGroup({
        category_id: this.learningmedia.category_id,
        ...this.learningmedia,
      });
    },
  },
  mounted() {
    this.fetchLearningMedia();
  },
};
</script>
