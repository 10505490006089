<template>
  <div>
    <v-btn block color="info" @click="openModalItem"> Level Manager </v-btn>
    <Modal
      v-if="isOpenItemModal"
      :isOpen="isOpenItemModal"
      :save="onSaveItem"
      :close="closeModalItem"
      :isLastStep="validate"
      :width="width"
      title="Level Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          disabled
          @click="increaseStep(name_list.level_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.level_manager_list">
          <v-stepper-header>
            <template v-for="n in step.level_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.level_manager_list > n"
                :step="n"
                editable
              >
                Level {{ n }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.level_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.level_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  disabled
                  @click="
                    reduceStep(
                      element.level_manager_list,
                      name_list.level_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <div>
                  <img
                    v-if="level[step - 1].background_uri"
                    :src="level[step - 1].background_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Background URI (URL or Select file)"
                    v-model="level[step - 1].background_uri"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      isImageList = true;
                      isSelectImage = true;
                    "
                  >
                    <v-icon>image</v-icon>Select Background Image
                  </v-btn>
                </div>
                <div>
                  <v-text-field
                    label="Background Music URI (URL or Select file)"
                    v-model="level[step - 1].bgm_uri"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      isSelectSound = true;
                      isUpdate = true;
                    "
                  >
                    <v-icon>audiotrack</v-icon>Select Background Music
                  </v-btn>
                  <v-form ref="form" v-model="validate" lazy-validation>
                    <v-text-field
                      type="number"
                      label="gem to pass"
                      v-model.number="level[step - 1].gem_collect"
                      :rules="[rules.numberRule]"
                    ></v-text-field>
                  </v-form>
                </div>
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.level_manager_list)"
              >
                Next
              </v-btn>
              <v-btn flat @click="backStep(step, name_list.level_manager_list)">
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>

    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeEditImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeEditSound"
      :save="saveSelectSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import ResourceSelect from '../../../../components/ResourceSelect.vue';
export default {
  name: 'UpdateLevel',
  components: {
    Modal,
    ResourceSelect,
  },
  computed: {},
  props: {
    level: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isUpdate: false,
      isSelectImage: false,
      isSelectSound: false,
      selectImageUri: '',
      selectSoundUri: '',
      maxLevel: 5,
      name_list: {
        level_manager_list: 'level_manager_list',
      },
      element: {
        level_manager_list: 1,
      },
      step: {
        level_manager_list: 1,
      },
      isOpenItemModal: false,
      width: '60%',
      rules: {
        required: value => !!value || 'Required',
        numberRule: value => {
          if (!isNaN(parseFloat(value)) && value >= -1) return true;
          return 'The number must be greater than equal -1';
        },
      },
      validate: true,
    };
  },
  methods: {
    saveSelectImage() {
      const uri = this.selectImageUri;
      if (this.isImageList) {
        const index = this.element.level_manager_list - 1;
        this.level[index].background_uri = uri;
      }
      this.selectImageUri = '';
      this.isImageList = false;
      this.isSelectImage = false;
    },
    closeEditImage() {
      this.isSelectImage = false;
      this.selectImageUri = '';
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectSoundUri;
      if (this.isUpdate) {
        const index = this.element.level_manager_list - 1;
        this.level[index].bgm_uri = uri;
      }
      this.selectSoundUri = '';
      this.isUpdate = false;
      this.isSelectSound = false;
    },
    closeEditSound() {
      this.isSelectSound = false;
      this.selectSoundUri = '';
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    increaseStep(list) {
      if (this.step[list] < this.maxLevel) {
        if (list === this.name_list.level_manager_list) {
          this.initialDefaultItem();
        }
        this.step[list] += 1;
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.level_manager_list) {
          this.step[list] -= 1;
          this.level.splice(index, 1);
        }
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },
    openModalItem() {
      this.isOpenItemModal = true;
      this.calculateItemStep();
    },
    calculateItemStep() {
      if (this.level.length === 0) {
        this.setDefaultItemByMaximumValue();
        this.step.level_manager_list = this.maxLevel;
      } else {
        this.step.level_manager_list = this.level.length;
      }
    },
    setDefaultItemByMaximumValue() {
      for (let index = 0; index < this.maxLevel; index++) {
        const formatObject = {
          background_uri: '',
          bgm_uri: '',
        };
        this.level.push(formatObject);
      }
    },
    initialDefaultItem() {
      const formatObject = {
        background_uri: '',
        bgm_uri: '',
      };
      this.level.push(formatObject);
    },
    onSaveItem() {
      this.isOpenItemModal = false;
    },
    closeModalItem() {
      this.isOpenItemModal = false;
    },
  },
};
</script>

<style></style>
