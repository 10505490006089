import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
const state = {
  EVENT_MESSAGE: [],
};
const getters = {};
const actions = {
  // TODO: fetch postbox event message
  fetchEventMessage({ commit }) {
    const queryparam = '?source=web_tool';
    http
      .GET(`/api/chat_system/event_message${queryparam}`, {
        isAuthorization: true,
      })
      .then(response => {
        const eventMessage = response.data.map(eventMsg => ({
          ...eventMsg,
          created_at: moment(eventMsg.created_at).format('YY/MM/DD h:mm A'),
          updated_at: moment(eventMsg.created_at).format('YY/MM/DD h:mm A'),
        }));
        commit('LOAD_EVENT_MESSAGE', eventMessage);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  // TODO: create postbox event message
  createEventMessage({ dispatch }, createObject) {
    http
      .POST(`/api/chat_system/event_message`, createObject, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: `Successfully created event message`,
          },
          {
            root: true,
          },
        );
        dispatch('fetchEventMessage');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  // TODO: update postbox event message
  updateEventMessage({ dispatch }, updateObject) {
    http
      .PATCH(`/api/chat_system/event_message`, updateObject, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: `Successfully updated event message`,
          },
          {
            root: true,
          },
        );
        dispatch('fetchEventMessage');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data,
          },
          {
            root: true,
          },
        );
      });
  },
  // TODO: delete postbox event message
  deleteEventMessage({ dispatch }, { _id, message }) {
    swal
      .fire({
        titleText: `Confirm Delete ?'${message}'`,
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'red',
        confirmButtonText: 'Remove',
        showCancelButton: true,
        type: 'warning',
      })
      .then(response => {
        if (response.value) {
          http
            .DELETE(`/api/chat_system/event_message?_id=${_id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'success',
                  message: 'Successfully deleted event message',
                },
                {
                  root: true,
                },
              );
              dispatch('fetchEventMessage');
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  type: 'error',
                  message: error.response.data,
                },
                {
                  root: true,
                },
              );
            });
        }
      });
  },
};
const mutations = {
  LOAD_EVENT_MESSAGE(state, payload) {
    state.EVENT_MESSAGE = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
