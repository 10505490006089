<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark tabs>
          <v-toolbar-title>Drag Combo Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="onclickCreateDragCombo()">
            <v-icon>add</v-icon>Create Drag Combo
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="data in dragComboStore.dragComboCategoryList"
              :key="data._id"
              @click="onClickEdit(data.category_id)"
            >
              <v-list-tile-avatar>
                <v-icon>import_contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ data.category }} ({{
                    data.number_of_game
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ data.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <!-- </v-list>
            <v-list-tile
              v-for="data in dragComboStore.dragComboCategoryList"
              :key="data._id"
              v-on:click="() => onClickEdit(data._id)"
            >
              <v-list-tile-avatar>
                <v-icon>drag_indicator</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ data.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list> -->
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onClickCreate"
      :close="closeCreate"
      :isLastStep="valid"
      title="Create Drag Combo"
    >
      <v-card>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout grid-list-xs column text-xs-center>
              <v-autocomplete
                chips
                required
                label="Select Category"
                :items="categoryList"
                item-text="category"
                item-value="_id"
                hide-details
                hide-no-data
                hide-selected
                :rules="[rules.required]"
                v-model="createData.category_id"
              ></v-autocomplete>
              <!-- <v-text-field
                label="title"
                v-model="createData.title"
                :rules="[rules.required]"
              ></v-text-field>
              <v-flex>
                <img
                  v-if="createData.background_uri"
                  class="image_thumbnail"
                  :src="createData.background_uri"
                />
                <v-text-field
                  label="Background Image URI"
                  :value="createData.background_uri"
                  v-model="createData.background_uri"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickEditImage()">
                  <v-icon>image</v-icon>Select Background Image</v-btn
                >
                <v-text-field
                  :value="createData.bgm_uri"
                  label="Background Music URI"
                  v-model="createData.bgm_uri"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickMusicSound(true)"
                  ><v-icon>audiotrack</v-icon>Select Background Music</v-btn
                >
                <v-text-field
                  :value="createData.introduction_voice_uri"
                  label="Introduction Voice URI"
                  v-model="createData.introduction_voice_uri"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickMusicSound()"
                  ><v-icon>audiotrack</v-icon>Select Introduction Voice</v-btn
                > 
              </v-flex>-->
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </Container>
</template>

<script>
import Container from '../../components/Container';
import { mapActions, mapState } from 'vuex';
import Modal from '../../components/Modal';
import rules from '../../utils/ruleValidate';
import ResourceSelect from '../../components/ResourceSelect.vue';
import swal from 'sweetalert2';

export default {
  components: {
    Container,
    Modal,
    ResourceSelect,
  },
  data() {
    return {
      rules,
      headers: [
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Updated_at', value: 'updated_at', sortable: false },
      ],
      isCreate: false,
      valid: true,
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectBgMusic: false,
      isSelectIntroMusic: false,
      isEditImage: false,
      selectImageUri: '',
      isSelectImageBtn: false,
      createData: {},
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
      categoryListStore: state => state.category.category,
    }),
    categoryList() {
      const categoryListStore = this.categoryListStore;
      const dragComboCategoryList = this.dragComboStore.dragComboCategoryList;
      const categoryList = categoryListStore.filter(elem => {
        return !dragComboCategoryList
          .map(({ category_id }) => category_id)
          .includes(elem._id);
      });
      return categoryList;
    },
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('dragcombo', [
      'fetchDragComboCategoryList',
      'createDragComboCategory',
      'setStateByKey',
    ]),
    async onclickCreateDragCombo() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
      // this.createData = { ...this.dragComboStore.defaultCreateGameDetail };
    },
    deleteGame(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            //delete
          }
        });
    },
    onclickEditImage(type) {
      this.isEditImage = true;
      this.isSelectImageBtn = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImageBtn:
          this.createData.background_uri = uri;
          this.isSelectImageBtn = false;
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onclickMusicSound(type) {
      this.isSelectSound = true;
      type ? (this.isSelectBgMusic = true) : (this.isSelectIntroMusic = true);
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectBgMusic:
          this.createData.bgm_uri = uri;
          this.isSelectBgMusic = false;
          break;
        case this.isSelectIntroMusic:
          this.createData.introduction_voice_uri = uri;
          this.isSelectIntroMusic = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onClickEdit(category_id) {
      this.$router.push(`/drag_combo/list?category_id=${category_id}`);
    },
    onClickCreate() {
      if (this.$refs.form.validate()) {
        let create = { ...this.createData };
        this.createDragComboCategory(create);
        this.isCreate = false;
      }
    },
    closeCreate() {
      this.isCreate = false;
    },
  },
  mounted() {
    this.fetchDragComboCategoryList({ page: 1, limit: 0 });
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.row-pointer:hover {
  cursor: pointer;
}
.tools {
  width: 10px;
}
</style>
