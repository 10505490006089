<template>
  <v-content>
    <v-checkbox
      :label="title"
      v-model="isToggleContainerSprite[currentStep]"
      @change="onchangeToggle()"
      v-if="type == 'dad_container_sprite' || 'book_sticker'"
    ></v-checkbox>
    <v-checkbox
      :label="title"
      :input-value="isToggle"
      v-on:change="e => onchangeToggle(e)"
      v-else
    ></v-checkbox>
    <v-layout
      row
      justify-center
      v-if="isToggle || isToggleContainerSprite[currentStep]"
    >
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn :color="colorBtn" dark v-on="on"
            >{{ fieldname }} : {{ originalSpriteSheet.column }}x{{
              originalSpriteSheet.row
            }}</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="title">
            <span class="headline">Edit Sprite Sheet Popup </span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Column"
                    :value="originalSpriteSheet.column"
                    type="number"
                    v-model.number="originalSpriteSheet.column"
                    @input="e => checkSum('column', e)"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Row"
                    :value="originalSpriteSheet.row"
                    type="number"
                    v-model.number="originalSpriteSheet.row"
                    @input="e => checkSum('row', e)"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Frame/Millisec"
                    :value="originalSpriteSheet.frame_ms"
                    type="number"
                    v-model.number="originalSpriteSheet.frame_ms"
                    @input="e => checkSum('frame_ms', e)"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Loop"
                    :value="originalSpriteSheet.loop_count"
                    type="number"
                    v-model.number="originalSpriteSheet.loop_count"
                    @input="e => checkSum('loop_count', e)"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="cancel">Cancel</v-btn>
            <v-btn color="blue darken-1" flat @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-content>
</template>

<script>
export default {
  name: 'SpriteSheet',
  data() {
    return {
      isClicked: false,
      dialog: false,
      originalSpriteSheet: {},
    };
  },
  props: {
    isToggle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Untitled',
      required: true,
    },
    fieldname: {
      type: String,
      default: 'Edit Sheet Data',
    },
    object: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    isToggleContainerSprite: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    colorBtn() {
      return this.title !== 'Use Container Sprite Sheet' ? 'black' : null;
    },
  },
  methods: {
    onchangeToggle() {
      this.$emit('onchangeSprite');
    },
    cancel() {
      this.originalSpriteSheet = { ...this.object };
      this.dialog = false;
    },
    save() {
      let data = {
        value: this.originalSpriteSheet,
      };
      switch (this.type) {
        case 'tts_sprite_sheet':
          data.key = 'sprite_sheet_data';
          this.$emit('saveToggleSpriteSheet', data);
          break;
        case 'tts_correct_sprite':
          data.key = 'correct_sprite_sheet_data';
          this.$emit('saveToggleSpriteSheet', data);
          break;
        case 'tts_multiple_media_list':
          data.key = 'multiple_media_list';
          data.index = this.currentStep;
          this.$emit('saveToggleSpriteSheet', data);
          break;
        case 'dad_container_sprite':
          data.key = 'container_sprite_sheet_data';
          data.index = this.currentStep;
          this.$emit('saveToggleSpriteSheet', data);
          break;
        case 'dad_choice_sprite':
          data.key = 'sprite_sheet_data';
          this.$emit('saveToggleSpriteSheet', data);
          break;
        case 'dad_drag_choice_sprite':
          data.key = 'drag_sprite_sheet_data';
          this.$emit('saveToggleSpriteSheet', data);
          break;
        case 'book_sticker':
          this.$emit('saveToggleSpriteSheet', data);
          break;
      }
      this.dialog = false;
    },
    checkSum(key, value) {
      if (value === '') {
        this.originalSpriteSheet[key] = 0;
      }
    },
  },
  created() {
    this.originalSpriteSheet = { ...this.object };
  },
  watch: {
    object(val) {
      this.originalSpriteSheet = { ...val };
    },
  },
};
</script>

<style scoped>
.title {
  justify-content: center;
}
</style>
