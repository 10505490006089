import moment from 'moment';
import http from '../../utils/httpClient';
import swal from 'sweetalert2';

const state = {
  codeList: [],
  isLoadCode: false,
  createCode: {
    type: '',
    amount: 1,
  },
};
const getters = {};
const actions = {
  fetchCodes({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('storeIsloadCode');
    http
      .GET(`/api/code?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(code => ({
          ...code,
          created_at: moment(code.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(code.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeCode', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch codes at this time. Please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        commit('storeIsloadCode');
      });
  },
  generateCode({ dispatch }, generateCode) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating code',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/code', generateCode, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchCodes', {});
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Generate Code complete',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteCode({ dispatch }, { id }) {
    swal
      .fire({
        titleText: 'Confirm Delete Code',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/code?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchCodes', {});
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
              throw new Error(err);
            });
        }
      });
  },
  editCreateCode({ commit }, { key, value }) {
    commit('storeCreateCode', {
      key,
      value,
    });
  },
};
const mutations = {
  storeCode(state, code) {
    state.codeList = code.map(res => ({
      ...res,
      expired_date: moment
        .unix(new Date(res.expired_date) * 0.001)
        .format('MMMM D YYYY h:mm a'),
      created_at: moment
        .unix(new Date(res.created_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
      updated_at: moment
        .unix(new Date(res.updated_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
    }));
  },
  storeIsloadCode(state) {
    state.isLoadCode = !state.isLoadCode;
  },
  storeCreateCode(state, { key, value }) {
    state.createCode = {
      ...state.createCode,
      [key]: value,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
