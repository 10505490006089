<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light @click="onClickBack()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (dragComboStore.selectedDragCombo &&
                dragComboStore.selectedDragCombo.title) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row grid-list-md>
            <GameDetail></GameDetail>
            <SetDetail v-if="dragComboStore.selectedSetIndex != -1"></SetDetail>
            <NodeDetail
              v-if="dragComboStore.selectedNodeIndex != -1"
            ></NodeDetail>
            <ChoiceDetail
              v-if="dragComboStore.selectedChoiceIndex != -1"
            ></ChoiceDetail>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </Container>
</template>

<script>
import Container from '../../components/Container';
import { mapActions, mapState } from 'vuex';
import Modal from '../../components/Modal';
import swal from 'sweetalert2';
import rules from '../../utils/ruleValidate';
import { GameDetail, SetDetail, NodeDetail, ChoiceDetail } from './components';

export default {
  components: {
    Container,
    GameDetail,
    SetDetail,
    NodeDetail,
    ChoiceDetail,
    // Modal,
  },
  data() {
    return {
      rules,
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
  },
  async created() {
    const selectGameData = this.dragComboStore.selectedDragCombo;
    if (Object.keys(selectGameData).length === 0) {
      if (this.$route.query.id || this.$route.query.sub_id) {
        const id = this.$route.query.id || this.$route.query.sub_id;
        this.selectedDragComboById(id);
      }
    }
  },
  methods: {
    ...mapActions('dragcombo', ['selectedDragComboById']),
    onClickBack() {
      if (this.$route.query.category_id)
        this.$router.push(
          '/drag_combo/list?category_id=' + this.$route.query.category_id,
        );
    },
  },
};
</script>

<style></style>
