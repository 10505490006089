import http from '../../utils/httpClient';
const state = {
  PAYMENT_STORE_DATA: [],
  IS_MAPPED: false,
};
const getters = {
  isMapLocalizedWordsStatus(state) {
    return state.IS_MAPPED;
  },
};
const actions = {
  async mapLocalizedWords({ commit, state }, localizedWords) {
    commit('MAP_LOCALIZED_WORDS');
    if (localizedWords) {
      await localizedWords.forEach(localizedItem => {
        state.PAYMENT_STORE_DATA.map(payment => {
          if (payment.title == localizedItem.key) {
            payment.localizedDisplayTitle = localizedItem.value;
          }
          if (payment.description == localizedItem.key) {
            payment.localizedDisplayDescription = localizedItem.value;
          }
          if (payment.monthlyBill == localizedItem.key) {
            payment.localizedDisplayMonthlyBill = localizedItem.value;
          }
          if (payment.price_tag == localizedItem.key) {
            payment.localizedDisplayPriceTag = localizedItem.value;
          }
        });
      });
      await commit('MAP_LOCALIZED_WORDS');
    }
  },
  fetchLocalizedWords({ dispatch }, toLocalizedObject) {
    // 1. filter unique item
    const uniqueTitle = [...new Set(toLocalizedObject.map(item => item.title))];
    const uniqueDescription = [
      ...new Set(toLocalizedObject.map(item => item.description)),
    ];
    const uniqueMonthlyBill = [
      ...new Set(toLocalizedObject.map(item => item.monthlyBill)),
    ];
    const uniquePriceTag = [
      ...new Set(toLocalizedObject.map(item => item.price_tag)),
    ];
    const cleanTitle = uniqueTitle.filter(item => item);
    const cleanDescription = uniqueDescription.filter(item => item);
    const cleanMonthlyBill = uniqueMonthlyBill.filter(item => item);
    const cleanPriceTag = uniquePriceTag.filter(item => item);
    const formatObject = {
      title: cleanTitle,
      description: cleanDescription,
      monthlyBill: cleanMonthlyBill,
      price_tag: cleanPriceTag,
    };
    // 2. create param builder
    const title = formatObject.title ? `&ID=${formatObject.title}` : '';
    const description = formatObject.description
      ? `&ID=${formatObject.description}`
      : '';
    const monthlyBill = formatObject.monthlyBill
      ? `&ID=${formatObject.monthlyBill}`
      : '';
    const price_tag = formatObject.price_tag
      ? `&ID=${formatObject.price_tag}`
      : '';
    const query = `language=English${title}${description}${monthlyBill}${price_tag}`;
    const formatQuery = query.replaceAll(',', '&ID=');
    // 3. call api
    dispatch('localization/fetchLocalizedWords', formatQuery, {
      root: true,
    });
  },
  fetchPaymentStore({ commit, dispatch }, selectedPlatform) {
    http
      .GET(`/api/payment_store_webtool/?platform=${selectedPlatform}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        let localizedList = [];
        data.map(element => {
          const toLocalizedObject = {
            title: element.title,
            description: element.description,
            monthlyBill: element.monthlyBill,
            price_tag: element.price_tag,
          };
          localizedList.push(toLocalizedObject);
        });
        dispatch('fetchLocalizedWords', localizedList);
        commit('SET_PAYMENT_STORE', data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  updatePaymentStore({ dispatch }, updateData) {
    http
      .PUT('/api/payment_store_webtool', updateData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully updated payment store',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Failed to update payment store', type: 'error' },
          { root: true },
        );
        throw new Error(error);
      });
  },
  resetPaymentStoreState({ commit }) {
    commit('RESET_PAYMENT_STORE_STATE');
  },
};
const mutations = {
  RESET_PAYMENT_STORE_STATE(state) {
    state.PAYMENT_STORE_DATA = [];
  },
  SET_PAYMENT_STORE(state, payload) {
    state.PAYMENT_STORE_DATA = payload;
  },
  MAP_LOCALIZED_WORDS(state) {
    state.IS_MAPPED = !state.IS_MAPPED;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
