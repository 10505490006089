<template>
  <Modal
    v-if="isOpen"
    :isOpen="isOpen"
    :save="save"
    :close="close"
    :isLastStep="valid"
    title="Create Home Topic Data"
  >
    <v-card>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-text-field
                :value="createData.title"
                v-model="createData.title"
                label="title"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
            <v-card>
              <v-toolbar dark>
                <v-toolbar-title>Add Home Group Data</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="green" @click="addGroupData()">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-toolbar>
            </v-card>
            <v-card-text>
              <v-data-table
                v-if="groupHome.length"
                :headers="headers"
                :items="groupHome"
                class="elevation-1"
                hide-actions
              >
                <template v-slot:items="props">
                  <td class="text-xs-left">
                    {{ props.index }}
                  </td>
                  <td>
                    <v-text-field
                      :value="groupHome[props.index].title"
                      v-model="groupHome[props.index].title"
                      label="title"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-btn
                      color="error"
                      @click="deleteGroup(props.index)"
                      small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
              <!-- <v-layout grid-list-xs>
                <v-flex>
                  {{ index }}
                </v-flex>
                <v-flex>
                  <v-text-field
                    :value="groupHome[index].title"
                    v-model="groupHome[index].title"
                    label="title"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
            </v-card-text>
          </v-layout>
        </v-form>
      </v-container>
    </v-card>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import ruleValidate from '../../../utils/ruleValidate';

export default {
  components: { Modal },
  data() {
    return {
      valid: true,
      rules: ruleValidate,
      createData: {},
      groupHome: [],
      headers: [
        { text: 'Index', value: 'index', sortable: false, width: '10px' },
        { text: 'Title', value: 'title', sortable: false },
      ],
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
    }),
  },
  methods: {
    ...mapActions('homepage', ['createPageTopic']),
    save() {
      this.createData = { ...this.createData, group_assign: this.groupHome };
      this.createPageTopic(this.createData);
      this.createData = {};
      this.groupHome = [];
      this.close();
    },
    close() {
      this.$emit('close');
      this.createData = {};
      this.groupHome = [];
    },
    addGroupData() {
      const defaultGroupData = JSON.parse(
        JSON.stringify(this.pageGameStore.defaultCreateHomeDataList),
      );
      delete defaultGroupData.topic_id;
      this.groupHome.push({ ...defaultGroupData });
    },
    deleteGroup(index) {
      this.groupHome.splice(index, 1);
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.createData = {
          ...this.pageGameStore.defaultCreateHomeDataTopic,
        };
      }
    },
  },
};
</script>

<style></style>
