import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  connectingnode: [],
  selectConnectingNode: {},
  selectedNodeIndex: -1,
  selectedCategoryId: '',
  updateChoiceData: {},
  updateNodeData: {},
  isUpdating: false,
  addChoiceData: [
    {
      match_key: 1,
    },
    {
      match_key: 1,
    },
  ],
  addNodeData: {
    match_count: 1,
  },
};
const getters = {};
const actions = {
  fetchConnectingNode({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/connectingnode?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(cn => ({
          ...cn,
          created_at: moment(cn.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(cn.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeConnectingNode', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch connectingnode in this time please type again',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectConnectingNode({ commit }, id) {
    commit('storeSelectConnectingNode', { id, sub_id: '' });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleConnectingNode({ commit, dispatch }, { id, sub_id }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/connectingnode?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(connectingnode => ({
          ...connectingnode,
          created_at: moment(connectingnode.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(connectingnode.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeConnectingNode', formatData);
        commit('storeSelectConnectingNode', { id, sub_id });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch connectingnode at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  updateChoiceData({ commit }, { key, value }) {
    commit('storeUpdateChoiceData', {
      key,
      value,
    });
  },
  updateNodeData({ commit }, { key, value }) {
    commit('storeUpdateNodeData', {
      key,
      value,
    });
  },
  updateNode({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/connectingnode/node',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Node Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleConnectingNode', {
            id: state.selectConnectingNode.category_id,
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update node. Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
          throw new Error(err);
        });
    }
  },
  updateChoice({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/choice',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Choice Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleConnectingNode', {
            id: state.selectConnectingNode.category_id,
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update card. Please Try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
          throw new Error(err);
        });
    }
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdateData');
  },

  deleteNode({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Node',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/connectingnode/node?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleConnectingNode', {
                id: state.selectConnectingNode.category_id,
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
              throw new Error(err);
            });
        }
      });
  },
  deleteChoice({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Node',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/choice?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleConnectingNode', {
                id: state.selectConnectingNode.category_id,
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
              throw new Error(err);
            });
        }
      });
  },
  updateCreateChoiceData({ commit }, { key, value, index }) {
    commit('storeCreateChoiceData', {
      key,
      value,
      index,
    });
  },
  updateCreateSingleChoiceData({ commit }, { key, value }) {
    commit('storeCreateSingleChoiceData', {
      key,
      value,
    });
  },
  updateCreateNodeData({ commit }, { key, value }) {
    commit('storeCreateNodeData', {
      key,
      value,
    });
  },
  addNode({ dispatch }, { category_id, ...data }) {
    const createNodeData = {
      category_id,
      node: {
        ...data.node,
      },
      left_nodes: [{ ...data.choices[0] }],
      right_nodes: [{ ...data.choices[1] }],
    };
    http
      .POST('/api/connectingnode', createNodeData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Node success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleConnectingNode', { id: category_id });
        router.go({
          path: '/connectingnode/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Node failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  addChoiceByNodeId({ dispatch }, { category_id, node_id, side, choice }) {
    const createCardData = {
      _id: node_id,
    };
    if (side == 'Left') {
      createCardData.left_nodes = [{ ...choice }];
    } else {
      createCardData.right_nodes = [{ ...choice }];
    }
    http
      .POST('/api/connectingnode/node', createCardData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Choice success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleConnectingNode', { id: category_id });
        router.go({
          path: '/connectingnode/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Add Choice failed',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeConnectingNode(state, connectingnodeList) {
    state.connectingnode = connectingnodeList;
  },
  storeSelectConnectingNode(state, { id, sub_id }) {
    if (id) {
      const index = state.connectingnode.findIndex(
        connectingnode => connectingnode.category_id === id,
      );
      state.selectConnectingNode = state.connectingnode[index];
      if (sub_id) {
        const nodeIndex = state.selectConnectingNode.nodes.findIndex(
          node => node._id === sub_id,
        );
        state.selectedNodeIndex = nodeIndex;
      }
    } else {
      delete state.selectConnectingNode;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateChoiceData(state, { key, value }) {
    if (value || value == 0 || value == false) {
      state.updateChoiceData = {
        ...state.updateChoiceData,
        [key]: value,
      };
    } else {
      delete state.updateChoiceData[key];
    }
  },
  storeCreateChoiceData(state, { key, value, index }) {
    if (value || value == 0 || value == false || value == '') {
      state.addChoiceData[index] = {
        ...state.addChoiceData[index],
        [key]: value,
      };
    } else {
      delete state.addChoiceData[index][key];
    }
  },
  storeCreateSingleChoiceData(state, { key, value }) {
    if (value || value == 0 || value == false) {
      state.addSingleChoiceData = {
        ...state.addSingleChoiceData,
        [key]: value,
      };
    } else {
      delete state.addSingleChoiceData[key];
    }
  },
  storeUpdateNodeData(state, { key, value }) {
    state.updateNodeData = {
      ...state.updateNodeData,
      [key]: value,
    };
  },
  storeCreateNodeData(state, { key, value }) {
    if (value || value == 0) {
      state.addNodeData = {
        ...state.addNodeData,
        [key]: value,
      };
    } else {
      delete state.addNodeData[key];
    }
  },
  clearStoreUpdateData(state) {
    Object.keys(state.updateChoiceData).forEach(key => {
      if (key != '_id') {
        delete state.updateChoiceData[key];
      }
    });
    Object.keys(state.updateNodeData).forEach(key => {
      if (key != '_id') {
        delete state.updateNodeData[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
