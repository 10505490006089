<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Shop Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <!-- <v-btn color="success" v-on:click="onClickAddItem">
            <v-icon>add</v-icon>Add Item
          </v-btn> -->
        </v-toolbar>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="itemStore"
            :search="search"
            :pagination.sync="pagination"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item.name }}</td>
              <td class="text-xs-center">{{ props.item.item_code }}</td>
              <td class="text-xs-center">{{ props.item.price[0].amount }}</td>
              <td class="text-xs-center">{{ props.item.limit_buy }}</td>
              <td class="text-xs-center">{{ props.item.isPremium }}</td>

              <!-- <td class="text-xs-center">
                <v-img
                  v-if="props.item.images"
                  lazy="props.item.images[0]"
                  class="image_thumbnail"
                  :src="props.item.images[0]"
                ></v-img>
              </td> -->

              <td class="text-xs-center">{{ props.item.created_at }}</td>
              <td class="justify-center layout px-0">
                <v-icon small class="mr-2" @click="onClickEditItem(props.item)"
                  >edit</v-icon
                >
                <!-- <v-icon small @click="onClickDeleteItem(props.item._id)"
                  >delete</v-icon
                > -->
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-flex>
    <!-- Add Modal -->
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeCreate"
      title="Add Item"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-text-field
                label="Name"
                :value="item.name"
                v-model="item.name"
                @change="v => onChangeAddItem('name', v)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-select
                @change="v => onChangeAddItem('type', v)"
                :items="constant.TYPE"
                :value="item.type"
                v-model="item.type"
                label="Type"
              ></v-select>
            </v-flex>
            <v-flex>
              <v-textarea
                label="Description"
                :value="item.description"
                v-model="item.description"
                :clearable="clearable"
                @change="v => onChangeAddItem('description', v)"
              ></v-textarea>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Jewel"
                :value="item.price[0].amount"
                v-model="item.price[0].amount"
                @change="v => onChangeAddItemPrice('price', v, 0)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Amount"
                :value="item.amount"
                v-model="item.amount"
                @change="v => onChangeAddItem('amount', v)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-checkbox
                label="Is Consumable"
                v-model="item.isConsumable"
                :value="item.isConsumable"
                @change="v => onChangeAddItem('isConsumable', v)"
              >
              </v-checkbox>
            </v-flex>
            <v-flex>
              <v-checkbox
                label="Is Premium"
                v-model="item.isPremium"
                :value="item.isPremium"
                @change="v => onChangeAddItem('isPremium', v)"
              >
              </v-checkbox>
            </v-flex>
            <v-flex>
              <h3>Images:</h3>
              <v-flex v-for="(image, index) in item.images" :key="index">
                <v-btn
                  v-on:click="
                    isAddImage = true;
                    isEditImageIndex = index;
                  "
                  >Select Image {{ index + 1 }}</v-btn
                >
                <br />
                <img
                  v-if="image"
                  :src="image"
                  :alt="image"
                  class="content_img"
                />
                <v-text-field :value="image" disabled>
                  <template v-slot:label>Image {{ index + 1 }}</template>
                </v-text-field>
              </v-flex>
              <v-container>
                <v-card-actions>
                  <v-btn color="red" @click="onDeleteImage">
                    <v-icon>remove</v-icon>Remove Image
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onAddImage">
                    <v-icon>add</v-icon>Add Image
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-flex>
          </v-layout>
          <ResourceSelect
            v-if="isAddImage"
            resourceType="image"
            :isShow="isAddImage"
            :close="closeAddImage"
            :save="saveImage"
            :value="selectImageUri"
            :onSelect="onSelectImage"
          />
        </v-container>
      </v-card>
    </Modal>
    <!-- Edit Modal -->
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onSaveEdit"
      :close="onCloseEdit"
      title="Edit Item"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-text-field
                label="Name"
                :value="item.name"
                v-model="item.name"
                @change="v => onChangeEditItem('name', v)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Item Code"
                :value="item.item_code"
                v-model="item.item_code"
                @change="v => onChangeEditItem('item_code', v)"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-textarea
                label="Description"
                :value="item.description"
                v-model="item.description"
                :clearable="clearable"
                @change="v => onChangeEditItem('description', v)"
              ></v-textarea>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Price: Jewel"
                :value="item.price[0].amount"
                v-model="item.price[0].amount"
                @change="v => onChangeEditItemPrice('price', parseInt(v), 0)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Limit Buy"
                :value="item.limit_buy"
                v-model="item.limit_buy"
                @change="v => onChangeEditItem('limit_buy', parseInt(v))"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-checkbox
                label="Is Premium"
                v-model="item.isPremium"
                :value="item.isPremium"
                @change="v => onChangeEditItem('isPremium', v)"
              >
              </v-checkbox>
            </v-flex>
            <!-- <v-flex>
              <h3>Images:</h3>
              <v-flex v-for="(image, index) in item.images" :key="index">
                <v-btn
                  v-on:click="
                    isEditImage = true;
                    isEditImageIndex = index;
                  "
                  >Select Image {{ index + 1 }}</v-btn
                >
                <br />
                <img
                  v-if="image"
                  :src="image"
                  :alt="image"
                  class="content_img"
                />
                <v-text-field :value="image" disabled>
                  <template v-slot:label>Image {{ index + 1 }}</template>
                </v-text-field>
              </v-flex>
              <v-container>
                <v-card-actions>
                  <v-btn color="red" @click="onDeleteImage">
                    <v-icon>remove</v-icon>Remove Image
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onAddImage">
                    <v-icon>add</v-icon>Add Image
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-flex> -->
          </v-layout>
          <ResourceSelect
            v-if="isEditImage"
            resourceType="image"
            :isShow="isEditImage"
            :close="closeEditImage"
            :save="saveImage"
            :value="selectImageUri"
            :onSelect="onSelectImage"
          />
        </v-container>
      </v-card>
    </Modal>
  </Container>
</template>

<style scoped>
.image_thumbnail {
  height: 50px;
  width: 50px;
  margin-top: 0px;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
import ResourceSelect from '../../components/ResourceSelect';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import constant from './constants';
export default {
  name: 'Item',
  components: {
    ResourceSelect,
    Container,
    Modal,
  },
  computed: {
    ...mapState({
      itemStore: state => state.shop.item,
      createdItemStore: state => state.shop.createItem,
      updatedItemStore: state => state.shop.updateItem,
    }),
  },
  data: function () {
    return {
      headers: [
        { text: 'Name', value: 'name', align: 'left', sortable: true },
        {
          text: 'Item Code',
          value: 'item_code',
          align: 'center',
          sortable: true,
        },
        { text: 'Jewel', value: 'jewel', align: 'center', sortable: true },
        {
          text: 'Limit Buy',
          value: 'limit_buy',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Premium',
          value: 'isPremium',
          align: 'center',
          sortable: true,
        },
        // { text: 'Image', value: 'images', sortable: true },
        {
          text: 'Created Date',
          value: 'created_at',
          align: 'center',
          sortable: true,
        },
        { text: 'Action', value: 'action', sortable: false, align: 'center' },
      ],
      pagination: {
        rowsPerPage: 25,
      },
      search: '',
      constant,
      clearable: true,
      isCreate: false,
      isEdit: false,
      isAddImage: false,
      isEditImage: false,
      isEditImageIndex: -1,
      item: {},
      defaultItem: {
        images: [''],
        price: [{ name: 'jewel', amount: 0 }],
        limit_buy: 5,
        isPremium: true,
      },
      nowDate: new Date().toISOString().slice(0, 10),
      menu: false,
      selectImageUri: '',
      timestamp: null,
      editedIndex: -1,
    };
  },
  watch: {},
  methods: {
    ...mapActions('shop', [
      'fetchItem',
      'addItem',
      'editItem',
      'deleteItem',
      'stateCreateItem',
      'stateEditItem',
      'clearCreateState',
      'clearUpdateState',
    ]),
    onClickEditItem(item) {
      this.stateEditItem({ key: '_id', value: item._id });
      this.isEdit = true;
      this.editedIndex = this.itemStore.indexOf(item);
      this.item = Object.assign({}, item);
    },
    onSaveEdit() {
      this.editItem(this.updatedItemStore);
      this.onCloseEdit();
    },
    onCloseEdit() {
      this.clearUpdateState();
      this.isEdit = false;
    },
    onClickDeleteItem(id) {
      this.deleteItem(id);
    },
    onClickAddItem() {
      this.isCreate = true;
      Object.keys(this.defaultItem).forEach(key => {
        this.item[key] = this.defaultItem[key];
      });
      Object.keys(this.item).forEach(key => {
        this.stateCreateItem({ key, value: this.item[key] });
      });
    },
    onChangeEditItem(key, value) {
      this.stateEditItem({ key, value });
    },
    onChangeEditItemPrice(key, value, index) {
      this.item.price[index].amount = value;
      this.stateEditItem({ key, value: this.item.price });
    },
    onChangeAddItem(key, value) {
      this.stateCreateItem({ key, value });
    },
    onChangeAddItemPrice(key, value, index) {
      this.item.price[index].amount = value;
      this.stateCreateItem({ key, value: this.item.price });
    },
    onSaveCreate() {
      this.addItem(this.createdItemStore);
      this.isCreate = false;
    },
    closeCreate() {
      this.isCreate = false;
      this.clearCreateState();
    },
    closeAddImage() {
      this.selectImageUri = '';
      this.isAddImage = !this.isAddImage;
      this.isEditImageIndex = -1;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
      this.isEditImageIndex = -1;
    },
    onAddImage() {
      this.item.images = [...this.item.images, ''];
    },
    onDeleteImage() {
      this.item.images.pop();
      if (this.isCreate) {
        this.stateCreateItem({
          key: 'images',
          value: this.item.images,
        });
      } else if (this.isEdit) {
        this.stateEditItem({
          key: 'images',
          value: this.item.images,
        });
      }
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveImage() {
      const uri = this.selectImageUri;
      if (this.isAddImage) {
        this.item.images[this.isEditImageIndex] = uri;
        this.stateCreateItem({
          key: 'images',
          value: this.item.images,
        });
        this.isAddImage = !this.isAddImage;
      } else if (this.isEditImage) {
        this.item.images[this.isEditImageIndex] = uri;
        this.stateEditItem({
          key: 'images',
          value: this.item.images,
        });
        this.isEditImage = !this.isEditImage;
      }
      this.item.image_uri = uri;
      this.selectImageUri = '';
      this.isEditImageIndex = -1;
    },
  },
  mounted() {
    this.fetchItem();
  },
};
</script>
