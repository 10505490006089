<template>
  <div>
    <v-select
      :items="FilterType"
      item-text="name"
      item-value="value"
      :value="filterData.type"
      v-model="filterData.type"
      label="Type"
      @change="e => onchangeFilterDetail('type', e)"
    ></v-select>
    <div v-if="filterData.type === 0 || filterData.type === 2">
      <!-- Basic -->
      <v-layout row>
        <v-text-field
          type="number"
          label="Min X"
          :value="filterData.anchor.min.x"
          v-model="filterData.anchor.min.x"
          @change="e => onchangeFilterDetail('anchor', e)"
          :disabled="filterData.type === 2"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Min Y"
          :value="filterData.anchor.min.y"
          v-model="filterData.anchor.min.y"
          @change="e => onchangeFilterDetail('anchor', e)"
          :disabled="filterData.type === 2"
        ></v-text-field>
      </v-layout>
      <v-layout row>
        <v-text-field
          type="number"
          label="Max X"
          :value="filterData.anchor.max.x"
          v-model="filterData.anchor.max.x"
          @change="e => onchangeFilterDetail('anchor', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Max Y"
          :value="filterData.anchor.max.y"
          v-model="filterData.anchor.max.y"
          @change="e => onchangeFilterDetail('anchor', e)"
        ></v-text-field>
      </v-layout>
    </div>
    <div v-if="filterData.type === 2">
      <!-- FaceNPoint -->
      <v-select
        v-if="filterData.action_type === 3"
        :items="FacePosition"
        item-text="name"
        item-value="value"
        :value="filterData.start_face_point_index"
        v-model="filterData.start_face_point_index"
        label="Start Face Index"
        @change="e => onchangeFilterDetail('start_face_point_index', e)"
      ></v-select>
      <v-layout row v-if="filterData.action_type !== 3">
        <v-text-field
          type="number"
          label="Point X"
          :value="filterData.point.x"
          v-model="filterData.point.x"
          @change="e => onchangeFilterDetail('point', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Point Y"
          :value="filterData.point.y"
          v-model="filterData.point.y"
          @change="e => onchangeFilterDetail('point', e)"
        ></v-text-field>
      </v-layout>
      <div v-if="filterData.action_type === 2">
        <div class="line"></div>
        <div style="display: inline-block"><h3>to</h3></div>
        <div class="line"></div>
        <v-layout row>
          <v-text-field
            type="number"
            label="Point X"
            :value="filterData.end_point.x"
            v-model="filterData.end_point.x"
            @change="e => onchangeFilterDetail('end_point', e)"
          ></v-text-field>
          <v-text-field
            type="number"
            label="Point Y"
            :value="filterData.end_point.y"
            v-model="filterData.end_point.y"
            @change="e => onchangeFilterDetail('end_point', e)"
          ></v-text-field>
        </v-layout>
      </div>
      <v-select
        :items="FaceNPointType"
        item-text="name"
        item-value="value"
        :value="filterData.action_type"
        v-model.number="filterData.action_type"
        label="Type"
        @change="e => onchangeFilterDetail('action_type', parseInt(e))"
      ></v-select>
      <v-text-field
        type="number"
        label="Duration Transition (ms)"
        :value="filterData.duration_ms_transition"
        @change="
          e => onchangeFilterDetail('duration_ms_transition', parseInt(e))
        "
      ></v-text-field>
      <v-layout row>
        <v-text-field
          type="number"
          label="Start Scale"
          :value="filterData.start_scale"
          @change="e => onchangeFilterDetail('start_scale', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="End Scale"
          :value="filterData.end_scale"
          @change="e => onchangeFilterDetail('end_scale', e)"
        ></v-text-field>
      </v-layout>
    </div>
    <div v-if="filterData.type === 3 || filterData.type === 4">
      <!-- <v-layout row> -->
      <v-text-field
        type="number"
        label="Left anchor min X"
        :value="filterData.left_anchor_min.x"
        v-model="filterData.left_anchor_min.x"
        @change="e => onchangeFilterDetail('left_anchor_min', e)"
      ></v-text-field>
      <v-text-field
        type="number"
        label="Left anchor min Y"
        :value="filterData.left_anchor_min.y"
        v-model="filterData.left_anchor_min.y"
        @change="e => onchangeFilterDetail('left_anchor_min', e)"
      ></v-text-field>
      <v-text-field
        type="number"
        label="Right anchor min X"
        :value="filterData.right_anchor_min.x"
        v-model="filterData.right_anchor_min.x"
        @change="e => onchangeFilterDetail('right_anchor_min', e)"
      ></v-text-field>
      <v-text-field
        type="number"
        label="Right anchor min Y"
        :value="filterData.right_anchor_min.y"
        v-model="filterData.right_anchor_min.y"
        @change="e => onchangeFilterDetail('right_anchor_min', e)"
      ></v-text-field>
      <!-- </v-layout> -->
    </div>
    <div v-if="filterData.type === 5">
      <!-- Particle -->
      <v-checkbox
        :input-value="filterData.is_loop"
        label="Is Loop"
        @change="e => onchangeFilterDetail('is_loop', e)"
      ></v-checkbox>
      <v-select
        :items="PositionModePaticle"
        item-text="name"
        item-value="value"
        :value="filterData.position_mode"
        v-model="filterData.position_mode"
        label="Position Mode"
        v-on:change="e => onchangeFilterDetail('position_mode', e)"
      ></v-select>
      <v-layout row v-if="filterData.position_mode === 1">
        <v-text-field
          type="number"
          label="Point X"
          :value="filterData.point.x"
          v-model="filterData.point.x"
          @change="e => onchangeFilterDetail('point', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Point Y"
          :value="filterData.point.y"
          v-model="filterData.point.y"
          @change="e => onchangeFilterDetail('point', e)"
        ></v-text-field>
      </v-layout>
      <v-text-field
        type="number"
        label="Angle"
        :value="filterData.angle"
        @change="e => onchangeFilterDetail('angle', e)"
      ></v-text-field>
      <v-select
        :items="ShapeType"
        item-text="name"
        item-value="value"
        :value="filterData.shape_type"
        v-model="filterData.shape_type"
        label="Shape Type"
        @change="e => onchangeFilterDetail('shape_type', e)"
      ></v-select>
      <v-layout row>
        <v-text-field
          label="Color"
          :value="filterData.color"
          @change="e => onchangeFilterDetail('color', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Rate"
          :value="filterData.rate"
          @change="e => onchangeFilterDetail('rate', e)"
          :rules="[rules.isDecimal]"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Max"
          :value="filterData.max"
          @change="e => onchangeFilterDetail('max', parseInt(e))"
          :rules="[rules.isDecimal]"
        ></v-text-field>
      </v-layout>
      <v-layout row>
        <v-text-field
          type="number"
          label="Life Time (ms)"
          :value="filterData.life_time"
          @change="e => onchangeFilterDetail('life_time', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Speed"
          :value="filterData.speed"
          @change="e => onchangeFilterDetail('speed', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Play Duration (ms)"
          :value="filterData.play_duration_ms"
          @change="e => onchangeFilterDetail('play_duration_ms', parseInt(e))"
        ></v-text-field>
      </v-layout>
      <v-layout row>
        <v-text-field
          type="number"
          label="Scale A"
          :value="filterData.size_over_life_time_scale_a"
          @change="e => onchangeFilterDetail('size_over_life_time_scale_a', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Scale B"
          :value="filterData.size_over_life_time_scale_b"
          @change="e => onchangeFilterDetail('size_over_life_time_scale_b', e)"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Rotate Speed"
          :value="filterData.rotate_speed"
          @change="e => onchangeFilterDetail('rotate_speed', e)"
        ></v-text-field>
      </v-layout>
    </div>
  </div>
</template>

<script>
import constant from '../constant';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      FilterType: constant.FilterType,
      ShapeType: constant.ShapeType,
      FaceNPointType: constant.FaceNPointType,
      FacePosition: constant.FacePosition,
      PositionModePaticle: constant.PositionModePaticle,
      rules: constant.rule,
    };
  },
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    ...mapActions('short_story', ['updateFilterStore', 'setDirty']),
    setDefaultFilterData(key, value) {
      let defaultData = {};
      const {
        Basic,
        SnapFace,
        FaceNPoint,
        FaceCap,
        Sunglasses,
        Particle,
        CoverFace,
        Scale,
      } = this.shortStoryStore;
      if (key === 'action_type') {
        if (value === 2) defaultData = FaceNPoint;
      } else {
        if (value === 0) defaultData = Basic;
        if (value === 1) defaultData = SnapFace;
        if (value === 2) defaultData = FaceNPoint;
        if (value === 3) defaultData = FaceCap;
        if (value === 4) defaultData = Sunglasses;
        if (value === 5) defaultData = Particle;
        if (value === 6) defaultData = CoverFace;
        if (value === 7) defaultData = Scale;
      }
      for (const [key, value] of Object.entries(defaultData)) {
        if (!(key in this.filterData)) {
          this.filterData[key] = value;
          this.updateFilterStore({ key, value });
        }
      }
    },
    onchangeFilterDetail(key, value) {
      if (key === 'type' || key === 'action_type')
        this.setDefaultFilterData(key, value);
      if (
        key === 'point' ||
        key === 'end_point' ||
        key === 'right_anchor_min' ||
        key === 'left_anchor_min' ||
        key === 'anchor'
      )
        value = this.filterData[key];
      this.updateFilterStore({ key, value });
      this.setDirty('filter');
    },
  },
};
</script>

<style scoped>
.line {
  width: 41%;
  height: 2px;
  border: 1px solid #c4c4c4;
  margin: 3px 15px;
  display: inline-block;
}
</style>
