import http from '../../utils/httpClient';
import Swal from 'sweetalert2';

const state = {
  isProcessing: false,
  doneBackupDevelopment: false,
  doneBackupProduction: false,
  doneBackupDevelopmentWithTimestamp: false,
  doneBackupProductionWithTimestamp: false,
  doneRestoreDevelopmentToProduction: false,
  userConfig: {},
  UNITS: [],
  SERVER_STATUS: false,
};
const getters = {};
const actions = {
  resetMigrationStatus({ commit, dispatch }) {
    commit('RESET_MIGRATION_STATUS');
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Reset Migration Status',
      },
      {
        root: true,
      },
    );
  },
  setUserConfig(
    { commit, dispatch },
    { username, password, isFilterLearningPathOut },
  ) {
    let credentialObject = {
      username: username,
      password: password,
      isFilterLearningPathOut: isFilterLearningPathOut,
    };
    commit('SET_USER_CONFIG', credentialObject);
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Setup User Configuration',
      },
      {
        root: true,
      },
    );
  },
  removeUserConfig({ commit }) {
    commit('RESET_USER_CONFIG');
  },
  updateServerStatus({ commit, dispatch }, updateData) {
    commit('IS_PROCESSING');
    http
      .PUT('/api/server_status/', updateData, { isAuthorization: true })
      .then(() => {
        commit('IS_PROCESSING');
        dispatch('getServerStatus');
      })
      .catch(error => {
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              error.response.data.error +
                ' Please re-login to use this feature' ||
              'Server Status is unavailable right now, please try again',
          },
          {
            root: true,
          },
        );
        throw new Error(error);
      });
  },
  getServerStatus({ commit, dispatch }) {
    commit('IS_PROCESSING');
    http
      .GET('/api/server_status/info', { isAuthorization: true })
      .then(response => {
        commit('IS_PROCESSING');
        const statusObject = {
          status: response.data[0].status,
          _id: response.data[0]._id,
        };
        commit('SET_SERVER_STATUS', statusObject);
      })
      .catch(error => {
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              error.response.data.error +
                ' Please re-login to use this feature' ||
              'Server Status is unavailable right now, please try again',
          },
          {
            root: true,
          },
        );
        throw new Error(error);
      });
  },
  getUnitList({ commit, dispatch }) {
    http
      .GET('/api/backup/units', { isAuthorization: true })
      .then(response => {
        commit('SET_CURRENT_UNIT_LIST', response.data);
      })
      .catch(() => {
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Unit list is unavailable right now, please try again',
          },
          {
            root: true,
          },
        );
      });
  },
  moveOnlyDataLearningPath(
    { commit, dispatch },
    { username, password, selectedUnitIds },
  ) {
    commit('IS_PROCESSING');
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Start Migrating Learning Path...',
      },
      {
        root: true,
      },
    );
    http
      .POST(
        '/api/backup/onlyLearningPath',
        {
          username: username,
          password: password,
          selectedUnitIds: selectedUnitIds,
        },
        { isAuthorization: true },
      )
      .then(response => {
        commit('IS_PROCESSING');
        Swal.fire(
          'Success',
          'Successfully migrated learning path data (3 collections) Units, Lessons, and Activities',
          'success',
        );
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message:
              'Successfully migrated learning path data (3 collections) Units, Lessons, and Activities',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        commit('IS_PROCESSING');
        Swal.fire('Failed', 'Failed on migrating learning path data', 'error');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Failed on migrating learning path data' ||
              err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  restoreDevelopmentToProductionDatabase(
    { commit, dispatch },
    { username, password, isFilterLearningPathOut },
  ) {
    commit('IS_PROCESSING');
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Start Migrate Development to Production Database...',
      },
      {
        root: true,
      },
    );
    http
      .POST(
        '/api/backup/restoreDevToProd',
        {
          username: username,
          password: password,
          isFilterLearningPathOut: isFilterLearningPathOut,
        },
        { isAuthorization: true },
      )
      .then(response => {
        commit('DONE_RESTORE_DEVELOPMENT_TO_PRODUCTION');
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message:
              'Successfully migrated development to production database ',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Failed migrated development to production database' ||
              err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  backupDevelopmentDatabaseWithTimestamp(
    { commit, dispatch },
    { username, password, isFilterLearningPathOut },
  ) {
    commit('IS_PROCESSING');
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Start Backup Development Database with timestamp...',
      },
      {
        root: true,
      },
    );
    http
      .POST(
        '/api/backup/backupDevWithTimestamp',
        {
          username: username,
          password: password,
          isFilterLearningPathOut: isFilterLearningPathOut,
        },
        { isAuthorization: true },
      )
      .then(response => {
        commit('DONE_BACKUP_DEVELOPMENT_WITH_TIMESTAMP');
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Successfully backup development database with timestamp',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Failed backup development database' || err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  backupProductionDatabaseWithTimestamp(
    { commit, dispatch },
    { username, password, isFilterLearningPathOut },
  ) {
    commit('IS_PROCESSING');
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Start Backup Production Database with timestamp...',
      },
      {
        root: true,
      },
    );
    http
      .POST(
        '/api/backup/backupProdWithTimestamp',
        {
          username: username,
          password: password,
          isFilterLearningPathOut: isFilterLearningPathOut,
        },
        { isAuthorization: true },
      )
      .then(response => {
        commit('DONE_BACKUP_PRODUCTION_WITH_TIMESTAMP');
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Successfully backup production database with timestamp',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Failed backup production database' || err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  backupProductionDatabase(
    { commit, dispatch },
    { username, password, isFilterLearningPathOut },
  ) {
    commit('IS_PROCESSING');
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Start Backup Production Database...',
      },
      {
        root: true,
      },
    );
    http
      .POST(
        '/api/backup/backupProd',
        {
          username: username,
          password: password,
          isFilterLearningPathOut: isFilterLearningPathOut,
        },
        { isAuthorization: true },
      )
      .then(response => {
        commit('DONE_BACKUP_PRODUCTION');
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Successfully backup production database',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Failed backup production database' || err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  backupDevelopmentDatabase(
    { commit, dispatch },
    { username, password, isFilterLearningPathOut },
  ) {
    commit('IS_PROCESSING');
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Start Backup Development Database...',
      },
      {
        root: true,
      },
    );
    http
      .POST(
        '/api/backup/backupDev',
        {
          username: username,
          password: password,
          isFilterLearningPathOut: isFilterLearningPathOut,
        },
        { isAuthorization: true },
      )
      .then(response => {
        commit('DONE_BACKUP_DEVELOPMENT');
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Successfully backup development database',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        commit('IS_PROCESSING');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message:
              'Failed backup development database' || err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
};

const mutations = {
  IS_PROCESSING(state) {
    state.isProcessing = !state.isProcessing;
  },
  SET_USER_CONFIG(state, payload) {
    if (payload) {
      state.userConfig = payload;
    } else {
      delete state.userConfig;
    }
  },
  RESET_MIGRATION_STATUS(state) {
    state.doneBackupDevelopment = false;
    state.doneBackupProduction = false;
    state.doneBackupDevelopmentWithTimestamp = false;
    state.doneBackupProductionWithTimestamp = false;
    state.doneRestoreDevelopmentToProduction = false;
  },
  RESET_USER_CONFIG(state) {
    state.userConfig = {};
  },
  DONE_BACKUP_DEVELOPMENT(state) {
    state.doneBackupDevelopment = !state.doneBackupDevelopment;
  },
  DONE_BACKUP_PRODUCTION(state) {
    state.doneBackupProduction = !state.doneBackupProduction;
  },
  DONE_BACKUP_PRODUCTION_WITH_TIMESTAMP(state) {
    state.doneBackupProductionWithTimestamp =
      !state.doneBackupProductionWithTimestamp;
  },
  DONE_BACKUP_DEVELOPMENT_WITH_TIMESTAMP(state) {
    state.doneBackupDevelopmentWithTimestamp =
      !state.doneBackupDevelopmentWithTimestamp;
  },
  DONE_RESTORE_DEVELOPMENT_TO_PRODUCTION(state) {
    state.doneRestoreDevelopmentToProduction =
      !state.doneRestoreDevelopmentToProduction;
  },
  SET_CURRENT_UNIT_LIST(state, value) {
    if (value) {
      state.UNITS = value;
    } else {
      delete state.UNITS;
    }
  },
  SET_SERVER_STATUS(state, value) {
    if (value) {
      state.SERVER_STATUS = value;
    } else {
      delete state.SERVER_STATUS;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
