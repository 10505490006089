<template>
  <label class="file_input">
    <div class="border">
      <div>
        <span v-if="!filename">{{ placeholder }}</span>
        <span v-else>{{ filename }}</span>
      </div>
      <input type="file" v-on:change="onSelect" :accept="accept" />
    </div>
  </label>
</template>
<script>
export default {
  name: 'FileInput',
  props: ['filename', 'onSelect', 'placeholder', 'accept'],
};
</script>

<style scoped>
.file_input {
  margin: inherit;
}
.file_input > .border {
  display: flex;
  border: 3px solid #ccc !important;
  border-radius: 16px;
  height: 45px;
  justify-content: center;
  padding-top: 8px;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 500;
}
.file_input > .border > input[type='file'] {
  display: none;
}
</style>
