import http from '../../utils/httpClient';
import moment from 'moment';
import router from '../../router';
import swal from 'sweetalert2';

const state = {
  isUpdating: false,
  CREATE: {},
  CREATED_VIDEO: {},
  READ: {},
  UPDATED_GROUP: {},
  UPDATED_VIDEO: {},
  DELETE: {},
  SELECTED_CATEGORY_ID: '',
  SELECTED_LEARNING_MEDIA: {},
  selectedGroupIndex: -1,
};
const getters = {
  getUpdatedGroup(state) {
    return state.UPDATED_GROUP;
  },
  getUpdatedVideo(state) {
    return state.UPDATED_VIDEO;
  },
};
const actions = {
  fetchLearningMedia({ commit, dispatch }) {
    commit('TOGGLE_UPDATING_STATE');
    http
      .GET(`/api/learningmedia`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(learningmedia => ({
          ...learningmedia,
          created_at: moment(learningmedia.created_at).format(
            'YYYY MMM DD  h:mm A',
          ),
          updated_at: moment(learningmedia.updated_at).format(
            'YYYY MMM DD  h:mm A',
          ),
        }));
        commit('DISPLAY_LEARNING_MEDIA_LIST_STATE', formatData);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch learning media at this time, please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('TOGGLE_UPDATING_STATE'));
  },
  createVideo({ dispatch }, { _id, ...data }) {
    const createVideoData = {
      _id,
      ...data,
    };
    http
      .POST('/api/learningmedia/group', createVideoData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully create learning media',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleLearningMedia', {
          id: state.SELECTED_CATEGORY_ID,
        });
        router.go({
          path: '/learningmedia/detail',
          query: {
            id: state.SELECTED_CATEGORY_ID,
          },
        });
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed create learning media',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  createGroup({ dispatch }, { category_id, ...data }) {
    const createGroupData = {
      category_id,
      ...data,
    };
    http
      .POST('/api/learningmedia', createGroupData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully create learning media group',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleLearningMedia', {
          id: state.SELECTED_CATEGORY_ID,
        });
        router.go({
          path: '/learningmedia/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed create learning media group',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchSingleLearningMedia({ commit, dispatch }, { id, sub_id }) {
    commit('TOGGLE_UPDATING_STATE');
    http
      .GET(`/api/learningmedia?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(learningmedia => ({
          ...learningmedia,
          created_at: moment(learningmedia.created_at).format(
            'YYYY MMMM D h:mm a',
          ),
          updated_at: moment(learningmedia.updated_at).format(
            'YYYY MMMM D h:mm a',
          ),
        }));
        commit('DISPLAY_LEARNING_MEDIA_LIST_STATE', formatData);
        commit('SET_SELECTED_LEARNING_MEDIA_STATE', { id, sub_id });
        commit('SET_SELECTED_CATEGORY_STATE', id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch learning media at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('TOGGLE_UPDATING_STATE'));
  },
  updateGroup({ dispatch }, { _id, ...data }) {
    const updateGroupData = {
      _id,
      ...data,
    };
    http
      .PUT('/api/learningmedia/group', updateGroupData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully update learning media group',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleLearningMedia', {
          id: state.SELECTED_CATEGORY_ID,
        });
        router.go({
          path: '/learningmedia/detail',
          query: {
            id: state.SELECTED_CATEGORY_ID,
          },
        });
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed update learning media group',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  updateVideo({ dispatch }, { _id, ...data }) {
    const updateGroupData = {
      _id,
      ...data,
    };
    http
      .PUT('/api/learningmedia/video', updateGroupData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully update learning media',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleLearningMedia', {
          id: state.SELECTED_CATEGORY_ID,
        });
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Failed update learning media',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  deleteVideo({ dispatch, state }, { videoID }) {
    swal
      .fire({
        titleText: 'Confirm Delete Video',
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'red',
        confirmButtonText: 'Remove',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/learningmedia/video?_id=${videoID}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleLearningMedia', {
                id: state.SELECTED_CATEGORY_ID,
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message:
                    'Cannot delete at this time, please try again later.',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  deleteGroup({ dispatch, state }, { groupID }) {
    swal
      .fire({
        titleText: 'Confirm Delete Group',
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'red',
        confirmButtonText: 'Remove',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/learningmedia/group?_id=${groupID}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete successfully',
                  type: 'success',
                },
                { root: true },
              );
              router.go({
                path: '/learningmedia/detail',
                query: {
                  id: state.SELECTED_CATEGORY_ID,
                },
              });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message:
                    'Cannot delete at this time, please try again later.',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  resetCreateState({ commit }) {
    commit('RESET_CREATED_STATE');
  },
  resetCreateVideoState({ commit }) {
    commit('RESET_CREATED_VIDEO_STATE');
  },
  setCreateState({ commit }, { key, value }) {
    commit('SET_CREATED_STATE', { key, value });
  },
  setCreateVideoState({ commit }, { key, value }) {
    commit('SET_CREATED_VIDEO_STATE', { key, value });
  },
  setUpdateGroupState({ commit }, { key, value }) {
    commit('SET_UPDATED_GROUP_STATE', { key, value });
  },
  setUpdateVideoState({ commit }, { key, value }) {
    commit('SET_UPDATED_VIDEO_STATE', { key, value });
  },
  setSelectedCategoryState({ commit }, id) {
    commit('SET_SELECTED_CATEGORY_STATE', id);
  },
  setSelectedLearningMediaState({ commit }, id) {
    commit('SET_SELECTED_LEARNING_MEDIA_STATE', { id, sub_id: '' });
  },
};
const mutations = {
  TOGGLE_UPDATING_STATE(state) {
    state.isUpdating = !state.isUpdating;
  },
  DISPLAY_LEARNING_MEDIA_LIST_STATE(state, payload) {
    if (payload) {
      state.READ = payload;
    } else {
      delete state.READ;
    }
  },
  RESET_CREATED_STATE(state) {
    state.CREATE = {};
  },
  RESET_CREATED_VIDEO_STATE(state) {
    state.CREATED_VIDEO = {};
  },
  SET_CREATED_STATE(state, { key, value }) {
    state.CREATE = {
      ...state.CREATE,
      [key]: value,
    };
  },
  SET_CREATED_VIDEO_STATE(state, { key, value }) {
    state.CREATED_VIDEO = {
      ...state.CREATED_VIDEO,
      [key]: value,
    };
  },
  SET_UPDATED_GROUP_STATE(state, { key, value }) {
    state.UPDATED_GROUP = {
      ...state.UPDATED_GROUP,
      [key]: value,
    };
  },
  SET_UPDATED_VIDEO_STATE(state, { key, value }) {
    state.UPDATED_VIDEO = {
      ...state.UPDATED_VIDEO,
      [key]: value,
    };
  },
  SET_SELECTED_CATEGORY_STATE(state, value) {
    if (value) {
      state.SELECTED_CATEGORY_ID = value;
    } else {
      delete state.SELECTED_CATEGORY_ID;
    }
  },
  SET_SELECTED_LEARNING_MEDIA_STATE(state, { id, sub_id }) {
    if (id) {
      const index = state.READ.findIndex(
        learningmedia => learningmedia.category_id === id,
      );
      state.SELECTED_LEARNING_MEDIA = state.READ[index];
      if (sub_id) {
        const groupIndex = state.SELECTED_LEARNING_MEDIA.groups.findIndex(
          group => group._id === sub_id,
        );
        state.selectedGroupIndex = groupIndex;
      }
    } else {
      delete state.SELECTED_LEARNING_MEDIA;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
