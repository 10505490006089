<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/whack_a_mole')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (selectedGameListStore && selectedGameListStore.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="selectedGameListStore">
          <v-layout row grid-list-md v-if="!updatingState">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Game</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(game, index) in gameDataStore"
                    :key="index"
                    @click="onClickSelectGame(game)"
                    :class="index === selectedGameIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ game.title }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs5 mr-2 v-if="selectedGameIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Game Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onClickUpdateGame">
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <div>
                      <v-tabs
                        v-model="active"
                        color="black"
                        dark
                        slider-color="yellow"
                      >
                        <v-tab v-for="n in 5" :key="n" ripple>
                          {{ menuLabel(n - 1) }}
                        </v-tab>
                        <v-tab-item v-for="n in 5" :key="n">
                          <v-flex v-if="n === 1">
                            <v-text-field
                              label="Title"
                              v-model="game.title"
                            ></v-text-field>
                            <v-flex>
                              <v-text-field
                                label="Introduction Word(tts message)"
                                v-model="game.tts_message"
                              ></v-text-field>
                              <v-select
                                :items="constants.VOICE_NAME"
                                label="Voice Name"
                                :value="game.voice_name"
                                v-model="game.voice_name"
                              ></v-select>
                            </v-flex>
                            <v-flex>
                              <v-btn
                                v-if="game.introduction_voice_uri"
                                class="white--text"
                                color="subtitle_sound"
                                @click="openSubtitleSound()"
                                >Subtitle Sound
                              </v-btn>
                              <v-text-field
                                label="Introduction Voice (URL or Select file)"
                                v-model="game.introduction_voice_uri"
                              ></v-text-field>
                              <v-btn
                                @click="
                                  isSelectSound = true;
                                  isSelectIntroVoice = true;
                                "
                                ><v-icon>audiotrack</v-icon>Select Introduction
                                Voice</v-btn
                              >
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                type="number"
                                label="Timer Limit"
                                v-model="game.timer_limit"
                              ></v-text-field>
                            </v-flex>
                          </v-flex>
                          <v-flex v-if="n === 2">
                            <UpdateLevel :level="level"></UpdateLevel>
                          </v-flex>
                          <v-flex v-if="n === 3">
                            <UpdateMole :mole="mole"></UpdateMole>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-flex v-if="n === 4">
                            <UpdateHole :hole="hole"></UpdateHole>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-flex v-if="n === 5">
                            <div class="point-data">
                              <v-btn
                                block
                                large
                                class="white--text"
                                color="pointdata"
                                @click="openPointData"
                                >Point Data</v-btn
                              >
                            </div>
                            <v-divider></v-divider>
                          </v-flex>
                        </v-tab-item>
                      </v-tabs>
                      <div class="text-xs-center mt-3">
                        <v-btn @click="next">next tab</v-btn>
                      </div>
                    </div>
                  </v-layout>
                </v-card-text>
                <v-btn
                  block
                  color="red"
                  dark
                  @click="onClickDeleteGame(game._id)"
                >
                  <v-icon>delete</v-icon>Delete game
                </v-btn>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="selectedGameIndex !== -1 ? game.learned_words : null"
      :incidentalWords="selectedGameIndex !== -1 ? game.incidental_words : null"
      :rewards="selectedGameIndex !== -1 ? game.reward : null"
      :sentences="selectedGameIndex !== -1 ? game.sentence : null"
      :phonics="selectedGameIndex !== -1 ? game.phonic : null"
    >
    </PointData>
  </Container>
</template>

<script>
import PointData from '../../components/PointData';
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';

import { mapState, mapActions } from 'vuex';
import UpdateLevel from './components/update/update-level.vue';
import UpdateMole from './components/update/update-mole.vue';
import UpdateHole from './components/update/update-hole.vue';
import constants from '../../constants';

export default {
  name: 'WhackAmole',
  components: {
    Container,
    ResourceSelect,
    PointData,
    UpdateLevel,
    UpdateMole,
    UpdateHole,
  },
  computed: {
    ...mapState({
      gameDataStore: state => state.whack_a_mole.GAME_DATA,
      selectedGameListStore: state => state.whack_a_mole.SELECTED_GAME_LIST,
      updatingState: state => state.whack_a_mole.isUpdating,
    }),
  },
  data() {
    return {
      constants,
      isSelectIntroVoice: false,
      isOpenPointData: false,
      width: '60%',

      isSelectCardSound: false,
      isSelectItemImage: false,

      active: null,
      selectedSoundUri: '',
      isSelectMainBackground: false,
      isSelectSound: false,
      isSelectBackgroundMusic: false,
      isSelectImage: false,
      selectedImageUri: '',
      selectedGameIndex: -1,
      game: {},
      level: [],
      mole: [],
      hole: [],
    };
  },
  methods: {
    ...mapActions('whack_a_mole', [
      'getGameList',
      'getGameData',
      'getGameDataByObjectID',
      'getCategoryByGameListID',
      'updateGame',
      'deleteGame',
    ]),
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.game.introduction_voice_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.game.learned_words = learned_words;
      }
      if (incidental_words) {
        this.game.incidental_words = incidental_words;
      }
      if (reward) {
        this.game.reward = reward;
      }
      if (sentence) {
        this.game.sentence = sentence;
      }
      if (phonic) {
        this.game.phonic = phonic;
      }
      this.onClickUpdateGame();
      this.isOpenPointData = false;
    },
    async createWhackAmoleItem() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
    },
    validateWidth(val) {
      if (val > 6) {
        this.width = '80%';
      } else if (val <= 6) {
        this.width = '60%';
      }
    },
    onClickDeleteGame(id) {
      this.deleteGame({ gameID: id });
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },
    menuLabel(index) {
      const label = ['Game', 'Level', 'Mole', 'Hole', 'Point Data'];
      return label[index];
    },
    next() {
      const active = parseInt(this.active);
      this.active = active < 4 ? active + 1 : 0;
    },
    closeSelectSound() {
      (this.isSelectSound = false), (this.selectedSoundUri = '');
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      if (this.isSelectBackgroundMusic) {
        this.game.bgm_uri = uri;
        this.isSelectBackgroundMusic = false;
      } else if (this.isSelectCardSound) {
        this.game.card.sound_uri = uri;
        this.isSelectCardSound = false;
      } else if (this.isSelectBackgroudMusic) {
        this.game.bgm_uri = uri;
        this.isSelectBackgroudMusic = false;
      } else if (this.isSelectIntroVoice) {
        this.game.introduction_voice_uri = uri;
        this.isSelectIntroVoice = false;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    onSelectImageResource(uri) {
      this.selectedImageUri = uri;
    },
    closeSelectImage() {
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    saveSelectImage() {
      const uri = this.selectedImageUri;
      if (this.isSelectMainBackground) {
        this.game.background_uri = uri;
      } else if (this.isSelectCardImage) {
        this.game.card.image_uri = uri;
      }
      this.isSelectCardImage = false;
      this.isSelectMainBackground = false;
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    async onClickSelectGame(data) {
      const gameStore = this.gameDataStore;
      this.selectedGameIndex = gameStore.findIndex(
        game => game._id === data._id,
      );
      this.game = gameStore[this.selectedGameIndex];
      this.level = this.game.level;
      this.mole = this.game.mole;
      this.hole = this.game.hole;
      this.level.forEach(e => {
        if (!('gem_collect' in e)) {
          Object.assign(e, { gem_collect: -1 });
        }
      });
    },
    onClickUpdateGame() {
      const whackAmole = {
        ...this.game,
        level: this.level,
        mole: this.mole,
        hole: this.hole,
      };
      this.updateGame(whackAmole);
    },
  },
  async mounted() {
    if (Object.keys(this.selectedGameListStore).length === 0) {
      if (this.$route.query && this.$route.query.sub_id) {
        await this.getGameDataByObjectID(this.$route.query.sub_id);
      } else {
        await this.getGameData(this.$route.query.id);
      }
      setTimeout(() => {
        this.getCategoryByGameListID(this.gameDataStore[0].game_list_id);
      }, 1000);
    }
  },
};
</script>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}

.modal-item {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

.point-data {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

.subtitle-btn {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}
</style>
