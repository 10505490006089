var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-flex',[_c('img',{staticClass:"content_img",attrs:{"src":_vm.player.idle_image_uri}}),_c('v-text-field',{attrs:{"label":"Idle Image URI (URL or Select file)"},model:{value:(_vm.player.idle_image_uri),callback:function ($$v) {_vm.$set(_vm.player, "idle_image_uri", $$v)},expression:"player.idle_image_uri"}}),_c('v-btn',{staticClass:"btn-center",on:{"click":_vm.onclickIdleImage}},[_c('v-icon',[_vm._v("image")]),_vm._v("Select Idle Image URI")],1),_c('SpriteSheet',{attrs:{"title":'is idle sprite sheet',"isToggle":_vm.player.is_idle_sprite_sheet,"fieldname":'Edit Idle Sheet Data',"object":_vm.player.idle_sprite_sheet_data},on:{"onchangeToggleSprite":function($event){return _vm.$emit(
          'onchangeToggleSprite',
          'is_idle_sprite_sheet',
          'idle_sprite_sheet_data',
          $event
        )},"saveSpriteSheetData":function($event){return _vm.$emit('saveSpriteSheetData', 'idle_sprite_sheet_data', $event)}}}),_c('img',{staticClass:"content_img",attrs:{"src":_vm.player.move_image_uri}}),_c('v-text-field',{attrs:{"label":"Move Image URI (URL or Select file)"},model:{value:(_vm.player.move_image_uri),callback:function ($$v) {_vm.$set(_vm.player, "move_image_uri", $$v)},expression:"player.move_image_uri"}}),_c('v-btn',{staticClass:"btn-center",on:{"click":_vm.onclickMoveImage}},[_c('v-icon',[_vm._v("image")]),_vm._v("Select Move Image URI")],1),_c('SpriteSheet',{attrs:{"title":'is move sprite sheet',"isToggle":_vm.player.is_move_sprite_sheet,"fieldname":'Edit Move Sheet Data',"object":_vm.player.move_sprite_sheet_data},on:{"onchangeToggleSprite":function($event){return _vm.$emit(
          'onchangeToggleSprite',
          'is_move_sprite_sheet',
          'move_sprite_sheet_data',
          $event
        )},"saveSpriteSheetData":function($event){return _vm.$emit('saveSpriteSheetData', 'move_sprite_sheet_data', $event)}}})],1),(_vm.isSelectImage)?_c('ResourceSelect',{attrs:{"resourceType":"image","isShow":_vm.isSelectImage,"close":_vm.closeSelectImage,"save":_vm.saveSelectImage,"value":_vm.selectedImageUri,"onSelect":_vm.onSelectImageResource}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }