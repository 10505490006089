<template>
  <v-flex xs7 mr-2>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Home Page Data</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="onClickUpdateGroup()"
          color="green"
          :disabled="!valid || isLock"
        >
          <v-icon>save</v-icon>Save
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card-text class="pa-0 ma-0">
      <v-container>
        <v-form ref="formUpdate" v-model="valid" lazy-validation>
          <v-layout grid-list-xs>
            <v-flex xs3>
              <img
                class="image_thumbnail1"
                :src="pageGameStore.selectedHomePageData.img_uri"
              />
            </v-flex>
            <v-flex xs8>
              <v-text-field
                label="Image URI (URL or Select file)"
                :value="pageGameStore.selectedHomePageData.img_uri"
                v-model="pageGameStore.selectedHomePageData.img_uri"
                @change="e => onchangePageGroup('img_uri', e)"
                :rules="[rules.validURI]"
                :disabled="isLock"
              ></v-text-field>
              <v-btn
                class="btn-center"
                @click="onclickEditImage()"
                :disabled="isLock"
                ><v-icon>image</v-icon>Select Image URI</v-btn
              >
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
    </v-card-text>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
  </v-flex>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ruleValidate from '../../../utils/ruleValidate';
import ResourceSelect from '../../../components/ResourceSelect.vue';
// import Modal from '../../../components/Modal.vue';
import swal from 'sweetalert2';

export default {
  components: { ResourceSelect },
  data() {
    return {
      valid: true,
      rules: ruleValidate,
      isEditImage: false,
      isSelectImg: false,
      selectImageUri: '',
    };
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
      isLock: state => state.homepage.adminIsLock,
    }),
  },
  methods: {
    ...mapActions('homepage', ['setStateByKey', 'updatePageGroupData']),
    onClickUpdateGroup() {
      if (this.$refs.formUpdate.validate()) {
        // swal
        //   .fire({
        //     titleText: 'Are you sure.',
        //     type: 'warning',
        //     showCancelButton: true,
        //     cancelButtonColor: '#d33',
        //   })
        //   .then(result => {
        //     if (result.value) {
        this.updatePageGroupData();
        //   }
        // });
      }
    },
    onchangePageGroup(key, value) {
      this.setStateByKey({
        statekey: 'updatePageGroup',
        key: key,
        value: value,
      });
    },
    onclickEditImage() {
      this.isEditImage = true;
      this.isSelectImg = true;
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImg:
          this.pageGameStore.selectedHomePageData.img_uri = uri;
          this.onchangePageGroup('img_uri', uri);
          this.isSelectImg = false;
          // this.setDirty('game');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
  },
};
</script>

<style>
.active {
  background-color: #212121;
}
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
</style>
