<template>
  <div>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Choice Part</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout column>
            <v-flex>
              <v-btn
                v-if="choices.length < 10"
                color="green"
                small
                @click="createChoice()"
              >
                <v-icon>add</v-icon>Create Choice
              </v-btn>
              <v-data-table
                :headers="[{ text: 'Choice', value: 'set', sortable: false }]"
                :items="choices"
                class="elevation-1 row-pointer"
                hide-actions
              >
                <template v-slot:items="props">
                  <tr
                    :class="
                      props.index === selectedChoiceIndex
                        ? 'active'
                        : 'inactive'
                    "
                    @click="seleteChoice(props.index)"
                  >
                    <td class="text-xs-left">
                      {{ props.item.name }}
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        @click="deleteChoice(props.index)"
                        small
                        fab
                        left
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    /> -->
    <ModalCreate
      :isOpen="isCreate"
      :title="title"
      @close="closeCreate()"
    ></ModalCreate>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
// import ResourceSelect from '../../../components/ResourceSelect.vue';
import rules from '../../../utils/ruleValidate';
import ModalCreate from './ModalCreate.vue';

export default {
  components: {
    // ResourceSelect,
    ModalCreate,
  },
  data() {
    return {
      valid: true,
      rules,
      isCreate: false,
      title: '',
      // choices: [],
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
    selectedSetIndex() {
      return this.dragComboStore.selectedSetIndex;
    },
    selectedNodeIndex() {
      return this.dragComboStore.selectedNodeIndex;
    },
    selectedChoiceIndex() {
      return this.dragComboStore.selectedChoiceIndex;
    },
    selectedChoice() {
      return this.dragComboStore.selectedDragCombo.sets[this.selectedSetIndex]
        .nodes[this.selectedNodeIndex].choices[this.selectedChoiceIndex];
    },
    choices() {
      return this.dragComboStore.selectedDragCombo.sets[this.selectedSetIndex]
        .nodes[this.selectedNodeIndex].choices;
    },
  },
  methods: {
    ...mapActions('dragcombo', [
      'updateNodeDragCombo',
      'setStateByKey',
      'clearStateByKey',
    ]),
    seleteChoice(index) {
      this.setStateByKey({
        statekey: 'selectedChoiceIndex',
        value: index,
      });
      this.setStateByKey({
        statekey: 'selectedChoice',
        value: this.selectedChoice,
      });
      this.clearStateByKey('updateChoice');
    },
    deleteChoice(index) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.clearStateByKey('selectedChoiceIndex');
            const deleteData = {
              id: this.dragComboStore.updateNode.id,
              indexChoice: index,
            };
            this.updateNodeDragCombo(deleteData);
          }
        });
    },
    createChoice() {
      this.isCreate = true;
      this.title = 'Create Choice';
    },
    closeCreate() {
      this.isCreate = false;
      this.title = '';
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
</style>
