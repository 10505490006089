<template>
  <v-layout row grid-list-md>
    <v-flex mr-2 xs2>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Event</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="green" @click="createEvent()">
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list v-if="shortStoryStore.selectedShortStory.events != undefined">
          is_auto
          <draggable @end="orderEventList">
            <v-list-tile
              v-for="(e, index) in shortStoryStore.selectedShortStory.events"
              :key="e._id"
              v-on:click="() => ({})"
            >
              <v-list-tile-action
                v-on:click="clickEvent(index, e._id, eventSelect[e.type].name)"
              >
                <v-checkbox
                  v-if="eventSelect[e.type].name === 'Play'"
                  :value="index"
                  v-model="shortStoryStore.selectedCheckboxEvent"
                  :disabled="isCheckBoxDisables(index)"
                  @change="event => onchangeCheckbox(e._id, 'is_auto', event)"
                ></v-checkbox>
              </v-list-tile-action>
              <v-list-tile-content v-on:click="onSelectEvent(index, e._id)">
                <v-list-tile-title>{{ index }} {{ e.name }}</v-list-tile-title>
                <v-list-tile-sub-title>
                  type: {{ eventSelect[e.type].name }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  v-if="eventSelect[e.type].name === 'Play'"
                  class="btn-center"
                  @click="copyEvent(e._id)"
                  flat
                  icon
                >
                  <v-icon>content_copy</v-icon></v-btn
                >
              </v-list-tile-action>
            </v-list-tile>
          </draggable>
        </v-list>
      </v-card>
    </v-flex>
    <EventDetail :eventId="eventId"></EventDetail>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onClickCreateEvent"
      :close="closeCreateEvent"
      :isLastStep="valid"
      title="Create Event"
    >
      <v-card>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  :value="shortStoryStore.createEvent.name"
                  v-model="shortStoryStore.createEvent.name"
                  label="Name"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-select
                  :items="EventType"
                  item-text="name"
                  item-value="value"
                  :value="shortStoryStore.createEvent.type"
                  v-model="shortStoryStore.createEvent.type"
                  label="Type"
                  :rules="[rules.required]"
                ></v-select>
                <v-text-field
                  type="number"
                  :value="shortStoryStore.createEvent.duration_ms"
                  v-model.number="shortStoryStore.createEvent.duration_ms"
                  label="Duration ms"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  :value="shortStoryStore.createEvent.sound_uri"
                  v-model="shortStoryStore.createEvent.sound_uri"
                  label="Play sound on start (URL or Select file)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickSound()"
                  ><v-icon>audiotrack</v-icon>Select play sound on start</v-btn
                >
                <v-checkbox
                  label="is_auto"
                  v-if="
                    shortStoryStore.createEvent.type === 1 &&
                    !shortStoryStore.selectedCheckboxEvent.length
                  "
                  v-model="shortStoryStore.createEvent.is_auto"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import Modal from '../../../components/Modal';
import constant from '../constant';
import EventDetail from '../components/EventDetail.vue';
import swal from 'sweetalert2';
import draggable from 'vuedraggable';

export default {
  components: {
    ResourceSelect,
    Modal,
    EventDetail,
    draggable,
  },
  data() {
    return {
      eventSelect: constant.EventType,
      EventType: [],
      eventId: '',
      // selectedEventIndex: -1,
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectSoundBtn: false,
      isCreate: false,
      valid: true,
      rules: constant.rule,
    };
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    ...mapActions('short_story', [
      'createEventShortStory',
      'clearCreateEvent',
      'updateEventStore',
      'clearUpdateEvent',
      'setSelectedEventIndex',
      'clearSelectedFilterIndex',
      'clearSelectedEventIndex',
      'clearDirty',
      'updateEventShortStory',
      'duplicateEventandFilter',
    ]),
    copyEvent(id) {
      const game_id = this.shortStoryStore.updateGameDetail.id;
      this.duplicateEventandFilter({ id, game_id });
    },
    clickEvent(index, id, type) {
      if (type !== 'Play') {
        this.onSelectEvent(index, id);
      }
    },
    isCheckBoxDisables(index) {
      return this.shortStoryStore.selectedCheckboxEvent.length >= 1 &&
        this.shortStoryStore.selectedCheckboxEvent.indexOf(index) == -1
        ? true
        : false;
    },
    onchangeCheckbox(id, key, value) {
      value.length ? (value = true) : (value = false);
      this.updateEventStore({ key: 'id', value: id });
      this.updateEventStore({ key, value });
      const updateData = { ...this.shortStoryStore.updateGameEvent };
      this.updateEventShortStory(updateData);
      this.clearUpdateEvent();
    },
    orderEventList(e) {
      const game_id = this.shortStoryStore.updateGameDetail.id;
      this.clearSelectedEventIndex();
      this.clearSelectedFilterIndex();
      this.updateEventShortStory({
        id: game_id,
        currentIndex: e.oldIndex,
        newIndex: e.newIndex,
      });
    },
    filterEvenType() {
      let typeEvent = [...constant.EventType];
      const evens = this.shortStoryStore.selectedShortStory.events;
      evens.forEach(e => {
        if (e.type === 0 || e.type === 2) {
          const index = typeEvent.map(c => c.value).indexOf(e.type);
          if (index > -1) {
            typeEvent.splice(index, 1); //if found Start,End remove from array
          }
        }
      });
      this.EventType = typeEvent;
    },
    createEvent() {
      this.filterEvenType();
      this.isCreate = true;
    },
    onClickCreateEvent() {
      if (this.$refs.form.validate()) {
        const game_id = this.shortStoryStore.updateGameDetail.id;
        const CreateEvent = {
          ...this.shortStoryStore.createEvent,
          game_id: game_id,
        };
        this.createEventShortStory(CreateEvent);
        this.clearCreateEvent();
        this.isCreate = false;
      }
    },
    closeCreateEvent() {
      this.isCreate = false;
      this.clearCreateEvent();
    },
    onSelectEvent(index, id) {
      if (this.shortStoryStore.isDirty.game) {
        swal.fire({
          titleText: 'You must save game data.',
          type: 'warning',
          showCancelButton: true,
        });
      } else if (this.shortStoryStore.isDirty.event) {
        swal
          .fire({
            titleText: 'You must save event data.',
            type: 'warning',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              // this.eventId = id;
              // this.setSelectedEventIndex(index);
              // this.clearUpdateEvent();
              // this.updateEventStore({ key: 'id', value: id });
              // this.clearSelectedFilterIndex();
              // this.clearDirty('event');
            }
          });
      } else if (this.shortStoryStore.isDirty.filter) {
        swal.fire({
          titleText: 'You must save filter data.',
          type: 'warning',
          showCancelButton: true,
        });
      } else {
        this.eventId = id;
        this.setSelectedEventIndex(index);
        this.clearUpdateEvent();
        this.updateEventStore({ key: 'id', value: id });
        this.clearSelectedFilterIndex();
        // this.clearDirty('event');
      }
    },
    onclickSound() {
      this.isSelectSound = true;
      this.isSelectSoundBtn = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectSoundBtn:
          this.shortStoryStore.createEvent.sound_uri = uri;
          this.isSelectSoundBtn = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
  },
};
</script>

<style scope>
.v-list__tile__action {
  min-width: 40px;
}
/* .event-title {
  padding-left: 40px;
}
.event-play {
  margin-left: -40px;
} */
</style>
