export const LANGUAGES = [
  'Thai',
  'English',
  'Japanese',
  'Indonesian',
  'Vietnamese',
  'ChineseTraditional',
  'ChineseSimplified',
  'Korean',
  'Arabic',
  'French',
  'Portuguese',
  'Russian',
  'Spanish',
  'Turkish',
  'German',
  'Italian',
];
