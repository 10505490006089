const env = process.env.NODE_ENV || 'chinese';

const config = {
  test: {
    server_address: 'http://localhost:2021',
    prod_address: 'https://chinese.galaxyportal.net',
  },
  development: {
    server_address: 'https://chinese.galaxyportal.net',
    prod_address: 'https://prod-chinese.galaxyportal.net',
  },
  production: {
    server_address: 'https://chinese.galaxyportal.net',
    prod_address: 'https://prod-chinese.galaxyportal.net',
  },
  chinese: {
    server_address: 'https://chinese.galaxyportal.net',
    prod_address: 'https://prod-chinese.galaxyportal.net',
  },
};

const cookiesHashUrlServer = {
  //set cookies for api server url
  development: 'Y!-rq;7GxjTW',
  production: 'Gd+CsYxn8_PE',
};

export default { ...config[env], cookiesHashUrlServer };
