export default {
  COUNTRY: [
    {
      text: 'Thailand',
      value: 'Thailand',
    },
    {
      text: 'China',
      value: 'China',
    },
    {
      text: 'Japan',
      value: 'Japan',
    },
  ],
  LANGUAGE: [
    {
      text: 'Thai',
      value: 'TH',
    },
    {
      text: 'English',
      value: 'EN',
    },
    {
      text: 'Chinese',
      value: 'CH',
    },
    {
      text: 'Japanese',
      value: 'JP',
    },
  ],
};
