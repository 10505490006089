<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Flying Game Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="green" @click="createFlyingGameGroup">
            <v-icon>add</v-icon>
          </v-btn>
          <v-text-field
            solo
            placeholder="Search"
            append-icon="search"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="flyingGame in gameListStore"
              :key="flyingGame._id"
              @click="onClickSelect(flyingGame)"
            >
              <v-list-tile-avatar>
                <v-icon>import_contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ flyingGame.category }} ({{
                    flyingGame.number_of_game
                  }})</v-list-tile-title
                >
                <v-list-tile-sub-title
                  >Updated at {{ flyingGame.updated_at }}</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeModalCreate"
      :isLastStep="valid"
      title="Create Game"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-autocomplete
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  :rules="requiredRule"
                  v-model="flyinggame.category_id"
                ></v-autocomplete>
                <v-text-field
                  required
                  :rules="requiredRule"
                  label="Game Title"
                  v-model="flyinggame.title"
                ></v-text-field>
                <v-flex>
                  <v-text-field
                    label="Introduction Word(tts message)"
                    v-model="flyinggame.tts_message"
                  ></v-text-field>
                  <v-select
                    :items="constants.VOICE_NAME"
                    label="Voice Name"
                    :value="flyinggame.voice_name"
                    v-model="flyinggame.voice_name"
                  ></v-select>
                </v-flex>
                <v-flex>
                  <v-text-field
                    label="Game Speed"
                    v-model="flyinggame.game_speed"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <img
                    v-if="flyinggame.main_background_uri"
                    :src="flyinggame.main_background_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Main Background URI (URL or Select file)"
                    v-model="flyinggame.main_background_uri"
                  ></v-text-field>
                  <v-btn dark @click="isSelectImage = true">
                    <v-icon>image</v-icon>Select Main Background
                  </v-btn>
                </v-flex>
                <v-btn dark @click="openModalSubBackground()">
                  <v-icon>image</v-icon>Sub Background Manager
                </v-btn>
                <v-autocomplete
                  chips
                  label="Select Character"
                  :items="constant.CHARACTER"
                  item-text="text"
                  item-value="value"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="flyinggame.player_data.character"
                ></v-autocomplete>
                <v-flex>
                  <v-text-field
                    label="Hit Audio URI (URL or Select file)"
                    v-model="flyinggame.player_data.hit_audio_uri"
                  />
                  <v-btn dark @click="isSelectSound = true">
                    <v-icon>audiotrack</v-icon> Select Hit Audio
                  </v-btn>
                </v-flex>
                <v-flex>
                  <v-checkbox
                    label="Hide Particle"
                    :value="flyinggame.player_data.is_hide_particle"
                    v-model="flyinggame.player_data.is_hide_particle"
                  ></v-checkbox>
                  <v-text-field
                    label="Paricle URI (URL or Select file)"
                    v-model="flyinggame.player_data.particle_uri"
                  ></v-text-field>
                  <v-btn
                    @click="
                      isSelectImage = true;
                      isSelectParticleUri = true;
                    "
                  >
                    <v-icon>audiotrack</v-icon>Select Particle Image</v-btn
                  >
                </v-flex>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <Modal
      v-if="isOpenModalSubBackground"
      :isOpen="isOpenModalSubBackground"
      :save="onSaveSubBackground"
      :close="closeModalSubBackground"
      :isLastStep="true"
      title="Sub Background"
    >
      <v-flex xs12 sm12 md12>
        <v-btn color="success" small fab @click="increaseStep('')"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.sub_background">
          <v-stepper-header>
            <template v-for="n in step.sub_background">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.sub_background > n"
                :step="n"
                editable
              >
                Sub Background {{ n }}
              </v-stepper-step>

              <v-divider v-if="n !== step.sub_background" :key="n"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.sub_background"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="reduceStep(step)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <img
                  v-if="
                    flyinggame.sub_background_list[step - 1].sub_background_uri
                  "
                  :src="
                    flyinggame.sub_background_list[step - 1].sub_background_uri
                  "
                  class="content_img"
                />
                <v-text-field
                  label="Sub Background URI (URL or Select file)"
                  v-model="
                    flyinggame.sub_background_list[step - 1].sub_background_uri
                  "
                ></v-text-field>
                <v-btn
                  dark
                  @click="
                    isSelectSubBackground = true;
                    isSelectImage = true;
                  "
                >
                  <v-icon>image</v-icon>Select Sub Background
                </v-btn>
                <v-text-field
                  label="Sub Background Speed"
                  type="number"
                  v-model="
                    flyinggame.sub_background_list[step - 1]
                      .sub_background_speed
                  "
                ></v-text-field>
              </v-flex>
              <v-btn color="primary" @click="nextStep(step)"> Next </v-btn>
              <v-btn flat @click="backStep(step)"> Back </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
  </Container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import ResourceSelect from '../../components/ResourceSelect';
import constant from './constant';
import constants from '../../constants';

export default {
  name: 'FlyingGame',
  components: { Container, Modal, ResourceSelect },
  data() {
    return {
      constants,
      constant,
      element: {
        sub_background: 1,
      },
      step: {
        sub_background: 1,
      },
      isSelectSubBackground: false,
      isOpenModalSubBackground: false,
      isSelectSound: false,
      selectSoundUri: '',
      isSelectParticleUri: false,
      isSelectImage: false,
      selectImageUri: '',
      flyinggame: {
        category_id: '',
        title: '',
        bgm_uri: '',
        main_background_uri: '',
        sub_background_list: [{ sub_background_uri: '' }],
        item: [
          {
            name: '',
            type: '',
            amount: 1,
            image: '',
            hit_audio_uri: '',
          },
        ],
        player_data: {
          character: 0,
          hit_audio_uri: '',
          is_hide_particle: false,
        },
      },
      requiredRule: [v => !!v || 'This field is required'],
      isCreate: false,
      valid: false,
    };
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      flyingGameStore: state => state.flyinggame,
      gameListStore: state => state.flyinggame.GAME_LIST,
      createState: state => state.flyinggame.CREATE,
    }),
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('flyinggame', [
      'resetCreateState',
      'createGameData',
      'getGameList',
      'getGameData',
      'setSelectedGameListState',
    ]),
    onClickSelect(data) {
      const id = data._id;
      this.getGameData(id);
      this.setSelectedGameListState(data);
      this.$router.push({
        path: '/flyinggame/detail',
        query: {
          id,
        },
      });
    },
    async createFlyingGameGroup() {
      // this.reset();
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
    },
    openModalSubBackground() {
      this.isOpenModalSubBackground = true;
    },
    addDefaultObjectSubBackground() {
      const formatObject = {
        sub_background_uri: '',
      };
      this.flyinggame.sub_background_list.push(formatObject);
    },
    increaseStep() {
      const max = 3;
      if (this.step.sub_background < max) {
        this.step.sub_background += 1;
        this.addDefaultObjectSubBackground();
      }
    },
    reduceStep(step) {
      let index = step - 1;
      if (this.step.sub_background > 1) {
        this.step.sub_background -= 1;
        this.flyinggame.sub_background_list.splice(index, 1);
      }
    },
    nextStep(currentStep) {
      if (currentStep === this.step.sub_background) {
        this.element.sub_background = 1;
      } else {
        this.element.sub_background = currentStep + 1;
      }
    },
    backStep(currentStep) {
      if (currentStep > 1) {
        this.element.sub_background -= 1;
      }
    },
    onSaveSubBackground() {
      this.isOpenModalSubBackground = false;
    },
    closeModalSubBackground() {
      this.isOpenModalSubBackground = false;
    },
    closeSelectSound() {
      this.selectSoundUri = '';
      this.isSelectSound = !this.isSelectSound;
    },
    saveSelectSound() {
      const uri = this.selectSoundUri;
      this.flyinggame.player_data.hit_audio_uri = uri;
      this.selectSoundUri = '';
      this.isSelectSound = !this.isSelectSound;
    },
    closeSelectImage() {
      this.selectImageUri = '';
      this.isSelectImage = !this.isSelectImage;
    },
    saveSelectImage() {
      const uri = this.selectImageUri;
      if (this.isSelectSubBackground) {
        this.flyinggame.sub_background_list[
          this.element.sub_background - 1
        ].sub_background_uri = uri;
      } else if (this.isSelectParticleUri) {
        this.flyinggame.player_data.particle_uri = uri;
      } else {
        this.flyinggame.main_background_uri = uri;
      }
      this.selectImageUri = '';
      this.isSelectSubBackground = false;
      this.isSelectImage = false;
      this.isSelectParticleUri = false;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    reset() {
      this.$refs.form.reset();
      this.resetStep();
      this.resetValidation();
    },
    resetStep() {
      this.step.sub_background = 1;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    closeModalCreate() {
      this.isCreate = false;
    },
    onSaveCreate() {
      this.isCreate = false;
      const gameObject = {
        ...this.flyinggame,
      };
      this.createGameData(gameObject);
    },
  },
  mounted() {
    this.getGameList();
  },
};
</script>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
