import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '../../router';
const state = {
  media: [],
  selectMedia: {},
  selectedCategoryId: '',
  selectedVideoIndex: -1,
  updateVideoData: {},
  isUpdating: false,
  addVideoData: {},
  eventData: {
    startX: '',
    startY: '',
    endX: '',
    endY: '',
    nextIndex: 0,
  },
};
const getters = {};
const actions = {
  fetchMedia({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/media?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(media => ({
          ...media,
          created_at: moment(media.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(media.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeMedia', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch media at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  selectMedia({ commit }, id) {
    commit('storeSelectMedia', { id, sub_id: '' });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchSingleMedia({ commit, dispatch }, { id, sub_id }) {
    commit('toggleStoreUpdate');
    http
      .GET(`/api/media?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(media => ({
          ...media,
          created_at: moment(media.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(media.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeMedia', formatData);
        commit('storeSelectMedia', { id, sub_id });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch media at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => commit('toggleStoreUpdate'));
  },
  updateVideoData({ commit }, { key, value }) {
    commit('storeUpdateVideoData', {
      key,
      value,
    });
  },
  updateVideo({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/media/video',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(({ data }) => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Video Success.',
              type: 'success',
            },
            {
              root: true,
            },
          );
          dispatch('fetchSingleMedia', { id: state.selectMedia.category_id });
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update set. Please try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateData({ commit }) {
    commit('clearStoreUpdateVideoData');
  },
  deleteVideo({ dispatch, state }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Video',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/media/video?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchSingleMedia', {
                id: state.selectMedia.category_id,
              });
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  updateCreateVideoData({ commit }, { key, value }) {
    commit('storeCreateVideoData', {
      key,
      value,
    });
  },
  addVideo({ dispatch }, { category_id, ...data }) {
    const createVideoData = {
      category_id,
      video: {
        ...data.video,
      },
    };
    http
      .POST('/api/media', createVideoData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Video success',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchSingleMedia', { id: category_id });
        router.go({
          path: '/media/detail',
          query: {
            id: category_id,
          },
        });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Create Video failed',
            type: 'errpr',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeMedia(state, mediaList) {
    state.media = mediaList;
  },
  storeSelectMedia(state, { id, sub_id }) {
    if (id) {
      const index = state.media.findIndex(media => media.category_id === id);
      state.selectMedia = state.media[index];
      if (sub_id) {
        const videoIndex = state.selectMedia.videos.findIndex(
          video => video._id === sub_id,
        );
        state.selectedVideoIndex = videoIndex;
      }
    } else {
      delete state.selectMedia;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeUpdateVideoData(state, { key, value }) {
    state.updateVideoData = {
      ...state.updateVideoData,
      [key]: value,
    };
  },
  storeCreateVideoData(state, { key, value }) {
    if (value || value == 0) {
      state.addVideoData = {
        ...state.addVideoData,
        [key]: value,
      };
    } else {
      delete state.addVideoData[key];
    }
  },
  clearStoreUpdateVideoData(state) {
    Object.keys(state.updateVideoData).forEach(key => {
      if (key != '_id') {
        delete state.updateVideoData[key];
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
