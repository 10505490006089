import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/src/stylus/app.styl';
import colors from 'vuetify/es5/util/colors';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
const iconsGroup = localStorage.getItem('current_icons_group') || 'md';
import 'tiptap-vuetify/dist/main.css';

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    pointdata: colors.purple,
    subtitle: '#AF9C0B',
    subtitle_sound: '#56876D',
    tapstory_image_set: '#53AC90',
    tapstory_image_group: '#3676EF',
    tapstory_image_data: '#6666EA',
  },
});

Vue.use(TiptapVuetifyPlugin, {
  iconsGroup: iconsGroup,
});
