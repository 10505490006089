import http from '../../utils/httpClient';
import swal from 'sweetalert2';
const state = {
  CONTENT_NAVIGATION: [],
  AVAILABLE_UNIT_LIST: [],
  SYNC_LEARNINGPATH_RESULT: [],
};
const actions = {
  fetchContentNavigation({ commit }) {
    http
      .GET(`/api/content_navigation`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_CONTENT_NAVIGATION', data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  fetchAvailableUnitList({ commit }) {
    http
      .GET(`/api/content_navigation/availableUnits`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_AVAILABLE_UNIT_LIST', data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  syncLearningPathContent({ commit, dispatch }, unit_id_list) {
    http
      .PUT(`/api/content_navigation/syncLearningPathContent`, unit_id_list, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        commit('SET_SYNC_LEARNINGPATH_RESULT', data);
        swal.fire(
          `${data.message}` || 'Done',
          `\n success: ${data.total_success} : \n failed: ${data.total_failed}`,
          'info',
        );
        dispatch('fetchContentNavigation');
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  createContentNavigation({ dispatch }, content) {
    http
      .POST('/api/content_navigation', content, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully created content navigation',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchContentNavigation');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Failed to create content navigation', type: 'error' },
          { root: true },
        );
        throw new Error(error);
      });
  },
  updateContentNavigation({ dispatch }, content) {
    http
      .PATCH('/api/content_navigation', content, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Successfully updated content navigation',
            type: 'success',
          },
          { root: true },
        );
        dispatch('fetchContentNavigation');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Failed to update content navigation', type: 'error' },
          { root: true },
        );
        throw new Error(error);
      });
  },
  deleteContentNavigation({ dispatch }, { ID, unit_name }) {
    swal
      .fire({
        titleText: `Delete Content ${unit_name}`,
        text: 'This operation cannot be undone. Would you like to proceed?',
        confirmButtonColor: 'red',
        confirmButtonText: 'Delete',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/content_navigation/?_id=${ID}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchContentNavigation');
            })
            .catch(error => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message:
                    'Cannot delete at this time, please try again later.',
                  type: 'error',
                },
                { root: true },
              );
              throw new Error(error);
            });
        }
      });
  },
  resetContentNavigation({ commit }) {
    commit('RESET_CONTENT_NAVIGATION');
  },
  resetAvailableUnitList({ commit }) {
    commit('RESET_AVAILABLE_UNIT_LIST');
  },
};
const mutations = {
  SET_SYNC_LEARNINGPATH_RESULT(state, value) {
    if (value) {
      state.SYNC_LEARNINGPATH_RESULT = value;
    } else {
      delete state.SYNC_LEARNINGPATH_RESULT;
    }
  },
  SET_CONTENT_NAVIGATION(state, value) {
    if (value) {
      state.CONTENT_NAVIGATION = value;
    } else {
      delete state.CONTENT_NAVIGATION;
    }
  },
  SET_AVAILABLE_UNIT_LIST(state, value) {
    if (value) {
      state.AVAILABLE_UNIT_LIST = value;
    } else {
      delete state.AVAILABLE_UNIT_LIST;
    }
  },
  RESET_CONTENT_NAVIGATION(state) {
    state.CONTENT_NAVIGATION = [];
  },
  RESET_AVAILABLE_UNIT_LIST(state) {
    state.AVAILABLE_UNIT_LIST = [];
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
