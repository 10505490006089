<template>
  <Modal
    v-if="isOpen"
    :isOpen="isOpen"
    :save="save"
    :close="close"
    :isLastStep="valid"
    :title="title"
  >
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-layout grid-list-xs column text-xs-center>
            <v-flex v-if="title === 'Create Sets'">
              <v-text-field
                :value="createData.name"
                v-model="createData.name"
                label="name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
            <v-flex v-if="title === 'Create Nodes'">
              <v-text-field
                :value="createData.name"
                v-model="createData.name"
                label="name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
            <v-flex v-if="title === 'Create Choice'">
              <v-text-field
                :value="createData.name"
                v-model="createData.name"
                label="name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
    </v-card>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import rules from '../../../utils/ruleValidate';

export default {
  components: { Modal },
  data() {
    return {
      valid: true,
      rules,
      createData: {},
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
    // choicesData() {
    //   const selectedSetIndex = this.dragComboStore.selectedSetIndex;
    //   const selectedNodeIndex = this.dragComboStore.selectedNodeIndex;
    //   return this.dragComboStore.selectedDragCombo.sets[selectedSetIndex].nodes[
    //     selectedNodeIndex
    //   ].choices;
    // },
  },
  methods: {
    ...mapActions('dragcombo', [
      'createSets',
      'createNodes',
      'setStateByKey',
      'updateNodeDragCombo',
    ]),
    save() {
      switch (this.title) {
        case 'Create Sets':
          this.createSets(this.createData);
          break;
        case 'Create Nodes':
          this.createNodes(this.createData);
          break;
        case 'Create Choice':
          // let choices = this.choicesData;
          // choices.push(this.createData);
          const create = {
            id: this.dragComboStore.updateNode.id,
            addChoices: this.createData,
          };
          this.updateNodeDragCombo(create);
          break;
        default:
          break;
      }
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        switch (this.title) {
          case 'Create Sets':
            this.createData = {
              ...this.dragComboStore.defaultCreateSets,
              dragcombo_id: this.dragComboStore.updateDetail.id,
            };
            break;
          case 'Create Nodes':
            this.createData = {
              ...this.dragComboStore.defaultCreateNodes,
              set_id: this.dragComboStore.updateSet.id,
            };
            break;
          case 'Create Choice':
            this.createData = {
              ...this.dragComboStore.defaultCreateChoice,
            };
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>

<style></style>
