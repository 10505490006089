import http from '../../utils/httpClient';

const state = {
  PRIMARY_KEY: {},
  BEFORE_UPDATE: {},
  SUBTITLE: {},
  SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION: {},
};
const actions = {
  getPrimaryKeyBySubtitleData({ commit }, subtitleData) {
    http
      .GET(
        `/api/subtitle_localization/primary?game_type=${subtitleData.game_type}&ID=${subtitleData.ID}&English=${subtitleData.English}`,
        { isAuthorization: true },
      )
      .then(response => {
        commit('SET_PRIMARY_KEY', response.data.result[0]);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  createSubtitle({ dispatch }, subtitle) {
    http
      .POST('/api/subtitle', subtitle, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('subtitle_localization/publishTimestamp', 'English', {
          root: true,
        });
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Successfully created subtitle data', type: 'success' },
          { root: true },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Failed to create subtitle data', type: 'error' },
          { root: true },
        );
      });
  },
  createSubtitleWithCreateSubtitleLocalization({ dispatch }, subtitle) {
    http
      .POST('/api/subtitle', subtitle, {
        isAuthorization: true,
      })
      .then(response => {
        // Validate if English Subtitle has a length more than one, loop through array and create subtitle localization.
        if (subtitle && subtitle.English.length > 1) {
          subtitle.English.forEach(english => {
            const subtitleLocalizationModel = {
              game_type: subtitle.game_type,
              game_id: subtitle.ref_id,
              subtitle_id: response.data._id,
              English: english,
            };
            setTimeout(() => {
              dispatch(
                'subtitle_localization/addLanguage',
                { languageData: subtitleLocalizationModel },
                { root: true },
              );
            }, 500);
          });
        } else {
          // If not create subtitle localization without any loop operation, but convert from array to string.
          const subtitleLocalizationModel = {
            game_type: subtitle.game_type,
            game_id: subtitle.ref_id,
            subtitle_id: response.data._id,
            English: subtitle.English.toString(),
          };
          dispatch(
            'subtitle_localization/addLanguage',
            { languageData: subtitleLocalizationModel },
            { root: true },
          );
        }
        dispatch('subtitle_localization/publishTimestamp', 'English', {
          root: true,
        });
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Successfully created subtitle data', type: 'success' },
          { root: true },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Failed to create subtitle data', type: 'error' },
          { root: true },
        );
      });
  },

  updateSubtitleLocalization({ dispatch }, { subtitle_localization }) {
    const subtitleLocalizationModel = {
      _id: subtitle_localization._id,
      ID: subtitle_localization.ref_id,
      game_type: subtitle_localization.game_type,
      English: subtitle_localization.English.toString(),
    };
    dispatch(
      'subtitle_localization/editLanguage',
      { updateData: subtitleLocalizationModel },
      { root: true },
    );
  },
  updateSubtitleWithCreateSubtitleLocalization({ dispatch }, { subtitle }) {
    http
      .PUT('/api/subtitle', subtitle, {
        isAuthorization: true,
      })
      .then(() => {
        // start TODO: dispatch create new subtitle localization
        // Validate if English Subtitle has a length more than one, loop through array and create subtitle localization.
        if (subtitle && subtitle.English.length > 1) {
          subtitle.English.forEach(english => {
            const subtitleLocalizationModel = {
              game_type: subtitle.game_type,
              game_id: subtitle.ref_id,
              subtitle_id: subtitle._id,
              English: english,
            };
            setTimeout(() => {
              dispatch(
                'subtitle_localization/addLanguage',
                { languageData: subtitleLocalizationModel },
                { root: true },
              );
            }, 500);
          });
        } else {
          // If not create subtitle localization without any loop operation, but convert from array to string.
          const subtitleLocalizationModel = {
            game_type: subtitle.game_type,
            game_id: subtitle.ref_id,
            subtitle_id: subtitle._id,
            English: subtitle.English.toString(),
          };
          dispatch(
            'subtitle_localization/addLanguage',
            { languageData: subtitleLocalizationModel },
            { root: true },
          );
        }
        dispatch('subtitle_localization/publishTimestamp', 'English', {
          root: true,
        });
        setTimeout(() => {
          dispatch(
            'appConfig/toggleAlert',
            { message: 'Successfully updated subtitle data', type: 'success' },
            { root: true },
          );
        }, 3000);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Failed to update subtitle data', type: 'error' },
          { root: true },
        );
      });
  },
  updateSubtitle({ dispatch }, { subtitle }) {
    http
      .PUT('/api/subtitle', subtitle, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Successfully updated subtitle data', type: 'success' },
          { root: true },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Failed to update subtitle data', type: 'error' },
          { root: true },
        );
      });
  },
  updateSingleFieldSubtitle({ dispatch }, { subtitle }) {
    http
      .PATCH('/api/subtitle', subtitle, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Successfully patched subtitle data', type: 'success' },
          { root: true },
        );
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          { message: 'Failed to patched subtitle data', type: 'error' },
          { root: true },
        );
      });
  },
  getSubtitleByID({ commit }, _id) {
    http
      .GET(`/api/subtitle/?_id=${_id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (data) {
          commit('SET_SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION', data[0]); // existing
        } else {
          commit('RESET_SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION'); // new
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  getSubtitleByRefID({ commit }, ref_id) {
    http
      .GET(`/api/subtitle/?ref_id=${ref_id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (data) {
          commit('SET_SUBTITLE_DATA', data[0]); // existing
        } else {
          commit('RESET_SUBTITLE_DATA'); // new
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  setBeforeUpdateData({ commit }, data) {
    commit('SET_BEFORE_UPDATE_DATA', data);
  },
  resetBeforeUpdateData({ commit }) {
    commit('RESET_BEFORE_UPDATE_DATA');
  },
};
const mutations = {
  RESET_PRIMARY_KEY(state) {
    state.BEFORE_UPDATE = {};
  },
  RESET_BEFORE_UPDATE_DATA(state) {
    state.BEFORE_UPDATE = {};
  },
  RESET_SUBTITLE_DATA(state) {
    state.SUBTITLE = {};
  },
  RESET_SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION(state) {
    state.SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION = {};
  },
  SET_SUBTITLE_DATA(state, payload) {
    if (payload) {
      state.SUBTITLE = payload;
    } else {
      delete state.SUBTITLE;
    }
  },
  SET_SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION(state, payload) {
    if (payload) {
      state.SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION = payload;
    } else {
      delete state.SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION;
    }
  },
  SET_BEFORE_UPDATE_DATA(state, payload) {
    if (payload) {
      state.BEFORE_UPDATE = payload;
    } else {
      delete state.BEFORE_UPDATE;
    }
  },
  SET_PRIMARY_KEY(state, payload) {
    if (payload) {
      state.PRIMARY_KEY = payload;
    } else {
      delete state.PRIMARY_KEY;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
