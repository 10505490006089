<template>
  <v-container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title
            >User Registration Statistics In 2020</v-toolbar-title
          >
        </v-toolbar>
        <v-layout row wrap>
          <v-flex
            xs12
            sm6
            md4
            lg3
            v-for="data in this.dashboardStore.statistics.numberOfUsersStats"
            :key="data.title"
          >
            <v-card class="text-xs-center ma-3">
              <v-responsive class="pt-4">{{ data.title }}</v-responsive>
              <v-card-text>
                <div class="pt-2 subheadline" v-if="data.number > 1">
                  {{ data.number }} People
                </div>
                <div class="pt-2 subheadline" v-else>
                  {{ data.number }} Person
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-container>
</template>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.centralized {
  justify-content: center;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'Dashboard',
  components: {},
  computed: {
    ...mapState({
      dashboardStore: state => state.dashboard,
    }),
  },
  methods: {
    ...mapActions('dashboard', ['fetchNumberOfUsers']),
  },
  data: function () {
    return {
      labels: ['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm'],
      value: [200, 675, 410, 390, 310, 460, 250, 240],
    };
  },
  mounted() {
    this.fetchNumberOfUsers();
  },
};
</script>
